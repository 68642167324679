import React, { Component } from "react";
import { Tabs, Tab } from "react-bootstrap";
import { Link } from "react-router-dom";
import { __t } from "../../locale/language";
import Alert from "../common/Alert";
import Alertinfo from "../common/Alertinfo";
import Alertsuccess from "../common/Alertsuccess";
import Alertyesno from "../common/Alertyesno";
import AlertDelete from "../common/AlertDelete";
import AssetModal from "./AssetModel";
import Utility from "../common/Utility";
import AssetsList from "./AssetsList";
import Can from "../common/AccessControl";
import $ from "jquery";
var ext_type = [
  { Data: "pdf", Value: "pdf" },
  { Data: "csv", Value: "csv" },
  { Data: "doc", Value: "doc" },
  { Date: "xls", Value: "xls" },
  { Date: "file", Value: "file" }
];
var delete_status;

class GetAssets extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modal: false,
      accept_type: "",
      type_search: "",
      select_date: ""
    };
  }
  getImage = file => {
    ////console.log(file)
    if (typeof file != "undefined" && typeof file.ext !== "undefined") {
      var file_typ = file.file_type;
      var check_image = file_typ.substring(0, 5);
      var file_ext = file.ext;
      var found;

      var defaultImage;
      let default_url = "/img/";
      if (check_image == "image") {
        var thumb_img = Utility.getThumbimage(file.url, file_ext);
        // //console.log(thumb_img)
        if (file.status === "active") {
          defaultImage = thumb_img;
        } else {
          defaultImage = file.url_deleted;
        }
      } else if (check_image == "video") {
        defaultImage = default_url + "default_video.png";
      } else {
        var obj = {
          pdf: "pdf",
          csv: "csv",
          doc: "doc",
          xls: "xls",
          file: "file"
        };

        found = Object.keys(obj).filter(function (key) {
          return obj[key] === file_ext;
        });
        if (found != "") {
          defaultImage = default_url + "default_" + found + "." + "png";
        } else {
          // defaultImage = default_url + "default_image.png";
          defaultImage = defaultImage;
        }
      }
      return defaultImage;
    } else {
      return "";
    }
  };

  componentWillReceiveProps({ accept_type, image_type, select_date, type_search }) {
    this.setState(
      {
        accept_type: accept_type,
        image_type: image_type,
        select_date: select_date,
        type_search: type_search
      },
      () => {
        //console.log(this.state.accept_type);
      }
    );
  }

  render() {
    var newpage = this.props.total / 30;
    //console.log(this.state.accept_type);
    var newdata = this.props.data;
    var image_type
    if (this.state.image_type != "undefined") {
      image_type = this.state.image_type;
    }
    console.log(this.props.total)
    //console.log(this.state.image_type)
    return (
      // <Can
      //   I={["view-user"]}
      //   userPermissions={typeof this.props.userpermission!="undefined"? this.props.userpermission :""}
      // >
      <div>
        <Alertinfo message={this.props.message} />
        <Alertyesno
          cnfrm_msg={this.props.cnfrm_msg}
          cnfrm_delete={this.props.cnfrm_delete}
        />

        <AlertDelete
          dlt_msg={this.props.dlt_msg}
          selected_delete={this.props.selected_delete}
        />
        <div className="row wrapper border-bottom white-bg page-heading">
          <div className="col-lg-10">
            <h2 style={{ marginTop: 10 }}>{__t("assets_list")}</h2>
            <strong style={{ float: "right" }}>
              {__t("total_record")}:{this.props.total}
            </strong>
            <ol className="breadcrumb">
              <li className="breadcrumb-item">
                <Link to="/">{__t("dashboard")}</Link>
              </li>
              <li className="breadcrumb-item">
                <Link to="/assets">{__t("assets")}</Link>
              </li>
              <li className="breadcrumb-item active">
                <strong>{__t("assets_list")}</strong>
              </li>
            </ol>
          </div>
        </div>
        <div className="ibox-title">
          {this.state.accept_type != "" ? (
            <form onSubmit={this.props.onFormSubmit}>
              <div className="file_input_wrap ht48">
                <div className="content ">
                  <div className="box ">
                    <input
                      type="file"
                      onChange={this.props.onChange}
                      name="file-1[]"
                      id="file-1"
                      className="inputfile inputfile-1"
                      data-multiple-caption="{count} files selected"
                      multiple
                      accept={this.state.accept_type}
                    />
                    <label htmlFor="file-1" className="ht48">
                      {" "}
                      <i className="fa fa-upload text_white" aria-hidden="true">
                        <span className="inputfile_span">
                          {this.props.file
                            ? this.props.file.name
                            : __t("choose_a_file")}
                        </span>
                      </i>
                    </label>
                  </div>
                </div>
                {this.props.user_type == "client" ||
                  this.props.user_type == "admin" ||
                  this.props.user_type == "reseller" ? (
                    <button type="submit" className="btn_ btn-primary mx-2 wd160">
                      {__t("Upload")}
                    </button>
                  ) : (
                    ""
                  )}
                {/* <div className="file_input_wrap wd160">
                                <select
                                    id="drop"
                                    className=" form-control"
                                    onChange={e => {
                                        this.props.typesearch(e, 1, 30);
                                    }}
                                >
                                    <option value="Select Media"> {__t("all_media")}</option>
                                    <option value="image">{__t("image")}</option>
                                    <option value="csv">{__t("csv")}</option>
                                    <option value="pdf">{__t("pdf")}</option>
                                    <option value="video">{__t("video")}</option>
                                </select>
                            </div> */}
                <input
                  id="dte"
                  type="date"
                  className="form-control mx-2 wd180"
                  onChange={e => {
                    this.props.datechange(e, 1, 30);
                  }}
                  placeholder="select date"
                />
                <input
                  type="text"
                  placeholder="search media items "
                  className="search_input_box wd160 form-control mx-2"
                  onChange={this.props.handleChange}
                  name="search"
                  id="search_field"
                />
                <input
                  className="btn btn-primary"
                  type="button"
                  onClick={() => {
                    this.props.searchAssetsList();
                  }}
                  name="search"
                  defaultValue={__t("search")}
                />
                &nbsp;
                <input
                  className="btn btn-warning"
                  type="button"
                  onClick={() => {
                    this.setState(
                      { search: "", type_search: "", select_date: "" },
                      () => {

                        this.props.getDocumnetData(this.props.type_search, 1, 30);

                        document.getElementById("search_field").value = "";
                        $("#drop").val("Select Media");
                        document.getElementById("dte").value = "";
                        
                        $("#drop_img").val("Select Image Type");
                      }
                    );
                  }}
                  name="btn_search"
                  value={__t("Clear")}
                />
                <div className="flex_end m-2">
                  {this.props.user_type == "admin" &&
                    image_type != "deleted" &&
                    this.props.data.length > 0 ? (
                      <div>
                        <label className="myCheckbox flx_center">
                          <input
                            type="checkbox"
                            id="selectall"
                            onClick={() => {
                              this.props.selectAll();
                            }}
                            name="test"
                          />
                          <span />
                          {__t("select_all")}
                        </label>
                      </div>
                    ) : (
                      ""
                    )}

                  {this.props.user_type == "admin" ||
                    this.props.user_type == "reseller" ? (
                      <select
                        id="drop_img"
                        onChange={e => {
                          this.props.imageType_search(e, 1, 30);
                        }}
                      >
                        <option value="Select Image Type"> {__t("All")}</option>
                        {
                          this.props.user_type == "admin"?
                          <option value="rejected">{__t("Rejected")}</option>
                          :"" 
                        }
                       
                        <option value="deleted">{__t("Deleted")}</option>
                      </select>
                    ) : (
                      ""
                    )}
                </div>
              </div>
            </form>
          ) : (
              ""
            )}
        </div>

        <div className="flex-column">
          <div className="flex_right mt-2">
            <button
              onClick={() => {
                this.props.changeCategory();
              }}
              className="btn_ btn-primary mx-2 wd160"
            >
              <i className="fa_back fa fa-arrow-left" />
              {__t("back")}
            </button>
          </div>
          {image_type == "Select Image Type" ||
            typeof image_type == "undefined" ||
            this.props.image_type == "" ||
            image_type == "" ? (
              <div
                style={{
                  float: "right",
                  marginTop: "10px",
                  textAlign: "right"
                }}
              >
                {/* <Can
                  I={["delete-assets"]}
                  userPermissions={this.props.userPermission}
                > */}
                <button
                  className="btn btn-danger btn_sm_2"
                  id="dlt_multiple"
                  style={{ display: "none" }}
                  onClick={() => {
                    this.props.all_delete("deleted");
                  }}
                >
                  <i className="fa fa-trash mright4" />
                  {__t("delete")}
                </button>

                <button
                  className="btn btn-primary btn_sm_2"
                  id="rjt_multiple"
                  style={{ display: "none" }}
                  onClick={() => {
                    this.props.all_delete("rejected");
                  }}
                >
                  <i className="fa fa-ban mright4" />
                  {__t("reject")}
                </button>
                {/* </Can> */}
              </div>
            ) : image_type == "rejected" ? (
              <div
                style={{
                  float: "right",
                  marginTop: "10px",
                  textAlign: "right"
                }}
              >
                <button
                  className="btn btn-danger btn_sm_2"
                  id="dlt_multiple"
                  style={{ display: "none" }}
                  onClick={() => {
                    this.props.all_delete("deleted");
                  }}
                >
                  <i className="fa fa-trash mright4" />
                  {__t("delete")}
                </button>
              </div>
            ) : (
                ""
              )}

          {newdata.length > 0 ? (
            <div className="show-image" id="show_img">
              {/* <i className="fa fa-check-square"></i> */}

              <div className="flex-container back_transp">
                {newdata
                  ? newdata.map((value, k) => {
                    //console.log(value);
                    var ext;
                    if (value.total && value.total != 0) {
                      ext = value.name.split(".").pop();
                      ext_type = ext;
                    }

                    return (
                      <div key={k}>
                        <div className="pos_rel pos_relhover">
                       
                          <div className="flx_space_between  pos_abs_btns_img">
                            {this.props.img_type == "" ||
                              image_type == "Select Image Type" ||
                              image_type == "" ||
                              (image_type != "deleted" &&
                                image_type != "rejected") ? (
                                // <Can
                                //   I={["reject-assets"]}
                                //   userPermissions={this.props.userPermission}
                                // >
                                <div className="flx_space_between  pos_abs_btns_img">
                                  <button
                                    className="btn btn-danger btn_sm"
                                    onClick={() => {
                                      this.props.admin_delete(
                                        value,
                                        "deleted"
                                      );
                                    }}
                                  >
                                    <i className="fa fa-trash mright4" />
                                    {__t("delete")}
                                  </button>

                                  {this.props.user_type == "admin" ?
                                    <button
                                      className="btn btn-primary btn_sm"
                                      onClick={() => {
                                        this.props.admin_delete(
                                          value,
                                          "rejected"
                                        );
                                      }}
                                    >
                                      <i className="fa fa-ban mright4" />
                                      {__t("reject")}
                                    </button>
                                    : ""}

                                </div>
                                // </Can>
                              ) : image_type == "rejected" ? (
                                <Can
                                  I={["delete-assets"]}
                                  userPermissions={this.props.userPermission}
                                >
                                  <button
                                    className="btn btn-danger btn_sm"
                                    onClick={() => {
                                      this.props.admin_delete(
                                        value,
                                        "deleted"
                                      );
                                    }}
                                  >
                                    <i className="fa fa-trash mright4" />
                                    {__t("delete")}
                                  </button>
                                  <button
                                    className="btn btn-primary btn_sm"
                                    onClick={() => {
                                      this.props.admin_delete(
                                        value,
                                        "retrieve"
                                      );
                                    }}
                                  >
                                    <i className="fa fa-ban mright4" />
                                    {__t("retrieve")}
                                  </button>
                                </Can>
                              ) : (
                                  ""
                                )}
                          </div>
                          {this.props.user_type == "admin" &&
                            image_type != "deleted" ? (
                              <label className="myCheckbox_img flx_center pos_abs_check">
                                <input
                                  type="checkbox"
                                  className="mycheckbox"
                                  id="checkbox"
                                  onClick={() => {
                                    this.props.select_delete(value);
                                  }}
                                  name="test"
                                  value="test"
                                />
                                <span />
                              </label>
                            ) : (
                              ""
                            )}

                          <div>
                            <div
                              onClick={() => this.props.editElement(value)}
                            >
                              <img
                                className="assets_img"
                                src={this.getImage(value)}
                                alt="alt"
                              />
                            </div>


                          </div>

                        </div>

                      </div>
                    );
                  })
                  : image_type == "deleted"
                    ? ""
                    : ""}
              </div>
              <div className="first_loader loader_full back_overlay_loader" id="upload-loader"
                            style={{ display: "none" }}
                          ><div className="lds-ring"><div></div>
                              <div></div><div>
                              </div><div></div>
                            </div></div>
              <AssetModal
                isOpen={this.props.modal}
                item={this.props.edititem}
                toggle={this.props.toggle}
                image={this.getImage(this.props.edititem)}
                userPermissions={this.props.userPermission}
              />
              {this.props.data.length > 0 ? (
                <div className="form-inline my-2 flex_end">
                  <button
                    className="btn btn-primary m-2"
                    id="prev"
                    style={{ display: "none" }}
                    onClick={() => {
                      this.props.previous();
                    }}
                  >
                    {" "}
                    {__t("previous")}
                  </button>
                  {newpage && newpage > 1 ? (
                    <button
                      className="btn btn-primary"
                      id="nxt"
                      onClick={() => {
                        this.props.next();
                      }}
                    >
                      {__t("next")}
                    </button>
                  ) : (
                      ""
                    )}
                </div>
              ) : (
                  ""
                )}
            </div>
          ) : (
              <div className="ibox-content">{__t("no_record_found")}</div>
            )}
        </div>
      </div>
      // </Can>
    );
  }
}

export default GetAssets;
