import React, { Component } from 'react';
import { __t } from '../../locale/language';
import { Tab } from 'react-bootstrap';
class Tab1 extends Component {
  constructor(props) {
    super(props);
  }
  render() {
    const tab1_data = this.props.tab1_data;
    var title = tab1_data.feature_title;
    var user_type = this.props.user_type;
    var host = this.props.subDomain;
    var domain = '';
    // console.log('host_value ' + host);
    if (typeof host == 'undefined' || host == '') {
      host = window.location.hostname;
    }
    //var host = ".test"
    if (host !== '') {
      let d = host.split('.');
      // console.log(d);
      if (d.length == 3) {
        domain = '.' + d[1] + '.' + d[2];
      } else {
        domain = host.replace('www', '');
      }
    } else {
      domain = host.replace('www', '');
    }

    return (
      <Tab eventKey={1} title='Server Detail'>
        <div className='row form-group'>
          <div className='col-md-6 col-sm-6 col-xs-12'>
            <label className='control-label  required-field'>
              {__t('name')}
            </label>
            <input
              type='text'
              name='name'
              className='form-control'
              placeholder='Name'
              value={tab1_data.name}
              onChange={this.props.handleChange}
            />
            <label id='-error' className='error'>
              {tab1_data.errors['name']}
            </label>
          </div>
          {user_type != 'client' ? (
            <div className='col-md-6 col-sm-6 col-xs-12'>
              <label className='control-label'>
                {__t('telephone_number')}
              </label>
              <input
                type='number'
                name='telephone_number'
                className='form-control'
                placeholder={__t('telephone_number')}
                value={tab1_data.telephone_number}
                onChange={this.props.handleChange}
              />
              <label id='-error' className='error'>
                {tab1_data.errors['telephone_number']}
              </label>
            </div>
          ) : (
            ''
          )}
          {tab1_data.subscription_plan &&
          this.props.subscription_feature.includes('subdomain') ? (
            <div className='col-md-6 col-sm-6 col-xs-12'>
              <label
                className='control-label'
                style={{ display: 'block' }}
                htmlFor='subdomain'
              >
                {__t('sub_domain')}
              </label>
              <input
                type='text'
                id='subdomain'
                name='subdomain'
                className='form-control'
                placeholder='Sub Domain'
                maxLength='30'
                defaultValue={tab1_data.subdomain}
                onChange={this.props.handleChange}
                //readOnly={tab1_data.plan == tab1_data.subs_plan_id ? "readonly" : ""}
              />
              <input
                type='text'
                className={'form-control'}
                id='subdomaintwo'
                value={this.props.parentDomain}
                disabled
              />

              <label id='-error' className='error'>
                {tab1_data.errors['subdomain']}
              </label>
            </div>
          ) : (
            ''
          )}
        </div>

        <div className='row form-group'>
          <div className='col-md-6 col-sm-6 col-xs-12'>
            <label className='control-label  required-field'>
              {__t('title')}
            </label>
            <input
              type='text'
              name='title'
              autoComplete='off'
              className='form-control'
              placeholder='Title'
              value={tab1_data.title}
              onChange={this.props.handleChange}
            />
            <label id='-error' className='error'>
              {tab1_data.errors['title']}
            </label>
          </div>
          <div className='col-md-6 col-sm-6 col-xs-12'>
            <label className='control-label'>{__t('status')}</label>
            <input
              type='text'
              name='status'
              className='form-control'
              onChange={this.props.handleChange}
              value={tab1_data.status}
              readOnly
            />
            <label id='-error' className='error'>
              {tab1_data.errors['status']}
            </label>
          </div>
        </div>
        <div className='row form-group'>
          <div className='col-md-6 col-sm-6 col-xs-12'>
            <label className='control-label required-field'>
              {__t('billing_address')}
            </label>
            <textarea
              type='text'
              name='address'
              autoComplete='off'
              className='form-control'
              placeholder='address'
              value={tab1_data.address}
              onChange={this.props.handleChange}
            />
            <label id='-error' className='error'>
              {tab1_data.errors['address']}
            </label>
          </div>
          {user_type == 'client' ? (
            <div className='col-md-6 col-sm-6 col-xs-12'>
              <label className='control-label'>
                {__t('subscription_plan')}
              </label>
              <input
                type='text'
                name='plan'
                className='form-control'
                onChange={this.props.handleChange}
                value={tab1_data.subscription_plan}
                readOnly
              />
              <label id='-error' className='error'>
                {tab1_data.errors['subscription_plan']}
              </label>
            </div>
          ) : (
            ''
          )}
          {user_type == 'admin' ? (
            <div className='col-md-6 col-sm-6 col-xs-12'>
              <label className='control-label required-field'>
                {__t('hst_number')}
              </label>
              <input
                type='text'
                name='hst_number'
                className='form-control'
                onChange={this.props.handleChange}
                value={tab1_data.hst_number}
              />
              <label id='-error' className='error'>
                {tab1_data.errors['hst_number']}
              </label>
            </div>
          ) : (
            ''
          )}
        </div>
        {user_type != 'client' ? (
          <div className='row'>
            <div className='col-md-6 col-sm-6 col-xs-12'>
              <label className='control-label required-field'>
                {__t('tax_label')}
              </label>
              <input
                type='text'
                name='tax_label'
                className='form-control required-field'
                placeholder={__t('tax_label')}
                value={tab1_data.tax_label}
                onChange={this.props.handleChange}
              />
              {/* onChange={this.props.updateSetting}/> */}
              <label id='-error' className='error'>
                {tab1_data.errors['tax_label']}
              </label>
            </div>
            <div className='col-md-6 col-sm-6 col-xs-12'>
              <label className='control-label'>
                {__t('fax_number')}
              </label>
              <input
                type='text'
                name='fax_number'
                className='form-control'
                placeholder={__t('fax_number')}
                value={tab1_data.fax_number}
                onChange={this.props.handleChange}
              />
              {/* onChange={this.props.updateSetting}/> */}
              <label id='-error' className='error'>
                {tab1_data.errors['fax_number']}
              </label>
            </div>

            <div className='col-md-6 col-sm-6 col-xs-12'>
              <label className='control-label required-field'>
                {__t('tax_number')}
              </label>
              <input
                type='text'
                name='tax_number'
                className='form-control'
                placeholder={__t('tax_number')}
                value={tab1_data.tax_number}
                onChange={this.props.handleChange}
              />
              {/* onChange={this.props.updateSetting}/> */}
              <label id='-error' className='error'>
                {tab1_data.errors['tax_number']}
              </label>
            </div>

            <div className='col-md-6 col-sm-6 col-xs-12'>
              <label className='control-label required-field'>
                {__t('tax_rate')}
              </label>
              <input
                type='number'
                name='tax_rate'
                className='form-control'
                placeholder={__t('tax_rate')}
                value={tab1_data.tax_rate}
                onChange={this.props.handleChange}
              />
              {/* onChange={this.props.updateSetting}/> */}
              <label id='-error' className='error'>
                {tab1_data.errors['tax_rate']}
              </label>
            </div>
          </div>
        ) : (
          ''
        )}

        <button
          className='btn btn-primary'
          onClick={() => {
            // this.props.handleSelect(2);
            this.props.validation();
          }}
        >
          {__t(user_type === 'client' ? 'Save' : 'next')}
        </button>
      </Tab>
    );
  }
}

export default Tab1;
