import React from "react";

const checkPermissions = (wholePermissions, allowedPermissions) => {
  if (allowedPermissions.length === 0) {
    return true;
  }
  let check = wholePermissions.some((permission) =>
    allowedPermissions.includes(permission)
  );
  return check;
};
const checkFeatuesSubscription = (subscription_feature, allowedPermissions) => {
  if (allowedPermissions.length === 0) {
    return true;
  }
  let finalResponce = false;
  if (allowedPermissions.length > 0) {
    for (let i = 0; i < allowedPermissions.length; i++) {
      const ap = allowedPermissions[i];
      finalResponce = finalResponce || subscription_feature.includes(ap);
    }
  }
  return finalResponce;
};

const AccessControl2 = ({
  I,
  wholePermissions,
  SF = "",
  IfNot = "",
  children,
}) => {
  let permitted = false;
  if (
    wholePermissions.userPermissions &&
    (wholePermissions.user_type == "admin" ||
      wholePermissions.user_type == "reseller")
  ) {
    permitted = checkPermissions(wholePermissions.userPermissions, I);
  }
  if (!permitted && wholePermissions.subscription_feature) {
    permitted = checkFeatuesSubscription(
      wholePermissions.subscription_feature,
      SF
    );
  }
  if (permitted) {
    return children;
  }
  return IfNot();
};

AccessControl2.defaultProps = {
  allowedPermissions: [],
  permissions: [],
  IfNot: () => null,
};

export default AccessControl2;

export const AccessControlFunc = (I, wholePermissions, SF = "") => {
  let permitted = false;
  if (
    wholePermissions &&
    wholePermissions.userPermissions &&
    (wholePermissions.user_type == "admin" ||
      wholePermissions.user_type == "reseller")
  ) {
    permitted = checkPermissions(wholePermissions.userPermissions, I);
  }
  if (!permitted && wholePermissions && wholePermissions.subscription_feature) {
    permitted = checkFeatuesSubscription(
      wholePermissions.subscription_feature,
      SF
    );
  }
  if (permitted) {
    return true;
  }
  return;
};
