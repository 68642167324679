import React, { Component } from "react";
import {  Tab } from "react-bootstrap";
import { __t } from "../../locale/language";
class Tab1 extends Component {
  constructor(props) {
    super(props);
  }
  render() {
    const tab1_data = this.props.tab1_data;
    const clientid = tab1_data.clientid;
    var host = this.props.subDomain;
    let ops = this.props.flag;
    var domain = "";
    if(ops=="create"){
      domain = host.replace("www", "");
    }else{
      if(tab1_data.old_subdomain !== ""){
        let d = tab1_data.old_subdomain.split(".");
        if(d.length  == 3 && typeof d[1] !== "undefined" && typeof d[2] !== "undefined" &&  d[1] !== "undefined" &&  d[2] !== "undefined"){
          domain = "."+d[1]+"."+d[2];
        }else{
          domain = host.replace("www", "");
        }
      }else{
        domain = host.replace("www", "");
      }
    }
    return (
      <Tab eventKey={1} title="Server Detail">
        <div className="row form-group">
          <div className="col-md-6 col-sm-6 col-xs-12">
            <label className="control-label required-field">{__t("name")}</label>
            <input type="text" name="name" className="form-control" placeholder={__t("name")} value={tab1_data.name} onChange={this.props.handleChange} />
            <label id="-error" className="error">
              {tab1_data.errors["name"]}
            </label>
          </div>
          <div className="col-md-6 col-sm-6 col-xs-12">
            <label className="control-label required-field">{__t("title")}</label>
            <input type="text" name="title" autoComplete="off" className="form-control" placeholder={__t("title")} value={tab1_data.title} onChange={this.props.handleChange} />
            <label id="-error" className="error">
              {tab1_data.errors["title"]}
            </label>
          </div>
        </div>

        <div className="row form-group">
          <div className="col-md-6 col-sm-6 col-xs-12">
            <label className="control-label required-field">{__t("subscription_plan")}</label>
            <select name="plan" className="form-control" onChange={this.props.getfeatures} value={tab1_data.plan} id="subs_plan">
              <option value="0">--{__t("select_plan")}--</option>
              {tab1_data.subscription_plan
                ? tab1_data.subscription_plan.map((val, key) => {
                    if (clientid == "") {
                      if (val.status == "active") {
                        return (
                          <option className={val.status + "_plan"} value={val.id} data-status={val.status} key={key}>
                            {val.title} (${val.price}) - {val.duration}
                            {__t("days")}&nbsp;&nbsp;
                            {val.status == "inactive" ? "(Inactive)" : ""}
                          </option>
                        );
                      } else {
                        return "";
                      }
                    } else {
                      return (
                        <option className={val.status + "_plan"} value={val.id} data-status={val.status} key={key}>
                          {val.title} (${val.price}) - {val.duration}
                          {__t("days")}&nbsp;&nbsp;
                          {val.status == "inactive" ? "(Inactive)" : ""}
                        </option>
                      );
                    }
                  })
                : ""}
            </select>
            <label id="-error" className="error">
              {tab1_data.errors["plan"]}
            </label>
          </div>
          <div className="col-md-6 col-sm-6 col-xs-12">
            <label className="control-label required-field">{__t("subscription_status")}</label>
            <select name="subs_status" className="form-control" onChange={this.props.handleChange} value={tab1_data.subs_status}>
              <option value="">-- {__t("select_subscription_status")}--</option>
              <option value="active">{__t("active")}</option>
              <option value="pause">{__t("pause")}</option>
              <option value="expire">{__t("expire")}</option>
            </select>
            <label id="-error" className="error">
              {tab1_data.errors["subs_status"]}
            </label>
          </div>
        </div>
        <div className="row form-group">
          <div className="col-md-6 col-sm-6 col-xs-12">
            <label className="control-label" style={{display:"block"}} htmlFor="subdomain">{__t("sub_domain")}</label>
            
              <input type="text" id="subdomain"
              name="subdomain"
              className="form-control"
              placeholder="Sub Domain"
              maxLength="15"
              defaultValue={tab1_data.subdomain}
              onChange={this.props.handleChange}
              readOnly={tab1_data.plan == tab1_data.subs_plan_id ? "readonly" : ""}
              />
              <input type="text" className={"form-control"} id="subdomaintwo" value={domain} disabled/>
              {/* <input
                type="text"
                name="subdomain"
                className="form-control pright33"
                placeholder="Sub Domain"
                maxLength="15"
                defaultValue={tab1_data.subdomain}
                //{tab1_data.active_domain}
                //== "active"?tab1_data.subdomain + ".ppca.opalina.in":""}
                onChange={this.props.handleChange}
                //readOnly={tab1_data.active_domain == "active" ? "" : "readonly"}
                readOnly={tab1_data.plan == tab1_data.subs_plan_id ? "readonly" : ""}
              /> */}
           

            <label id="-error" className="error">
              {tab1_data.errors["subdomain"]}
            </label>
          </div>

          <div className="col-md-6 col-sm-6 col-xs-12">
            <label className="control-label required-field">{__t("status")}</label>
            <select name="status" className="form-control" onChange={this.props.handleChange} value={tab1_data.status}>
              <option value="select">--{__t("select_status")}--</option>
              <option value="active">{__t("active")}</option>
              <option value="inactive">{__t("inactive")}</option>
            </select>
            <label id="-error" className="error">
              {tab1_data.errors["status"]}
            </label>
          </div>
        </div>
      </Tab>
    );
  }
}

export default Tab1;
