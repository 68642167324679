import React from "react";
import { Modal, ModalHeader, ModalBody, Button, ModalFooter } from "reactstrap";
import { __t } from "../../locale/language";
import ReactQuill from "react-quill";
class ReplyModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      modal: false
    };
  }

  componentWillReceiveProps({ isOpen }) {
    this.setState({ modal: isOpen });
  }

  render() {
    var reply_email = this.props.data.row_data
      ? JSON.parse(this.props.data.row_data.widget_json)
      : [];
    var email_templates = this.props.data.template_list;
    const temp_selection = (
      <select
        name="email_template"
        className={"form-control"}
        onChange={this.props.changeTemplate}
        value={this.props.data.email_template}
      >
        <option value="">{__t("select_email_template")}</option>
        {email_templates.map((v, k) => {
          return (
            <option key={v.id} value={v.id}>
              {v.title}
            </option>
          );
        })}
      </select>
    );

    return (
      <Modal isOpen={this.state.modal}>
        <ModalHeader>{__t("reply_to")}</ModalHeader>
        <ModalBody className="media-modal">
          {temp_selection}
          {__t("to")}
          <input
            type="text"
            value={reply_email.email}
            className="form-control"
            name="To"
            disabled
          />
          {__t("subject")}
          <input
            type="text"
            onChange={this.props.handleChange}
            className="form-control"
            name="subject"
            value={this.props.data.subject}
          />
          {__t("cc")}
          <input
            type="text"
            onChange={this.props.handleChange}
            className="form-control"
            name="cc"
            value={this.props.data.cc}
          />
          {__t("bcc")}
          <input
            type="text"
            onChange={this.props.handleChange}
            className="form-control"
            name="bcc"
            value={this.props.data.bcc}
          />
          <label className="control-label">Reply</label>
          <ReactQuill
            value={this.props.data.reply}
            onChange={this.props.editorChange}
          />
        </ModalBody>
        <ModalFooter>
          <Button
            color="primary"
            onClick={() => {
              this.props.sendwidgetemailreply(
                reply_email.email,
                this.props.data.row_data.id
              );
            }}
          >
            {__t("send")}
          </Button>{" "}
          <Button
            color="secondary"
            onClick={() => {
              this.props.mediaToggle(this.props.data.row_data);
            }}
          >
            {__t("cancel")}
          </Button>
        </ModalFooter>
      </Modal>
    );
  }
}

export default ReplyModal;
