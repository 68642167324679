import React, { Component } from "react";
import grapesjs from "grapesjs";
import Utility from "../common/Utility";

class Preview3 extends Component {
  constructor(props) {
    super(props);
    this.editor = null;
    this.saveContent = this.saveContent.bind(this);
  }

  saveContent() {
    var iframe = document.getElementsByClassName("gjs-frame")[0];
    var innerDoc = iframe.contentDocument || iframe.contentWindow.document;
    var frameBody = innerDoc.querySelector("body");
    var style = frameBody.querySelector("style");
    var htmlContent = frameBody.querySelector("#wrapper");
    var html = htmlContent.innerHTML; //get html content of document
    var css = this.editor.getCss(); //get css content of document
    //console.log(html);
  }

  shouldComponentUpdate() {
    return false;
  }
  componentDidMount() {
    this.editor = grapesjs.init({
      allowScripts: 1,
      showOffsets: 1,
      autorender: 0,
      noticeOnUnload: 0,
      container: "#gjs",
      height: "700px",
      fromElement: true,
      storageManager: { type: null },
      blockManager: { blocks: [] },
      canvas: {
        styles: [Utility.baseUrlFe() + "custom.css"]
      }
    });

    var pnm = this.editor.Panels;

    // pnm.removeButton("views", "open-sm");
    pnm.removeButton("views", "open-layers");
    pnm.removeButton("views", "open-blocks");
    pnm.removeButton("views", "open-tm");
    // pnm.removePanel("views");

    pnm.addButton("options", [
      {
        id: "undo",
        className: "fa fa-undo icon-undo",
        command: function command(editor, sender) {
          sender.set("active", 0);
          editor.UndoManager.undo(1);
        },
        attributes: {
          title: "Undo (CTRL/CMD + Z)"
        }
      },
      {
        id: "redo",
        className: "fa fa-repeat icon-redo",
        command: function command(editor, sender) {
          sender.set("active", 0);
          editor.UndoManager.redo(1);
        },
        attributes: {
          title: "Redo (CTRL/CMD + Y)"
        }
      },
      {
        id: "save",
        className: "fa fa-save",
        command: "saveHtml",
        attributes: {
          title: "Save"
        }
      }
    ]);

    this.editor.Commands.add("saveHtml", {
      run() {
        var iframe = document.getElementsByClassName("gjs-frame")[0];
        var innerDoc = iframe.contentDocument || iframe.contentWindow.document;
        var frameBody = innerDoc.querySelector("body");
        var style = frameBody.querySelector("style");
        var htmlContent = frameBody.querySelector("#wrapper");
        var html = htmlContent.innerHTML; //get html content of document
        //console.log(html);
        alert("Saved Successfully");
      }
    });

    this.editor.render();
    var comps = this.editor.DomComponents;

    // Change background of the wrapper and set some attribute
    var wrapper = comps.getWrapper();
    wrapper.set("style", {
      "background-color": "white"
    });
  }
  render() {
    return (
      <React.Fragment>
        <div className="panel__top">
          <div className="panel__basic-actions" />
          <div className="panel__devices" />
          <div className="panel__switcher" />
        </div>
        <div className="editor-row">
          <div className="editor-canvas">
            <div
              id="gjs"
              style={{ height: "700px !important", overflow: "hidden" }}
            >
              <div className="pos_rel">
                <div className="header3">
                  <h2>
                    Opalina <span className="txt_light_green">Technology</span>
                  </h2>
                  <div className="header_navbar">
                    <span>Conatct us </span>
                    <span>About us </span>
                    <span> Services </span>
                  </div>
                </div>
                <div className="preview_container3">
                  <div className="pos_rel">
                    <div className="slider3_txt">
                      <h1>Hello world</h1>
                      <p>
                        There are many variations of There are many variations
                        of Lorem Ipsum available
                      </p>
                    </div>
                    <img
                      src="/img/landing/header_two.jpg"
                      className="preview3_slider_img"
                    />
                  </div>
                  <div className="preview3_row_contianer">
                    <div className="preview3_div2">
                      <img
                        src="/img/landing/header_one.jpg"
                        className="preview3_row2_img"
                      />
                      <div className="preview3_row2_txt">
                        <h1>
                          <u>Opalina Technology</u>
                        </h1>
                        <p>
                          There are many variations of passages of Lorem Ipsum
                          available, There are many variations of passages of
                          Lorem Ipsum available, There are many variations of
                          passages of Lorem Ipsum available, There are many
                          variations of passages of Lorem Ipsum available,{" "}
                        </p>
                      </div>
                    </div>

                    <div className="preview3_div3">
                      <h2>About</h2>
                      <p>
                        Ipsum is that it has a more-or-less normal distribution
                        of letters, as opposed to using 'Content here, content
                        here', making it look like readable English. There are
                        many variations of There are many variations of passages
                        of Lorem Ipsum available,
                      </p>
                      <a href="#" className="link_btn txt_light_green">
                        learn more
                      </a>
                    </div>

                    <div className="preview3_div2">
                      <div className="preview3_row2_txt">
                        <h1>
                          <u>Campaign</u>
                        </h1>
                        <i>There are many variations Ipsum available</i>
                        <p>
                          of passages of Lorem Ipsum available,There are many
                          variations of passages of Lorem Ipsum available,There
                          are many variations of passages of Lorem Ipsum
                          available,
                        </p>
                      </div>
                      <div className="pos_rel">
                        <a
                          href="#"
                          className="link_btn txt_light_green viewmore3"
                        >
                          view more
                        </a>
                        <img
                          src="/img/landing/header_two.jpg"
                          className="preview3_row4_img"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="footer3">
                  <p>
                    © 2023 by opalina technology. Proudly created with
                    opalina.in
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default Preview3;
