import React from "react";
import { Link } from "react-router-dom";
import Utility from "../common/Utility";
import { __t } from "../../locale/language";
import Can from "../common/AccessControl";
import Moment from "moment";
import $ from "jquery";
import { Redirect } from "react-router-dom";
class ContactDataSet extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      editItem: this.props.editItem,
      scenario: this.props.scenario,
      type: this.props.type,
      table: this.props.table,
      columns: this.props.columns,
      errors: {}
    };
  }

  handleChange = e => {
    let editItem = this.state.editItem;
    Object.assign(editItem, { [e.target.name]: e.target.value });
    this.setState({ editItem });
  };
  handleValidation = () => {
    //console.log(this.state.editItem)
    let fields = this.state;
    let errors = {};
    let formIsValid = true;
    //console.log(fields.editItem.ParlFirstName)
    var obj={};
    obj=fields.editItem
   //console.log( obj['Email:'])
   let email=obj['Email:']
    // return false
    if (fields.editItem.ParlFirstName == "") {
      formIsValid = false;
      errors["name"] = __t("first_name_cannot_be_empty");
    }
    if (email == "") {
      formIsValid = false;
      errors["email"] = __t("email_cannot_be_empty");
    }
    this.setState({ errors: errors });
    return formIsValid;

  }
  saveDataSet = async () => {
    if (this.handleValidation()) {
      let res = await Utility.executeRequest("update-data-contact", { contact: this.state.editItem, table: this.state.table, type: this.state.type });
      if (typeof res.code != "undefined") {
        this.props.toggleForm();
      }
    }

  };

  render() {
    return (
      <React.Fragment>
        <div className="row wrapper border-bottom white-bg page-heading">
          <div className="col-lg-10">
            <h2 style={{ marginTop: 10 }}>{__t("contact_data_set")}</h2>
            <ol className="breadcrumb">
              <li className="breadcrumb-item">
                <Link to="/">{__t("dashboard")}</Link>
              </li>
              <li className="breadcrumb-item">
                <Link to="/contacts">{__t("contact_data_set")}</Link>
              </li>
              <li className="breadcrumb-item active">
                <strong>{__t("create_contact")}</strong>
              </li>
            </ol>
          </div>
        </div>
        <div className="wrapper wrapper-content animated fadeInRight">
          <div className="ibox-title">
            <h5 id="title2">{__t("create_contact")}</h5>
          </div>
          <div className="ibox-content my-2">
            <div className="form-horizontal col-md-12 col-sm-12 col-xs-12">
              <div className="row">
                {this.state.columns.map(column => {
                  if (column.COLUMN_NAME == "ID") {
                    return "";
                  }
                  return (
                    <div key={column.COLUMN_NAME} className="form-group col-md-6 col-sm-6 col-xs-12">
                      {
                        column.COLUMN_NAME == "ParlFirstName" ||  column.COLUMN_NAME == "Email:"?
                          <label className="control-label required-field"> {__t(column.COLUMN_NAME)}</label> :

                          <label className="control-label"> {__t(column.COLUMN_NAME)}</label>
                      }

                      <input
                        type="text"
                        name={column.COLUMN_NAME}
                        onChange={this.handleChange}
                        value={typeof this.state.editItem[column.COLUMN_NAME] != "undefined" ? this.state.editItem[column.COLUMN_NAME] : ""}
                        className="form-control"
                      />
                      {column.COLUMN_NAME == "ParlFirstName" ?
                        <label id="-error" className="error">
                          {this.state.errors["name"]}
                        </label> : ""
                      }{
                        column.COLUMN_NAME == "Email:" ?
                        <label id="-error" className="error">
                          {this.state.errors["email"]}
                        </label> : ""
                      }
                    </div>
                  );
                })}

                <div className="form-group col-md-6 col-sm-6 col-xs-12">
                  <button className="btn btn-sm btn-primary" onClick={this.saveDataSet}>
                    {__t(this.state.scenario)}
                  </button>
                  <button className="btn btn-sm btn-primary" onClick={this.props.toggleForm} style={{ marginLeft: "8px" }}>
                    {__t("cancel")}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default ContactDataSet;
