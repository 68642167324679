import React from "react";
import { Link } from "react-router-dom";
import Utility from "../common/Utility";
import { __t } from "../../locale/language";
import Can from "../common/AccessControl";
import Moment from "moment";
import $ from "jquery";
import ContactDeleteAlert from "../common/ContactDeleteAlert"

class ContactDataSet extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      page: 1,
      data_sets: [],
      delete_id: ""
    };
    this.search = "";
    this.contact_delete = this.contact_delete.bind(this);
  }
  componentWillMount() {
    this.getDataSet(1);
  }
  clearSearch = async () => {
    let res = await Utility.executeRequest("get-data-set", { page: 1, search: "" });
    if (res.code == 200) {
      this.search.value = "";
      this.setState({ data_sets: res.data, page: 1 });
    }
  };
  getDataSet = async page => {
    page = page || this.state.page;
    let res = await Utility.executeRequest("get-data-set", { page: page, search: this.search.value });
    if (res && res.code == 200) {
      this.setState({ data_sets: res.data, page: page });
    }
  };
  updateTable = () => {
    this.getDataSet(this.state.page);
  };
  deleteData = async id => {
    if (window.confirm(__t("delete_confirm"))) {
      let res = await Utility.executeRequest("del-data-set", { id: id });
      console.log(res)
      if (res.code == 200) {
        this.getDataSet(this.state.page);
      }
      else if (res.code == 204) {

        $("#msg_text2").text(__t("relation_id_exists"));
        //$("#alertinfo").show();
        $("#contact_delete").show();
        this.setState({
          delete_id: id
        })

      }
    }

  };
  contact_delete = async () => {
    console.log(this.state.delete_id)
    let res = await Utility.executeRequest("delete-contact-data", { id: this.state.delete_id });
    if (res.code == 200) {
      this.getDataSet(this.state.page);
      $("#contact_delete").hide();
      $("#alertinfo").show();
      $("#msg_text").text(__t("successfully_deleted"));

    }
  }

  render() {
    const { data_sets } = this.state;
    //console.log(data_sets);
    return (
      <React.Fragment>
        <div className="row wrapper border-bottom white-bg page-heading">
          <div className="col-lg-10">
            <h2 style={{ marginTop: 10 }}>{__t("contact_data_set")}</h2>
            <ol className="breadcrumb">
              <li className="breadcrumb-item">
                <Link to="/">{__t("dashboard")}</Link>
              </li>
              <li className="breadcrumb-item active">
                <strong>{__t("contact_data_set")}</strong>
              </li>
            </ol>
          </div>
        </div>
        <ContactDeleteAlert contact_delete={this.contact_delete} />
        <div className="wrapper wrapper-content animated fadeInRight">
          <div className="row">
            <div className="col-lg-12">
              <div className="ibox ">
                <div className="ibox-title">
                  <div className="row">
                    <div className="col-md-6 col-sm-12 col-xs-12">
                      <div className=" form-inline">
                        <div className="form-group mb-2">
                          <input
                            type="text"
                            name="search"
                            ref={search => {
                              this.search = search;
                            }}
                            defaultValue={this.state.search}
                            className="form-control"
                            placeholder={__t("search_by_title")}
                          />
                        </div>
                        <button onClick={this.updateTable} className="btn btn-primary mb-2">
                          {__t("search")}
                        </button>
                        <button onClick={this.clearSearch} className="btn btn-primary mb-2">
                          {__t("clear")}
                        </button>
                        <Link className="btn btn-primary mb-2" to={{ pathname: `/contacts/dataset/create/` }}>
                          {__t("create")}
                        </Link>
                      </div>
                    </div>
                    <div className="col-md-6 col-sm-12 col-xs-12">
                      <div className="form-inline" style={{ float: "right" }}>
                        {/* <Link
                          className="btn btn-primary mb-2"
                          to={{
                            pathname: `/contacts/dataset/ridinglists/`
                          }}
                        >
                          {__t("riding_lists")}
                        </Link> */}
                        <Link
                          className="btn btn-primary mb-2"
                          to={{
                            pathname: `/contacts/dataset/dbconfig`
                          }}
                        >
                          {__t("db_config")}
                        </Link>

                        <Link
                          className="btn btn-primary mb-2"
                          to={{
                            pathname: `/contacts/dataset/contact_lists/`
                          }}
                        >
                          {__t("contact_lists")}
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="ibox-content my-2">
            {data_sets.count > 0 ? (
              <table width="" border="0" className={"table table-striped table-hover table-bordered table-condensed tbl_word_break"}>
                <thead>
                  <tr>
                    {/* <th>{__t("id")}</th> */}
                    <th>{__t("title")}</th>
                    <th>{__t("riding_list")}</th>
                    <th>{__t("contact_list")}</th>
                    <th>{__t("relation_riding_field")}</th>
                    <th>{__t("relation_contact_field")}</th>
                    <th>{__t("status")}</th>
                    <th>{__t("created_at")}</th>
                    <th>{__t("action")}</th>
                  </tr>
                </thead>

                <tbody>
                  {data_sets.rows.map(v => {
                    return (
                      <tr key={v.id}>
                        {/* <td>{v.id}</td> */}
                        <td>{v.title}</td>
                        <td>{v.riding_list}</td>
                        <td>{v.rel_riding_field}</td>
                        <td>{v.contact_list}</td>
                        <td>{v.rel_contact_field}</td>
                        <td>{__t(v.status)}</td>
                        <td>{Moment(v.created_at).format("DD-MMM-YYYY HH:mm:ss")}</td>

                        <td>
                          <Link
                            className="btn btn-sm btn-primary mb-2"
                            to={{
                              pathname: `/contacts/dataset/update/` + v.id
                            }}
                          >
                            <i className="fa fa-pencil" />
                          </Link>
                          <button
                            className="btn btn-sm btn-primary mb-2"
                            onClick={() => {
                              this.deleteData(v.id);
                            }}
                          >
                            {" "}
                            <i className="fa fa-trash" />
                          </button>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            ) : (
                <div>
                  <div className="">{__t("no_record_found")}!</div>
                </div>
              )}

            {this.pagination(this.state.page, 10, data_sets.count, this.getDataSet)}
          </div>
        </div>
      </React.Fragment>
    );
  }
  pagination = (page, sizePerPage, total, updateTable) => {
    if (total <= 0) {
      return "";
    }
    let total_page = Math.ceil(total / sizePerPage);
    let pages = [];
    for (let i = 1; i <= total_page; i++) {
      pages.push(
        <li key={i} className={page == i ? "page-item active" : "page-item"}>
          <a
            className="page-link"
            onClick={e => {
              updateTable(i);
              e.preventDefault();
            }}
          >
            {i}
          </a>
        </li>
      );
    }
    return (
      <nav aria-label="Page navigation example">
        <ul className="pagination justify-content-end">
          <li className={page <= 1 ? "page-item disabled" : "page-item"} disabled={page <= 1 ? "disabled" : ""}>
            <a
              className="page-link"
              onClick={e => {
                if (page > 1) {
                  updateTable(page - 1);
                }
                e.preventDefault();
              }}
            >
              {__t("back")}
            </a>
          </li>
          {pages}

          <li className={page == total_page ? "page-item disabled" : "page-item"} disabled={page == total_page ? "disabled" : ""}>
            <a
              className="page-link"
              onClick={e => {
                if (page < total_page) {
                  updateTable(page + 1);
                }
                e.preventDefault();
              }}
            >
              {__t("next")}
            </a>
          </li>
        </ul>
      </nav>
    );
  };
}

export default ContactDataSet;
