export const fr = {
  dashboard: "Tableau de bord",
  search: "Chercher",
  clients: "Les clients",
  create_client: "Create Clients",
  update_client: "Update Clients",
  user: "Membre du personnel",
  create_user: "Create User",
  update_user: "Update User",
  role: "Rôle",
  log_out: "Connectez - Out",
  subscription: "Abonnement",
  paymentgateway: "Passerelle de paiement",
  reseller: "Revendeur",
  contact_persons: "Persions de contact",
  message_preview_button_label: "Message preview button label"
};
