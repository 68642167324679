import React from "react";
import { Modal, ModalHeader, ModalBody, Button, ModalFooter } from "reactstrap";
import { __t } from "../../locale/language";
import DOMPurify from "dompurify";
class ViewModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      modal: false
    };
  }

  componentWillReceiveProps({ isOpen }) {
    this.setState({ modal: isOpen });
  }

  render() {
    var reply_email = this.props.data ? JSON.parse(this.props.data.widget_json) : [];
    var message_body = this.props.data ? this.props.data.email_body : "";
    return (
      <Modal isOpen={this.state.modal}>
        <ModalHeader>{__t("contact_details")}</ModalHeader>
        <ModalBody className="media-modal">
          <div className="message_box">
            <p className="message_box_wrapp">
              <div
                dangerouslySetInnerHTML={{
                  // __html: message_body.split("<img src=rr onerror=prompt").join('')
                  __html: DOMPurify.sanitize(message_body).replace(/<img[^>"']*((("[^"]*")|('[^']*'))[^"'>]*)*>/g,"")
                }}
              />
            </p>
            {Object.entries(reply_email).map(([key, val]) => {

              if (val && val.name != "privacy_policy" && val.name != "message") {
                if (val.name == "campaign_update") {
                  return (
                    <div key={key}>
                      <p >
                        <strong>{val.label}</strong> :{val.value == true ? __t("yes") : __t("no")}
                      </p>
                    </div>
                  );
                } else {
                  return (
                    <div key={key}>
                      <p >
                        <strong>{val.label}</strong> :{this.removeTags(DOMPurify.sanitize(val.value))}
                      </p>
                    </div>
                  );
                }
              }
            })}
          </div>
        </ModalBody>
        <ModalFooter>
          <Button
            color="secondary"
            onClick={() => {
              this.props.Toggle(this.props.data);
            }}
          >
            {__t("close")}
          </Button>
        </ModalFooter>
      </Modal>
    );
  }

   removeTags=(str)=> {
    if ((str===null) || (str===''))
        return false;
    else
        str = str.toString();
          
    // Regular expression to identify HTML tags in
    // the input string. Replacing the identified
    // HTML tag with a null string.
    return str.replace( /(<([^>]+)>)/ig, '');
}
}

export default ViewModal;
