import React from "react";
import { eng } from "./English";
import { fr } from "./French";

const Language = {
  English: eng,
  French: fr
};
export const __t = key => {
  var lang = null;
  var lan = localStorage.getItem("language");
  if (typeof lan !== "undefined" && lan !== null) {
    lang = Language[lan];
  } else {
    lang = eng;
  }
  if (
    lang &&
    !Array.isArray(key) &&
    typeof key == "string" &&
    lang[key.toLowerCase()]
  ) {
    return lang[key.toLowerCase()];
  } else {
    return key;
  }
};
export const __currency = key => {
  return "$";
};
export const __language = changeFun => {
  var list = [];
  for (var key in Language) {
    list.push(key);
  }
  var active = localStorage.getItem("language");

  return (
    <div>
      <label className="control-label">{__t("language")}:&nbsp; </label>
      <select onChange={changeFun} value={active}>
        {list.map(function(object, i) {
          return (
            <option key={i} value={object}>
              {object}
            </option>
          );
        })}
      </select>
    </div>
  );
};
