import React from "react";
import $ from "jquery";
class ChangePassword extends React.Component {
  render() {
    return (
      <div className="middle-box text-center loginscreen animated fadeInDown">
        <div>
          <div>
            <h1 className="logo-name">IN+</h1>
          </div>
          <h3>Welcome to IN+</h3>

          <p>Login in. To see it in action.</p>

          <div className="form-group">
            <input
              type="email"
              className="form-control"
              placeholder="Username"
              required=""
            />
          </div>

          <div className="form-group">
            <input
              type="password"
              className="form-control"
              placeholder="Password"
              required=""
            />
          </div>
          <button
            type="submit"
            className="btn btn-primary block full-width m-b"
            onClick={this.login}
          >
            Login
          </button>

          <a href="#">
            <small>Forgot password?</small>
          </a>

          <p className="m-t">
            {" "}
            <small>
              Inspinia we app framework base on Bootstrap 3 &copy; 2014
            </small>{" "}
          </p>
        </div>
      </div>
    );
  }

  componentDidMount() {
    $("body").addClass("gray-bg");
  }

  componentWillUnmount() {
    $("body").removeClass("gray-bg");
  }
}

export default ChangePassword;
