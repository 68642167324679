import React, { Component } from "react";
import { Tab } from "react-bootstrap";
import { Link } from "react-router-dom";
import { __t } from "../../locale/language";
import Utility from "../common/Utility";
class Tab3ForMyClient extends Component {
  constructor(props) {
    super(props);
    this.state = {
      form: {},
      token: localStorage.getItem("token"),
      client_id: localStorage.getItem("client_id"),
    };
  }
  componentDidMount() {
    this.getSmtpDetails();
  }
  getSmtpDetails = async () => {
    let request = await Utility.ExecuteData(
      "getSmtpSettings",
      this.state.token,
      this.state.form
    );
    if (request.code == 200) {
      let response = request.data;
      let { smtp_username, smtp_host, smpt_port, client_override } = {
        ...response,
      };
      let formdata = {
        sender_email: smtp_username,
        host: smtp_host,
        port: smpt_port,
        client_overwrite: client_override,
      };

      this.setState({ form: formdata });
    }
  };
  validate = () => {
    let { form } = this.state;
    let isValid = true;
    let fields = Object.keys(form);
    console.log(form);
    fields.map((fieldname) => {
      //this only occurs if someone deletes the data in the field
      if (("" + form[fieldname]).trim() == "") {
        alert(__t("all_fields_are_mandatory"));
        isValid = false;
      }
    });

    if (fields.length < 1) {
      alert(__t("nothing_to_update"));
      isValid = false;
    }

    return isValid;
  };
  checksmtp = async () => {
    if (this.validate()) {
      let request = await Utility.ExecuteData(
        "checkSmtpSettings",
        this.state.token,
        this.state.form
      );
      if (request.code == 200) {
        let response = request.data;
        if (response.success == 1) {
          alert(__t("valid_smtp_configurations"));
          this.setState({ verified: true });
        }
      } else {
        if (request.code == 400 && request.data.error) {
          alert("SMTP Authentication failed");
        }
        // alert(__t("invalid_smtp_configurations"))
      }
    }
  };
  update = async () => {
    if (this.validate()) {
      let request = await Utility.ExecuteData(
        "updateSmtpSettings",
        this.state.token,
        this.state.form
      );
      if (request.code == 200) {
        let response = request.data;
        alert(__t("smtp_settings_updated"));
      }
    }
  };
  handleChange = (e) => {
    let form = this.state.form;
    if (e.target.type == "checkbox") {
      let isChecked = e.target.checked;
      form[e.target.name] = isChecked ? "1" : "0";

      this.setState(form);
    } else {
      form[e.target.name] = e.target.value;
      this.setState({ verified: false, form }); //something changed then we first need to verify the smtp
    }
  };

  render() {
    return (
      <Tab eventKey={3} title="SMTP Detail For Client" disabled>
        <div className="row form-group">
          <div className="col-md-6 col-sm-6 col-xs-12">
            <label className="control-label">{__t("sender_email")}</label>
            <input
              type="email"
              name="sender_email"
              className="form-control"
              placeholder="Sender Email"
              value={this.state["form"].sender_email}
              onChange={this.handleChange}
            />
            <label id="-error" className="error">
              {""}
            </label>
          </div>

          <div className="col-md-6 col-sm-6 col-xs-12">
            <label className="control-label">{__t("password")}</label>
            <input
              type="password"
              name="password"
              autoComplete="new-password"
              className="form-control"
              placeholder="Password"
              value={this.state["form"].password}
              onChange={this.handleChange}
            />
            <label id="-error" className="error">
              {""}
            </label>
          </div>

          <div className="col-md-6 col-sm-6 col-xs-12">
            <label className="control-label">{__t("host")}</label>
            <input
              type="text"
              name="host"
              autoComplete="off"
              className="form-control"
              placeholder="Host"
              value={this.state["form"].host}
              onChange={this.handleChange}
            />
            <label id="-error" className="error">
              {""}
            </label>
          </div>

          <div className="col-md-6 col-sm-6 col-xs-12">
            <label className="control-label">{__t("port")}</label>
            <input
              type="text"
              name="port"
              autoComplete="off"
              className="form-control"
              placeholder="Port"
              value={this.state["form"].port}
              onChange={this.handleChange}
            />
            <label id="-error" className="error">
              {""}
            </label>
          </div>
        </div>
        <div className="row form-check">
          <div className="col-md-6 col-sm-6 col-xs-12">
            <input
              type="checkbox"
              className="form-check-input"
              checked={
                this.state["form"].client_overwrite == "1" ? true : false
              }
              id="exampleCheck1"
              onClick={this.handleChange}
              name={"client_overwrite"}
            />
            <label className="form-check-label" htmlFor="exampleCheck1">
              {__t("overwrite_client_settings")}
            </label>
          </div>
        </div>
        <br />
        <br />

        <div className="row form-group">
          <div className="col-md-12">
            <button
              className={
                this.state.verified
                  ? "btn btn-primary"
                  : "btn btn-primary disabled "
              }
              onClick={this.state.verified ? this.update : ""}
              type="submit"
            >
              {__t("submit")}
            </button>
            &nbsp;&nbsp;
            <button className={"btn btn-primary"} onClick={this.checksmtp}>
              {__t("test_smtp")}
            </button>
            &nbsp;&nbsp;
          </div>
        </div>
      </Tab>
    );
  }
}

export default Tab3ForMyClient;
