import React from "react";
import { Redirect, Link } from "react-router-dom";
import { Tabs, Tab } from "react-bootstrap";
import Utility from "../common/Utility";
import Tab1 from "./Tab1";
import Tab2 from "./Tab2";
import Tab3 from "./Tab3";
import bcrypt from "bcryptjs";
import AlertDelete from "../common/AlertDelete";
import Can from "../common/AccessControl";
import { __t } from "../../locale/language";
import Moment from "moment";
import $ from "jquery";
class ClientUpdate extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      redirect: false,
      errors: {},
      name: "",
      subdomain: "",
      title: "",
      status: "",
      sender_email: "",
      sender_name: "",
      username: "",
      password: "",
      port: "",
      host: "",
      first_name: "",
      last_name: "",
      user_name: "",
      user_contact: "",
      user_password: "",
      confirm_password: "",
      user_email: "",
      user_type: "client",
      user_status: "",
      token: localStorage.getItem("token"),
      client_id: localStorage.getItem("client_id"),
      key: 1,
      subscription_plan: [],
      plan: 0,
      // plan: "",
      subs_status: "",
      active_domain: "",
      old_subdomain: "",
      logData: "",
      clientid: "",
      subs_plan_id: "",
      verified: 0,
      verifiedemail: "off"

    };
    this.create = this.create.bind(this);
    this.handleSelect = this.handleSelect.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.nextTab = this.nextTab.bind(this);
    this.checkDomain = this.checkDomain.bind(this);
    this.createHash = this.createHash.bind(this);
    this.getfeatures = this.getfeatures.bind(this);
    this.getLogData = this.getLogData.bind(this);
  }
  componentDidMount() {
    if (typeof this.props.location.state != "undefined" && this.props.location.state.id) {
      $("#title").text(__t("edit_client"));

      $("#title2").text(__t("edit_client"));
    }

    if (
      //when update
      this.props.location.state &&
      typeof this.props.location.state !== "undefined"
    ) {
      this.getData();
      this.getUserData();
      this.findSubscriptionPlan();
      this.getLogData();
    }
    // else{
    //   this.getsmtp();
    // }
    this.getSubscriptionPlan();


  }

  getsmtp = () => {
    var p_id = localStorage.getItem("client_id");

    Utility.ExecuteData("getsmtp", this.token, {
      id: p_id
    }).then((data) => {
      this.setState({
        host: data.data[0].host,
        sender_email: data.data[0].sender_email,
        password: data.data[0].password,
        port: data.data[0].port,
        username: data.data[0].username
      })
    });
  }

  getUserData = () => {
    var id = this.props.location.state.id;
    Utility.ExecuteData("finduserdata", this.token, {
      id: id
    }).then(data => {
      this.setState({
        first_name: data.data.first_name,
        last_name: data.data.last_name,
        user_name: data.data.user_name,
        user_contact: data.data.cell_number,
        email: data.data.email,
        user_status: data.data.status,
      });

    });
  }
  getData() {
    var id = this.props.location.state.id;
    Utility.ExecuteData("findclient", this.token, {
      id: id
    }).then(data => {
      this.findSubscriptionPlan(data);
    });
  }
  getSubscriptionPlan = () => {
    var cli_data = "";
    if (typeof this.props.location.state != "undefined" && this.props.location.state.id) {
      cli_data = this.props.location.state.id;
    }
    Utility.ExecuteData("findsubscriptionbyType", this.token, {
      type: "client",
      client_id: this.state.client_id,
      client_data_id: cli_data
    }).then(data => {
      if (data) {
        this.setState({ subscription_plan: data.data });
      }
    });
  };
  getfeatures(event) {
    var current_status = event.target[event.target.selectedIndex].getAttribute("data-status");
    if (current_status == "inactive") {
      alert("You cannot select a inactive plan");
      return;
    }
    var val = event.target.value;
    Utility.ExecuteData("getfeaturesbyplan", this.token, {
      id: event.target.value
    }).then(data => {
      if (data.data.length) {
        this.setState({ plan: val, active_domain: "active" });
      } else {
        this.setState({ plan: val, active_domain: "inactive" });
      }
    });
  }
  findSubscriptionPlan = data => {
    var id = this.props.location.state.id;
    Utility.ExecuteData("clientsubscription", this.token, {
      id: id
    }).then(result => {
      if (data) {
        var sub = data.data.subdomain.split(".");
        var stateData = {
          name: data.data.name,
          subdomain: sub[0],
          old_subdomain: data.data.subdomain,
          title: data.data.title,
          status: data.data.status,
          sender_email: data.data.sender_email,
          sender_name: data.data.sender_name,
          username: data.data.username,
          port: data.data.port,
          host: data.data.host,
          plan: result.data[0] ? (typeof result.data[0].subscription_id !== "undefined" && result.data[0].subscription_id != "" ? result.data[0].subscription_id : "") : 0,
          subs_status: result.data[0] ? (typeof result.data[0].status !== "undefined" ? result.data[0].status : "") : 0,
          subs_plan_id: result.data[0] ? (typeof result.data[0].subscription_id !== "undefined" ? result.data[0].subscription_id : "") : 0,
          verified: result.data[0]["verified"]
        };
        this.setState(stateData);
      }
    });
  };
  checkDomain(key) {
    if (this.state.active_domain == "active") {
      let errors = {};
      let formIsValid = true;
      if (this.state.subdomain) {
        if (this.state.subdomain !== this.state.old_subdomain) {
          let subdomain = this.state.subdomain;
          var host = this.props.userPermissions.subdomain;
          var domain = "";
          if (this.state.old_subdomain !== "") {
            let d = this.state.old_subdomain.split(".")
            if (d.length == 3) {
              domain = "." + d[1] + "." + d[2];
            } else {
              domain = host.replace("www", "");
            }
          } else {
            domain = host.replace("www", "");
          }
          Utility.ExecuteData("checkdomain", this.state.token, {
            subdomain: subdomain + "" + domain
          }).then(response => {
            if (response.code === 200) {
              formIsValid = false;
              errors["subdomain"] = __t("subdomain_already_exists");
              this.setState({ errors: errors });
            } else {
              formIsValid = true;
              this.setState({ key: 2, errors: {} }, () => { });
            }
          });
        } else {
          formIsValid = true;
          this.setState({ key: 2, errors: {} }, () => { });
        }
      } else {
        formIsValid = false;
        errors["subdomain"] = __t("chk_sub_domain");
        this.setState({ errors: errors });
        return formIsValid;
      }
    } else {
      this.setState({ key: 2, errors: {} }, () => {
      });
    }
  }
  createHash(pwd) {
    const saltRounds = 10;
    return new Promise((resolve, reject) => {
      bcrypt.genSalt(saltRounds, function (err, salt) {
        if (err) return err;
        bcrypt.hash(pwd, salt, function (err, hash) {
          // Store hash in your password DB.
          if (err) {
            reject(err);
          } else {
            resolve(hash);
          }
        });
      });
    });
  }
  handleChange(event) {

    if (event.target.name === "subdomain") {
      this.setState({
        [event.target.name]: event.target.value
      });
    } else if (event.target.name == "verified") {
      // for check box

      this.setState({
        [event.target.name]: event.target.checked ? 1 : 0
      })

      // end check box
    } else if (this.props.location.state && typeof this.props.location.state !== "undefined" && event.target.name == "plan") {
      if (window.confirm("Are you sure want to change the plan")) {
        this.setState({ [event.target.name]: event.target.value });
      }
    } else {
      this.setState({ [event.target.name]: event.target.value });
    }
  }
  checkValidation(key) {
    let fields = this.state;
    let errors = {};
    let formIsValid = true;
    var checkflag;

    if (this.props.location.state && typeof this.props.location.state !== "undefined") {
      checkflag = "update";

      //   $("#tab_2").removeAttr("disabled","")
    } else {
      checkflag = "create";
    }
    if (key == 1) {
      if (!fields["name"]) {
        formIsValid = false;
        errors["name"] = __t("name_cannot_be_empty");
      }
      if (!fields["title"]) {
        formIsValid = false;
        errors["title"] = __t("title_cannot_be_empty");
      }
      if (!fields["status"]) {
        formIsValid = false;
        errors["status"] = __t("please_select_a_status");
      }
      if (!fields["plan"] || fields["plan"] == 0) {
        formIsValid = false;
        errors["plan"] = __t("please_select_subscription_plan");
      }
      if (!fields["subs_status"]) {
        formIsValid = false;
        errors["subs_status"] = __t("please_select_subscription_status");
      }
    }

    if (key == 2) {
      if (!this.props.location.state && typeof this.props.location.state === "undefined") {
        if (!fields["password"]) {
          formIsValid = false;
          errors["password"] = __t("password_cannot_be_empty");
        }
      }
      if (!fields["sender_name"]) {
        formIsValid = false;
        errors["sender_name"] = __t("sender_name_cannot_be_empty");
      }
      // if (!fields["username"]) {
      //   formIsValid = false;
      //   errors["username"] = __t("user_name_cannot_be_empty");
      // }
      if (!fields["host"]) {
        formIsValid = false;
        errors["host"] = __t("host_cannot_be_empty");
      }
      if (!fields["port"]) {
        formIsValid = false;
        errors["port"] = __t("port_cannot_be_empty");
      }
      if (!fields["sender_email"]) {
        formIsValid = false;
        errors["sender_email"] = __t("email_cannot_be_empty");
      } else if (typeof fields["sender_email"] !== "undefined") {
        let lastAtPos = fields["sender_email"].lastIndexOf("@");
        let lastDotPos = fields["sender_email"].lastIndexOf(".");
        if (!(lastAtPos < lastDotPos && lastAtPos > 0 && fields["sender_email"].indexOf("@@") == -1 && lastDotPos > 2 && fields["sender_email"].length - lastDotPos > 2)) {
          formIsValid = false;
          errors["sender_email"] = __t("email_is_not_valid");
        }
      }
    }
    if (key == 3) {
      if (!fields["first_name"]) {
        formIsValid = false;
        errors["first_name"] = __t("first_name_cannot_be_empty");
      }
      if (!fields["user_name"]) {
        formIsValid = false;
        errors["user_name"] = __t("user_name_cannot_be_empty");
      }
      if (!fields["user_status"]) {
        formIsValid = false;
        errors["user_status"] = __t("please_select_a_status");
      }
      if (checkflag == "update") {
        if (!fields["email"]) {
          formIsValid = false;
          errors["user_email"] = __t("email_cannot_be_empty");
        } else if (typeof fields["email"] !== "undefined") {
          let lastAtPos = fields["email"].lastIndexOf("@");
          let lastDotPos = fields["email"].lastIndexOf(".");
          if (!(lastAtPos < lastDotPos && lastAtPos > 0 && fields["email"].indexOf("@@") == -1 && lastDotPos > 2 && fields["email"].length - lastDotPos > 2)) {
            formIsValid = false;
            errors["user_email"] = __t("email_is_not_valid");
          }
        }
      }

      if (checkflag == "create") {
        console.log("dvbhub")
        if (!fields["user_email"]) {
          console.log("dvbhub")
          formIsValid = false;
          errors["user_email"] = __t("email_cannot_be_empty");
        } else if (typeof fields["user_email"] !== "undefined") {
          let lastAtPos = fields["user_email"].lastIndexOf("@");
          let lastDotPos = fields["user_email"].lastIndexOf(".");
          if (!(lastAtPos < lastDotPos && lastAtPos > 0 && fields["user_email"].indexOf("@@") == -1 && lastDotPos > 2 && fields["user_email"].length - lastDotPos > 2)) {
            formIsValid = false;
            errors["user_email"] = __t("email_is_not_valid");
          }
        }
        if (!fields["confirm_password"]) {
          formIsValid = false;
          errors["confirm_password"] = __t("confirm_password_cannot_be_empty");
        } else if (fields["user_password"] !== fields["confirm_password"]) {
          formIsValid = false;
          errors["confirm_password"] = __t("password_and_confirm_password_are_not_matched");
        }
        //password
        if (!fields["user_password"]) {
          formIsValid = false;
          errors["user_password"] = __t("password_cannot_be_empty");
        }
      }
    }
    this.setState({ errors: errors });
    return formIsValid;
  }
  capitalize = str => {
    return str.charAt(0).toUpperCase() + str.slice(1);
  };
  handleSelect(key) {
    this.setState({ key });

  }
  nextTab(key) {
    // return false
    if (key == 2) {
      if (this.checkValidation(1)) {
        this.checkDomain(key);
      }
    } else if (key == 3) {
      if (this.checkValidation(2)) {
        this.setState({ key: key });
      }
    }
  }
  token = localStorage.getItem("token");
  create() {
    var host = this.props.userPermissions.subdomain;
    var domain = "";
    if (this.checkValidation(this.state.key)) {
      if (
        // client update
        this.props.location.state &&
        typeof this.props.location.state !== "undefined"
      ) {
        if (this.state.old_subdomain !== "") {
          let d = this.state.old_subdomain.split(".")
          if (d.length == 3 && typeof d[1] !== "undefined" && typeof d[2] !== "undefined" && d[1] !== "undefined" && d[2] !== "undefined") {
            domain = "." + d[1] + "." + d[2];
          } else {
            domain = host.replace("www", "");
          }
        } else {
          domain = host.replace("www", "");
        }

        console.log(this.state);
        if (this.state.password) {

          this.setState({ id: this.props.location.state.id, subdomain: this.state.subdomain + domain }, () => {
            Utility.ExecuteData("clientupdate", this.token, this.state).then(responseJson => {
              if (responseJson.code === 200) {
                this.setState({ redirect: true });
              } else {
                alert("Something went wront. Try Again.");
              }
            })
              .catch(error => {
                this.setState({ errors: true });
              });
          });
        } else {
          // for client update when password not change
          this.setState({ id: this.props.location.state.id, subdomain: this.state.subdomain + domain }, () => {
            Utility.ExecuteData("clientupdate", this.token, this.state)
              .then(responseJson => {
                if (responseJson.code === 200) {
                  this.setState({ redirect: true });
                } else {
                  alert("Something went wront. Try Again.");
                }
              })
              .catch(error => {
                this.setState({ errors: true });
              });
          });
        }
      } else {

        this.createHash(this.state.user_password)
          .then(userhash => {
            // domain = host.replace("www", "");
            this.setState({ user_password: userhash, subdomain: this.state.subdomain + domain });
          })
          .then(() => {
            Utility.ExecuteData("clientcreate", this.token, this.state)
              .then(responseJson => {
                if (responseJson.code === 200) {
                  this.setState({ redirect: true });
                } else if (responseJson.success == "email already exist") {
                  let formIsValid = false;
                  let errors = {};
                  errors["user_email"] = __t("email_exists");
                  this.setState({ errors: errors });
                } else {
                  alert(__t("something_went_wrong_please_try_again"));
                }
              })
              .catch(error => {
                this.setState({ errors: true });
              });
          });

      }
    }
  }
  getLogData() {
    var id = this.props.location.state.id;
    if (this.props.location.state && typeof this.props.location.state !== "undefined") {
      this.setState({
        clientid: this.props.location.state.id
      });
    }

    Utility.ExecuteData("getLogData", this.token, {
      id: id
    })
      .then(responseJson => {
        if (responseJson.code === 200) {
          this.setState({
            logData: responseJson.data
          });
        } else {
          alert("Something went wront. Try Again.");
        }
      })
      .catch(error => {
        this.setState({ errors: true });
      });
  }
  render() {
    var opts = {};
    var flag = "";
    if (this.props.location.state && typeof this.props.location.state !== "undefined") {
      flag = "update";
      opts["readOnly"] = "readOnly";
    } else {
      flag = "create";
    }
    const { redirect } = this.state;
    if (redirect) {
      return <Redirect push to="/client/" />;
    }
    return (
      <Can I={["view-client"]} userPermissions={this.props.userPermissions.userPermissions}>
        <div className="row wrapper border-bottom white-bg page-heading">
          <div className="col-lg-10">
            <h2 style={{ marginTop: 10 }}>{__t("clients")}</h2>
            <ol className="breadcrumb">
              <li className="breadcrumb-item">
                <Link to="/">{__t("dashboard")}</Link>
              </li>
              <li className="breadcrumb-item">
                <Link to="/client">{__t("client")}</Link>
              </li>
              <li className="breadcrumb-item active">
                <strong id="title"> {__t("create_client")}</strong>
              </li>
            </ol>
          </div>
        </div>
        <div className="row">
          <div className="col-md-12">
            <div className="ibox float-e-margins">
              <div className="ibox-title">
                <h5 id="title2">{__t("create_client")}</h5>
              </div>
              <div className="ibox-content">
                <div className="form-horizontal">
                  <Tabs activeKey={this.state.key} onSelect={this.handleSelect} animation={false} id="noanim-tab-example">
                    <Tab eventKey={1} title="Server Detail">
                      <Tab1 flag={flag} tab1_data={this.state} checkDomain={this.checkDomain} subDomain={this.props.userPermissions.subdomain} handleChange={this.handleChange} getfeatures={this.getfeatures} />
                    </Tab>
                    {
                      flag == "create" ?
                        <Tab eventKey={2} title="SMTP Detail" disabled>
                          <Tab2 flag={flag} capitalize={this.capitalize} create={this.create} tab2_data={this.state} handleChange={this.handleChange} handleValidation={this.handleValidation} />
                        </Tab> :
                        <Tab eventKey={2} title="SMTP Detail">
                          <Tab2 flag={flag} capitalize={this.capitalize} create={this.create} tab2_data={this.state} handleChange={this.handleChange} handleValidation={this.handleValidation} />
                        </Tab>
                    }
                    {/* <Tab eventKey={2} title="SMTP Detail" disabled>
                      <Tab2 flag={flag} capitalize={this.capitalize} create={this.create} tab2_data={this.state} handleChange={this.handleChange} handleValidation={this.handleValidation} />
                    </Tab> */}
                    {/* {flag == "create" ? ( */}
                    <Tab eventKey={3} title="User Detail" >
                      <Tab3 flag={flag} capitalize={this.capitalize} create={this.create} tab3_data={this.state} handleChange={this.handleChange} />
                    </Tab>
                    {/* // ) : (
                    //     ""
                    //   )} */}
                    {this.state.key === 1 && (
                      <div>
                        <button onClick={() => this.nextTab(2)} value="next" className="form-group btn btn-primary btn btn-primary">
                          {__t("next")}>>
                        </button>
                        <div className="row">
                          {this.props.location.state && typeof this.props.location.state !== "undefined" ? (
                            <div className="col-lg-6">
                              <div className="ibox float-e-margins">
                                <div className="ibox-title">
                                  <h5>{__t("logs")}</h5>
                                </div>
                                <div className="ibox-content">
                                  <table>
                                    <thead>
                                      <tr>
                                        <th>{__t("log")}</th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      {this.state.logData
                                        ? this.state.logData.map((val, key) => {
                                          return (
                                            <tr key={key}>
                                              <td>
                                                {val.log} {val.created_by} {__t("on")}
                                              </td>
                                              <td>{Moment(val.created_at).format("DD-MMM-YYYY HH:mm:ss")}</td>
                                              <td className="text-navy"> </td>
                                            </tr>
                                          );
                                        })
                                        : null}
                                    </tbody>
                                  </table>
                                </div>
                              </div>
                            </div>
                          ) : (
                              ""
                            )}
                        </div>
                      </div>
                    )}
                    {
                      this.state.key === 2 && (
                        <div>

                          <button onClick={() => this.getsmtp()} className="form-group btn btn-primary btn btn-primary">
                            {__t("set_default_smtp")}
                          </button>&nbsp;&nbsp;
                          <button onClick={() => this.nextTab(3)} value="next" className="form-group btn btn-primary btn btn-primary">
                            {__t("next")} >>
                          </button>
                        </div>
                      )
                    }
                  </Tabs>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Can>
    );
  }
}

export default ClientUpdate;
