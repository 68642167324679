import React, { useEffect, useState } from "react";
import Utility from "../common/Utility";
import TwoFA from "../common/TwoFA";

const TwoFactorAuthentication = () => {
  const [user2FA, setUser2FA] = useState(false);
  const [siteWide2FA, setSiteWide2FA] = useState(false);
  const [resultData, setResultData] = useState({});

  const handleUser2FAChange = (e) => {
    setUser2FA(e.target.value === "true");
  };

  const handleSiteWide2FAChange = (e) => {
    setSiteWide2FA(e.target.value === "true");
  };

  // const getTwoFAConfig = async () => {
  //   let res = await Utility.GetData("get2faconfig", {});

  //   if (res && res.code == 200) {
  //     setResultData(res.data);
  //     let result = res.data;
  //     setUser2FA(result.enable_user == 1 ? true : false);
  //     setSiteWide2FA(result.force_site_wide == 1 ? true : false);
  //   }
  // };
  const getTwoFAConfig = async () => {
    let res = await Utility.GetData("get2faconfig", {});

    if (res) {
      if (res.code === 200) {
        setResultData(res.data);
        let result = res.data;
        if (result) {
          setUser2FA(result.enable_user === "1" ? true : false);
          setSiteWide2FA(result.force_site_wide === "1" ? true : false);
        }
      } else {
        setUser2FA(false);
        setSiteWide2FA(false);
        // Handle the case where the response has a different status code (not 200).
        // You can show an error message or take appropriate action here.
      }
    } else {
      setUser2FA(false);
      setSiteWide2FA(false);
      // Handle the case where 'res' is null or undefined.
      // You can show an error message or take appropriate action here.
    }
  };

  useEffect(() => {
    getTwoFAConfig();
  }, []);

  const updateConfig = async () => {
    let obj = {
      enable_user: user2FA,
      force_site_wide: siteWide2FA,
      id: resultData.id,
    };

    console.log(obj);
    let res = await Utility.ExecuteData("update2faconfig", "", obj);
  };

  return (
    <div>
      <div style={{ display: "flex", marginBottom: "10px" }}>
        <p style={{ marginRight: "100px" }}>Enable User 2FA:</p>
        <label style={{ marginRight: "100px" }}>
          <input
            style={{ marginRight: "2px" }}
            type="radio"
            name="user2FA"
            value="true"
            checked={user2FA}
            onChange={handleUser2FAChange}
          />
          ON
        </label>
        <label>
          <input
            style={{ marginRight: "2px" }}
            type="radio"
            name="user2FA"
            value="false"
            checked={!user2FA}
            onChange={handleUser2FAChange}
          />
          OFF
        </label>
      </div>

      <div style={{ display: "flex", marginBottom: "10px" }}>
        <p style={{ marginRight: "77px" }}>Force 2FA Site Wide:</p>
        <label style={{ marginRight: "100px" }}>
          <input
            style={{ marginRight: "2px" }}
            type="radio"
            name="siteWide2FA"
            value="true"
            checked={siteWide2FA}
            onChange={handleSiteWide2FAChange}
          />
          ON
        </label>
        <label>
          <input
            style={{ marginRight: "2px" }}
            type="radio"
            name="siteWide2FA"
            value="false"
            checked={!siteWide2FA}
            onChange={handleSiteWide2FAChange}
          />
          OFF
        </label>
      </div>

      <div style={{ marginTop: "20px" }}>
        <style>{`
        .fade:not(.show) {
          opacity: 1;
        }
      `}</style>
      </div>
      <div>
        <button
          className="btn btn-primary"
          type="submit"
          onClick={() => {
            updateConfig();
          }}
        >
          Update
        </button>
      </div>
      <TwoFA />
    </div>
  );
};

export default TwoFactorAuthentication;
