import React from "react";
import { __t } from "../../locale/language";

const AdressComponents = (props) => {
  let AdressComponentsArr = {
    street_number: "Street number",
    route: "Street",
    neighborhood: "Neighborhood",
    locality: "City/Town",
    administrative_area_level_2: "Municipality/County",
    administrative_area_level_1: "Province/Territory",
    country: "Country",
    postal_code: "Postal Code",
    address: "Address",
    constituency: "Constituency",
  };
  return Object.keys(AdressComponentsArr).map((el, key) => {
    return (
      <button
        type="button"
        className={"embed-btn"}
        onClick={() => {
          props.handleClick(el);
        }}
        data-for="soclose"
        data-tip={AdressComponentsArr[el]}
        key={key}
      >
        {__t(AdressComponentsArr[el])}
      </button>
    );
  });
};

export default AdressComponents;
