import $ from 'jquery';
import { __t } from "../../locale/language";
import React from 'react';
export default class AlertSuccess extends React.Component {
    constructor(props){
        super(props)
    }
    
    render() {
        return (
         
            <div className="alert_oerlay">

                <div className="alert_box success_back">
                    <i className="fa fa-check success_txt fa_alert"></i>
                    <h3> {this.props.message} </h3>
                    <button className="btn btn-success success_btn" onClick={this.props.ok}>{__t("ok")}</button>
                </div>
            </div>

        )
    }
}