import React, { Component } from "react";
import { NavLink, Location } from "react-router-dom";
import { __t } from "../../locale/language";
import Can from "./AccessControl";
import $ from "jquery";
import { AccessControlFunc } from "./AccessControl2";

class Navigation extends Component {
  constructor(props) {
    super(props);
    this.user_type = this.props.userPermissions.user_type;
    this.userPermissions = this.props.userPermissions.userPermissions;
    this.wholePermissions = this.props.userPermissions;
  }
  componentDidMount() {
    const { menu } = this.refs;
    $(menu).metisMenu();
  }

  activeRoute(routeName) {
    return this.props.location.pathname.indexOf(routeName) > -1 ? "active" : "";
  }

  secondLevelActive(routeName) {
    return this.props.location.pathname.indexOf(routeName) > -1 ? "nav nav-second-level collapse in" : "nav nav-second-level collapse";
  }

  render() {
    return (
      <nav className="navbar-default navbar-static-side " role="navigation">
        <ul className="nav metismenu flex-column" id="side-menu" ref="menu">
          <li className="nav-item nav-header">
            <div className="dropdown profile-element">
              {" "}
              <span />
              {/* <a data-toggle="dropdown" className="dropdown-toggle" href="#"> */}
              <a href="/">
                <span className="clear">
                  {" "}
                  <span className="block m-t-xs">
                    {" "}
                    <strong className="font-bold">
                      {this.props.userPermissions.first_name} {this.props.userPermissions.last_name}
                    </strong>
                  </span>{" "}
                  <span className="text-muted text-xs block">
                    {__t(this.user_type)}
                    <b className="caret" />
                  </span>{" "}
                </span>{" "}
              </a>
              {/* <ul className="dropdown-menu animated fadeInRight m-t-xs">
                <li>
                  <a href="#"> Logout</a>
                </li>
              </ul> */}
            </div>
            <div className="logo-element">PP</div>
          </li>
          <li className={this.props.location.pathname === "/" ? "nav-item active" : "nav-item"}>
            <NavLink to="/" className="nav-link">
              <i className="fa fa-th-large" /> <span className="nav-label">{__t("dashboard")}</span>
            </NavLink>
          </li>
          <Can I={["view-user"]} userPermissions={this.userPermissions}>
            <li className={this.activeRoute("/user")}>
              <NavLink to="/user" className="nav-link">
                <i className="fa fa-user" /> <span className="nav-label">{__t("user")}</span>
              </NavLink>
            </li>
          </Can>

          {this.user_type != "client" ? (
            <Can I={["view-client"]} userPermissions={this.userPermissions}>
              <li className="nav-item" className={this.activeRoute("/client")}>
                <NavLink to="/client" className="nav-link">
                  <i className="fa fa-users" /> <span className="nav-label">{__t("clients")}</span>
                </NavLink>
              </li>
            </Can>
          ) : (
              ""
            )}
          {this.user_type == "admin" ? (
            <Can I={["all-user"]} userPermissions={this.userPermissions}>
              <li className="nav-item" className={this.activeRoute("/all-user")}>
                {" "}
                <NavLink to="/all-user" className="nav-link">
                  <i className="fa fa-user" /> <span className="nav-label">{__t("all_user")}</span>
                </NavLink>
              </li>
            </Can>
          ) : (
              ""
            )}
          {this.user_type == "admin" ? (
            <Can I={["view-client"]} userPermissions={this.userPermissions}>
              <li className="nav-item" className={this.activeRoute("/reseller")}>
                <NavLink to="/reseller" className="nav-link">
                  <i className="fa fa-users" /> <span className="nav-label">{__t("reseller")}</span>
                </NavLink>
              </li>
            </Can>
          ) : (
              ""
            )}
          {this.user_type == "admin" ? (
            <Can I={["view-contact"]} userPermissions={this.userPermissions}>
            <li className="nav-item" className={this.activeRoute("/contacts")}>
              <NavLink to="/contacts" className="nav-link">
                <i className="fa fa-address-book-o" /> <span className="nav-label">{__t("contact_data_set")}</span>
              </NavLink>
            </li>
              </Can>
      ) : (
        ""
      )}
          <Can I={["view-role"]} userPermissions={this.userPermissions}>
            <li className="nav-item" className={this.activeRoute("/role")}>
              <NavLink to="/role" className="nav-link">
                <i className="fa fa-sliders" /> <span className="nav-label">{__t("role")}</span>
              </NavLink>
            </li>
          </Can>
          {this.user_type != "client" ? (
            <Can I={["view-subscription"]} userPermissions={this.userPermissions}>
              <li className="nav-item" className={this.activeRoute("/subscription")}>
                <NavLink to="/subscription" className="nav-link">
                  <i className="fa fa-list-alt" /> <span className="nav-label">{__t("subscription")}</span>
                </NavLink>
              </li>
            </Can>
          ) : (
              ""
            )}
          <Can I={["view-paymentgateway"]} userPermissions={this.userPermissions}>
            <li className="nav-item" className={this.activeRoute("/paymentgateway")}>
              <NavLink to="/paymentgateway/create" className="nav-link">
                <i className="fa fa-money" /> <span className="nav-label">{__t("keys")}</span>
              </NavLink>
            </li>
          </Can>
          {/* <li
            className="nav-item"
            className={this.activeRoute("/transactions")}
          >
            <NavLink to="/transactions" className="nav-link">
              <i className="fa fa-exchange" />{" "}
              <span className="nav-label">{__t("transactions")}</span>
            </NavLink>
          </li> */}
          <Can I={["view-templates"]} userPermissions={this.userPermissions}>
            <li className="nav-item" className={this.activeRoute("/templates")}>
              <NavLink to="/templates" className="nav-link">
                <i className="fa fa-columns" /> <span className="nav-label">{__t("templates")}</span>
              </NavLink>
            </li>
          </Can>
          {this.user_type == "client" ? (
            <Can I={["view-email-tmpl"]} userPermissions={this.userPermissions}>
              <li className="nav-item" className={this.activeRoute("/email-template")}>
                <NavLink to="/email-template" className="nav-link">
                  <i className="fa fa-envelope" /> <span className="nav-label">{__t("letter_formatting")}</span>
                </NavLink>
              </li>
            </Can>
          ) : (
              ""
            )}
          <Can I={["view-widget"]} userPermissions={this.userPermissions}>
            <li className="nav-item" className={this.activeRoute("/widgets")}>
              <NavLink to="/widgets" className="nav-link">
                <i className="fa fa-file-code-o" /> <span className="nav-label">{__t("my_letters")}</span>
              </NavLink>
            </li>
          </Can>
          <Can I={["view-campaign"]} userPermissions={this.userPermissions}>
            <li className="nav-item" className={this.activeRoute("/campaign")}>
              <NavLink to="/campaign" className="nav-link">
                <i className="fa fa-file-code-o" /> <span className="nav-label">{__t("campaign")}</span>
              </NavLink>
            </li>
          </Can>

          <Can I={["view-assets"]} userPermissions={this.userPermissions}>
            <li className="nav-item" className={this.activeRoute("/assets")}>
              <NavLink to="/assets" className="nav-link">
                <i className="fa fa-picture-o" /> <span className="nav-label">{__t("assets")}</span>
              </NavLink>
            </li>
          </Can>
          {this.user_type == "admin" ? (
            <Can I={["view-email-tmpl"]} userPermissions={this.userPermissions}>
              <li className="nav-item" className={this.activeRoute("/email-template")}>
                <NavLink to="/email-template" className="nav-link">
                  <i className="fa fa-envelope" /> <span className="nav-label">{__t("email_temp")}</span>
                </NavLink>
              </li>
            </Can>
          ) : (
              ""
            )}
          {this.user_type == "admin" ? (
            <li className="nav-item" className={this.activeRoute("/db-backups")}>
              <NavLink to="/db-backups" className="nav-link">
                <i className="fa fa-database" /> <span className="nav-label">{__t("db_backups")}</span>
              </NavLink>
            </li>
          ) : (
              ""
            )}
          {/* {this.user_type == "client" ? ( */}
          <Can I={["view-settings"]} userPermissions={this.userPermissions}>
            <li className="nav-item" className={this.activeRoute("/settings")}>
              <NavLink to="/settings" className="nav-link">
                <i className="fa fa-cog" /> <span className="nav-label">{__t("settings")}</span>
              </NavLink>
            </li>
          </Can>
          {/* ) : (
            ""
          )} */}
          {this.user_type == "client" ? (
            <li className="nav-item" className={this.activeRoute("/clientsubscription")}>
              <NavLink to="/clientsubscription" className="nav-link">
                <i className="fa fa-list-alt" /> <span className="nav-label">{__t("clientsubscription")}</span>
              </NavLink>
            </li>
          ) : (
              ""
            )}
          {this.user_type != "client" ? (
            <Can I={["view-transaction"]} userPermissions={this.userPermissions}>
              <li className="nav-item" className={this.activeRoute("/transaction")}>
                {" "}
                <NavLink to="/transaction" className="nav-link">
                  <i className="fa fa-credit-card" /> <span className="nav-label">{__t("transaction")}</span>
                </NavLink>
              </li>
            </Can>
          ) : (
              ""
            )}

          <Can I={["view-user"]} userPermissions={this.userPermissions}>
            {this.user_type != "reseller" ? (
              <li className="nav-item" className={this.activeRoute("/widget-email")}>
                {" "}
                <NavLink to="/widget-email" className="nav-link">
                  <i className="fa fa-at" /> <span className="nav-label">{__t("widget_email")}</span>
                </NavLink>
              </li>
            ) : (
                ""
              )}
          </Can>
          {this.user_type == "admin" ? (
            <Can I={["view-email-schedule"]} userPermissions={this.userPermissions}>
              <li className="nav-item" className={this.activeRoute("/bulk-email")}>
                {" "}
                <NavLink to="/bulk-email" className="nav-link">
                  <i className="fa fa-credit-card" /> <span className="nav-label">{__t("bulk_email")}</span>
                </NavLink>
              </li>
            </Can>
          ) : (
              ""
            )}

          {false && AccessControlFunc(
            ["update-user"],
            this.wholePermissions,
            ["letter_autoresponder", "newsletter_autoresponder"]
          ) && <li className="nav-item" className={this.activeRoute("/letter-variations")}>
            {" "}
            <NavLink to="/letter-variations" className="nav-link">
              <i className="fa fa-at" /> <span className="nav-label">{__t("Letter Variations")}</span>
            </NavLink>
          </li>}
        </ul>
      </nav>
    );
  }
}

export default Navigation;
