import React, { Component } from "react";
import Utility from "../common/Utility";
import { __t ,__currency} from "../../locale/language";
import Moment from "moment";
import RemotePagination from "../common/Table";
import $ from "jquery";
import { Link } from "react-router-dom";
var subscription_div = "";
class ClientOverview extends Component {
    constructor(props) {
        super(props);
        this.state = {
            client_id: localStorage.getItem("client_id"),
            token: localStorage.getItem("token"),
            user_type: Utility.getIdFromToken(localStorage.getItem("token")),
            id: "",
            client_data: "",
            page: 1,
            data: [],
            sizePerPage: 10,
            total: 0,
            search: "",
            reseller_name: "",
            year_data:"",
            month_data:"",
        };

    }


    componentDidMount() {
        var id = "";

        if (this.props.location &&
            typeof this.props.match.params.id !== "undefined") {
            id = this.props.match.params.id;
            this.setState({
                id: id
            }, () => {
              //console.log(this.state.id)
            })

        }

    }
    handleTableChange = (type, { page, sizePerPage }) => {
        const currentIndex = (page - 1) * sizePerPage;

        this.getClientData(page, sizePerPage);
    };
    getClientData = (page, sizePerPage) => {
        //console.log(this.state.id)
        Utility.ExecuteData("getClientDetails", this.token, {
            id: this.state.id,
            page: page,
            limit: sizePerPage,
            search: this.state.search,

        }).then(res => {
            //console.log(res)
            if(res){
                this.setState({
                    page,
                    data: res.client_list,
                    sizePerPage,
                    total: res.count,
                    reseller_name: res.client_list[0].reseller_name?res.client_list[0].reseller_name:"",
                    year_data: res.year_data.length>0?res.year_data[0].yearly_price:0,
                    month_data: res.month_data.length>0 ?res.month_data[0].monthly_price:0
                })
            }
         
        })

    }
    getDuration = (end_date) => {
        var now = Moment(new Date()); //todays date
        var end = Moment(end_date); // another date
        var duration = Moment.duration(end.diff(now));
        var days = parseInt(duration.asDays());
        if (days < 0) {
            return 0;
        }
        return days ? days : 0;
    }
    renderButtons(cell, row, rowIndex) {
        //console.log(row)
        return (
          <span>
         
              <Link
                to={{
                  pathname: `/clientdetail/${row.id}`,
                  state: { id: `${row.id}` }
                }}
                className="btn btn-primary btn-sm"
              >
               {__t("view")}
              </Link>
            </span>
        );
      }
    render() {
      
        const { data, sizePerPage, page, total } = this.state;
        const columns = [
            { dataField: "name", text: "Client Name" },
            { dataField: "title", text: " Client Title" },

            { dataField: "sub_name", text: "Plan" },
        //     { dataField: "price", text: "Price" 
        //  },

         {
            dataField: "price",
            text: __t("Price"),
            formatter: (cell, row, rowIndex) => {
              return __currency() + cell;
            }},
            { dataField: "sub_status", text: "Plan Status" },
            {
                dataField: "end_date",
                text: "Remaining Days",
                formatter: this.getDuration.bind(this)
            },
            { dataField: "status", text: "Status" },
            {
                dataField: "updated_at",
                text: __t("updated_at"),
                formatter: cellContent =>
                    Moment(cellContent).format("DD-MMM-YYYY HH:mm:ss")
            },
            {
                dataField: "action",
                text: "Action",
                formatter: this.renderButtons.bind(this)
              }

        ];
        return (
            <div>
                <div className="row wrapper border-bottom white-bg page-heading p10 mb10">
                    <div className="col-sm-5">
                    <button className=" btn btn-primary btn btn-primary"> <Link to="/">{__t("back")}</Link></button>
                               
                                </div>
                                <div className="col-sm-7">
                                <h2 style={{ marginTop: 10 }}>{__t("reseller_overview")}</h2>
                    </div>
                </div>
                {/* {__t("reseller_name")} :{this.state.reseller_name} */}
                <div className="ibox-content">
                    <div className="row">
                        <div className="col-sm-6">
                            <div className="">
                                <label className="mr10"><strong>{__t("reseller_name")}:</strong></label>
                                <span>{this.state.reseller_name}</span>
                            </div>
                        </div>
                        <div className="col-sm-6">
                            <div className="">
                            <label className="mr10"><strong>{__t("total_clients")}:</strong></label>
                                <span>{this.state.total}</span>
                            </div>
                        </div>
                        </div>
                        <div className="row">
                        <div className="col-sm-6">
                            <div className="">
                            <label className="mr10"><strong>{__t("monthly_revenue")}:</strong></label>
                                <span>{__currency()}{this.state.month_data}</span>
                            </div>
                        </div>
                        <div className="col-sm-6">
                            <div className="">
                            <label className="mr10"><strong>{__t("yearly_revenue")}:</strong></label>
                                <span>{__currency()}{this.state.year_data}</span>
                            </div>
                        </div>
                     
                    </div>

                </div>
                <div className="ibox-content">
                    {this.state.total > 0 ? (
                        <RemotePagination
                            columns={columns}
                            data={data}
                            page={page}
                            sizePerPage={sizePerPage}
                            totalSize={total}
                            onTableChange={this.handleTableChange}
                        />
                    ) : (
                            __t("no_record_found")
                        )}
                </div>

            </div>
        );
    }
}

export default ClientOverview;
