import React from "react";
import { Link } from "react-router-dom";
import RemotePagination from "../common/Table";
import Utility from "../common/Utility";
import { __t } from "../../locale/language";
import Can from "../common/AccessControl";
import LazyLoad from "react-lazyload";
import $ from "jquery";
import Axios from "axios";
import FormData from "form-data";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";

import Alert from "../common/Alert";
import Alertinfo from "../common/Alertinfo";
import AlertDelete from "../common/AlertDelete";
import GetAssets from "./GetAssets";
import Category from "./Category";
var component_type = "";
var ext_type = [
  { Data: "pdf", Value: "pdf" },
  { Data: "csv", Value: "csv" },
  { Data: "doc", Value: "doc" },
  { Date: "xls", Value: "xls" },
  { Date: "file", Value: "file" },
];
var delete_status;
var image_type;
var select_data = [];
const BASE_URL = Utility.baseUrlBe() + "assetscreate";
class AssetsList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      client_id: localStorage.getItem("client_id"),
      token: localStorage.getItem("token"),
      search: "",
      edititem: {},
      modal: false,
      message: "",
      selected_data: [],
      data: [],
      cnfrm_msg: "",
      img_type: "",
      dlt_msg: "",
      user_data: Utility.getIdFromToken(localStorage.getItem("token")),
      select_date: "",
      type_search: "",
      c_type: "",
      accept_type: "",
      multiple: false,
    };
    this.onFormSubmit = this.onFormSubmit.bind(this);
    this.onChange = this.onChange.bind(this);
    this.updateAssetsList = this.updateAssetsList.bind(this);
    this.editElement = this.editElement.bind(this);
    this.toggle = this.toggle.bind(this);
    this.typesearch = this.typesearch.bind(this);
    this.datechange = this.datechange.bind(this);
    this.admin_delete = this.admin_delete.bind(this);
    this.selectAll = this.selectAll.bind(this);
    this.all_delete = this.all_delete.bind(this);
    this.select_delete = this.select_delete.bind(this);
    this.imageType_search = this.imageType_search.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.cnfrm_delete = this.cnfrm_delete.bind(this);
    this.selected_delete = this.selected_delete.bind(this);
    this.next = this.next.bind(this);
    this.previous = this.previous.bind(this);
    this.searchAssetsList = this.searchAssetsList.bind(this);
    this.getDocumnetData = this.getDocumnetData.bind(this);
    this.changeCategory = this.changeCategory.bind(this);
  }

  updateAssetsList(page = 1, sizePerPage = 30) {
    $("#rjt_multiple").hide();
    $("#dlt_multiple").hide();
    $(".mycheckbox").prop("checked", false);
    $("#selectall").prop("checked", false);
    //console.log("dfvhbghb")
    Utility.ExecuteData("assetsdata", this.state.token, {
      page: page,
      limit: sizePerPage,
      search: this.state.search,
      token: this.state.token,
    }).then((data) => {
      var new_data;
      var new_count;
      if (data.data.rows == undefined) {
        new_data = data.data;
      } else {
        new_data = data.data.rows;
      }

      if (data.data.count == undefined) {
        new_count = data.count[0].count;
      } else {
        new_count = data.data.count;
      }
      this.setState(() => ({
        page,
        data: new_data,
        sizePerPage,
        total: new_count,
      }));
    });
  }
  searchAssetsList(page = 1, sizePerPage = 30) {
    ////console.log(this.state.search);
    if (this.state.search) {
      Utility.ExecuteData("assetsdata", this.state.token, {
        page: page,
        limit: sizePerPage,
        search: this.state.search,
        token: this.state.token,
      }).then((data) => {
        var new_data;

        if (data.data.rows == undefined) {
          new_data = data.data;
        } else {
          new_data = data.data.rows;
        }

        this.setState(() => ({
          page,
          data: new_data,
          sizePerPage,
          total: data.data.count,
        }));
      });
      var newpage = this.state.total / 30;
      $("#prev").hide();
      if (newpage && newpage > 1) {
        $("#nxt").show();
      }
    }
  }
  previous() {
    var sizePerPage = 30;
    var page;
    var total_count = [];
    ////console.log(this.state.page);
    if (this.state.page == "2") {
      $("#prev").hide();
      $("#nxt").show();
      if (
        this.state.type_search &&
        this.state.img_type != "Select Image Type"
      ) {
        var newpage = this.state.page - 1;
        Utility.ExecuteData("findAssets", this.token, {
          page: newpage,
          limit: sizePerPage,
          type: this.state.type_search,
          token: this.state.token,
          status: image_type,
          type_search: this.state.type_search,
        }).then((data) => {
          let total_count = "";
          if (data.total && Array.isArray(data.total)) {
            total_count = data.total[0][0].total;
          } else {
            total_count = data.total;
          }
          this.setState(() => ({
            page: newpage,
            data: data.data,
            total: total_count,
          }));
        });
        // this.typesearch(this.state.type_search, this.state.page + 1, 10);
      } else if (image_type) {
        var newpage = this.state.page - 1;
        Utility.ExecuteData("filterImage", this.state.token, {
          page: newpage,
          limit: sizePerPage,
          search: this.state.search,
          token: this.state.token,
          image_type: image_type,
          client_id: this.state.client_id,
          type_search: this.state.type_search,
        }).then((data) => {
          var new_data;

          if (data.data.rows == undefined) {
            new_data = data.data;
          } else {
            new_data = data.data.rows;
          }

          this.setState(() => ({
            page: newpage,
            data: new_data,

            total: data.data.count,
            img_type: image_type,
          }));
        });
      } else if (
        this.state.search &&
        this.state.img_type != "Select Image Type"
      ) {
        var newpage = this.state.page - 1;
        ////console.log("aaa");

        Utility.ExecuteData("assetsdata", this.state.token, {
          page: newpage,
          limit: sizePerPage,
          search: this.state.search,
          token: this.state.token,
        }).then((data) => {
          var new_data;
          if (data.data.rows == undefined) {
            new_data = data.data;
          } else {
            new_data = data.data.rows;
          }
          this.setState(() => ({
            page: newpage,
            data: new_data,
            total: data.data.count,
          }));
        });
      } else {
        //this.updateAssetsList(this.state.page - 1, 30);
        this.getDocumnetData(this.state.type_search, this.state.page - 1, 30);
      }
    } else {
      $("#prev").show();
      $("#nxt").show();
      if (
        this.state.type_search &&
        this.state.img_type != "Select Image Type"
      ) {
        var newpage = this.state.page - 1;
        Utility.ExecuteData("findAssets", this.token, {
          page: newpage,
          limit: sizePerPage,
          type: this.state.type_search,
          token: this.state.token,
          status: image_type,
          type_search: this.state.type_search,
        }).then((data) => {
          let total_count = "";
          if (data.total && Array.isArray(data.total)) {
            total_count = data.total[0][0].total;
          } else {
            total_count = data.total;
          }
          this.setState(() => ({
            page: newpage,
            data: data.data,
            total: total_count,
          }));
        });
        // this.typesearch(this.state.type_search, this.state.page + 1, 10);
      } else if (image_type) {
        var newpage = this.state.page - 1;
        Utility.ExecuteData("filterImage", this.state.token, {
          page: newpage,
          limit: sizePerPage,
          search: this.state.search,
          token: this.state.token,
          image_type: image_type,
          client_id: this.state.client_id,
          type_search: this.state.type_search,
        }).then((data) => {
          var new_data;
          if (data.data.rows == undefined) {
            new_data = data.data;
          } else {
            new_data = data.data.rows;
          }

          this.setState(() => ({
            page: newpage,
            data: new_data,

            total: data.data.count,
            img_type: image_type,
          }));
        });
      } else if (
        this.state.select_date &&
        this.state.img_type != "Select Image Type"
      ) {
        var newpage = this.state.page - 1;
        Utility.ExecuteData("findAssets", this.token, {
          page: newpage,
          limit: sizePerPage,
          type: this.state.type_search,
          select_date: this.state.select_date,
          token: this.state.token,
          status: image_type,
          type_search: this.state.type_search,
        }).then((data) => {
          ////console.log(data);
         

          let total_count = "";
          if (data.total && Array.isArray(data.total)) {
            if (data.total[0].length == 0) {
              total_count = 0;
            } else {
              total_count = data.total[0][0].total;
            }
          } else {
            total_count = data.total;
          }
          this.setState(() => ({
            page: page,
            data: data.data,
            total: total_count,
            select_date: this.state.select_date,
          }));
        });
      } else {
        //this.updateAssetsList(this.state.page - 1, 30);
        this.getDocumnetData(this.state.type_search, this.state.page - 1, 30);
      }
    }
  }
  next() {
    var sizePerPage = 30;
    var page;
    var newpage = this.state.total / 30;
    var result = newpage - Math.floor(newpage) !== 0;
    var total_count = [];
    ////console.log(image_type);
    ////console.log(this.state.type_search);
    if (result) {
      newpage = Math.trunc(newpage);
      newpage = newpage + 1;
    }

    if (this.state.page == "1") {
      $("#prev").hide();
    }
    if (this.state.page == newpage) {
      $("#nxt").hide();
    } else {
      if (this.state.page == newpage - 1) {
        $("#nxt").hide();
      }
      if (
        this.state.type_search &&
        this.state.img_type != "Select Image Type"
      ) {
        var newpage = this.state.page + 1;
        Utility.ExecuteData("findAssets", this.token, {
          page: newpage,
          limit: sizePerPage,
          type: this.state.type_search,
          token: this.state.token,
          status: image_type,
          type_search: this.state.type_search,
        }).then((data) => {
          if (data.total && Array.isArray(data.total)) {
            if (data.total[0].length == 0) {
              total_count = 0;
            } else {
              total_count = data.total[0][0].total;
            }
          } else {
            total_count = data.total;
          }
          ////console.log(data);
          this.setState(() => ({
            page: newpage,
            data: data.data,
            total: total_count,
          }));
        });
        $("#prev").show();
        // this.typesearch(this.state.type_search, this.state.page + 1, 10);
      } else if (
        this.state.select_date &&
        this.state.img_type != "Select Image Type"
      ) {
        var newpage = this.state.page + 1;
        Utility.ExecuteData("findAssets", this.token, {
          page: newpage,
          limit: sizePerPage,
          type: this.state.type_search,
          select_date: this.state.select_date,
          token: this.state.token,
          status: image_type,
          type_search: this.state.type_search,
        }).then((data) => {
          ////console.log(data);
          if (data.total && Array.isArray(data.total)) {
            if (data.total[0].length == 0) {
              total_count = 0;
            } else {
              total_count = data.total[0][0].total;
            }
          } else {
            total_count = data.total;
          }
          this.setState(() => ({
            page: newpage,
            data: data.data,
            total: total_count,
            select_date: this.state.select_date,
          }));
        });
        $("#prev").show();
      } else if (image_type) {
        ////console.log("shchgdh");
        var newpage = this.state.page + 1;
        Utility.ExecuteData("filterImage", this.state.token, {
          page: newpage,
          limit: sizePerPage,
          search: this.state.search,
          token: this.state.token,
          image_type: image_type,
          client_id: this.state.client_id,
          type_search: this.state.type_search,
        }).then((data) => {
          var new_data;

          if (data.data.rows == undefined) {
            new_data = data.data;
          } else {
            new_data = data.data.rows;
          }

          this.setState(() => ({
            page: newpage,
            data: new_data,

            total: data.data.count,
            img_type: image_type,
          }));
        });
        $("#prev").show();
      } else if (
        this.state.search &&
        this.state.img_type != "Select Image Type"
      ) {
        ////console.log("aaa");
        Utility.ExecuteData("assetsdata", this.state.token, {
          page: newpage,
          limit: sizePerPage,
          search: this.state.search,
          token: this.state.token,
        }).then((data) => {
          var new_data;
          if (data.data.rows == undefined) {
            new_data = data.data;
          } else {
            new_data = data.data.rows;
          }
          this.setState(() => ({
            page: newpage,
            data: new_data,
            total: data.data.count,
          }));
        });
        $("#prev").show();
      } else {
        if (this.state.page == newpage - 1) {
          $("#nxt").hide();
        }
        // this.updateAssetsList(this.state.page + 1, 30);
        this.getDocumnetData(this.state.type_search, this.state.page + 1, 30);
        $("#prev").show();
      }
    }
  }

  getDocumnetData(type, page = 1, sizePerPage = 30) {
    $(".mycheckbox").prop("checked", false);
    //console.log(this.state);
    var type_search = type;
    console.log(type, "sdsdsdd");

    var accept_type = type_search;
    this.setState(
      {
        accept_type: accept_type,
        c_type: 2,
        select_date: "",
        img_type: "",
      },
      () => {
        //console.log(this.state.admin_status)
        Utility.ExecuteData("findAssets", this.token, {
          page: page,
          limit: 30,
          type: type_search,
          select_date: this.state.select_date,
          token: this.state.token,
          client_id: this.state.client_id,
          // status: image_type,
          type_search: type_search,
        }).then((data) => {
          $("#show_img").show();
          console.log(data, "sfsdfsdf");
         
          let total_count = "";
          if (data.total && Array.isArray(data.total)) {
            total_count = data.total[0][0].total;
          } else {
            total_count = data.total;
          }
          this.setState(() => ({
            page: page,
            data: data.data,
            total: total_count,
            type_search: type_search,
          }));
        });
      }
    );

    // return false;
  }
  componentDidMount() {
    // this.updateAssetsList(1, 30);
    //this.getDocumnetData(type, 1, 30)
  }
  onChange(e) {
    var res = e.target.files[0].type.substring(0, 5);
    console.log(res);
    let file_type = e.target.files[0].type;
    console.log(file_type);
    let types = ["image/jpeg", "image/png", "image/gif"];
    if (types.indexOf(file_type) < 0) {
      $("#msg_text").text("Invalid file type.");
      $("#alertinfo").show();

      return;
    }

    //return false
    if (e.target.files[0].type != "" && res == "image") {
      var type = "";
      var _URL = window.URL || window.webkitURL;
      var file, img;
      //console.log(e.target.files[0])
      if ((file = e.target.files[0])) {
        img = new Image();
        img.src = _URL.createObjectURL(file);
        let promise = new Promise(function (resolve, reject) {
          img.onload = function () {
            //let imsize = Utility.imageSize(file.size)
            //console.log(this.width, this.height)
            if (this.width > 1280 && this.height > 720 && file.size > 5253158) {
              $("#msg_text").text(__t("size_should_below_5mb"));
              $("#alertinfo").show();
              type = "false";
              resolve(file);
            } else {
              type = "true";
              resolve(file);
            }
          };

          // immediately give the result: 123
        });
        promise.then(() => {
          if (type == "true") {
            this.setState({ file: file });
          }
        });
      }
    } else {
      this.setState({ file: e.target.files[0] });
    }

    ////console.log(e.target.files[0]);
  }

  onFormSubmit(e) {
    e.preventDefault();
    ////console.log(this.state);
    //return false
    ////console.log(this.state.file);
    if (this.state.file) {
      const formData = new FormData();
      formData.append("file", this.state.file);
      formData.append("client_id", this.state.client_id);
      formData.append("token", this.state.token);
      var actkn =
        typeof this.state.token != "undefined"
          ? "Bearer " + this.state.token
          : "";
      const config = {
        headers: {
          "content-type": "multipart/form-data",
          authorization: actkn,
        },
      };
      $("#upload-loader").css("display", "flex");
      Axios.post(BASE_URL, formData, config)
        .then((response) => {
          console.log(response, "response");
          $("#upload-loader").css("display", "none");
          if (response && response.data && response.data.code == 200) {
            $("#msg_text").text("File is successfully uploaded");
            $("#alertinfo").show();
          } else {
            $("#msg_text").text("Invalid File type");
            $("#alertinfo").show();
          }

          // this.updateAssetsList(1, 30);
          this.getDocumnetData(this.state.type_search, 1, 30);
        })
        .catch((error) => {
          this.setState({ errors: true });
        });
      document.getElementById("file-1").value = "";
      this.state.file = "";
    } else {
      $("#msg_text").text("Please select files");
      $("#alertinfo").show();
    }
  }
  toggle() {
    ////console.log(this.state.img_type)
    //this.updateAssetsList(1, 30);
    this.setState({ modal: !this.state.modal });
  }
  changeCategory() {
    //console.log(this.state)
    this.setState({
      select_date: "",
      type_search: "",
      image_type: "",
      img_type: "Select Image Type",
      status: "",
      c_type: "",
    });
  }
  editElement(elem) {
    ////console.log(elem);

    this.setState({ modal: true, edititem: elem }, () => {
      ////console.log(this.state.modal);
    });
  }
  typesearch(e, page = 1, sizePerPage = 30) {
    $("#prev").hide();
    var type_search = e.target.value;
    //console.log(this.state.type_search);
    ////console.log(type_search);
    if (type_search != "Select Media") {
      Utility.ExecuteData("findAssets", this.token, {
        page: page,
        limit: 30,
        type: type_search,
        select_date: this.state.select_date,
        token: this.state.token,
        client_id: this.state.client_id,
        status: image_type,
        type_search: type_search,
      }).then((data) => {
        ////console.log(data.total[0][0].total);
        let total_count = "";
        if (data.total && Array.isArray(data.total)) {
          total_count = data.total[0][0].total;
        } else {
          total_count = data.total;
        }

        this.setState(() => ({
          page: page,
          data: data.data,
          total: total_count,
          type_search: type_search,
        }));
      });
    } else if (type_search == "Select Media") {
      this.state.type_search = "";
      this.updateAssetsList(1, 30);
    }
  }
  datechange(e, page = 1, sizePerPage = 30) {
    var select_date = e.target.value;
    ////console.log(document.getElementById("dte").value);
    Utility.ExecuteData("findAssets", this.token, {
      page: page,
      limit: 30,
      type: this.state.type_search,
      select_date: select_date,
      token: this.state.token,
      status: image_type,
      type_search: this.state.type_search,
    }).then((data) => {
      ////console.log(data);
      let total_count = "";
      if (data.total && Array.isArray(data.total)) {
        total_count = data.total[0][0].total;
      } else {
        total_count = data.total;
      }
      this.setState(() => ({
        page: page,
        data: data.data,
        total: total_count,
        select_date: select_date,
      }));
    });
  }
  cnfrm_delete(data, admin_status) {
    //return false
    var newpage = this.state.total / 30;
    data = this.state.admin_data;
    admin_status = this.state.admin_status;
    ////console.log(admin_status);
    ////console.log(image_type);
    if (admin_status == "retrieve") {
      Utility.ExecuteData("retrieveImage", this.state.token, {
        token: this.state.token,
        item: data,
        status: admin_status,
      })
        .then((responseJson) => {
          ////console.log("dvdb");
          if (responseJson.code === 200) {
            $("#prev").hide();
            if (newpage && newpage > 1) {
              $("#nxt").show();
            }

            this.getDocumnetData(this.state.type_search, 1, 30);
            image_type = "";
            this.setState(
              {
                admin_status: "",
                image_type: "",
              },
              () => {
                ////console.log(this.state.type_search);
              }
            );
            $("#drop_img").val("Select Image Type");
            // this.updateAssetsList(1, 30);
          } else {
            alert("Something went wront. Try Again.");
          }
        })
        .catch((error) => {
          this.setState({ errors: true });
        });
    } else {
      //console.log(data)
      Utility.ExecuteData("moderate", this.state.token, {
        token: this.state.token,
        item: data,
        status: admin_status,
      })
        .then((responseJson) => {
          if (responseJson.code === 200) {
            ////console.log("dvbh");
            ////console.log(this.state.type_search);
            $("#prev").hide();
            if (newpage && newpage > 1) {
              $("#nxt").show();
            }
            image_type = "";
            this.setState({
              admin_status: "",
              image_type: "",
            });
            $("#drop_img").val("Select Image Type");
            $("#checkbox").prop("checked", false);
            // this.updateAssetsList(1, 30);
            this.getDocumnetData(this.state.type_search, 1, 30);
          } else {
            alert("Something went wront. Try Again.");
          }
        })
        .catch((error) => {
          this.setState({ errors: true });
        });
    }
  }
  admin_delete(data, admin_status) {
    $("#alertshow").show();
    var msg_status;
    if (admin_status == "retrieve") {
      msg_status = "retrieve";
    } else if (admin_status == "rejected") {
      msg_status = "reject";
    } else {
      msg_status = "delete";
    }

    this.setState({
      alert: true,
      cnfrm_msg: "Are you sure want to " + msg_status + " ?",
      admin_data: data,
      admin_status: admin_status,
    });
    return false;
  }
  selected_delete() {
    var newpage = this.state.total / 30;
    if (document.getElementById("selectall").checked == true) {
      $("#rjt_multiple").show();
      $("#dlt_multiple").show();
      var select_id = [];
      var select_name = [];

      this.state.data.map((value, k) => {
        select_id.push(value.id);
        select_name.push(value.name);
      });
      let promise = new Promise((resolve, reject) => {
        Utility.ExecuteData("selectAll", this.state.token, {
          token: this.state.token,
          data: this.state.data,
          select_id: select_id,
          status: delete_status,
          select_name: select_name,
        })
          .then((responseJson) => {
            if (responseJson.code === 200) {
              resolve("assets deleted");
              //console.log("dfvb")
              select_data = [];
              $("#msg_text").text("successfully " + delete_status);
              $("#alertinfo").show();
              $(".mycheckbox").prop("checked", false);
              $("#checkbox").prop("checked", false);
              $("#rjt_multiple").hide();
              $("#dlt_multiple").hide();
            } else {
              $("#msg_text").text("Something went wront. Try Again.");
              $("#alertinfo").show();
            }
          })
          .catch((error) => {
            this.setState({ errors: true });
          });
      });
      promise.then((message) => {
        $("#prev").hide();
        if (newpage && newpage > 1) {
          $("#nxt").show();
        }
        //this.updateAssetsList(1, 30);
        this.getDocumnetData(this.state.type_search, 1, 30);
      });
    } else if (this.state.selected_data != "") {
      var select_id = [];
      var select_name = [];
      this.state.selected_data.map((value, k) => {
        select_id.push(value.id);
        select_name.push(value.name);
      });
      Utility.ExecuteData("selectAll", this.state.token, {
        token: this.state.token,
        data: this.state.selected_data,
        select_id: select_id,
        status: delete_status,
        select_name: select_name,
      })
        .then((responseJson) => {
          if (responseJson.code === 200) {
            this.setState({ redirect: true });
            $("#msg_text").text("successfully " + delete_status);
            $("#alertinfo").show();
            $("#rjt_multiple").hide();
            $("#dlt_multiple").hide();
            select_data = [];
            //this.updateAssetsList(1, 30);
            this.getDocumnetData(this.state.type_search, 1, 30);
          } else {
            alert("Something went wront. Try Again.");
          }
        })
        .catch((error) => {
          this.setState({ errors: true });
        });

      $(".mycheckbox").prop("checked", false);
      $("#checkbox").prop("checked", false);
      this.state.selected_data = "";
    }
  }
  all_delete(status) {
    if (
      document.getElementById("selectall").checked == true ||
      this.state.selected_data != ""
    ) {
      delete_status = status;
      var dlt_status;
      if (status == "deleted") {
        dlt_status = "delete";
      } else if (status == "rejected") {
        dlt_status = "reject";
      }
      this.setState({
        dlt_msg: "Are you sure want to " + dlt_status + " ?",
      });

      $("#alertdelete").show();
      return false;
    } else {
      this.setState({
        message: "Please select files",
      });

      return false;
    }
  }

  selectAll() {
    ////console.log(this.state.image_type);
    if (document.getElementById("selectall").checked == true) {
      $("#rjt_multiple").show();
      $("#dlt_multiple").show();
      $(".mycheckbox").prop("checked", true);
      this.setState({
        multiple: true,
      });
    } else {
      $("#rjt_multiple").hide();
      $("#dlt_multiple").hide();
      $(".mycheckbox").prop("checked", false);
      this.setState({
        multiple: false,
      });
    }
  }
  select_delete(value) {
    // //console.log("dfhvbhu", this.state.selected_data)
    //console.log(value)

    ////console.log(this.state.selected_data);
    if (value) {
      var get_data = Object.keys(select_data).filter(function (key, val) {
        //console.log(val)
        return select_data[val] === value;
      });
      //console.log(get_data)
      if (get_data != "") {
        select_data.pop(get_data);
      } else {
        select_data.push(value);
      }
      //console.log(select_data);
      this.setState(
        {
          selected_data: select_data,
        },
        () => {
          if (this.state.selected_data.length == 0) {
            $("#rjt_multiple").hide();
            $("#dlt_multiple").hide();
            //select_data = this.state.selected_data;
          } else if (this.state.selected_data.length >= 0) {
            $("#rjt_multiple").show();
            $("#dlt_multiple").show();
          }
          //console.log(this.state.selected_data.length)
          //console.log(this.state.selected_data)
        }
      );
    }

    //  else if (document.getElementById("checkbox").checked == false) {
    //   $("#rjt_multiple").hide();
    //   $("#dlt_multiple").hide();
    // }
    // else if (this.state.selected_data.length >= 0) {
    //   $("#rjt_multiple").show();
    //   $("#dlt_multiple").show();
    // }
  }

  imageType_search(e, page = 1, sizePerPage = 30) {
    image_type = e.target.value;
    //console.log(image_type);
    if (image_type != "Select Image Type" || this.state.type_search) {
      Utility.ExecuteData("filterImage", this.state.token, {
        page: page,
        limit: sizePerPage,
        search: this.state.search,
        token: this.state.token,
        image_type: image_type,
        client_id: this.state.client_id,
        type_search: this.state.type_search,
      }).then((data) => {
        var new_data;

        if (data.data.rows == undefined) {
          new_data = data.data;
        } else {
          new_data = data.data.rows;
        }

        this.setState(() => ({
          page,
          data: new_data,
          sizePerPage,
          total: data.data.count,
          img_type: image_type,
        }));
      });
    }
    if (image_type == "Select Image Type" && this.state.type_search == "") {
      ////console.log(this.state.type_search);
      // this.updateAssetsList(1, 30);
      this.getDocumnetData(this.state.type_search, 1, 30);
    }
  }

  handleChange(event) {
    this.setState({ [event.target.name]: event.target.value });
  }
  componentWillReceiveProps(c_type) {
    ////console.log(c_type);
  }

  render() {
    //console.log(this.state.data);
    var usP = this.props.userPermissions.userPermissions;
    return (
      <div>
        {this.state.c_type == 1 || this.state.c_type == "" ? (
          <Category
            getDocumnetData={this.getDocumnetData}
            c_type={this.state.c_type}
          />
        ) : (
          <GetAssets
            user_type={this.state.user_data}
            total={this.state.total}
            message={this.state.message}
            cnfrm_msg={this.state.cnfrm_msg}
            cnfrm_delete={this.state.cnfrm_delete}
            dlt_msg={this.state.dlt_msg}
            file={this.state.file}
            updateAssetsList={this.updateAssetsList}
            searchAssetsList={this.searchAssetsList}
            previous={this.previous}
            next={this.next}
            getDocumnetData={this.getDocumnetData}
            onChange={this.onChange}
            onFormSubmit={this.onFormSubmit}
            toggle={this.toggle}
            editElement={this.editElement}
            typesearch={this.typesearch}
            datechange={this.datechange}
            cnfrm_delete={this.cnfrm_delete}
            admin_delete={this.admin_delete}
            selected_delete={this.selected_delete}
            all_delete={this.all_delete}
            data={this.state.data}
            selectAll={this.selectAll}
            select_delete={this.select_delete}
            imageType_search={this.imageType_search}
            handleChange={this.handleChange}
            userPermission={usP}
            modal={this.state.modal}
            edititem={this.state.edititem}
            changeCategory={this.changeCategory}
            accept_type={this.state.accept_type}
            image_type={this.state.img_type}
            select_date={this.state.select_date}
            type_search={this.state.type_search}
          />
        )}
      </div>
    );
  }
}

export default AssetsList;
