import React, { Component } from "react";
import { Tabs, Tab } from "react-bootstrap";
import { Link } from "react-router-dom";
import { __t } from "../../locale/language";
class Tab2 extends Component {
  constructor(props) {
    super(props);
  }
  render() {
    const tab2_data = this.props.tab2_data;
    const flag = this.props.flag;
    return (
      <Tab eventKey={2} title="SMTP Detail" disabled>
        <div className="row form-group">
          <div className="col-md-6 col-sm-6 col-xs-12">
            <label className="control-label required-field">{__t("sender_email")}</label>
            <input type="email" name="sender_email" className="form-control" placeholder={__t("sender_email")} value={tab2_data.sender_email} onChange={this.props.handleChange} />
            <label id="-error" className="error">
              {tab2_data.errors["sender_email"]}
            </label>
          </div>
          <div className="col-md-6 col-sm-6 col-xs-12">
            <label className="control-label required-field">{__t("sender_name")}</label>
            <input type="text" name="sender_name" autoComplete="off" className="form-control" placeholder={__t("sender_name")} value={tab2_data.sender_name} onChange={this.props.handleChange} />
            <label id="-error" className="error">
              {tab2_data.errors["sender_name"]}
            </label>
          </div>
        </div>
        <div className="row form-group">
          {/* <div className="col-md-6 col-sm-6 col-xs-12">
            <label className="control-label required-field">{__t("uname")}</label>
            <input
              type="text"
              name="username"
              autoComplete="off"
              className="form-control"
              placeholder={__t("uname")}
              value={tab2_data.username}
              onChange={this.props.handleChange}
            />
            <label id="-error" className="error">
              {tab2_data.errors["username"]}
            </label>
          </div> */}
          <div className="col-md-6 col-sm-6 col-xs-12">
            <label className={flag == "update" ? "control-label" : "control-label required-field"}>{__t("password")}</label>
            <input type="password" name="password" autoComplete="new-password" className="form-control" placeholder={__t("password")} onChange={this.props.handleChange} />
            <label id="-error" className="error">
              {tab2_data.errors["password"]}
            </label>
          </div>
        </div>
        <div className="row form-group">
          <div className="col-md-6 col-sm-6 col-xs-12">
            <label className="control-label required-field">{__t("host")}</label>
            <input type="text" name="host" autoComplete="off" className="form-control" placeholder={__t("host")} value={tab2_data.host} onChange={this.props.handleChange} />
            <label id="-error" className="error">
              {tab2_data.errors["host"]}
            </label>
          </div>
          <div className="col-md-6 col-sm-6 col-xs-12">
            <label className="control-label required-field">{__t("port")}</label>
            <input type="number" name="port" autoComplete="off" className="form-control" placeholder={__t("port")} value={tab2_data.port} onChange={this.props.handleChange} />
            <label id="-error" className="error">
              {tab2_data.errors["port"]}
            </label>
          </div>
        </div>
        {/* {flag === "update" ? (
          <div className="row form-group">
            <div className="col-md-12">
              <button className="btn btn-primary" onClick={this.props.create} type="submit">
                {this.props.capitalize(this.props.flag)}
              </button>
              &nbsp;&nbsp;
              <Link className="btn btn-white" to="/reseller">
                {__t("cancel")}
              </Link>
            </div>
          </div>
        ) : (
          ""
        )} */}
      </Tab>
    );
  }
}

export default Tab2;
