import React from "react";
import ReactQuill from "react-quill";
import { __t } from "../../locale/language";
import Utility from "../common/Utility"
import ReactTooltip from "react-tooltip";
import AdressComponents from "../common/AdressComponent";
// Accessing the Quill backing instance using React ref functions

class Editor extends React.Component {
  constructor(props) {
    super(props);

    this.state = { editorHtml: this.props.defaultValue, mountedEditor: false, embedColumns: this.props.embedColumns };
    this.quillRef = null;
    this.reactQuillRef = null;
    this.handleChange = this.handleChange.bind(this);
    this.handleClick = this.handleClick.bind(this);
    this.attachQuillRefs = this.attachQuillRefs.bind(this);
  }

  componentDidMount() {
    this.attachQuillRefs();
    //this.setState({ editorHtml: this.props.defaultValue });
  }

  componentDidUpdate() {
    this.attachQuillRefs();
  }

  attachQuillRefs() {
    // Ensure React-Quill reference is available:
    if (typeof this.reactQuillRef.getEditor !== "function") return;
    // Skip if Quill reference is defined:
    if (this.quillRef != null) return;

    const quillRef = this.reactQuillRef.getEditor();
    if (quillRef != null) this.quillRef = quillRef;
  }

  handleClick(name) {
    var range = this.quillRef.getSelection();
    let position = range ? range.index : 0;
    this.quillRef.insertText(position, "{" + name + "}");
  }

  handleChange(html) {
    this.setState({ editorHtml: html }, () => {
      this.props.onChange(html);
    });
  }

  render() {
    return (
      <div>
        <ReactQuill
          ref={el => {
            this.reactQuillRef = el;
          }}
          theme={"snow"}
          onChange={this.handleChange}
          modules={Editor.modules}
          formats={Editor.formats}
          defaultValue={this.state.editorHtml}
          placeholder={this.props.placeholder}
        />
        <label>{__t("field_merge_label")} : </label>
        {this.props.embedName.map((v, i) => {
          if (v.name == "address") {
            return (
              <AdressComponents handleClick={this.handleClick} key={i} />
            );
          } else {
            return (
              <button
                className={"embed-btn"}
                key={i}
                onClick={() => {
                  this.handleClick(v.name);
                }}
                data-for='soclose'
                data-tip={Utility.getLabel(v.label)}
              >
                {v.label}
              </button>
            );
          }
        })}
        <br/>
        <label>{__t("field_merge_label_rep")} : </label>
        {this.state.embedColumns.map(v => {
          if (v.COLUMN_NAME != "ID") {
            return (<button
              className={"embed-btn"}
              key={v.COLUMN_NAME}
              onClick={() => {
                this.handleClick(v.COLUMN_NAME);
              }}
              data-for='soclose'
              data-tip={Utility.getLabel(v.COLUMN_NAME)}
            >
              {v.COLUMN_NAME}
            </button>);
          } else { return null; }

        })}

        <ReactTooltip id='soclose'
          getContent={(dataTip) =>
            <div><h3>{dataTip}</h3></div>}
          effect='solid'
          delayHide={0}
          delayShow={0}
          delayUpdate={0}
          place={'top'}
          border={true}
          type={'dark'} />
        {this.state.embedColumns.length <= 0 ? __t("select_contact_list_embed") : null}

      </div>
    );
  }
}

/*
 * Quill modules to attach to editor
 * See https://quilljs.com/docs/modules/ for complete options
 */
Editor.modules = {};
Editor.modules.toolbar = [
  ["bold", "italic", "underline", "strike"], // toggled buttons
  ["blockquote", "code-block"], // blocks
  [{ header: 1 }, { header: 2 }], // custom button values
  [{ list: "ordered" }, { list: "bullet" }], // lists
  [{ script: "sub" }, { script: "super" }], // superscript/subscript
  [{ indent: "-1" }, { indent: "+1" }], // outdent/indent
  [{ direction: "rtl" }], // text direction
  [{ size: ["small", false, "large", "huge"] }], // custom dropdown
  [{ header: [1, 2, 3, 4, 5, 6, false] }], // header dropdown
  [{ color: [] }, { background: [] }], // dropdown with defaults
  [{ font: [] }], // font family
  [{ align: [] }], // text align
  ["clean"] // remove formatting
];

/*
 * Quill editor formats
 * See https://quilljs.com/docs/formats/
 */
Editor.formats = [
  "header",
  "font",
  "background",
  "color",
  "code",
  "size",
  "bold",
  "italic",
  "underline",
  "strike",
  "blockquote",
  "list",
  "bullet",
  "indent",
  "script",
  "align",
  "direction",
  "link",
  "image",
  "code-block",
  "formula",
  "video"
];

// Editor.propTypes = {
//   placeholder: React.PropTypes.string
// };

export default Editor;
