import React from "react";
import { Redirect, Link } from "react-router-dom";
import { Checkbox } from "react-bootstrap";
import Utility from "../common/Utility";
import Alertyesno from "../common/Alertyesno";
import { __t } from "../../locale/language";
import { Container, Draggable } from "react-smooth-dnd";
import EditorModal from "./editorModal";
import $ from "jquery";
import ReactQuill from "react-quill";
import Can from "../common/AccessControl";
import SelectImageModal from "./mediaModal";
import Select from "react-select";
import {
  applyDrag,
  formElement,
  getElementByType,
  checkDuplicateBFieldName,
  defaultWidgetFormForDonation,
  defaultWidgetFormForContact,
  generateFormElement,
  filterEmbed,
} from "./utils";
import { object } from "prop-types";
import DropdownField from "./fields/dropdown";
import ReactTooltip from "react-tooltip";
import { AccessControlFunc } from "../common/AccessControl2";

class WidgetUpdate extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      title: "",
      description: "",
      status: "",
      email_template: "",
      privacy_policy: "",
      all_email_temp: [],
      redirect: false,
      errors: {},
      social_share: [],
      modal: false,
      mediaModal: false,
      edititem: {},
      type: "",
      facebook_msg: "",
      twitter_msg: "",
      defaultWidgetForm: "",
      formElement,
      typeforclient: [],
      parent: 0,
      embedName: [],
      token: localStorage.getItem("token"),
      client_id: localStorage.getItem("client_id"),
      testmail_status: "",
      subject: "",
      message: "",
      contact_list: [],
      letter_rotation_party: [{ value: "Any", label: "Any" }],
      letter_rotation_party_ary: [
        { value: "Any", label: "Any" },
        { value: "Default", label: "Default" },
      ],
      letter_rotation_province: [{ value: "Any", label: "Any" }],
      letter_rotation_province_ary: [
        { value: "Any", label: "Any" },
        { value: "Default", label: "Default" },
      ],
      contact_lists: [],
      merge_column: [],
      widget_setting: {
        widget_layout: "default",
        border_radius: "0",
        background_color: "#f1f1f1",
        text_color: "#353535",
        text_size: "12",
        widget_border_radius: 0,
        suc_message: __t("suc_message_default"),
        err_message: __t("err_message_default"),
        contact_message: __t("contact_not_found_default"),
        validation_msg_preview: __t("validation_msg_preview_default"),
        validation_msg: __t("validation_msg_default"),
        newsletter_signup_msg: __t("newsletter_signup_msg_default"),
      },
      list_options: [],
      donation_type: "",
      cc: [],
      bcc: [],
      subscription_feature: this.props.userPermissions.subscription_feature,
      user_type: this.props.userPermissions.user_type,
      contact_data: "",
      contact_label: [],
      contact_lists_count: 1,
    };
  }
  getContactList = () => {
    return new Promise(async (resolve, reject) => {
      let subs_id = localStorage.getItem("subs_id");
      let res = await Utility.executeRequest("get-data-set-rel", {
        rel: "subscription",
        rel_id: subs_id,
      });
      var arr = [];
      res.data.rows.map((val, key) => {
        var list_options = {};
        list_options["value"] = val.id;
        list_options["label"] = val.title;
        arr.push(list_options);
      });
      resolve({
        list_options: arr,
        contact_lists: res.data.rows,
      });
    }).catch((e) => {});
  };
  addCc = (e, k, type) => {
    if (type == "cc") {
      let cc = this.state.cc;
      let obj = cc[k];
      obj[e.target.name] = e.target.value;
      cc[k] = obj;
      this.setState({ cc: cc });
    } else if (type == "bcc") {
      let bcc = this.state.bcc;
      let obj = bcc[k];
      obj[e.target.name] = e.target.value;
      bcc[k] = obj;
      this.setState({ bcc: bcc });
    }
  };
  addMore = (type) => {
    if (type == "cc") {
      let cc = this.state.cc;
      cc.push({ name: "", email: "" });
      this.setState({ cc: cc });
    } else if (type == "bcc") {
      let bcc = this.state.bcc;
      bcc.push({ name: "", email: "" });
      this.setState({ bcc: bcc });
    }
  };
  removeCc = (k, type) => {
    if (type == "cc") {
      let cc = this.state.cc;
      cc.splice(k, 1);
      this.setState({ cc: cc });
    } else if (type == "bcc") {
      let bcc = this.state.bcc;
      bcc.splice(k, 1);
      this.setState({ bcc: bcc });
    }
  };
  deleteElement = (elem) => {
    let newArr = [];
    this.state.defaultWidgetForm.map((v) => {
      if (v.id !== elem.id) {
        newArr.push(v);
      }
    });
    this.setState({ defaultWidgetForm: newArr });
  };
  editElement = (elem) => {
    // console.log(elem)
    let embedName = filterEmbed(this.state.defaultWidgetForm);
    this.setState(
      { modal: true, edititem: elem, embedName: embedName },
      () => {}
    );
  };
  toggle = () => {
    this.setState({ modal: !this.state.modal, edititem: [] }, () => {});
  };

  editorChange = (value) => {
    String.prototype.replaceAll =
      String.prototype.replaceAll ||
      function (string, replaced) {
        return this.replace(new RegExp(string, "g"), replaced);
      };
    this.setState({ description: value });
  };
  privacyChange = (value) => {
    this.setState({ privacy_policy: value });
  };
  mediaToggle = () => {
    this.setState({
      modal: !this.state.modal,
      mediaModal: !this.state.mediaModal,
    });
  };

  componentDidMount() {
    let ref = this;
    console.log(this.state.subscription_feature);
    if (
      (this.state.subscription_feature.includes("widget") &&
        this.state.user_type == "client") ||
      this.state.user_type == "admin" ||
      this.state.user_type == "reseller"
    ) {
      if (this.props.userPermissions.user_type == "client") {
        this.getWidgetForClient();
      }
      if (
        typeof this.props.location.state != "undefined" &&
        this.props.location.state.id
      ) {
        $("#title").text(__t("edit_widget"));
      } else {
        ref.getContactList().then((res) => {
          ref.setState({ ...res });
        });
      }
      if (
        this.props.location.state &&
        typeof this.props.location.state !== "undefined"
      ) {
        this.findWidget();
      }
      this.findEmailTemp();
    } else {
      this.setState({
        redirect: true,
      });
    }
  }
  getContactListOnChange = async (id, contact_list) => {
    let tables = [];
    // console.log(contact_list, "................");
    Object.keys(this.state.contact_lists).map(async (key, index) => {
      // console.log(contact_list, this.state.contact_lists[key]["id"], 'jgjg');
      // console.log(this.state.contact_lists);
      if (contact_list.indexOf(this.state.contact_lists[key]["id"]) != -1) {
        tables.push(this.state.contact_lists[key].contact_list);
      }
      if (id == this.state.contact_lists[key]["id"]) {
        //do the request for columns
        let columns = await Utility.executeRequest("get-data-columns", {
          table: this.state.contact_lists[key]["contact_list"],
        });
        if (columns) {
          this.setState({ merge_column: columns.data });
        }
      }
    });
    console.log(tables, "tables");
    if (
      this.state.subscription_feature &&
      Array.isArray(this.state.subscription_feature) &&
      this.state.subscription_feature.includes("letter_rotation")
    ) {
      if (tables.length > 0) {
        // letter_rotation_party_ary: [
        //   { value: "Any", label: "Any" },
        //   { value: "Default", label: "Default" },
        // ],
        // letter_rotation_province_ary: [
        //   { value: "Any", label: "Any" },
        //   { value: "Default", label: "Default" },
        // ],

        this.setState({
          letter_rotation_party_ary: [
            { value: "Any", label: "Any" },
            { value: "Default", label: "Default" },
          ],
          letter_rotation_province_ary: [
            { value: "Any", label: "Any" },
            { value: "Default", label: "Default" },
          ],
        });

        let provinceData = await Utility.executeRequest("get-party-province", {
          table: tables.join(","),
          column: "ProvinceEng",
        });
        let partyData = await Utility.executeRequest("get-party-province", {
          table: tables.join(","),
          column: "PartyShortTitle",
        });

        if (partyData && partyData.code == 200) {
          let list = partyData.data;
          let newlist = [];
          list.map((val) => {
            if (val) {
              val.map((value) => {
                if (value.PartyShortTitle) {
                  newlist.push({
                    value: value.PartyShortTitle,
                    label: value.PartyShortTitle,
                  });
                }
              });
            }
          });
          let st = this.state.letter_rotation_party_ary;
          this.setState({
            letter_rotation_party_ary: [...st, ...newlist],
          });
        }
        if (provinceData && provinceData.code == 200) {
          let list = provinceData.data;
          let newlist = [];
          list.map((val) => {
            if (val) {
              val.map((value) => {
                newlist.push({
                  value: value["ProvinceEng"],
                  label: value["ProvinceEng"],
                });
              });
            }
          });
          let st = this.state.letter_rotation_province_ary;
          this.setState({
            letter_rotation_province_ary: [...st, ...newlist],
          });
        }
      }
    }
  };
  getWidgetForClient = () => {
    Utility.ExecuteData("getWidgetForClient", this.token, {
      client_id: this.state.client_id,
    }).then((result) => {
      this.setState({ typeforclient: result.data });
    });
  };
  findEmailTemp = () => {
    var client_id = { client_id: this.state.client_id };
    Utility.ExecuteData("findallemailtemplate", this.token, client_id).then(
      (result) => {
        this.setState({
          all_email_temp: result.data,
        });
      }
    );
  };

  findWidget = async () => {
    var id = this.props.location.state.id;
    let result = await Utility.executeRequest("findwidgetforupdate", {
      id: id,
      client_id: this.state.client_id,
      search: "",
    });

    if (result.data) {
      let clists = await this.getContactList();
      let list = this.state.contact_lists;
      let contact_list = [];
      let tables = [];
      let res = await Utility.executeRequest("get_contact_set_data", {
        id: result.data[0].id,
        type: "widget",
      });

      if (res.data.length > 0) {
        contact_list = clists.contact_lists.filter((elem) => {
          return elem.id == res.data[0].data_set_id;
        });

        res.data.map((val) => {
          clists.contact_lists.map((value) => {
            if (val.label == value.title) {
              tables.push(value.contact_list);
            }
          });
        });
      }

      if (
        this.state.subscription_feature &&
        Array.isArray(this.state.subscription_feature) &&
        this.state.subscription_feature.includes("letter_rotation")
      ) {
        if (tables.length > 0) {
          let provinceData = await Utility.executeRequest(
            "get-party-province",
            {
              table: tables.join(","),
              column: "ProvinceEng",
            }
          );
          let partyData = await Utility.executeRequest("get-party-province", {
            table: tables.join(","),
            column: "PartyShortTitle",
          });

          if (partyData && partyData.code == 200) {
            let list = partyData.data;
            let newlist = [];
            list.map((val) => {
              if (val) {
                val.map((value) => {
                  if (value.PartyShortTitle) {
                    newlist.push({
                      value: value.PartyShortTitle,
                      label: value.PartyShortTitle,
                    });
                  }
                });
              }
            });
            let st = this.state.letter_rotation_party_ary;
            this.setState({
              letter_rotation_party_ary: [...st, ...newlist],
            });
          }
          if (provinceData && provinceData.code == 200) {
            let list = provinceData.data;
            let newlist = [];
            list.map((val) => {
              if (val) {
                val.map((value) => {
                  newlist.push({
                    value: value["ProvinceEng"],
                    label: value["ProvinceEng"],
                  });
                });
              }
            });
            let st = this.state.letter_rotation_province_ary;
            this.setState({
              letter_rotation_province_ary: [...st, ...newlist],
            });
          }
        }
      }

      let clist = [];
      if (contact_list.length) {
        clist = await Utility.executeRequest("get-data-columns", {
          table: contact_list[0]["contact_list"],
        });
      }

      //sk code
      let letter_rotation_party = result.data[0].letter_rotation_party;
      let letter_rotation_province = result.data[0].letter_rotation_province;
      letter_rotation_party = letter_rotation_party.split(",");
      letter_rotation_province = letter_rotation_province.split(",");
      let letter_rotation_party_ary = [];
      let letter_rotation_province_ary = [];

      letter_rotation_party.map((val) => {
        letter_rotation_party_ary.push(
          val == 1
            ? { value: "1", label: "Default" }
            : val == 0
            ? { value: "Any", label: "Any" }
            : { value: val, label: val }
        );
      });
      letter_rotation_province.map((val) => {
        letter_rotation_province_ary.push(
          val == 1
            ? { value: "1", label: "Default" }
            : val == 0
            ? { value: "Any", label: "Any" }
            : { value: val, label: val }
        );
      });

      //sk code
      var setting =
        result.data[0].widget_setting != null
          ? JSON.parse(result.data[0].widget_setting)
          : [];
      this.setState(
        {
          title: result.data[0].title,
          status: result.data[0].status,
          description: result.data[0].description,
          letter_rotation_party: letter_rotation_party_ary, //sk
          letter_rotation_province: letter_rotation_province_ary, //sk
          contact_list:
            res.data.length > 0 ? res.data.map((v, i) => v.data_set_id) : [],
          contact_lists_count: res.data.length > 0 ? res.data.length : 1,
          email_template: result.data[0].email_template,
          facebook_msg: result.data[0].facebook_msg,
          twitter_msg: result.data[0].twitter_msg,
          widget_setting: Object.assign(this.state.widget_setting, setting),
          type: result.data[0].type,
          privacy_policy: result.data[0].privacy_policy,
          subject: result.data[0].subject,
          cc: result.data[0].cc != null ? JSON.parse(result.data[0].cc) : [],
          bcc: result.data[0].bcc != null ? JSON.parse(result.data[0].bcc) : [],
          defaultWidgetForm: result.data[0].form_json.length
            ? JSON.parse(result.data[0].form_json)
            : "",
          social_share: result.data[0].social_share.length
            ? JSON.parse(result.data[0].social_share)
            : "",
          merge_column: typeof clist.data != "undefined" ? clist.data : [],
          ...clists,
        },
        () => {
          this.updateChecked();
        }
      );
    }
  };
  setImageUrl = (url) => {
    let editItem = this.state.edititem;
    editItem["url"] = url;
    this.setState({
      edititem: editItem,
    });
  };
  updateChecked = () => {
    Object.entries(this.state.social_share).map(([key, value]) => {
      if (value == "true") {
        this.setState((prevState) => ({
          social_share: {
            ...prevState.social_share,
            [key]: !prevState.social_share[key],
          },
        }));
      }
    });
  };

  client_id = localStorage.getItem("client_id");
  token = localStorage.getItem("token");

  handleChange = (event) => {
    console.log(event.target.name);
    if (event.target.name == "email_template") {
      var current_status =
        event.target[event.target.selectedIndex].getAttribute("data-status");
      if (current_status == "inactive") {
        alert("You cannot select a inactive template");
        return;
      }
    }
    this.setState({ [event.target.name]: event.target.value });
  };
  handleValidation = async () => {
    let fields = this.state;
    let errors = {};
    let formIsValid = true;
    let emailField = await getElementByType(fields.defaultWidgetForm, "Email");

    if (emailField === null) {
      formIsValid = false;
      $("#verified_token_tk").show();
      $("#verified_token_tk").css("display", "block");
      $("#msg_text").text(__t("email_required_inwidget"));
      $("#alertinfo").show();
    }
    let dupField = await checkDuplicateBFieldName(fields.defaultWidgetForm);
    if (dupField) {
      formIsValid = false;
      $("#verified_token_tk").show();
      $("#verified_token_tk").css("display", "block");
      $("#msg_text").text(__t("dup_field_inwidget"));
      $("#alertinfo").show();
    }
    if (!fields["title"]) {
      formIsValid = false;
      errors["title"] = __t("title_empty_chk");
    }
    if (!fields["status"]) {
      formIsValid = false;
      errors["status"] = __t("select_status");
    }
    if (
      this.state.type !== "donation" &&
      this.props.userPermissions.user_type == "client" &&
      (!fields["contact_list"] || fields["contact_list"] == "select")
    ) {
      formIsValid = false;
      errors["contact_list"] = __t("please_select_contact_list");
    }

    if (
      this.props.userPermissions.user_type == "client" &&
      (fields.email_template == "" || fields["email_list"] == "")
    ) {
      formIsValid = false;
      errors["email_list"] = __t("please_select_email_list");
    }
    if (!fields["privacy_policy"]) {
      formIsValid = false;
      errors["privacy_policy"] = __t("privacy_policy_not_exists");
    }
    if (
      !this.props.location.state &&
      typeof this.props.location.state === "undefined"
    ) {
      if (!fields["type"] || fields["type"] == 0) {
        formIsValid = false;
        errors["type"] = __t("select_type");
      }
    }

    this.setState({ errors: errors });
    console.log(formIsValid);
    return formIsValid;
  };
  createWidget = async () => {
    if (await this.handleValidation()) {
      if (
        this.props.location.state &&
        typeof this.props.location.state !== "undefined"
      ) {
        this.setState({ id: this.props.location.state.id }, () => {
          // console.log('update')
          // console.log(JSON.stringify(this.state))
          Utility.ExecuteData("updatewidget", this.token, this.state)
            .then((responseJson) => {
              if (responseJson.code === 200) {
                // this.setState({ redirect: true });
                $("#msg_text").text(__t("successfully_updated_campaign"));
                $("#alertinfo").css("display", "block");
              } else {
                alert("Something went wront. Try Again.");
              }
            })
            .catch((error) => {
              this.setState({ errors: true });
            });
        });
      } else {
        console.log(this.state);

        // return false;
        // console.log(JSON.stringify(this.state));
        Utility.ExecuteData("createwidget", this.token, this.state)
          .then((responseJson) => {
            if (responseJson.code === 200) {
              $("#msg_text").text("Successfully saved");
              $("#alertinfo").css("display", "block");
            } else {
              alert("Something went wront. Try Again.");
            }
          })
          .catch((error) => {
            this.setState({ errors: true });
          });
      }
    }
  };
  getWidget = (event) => {
    if (this.props.userPermissions.user_type !== "client") {
      let value = event.target.value;
      if (value == "contact") {
        this.setState({
          [event.target.name]: value,
          defaultWidgetForm: defaultWidgetFormForContact,
        });
      } else if (value == "donation") {
        this.setState({
          [event.target.name]: value,
          defaultWidgetForm: defaultWidgetFormForDonation,
        });
      } else {
        this.setState({
          [event.target.name]: value,
          defaultWidgetForm: "",
        });
      }
    } else {
      let value = event.target.value;
      this.state.typeforclient.map((val, key) => {
        if (val.id == value) {
          var data = JSON.parse(val.form_json);
          var settings =
            val.widget_setting != null ? JSON.parse(val.widget_setting) : {};
          this.setState({
            [event.target.name]: val.id,
            type: val.type,
            defaultWidgetForm: data,
            twitter_msg: val.twitter_msg,
            facebook_msg: val.facebook_msg,
            privacy_policy: val.privacy_policy,
            description: val.description,
            subject: val.subject,
            donation_type: value,
            cc: [],
            bcc: [],
            widget_setting: Object.assign(this.state.widget_setting, settings),
          });
        }
      });
    }
  };
  handleChecked = (e) => {
    const { name } = e.target;
    this.setState((prevState) => ({
      social_share: {
        ...prevState.social_share,
        [name]: !prevState.social_share[name],
      },
    }));
  };
  selectEmailTemplate = (e) => {
    if (
      this.state.donation_type == "" &&
      this.props.userPermissions.user_type !== "client"
    ) {
      $("#msg_text").text(__t("please_select_type"));
      $("#alertinfo").show();
    } else {
      let tempid = e.target.value;
      this.state.all_email_temp.map((obj) => {
        if (obj.id === parseInt(tempid)) {
          this.setState({
            newTemplate: e.target.value,
            cc: JSON.parse(obj.cc),
            bcc: JSON.parse(obj.bcc),
          });
          $("#alertshow").css("display", "block");
        }
      });
    }
  };
  changeTemplate = () => {
    let selectedTemplate = this.state.newTemplate;
    let message = "";
    let subject = "";
    this.state.all_email_temp.map((v) => {
      if (selectedTemplate == v.id) {
        message = v.message;
        subject = v.subject;
      }
    });

    let formElement = this.state.defaultWidgetForm;
    if (
      message !== null &&
      message !== "" &&
      typeof formElement !== "undefined"
    ) {
      formElement.map((v, k) => {
        if (v.element == "Text Area") {
          formElement[k]["text"] = message;
        }
      });
    }

    this.setState({
      email_template: selectedTemplate,
      defaultWidgetForm: formElement,
      subject: subject,
    });
  };
  contact_id = (e) => {
    let clist = e.target.value;
    let list = this.state.contact_lists;
    let contact_list = list.filter((elem) => {
      return elem.id == clist;
    });
    if (contact_list.length) {
      var data = Utility.ExecuteData("get-data-columns", this.token, {
        table: contact_list[0]["contact_list"],
      })
        .then((responseJson) => {
          if (responseJson.code === 200) {
            this.setState({
              contact_list: clist,
              merge_column: responseJson.data,
            });
          } else {
            alert("Something went wront. Try Again.");
          }
        })
        .catch((error) => {
          this.setState({ errors: true });
        });
      console.log("Data " + data);
    } else {
      this.setState({
        contact_list: [],
        merge_column: [],
      });
    }
  };
  updateSetting = (e) => {
    this.setState({
      widget_setting: Object.assign(this.state.widget_setting, {
        [e.target.name]: e.target.value,
      }),
    });
  };
  addMoreContactLists = (e) => {
    if (this.state.contact_lists_count < 5) {
      this.setState({
        contact_lists_count: this.state.contact_lists_count + 1,
      });
    }
  };
  //sk code
  multiHandleChangeList = (letter_rotation_party) => {
    var arr = [];
    console.log(letter_rotation_party, "letter_rotation_party");
    letter_rotation_party.map((val, key) => {
      // if (letter_rotation_party.length > 0 && val.label != "Any") {
      arr.push({ value: val.value, label: val.label });
      // } else if (letter_rotation_party.length == 1 && val.label == "Any") {
      //   arr.push({ value: val.value, label: val.label });
      // }
    });
    console.log(arr);
    this.setState({ letter_rotation_party: arr });
  };
  provinceMultiHandleChangeList = (letter_rotation_province) => {
    var arr = [];
    letter_rotation_province.map((val, key) => {
      // if (letter_rotation_province.length > 0 && val.label != "Any") {
      arr.push({ value: val.value, label: val.label });
      // } else if (letter_rotation_province.length == 1 && val.label == "Any") {
      //   arr.push({ value: val.value, label: val.label });
      // }
    });
    this.setState({ letter_rotation_province: arr });
  };
  //sk code
  render() {
    var text_size_option = [];
    for (var i = 13; i < 50; i++) {
      text_size_option.push(
        <option key={"opt" + i} value={i}>
          {i + "px"}
        </option>
      );
    }
    const { redirect } = this.state;
    if (redirect) {
      return <Redirect push to="/widgets/" />;
    }

    return (
      <Can
        I={["update-widget"]}
        userPermissions={this.props.userPermissions.userPermissions}
      >
        <div style={{ height: "none" }}>
          <div className="row wrapper border-bottom white-bg page-heading">
            <div className="col-lg-10">
              <h2 style={{ marginTop: 10 }}>{__t("widget")}</h2>
              <ol className="breadcrumb">
                <li className="breadcrumb-item">
                  <Link to="/">{__t("dashboard")}</Link>
                </li>
                <li className="breadcrumb-item">
                  <Link to="/widgets">{__t("widget")}</Link>
                </li>
                <li className="breadcrumb-item active">
                  <strong id="title">{__t("create_widget")}</strong>
                </li>
              </ol>
            </div>
          </div>
          <div className="">
            <div className="row">
              <div className="col-md-12">
                <div className="ibox float-e-margins">
                  {/* <div className="ibox-title">
                    <h5>{__t("preview")}</h5>
                  </div> */}
                  <div className="ibox-content">
                    <div className="row">
                      <div className="col-md-6">
                        {this.props.location.state &&
                        typeof this.props.location.state !== "undefined" ? (
                          ""
                        ) : this.props.userPermissions.user_type !==
                          "client" ? (
                          <div className="form-group">
                            <label className="control-label required-field">
                              {__t("type")}
                            </label>
                            <select
                              value={this.state.type}
                              name="type"
                              className="form-control"
                              onChange={this.getWidget}
                            >
                              <option value="0">{__t("select_type")}</option>
                              <option value="contact">
                                {__t("contact_widget")}
                              </option>
                              <option value="donation">
                                {__t("donation_widget")}
                              </option>
                            </select>
                            <label id="-error" className="error">
                              {this.state.errors["type"]}
                            </label>
                          </div>
                        ) : (
                          <div className="form-group">
                            <label className="control-label required-field">
                              {__t("type")}
                            </label>
                            <select
                              value={this.state.parent}
                              name="parent"
                              className="form-control"
                              onChange={this.getWidget}
                            >
                              <option value="">{__t("select_type")}</option>
                              {this.state.typeforclient.map((val, key) => {
                                return (
                                  <option key={key} value={val.id}>
                                    {val.title}
                                  </option>
                                );
                              })}
                            </select>
                          </div>
                        )}
                      </div>
                    </div>

                    <div className="widget-info row">
                      <div className="form-group col-md-6">
                        <label className="control-label required-field">
                          {__t("title")}
                        </label>
                        <input
                          type="text"
                          value={this.state.title}
                          className="form-control"
                          name="title"
                          placeholder={__t("title")}
                          onChange={this.handleChange}
                        />
                        <label id="-error" className="error">
                          {this.state.errors["title"]}
                        </label>
                      </div>
                      <div className="form-group col-md-6">
                        <label className="control-label required-field">
                          {__t("status")}
                        </label>
                        <select
                          value={this.state.status}
                          name="status"
                          className="form-control"
                          onChange={this.handleChange}
                        >
                          <option value="">{__t("select_status")}</option>
                          <option value="active">{__t("active")}</option>
                          <option value="inactive">{__t("inactive")}</option>
                        </select>
                        <label id="-error" className="error">
                          {this.state.errors["status"]}
                        </label>
                      </div>
                      <div className="form-group col-md-6">
                        <label className="control-label">
                          {__t("description")}
                        </label>
                        <ReactQuill
                          placeholder={__t("description")}
                          value={this.state.description}
                          onChange={this.editorChange}
                        />
                      </div>
                      <div className="col-md-6">
                        <div className="form-group">
                          <label className="control-label required-field">
                            {__t("privacy_policy")}
                          </label>
                          <ReactQuill
                            placeholder={__t("privacy_policy")}
                            value={this.state.privacy_policy}
                            onChange={this.privacyChange}
                          />
                          &nbsp;&nbsp;
                          <label id="-error" className="error">
                            {this.state.errors["privacy_policy"]}
                          </label>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-6 col-sm-6 col-xs-12">
                        <div className="form-group">
                          <Checkbox
                            name="facebook"
                            checked={this.state.social_share["facebook"]}
                            onChange={this.handleChecked}
                          >
                            &nbsp;&nbsp;
                            {__t("share_widget_url_on_facebook")}
                          </Checkbox>

                          <textarea
                            name="facebook_msg"
                            value={this.state.facebook_msg}
                            className="form-control"
                            placeholder={__t("fb_message")}
                            onChange={this.handleChange}
                          />
                        </div>
                      </div>
                      <div className="col-md-6 col-sm-6 col-xs-12">
                        <div className="form-group">
                          <Checkbox
                            name="twitter"
                            checked={this.state.social_share["twitter"]}
                            onChange={this.handleChecked}
                          >
                            &nbsp;&nbsp;
                            {__t("tweet_widget_url_on_twitter")}
                          </Checkbox>

                          <textarea
                            name="twitter_msg"
                            value={this.state.twitter_msg}
                            className="form-control"
                            placeholder={__t("twitter_message")}
                            onChange={this.handleChange}
                          />
                        </div>
                      </div>

                      <div className="col-md-6 col-sm-6 col-xs-12">
                        {this.props.userPermissions.user_type === "client" &&
                        this.state.type == "contact" ? (
                          <div className="form-group form-inline">
                            <label
                              className="control-label required-field"
                              style={{ width: "100%", display: "block" }}
                            >
                              {__t("contact_list")}
                            </label>
                            <br />

                            <>
                              {this.state.contact_lists_count > 0
                                ? [
                                    ...Array(this.state.contact_lists_count),
                                  ].map((x, i) => {
                                    return (
                                      <>
                                        <DropdownField
                                          className="col-sm-10"
                                          label={__t("Select_contact_list")}
                                          defaultValue={
                                            this.state.contact_list[i]
                                              ? this.state.contact_list[i]
                                              : ""
                                          }
                                          options={this.state.list_options}
                                          onChange={(e) => {
                                            let contact_list =
                                              this.state.contact_list;
                                            contact_list[i] = parseInt(
                                              e.target.value
                                            );
                                            this.setState(
                                              { contact_list },
                                              () => {
                                                this.getContactListOnChange(
                                                  contact_list[0],
                                                  contact_list
                                                );
                                              }
                                            );
                                          }}
                                        />
                                        {i > 0 ? (
                                          <i
                                            className="fa fa-trash col-sm-2"
                                            style={{ fontSize: "18px" }}
                                            onClick={() => {
                                              let contact_list =
                                                this.state.contact_list;
                                              contact_list.splice(i, 1);
                                              let contact_lists_count =
                                                this.state.contact_lists_count -
                                                1;
                                              this.setState({
                                                contact_list,
                                                contact_lists_count,
                                              });
                                            }}
                                          >
                                            &nbsp;
                                          </i>
                                        ) : (
                                          ""
                                        )}
                                        <br />
                                      </>
                                    );
                                  })
                                : ""}
                              {this.state.contact_lists_count < 5 ? (
                                <a
                                  className="add_more_button"
                                  href="javascript:void(0);"
                                  onClick={this.addMoreContactLists}
                                >
                                  {__t(" + Add more.")}{" "}
                                </a>
                              ) : (
                                ""
                              )}
                            </>

                            <label id="-error" className="error">
                              {this.state.errors["contact_list"]}
                            </label>
                          </div>
                        ) : (
                          ""
                        )}
                      </div>
                      {/* //sk code */}

                      {this.state.subscription_feature &&
                        Array.isArray(this.state.subscription_feature) &&
                        this.state.subscription_feature.includes(
                          "letter_rotation"
                        ) && (
                          <div className="col-md-6 col-sm-6 col-xs-12">
                            <div >
                              <div className="form-group">
                                <label className="control-label">
                                  {__t("letter_rotation_by_party")}
                                </label>

                                <Select
                                  value={this.state.letter_rotation_party}
                                  isMulti
                                  onChange={this.multiHandleChangeList}
                                  options={this.state.letter_rotation_party_ary}
                                  name="rotation_by_party"
                                />
                              </div>
                            </div>
                            <div >
                              <div className="form-group">
                                <label className="control-label">
                                  {__t("letter_rotation_by_province_region")}
                                </label>

                                <Select
                                  value={this.state.letter_rotation_province}
                                  isMulti
                                  onChange={this.provinceMultiHandleChangeList}
                                  options={
                                    this.state.letter_rotation_province_ary
                                  }
                                  name="rotation_by_province_region"
                                />
                              </div>
                            </div>
                          </div>
                        )}
                      {/* sk code */}
                      {this.props.userPermissions.user_type === "client" &&
                      this.state.defaultWidgetForm ? (
                        <React.Fragment>
                          <div className={"col-md-12 col-sm-12 col-xs-12"}>
                            <div className={"row"}>
                              <div className="col-md-6 col-sm-6 col-xs-6">
                                <div className="form-group">
                                  <label className="control-label required-field">
                                    {__t("email_template")}
                                  </label>
                                  <select
                                    name="email_template"
                                    className="form-control"
                                    rows="5"
                                    value={this.state.email_template}
                                    onChange={(e) => {
                                      this.selectEmailTemplate(e);
                                    }}
                                  >
                                    <option value="">
                                      {__t("select_email_temp")}{" "}
                                    </option>
                                    {this.state.all_email_temp.map(
                                      (val, key) => {
                                        return (
                                          <option
                                            className={val.status + "_plan"}
                                            data-status={val.status}
                                            key={key}
                                            value={val.id}
                                          >
                                            {val.title}{" "}
                                            {val.status == "inactive"
                                              ? "(Inactive)"
                                              : ""}
                                          </option>
                                        );
                                      }
                                    )}
                                  </select>

                                  <label id="-error" className="error">
                                    {this.state.errors["email_list"]}
                                  </label>
                                </div>

                                <div className="row form-group">
                                  <div className="col-md-6 col-sm-6 col-xs-12">
                                    {this.state.cc.map((v, k) => {
                                      return (
                                        <div className="row" key={k}>
                                          <div className="col-5">
                                            <input
                                              type="text"
                                              name={"name"}
                                              value={v.name}
                                              className="col-md-12 form-control mr-2"
                                              placeholder={__t("name")}
                                              onChange={(e) => {
                                                this.addCc(e, k, "cc");
                                              }}
                                            />
                                          </div>
                                          <div className="col-5">
                                            <input
                                              type="text"
                                              name={"email"}
                                              value={v.email}
                                              className="col-md-12 form-control"
                                              placeholder={__t("enter_email")}
                                              onChange={(e) => {
                                                this.addCc(e, k, "cc");
                                              }}
                                              onKeyUp={(e) => {
                                                let val = e.target.value;
                                                let lastAtPos =
                                                  val.lastIndexOf("@");
                                                let lastDotPos =
                                                  val.lastIndexOf(".");

                                                if (
                                                  !(
                                                    lastAtPos < lastDotPos &&
                                                    lastAtPos > 0 &&
                                                    val.indexOf("@@") == -1 &&
                                                    lastDotPos > 2 &&
                                                    val.length - lastDotPos > 2
                                                  )
                                                ) {
                                                  e.target.style.border =
                                                    "1px solid rgb(245, 40, 40)";
                                                } else {
                                                  e.target.style.border =
                                                    "1px solid #e5e6e7";
                                                }
                                              }}
                                            />
                                          </div>
                                          <div className="col-2">
                                            <button
                                              className="btn btn-small"
                                              onClick={() => {
                                                this.removeCc(k, "cc");
                                              }}
                                            >
                                              <i
                                                className="fa fa-trash"
                                                aria-hidden="true"
                                              />
                                            </button>
                                          </div>
                                        </div>
                                      );
                                    })}
                                    <button
                                      className="btn btn-small ml-2"
                                      onClick={() => {
                                        this.addMore("cc");
                                      }}
                                    >
                                      <i
                                        className="fa fa-plus mr-2"
                                        aria-hidden="true"
                                      />
                                      CC
                                    </button>
                                    <label id="-error" className="error">
                                      {this.state.errors["cc"]}
                                    </label>
                                  </div>
                                  <div className="col-md-6 col-sm-6 col-xs-12">
                                    {this.state.bcc.map((v, k) => {
                                      return (
                                        <div className="row" key={k}>
                                          <div className="col-5">
                                            <input
                                              type="text"
                                              name={"name"}
                                              value={v.name}
                                              className="col-md-12 form-control"
                                              placeholder={__t("name")}
                                              onChange={(e) => {
                                                this.addCc(e, k, "bcc");
                                              }}
                                            />
                                          </div>
                                          <div className="col-5">
                                            <input
                                              type="text"
                                              name={"email"}
                                              value={v.email}
                                              className="col-md-12 form-control"
                                              placeholder={__t("enter_email")}
                                              onChange={(e) => {
                                                this.addCc(e, k, "bcc");
                                              }}
                                              onKeyUp={(e) => {
                                                let val = e.target.value;
                                                let lastAtPos =
                                                  val.lastIndexOf("@");
                                                let lastDotPos =
                                                  val.lastIndexOf(".");

                                                if (
                                                  !(
                                                    lastAtPos < lastDotPos &&
                                                    lastAtPos > 0 &&
                                                    val.indexOf("@@") == -1 &&
                                                    lastDotPos > 2 &&
                                                    val.length - lastDotPos > 2
                                                  )
                                                ) {
                                                  e.target.style.border =
                                                    "1px solid rgb(245, 40, 40)";
                                                } else {
                                                  e.target.style.border =
                                                    "1px solid #e5e6e7";
                                                }
                                              }}
                                            />
                                          </div>
                                          <div className="col-2">
                                            <button
                                              className="btn btn-small"
                                              onClick={() => {
                                                this.removeCc(k, "bcc");
                                              }}
                                            >
                                              <i
                                                className="fa fa-trash "
                                                aria-hidden="true"
                                              />
                                            </button>
                                          </div>
                                        </div>
                                      );
                                    })}
                                    <button
                                      className="btn btn-small"
                                      onClick={() => {
                                        this.addMore("bcc");
                                      }}
                                    >
                                      <i
                                        className="fa fa-plus mr-2"
                                        aria-hidden="true"
                                      />
                                      BCC
                                    </button>
                                    <label id="-error" className="error">
                                      {this.state.errors["bcc"]}
                                    </label>
                                  </div>
                                </div>
                              </div>
                              <div className={"col-md-6 col-sm-6 col-xs-12"}>
                                <label className={"control-label"}>
                                  {__t("Subject")}
                                </label>
                                <input
                                  type={"text"}
                                  value={
                                    this.state.subject !== null
                                      ? this.state.subject
                                      : ""
                                  }
                                  onChange={(e) => {
                                    this.setState({ subject: e.target.value });
                                  }}
                                  name={"subject"}
                                  className={"form-control"}
                                />
                              </div>
                            </div>
                          </div>
                        </React.Fragment>
                      ) : (
                        ""
                      )}
                    </div>

                    {this.state.defaultWidgetForm ? (
                      <React.Fragment>
                        <div className="row">
                          <div
                            className="alert alert-danger w-alert"
                            role="alert"
                          >
                            <strong>{__t("disclaimer")}:</strong>{" "}
                            {__t("disclaimer_msg")}{" "}
                          </div>
                          <div
                            className="alert alert-info w-alert"
                            role="alert"
                          >
                            <strong>{__t("info")}:</strong>{" "}
                            {__t("test_widget_email_msg")}{" "}
                          </div>

                          <div className="row widget-editor-area widget-editor-area_fix mt-2 col-sm-8">
                            <div
                              className={
                                "widget_" +
                                this.state.widget_setting.widget_layout
                              }
                              style={{
                                borderRadius:
                                  this.state.widget_setting
                                    .widget_border_radius + "px",
                              }}
                            >
                              <div
                                className="widget-container drop-zone"
                                style={{
                                  background:
                                    this.state.widget_setting.background_color,
                                  color: this.state.widget_setting.text_color,
                                }}
                              >
                                <div
                                  className="outer"
                                  style={{ height: "inherit", padding: "0px" }}
                                >
                                  <Container
                                    groupName="1"
                                    dragClass="form-ghost"
                                    dropClass="form-ghost-drop"
                                    onDrop={this.onDrop}
                                  >
                                    {this.generateForm(
                                      this.state.defaultWidgetForm
                                    )}
                                  </Container>
                                </div>
                              </div>
                            </div>

                            <div className="clearfix" />
                          </div>

                          <div className="col-md-4 col-sm-4">
                            <ul className="toolbox">
                              <li>{__t("toolbox")}</li>
                              {AccessControlFunc(
                                ["update-user"],
                                this.props.userPermissions,
                                [
                                  "letter_autoresponder",
                                  "newsletter_autoresponder",
                                ]
                              ) && (
                                <li>
                                  <div className="form-group">
                                    <label className="control-label">
                                      {__t("Enable Newsletter Signup")}
                                    </label>
                                    <select
                                      name="newsletter_signup"
                                      className="form-control"
                                      value={
                                        this.state.widget_setting
                                          .newsletter_signup
                                      }
                                      onChange={this.updateSetting}
                                    >
                                      <option value="0">
                                        {__t("Enabled")}
                                      </option>
                                      <option value="1">
                                        {__t("Disabled")}
                                      </option>
                                    </select>
                                  </div>
                                </li>
                              )}
                              <li>
                                <div className="form-group">
                                  <label className="control-label required-field">
                                    {__t("remove_contact_ability")}
                                  </label>
                                  <select
                                    name="contact_exclude_ability"
                                    className="form-control"
                                    value={
                                      this.state.widget_setting
                                        .contact_exclude_ability
                                    }
                                    onChange={this.updateSetting}
                                  >
                                    <option value="0">{__t("Enabled")}</option>
                                    <option value="1">{__t("Disabled")}</option>
                                  </select>
                                </div>
                              </li>
                              <li>
                                <div className="form-group">
                                  <label className="control-label required-field">
                                    {__t("message_preview_button_label")}
                                  </label>
                                  <input
                                    name="preview_button_label"
                                    className="form-control"
                                    value={
                                      this.state.widget_setting
                                        .preview_button_label
                                    }
                                    onChange={this.updateSetting}
                                  />
                                </div>
                              </li>
                              <li>
                                <div className="form-group">
                                  <label className="control-label required-field">
                                    {__t("widget_layout")}
                                  </label>
                                  <select
                                    name="widget_layout"
                                    className="form-control"
                                    value={
                                      this.state.widget_setting.widget_layout
                                    }
                                    onChange={this.updateSetting}
                                  >
                                    <option value="default">
                                      {__t("default")}
                                    </option>
                                    <option value="fullwidth">
                                      {__t("full_width")}
                                    </option>
                                    <option value="box">{__t("boxed")}</option>
                                    {/* <option value="rounded">{__t("rounded")}</option> */}
                                    <option value="left_align">
                                      {__t("left_align")}
                                    </option>
                                    <option value="right_align">
                                      {__t("right_align")}
                                    </option>
                                    <option value="center_align">
                                      {__t("center_align")}
                                    </option>
                                  </select>
                                </div>
                              </li>
                              <li>
                                <div className="form-group">
                                  <label className="control-label required-field">
                                    {__t("widget_border_radius")}
                                  </label>
                                  <input
                                    type="range"
                                    value={
                                      this.state.widget_setting
                                        .widget_border_radius
                                    }
                                    name="widget_border_radius"
                                    className="form-control"
                                    min="0"
                                    max="20"
                                    onChange={this.updateSetting}
                                  />
                                </div>
                              </li>
                              <li>
                                <div className="form-group">
                                  <label className="control-label required-field">
                                    {__t("input_radius")}
                                  </label>
                                  <input
                                    type="range"
                                    value={
                                      this.state.widget_setting.border_radius
                                    }
                                    name="border_radius"
                                    className="form-control"
                                    min="0"
                                    max="30"
                                    onChange={this.updateSetting}
                                  />
                                </div>
                              </li>
                              <li>
                                <div className="form-group">
                                  <label className="control-label required-field">
                                    {__t("background_color")}
                                  </label>
                                  <input
                                    type="color"
                                    value={
                                      this.state.widget_setting.background_color
                                    }
                                    name="background_color"
                                    className="form-control"
                                    style={{ height: "30px" }}
                                    onChange={this.updateSetting}
                                  />
                                </div>
                              </li>
                              <li>
                                <div className="form-group">
                                  <label className="control-label required-field">
                                    {__t("text_color")}
                                  </label>
                                  <input
                                    type="color"
                                    value={this.state.widget_setting.text_color}
                                    name="text_color"
                                    className="form-control"
                                    style={{ height: "30px" }}
                                    onChange={this.updateSetting}
                                  />
                                </div>
                              </li>
                              <li>
                                <div className="form-group">
                                  <label className="control-label required-field">
                                    {__t("text_size")}
                                  </label>
                                  <select
                                    name="text_size"
                                    className="form-control"
                                    value={this.state.widget_setting.text_size}
                                    onChange={this.updateSetting}
                                  >
                                    <option value="12">
                                      {__t("default_size")}
                                    </option>
                                    {text_size_option.map((v) => {
                                      return v;
                                    })}
                                  </select>
                                </div>
                              </li>
                              <li>
                                <strong>Draggable Elements.</strong>
                              </li>
                              <Container
                                groupName="1"
                                behaviour="copy"
                                getChildPayload={(i) =>
                                  this.state.formElement[i]
                                }
                                onDrop={(e) => {
                                  //this.setState({
                                  //items1: applyDrag(this.state.items1, e)});
                                }}
                              >
                                {this.state.formElement.map((v, k) => {
                                  let li = <li>{v.element}</li>;
                                  if (
                                    v.element == "Checkbox" ||
                                    v.element == "Radiobtn"
                                  ) {
                                    li = <li>{v.elem_name}</li>;
                                    if (v.element == "Radiobtn") {
                                      if (
                                        AccessControlFunc(
                                          ["update-user"],
                                          this.props.userPermissions,
                                          [
                                            "letter_autoresponder",
                                            "newsletter_autoresponder",
                                          ]
                                        )
                                      ) {
                                        li = <li>{v.elem_name}</li>;
                                      } else {
                                        li = "";
                                      }
                                    }
                                  }

                                  return li ? (
                                    <Draggable key={k}>
                                      <div className="draggable-item">{li}</div>
                                    </Draggable>
                                  ) : (
                                    <div key={k} />
                                  );
                                })}
                              </Container>
                              <li>
                                <p>{__t("drag_drop_msg")}</p>
                              </li>
                              <li>
                                <div className="form-group">
                                  <label className="control-label required-field">
                                    {__t("suc_message")}
                                  </label>
                                  <input
                                    type="text"
                                    value={
                                      this.state.widget_setting.suc_message
                                    }
                                    name="suc_message"
                                    className="form-control"
                                    style={{ height: "30px" }}
                                    onChange={this.updateSetting}
                                  />
                                </div>
                              </li>
                              <li>
                                <div className="form-group">
                                  <label className="control-label required-field">
                                    {__t("err_message")}
                                  </label>
                                  <input
                                    type="text"
                                    value={
                                      this.state.widget_setting.err_message
                                    }
                                    name="err_message"
                                    className="form-control"
                                    style={{ height: "30px" }}
                                    onChange={this.updateSetting}
                                  />
                                </div>
                              </li>
                              <li>
                                <div className="form-group">
                                  <label className="control-label required-field">
                                    {__t("contact_not_found")}
                                  </label>
                                  <input
                                    type="text"
                                    value={
                                      this.state.widget_setting.contact_message
                                    }
                                    name="contact_message"
                                    className="form-control"
                                    style={{ height: "30px" }}
                                    onChange={this.updateSetting}
                                  />
                                </div>
                              </li>
                              <li>
                                <div className="form-group">
                                  <label className="control-label required-field">
                                    {__t("validation_msg")}
                                  </label>
                                  <input
                                    type="text"
                                    value={
                                      this.state.widget_setting.validation_msg
                                    }
                                    name="validation_msg"
                                    className="form-control"
                                    style={{ height: "30px" }}
                                    onChange={this.updateSetting}
                                  />
                                </div>
                              </li>
                              <li>
                                <div className="form-group">
                                  <label className="control-label required-field">
                                    {__t("validation_msg_preview")}
                                  </label>

                                  <input
                                    type="text"
                                    value={
                                      this.state.widget_setting
                                        .validation_msg_preview
                                    }
                                    name="validation_msg_preview"
                                    className="form-control"
                                    style={{ height: "30px" }}
                                    onChange={this.updateSetting}
                                  />
                                </div>
                              </li>
                              {AccessControlFunc(
                                ["update-user"],
                                this.props.userPermissions,
                                ["newsletter_autoresponder"]
                              ) && (
                                <li>
                                  <div className="form-group">
                                    <label className="control-label required-field">
                                      {__t("Newsletter Confirmation message")}
                                    </label>
                                    <input
                                      type="text"
                                      value={
                                        this.state.widget_setting
                                          .newsletter_signup_msg
                                      }
                                      name="newsletter_signup_msg"
                                      className="form-control"
                                      style={{ height: "30px" }}
                                      onChange={this.updateSetting}
                                    />
                                  </div>
                                </li>
                              )}
                            </ul>
                          </div>
                        </div>
                      </React.Fragment>
                    ) : (
                      ""
                    )}

                    <div className="form-group">
                      <button
                        className="btn btn-primary"
                        onClick={this.createWidget}
                        type="submit"
                      >
                        {this.props.location.state &&
                        typeof this.props.location.state !== "undefined"
                          ? __t("update")
                          : __t("create")}
                      </button>{" "}
                      &nbsp;&nbsp;
                      <Link to="/widgets" className="btn btn-white">
                        {__t("cancel")}
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <EditorModal
            isOpen={this.state.modal}
            item={this.state.edititem}
            merge_column={this.state.merge_column}
            toggle={this.toggle}
            mediaToggle={this.mediaToggle}
            embedName={this.state.embedName}
            userPermissions={this.props.userPermissions}
          />
          <SelectImageModal
            isOpen={this.state.mediaModal}
            mediaToggle={this.mediaToggle}
            setImageUrl={this.setImageUrl}
          />
        </div>
        <Alertyesno
          cnfrm_msg={__t("chng_email_template_replace_widget_msg")}
          cnfrm_delete={this.changeTemplate}
        />
      </Can>
    );
  }
  onDrop = (dropResult) => {
    if (this.state.defaultWidgetForm) {
      var newForm = new Array();
      newForm = applyDrag(this.state.defaultWidgetForm, dropResult).then(
        (result) => {
          let finalForm = new Array();

          result.map((v, k) => {
            if (typeof v !== "undefined") {
              var obj = {};
              obj = Object.assign({ id: k }, v);
              obj["id"] = k;
              if (typeof v.id == "undefined" && typeof v.name != "undefined") {
                let name = obj["name"];
                let label = obj["label"];
                result.map((dup) => {
                  if (
                    dup.name == obj["name"] &&
                    dup.name !== "address" &&
                    dup.name !== "newsletter_signup"
                  ) {
                    name = obj["name"] + (result.length + 1);
                    label = obj["label"] + " " + (result.length + 1);
                  }
                });
                obj["name"] = name;
                obj["label"] = label;
              }
              finalForm.push(obj);
            }
          });
          return this.setState({ defaultWidgetForm: finalForm }, () => {});
        }
      );
    }
  };

  generateForm = (form) => {
    if (this.state.defaultWidgetForm) {
      let i = 0;
      var skipp_delete = [];
      let all_items = [];
      let items_updated = false;
      if (
        this.props.userPermissions.user_type == "client" ||
        this.props.userPermissions.user_type == "reseller"
      ) {
        skipp_delete = [
          "address",
          "donation_amount",
          "message",
          "email",
          "Submit button",
        ];
      }
      let existing = [];
      let fullform = [];
      form.map((item) => {
        try {
          if (existing.indexOf(item.id) != -1) {
            item.id = item.id + 100; //to be safe from not overwriting any other values , as there couldn't be 100 fields
            items_updated = true;
          }
          existing.push(item.id);
        } catch (error) {}

        all_items.push(item);
        fullform.push(
          <Draggable key={i++}>
            <div className="dragable-items">
              {generateFormElement(
                item,
                this.handleChange,
                this.state.widget_setting
              )}
              <div className="btn_area">
                <span
                  className="edit-btn"
                  onClick={() => this.editElement(item)}
                >
                  <i className="fa fa-pencil" />
                </span>
                {skipp_delete.includes(item.name) === false ? (
                  <span
                    className="delete-btn"
                    onClick={() => this.deleteElement(item)}
                  >
                    <i className="fa fa-trash" />
                  </span>
                ) : (
                  ""
                )}
              </div>
            </div>
          </Draggable>
        );
      });
      if (items_updated) {
        //all this becuase there was error in utils default widget form
        this.setState({ defaultWidgetForm: all_items });
      }
      return fullform;
    }
  };
}

export default WidgetUpdate;
