import React, { Component } from "react";

export const generateFormElement = (item, handleChange) => {
  ////console.log(item);
  switch (item.element) {
    case "Headings":
      return (
        <div className={`form-group`}>
          <h1 className={item.className}>{item.text}</h1>
        </div>
      );
    case "Paragraph":
      return <p className={item.className + " para_widgit"}>{item.text}</p>;
    case "Text Field":
      return (
        <div className="form-items ">
          <div className={`form-group`}>
            {/* <label className="control-label">{item.label}</label> */}
            <input
              type="text"
              data-required={item.required ? "required" : ""}
              data-validation={item.validation ? item.validation : ""}
              name={item.name}
              placeholder={item.placeholder}
              className={item.className + " check-validation"}
            />
          </div>
        </div>
      );
    case "Text Area":
      return (
        <div className="form_items_textarea">
          <div className={`form-group`}>
            {/* <label className="control-label">{item.label}</label> */}
            <textarea
              name={item.name}
              row={item.row}
              data-required={item.required ? "required" : ""}
              data-validation={item.validation ? item.validation : ""}
              col={item.col}
              className={item.className + " check-validation"}
              defaultValue={item.text}
              onChange={handleChange}
            />
          </div>
        </div>
      );
    case "Selection List":
      return (
        <div className="form-items ">
          <div className={`form-group`}>
            <label className="control-label">{item.label}</label>
            <select name={item.name} className={item.className}>
              <option>Please Select One</option>
            </select>
          </div>
        </div>
      );
    case "Image":
      return (
        <div className="pos_rel">
          <img
            src={item.url}
            className={item.className}
            height={item.height + "%"}
            width={item.width + "%"}
          />
          <div className="title_img">
            <div className="image-heading">{item.heading}</div>
            <div className="image-paragraph">{item.paragraph}</div>
          </div>
        </div>
      );
    case "Checkbox":
      return <h1 />;
    case "Donation":
      return (
        <div className="pos_rel">
          {item.donation_arr.map(val => {
            return (
              <div className="donate_section">
                <input type="radio"id="donate_price" checked="checked" name="donation_amount" />
                
                <label  for="donate_price"></label><span>{val}</span>
               
              </div>
            );
          })}
        </div>
      );
  }
};
