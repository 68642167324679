import React from "react";
import { Link } from "react-router-dom";
import RemotePagination from "../common/Table";
import Utility from "../common/Utility";
import Spinner from "../common/Spinner";
import { __t } from "../../locale/language";
import Replymodal from "./replyModal";
import ViewModal from "./ViewModal";
import Moment from "moment";
import { CSVLink } from "react-csv";
import $ from "jquery";
import Axios from "axios";
import { AccessControlFunc } from "../common/AccessControl2";

const BASE_URL = Utility.baseUrlBe() + "deletecamprecords";
let now = Moment(new Date());
let dateTime = now.format("MM-DD-YY");

class Index extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      page: 1,
      data: [],
      sizePerPage: 10,
      total: 0,
      search: "",
      campaign: [],
      campaign_id: "",
      widget: [],
      related_clients: [],
      csvdata: [],
      widget_id: "",
      related_client_id: "",
      related_camp_id: "",
      client_id: localStorage.getItem("client_id"),
      user_type: this.props.userPermissions.user_type,
      modal: false,
      row_data: "",
      to: "",
      cc: "",
      bcc: "",
      reply: "",
      token: localStorage.getItem("token"),
      subject: "",
      email_template: "",
      template_list: [],
      viewmodal: false,
      clientlist: "",
      get_client_id: "",
      camp_list: "",
      default_clientlist: "",
      startPoint: 0,
      recordLimit: 500,
      exportLoading: true,
      csvFileName: `Export-Submissions.csv`,
      csvFilePath: "",
      widgetType: "Widgetemail",
    };
    this.emailList = this.emailList.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.getallcampaign = this.getallcampaign.bind(this);
    this.campChange = this.campChange.bind(this);
    this.widgetChange = this.widgetChange.bind(this);
    this.getallrelatedclients = this.getallrelatedclients.bind(this);
    this.clientChange = this.clientChange.bind(this);
    this.getcsv = this.getcsv.bind(this);
    this.replytouser = this.replytouser.bind(this);
    this.mediaToggle = this.mediaToggle.bind(this);
    this.sendwidgetemailreply = this.sendwidgetemailreply.bind(this);
    this.Toggle = this.Toggle.bind(this);
    this.getclient = this.getclient.bind(this);
    this.getclientcamp = this.getclientcamp.bind(this);
    this.delCampRecord = this.delCampRecord.bind(this);
    this.deleteAllRows = this.deleteAllRows.bind(this);
  }
  handleChange(event) {
    this.setState({ [event.target.name]: event.target.value });
  }
  getTemplate = () => {
    Utility.ExecuteData("gettemplateforschedule", this.state.token, {}).then(
      (template_list) => {
        this.setState({ template_list: template_list.templates });
      }
    );
  };
  changeTemplate = (e) => {
    let temp_id = e.target.value;
    if (temp_id !== "") {
      this.state.template_list.map((v) => {
        if (v.id == temp_id) {
          this.setState({
            email_template: temp_id,
            subject: v.subject,
            cc: v.cc,
            bcc: v.bcc,
            reply: v.message,
          });
        }
      });
    } else {
      this.setState({
        email_template: temp_id,
        subject: "",
        cc: "",
        bcc: "",
        reply: "",
      });
    }
  };
  replytouser(cell, row, rowIndex) {
    return (
      <div>
        {cell == "yes" ? (
          <div
            className="btn btn-primary"
            onClick={(e) => {
              this.mediaToggle(row);
            }}
          >
            Reply
          </div>
        ) : (
          ""
        )}
        &nbsp;
        <div
          className="btn btn-primary"
          onClick={(e) => {
            this.Toggle(row);
          }}
        >
          {__t("view")}
        </div>
        &nbsp;
        <div
          className="btn btn-danger"
          onClick={(e) => {
            this.delCampRecord(row);
          }}
        >
          {__t("Delete")}
        </div>
      </div>
    );
  }
  sendwidgetemailreply(to, widget_email_id) {
    if (this.state.email_template) {
      var data = {
        to: to,
        cc: this.state.cc,
        bcc: this.state.bcc,
        subject: this.state.subject,
        message: this.state.reply,
        email_template_id: this.state.email_template,
        widget_email_id: widget_email_id,
        client_id: this.state.client_id,
      };
      Utility.ExecuteData("sendwidgetemailreply", this.state.token, data).then(
        (response) => {
          if (response.code === 200) {
            this.setState({ modal: !this.state.modal });
          }
        }
      );
    } else {
      alert("Please select a Email Template");
      return;
    }
  }
  campChange(event) {
    let promise = new Promise((resolve, reject) => {
      if (event.target.value !== "0") {
        this.state.campaign.map((val, key) => {
          if (val.id == event.target.value) {
            var camp_widget = JSON.parse(val.widgets);
            this.setState({
              campaign_id: event.target.value,
              widget: camp_widget,
              widget_id: "",
            });
            resolve("set");
          }
        });
      } else {
        this.setState({
          campaign_id: event.target.value,
          widget: [],
          widget_id: "",
        });
        resolve("set");
      }
    });
    promise.then((message) => {
      this.emailList();
      this.setState({ csvdata: [], startPoint: 0, exportLoading: true }, () => {
        this.getcsv();
      });
    });
  }
  deleteAllRows() {
    if (window.confirm("Are you sure to delete all record?")) {
      // alert("ok-" + $("#camp_list").val());
      // alert("ok-" + JSON.stringify(this.state.data[0].client_id));
      var actkn =
        typeof this.state.token != "undefined"
          ? "Bearer " + this.state.token
          : "";
      let inputBody = {
        camp_id: $("#camp_list").val(),
        client_id:
          this.state && this.state.data[0].client_id
            ? this.state.data[0].client_id
            : null,
        widgetType: this.state.widgetType,
      };
      const config = {
        headers: {
          authorization: actkn,
        },
      };
      Axios.post(BASE_URL, inputBody, config)
        .then((response) => {
          // console.log(response);
          this.emailList();
          this.getcsv();
          // window.location.reload();
        })
        .catch((error) => {
          // console.log(error);
          this.setState({ errors: true });
        });
    }
  }
  delCampRecord(row) {
    if (window.confirm("Are you sure to delete this record?")) {
      // alert("ok-" + JSON.stringify(row.client_id));
      var actkn =
        typeof this.state.token != "undefined"
          ? "Bearer " + this.state.token
          : "";
      let inputBody = {
        row_id: row.id,
        client_id: row.client_id,
        widgetType: this.state.widgetType,
      };
      const config = {
        headers: {
          authorization: actkn,
        },
      };
      Axios.post(BASE_URL, inputBody, config)
        .then((response) => {
          // console.log(response);
          this.emailList();
          this.getcsv();
        })
        .catch((error) => {
          // console.log(error);
          this.setState({ errors: true });
        });
    }
  }
  mediaToggle(row) {
    this.setState({
      modal: !this.state.modal,
      row_data: row ? row : [],
    });
  }
  Toggle(row) {
    this.setState({
      viewmodal: !this.state.viewmodal,
      row_data: row ? row : [],
    });
  }
  widgetChange(event) {
    let promise = new Promise((resolve, reject) => {
      this.setState({
        widget_id: event.target.value,
      });
      resolve("set");
    });
    promise.then((message) => {
      this.emailList();
      this.getcsv();
    });
  }
  editorChange = (value) => {
    this.setState({ reply: value });
  };
  getclientcamp() {
    var camp_list = [];
    let promise = new Promise((resolve, reject) => {
      this.state.campaign.map((val, key) => {
        if (val.client_id == this.state.client_id) {
          camp_list.push(val);
          var camp_widget = JSON.parse(val.widgets);
          this.setState({
            widget: camp_widget,
            widget_id: "",
            camp_list: camp_list,
            related_client_id: this.state.client_id,
          });
          resolve("set");
        }
      });
    });
    promise.then((message) => {
      this.emailList();
      // this.getcsv();
    });
  }
  clientChange(event) {
    var camp_list = [];
    let promise = new Promise((resolve, reject) => {
      this.state.campaign.map((val, key) => {
        if (val.client_id == event.target.value) {
          camp_list.push(val);
          var camp_widget = JSON.parse(val.widgets);
          this.setState({
            //  campaign_id: event.target.value,
            // campaign_id: val.id,
            widget: camp_widget,
            widget_id: "",
            camp_list: camp_list,
          });
          resolve("set");
        }
      });
      this.setState({
        related_client_id: event.target.value,
        get_client_id: event.target.value,
      });
      resolve("set");
    });
    promise.then((message) => {
      // this.emailList();
      // this.getcsv();
      // this.getallcampaign();
    });
  }
  getclient(event) {
    let target_val = event.target.value;
    let type = "";
    var related_client_id = "";
    if (event.target.value !== "") {
      if (event.target.value == "admin") {
        related_client_id = 1;
      } else if (event.target.value == "self") {
        related_client_id = "";
      } else {
        related_client_id = event.target.value;
      }
      if (target_val != "admin" && target_val != "self") {
        type = "reseller";
      } else {
        type = "";
      }
      Utility.ExecuteData("getclientdata", this.token, {
        id: event.target.value,
        type: type,
      }).then((data) => {
        if (data.code === 200) {
          this.setState(
            {
              campaign: data.data,
              clientlist: data.clientlist,
              related_client_id: related_client_id,
              camp_list: "",
            },
            () => {
              this.emailList();
              this.getcsv();
              this.getallcampaign();
            }
          );
        } else {
          //  console.log("no data found");
        }
      });
    } else {
      this.setState(
        {
          related_client_id: "",
        },
        () => {
          this.emailList(1, 10);
          this.getallrelatedclients();
          this.getcsv();
          this.getTemplate();
        }
      );
    }
  }
  getallcampaign() {
    Utility.ExecuteData("getallcampaign", this.token, {
      id: this.state.related_camp_id,
    }).then((data) => {
      if (data.code === 200) {
        this.setState({ campaign: data.data }, () => {
          if (this.state.user_type == "client") {
            this.getclientcamp();
          }
        });
      } else {
        console.log("no data found");
      }
    });
  }
  getallrelatedclients() {
    Utility.ExecuteData("getclientdata", this.token, {
      client_id: this.state.client_id,
      user_type: this.state.user_type,
    }).then((data) => {
      if (data.code === 200) {
        this.setState({
          related_clients: data.data,
          clientlist: data.clientlist,
          default_clientlist: data.clientlist,
        });
      } else {
        console.log("no data found");
      }
    });
  }
  client_id = localStorage.getItem("client_id");
  token = localStorage.getItem("token");
  handleTableChange = (type, { page, sizePerPage }) => {
    const currentIndex = (page - 1) * sizePerPage;
    //write fetch data function
    this.emailList(page, sizePerPage);
    this.getcsv();
  };

  emailList(page = 1, sizePerPage = 10) {
    Utility.ExecuteData("widgetemaildata", this.token, {
      page: page,
      limit: sizePerPage,
      search: this.state.search,
      user_type: this.state.user_type,
      campaign_id: this.state.campaign_id,
      widget_id: this.state.widget_id,
      related_client_id: this.state.related_client_id,
      client_id: this.state.client_id,
      widgetType: this.state.widgetType,
    }).then((data) => {
      this.setState(() => ({
        page,
        data: data.data.rows,
        sizePerPage,
        total: data.data.count,
      }));
    });
  }

  getcsv(
    startPoint = this.state.startPoint,
    recordLimit = this.state.recordLimit
  ) {
    // let now = Moment(new Date());
    // let dateTime = now.format("DD-MMM-YYYY HH:mm:ss");
    let promise = new Promise((resolve, reject) => {
      Utility.ExecuteData(
        "download_csv",
        this.token,
        {
          search: this.state.search,
          user_type: this.state.user_type,
          campaign_id: this.state.campaign_id,
          widget_id: this.state.widget_id,
          related_client_id: this.state.related_client_id,
          startPoint,
          recordLimit,
          widgetType: this.state.widgetType,
        },
        false
      ).then((result) => {
        resolve(result);
      });
    });
    promise.then((res) => {
      if (res.code == 200) {
        let rand3 = Math.floor(Math.random() * (999 - 100 + 1) + 100);
        let csvFileName = `Export-Submissions--${dateTime}-${rand3}.csv`;
        this.setState({
          csvFileName,
          csvFilePath: res.data,
          exportLoading: false,
        });
      }
    });
  }

  getcsv_Old(
    startPoint = this.state.startPoint,
    recordLimit = this.state.recordLimit
  ) {
    // let now = Moment(new Date());
    // let dateTime = now.format("DD-MMM-YYYY HH:mm:ss");
    let promise = new Promise((resolve, reject) => {
      Utility.ExecuteData(
        "csvdata",
        this.token,
        {
          search: this.state.search,
          user_type: this.state.user_type,
          campaign_id: this.state.campaign_id,
          widget_id: this.state.widget_id,
          related_client_id: this.state.related_client_id,
          startPoint,
          recordLimit,
        },
        false
      ).then((result) => {
        var arr = [];
        result.data.map((val, k) => {
          let json1 = val.widget_json;
          delete val.widget_json;
          delete val[0];
          arr[k] = val;
          var json = JSON.parse(json1);
          Object.entries(json).map(([key, value]) => {
            arr[k][key] = value;
          });
        });
        resolve(arr);
      });
    });
    promise.then((arr) => {
      var newarr = this.state.csvdata;
      //  if (arr[1]) {
      arr.map((val, k) => {
        var count = Object.keys(val).length;

        var obj = {};
        for (var i = 0; i <= count; i++) {
          let valParse =
            typeof val[i] == "string" ? JSON.parse(val[i]) : val[i];
          if (this.state.campaign_id == val.campaign_id) {
            if (val[i]) {
              if (val[i].name == "campaign_update") {
                if (val[i].value == "") {
                  val[i].value = "No";
                } else {
                  val[i].value = "Yes";
                }
              }
              if (val[i].name != "privacy_policy" && val[i].name != "message") {
                obj[val[i].label] = val[i].value;
              }
            } else {
              obj["Campaign"] = val["Campaign"];
              obj["Contact Category"] = "";
              obj["Contact email"] = val["Contact email"];
              obj["Contact name"] = val["Contact name"];
              // obj["Domain"] = val['Domain']
              obj["Widget"] = val["Widget"];
              obj["status"] = val["status"];
              let createdAt = Moment(val["created_at"]).format(
                "DD-MMM-YYYY HH:mm:ss"
              );
              obj["Date"] = createdAt;
            }
          }
        }
        if (obj["Campaign"]) {
          newarr.push(obj);
        }
      });
      // }
      const newStartPoint = startPoint + recordLimit;
      this.setState({ csvdata: newarr, startPoint: newStartPoint }, () => {
        if (arr.length === recordLimit) {
          this.getcsv(newStartPoint, recordLimit);
        } else {
          let rand3 = Math.floor(Math.random() * (999 - 100 + 1) + 100);
          let csvFileName = `Export-Submissions--${dateTime}-${rand3}.csv`;
          this.setState({ csvFileName });
          setTimeout(() => {
            this.setState({ exportLoading: false });
          }, 3000);
        }
      });
    });
  }

  componentDidMount() {
    this.emailList(1, 10);
    this.getallrelatedclients();
    if (this.state.user_type == "admin") {
      // this.getcsv();
      this.getallcampaign();
    }

    this.getTemplate();
    if (this.state.user_type == "client") {
      this.getallcampaign();
    }
  }
  handleWidgetType = (e) => {
    let { name, value } = e.target;
    this.setState({ [name]: value }, () => {
      this.emailList();
      this.getcsv();
    });
  };
  render() {
    const { data, sizePerPage, page, total } = this.state;
    const columns = [
      { dataField: "contact_name", text: __t("contact_name") },
      { dataField: "contact_email", text: __t("contact_email") },
      {
        dataField: "created_at",
        text: __t("created_at"),
        formatter: (cellContent) =>
          Moment(cellContent).format("DD-MMM-YYYY HH:mm:ss"),
      },
      {
        dataField: "campaign_updates",
        text: "Action",
        formatter: this.replytouser.bind(this),
      },
    ];
    return (
      <div>
        <div className="row wrapper border-bottom white-bg page-heading">
          <div className="col-lg-10">
            <h2 style={{ marginTop: 10 }}>{__t("widget_email_list")}</h2>
            <strong style={{ float: "right" }}>
              {__t("total_record")}:{this.state.total}
            </strong>
            <ol className="breadcrumb">
              <li className="breadcrumb-item">
                <Link to="/">{__t("dashboard")}</Link>
              </li>
              <li className="breadcrumb-item">
                <Link to="/widget-email">{__t("widget_email_list")}</Link>
              </li>
            </ol>
          </div>
        </div>

        <div className="wrapper wrapper-content ">
          <div className="row">
            <div className="col-lg-12">
              <div className="ibox ">
                <div className="ibox-title">
                  <div className="row from-control">
                    <div className="col-lg-3">
                      <select
                        name="widgetType"
                        className="form-control"
                        onChange={this.handleWidgetType}
                        defaulValue={this.state.widgetType}
                      >
                        <option value="Widgetemail">Widget Email</option>
                        {AccessControlFunc(
                          ["update-user"],
                          this.props.userPermissions,
                          ["newsletter_autoresponder"]
                        ) && (
                          <option value="Newsletter">
                            Autoresponder Newsletter Email
                          </option>
                        )}
                        {AccessControlFunc(
                          ["update-user"],
                          this.props.userPermissions,
                          ["letter_autoresponder"]
                        ) && (
                          <option value="Letter">
                            Autoresponder Letter Email
                          </option>
                        )}
                      </select>
                    </div>
                    <div className="col-lg-3">
                      <input
                        className="form-control"
                        type="text"
                        name="search"
                        id="search_field"
                        onChange={this.handleChange}
                        placeholder={__t("search_by_name_email")}
                      />
                    </div>
                    <div className="col-lg-1">
                      <input
                        className="btn btn-primary"
                        type="button"
                        onClick={() => {
                          this.emailList();
                        }}
                        name="btn_search"
                        value={__t("search")}
                      />
                    </div>
                    {this.state.user_type !== "client" ? (
                      <div className="col-lg-3">
                        <select
                          name="related_client"
                          className="form-control"
                          onChange={this.getclient}
                          defaultValue={this.state.related_client_id}
                          id="resellerlist"
                        >
                          <option value="">--{__t("select_reseller")}--</option>
                          <option value="admin">{__t("admin")}</option>
                          <option value="self">{__t("self_registered")}</option>
                          {this.state.related_clients.map((val, key) => {
                            return (
                              <option key={key} value={val.id}>
                                {val.name}
                              </option>
                            );
                          })}
                        </select>
                      </div>
                    ) : (
                      ""
                    )}
                    {this.state.user_type !== "client" ? (
                      <div className="col-lg-3">
                        <select
                          name="camp"
                          className="form-control"
                          onChange={this.clientChange}
                          defaulValue={this.state.get_client_id}
                          id="clientlist"
                        >
                          <option value="select">
                            --{__t("select_client")}--
                          </option>
                          {this.state.clientlist
                            ? this.state.clientlist.map((val, key) => {
                                return (
                                  <option key={key} value={val.id}>
                                    {val.name}
                                  </option>
                                );
                              })
                            : ""}
                        </select>
                      </div>
                    ) : (
                      ""
                    )}
                    <div className="col-lg-3">
                      <select
                        name="widget"
                        className="form-control"
                        //onChange={this.widgetChange}
                        onChange={this.campChange}
                        defaulValue={this.state.campaign_id}
                        id="camp_list"
                      >
                        <option value="" id="camplist">
                          --{__t("select_campaign")}--
                        </option>
                        {/* {this.state.widget.map((val, key) => { */}
                        {this.state.camp_list
                          ? this.state.camp_list.map((val, key) => {
                              return (
                                <option key={key} value={val.id}>
                                  {val.title}
                                </option>
                              );
                            })
                          : ""}
                      </select>
                    </div>
                    <div className="col-lg-1">
                      <input
                        className="btn btn-warning"
                        type="button"
                        onClick={() => {
                          $("#resellerlist").val("");
                          $("#camp_list").val("");
                          $("#clientlist").val("select");

                          if (this.state.user_type == "admin") {
                            this.setState(
                              {
                                search: "",
                                widget_id: "",
                                campaign_id: "",
                                related_client_id: "",
                                // campaign: [],
                                widget: [],
                                camp_list: "",
                                clientlist: this.state.default_clientlist,
                              },
                              () => {
                                this.emailList();
                                this.getcsv();
                                document.getElementById("search_field").value =
                                  "";
                              }
                            );
                          } else {
                            this.setState(
                              {
                                search: "",
                                widget_id: "",
                                campaign_id: "",
                                // campaign: [],
                                widget: [],
                                camp_list: "",
                              },
                              () => {
                                this.emailList();
                                this.getallcampaign();
                                this.getcsv();
                                document.getElementById("search_field").value =
                                  "";
                              }
                            );
                          }
                        }}
                        name="btn_search"
                        value={__t("Clear")}
                      />
                    </div>
                    <div className="col-lg-1">
                      {this.state.data.length > 0 &&
                      this.state.camp_list != "" &&
                      this.state.total &&
                      $("#camp_list").val() != "" ? (
                        this.state.exportLoading ? (
                          <i
                            class="fa fa-spinner fa-3x fa-spin"
                            aria-hidden="true"
                          ></i>
                        ) : (
                          <a
                            className="btn btn-primary"
                            // href={`data:text/csv;charset=utf-8, ${escape(this.state.csvFilePath)}`}
                            href={this.state.csvFilePath}
                            download={this.state.csvFileName}
                            // data={this.state.csvdata}
                          >
                            {__t("export")}
                          </a>
                        )
                      ) : (
                        ""
                      )}
                    </div>
                    {this.state.oldCsvLinkFalse &&
                      this.state.data.length > 0 &&
                      this.state.camp_list != "" &&
                      this.state.total && (
                        <div className="col-lg-1">
                          {$("#camp_list").val() != "" ? (
                            this.state.exportLoading ? (
                              <i
                                class="fa fa-spinner fa-3x fa-spin"
                                aria-hidden="true"
                              ></i>
                            ) : (
                              <CSVLink
                                filename={this.state.csvFileName}
                                className="btn btn-primary"
                                data={this.state.csvdata}
                              >
                                {__t("export")}
                              </CSVLink>
                            )
                          ) : (
                            ""
                          )}
                        </div>
                      )}
                    {this.state.data.length > 0 &&
                      this.state.camp_list != "" &&
                      this.state.total && (
                        <div className="col-lg-1">
                          {$("#camp_list").val() != "" ? (
                            <input
                              className="btn btn-danger"
                              type="button"
                              onClick={this.deleteAllRows}
                              name="btn_deleteall"
                              value={__t("Delete All")}
                            />
                          ) : (
                            ""
                          )}
                        </div>
                      )}
                  </div>
                </div>
                <div className="ibox-content">
                  <Spinner />
                  {this.state.total > 0 ? (
                    <RemotePagination
                      columns={columns}
                      data={data}
                      page={page}
                      sizePerPage={sizePerPage}
                      totalSize={total}
                      onTableChange={this.handleTableChange}
                    />
                  ) : (
                    __t("no_record_found")
                  )}
                  <Replymodal
                    isOpen={this.state.modal}
                    mediaToggle={this.mediaToggle}
                    data={this.state}
                    handleChange={this.handleChange}
                    editorChange={this.editorChange}
                    changeTemplate={this.changeTemplate}
                    sendwidgetemailreply={this.sendwidgetemailreply}
                  />
                  <ViewModal
                    isOpen={this.state.viewmodal}
                    Toggle={this.Toggle}
                    data={this.state.row_data}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Index;
