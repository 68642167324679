import React, { Component } from "react";
import Utility from "../common/Utility";
import Moment from "moment";
import { __t } from "../../locale/language";
class ResellerDB extends Component {
  constructor(props) {
    super(props);
    this.state = {
      client_id: localStorage.getItem("client_id"),
      token: localStorage.getItem("token"),
      user_type: Utility.getIdFromToken(localStorage.getItem("token")),
      client_count: "",
      user_count: "",
      campaign_count: "",
      widgets_count: "",
      camp_count: "",
    };
    this.getClientData = this.getClientData.bind(this);
    this.getUserData = this.getUserData.bind(this);
    this.getCampaignData = this.getCampaignData.bind(this);
    this.getWidgetsData = this.getWidgetsData.bind(this);
    this.getCampaignList = this.getCampaignList.bind(this);
  }
  getClientData() {
    //console.log(this.state.user_type);
    Utility.ExecuteData("ClientCount", this.state.token, {
      token: this.state.token,
      user_type: this.state.user_type,
      type: "client",
      client_id: this.state.client_id,
    }).then((data) => {
      //console.log(data.data[0]);
      let count = "";
      if (data.data && Array.isArray(data.data)) {
        count = data.data[0];
      } else {
        count = data.data;
      }

      this.setState(() => ({
        client_count: count,
      }));
    });
  }
  getUserData() {
    //console.log(this.state.user_type);
    Utility.ExecuteData("UserCount", this.state.token, {
      token: this.state.token,
      user_type: this.state.user_type,
      type: "user",
      client_id: this.state.client_id,
    }).then((data) => {
      //console.log(data.data[0]);
      let count = "";
      if (data.data && Array.isArray(data.data)) {
        count = data.data[0];
      } else {
        count = data.data;
      }

      this.setState(() => ({
        user_count: count,
      }));
    });
  }

  getCampaignData() {
    //console.log(this.state.user_type);
    Utility.ExecuteData("CampaignCount", this.state.token, {
      token: this.state.token,
      user_type: this.state.user_type,
      type: "campaign",
      client_id: this.state.client_id,
    }).then((data) => {
      //console.log(data.data[0]);
      let count = "";
      if (data.data && Array.isArray(data.data)) {
        count = data.data[0];
      } else {
        count = data.data;
      }

      this.setState(() => ({
        campaign_count: count,
      }));
    });
  }
  getWidgetsData() {
    //console.log(this.state.user_type);
    Utility.ExecuteData("WidgetsCount", this.state.token, {
      token: this.state.token,
      user_type: this.state.user_type,
      type: "widgets",
      client_id: this.state.client_id,
    }).then((data) => {
      //console.log(data.data[0]);
      let count = "";
      if (data.data && Array.isArray(data.data)) {
        count = data.data[0];
      } else {
        count = data.data;
      }

      this.setState(() => ({
        widgets_count: count,
      }));
    });
  }
  componentDidMount() {
    this.getClientData();
    this.getUserData();
    this.getCampaignData();
    this.getWidgetsData();
    this.getCampaignList();
    this.getLetterReport();
  }

  getLetterReport = () => {
    Utility.ExecuteData("getadminlettersrecord", this.state.token, {
      token: this.state.token,
      user_type: this.state.user_type,
    }).then((data) => {
      //console.log(data);
      this.setState({
        letter_report: data.data,
      });
    });
  };
  getCampaignList() {
    Utility.ExecuteData("getCampaignList", this.state.token, {
      token: this.state.token,
      user_type: this.state.user_type,
      type: "user",
    }).then((data) => {
      //console.log(data);
      this.setState({
        camp_list: data.data,
        camp_count: data.count[0].total,
      });
    });
  }

  renderClientLetterCounts() {
    const clientLetterCounts = {};

    if (
      !Array.isArray(this.state.letter_report) ||
      this.state.letter_report.length === 0
    ) {
      return (
        <tr>
          <td colSpan="5">No records found</td>
        </tr>
      );
    }

    // this.state.camp_list.forEach((val) => {
    //   const client = val.client_name;
    //   const month = Moment(val.publish_on).format("MMMM");
    //   const year = Moment(val.publish_on).format("YYYY");
    //   const key = client + month + year;

    //   if (!clientLetterCounts[key]) {
    //     clientLetterCounts[key] = {
    //       client: client,
    //       reseller: val.reseller_name || "N/A",
    //       month: month,
    //       year: year,
    //       count: val.letter_sent,
    //     };
    //   } else {
    //     clientLetterCounts[key].count += val.letter_sent;
    //   }
    // });

    // const sortedClientLetterCounts = Object.values(clientLetterCounts).sort(
    //   (a, b) => {
    //     // Sort by client name alphabetically
    //     if (a.client < b.client) return -1;
    //     if (a.client > b.client) return 1;

    //     // Sort by total letter sent
    //     if (a.count > b.count) return -1;
    //     if (a.count < b.count) return 1;

    //     // Sort by month and year
    //     const aDate = Moment(`${a.month} ${a.year}`, "MMMM YYYY");
    //     const bDate = Moment(`${b.month} ${b.year}`, "MMMM YYYY");
    //     return bDate - aDate;
    //   }
    // );

    if (this.state.letter_report.length > 0) {
      return this.state.letter_report.map((val, index) => (
        <tr key={index}>
          <td>{val.client_name}</td>
          <td>{val.date.split(" ")[0]}</td>
          <td>{val.date.split(" ")[1]}</td>
          <td>{val.letter_sent}</td>
        </tr>
      ));
    } else {
      return (
        <tr>
          <td colSpan="5">No records found</td>
        </tr>
      );
    }
  }
  render() {
    var client;
    var user;
    var campaign;
    var widgets;
    if (
      typeof this.state.client_count != "undefined" &&
      typeof this.state.campaign_count != "undefined" &&
      typeof this.state.widgets_count != "undefined"
    ) {
      client = this.state.client_count;
      user = this.state.user_count;
      campaign = this.state.campaign_count;
      widgets = this.state.widgets_count;
    }
    return (
      <div>
        <div className="row">
          {client && (
            <div className="col-lg-3">
              <div className="ibox ">
                <div className="ibox-title">
                  <h5>{__t("client")}</h5>
                </div>
                <div className="ibox-content">
                  <h1 className="no-margins">
                    {client.Total}/{client.Total == 0 ? "0" : client.Active}/
                    {client.Total == 0 ? "0" : client.Inactive}
                  </h1>
                  <div className="stat-percent font-bold text-success" />
                  <small>
                    {__t("total")}/{__t("active")}/{__t("inactive")}
                  </small>
                </div>
              </div>
            </div>
          )}

          <div className="col-lg-3">
            <div className="ibox ">
              <div className="ibox-title">
                <h5>{__t("staff_users")}</h5>
              </div>
              <div className="ibox-content">
                <h1 className="no-margins">
                  {user.Total}/{user.Total == 0 ? "0" : user.Active}/
                  {user.Total == 0 ? "0" : user.Inactive}
                </h1>
                <div className="stat-percent font-bold text-success" />
                <small>
                  {__t("total")}/{__t("active")}/{__t("inactive")}
                </small>
              </div>
            </div>
          </div>

          <div className="col-lg-3">
            <div className="ibox ">
              <div className="ibox-title">
                <h5>{__t("campaign")}</h5>
              </div>
              <div className="ibox-content">
                <h1 className="no-margins">
                  {campaign.Total}/{campaign.Total == 0 ? "0" : campaign.Active}
                  /{campaign.Total == 0 ? "0" : campaign.Inactive}
                </h1>
                <div className="stat-percent font-bold text-success" />
                <small>
                  {__t("total")}/{__t("active")}/{__t("inactive")}
                </small>
              </div>
            </div>
          </div>
          <div className="col-lg-3">
            <div className="ibox ">
              <div className="ibox-title">
                <h5>{__t("widgets")}</h5>
              </div>
              <div className="ibox-content">
                <h1 className="no-margins">
                  {widgets.Total}/{widgets.Total == 0 ? "0" : widgets.Active}/
                  {widgets.Total == 0 ? "0" : widgets.Inactive}
                </h1>
                <div className="stat-percent font-bold text-success" />
                <small>
                  {__t("total")}/{__t("active")}/{__t("inactive")}
                </small>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-6">
            <div className="ibox float-e-margins">
              <div className="ibox-title">
                <h5>{__t("campaign")}</h5>
                <h5 style={{ float: "right" }}>
                  {__t("campaign_count")}:{this.state.camp_count}
                </h5>
              </div>
              <div className="ibox-content p0">
                <table className="table table-hover no-margins">
                  <thead>
                    <tr>
                      <th className="border_top0">{__t("campaign")}</th>
                      <th className="border_top0">{__t("clients")}</th>
                      <th className="border_top0">{__t("start_from")}</th>
                      <th className="border_top0">{__t("letter_sent")}</th>
                    </tr>
                  </thead>
                  <tbody>
                    {this.state.camp_list && this.state.camp_list.length > 0 ? (
                      this.state.camp_list.map((val, k) => {
                        //console.log(val);
                        return (
                          <tr key={k}>
                            <td>{val.title} </td>
                            <td>{val.client_name}</td>
                            <td>
                              <small>
                                {Moment(val.publish_on).format(
                                  "DD-MMM-YYYY HH:mm:ss"
                                )}
                              </small>
                            </td>
                            <td>{val.letter_sent}</td>
                            <td className="text-navy"> </td>
                          </tr>
                        );
                      })
                    ) : (
                      <tr>
                        <td></td>
                        <td>{__t("no_record_found")}</td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
        <div
          className="col-lg-6"
          style={{ height: "300px", overflow: "hidden", "overflow-y": "auto" }}
        >
          <div className="ibox float-e-margins">
            <div className="ibox-content p0">
              <table className="table table-hover">
                <thead>
                  <tr>
                    <th>
                      Clients<i class="fa fa-fw fa-sort"></i>
                    </th>
                    <th>Month</th>
                    <th>Year</th>
                    <th>Letter Sent</th>
                  </tr>
                </thead>
                <tbody>{this.renderClientLetterCounts()}</tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default ResellerDB;
