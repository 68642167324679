import React from "react";
import CampaignList from "./CampaignList";
import Analytics from "./Analytics";
import CampaignUpdate from "./CampaignUpdate";
import { Route, Switch } from "react-router-dom";
class Index extends React.Component {
  constructor(props) {
    super(props);
  }
  render() {
    return (
      <Switch>
        <Route
          path="/campaign/create"
          render={props => (
            <CampaignUpdate
              {...props}
              userPermissions={this.props.userPermissions}
            />
          )}
        />
        <Route
          path="/campaign/create/"
          render={props => (
            <CampaignUpdate
              {...props}
              userPermissions={this.props.userPermissions}
            />
          )}
        />
        <Route
          path="/campaign/update/:id"
          render={props => (
            <CampaignUpdate
              {...props}
              userPermissions={this.props.userPermissions}
            />
          )}
        />
        <Route
          path="/campaign/analytics/:id"
          render={props => (
            <Analytics
              {...props}
              userPermissions={this.props.userPermissions}
            />
          )}
        />
        <Route
          path="/campaign"
          render={props => (
            <CampaignList
              {...props}
              userPermissions={this.props.userPermissions}
            />
          )}
        />
      </Switch>
    );
  }
}

export default Index;
