export const eng = {
  admin: "Admin",
  reseller: "Reseller",
  social_point: "Social Capital Points",
  enter_social_point: "Enter social capital points",
  tag: "Tags",
  client: "Client",
  log_out: "Logout",
  log_in: "Login",
  register: "Register",
  search: "Search",
  clear: "Clear",
  clients: "Clients",
  create_client: "Create Client",
  update_client: "Update Client",
  user: "Staff User",
  user_list: "User List",
  create_user: "Create User",
  update_user: "Update User",
  role: "User Roles",
  edit: "Edit",
  delete: "Delete",
  add: "Add",
  update: "Update",
  create: "Create",
  cancel: "Cancel",
  delete_confirm: "Are you sure you want to delete?",
  first_name: "First Name",
  last_name: "Last Name",
  email: "Email",
  uname: "Username",
  password: "Password",
  conf_password: "Confirm Password",
  contact: "Contact",
  contact_persons: "Contact Persons",
  status: "Status",
  active: "Active",
  inactive: "Inactive",
  message_conf_pwd: "Confirm Password cannot be empty.",
  message_pwd_match: "Password and Confirm Password do not match.",
  message_pwd_empty: "Password cannot be empty.",
  message_email_pwd_empty: "Email and Password are invalid.",
  chk_empty_fname: "First Name cannot be empty.",
  chk_empty_uname: "User Name cannot be empty.",
  select_status: "Please select a Status",
  chk_empty_email: "Email cannot be empty.",
  chk_valid_email: "Email is not valid.",
  try_again: "Something went wrong. Try Again.",
  title: "Title",
  description: "Description",
  create_role: "Create Role",
  select_title: "Title cannot be empty.",
  subscription: "Subscription",
  your_subscription: "Your Subscription has",
  please_click_the_renew_button: "Please click the renew button to renew / update your subscription.",
  subscription_list: "Subscription List",
  duration: "Duration",
  price: "Price",
  select_duration: "Duration cannot be empty.",
  select_price: "Price cannot be empty. ",
  paymentgateway: "Payment Gateway",
  uname: "User Name",
  office_number: "Office Number",
  user_designation: "User Designation",
  user_address: "User Address",
  templates: "Templates",
  email_templates: "Widget Email Template",
  email_temp: "Email Template",
  widgets_list: "Widget List",
  widgets: "Widgets",
  create_widget: "Create Widget",
  text: "Text",
  custom_css_class: "Custom CSS Class",
  field_label: "Field Label",
  placeholder: "Placeholder",
  validation: "Validation",
  required: "Required",
  none: "None",
  defaile_message: "Default Message",
  widget_editor: "Widget Editor",
  toolbox: "Toolbox",
  title_empty_chk: "Title cannot be empty",
  reseller: "Resellers",
  campaign: "Campaigns",
  assets: "Assets",
  others: "Others",
  gta_list: "GTA List",
  mp_sand_sanetors: "MP Sand Sanetors",
  new_brunswick_candidate: "New Brunswick Candidate",
  on_tario_list: "On Tario List",
  parliament_list: "Parliament List",
  quebec_candidate: "Quebec Candidate",
  click_to_upgrade: "Click to upgrade",
  your_subscription_has_been: " your subscription has been ",
  by_the_administrator: " by the administrator",
  please_contact_website_administrator: " please contact website administrator.",
  click_to_renew: "RENEW / UPDATE SUBSCRIPTION",
  expired: "Expired",
  paused: "Paused",
  expire: "Expire",
  pause: "Pause",
  test_mail: "Test Mail",
  no_record_found: "No Record Found",
  edit_user: "Edit User",
  client_list: "Client List",
  edit_client: "Edit Client",
  reseller_list: "Reseller List",
  create_reseller: "Create Reseller",
  edit_reseller: "Edit Reseller",
  contact_list: "Contact List",
  create_contact: "Create Contact",
  edit_contact: "Edit Contact",
  edit_role: "Edit Role",
  create_subscription: "Create Subscription",
  edit_subscription: "Edit Subscription",
  edit_widget: "Edit Widget",
  campaign_list: "Campaign List",
  create_campaign: "Create Campaign",
  edit_campaign: "Edit Campaign",
  email_template: "Email Template",
  create_email_template: "Create Email Template",
  edit_email_template: "Edit Email Template",
  your: "Your ",
  plaese_contact_to_website_administrator: "please contact the website administrator.",
  password_sent_to_mail: "Your password reset link has been sent to your email address. Please check your inbox or spam folder.",
  email_not_found: "Email Not Found",
  assets_list: "Assets List",
  widget_email_list: "Widget Email List",
  role_list: "Role List",
  db_backups: "Database Backups",
  keys: "API Keys",
  transaction: "Transactions",
  widget_email: "Widget Submission",
  all_user: "All Users",
  next: "Next",
  previous: "Previous",
  select_all: "Select All",
  create_db_backup: "Create DataBase Backup",
  all_media: "All Media",
  image: "Image",
  csv: "CSV",
  pdf: "PDF",
  video: "Video",
  caption: "Caption",
  url: "URL",
  dimensions: "Dimensions",
  file_size: "File Size",
  upload_on: "Uploaded on",
  file_type: "File Type",
  file_name: "File Name",
  mail_send_to: "Please insert the email address",
  count: "Count",
  total_record: "Total Records",
  csv_file: "Select CSV file",
  total_count: "Total Count",
  reply_to: "Reply to",
  provide_tilte: "Title cannot be empty.",
  provide_template: "Template cannot be empty.",
  provide_date: "Date cannot be empty.",
  create_schedule: "Create Schedule",
  complete_profile: "Please complete your profile",
  logo: "Logo",
  short_description: "Short Description",
  long_description: "Long Description",
  name: "Name",
  long_description_cannot_be_empty: "Long description cannot be empty.",
  short_description_cannot_be_empty: "Short description cannot be empty.",
  please_upload_logo: "Please upload logo",
  name_cannot_be_empty: "Name cannot be empty",
  select_image: "Select image",
  search_by_name_email: "Search by Name, Email Id",
  search_by_name: "Search by Name",
  search_by_title: "Search by Title",
  total_users: "Total Users",
  total_reseller: "Total Resellers",
  total_contacts: "Total Contacts",
  total_subscription: "Total Subscriptions",
  total_roles: "Total Roles",
  total_widgets: "Total Widgets",
  lname: "Last Name",
  google_api_keys: "Google API Key",
  select_email_temp: "Select Email Template",
  total_camp: "Total Campaigns",
  total_email_templates: "Total Email Templates",
  total_schedules: "Total Schedules",
  info_type: "Information Type",
  info: "Information",
  add_more_info: "Add Information",
  select_type: "Please select a Type",
  updated_at: "Updated On",
  paypal: "Paypal",
  bulk_email: "Schedule Email",
  create_db_backup: "Create DataBase Backup",
  all_media: "All Media",
  image: "Image",
  csv: "CSV",
  pdf: "PDF",
  video: "Video",
  caption: "Caption",
  url: "URL",
  dimensions: "Dimensions",
  file_size: "File Size",
  upload_on: "Uploaded on",
  file_type: "File Type",
  file_name: "File Name",
  mail_send_to: "Please insert email address",
  count: "Count",
  total_record: "Total Record",
  csv_file: "Select CSV file",
  total_count: "Total Count",
  reply_to: "Reply to",
  provide_tilte: "Title cannot be empty.",
  provide_template: "Template cannot be empty.",
  provide_date: "Date cannot be empty.",
  create_schedule: "Create Schedule",
  complete_profile: "Complete Profile",
  logo: "Logo",
  short_description: "Short Description",
  long_description: "Long Description",
  name: "Name",
  long_description_cannot_be_empty: "Long description cannot be empty.",
  short_description_cannot_be_empty: "Short description cannot be empty.",
  please_upload_logo: "Please upload logo",
  name_cannot_be_empty: "Name cannot be empty.",
  select_image: "Select image",
  lname: "Last Name",
  bulk_email: "Schedule Email",
  total_schedules: "Total Scheduled Emails",
  bulk_email_list: "Bulk Email List",
  total_users: "Total Users",
  total_selected_users: "Total Selected Users",
  edit_email_schedule: "Update Email Schedule",
  enter_sub: "Enter Subject",
  enter_email: "Enter Email",
  enter_msg_here: "Enter your message here",
  test_mail_msg: "Send Test Mail",
  send: "Send",
  apply: "Apply",
  created_at: "Created At",
  mobile_number: "Mobile Number",
  features: "Features",
  type: "Type",
  subscription_update: "Subscription Update",
  twitter_message: "Twitter Message",
  fb_message: "Facebook Message",
  widget: "Widget",
  sub_title: "Sub Title",
  select: "Select",
  all_user_list: "All Users List",
  schedule_on: "Schedule On",
  created_on: "Created On",
  updated_on: "Updated On",
  create_email_schedule: "Create Email Schedule",
  download: "Download",
  published_date: "Published date",
  end_date: "End Date",
  select_file_type: "Please select a File Type",
  users: "User",
  total_clients: "Total Clients",
  contact_widget: "Contact Widget",
  donation_widget: "Donation Widget",
  selected_option: "Please select  widgets",
  selected_tag: "Please select tags",
  select_end_date: "End date cannot be empty",
  select_publish_on: "Published date cannot be empty",
  select_sub_title: "Sub title cannot be empty",
  select_description: "Description cannot be empty",
  analytics: "Analytics",
  action: "Action",
  preview: "Preview",
  go_back: "Go Back",
  contact_name: "Contact Name",
  contact_email: "Contact Email",
  schedule_message: "Schedule date cannot be smaller than current date",
  schedule_cannot_null: "schedule date cannot be null",
  reject: "Reject",
  choose_a_file: "Choose a file",
  sender_name: "Sender Name",
  sender_email: "Sender Email",
  subscription_plan: "Subscription Plan",
  subscription_status: "Subscription Status",
  select_subscription_status: "Select Subscription Status",
  chk_sub_domain: "Sub Domain cannot be empty.",
  subject: "Subject",
  default: "Default",
  offer: "Offer",
  select_email_template: "Select Email Template",
  all_type: "All Type",
  bulk_email_list: "Bulk Email List",
  chk_empty_ward: "Ward cannot be empty.",
  chk_empty_country: "Country cannot be empty.",
  email_type: "Please select a type.",
  role_list: "Role List",
  upload: "Upload",
  file_is_successfully_uploaded: "File is successfully uploaded",
  privacy_policy_not_exists: "Privacy Policy cannot be empty.",
  privacy_policy: "Privacy Policy",
  background_color: "Background Color",
  current_plan_is: "You have subscribed for ",
  and_will_expire_after: "and will expire after",
  days: "days",
  hours: "hours",
  plan: "plan",
  total: "Total",
  letter_sent: "Letter Sent",
  start_from: "Start From",
  log: "Log",
  logs: "Logs",
  staff_users: "Staff Users",
  all_users: "All Users",
  self_registered: "By Self-Registered",
  admin_caps: "By Admin",
  please_select_defult_prce: "Please select defult price",
  please_set_all_price: "Please set all price list",
  tweet_widget_url_on_twitter: "Tweet widget url on Twitter",
  share_widget_url_on_facebook: "Share widget url on Facebook",
  text_color: "Text Color",
  lock_message: "Lock Message",
  campaign_number: "Number of Campaigns",
  number_of_campaign: "Number of Campaigns",
  plese_give_campaign_number: "Please set campaigns number",
  subs_package: "Subscription Package",
  campaigns_caps: "Campaigns",
  remaining_campaings: "Remaining Campaings",
  client_name: "Client Name",
  embid_code_nation_use: "Use the following NationBuilder embed code in your NationBuilder campaign website. ",
  embid_code_use: "Use following embed code to embed this campaign in your website. ",
  short_code_use: "Paste this shortcode in template editor where you want to embed this campaign",
  clientsubscription: "My Subscriptions",
  payment_gateway_stripe: "Payment Gateway(Stripe)",
  select_status: "Select Status",
  secret_key: "Secret Key",
  google_api_key: "Google Api Key",
  publishable_key: "Publishable key",
  client_secret: "Client Secret",
  client_id: "Client ID",
  order_id: "Order ID",
  download_pdf: "Download Pdf",
  payment_mode: "Payment Mode",
  transaction_status: "Transaction Status",
  transaction_id: "Transaction Id",
  transaction_mode: "Transaction Mode",
  paid_unpaid: "Paid/Unpaid",
  refund: "Refund",
  refunded: "Refunded",
  paid: "Paid",
  unpaid: "Unpaid",
  amount: "Amount",
  paid_transaction_status: "Paid Transaction Status",
  pay: "Pay",
  reverse: "Reverse",
  payment_not_paid: "Mark this payment paid to reseller.",
  payment_pay: "Mark this payment unpaid to reseller.",

  n_a: "N/A",
  not_completed: "Not Completed",
  canceled: "Canceled",
  domain: "Domain",
  please_select_category: "Please Select Category",
  document: "Document",
  images: "Images",
  videos: "Videos",
  something_went_wrong_please_try_again: "something went wrong ,please try again.",
  please_select_email_from_table: "Please select email from table",
  campaign_name: "Campaign Name",
  total_letter_sent: "Total letter sent",
  letter_sent_to_official: "Letter sent to Official",
  s_no: "S.No.",
  party_name: "Party Name",
  letter_sent_by_party: "Letter sent by Party",
  city_name: "City Name",
  letter_sent_by_city: "Letter sent by City",
  camp_1: "Campaign Title 1",
  camp_2: "Campaign Title 2",
  camp_3: "Campaign Title 3",
  there_is_no_one_who: "There is no one who loves pain itself, who seeks after it ",
  and_wants_to_have: "and wants to have it, simply because it is pain...",
  share_this_campaign: "Share this campaign",
  select_reseller: "Select Reseller",
  campaign_update: "Campaign Update",
  shoping_cart: "YOUR SHOPPING CART",
  search_by_name_title: "Search by Name,Title",
  email_exists: "email already exists",
  please_select_a_file: "please select a file",
  upload_image: "upload image",
  please_select_only_image_type: "Please select only Image type.",
  sub_domain: "Sub Domain",
  please_upgrade_your_plan: "Please upgrade your plan!",
  Subdomain_missing_please_complete_your_profile: "Subdomain missing! please complete your profile",
  all: "All",
  opalina_technology: "Opalina Technology",
  aspire_minds: "aspire minds",
  push_politics: "Push Plitics",
  It_is_a_long_established: "It is a long established fact that a reader will be",
  at_its_layout: "at its layout. The point of using Lorem Ipsum is that it",
  has_a_more_or_less_normal: " has a more-or-less normal distribution of letters, as",
  opposed_to_using: "  opposed to using 'Content here, content here', making it",
  look_like_readable: "look like readable English.",
  know_more: "Know More",
  contact_us: "Contact Us",
  about_us: "About Us",
  services: "Services",
  footer: "Footer",
  push_politics_subheading: "Push politics subheading",
  please_select_file_type: "please select file type",
  email_template_list: "Email Template List",
  mail_successfully_sent: "mail successfully sent!",
  invalid_smtp_configurations: "Invalid SMTP configurations",
  valid_smtp_configurations: "Valid SMTP configurations",
  please_enter_a_test_mail: "Please Enter a Test Mail",
  paymentgateway_list: "Payment Gateway List",
  access_key: "Access Key",
  access_key_secret: "Access Key Secret",
  access_token_secret: "access_token_secret",
  access_token: "access_token",
  password_cannot_be_empty: "password cannot be empty",
  password_and_confirm_password_are_not_matched: "Password and Confirm Password are not matched",
  confirm_password_cannot_be_empty: "Confirm password cannot be empty",
  email_is_not_valid: "Email is not valid",
  email_cannot_be_empty: "Email cannot be empty",
  please_select_a_status: "please select a status",
  user_name_cannot_be_empty: "User name cannot be empty",
  first_name_cannot_be_empty: "First Name cannot be empty",
  port_cannot_be_empty: "Port cannot be empty",
  host_cannot_be_empty: "Host cannot be empty",
  sender_name_cannot_be_empty: "Sender name cannot be empty",
  please_select_subscription_status: "please select subscription status",
  please_select_subscription_plan: "please select subscription plan",
  title_cannot_be_empty: "Title cannot be empty",
  subdomain_already_exists: "subdomain already exists",
  please_select_from_contact_list: "Please select from contact list",
  role_title_exists: "Role title already Exists",
  select_package: "Select Package",
  price_configuration: "price configuration",
  select_dur: "Select Duration",
  transaction_list: "Transaction List",
  select_client: "Select Client",
  search_by_order_or_email: "search by order or email",
  note: "Note",
  payment_status: "Payment Status",
  to: "To",
  cc: "CC",
  bcc: "BCC",
  contact_details: "Contact Details",
  label: "Label",
  add_option: "Add_Option",
  contact_list_search_available_in_your_widget: "Contact list search available in your widget",
  button_title: "Button Title",
  select_contact_list: "Select Contact List",
  privacy_terms: "PRIVACY and TERMS of USE.",
  subscribe_for_campaign_updates: "Subscribe for Campaign updates.",
  please_select_contact_list: "please select contact list",
  create_default_widget: "Create default widget",
  reseller_overview: "Reseller Overview",
  current_year_revenus: "This Year",
  current_month_revenus: "This Month",
  pay_with_stripe: "Pay with STRIPE",
  total_revenue: "Total Revenue",
  campaign_count: "Campaign Count",
  dashboard: "Dashboard",
  email_not_exist: "Email and password are Invalid",
  pay_with_stripe: "Pay with STRIPE",
  total_revenue: "Total Revenue",
  campaign_count: "Campaign Count",
  roles_cannot_empty: "Roles cannot be empty",
  google_api_field: "Please add Google Api Key",
  api_key: "Api Key",
  settings: "Settings",
  please_select_email_list: "please select email list",
  widget_email_list: "Widget Email List",
  select_campaing: "Select Campaign",
  select_widget: "Select Widget",
  incorrect_email_pass: "Incorrect Email or Password",
  email_pwd_cannot_empty: "Email or Password Cannot be empty",
  client_does_not_exist: "Client account Deactivated or Not exist",
  client_account_is_inactive: "Client account is Inactive contact to website Administrator.",
  user_account_is_inactive: "Your account is Inactive",
  paypal_client_id: "Paypal Client ID",
  paypal_secret_key: "Paypal Secret Key",
  stripe_publishable_key: "Publishable Key",
  stripe_secret_key: "Secret Key",
  google_geo_api_key: "Google API Key",
  google_places_api_key: "Api Key",
  date: "Date",
  mode: "Mode",
  id: "ID",
  order: "Order",
  field_name: "Field Name",
  define_your_privacy_policy_above: "define your privacy policy above",
  custom_class: "custom class",
  select_plan: "Select Plan",
  on: " on  ",
  email_id: "Email ID",
  by_self: "By Self",
  name_or_mail_cannot_be_empty: "Name or Email cannot be empty",
  invalid_email: "Invalid Email",
  chng_email_template_replace_widget_msg: "Change email template will replace your default widget message.",
  uploaded_image_shown: "Uploaded images will be available to add in template editor!",
  view: "View",
  yes: "Yes",
  no: "No",
  retrieve: "Retrieve",
  duplicate_address: "Address by geo location cannot be duplicate.",
  positive_amount_only: "Add positive value only",
  pls_add_amt: "Please add a amount",
  camp_title: "Campaign Title",
  subs_alredy_created: "Subscription Title already exists!",
  ward: "Ward",
  constituency: "Constituency",
  chk_empty_constitiuency: "constituency cannot be empty",
  payment_refunded: "Mark this payment refunded.",
  payment_refund_reverse: "Mark this refund reverse.",
  refund_reversed: "Refund Reverse",
  payment_paid: "Payment is paid",
  payment_unpaid: "Payment is reversed",
  refund_is_reversed: "Refund is reversed",
  payment_is_refunded: "Payment is refunded",
  previous_activity: "Previous Activities",
  add_roles: "Please add roles",
  add_image_assets: "Please add image in assets",
  account_detail: "Account Detail",
  smtp_detail: "SMTP Detail",
  billing_address: "Billing Address",
  please_provide_your_address: "Please provide your address",
  size_should_below_200kb: "Image size should be less than 200 KB and resolution should below 300 x 150",
  download: "Download",
  select_campaign: "Select Campaign",
  total_tranx: "Total Transactions",
  plan: "Plan",
  tranx_id: "Transaction ID",
  deleted: "Deleted",
  download_reciept: "Download Reciept",
  cash: "Cash",
  test_smtp: "Test SMTP",
  plese_verify_smtp_detail: "Please provide valid SMTP credentials.",
  click_to_verify: "Go to Settings",
  transactions: "Transactions",
  upload_thumb_image: "Upload Thumb Image",
  please_upload_thumb: "Please upload thumb image",
  please_verify_your_smtp_detail: "Please verify your SMTP details.",
  completed: "Completed",
  failed: "Failed",
  started: "Started",
  field_merge_label: "Embed widget field in Message",
  field_merge_label_rep: "Embed Rep. data in Message",
  successfully_updated: "Successfully Updated",
  successfully_updated_campaign: "Successfully Updated, Widget changes can take up to 5 minutes to take effect.",
  host: "Host",
  please_fill_required_fields: "Please fill widget details to send mail",
  upgrade_plan: "Upgrade Plan",
  plan_status: "Plan Status",
  remaining_days: "Remaining Days",
  back: "Back",
  reseller_name: "Reseller Name",
  total_clients: "Total Clients",
  monthly_revenue: "This month revenue",
  yearly_revenue: "This year revenue",
  successfully_updated_invalid_credentials: "Successfully updated but SMTP details are invalid.",
  widget_layout: "Widget Layout",
  widget_border_radius: "Widget Border Radius",
  input_radius: "Input Box Radius",
  full_width: "Full Width",
  boxed: "Boxed",
  left_align: "Left Align",
  right_align: "Right Align",
  center_align: "Center Align",
  hst_number: "Harmonized Sales Tax",
  please_select_type: "Please select type",
  additional_styles: "Additional Style CSS",
  subdomain_missing_please_complete_your_profile: "Subdomain missing please complete your profile",
  close: "Close",
  disclaimer_msg: "The final Widget will look a lot different from what you see here. Please always preview in the campaign before publishing it.",
  test_widget_email_msg: "Test email layout in campaign preview by sending test email.",
  disclaimer: "Disclaimer",
  info: "Info.",
  drag_drop_msg: "Drag and Drop element in your widget and customize element by edit button.",
  size_should_below_5mb: "Image size should be less than 5 MB and resolution should below 1280 x 720",
  telephone_number: "Telephone Number",
  fax_number: "Fax Number",
  fax_number_cannot_be_empty: "Fax number cannot be empty",
  telephone_number_cannot_be_empty: "Telephone number cannot be empty",
  tax_label: "Tax label",
  tax_number: "Tax number",
  tax_rate: "Tax rate",
  tax_number_cannot_be_empty: "Tax number cannot be empty",
  tax_rate_cannot_be_empty: "Tax rate cannot be empty",
  tax_label_cannot_be_empty: "Tax label cannot be empty",
  search_by_plan_or_email: "Search by plan or email",
  address_cannot_be_empty: "Address cannot be empty",
  hst_number_cannot_be_empty: "Harmonized Sales Tax number cannot be empty",
  please_contact_to_admin: "Payment receipt not available, Please contact to website administrator",
  style_updated_successfully: "Style updated successfully",
  client_count: "Client Count",
  sub_domain_already: "Domain already taken.",
  email_title_already_exist: "Email title already exists",
  edit_profile: "Edit your profile",
  riding_lists: "Riding Lists",
  select_riding_list: "Select Riding List",
  rel_riding_field: "Relation Riding Field",
  select_rel_riding_field: "Select Relation Riding Field",
  rel_contact_lists: "Relation Contact Field",
  select_rel_contact_field: "Select Relation Contact Field",
  create_data_set: "Create Data Set",
  contact_data_set: "Contact Data Set",
  contact_lists: "Contact Lists",
  riding_list_cannot_be_empty: "Riding List cannot be empty",
  rel_riding_field_cannot_be_empty: "Relation Riding field cannot be empty",
  contact_list_cannot_be_empty: "Contact list cannot be empty",
  rel_contact_field_cannot_be_empty: "Relation Contact field cannot be empty",
  relation_riding_field: "Relation Riding Field",
  relation_contact_field: "Relation Contact Field",
  riding_list: "Riding List",
  edit_data_set: "Edit Data Set",
  search_by_first_name: "Search by first name",
  db_config: "Database Configuration",
  database: "Database",
  contact_data_list: "Contact Data List",
  contact_list_host_cannot_be_empty: "Contact data host cannot be empty",
  contact_list_username_cannot_be_empty: "Contact data usename cannot be empty",
  riding_list_host_cannot_be_empty: "Riding  host cannot be empty",
  invalid_credentials: "Invalid Credentials",
  relation_id_exists: "Contact Data Set already in use,deleting contact will effect on Reseller,Subscription and Widget.",
  successfully_deleted: "Successfully deleted",
  client_count: "Client Count",
  change_image: "Change Image",
  remove_image: "Remove Image",
  display_header: "Display Header",
  text_size: "Text Size",
  default_size: "Default 12px",
  test_mail_sent: "A test email sent on your associated email successfully.",
  test_mail_err: "Invalid SMTP credential.",
  client_count: "Total Clients",
  language: "Language",
  export: "Export",
  select_contact_list_embed: "Select contact list for embed Rep. data in message",
  letter_sent_by_constituency: "Letter sent by Constituency",
  contact_party: "Contact Party",
  letter_count: "Letter Count",
  contact_constituency: "Contact Constituency",
  constituency_name: "Constituency Name",
  set_default_smtp: "Set Default SMTP",
  suc_message: "Success Message on email sent",
  err_message: "Error message on email failure",
  contact_not_found: "Message on contact not found",
  suc_message_default: "Your email successfully sent!",
  err_message_default: "Email not sent, Internal Error!",
  contact_not_found_default: "Sorry, we did not find representative for selected address.",
  validation_msg_preview: "Preview validation message",
  validation_msg_preview_default: "*All fields are mandatory to generate preview.",
  validation_msg: "Validation message",
  validation_msg_default: "*All fields are mandatory.",
  font_color: "Font Color",
  height: "Height",
  heading: "Heading",
  paragraph: "Paragraph",
  image_alignment: "Image Alignment",
  center: "Center Aligned",
  left: "Left Aligned",
  right: "Right Aligned",
  email_required_inwidget: "Email type field required to create widget",
  dup_field_inwidget: "Cannot add duplicate field in widget.",
  maintenance_label: "Maintenance Break",
  verified: " Verified",
  my_letters: "My letters",
  letter_formatting: "Letter formatting",
  remove_contact_ability: "Ability to remove specific contact",
  message_preview_button_label: "Message preview button label",
  submit: "Submit",
  overwrite_client_settings: "Overwrite client settings",
  update: "Update",
  port: "Port",
  all_fields_are_mandatory: "All fields are mandatory",
  nothing_to_update: "Nothing to update",
  client_smtp_detail: "Smtp settings - client",
  tracking_analytics: "Tracking / Analytics",
  smtp_settings_updated: "Smtp Settings Updated",
  email_field_is_always_mandatory: "Email field is mandatory",
  "subdomain already exist!": "subdomain already exist!",
  "total_amount": "Total Amount",
  subscription_start: "Start",
  subscription_end: "End",
  "plan_discontinued": "This plan is discontinued",
  "Street number":"Street number",
  "Street":"Street",
  "Neighborhood":"Neighborhood",
  "City/Town":"City/Town",
  "Muncipality/County":"Muncipality/County",
  "Province/Territory":"Province/Territory",
  "Country":"Country",
  "Postal Code":"Postal Code",
  "Address":"Address",
  "campaign_analytics_tracking":"Campaign Analytics Tracking",
  "campaign_social_media_tracking":"Campaign Social Media Tracking",
  "google_analytics":"Google Analytics ID",
  "google_analytics_placeholder":"Only Google Analytics ID paste here",
  "adobe_analytics":"Adobe Analytics",
  "generic_analytics":"Generic Analytics",
  "facebook_analytics":"FaceBook Analytics",
  "twitter_analytics":"Twitter Analytics",
  "linkedin_analytics":"LinkedIn Analytics",
  "tracking_updated":"Tracking / Analytics details updated successfully",
  "script_tag_invalid":"script tag open or close is invalid",
  "script_scr_invalid":"invalid script src",
  "script_stmt_invalid":"invalid script code",
  "script_bracket_invalid":"invalid open & close bracket of script code",
  "script_stmt_unused":"please remove unused or commented script code",
  "Campaign Details": "Campaign Details",
  "Letter Autoresponder": "Letter Autoresponder",
  "Newsletter Autoresponder": "Newsletter Autoresponder",
  "URL Redirection": "URL Redirection",
  "You are being redirected to the campaign website. Is this OK?": 
    "You are being redirected to the campaign website. Is this OK?",
  "Please enter message box text!!":
    "Please enter message box text!!",
  "Please enter a URL to Redirect after form is submitted": 
    "Please enter a URL to Redirect after form is submitted",
  "Enable / Disable URL Redirection Post Form Submission": 
    "Enable / Disable URL Redirection Post Form Submission",
  "Enable": "Enable",
  "Disable": "Disable",
  "URL to Redirect after form is submitted": "URL to Redirect after form is submitted",
  "Enable / Disable Redirection Confirmation Message Box":
    "Enable / Disable Redirection Confirmation Message Box",
  "Message Box Text": "Message Box Text",
  "Enable Newsletter Signup": "Enable Newsletter Signup",
  "Newsletter Confirmation message": "Newsletter Confirmation message",
  newsletter_signup_msg_default: "Thank you for signing up to our newsletter",
  letter_rotation_by_party:"Letter Rotation By Party",
  letter_rotation_by_province_region:"Letter Rotation By Province/Region",
  letter_widgets:'Letter Widgets',
  letter_rotation_selector:'Letter Rotation Selector',
  random:'Random',
};
