import React from "react";
import { Redirect, Link } from "react-router-dom";
import { Tabs, Tab } from "react-bootstrap";
import Utility from "../common/Utility";
import Tab1 from "./Tab1";
import Tab2 from "./Tab2";
import Tab3 from "./Tab3";
import bcrypt from "bcryptjs";
import Can from "../common/AccessControl";
import $ from "jquery";
import { __t } from "../../locale/language";
const list_options = [
  { value: 1, label: "Greater Toronto Area" },
  { value: 2, label: "Member of Parliament - Senatrs" },
  { value: 3, label: "New Brunswick Candidates" },
  { value: 4, label: "Ontario" },
  { value: 5, label: "Member of Parliament" },
  { value: 6, label: "Quebec Candidates" },
  { value: 7, label: "Others" }
];
var contact_data = [];
class ResellerUpdate extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      redirect: false,
      errors: {},
      name: "",
      subdomain: "",
      title: "",
      status: "",
      sender_email: "",
      sender_name: "",
      username: "",
      password: "",
      port: "",
      host: "",
      first_name: "",
      last_name: "",
      user_name: "",
      user_contact: "",
      user_password: "",
      confirm_password: "",
      user_email: "",
      user_type: "reseller",
      user_status: "",
      token: localStorage.getItem("token"),
      key: 1,
      subscription_plan: [],
      plan: 0,
      contact_list: [],
      list_options,
      tax_label: "",
      tax_number: "",
      tax_rate: "",
      tax_data: { telephone_number: "", fax_number: "", tax_label: "", tax_number: "", tax_rate: "" },
      sub_dom: "",
      contact_data: "",
      contact_label: [],
      remove_id: [],
    };
    this.create = this.create.bind(this);
    this.handleSelect = this.handleSelect.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.nextTab = this.nextTab.bind(this);
    this.checkDomain = this.checkDomain.bind(this);
    this.createHash = this.createHash.bind(this);
    this.multiHandleChangeList = this.multiHandleChangeList.bind(this);
  }
  componentDidMount() {
    ////console.log(this.props);
    this.get_contact_set_data()
    if (typeof this.props.location.state != "undefined" && this.props.location.state.id) {
      $("#title").text(__t("edit_reseller"));
      $("#title2").text(__t("edit_reseller"));
    }
    this.getSubscriptionPlan();
    if (
      //when update
      this.props.location.state &&
      typeof this.props.location.state !== "undefined"
    ) {
      this.getData();
      this.getUserData();

      //this.findSubscriptionPlan();
    }
    // else {
    //   this.getsmtp();
    // }


  }
  getUserData = () => {
    var id = this.props.location.state.id;
    Utility.ExecuteData("finduserdata", this.token, {
      id: id
    }).then(data => {
      this.setState({
        first_name: data.data.first_name,
        last_name: data.data.last_name,
        user_name: data.data.user_name,
        user_contact: data.data.cell_number,
        email: data.data.email,
        user_status: data.data.status,
      });
      //console.log(data);

    });
  }
  get_contact_set_data = async () => {
    let res = await Utility.executeRequest("get-data-set", { limit: 1000, type: "reseller" });
    //console.log(res)

    //console.log(res.data.rows);

    var arr = []
    res.data.rows.map((val, key) => {
      var list_options = {}
      list_options['value'] = val.id;
      list_options['label'] = val.title;
      arr.push(list_options)
    })
    // //console.log(arr)
    this.setState({
      contact_label: arr,
      contact_data: res.data.rows
    })

  }
  getData() {
    var id = this.props.location.state.id;
    Utility.ExecuteData("findclient", this.token, {
      id: id
    }).then(data => {
      //console.log(data);
      this.findSubscriptionPlan(data);
    });
  }
  getsmtp = () => {
    var p_id = localStorage.getItem("client_id");

    Utility.ExecuteData("getsmtp", this.token, {
      id: p_id
    }).then((data) => {
      this.setState({
        host: data.data[0].host,
        port: data.data[0].port,
        sender_email: data.data[0].sender_email,
        username: data.data[0].username,
        password: data.data[0].password
      })
    });
  }
  getSubscriptionPlan = () => {
    Utility.ExecuteData("findsubscriptionbyType", this.token, {
      type: "reseller"
    }).then(data => {
      this.setState({ subscription_plan: data.data });
    });
  };

  findSubscriptionPlan = async (data) => {
    var id = this.props.location.state.id;
    let res = await Utility.executeRequest("get_contact_set_data", { id: id, type: "reseller" });
    contact_data = res.data
    //console.log(res.data)
    Utility.ExecuteData("clientsubscription", this.token, {
      id: id
    }).then(result => {
      console.log(JSON.parse(data.data.tax_data))
      var tax_d = JSON.parse(data.data.tax_data)
      var stateData = {
        name: data.data.name,
        subdomain: data.data.subdomain,
        title: data.data.title,
        // contact_list: JSON.parse(data.data.contact_list),
        contact_list: res.data,
        status: data.data.status,
        sender_email: data.data.sender_email,
        sender_name: data.data.sender_name,
        username: data.data.username,
        tax_label: tax_d['tax_label'],
        tax_number: tax_d['tax_number'],
        tax_rate: tax_d['tax_rate'],
        port: data.data.port,
        host: data.data.host,
        plan: result.data[0] ? (typeof result.data[0].subscription_id !== "undefined" ? result.data[0].subscription_id : "") : 0
      };

      this.setState(stateData);
    });
  };
  checkDomain() {
    let formIsValid = true;
    //console.log(this.props)
    if (!this.props.location.state && typeof this.props.location.state === "undefined") {
      let subdomain = this.state.subdomain;

      if (subdomain) {
        Utility.ExecuteData("checkdomain", this.state.token, {
          subdomain: subdomain + ".ppca.opalina.in"
        }).then(response => {
          //console.log("sdc",response)
          if (response.code === 200) {
            //console.log("sdchbhj")
            formIsValid = false;
            this.setState({
              errors: {
                ["subdomain"]: __t("sub_domain_already")
              },
              sub_dom: "false"
            });
          } else if (response.code === 204) {
            formIsValid = false;
            this.setState({
              errors: {
                ["subdomain"]: ""
              },
              sub_dom: "true"
            });
          }
        });
      }
    }
  }
  createHash(pwd) {
    const saltRounds = 10;
    return new Promise((resolve, reject) => {
      bcrypt.genSalt(saltRounds, function (err, salt) {
        if (err) return err;
        bcrypt.hash(pwd, salt, function (err, hash) {
          // Store hash in your password DB.
          if (err) {
            reject(err);
          } else {
            resolve(hash);
          }
        });
      });
    });
  }
  handleChange(event) {
    console.log(event.target.name, '-----', event.target.value);

    this.setState({ [event.target.name]: event.target.value });
  }
  multiHandleChangeList = contact_list => {
    var arr = [];

    contact_list.map((val, key) => {
      arr.push({ id: val.value, title: val.label });
    });
    this.setState({
      contact_list
    });
    ////console.log(this.state.contact_list);
  };
  checkValidation(key) {
    let fields = this.state;
    let errors = {};
    let formIsValid = true;
    var remove_id = [];
    console.log(fields);
    //return false
    var flag;
    if (this.props.location.state && typeof this.props.location.state !== "undefined") {

      flag = "update";

      var cnt = this.state.contact_list
      var cnt2 = contact_data
      cnt2 = cnt2.filter(function (item) {
        return !cnt.includes(item);
      })
      console.log(cnt2)
      cnt2.map((val, k) => {
        remove_id.push(val.data_set_id)
      })
      console.log(remove_id)

      //   $("#tab_2").removeAttr("disabled","")
    } else {
      flag = "create";
    }
    console.log("dfhvb")
    if (key == 1) {
      if (fields.sub_dom == "false") {
        ////console.l og("dcvj")
        formIsValid = false;
        errors["subdomain"] = __t("sub_domain_already");
      }
      if (!fields["name"]) {
        formIsValid = false;
        errors["name"] = __t("name_cannot_be_empty");
      }
      if (fields["contact_list"].length <= 0) {
        formIsValid = false;
        errors["contact_list"] = __t("please_select_from_contact_list");
      }
      if (!fields["title"]) {
        formIsValid = false;
        errors["title"] = __t("title_cannot_be_empty");
      }
      if (!fields["status"] || fields["status"] == "select") {
        formIsValid = false;
        errors["status"] = __t("please_select_a_status");
      }
    }
    if (key == 2) {
      if (!this.props.location.state && typeof this.props.location.state === "undefined") {
        if (!fields["password"]) {
          formIsValid = false;
          errors["password"] = __t("password_cannot_be_empty");
        }
      }
      if (!fields["sender_name"]) {
        formIsValid = false;
        errors["sender_name"] = __t("sender_name_cannot_be_empty");
      }
      // if (!fields["username"]) {
      //   formIsValid = false;
      //   errors["username"] = __t("user_name_cannot_be_empty");
      // }
      if (!fields["host"]) {
        formIsValid = false;
        errors["host"] = __t("host_cannot_be_empty");
      }
      if (!fields["port"]) {
        formIsValid = false;
        errors["port"] = __t("port_cannot_be_empty");
      }
      if (!fields["sender_email"]) {
        formIsValid = false;
        errors["sender_email"] = __t("email_cannot_be_empty");
      } else if (typeof fields["sender_email"] !== "undefined") {
        let lastAtPos = fields["sender_email"].lastIndexOf("@");
        let lastDotPos = fields["sender_email"].lastIndexOf(".");
        if (!(lastAtPos < lastDotPos && lastAtPos > 0 && fields["sender_email"].indexOf("@@") == -1 && lastDotPos > 2 && fields["sender_email"].length - lastDotPos > 2)) {
          formIsValid = false;
          errors["sender_email"] = __t("email_is_not_valid");
        }
      }
    }
    if (key == 3) {
      console.log("dfhvb")
      if (!fields["first_name"]) {
        formIsValid = false;
        errors["first_name"] = __t("first_name_cannot_be_empty");
      }
      if (!fields["user_name"]) {
        formIsValid = false;
        errors["user_name"] = __t("user_name_cannot_be_empty");
      }
      if (!fields["user_status"] || fields["user_status"] == "select") {
        formIsValid = false;
        errors["user_status"] = __t("please_select_a_status");
      }
      if (flag == "update") {
        if (!fields["email"]) {
          formIsValid = false;
          errors["user_email"] = __t("email_cannot_be_empty");
        } else if (typeof fields["email"] !== "undefined") {
          let lastAtPos = fields["email"].lastIndexOf("@");
          let lastDotPos = fields["email"].lastIndexOf(".");
          if (!(lastAtPos < lastDotPos && lastAtPos > 0 && fields["email"].indexOf("@@") == -1 && lastDotPos > 2 && fields["email"].length - lastDotPos > 2)) {
            formIsValid = false;
            errors["user_email"] = __t("email_is_not_valid");
          }
        }
      }
      if (flag == "create") {

        if (!fields["user_email"]) {
          formIsValid = false;
          errors["user_email"] = __t("email_cannot_be_empty");
        } else if (typeof fields["user_email"] !== "undefined") {
          let lastAtPos = fields["user_email"].lastIndexOf("@");
          let lastDotPos = fields["user_email"].lastIndexOf(".");
          if (!(lastAtPos < lastDotPos && lastAtPos > 0 && fields["user_email"].indexOf("@@") == -1 && lastDotPos > 2 && fields["user_email"].length - lastDotPos > 2)) {
            formIsValid = false;
            errors["user_email"] = __t("email_is_not_valid");
          }
        }
        if (!fields["confirm_password"]) {
          formIsValid = false;
          errors["confirm_password"] = __t("confirm_password_cannot_be_empty");
        } else if (fields["user_password"] !== fields["confirm_password"]) {
          formIsValid = false;
          errors["confirm_password"] = __t("password_and_confirm_password_are_not_matched");
        }
        if (!fields["user_password"]) {
          formIsValid = false;
          errors["user_password"] = __t("password_cannot_be_empty");
        }
      }

      if (!fields["user_status"] || fields["user_status"] == "select") {
        formIsValid = false;
        errors["user_status"] = __t("please_select_a_status");
      }
      if (!fields["tax_number"]) {
        formIsValid = false;
        errors["tax_number"] = __t("tax_number_cannot_be_empty");
      }
      if (!fields["tax_label"]) {
        formIsValid = false;
        errors["tax_label"] = __t("tax_label_cannot_be_empty");
      }
      if (!fields["tax_rate"]) {
        formIsValid = false;
        errors["tax_rate"] = __t("tax_rate_cannot_be_empty");
      }

      //password

    }
    let tax_data = fields.tax_data;
    tax_data.tax_label = fields.tax_label
    tax_data.tax_number = fields.tax_number
    tax_data.tax_rate = fields.tax_rate
    this.setState({
      errors: errors,
      tax_data: tax_data,
      remove_id: remove_id
    });
    return formIsValid;
  }
  capitalize = str => {
    return str.charAt(0).toUpperCase() + str.slice(1);
  };
  handleSelect(key) {
    this.setState({ key });
  }
  nextTab(key) {
    if (key == 2) {
      if (this.checkValidation(1)) {
        this.setState({ key: key });
      }
    } else if (key == 3) {
      if (this.checkValidation(2)) {
        this.setState({ key: key });
      }
    }
  }
  token = localStorage.getItem("token");
  create() {
    console.log(this.state)
    // console.log(contact_data)


    // console.log(cnt); // [ 'a', 'c', 'e' ]
    // console.log(cnt2);
    //return false

    if (this.checkValidation(this.state.key)) {
      ////console.log(this.state.tax_data)
      //return false
      if (
        // reseller update
        this.props.location.state &&
        typeof this.props.location.state !== "undefined"
      ) {
        if (false && this.state.password) {
          // for user update when password is change

          this.createHash(this.state.password)
            .then(myhash => {
              this.setState({ password: myhash });
            })
            .then(() => {
              this.setState({ id: this.props.location.state.id }, () => {
                Utility.ExecuteData("clientupdate", this.token, this.state)
                  .then(responseJson => {
                    if (responseJson.code === 200) {
                      this.setState({ redirect: true });
                    } else {
                      alert("Something went wront. Try Again.");
                    }
                  })
                  .catch(error => {
                    this.setState({ errors: true });
                  });
              });
            });
        } else {
          // for reseller update when password not change
          // //console.log(this.state);
          this.setState({ id: this.props.location.state.id }, () => {
            Utility.ExecuteData("clientupdate", this.token, this.state)
              .then(responseJson => {
                if (responseJson.code === 200) {
                  this.setState({ redirect: true });
                } else {
                  alert("Something went wront. Try Again.");
                }
              })
              .catch(error => {
                this.setState({ errors: true });
              });
          });
        }
      } else {
        this.createHash(this.state.user_password)
          .then(userhash => {
            this.setState({ user_password: userhash });
          })
          .then(() => {
            Utility.ExecuteData("clientcreate", this.token, this.state)
              .then(responseJson => {
                if (responseJson.code === 200) {
                  this.setState({ redirect: true });
                } else if (responseJson.success == "email already exist") {
                  let formIsValid = false;
                  let errors = {};
                  errors["user_email"] = __t("email_exists");
                  this.setState({ errors: errors });
                } else {
                  alert(__t("try_again"));
                }
              })
              .catch(error => {
                this.setState({ errors: true });
              });
          });
        // });
      }
    }
  }
  render() {
    // //console.log(this.state.contact_list);
    var opts = {};
    var flag = "";
    if (this.props.location.state && typeof this.props.location.state !== "undefined") {
      flag = "update";
      opts["readOnly"] = "readOnly";
      //   $("#tab_2").removeAttr("disabled","")
    } else {
      flag = "create";
    }
    const { redirect } = this.state;
    if (redirect) {
      return <Redirect push to="/reseller/" />;
    }
    return (
      <Can I={["view-reseller"]} userPermissions={this.props.userPermissions.userPermissions}>
        <div className="row wrapper border-bottom white-bg page-heading">
          <div className="col-lg-10">
            <h2 style={{ marginTop: 10 }}>{__t("reseller")}</h2>
            <ol className="breadcrumb">
              <li className="breadcrumb-item">
                <Link to="/">{__t("dashboard")}</Link>
              </li>
              <li className="breadcrumb-item">
                <Link to="/reseller">{__t("reseller")}</Link>
              </li>
              <li className="breadcrumb-item active">
                <strong id="title"> {__t("create_reseller")}</strong>
              </li>
            </ol>
          </div>
        </div>
        <div className="row">
          <div className="col-md-12">
            <div className="ibox float-e-margins">
              <div className="ibox-title">
                <h5 id="title2">{__t("create_reseller")}</h5>
              </div>
              <div className="ibox-content">
                <div className="form-horizontal">
                  <Tabs activeKey={this.state.key} onSelect={this.handleSelect} animation={false} id="noanim-tab-example">
                    <Tab eventKey={1} title="Server Detail">
                      <Tab1 tab1_data={this.state} checkDomain={this.checkDomain} handleChange={this.handleChange} multiHandleChangeList={this.multiHandleChangeList} />
                    </Tab>

                    {this.props.location.state && typeof this.props.location.state !== "undefined" ? (
                      <Tab eventKey={2} title="SMTP Detail">
                        <Tab2 flag={flag} capitalize={this.capitalize} create={this.create} tab2_data={this.state} handleChange={this.handleChange} handleValidation={this.handleValidation} />
                      </Tab>
                    ) : (
                        <Tab eventKey={2} title="SMTP Detail" disabled>
                          <Tab2 flag={flag} capitalize={this.capitalize} create={this.create} tab2_data={this.state} handleChange={this.handleChange} handleValidation={this.handleValidation} />
                        </Tab>
                      )}
                    {/* {flag == "create" ? ( */}
                    <Tab eventKey={3} title="User Detail" >
                      <Tab3 flag={flag} capitalize={this.capitalize} create={this.create} tab3_data={this.state} handleChange={this.handleChange} handleValidation={this.handleValidation} />
                    </Tab>
                    {/* ) : (
                        ""
                      )} */}
                    {this.state.key === 1 && (
                      <button onClick={() => this.nextTab(2)} value="next" className="form-group btn btn-primary btn btn-primary">
                        {__t("next")} >>
                      </button>
                    )}
                    {
                      this.state.key === 2 && (
                        <div>

                          <button onClick={() => this.getsmtp()} className="form-group btn btn-primary btn btn-primary">
                            {__t("set_default_smtp")}
                          </button>&nbsp;&nbsp;
                          <button onClick={() => this.nextTab(3)} value="next" className="form-group btn btn-primary btn btn-primary">
                            {__t("next")} >>
                          </button>
                        </div>
                      )
                    }
                  </Tabs>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Can>
    );
  }
}

export default ResellerUpdate;
