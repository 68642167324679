import React, { Component } from "react";
import { Tabs, Tab } from "react-bootstrap";
import { __t } from "../../locale/language";
import Select from "react-select";


class Tab1 extends Component {
  constructor(props) {
    super(props);
    this.state = {
     // contact_list: [],
     
    }
  }
 
  render() {
    const tab1_data = this.props.tab1_data;
   ////console.log(tab1_data)
    return (
      <Tab eventKey={1} title="Server Detail">
        <div className="row form-group">
          <div className="col-md-6 col-sm-6 col-xs-12">
            <label className="control-label required-field">{__t("name")}</label>
            <input
              type="text"
              name="name"
              className="form-control"
              placeholder={__t("name")}
              value={tab1_data.name}
              onChange={this.props.handleChange}
            />
            <label id="-error" className="error">
              {tab1_data.errors["name"]}
            </label>
          </div>
          <div className="col-md-6 col-sm-6 col-xs-12">
            <label className="control-label required-field">{__t("title")}</label>
            <input
              type="text"
              name="title"
              autoComplete="off"
              className="form-control"
              placeholder={__t("title")}
              value={tab1_data.title}
              onChange={this.props.handleChange}
            />
            <label id="-error" className="error">
              {tab1_data.errors["title"]}
            </label>
          </div>
          <div className="col-md-6 col-sm-6 col-xs-12">
            <label className="control-label">{__t("domain")}</label>
            <input
              type="text"
              name="subdomain"
              className="form-control"
              placeholder={__t("domain")}
              value={tab1_data.subdomain}
              onChange={this.props.handleChange}
              onBlur={this.props.checkDomain}
            />
            <label id="-error" className="error">
              {tab1_data.errors["subdomain"]}
            </label>
          </div>
       
         
          <div className="col-md-6 col-sm-6 col-xs-12">
            <label className="control-label required-field">{__t("status")}</label>
            <select
              name="status"
              className="form-control"
              onChange={this.props.handleChange}
              value={tab1_data.status}
            >
              <option value="select">--{__t("select_status")}--</option>
              <option value="active">{__t("active")}</option>
              <option value="inactive">{__t("inactive")}</option>
            </select>
            <label id="-error" className="error">
              {tab1_data.errors["status"]}
            </label>
          </div>
        </div>
       
     

        <div className="col-md-6 col-sm-6 col-xs-12">
          <div className="form-group">
            <label className="control-label required-field">
              {__t("contact_lists")}
            </label>
            <Select
             value={tab1_data.contact_list}
            // value={tab1_data.contact_data}
              isMulti
              onChange={this.props.multiHandleChangeList}
               //options={tab1_data.list_options}
              options={tab1_data.contact_label}
              
              name="contact_list"
             
            />
            <label id="-error" className="error">
              {tab1_data.errors["contact_list"]}
            </label>
          </div>
        </div>
      </Tab>
    );
  }
}

export default Tab1;
