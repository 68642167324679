import React, { Component } from "react";
import grapesjs from "grapesjs";
import Utility from "../common/Utility";
import { __t } from "../../locale/language";

class Preview1 extends Component {
  constructor(props) {
    super(props);
    this.editor = null;
  }

  saveContent = () => {
    var iframe = document.getElementsByClassName("gjs-frame")[0];
    var innerDoc = iframe.contentDocument || iframe.contentWindow.document;
    var frameBody = innerDoc.querySelector("body");
    var style = frameBody.querySelector("style");
    var htmlContent = frameBody.querySelector("#wrapper");
    var html = htmlContent.innerHTML; //get html content of document
    var css = this.editor.getCss(); //get css content of document
    //console.log(html);
  };

  shouldComponentUpdate() {
    return false;
  }
  componentDidMount() {
    this.editor = grapesjs.init({
      allowScripts: 1,
      showOffsets: 1,
      autorender: 0,
      noticeOnUnload: 0,
      container: "#gjs",
      height: "700px",
      fromElement: true,
      clearOnRender: 0,
      storageManager: {
        autoload: 0
      },
      blockManager: {
        blocks: []
      },
      canvas: {
        styles: [Utility.baseUrlFe() + "custom.css"]
      }
    });

    var pnm = this.editor.Panels;

    // pnm.removeButton("views", "open-sm");
    pnm.removeButton("views", "open-layers");
    pnm.removeButton("views", "open-blocks");
    pnm.removeButton("views", "open-tm");
    // pnm.removePanel("views");

    pnm.addButton("options", [
      {
        id: "undo",
        className: "fa fa-undo icon-undo",
        command: function command(editor, sender) {
          sender.set("active", 0);
          editor.UndoManager.undo(1);
        },
        attributes: {
          title: "Undo (CTRL/CMD + Z)"
        }
      },
      {
        id: "redo",
        className: "fa fa-repeat icon-redo",
        command: function command(editor, sender) {
          sender.set("active", 0);
          editor.UndoManager.redo(1);
        },
        attributes: {
          title: "Redo (CTRL/CMD + Y)"
        }
      },
      {
        id: "save",
        className: "fa fa-save",
        command: "saveHtml",
        attributes: {
          title: "Save"
        }
      }
    ]);

    this.editor.Commands.add("saveHtml", {
      run() {
        var iframe = document.getElementsByClassName("gjs-frame")[0];
        var innerDoc = iframe.contentDocument || iframe.contentWindow.document;
        var frameBody = innerDoc.querySelector("body");
        var style = frameBody.querySelector("style");
        var htmlContent = frameBody.querySelector("#wrapper");
        var html = htmlContent.innerHTML; //get html content of document
        //console.log(html);
        alert("Saved Successfully");
      }
    });

    this.editor.render();
    var comps = this.editor.DomComponents;
    var defaultType = comps.getType("text");
    // Change background of the wrapper and set some attribute
    var wrapper = comps.getWrapper();
    wrapper.set("style", { "background-color": "white" });
  }
  render() {
    return (
      <React.Fragment>
        <div className="panel__top">
          <div className="panel__basic-actions" />
          <div className="panel__devices" />
          <div className="panel__switcher" />
        </div>
        <div className="editor-row">
          <div className="editor-canvas">
            <div
              id="gjs"
              style={{ height: "700px !important", overflow: "hidden" }}
            >
              <div className="preview_container">
                <div className="header_logo">
                  <img
                    src="/img/profile_small.jpg"
                    className="boder_readi_50 logo_img"
                  />
                  <div className="logo_txt">
                    <h4>{__t("opalina_technology")}</h4>
                    <small>{__t("aspire_minds")}</small>
                  </div>
                </div>

                <div className="preview2_section ">
                  <div className="col-6 preview2_left_div bg_gray1 txt_black">
                    <h2 className="heading">{__t("push_politics")}</h2>
                    <p className="header_para">
                      {__t("It_is_a_long_established")}
                      {__t("distracted_by_the_readable")}
                      {__t("at_its_layout")}
                      {__t("has_a_more_or_less_normal")}
                      {__t("opposed_to_using")}
                      {__t("look_like_readable")}
                    </p>
                    <button className="grp_btn_blue bg_dark_blue">
                      {__t("know_more")}
                    </button>
                  </div>
                  <div className="col-6 preview2_right_div pos_rel">
                    <img
                      src="/img/pic06.jpg"
                      className=" preview2_section_img"
                    />
                  </div>
                </div>

                <div className="preview1_section">
                  <div className="col-6 preview2_right_div pos_rel">
                    <div className="preview_content">
                      <img
                        src="/img/profile.jpg"
                        className="flt_left content_img_space"
                      />
                      <h2 className="preview_content_head">{__t("push_politics")}</h2>
                      <a href="#" className="subheading_anchor">
                        {__t("push_politics_subheading")}
                      </a>
                    </div>
                  </div>
                  <div className="col-6 preview1_left_div bg_gray">
                    <p className="preview_content txt_black">
                      {__t("It_is_a_long_established")}
                      {__t("distracted_by_the_readable")}
                      {__t("at_its_layout")}
                      {__t("has_a_more_or_less_normal")}
                      {__t("opposed_to_using")}
                      {__t("look_like_readable")}
                    </p>
                  </div>
                </div>

                <div className="preview1_section">
                  <div className="col-6 preview2_right_div pos_rel">
                    <div className="pos_abs content_abs">
                      <div className="flt_left content_abs_txt">
                        <h2>Campaign</h2>
                        <p>
                          {__t("It_is_a_long_established")}
                          {__t("distracted_by_the_readable")}
                          {__t("at_its_layout")}
                          {__t("has_a_more_or_less_normal")}
                          {__t("opposed_to_using")}
                          {__t("look_like_readable")}
                        </p>
                      </div>
                    </div>
                    <img
                      src="/img/pic08.jpg"
                      className=" preview2_section_img"
                    />
                  </div>
                  <div className="col-6 preview1_left_div bg_gray">
                    <div className="card1_preview width100">
                      <a href="https://damienflandrin.fr/blog/post/tutoriel-realiser-une-carte-en-css">
                        <div className="card-image">
                          <img
                            src="https://damienflandrin.fr/storage/tutoriel-realiser-une-carte-en-css/card-image.jpg"
                            alt="Orange"
                          />
                        </div>

                        <div className="card-body">
                          <div className="card-title">
                            <h3>Lorem Ipsum</h3>
                          </div>
                          <div className="card-date">
                            <h5>20 Novembre 1992</h5>
                          </div>

                          <div className="card-excerpt">
                            <p>
                              {" "}
                              Lorem ipsum dolor sit amet, consectetur
                              adipisicing elit. Aliquam voluptatibus autem
                              consectetur voluptate facere at, omnis ab optio
                              placeat officiis!Lorem ipsum dolor sit amet,
                              consectetur adipisicing elit.
                            </p>
                          </div>
                        </div>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              <div className="footer_section">
                <div className=" footer_div bg_dark_blue">
                  <h4>{__t("footer")}</h4>
                  <ul>
                    <li>{__t("contact_us")}</li>
                    <li>{__t("about_us")}</li>
                    <li>{__t("services")}</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="footer_btns">
          <button className="grp_btn mright20 bg_dark_blue">{__t("save")}</button>
          <button className="grp_btn bg_dark_blue">{__t("cancel")}</button>
        </div>
      </React.Fragment>
    );
  }
}

export default Preview1;
