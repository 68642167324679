import React from 'react';
import Utility from '../common/Utility';
import PaypalExpress from './paypalButton';
import paypal from "paypal-checkout";
import { __t } from "../../locale/language";

export default class CheckOut extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            template:[],
            user_id:''         
        }
        this.token=localStorage.getItem("token")
        this.getTemplate = this.getTemplate.bind(this)     
    }
    getTemplate(){
        Utility.ExecuteData("findTemplate" ,this.token, {
          id:this.props.match.params.id
          }).then(result => {
         //console.log(result)
         this.setState({
             template:result.data,
             id:result.data.id
         })
     
          });

    }
    componentDidMount(){
        this.setState({
            user_id:this.props.userPermissions.id
          })    
        this.getTemplate()

    }
   
    render() {  
        const onSuccess = (payment) => {
            //console.log("The payment was succeeded!", payment);
           // this.insertClient();
        }      
        let env = 'sandbox';
       
        const data = {
            'userid': this.state.user_id,
            'orderid': this.state.order_id,
            'id':this.state.id,
            'status': 'started'
        }       
        const payment = function () {
            var CREATE_URL = Utility.baseUrlBe() + "createPaymentforTemplate";
       
            return paypal.request.post(CREATE_URL, data).then(function (res) {
               
                return res.id;
            });
        };

        const onAuthorize = function (data, actions) {
            var EXECUTE_URL = Utility.baseUrlBe() + "executePayment";
                       
            return paypal.request.post(EXECUTE_URL, data).then(function (res) {
                onSuccess(res.body)
                    
            
            });
        };    
        return (
            <div>               
                <div className="checkout_table_box">
                    <div className="checkout_btn">
                        <h4>{__t("shoping_cart")}</h4>

                    </div>
                    <table className="table table-border checkout_tbl">
                        <thead>
                            <tr className="checkout_tbl_td">
                                <th>{__t("title")}</th>
                                <th>{__t("type")}</th>
                                <th>{__t("price")}</th>
                            </tr>
                        </thead>
                        <tbody className="checkout_body">
                      {this.state.template?
                          <tr> <td>{this.state.template.title?this.state.template.title:''}</td>
                         <td>{this.state.template.type?this.state.template.type:''}</td>
                         <td>{this.state.template.price?this.state.template.price:''}</td>
                         </tr>  :''} </tbody>



                    </table>
                    <div className="flx_end"><label>{"Total Price:"+this.state.template.price}</label></div>
                    <div className="flx_end">
                    <PaypalExpress
                         
                         env={env}
                         onSuccess={onSuccess}
                         payment={payment}
                         commit={true}
                         onAuthorize={onAuthorize}
                      
                       
                     />
                    </div>
                </div>
            </div>
        )
    }
}