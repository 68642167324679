import React from "react";
import { Redirect, Link } from "react-router-dom";
import Utility from "../common/Utility";
import { __t } from "../../locale/language";
import Can from "../common/AccessControl";
import DateTimePicker from "react-datetime-picker";
import ReactQuill from "react-quill";
import $ from "jquery";
var select_data = [];
var user_type;
var status;
var temp_id;
var get_email = [];
var alluser = [];
var final_data = [];
const set1 = new Set();

var token = localStorage.getItem("token");
class EmailUpdate extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      title: "",
      email_json: {},
      status: "pending",
      scheduled_on: new Date(),
      repeat: 1,
      errors: {},
      template: "",
      list_type: "",
      email_body: "",
      subject: "",
      cc: "",
      bcc: "",
      email_list: [],
      user_list: [],
      search: "",
      template_list: [],
      email_template: "",
      token: localStorage.getItem("token"),
      reply_to: "",
      total: "",
      sizePerPage: 10,
      page: 1,
      scheduled_on: new Date(),
      redirect: false,
      client_id: localStorage.getItem("client_id"),
      selected: []
    };
    this.getSchedule = this.getSchedule.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.getUserList = this.getUserList.bind(this);
    this.changeTemplate = this.changeTemplate.bind(this);
    this.create = this.create.bind(this);
    this.checkbutton = this.checkbutton.bind(this);
    this.select_all = this.select_all.bind(this);
    this.search = this.search.bind(this);
    this.changeStatus = this.changeStatus.bind(this);
    this.checkStatus = this.checkStatus.bind(this);
    this.handleValidation = this.handleValidation.bind(this);
    this.onDatechange = this.onDatechange.bind(this);
    this.findalluser = this.findalluser.bind(this);
  }
  changeTemplate = e => {
    temp_id = e.target.value;
    if (temp_id !== "") {
      this.state.template_list.map(v => {
        if (v.id == temp_id) {
          this.setState({
            email_template: temp_id,
            subject: v.subject,
            cc: v.cc,
            bcc: v.bcc,
            email_body: v.message
          });
        }
      });
    } else {
      this.setState({
        email_template: temp_id,
        subject: "",
        cc: "",
        bcc: "",
        email_body: ""
      });
    }
  };
  changeStatus(e) {
    user_type = e.target.value;
    this.getUserList(1, 10);
  }
  checkStatus(e) {
    status = e.target.value;
    if (status == "all") {
      status = "";
    }
    this.getUserList(1, 10);
  }
  getUserList(page = 1, sizePerPage = 10) {
    // console.log(this.state.username);
    // console.log(select_data);
    var get_id = [];

    let promise = new Promise((resolve, reject) => {
      Utility.ExecuteData("finduserwithfilter", this.state.token, {
        search:
          typeof this.state.username == "undefined" ? "" : this.state.username,

        page: page,
        limit: sizePerPage,
        email_search:
          typeof this.state.email == "undefined" ? "" : this.state.email,
        user_type: user_type,
        status: status
      }).then(user_list => {
        resolve("success");
        console.log();
        this.setState({
          user_list: user_list.data.rows,
          total: user_list.data.count,
          page,
          sizePerPage
        });
      });
    });
    promise.then(message => {
      // console.log(newpage);
      var page2;
      var newpage = this.state.total / 10;
      var result = newpage - Math.floor(newpage) !== 0;
      if (result) {
        newpage = Math.trunc(newpage);
        newpage = newpage + 1;
        // console.log(newpage);
        // console.log(this.state.page);
        page2 = this.state.page;
      }
      // console.log(newpage);
      // console.log(page2);
      if (newpage == 1 || newpage < 1 || newpage == this.state.page) {
        $("#nxt").css("display", "none");
      }
      if (page2 == newpage) {
        $("#nxt").hide();
        $("#prev").css("display", "inline");
      } else {
        $("#nxt").css("display", "inline");
      }
      // console.log(get_email);

      var all_tick = [];
      this.state.user_list.map((val, key) => {
        if (set1.has(val.email)) {
          $("#" + val.id).prop("checked", true);
          all_tick.push(val.email);
        } else {
          $("#" + val.id).prop("checked", false);
        }
      });
      // console.log(all_tick)
      if (all_tick.length == 10) {
        $("#select_all").prop("checked", true);
      } else {
        $("#select_all").prop("checked", false);
      }
    });
  }
  getTemplate = () => {
    Utility.ExecuteData("gettemplateforschedule", this.state.token, {
      type: "offer"
    }).then(template_list => {
      // console.log(template_list);
      this.setState({ template_list: template_list.templates });
    });
  };
  componentDidMount() {
    // console.log(this.props);
    get_email = [];
    //select_data = [];
    set1.clear();
    this.setState({
      selected: []
    });
    this.getUserList(1, 10);
    this.getSchedule();
    this.getTemplate();
    this.findalluser();
  }
  handleChange = e => {
    this.setState({ [e.target.name]: e.target.value });
  };
  getSchedule = () => {
    var id = this.props.location.state.id;
    //console.log(id);
    var mail_data;
    // console.log(this.state.token);

    let promise = new Promise((resolve, reject) => {
      Utility.ExecuteData("getemaildata", this.state.token, {
        id: id
      }).then(data => {
        resolve("data");
        console.log(data);
        console.log(this.state.scheduled_on);

        mail_data = data.data.rows[0];
        var arr = JSON.parse(mail_data.email_json);
        var pub_on = new Date(mail_data.scheduled_on);

        arr[0].email_list.map((val, k) => {
          get_email.push(val.email);
          set1.add(val.email);
        });
        this.setState({
          selected: set1.size,
          scheduled_on: pub_on
        });
        // for (let item of set1) {
        //   console.log(item);
        // }
      });
    });

    promise.then(message => {
      this.state.user_list.map((val, key) => {
        if (get_email.includes(val.email)) {
          $("#" + val.id).prop("checked", true);
          // select_data.push(val);
        } else {
          $("#" + val.id).prop("checked", false);
        }
      });
      console.log(mail_data);
      this.setState({
        title: mail_data.title,
        status: mail_data.status,
        token: mail_data.token,
        email_body: mail_data.email_body,
        subject: mail_data.subject,
        cc: mail_data.cc,
        bcc: mail_data.bcc,
        reply_to: mail_data.reply_to
      });
    });
  };
  editorChange = value => {
    this.setState({ email_body: value });
  };
  findalluser() {
    let promise = new Promise((resolve, reject) => {
      Utility.ExecuteData(
        "findalluserwithfilter",
        this.state.token,
        {}
      ).then(alluser_list => {
        resolve("success");
        alluser = alluser_list.data.rows;
      });
    });
  }
  handleValidation() {
    let fields = this.state;
    let errors = {};
    let formIsValid = true;
    if (!fields["title"]) {
      formIsValid = false;
      errors["title"] = __t("provide_tilte");
    }

    if (this.state.scheduled_on == null) {
      formIsValid = false;
      errors["date"] = __t("provide_date");
    }
    this.setState({ errors: errors });
    return formIsValid;
  }

  create() {
    var mail = set1;
    alluser.map(m => {
      if (set1.has(m.email)) {
        final_data.push(m);
      }
    });

    if (final_data.length > 0) {
      if (this.handleValidation()) {
        var email_listing = [{ type: "selected", email_list: [] }];
        final_data.map(i => {
          email_listing[0].email_list.push({
            name: i.first_name,
            email: i.email
          });
        });

        if (email_listing[0].email_list.length > 0) {
          Utility.ExecuteData("updateschedule", token, {
            client_id: this.props.location.state.id,
            title: this.state.title,
            email_list: email_listing,
            status: this.state.status,
            scheduled_on: this.state.scheduled_on,
            repeat: this.state.repeat,
            token: token,
            email_body: this.state.email_body,
            subject: this.state.subject,
            cc:this.state.cc,
            bcc:this.state.bcc,
            reply_to:this.state.reply_to
          }).then(data => {
            if (data) {
              final_data = [];
              email_listing[0].email_list = [];
              set1.clear();
              this.setState({ redirect: true });
            } else {
              $("#msg_text").text(__t("something_went_wrong_please_try_again"));
              $("#alertinfo").show();
            }
            // console.log(data);
          });
        } else {
          $("#msg_text").text(__t("please_select_email_from_table"));
          $("#alertinfo").show();
        }
      }
    }
  }

  checkbutton(value) {
    let email_data;
    email_data = value.email;
    if (set1.has(email_data)) {
      set1.delete(email_data);
      this.setState({
        selected: set1.size
      });
    } else {
      set1.add(email_data);
      this.setState({
        selected: set1.size
      });
    }
  }

  select_all() {
    if (document.getElementById("select_all").checked == true) {
      $(".mycheckbox").prop("checked", true);
      this.state.user_list.map((k, key) => {
        if (k.user_type != "admin") {
          set1.add(k.email);
        }
        this.setState({
          selected: set1.size
        });
      });
    } else if (document.getElementById("select_all").checked == false) {
      $(".mycheckbox").prop("checked", false);
      this.state.user_list.map((k, key) => {
        if (k.user_type != "admin") {
          set1.delete(k.email);
        }
      });
      this.setState({
        selected: set1.size
      });
      // select_data = [];
    }
  }
  search() {
    this.getUserList(1, 10);
  }
  previous() {
    if (this.state.page == "2") {
      $("#prev").hide();
      $("#nxt").show();
      this.getUserList(this.state.page - 1, 10);
    } else {
      $("#prev").show();
      $("#nxt").show();
      this.getUserList(this.state.page - 1, 10);
    }
  }
  next() {
    var newpage = this.state.total / 10;
    var result = newpage - Math.floor(newpage) !== 0;
    if (result) {
      newpage = Math.trunc(newpage);
      // console.log(newpage);
      newpage = newpage + 1;
      // console.log(newpage);
    }
    if (this.state.page == "1") {
      $("#prev").hide();
    }
    if (this.state.page == newpage) {
      $("#nxt").hide();
    } else {
      if (this.state.page == newpage - 1) {
        $("#nxt").hide();
      }
      this.getUserList(this.state.page + 1, 10);
      $("#prev").show();
    }
  }
  onDatechange = scheduled_on => {
    console.log(scheduled_on);
    var ndate = new Date();
    if (scheduled_on == null) {
      $("#msg_text").text(__t("schedule_cannot_null"));
      $("#alertinfo").show();
      return false;
    }
    if (scheduled_on < ndate) {
      $("#msg_text").text(__t("schedule_message"));
      $("#alertinfo").show();
      return false;
    }
    this.setState({ scheduled_on });
  };
  render() {
    const { redirect } = this.state;
    if (redirect) {
      return <Redirect push to="/bulk-email/" />;
    }
    var email_templates = this.state.template_list;
    const temp_selection = (
      <select
        name="email_template"
        className={"form-control"}
        onChange={this.changeTemplate}
        value={this.state.email_template}
      >
        <option value="">{__t("select_email_template")}</option>
        {email_templates.map((v, k) => {
          return (
            <option key={v.id} value={v.id}>
              {v.title}
            </option>
          );
        })}
      </select>
    );
    var email_template_config = "";

    const user_table = (
      <table className="table table-bordered">
        <thead>
          <tr>
            <th />
            <th>{__t("uname")}</th>
            <th>{__t("lname")}</th>
            {/* <th>{__t("client")}</th> */}
            <th>{__t("email")}</th>
            <th>{__t("status")}</th>
            <th>{__t("type")}</th>
            <th>{__t("action")}</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>
              <input
                type={"checkbox"}
                className="select_all"
                id="select_all"
                onClick={this.select_all}
                name="select_all"
                value={"all"}
              />
            </td>

            <td>
              <input
                type={"text"}
                name="username"
                onChange={this.handleChange}
                className={"form-control"}
              />
            </td>
            <td>
              <input
                type={"text"}
                name="lastname"
                onChange={this.handleChange}
                className={"form-control"}
              />
            </td>
            {/* <td /> */}
            <td>
              <input
                type={"text"}
                name="email"
                onChange={this.handleChange}
                className={"form-control"}
              />
            </td>
            <td>
              <select
                name="status"
                onChange={e => {
                  this.checkStatus(e);
                }}
                className={"form-control"}
              >
                <option value={"all"}> {__t("status")}</option>
                <option value={"active"}> {__t("active")}</option>
                <option value={"inactive"}> {__t("inactive")}</option>
              </select>
            </td>
            <td>
              <select
                name="type"
                // onChange={this.changeStatus(value)}
                onChange={e => {
                  this.changeStatus(e);
                }}
                className={"form-control"}
              >
                <option value={"all"}> {__t("all_status")}</option>
                <option value={"active"}> {__t("active")}</option>
                <option value={"inactive"}> {__t("inactive")}</option>
              </select>
            </td>
            <td>
              <button className="btn btn-primary" onClick={this.search}>
                {__t("search")}
              </button>
            </td>
          </tr>
          {this.state.user_list.map((v, k) => {
            return (
              <tr key={k}>
                <td>
                  <input
                    type={"checkbox"}
                    className="mycheckbox"
                    name={"select" + v.id}
                    value={"select" + v.id}
                    className="mycheckbox"
                    id={v.id}
                    onClick={() => {
                      this.checkbutton(v);
                    }}
                  />
                </td>
                <td>{v.first_name}</td>
                <td>{v.last_name}</td>
                <td>{v.email}</td>
                <td>{v.status}</td>
                <td>{__t(v.user_type)}</td>
                <td />
              </tr>
            );
          })}
        </tbody>
      </table>
    );
    return (
      <Can
        I={["create-email-schedule"]}
        userPermissions={this.props.userPermissions.userPermissions}
      >
        <div>
          <div className="row wrapper border-bottom white-bg page-heading">
            <div className="col-lg-10">
              <h2 style={{ marginTop: 10 }}>{__t("bulk_email_list")}</h2>
              <ol className="breadcrumb">
                <li className="breadcrumb-item">
                  <Link to="/">{__t("dashboard")}</Link>
                </li>
                <li className="breadcrumb-item">
                  <Link to="/bulk-email">{__t("bulk_email")}</Link>
                </li>
                <li className="breadcrumb-item active">
                  <strong id="title">{__t("edit_email_schedule")}</strong>
                </li>
              </ol>
            </div>
          </div>
          <div className="wrapper wrapper-content animated fadeInRight">
            <div className="col-md-12">
              <div className="ibox float-e-margins">
                <div className="ibox-title">
                  <h5>{__t("edit")}</h5>
                </div>
                <div className="ibox-content">
                  <div className="form-horizontal">
                    <div className="row form-group">
                      <div className="col-md-6 col-sm-6 col-xs-12">
                        <label className="control-label required-field">
                          {__t("title")}
                        </label>
                        <input
                          type="text"
                          name="title"
                          value={this.state.title}
                          className="form-control"
                          placeholder={__t("title")}
                          onChange={this.handleChange}
                        />
                        <label id="-error" className="error">
                          {this.state.errors["title"]}
                        </label>
                      </div>
                      <div className="col-md-6 col-sm-6 col-xs-12">
                        <label className="control-label required-field">
                          {__t("schedule_on")}
                        </label>

                        <DateTimePicker
                          className="form-control"
                          onChange={this.onDatechange}
                          value={this.state.scheduled_on}
                        />
                        <label id="-error" className="error">
                          {this.state.errors["date"]}
                        </label>
                      </div>

                      <div className="col-md-6 col-sm-6 col-xs-12">
                        <label className="control-label">
                          {__t("subject")}
                        </label>
                        <input
                          type="subject"
                          name="subject"
                          value={this.state.subject}
                          className="form-control"
                          //placeholder={__t("subject")}
                          onChange={this.handleChange}
                        />
                        <label id="-error" className="error">
                          {this.state.errors["subject"]}
                        </label>
                      </div>
                      <div className="col-md-6 col-sm-6 col-xs-12">
                        <label className="control-label">{__t("cc")}</label>
                        <input
                          type="text"
                          name="cc"
                          value={this.state.cc}
                          className="form-control"
                          placeholder={__t("cc")}
                          onChange={this.handleChange}
                        />
                        <label id="-error" className="error">
                          {this.state.errors["cc"]}
                        </label>
                      </div>
                      <div className="col-md-6 col-sm-6 col-xs-12">
                        <label className="control-label">{__t("bcc")}</label>
                        <input
                          type="text"
                          name="bcc"
                          value={this.state.bcc}
                          className="form-control"
                          placeholder={__t("bcc")}
                          onChange={this.handleChange}
                        />
                        <label id="-error" className="error">
                          {this.state.errors["bcc"]}
                        </label>
                      </div>
                      <div className="col-md-6 col-sm-6 col-xs-12">
                        <label className="control-label">
                          {__t("reply_to")}
                        </label>
                        <input
                          type="text"
                          name="reply_to"
                          value={this.state.reply_to}
                          className="form-control"
                          placeholder={__t("reply_to")}
                          onChange={this.handleChange}
                        />
                        <label id="-error" className="error">
                          {this.state.errors["cc"]}
                        </label>
                      </div>
                      <div className="col-md-12 col-sm-12 col-xs-12">
                        <label className="control-label">
                          {__t("message")}
                        </label>
                        <ReactQuill
                          value={this.state.email_body}
                          onChange={this.editorChange}
                        />
                        <label id="-error" className="error">
                          {this.state.errors["message"]}
                        </label>
                      </div>
                    </div>

                    <div className="row form-group">
                      &nbsp;&nbsp;&nbsp;<strong>
                        {__t("total_users")}: {this.state.total}
                      </strong>
                      &nbsp;&nbsp;&nbsp;{" "}
                      <strong>
                        {__t("total_selected_users")}: {set1.size}
                      </strong>
                      <div className="col-md-12 col-sm-12 col-xs-12">
                        {user_table}
                      </div>
                    </div>
                    {this.state.user_list.length > 0 ? (
                      <div className="row">
                        <div className="col-md-6 col-sm-6 col-xs-6">
                          <button
                            className="btn btn-primary btn-sm"
                            onClick={this.create}
                          >
                            {__t("update")}
                          </button>
                          &nbsp;
                          <Link
                            to="/bulk-email"
                            className="btn btn-primary btn-sm"
                          >
                            {__t("cancel")}
                          </Link>
                        </div>
                        <div className="col-md-6 col-sm-6 col-xs-6 flex_end">
                          <button
                            className="btn btn-primary btn-sm"
                            id="prev"
                            style={{ display: "none" }}
                            onClick={() => {
                              this.previous();
                            }}
                          >
                            {" "}
                            {__t("previous")}
                          </button>
                          <button
                            className="btn btn-primary btn-sm mx-1"
                            onClick={() => {
                              this.next();
                            }}
                            id="nxt"
                          >
                            {__t("next")}
                          </button>
                        </div>
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Can>
    );
  }
}

export default EmailUpdate;
