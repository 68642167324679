import React from "react";
import { Redirect } from "react-router-dom";
import { ListGroup, ListGroupItem, Checkbox } from "react-bootstrap";
import { Link } from "react-router-dom";
import RemotePagination from "../common/Table";
import Utility from "../common/Utility";
import { __t } from "../../locale/language";
import Can from "../common/AccessControl";
import $ from "jquery";

class RoleUpdate extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      redirect: false,
      isChecked: [],
      role_list: [],
      keys: [],
      title: "",
      errors: {},
      check_role: "",
      description: "",
      client_id: localStorage.getItem("client_id"),
      status: "",
      token: localStorage.getItem("token"),
    };
    this.handleChecked = this.handleChecked.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.checkUserRoles = this.checkUserRoles.bind(this);
  }
  componentDidMount() {
    //console.log(this.props);
    if (
      typeof this.props.location.state != "undefined" &&
      this.props.location.state.id
    ) {
      $("#title").text(__t("edit_role"));
      $("#title2").text(__t("edit_role"));
    }
    this.defaultRoles();
    if (
      this.props.location.state &&
      typeof this.props.location.state !== "undefined"
    ) {
      this.findRole();
    }
  }
  checkUserRoles(id) {
    //console.log("nnnnn ", id);
    return new Promise((resolve, reject) => {
      var data = {
        client_id: this.state.client_id,
        title: this.state.title,
        id: id,
      };
      var errors = {};
      Utility.ExecuteData("checkuserrole", this.token, data)
        .then((data) => {
          //console.log("hellllllll", data);
          if (id) {
            if (data.data.length >= 2) {
              errors["title"] = __t("role_title_exists");
              this.setState({ errors: errors });
              resolve("false");
            } else {
              resolve("true");
            }
          } else {
            if (Array.isArray(data.data) && data.data.length > 0) {
              errors["title"] = __t("role_title_exists");
              this.setState({ errors: errors });
              resolve("false");
            } else {
              resolve("true");
            }
          }
        })
        .catch((err) => {
          reject(err);
        });
    });
  }
  handleValidation = () => {
    let fields = this.state;
    let errors = {};
    let formIsValid = true;

    // return false
    if (!fields["status"] || fields["status"] == "select") {
      formIsValid = false;
      errors["status"] = __t("select_status");
    }
    if (!fields["title"]) {
      formIsValid = false;
      errors["title"] = __t("select_title");
    }
    // if (fields["title"] == "admin" || fields["title"] == "Admin") {
    //   formIsValid = false;
    //   errors["title"] = __t("already_exist");
    // }
    if (fields.isChecked.length <= 0) {
      formIsValid = false;
      errors["roles"] = __t("add_roles");
    }
    this.setState({ errors: errors });
    return formIsValid;
  };
  findRole = () => {
    var id = this.props.location.state.id;
    Utility.ExecuteData("findrole", this.token, { id: id }).then((data) => {
      this.setState({
        title: data.data.title,
        description: data.data.description,
        status: data.data.status,
      });
      var i = 0;
      data.permission.map(() => {
        this.updateChecked(data.permission[i++].item);
      });
    });
  };

  updateChecked = (e) => {
    const { name } = e;
    this.setState((prevState) => ({
      isChecked: {
        ...prevState.isChecked,
        [e]: !prevState.isChecked[e],
      },
    }));
  };

  handleChecked = (e) => {
    const { name } = e.target;
    this.setState((prevState) => ({
      isChecked: {
        ...prevState.isChecked,
        [name]: !prevState.isChecked[name],
      },
    }));
  };
  handleChange(event) {
    this.setState({ [event.target.name]: event.target.value });
  }
  defaultRoles() {
    Utility.GetData("defaultroles", this.token).then((roles) => {
      var list = [];
      for (var i = 0; i < roles.data.length; i++) {
        var grp = roles.data[i].group;
        var newList = new Array();
        if (list[grp] != "undefined") {
          newList = typeof list[grp] == "undefined" ? [] : list[grp];
          newList.push(roles.data[i]);
        }
        list[grp] = newList;
      }
      var keys = [];
      for (var key in list) {
        keys.push(key);
      }
      this.setState({ role_list: list, keys: keys });
    });
  }
  createRole = () => {
    var id = "";
    if (
      this.props.location.state &&
      typeof this.props.location.state !== "undefined"
    ) {
      id = this.props.location.state.id;
    }
    if (this.handleValidation()) {
      this.checkUserRoles(id)
        .then((res) => {
          this.setState({ check_role: res }, () => {
            if (this.state.check_role == "true") {
              if (id) {
                this.setState({ id: id }, () => {
                  Utility.ExecuteData("updaterole", this.token, this.state)
                    .then((responseJson) => {
                      if (responseJson.code === 200) {
                        this.setState({ redirect: true });
                      } else {
                        alert("Something went wront. Try Again.");
                      }
                    })
                    .catch((error) => {
                      this.setState({ errors: true });
                    });
                });
              } else {
                Utility.ExecuteData("createrole", this.token, this.state)
                  .then((responseJson) => {
                    if (responseJson.code === 200) {
                      this.setState({ redirect: true });
                    } else {
                      alert(__t("try_again"));
                    }
                  })
                  .catch((error) => {
                    this.setState({ errors: true });
                  });
              }
            }
          });
        })
        .catch((err) => {
          this.setState({ check_role: err });
        });
    }
  };
  token = localStorage.getItem("token");
  client_id = localStorage.getItem("client_id");
  render() {
    const { redirect } = this.state;
    if (redirect) {
      return <Redirect push to="/role/" />;
    }
    var keys = this.state.keys;
    var permission = this.state.role_list;
    return (
      <Can
        I={["update-role"]}
        userPermissions={this.props.userPermissions.userPermissions}
      >
        <div>
          <div className="row wrapper border-bottom white-bg page-heading">
            <div className="col-lg-10">
              <h2 style={{ marginTop: 10 }}>{__t("role_list")}</h2>
              <ol className="breadcrumb">
                <li className="breadcrumb-item">
                  <Link to="/">{__t("dashboard")}</Link>
                </li>
                <li className="breadcrumb-item">
                  <Link to="/role">{__t("role")}</Link>
                </li>
                <li className="breadcrumb-item active">
                  <strong id="title">{__t("create_role")}</strong>
                </li>
              </ol>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12">
              <div className="ibox float-e-margins">
                <div className="ibox-title">
                  <h5 id="title2">{__t("create_role")}</h5>
                </div>
                <div className="ibox-content">
                  <div className="form-horizontal">
                    <div className="row form-group">
                      <div className="col-md-6 col-sm-6 col-xs-12">
                        <label className="control-label required-field">
                          {" "}
                          {__t("title")}
                        </label>
                        <input
                          type="text"
                          name="title"
                          value={this.state.title}
                          className="form-control"
                          placeholder="Title"
                          onChange={this.handleChange}
                          // onBlur={this.checkUserRoles}
                        />
                        <label id="-error" className="error">
                          {this.state.errors["title"]}
                        </label>
                      </div>
                      <div className="col-md-6 col-sm-6 col-xs-12">
                        <label className="control-label">
                          {__t("description")}
                        </label>
                        <textarea
                          type=""
                          name="description"
                          value={this.state.description}
                          className="form-control"
                          placeholder="Description"
                          onChange={this.handleChange}
                        />
                      </div>
                    </div>
                    <div className="row form-group">
                      <div className="col-md-6 col-sm-6 col-xs-12">
                        <label className="control-label required-field">
                          {__t("status")}
                        </label>
                        <select
                          name="status"
                          className="form-control"
                          value={this.state.status}
                          onChange={this.handleChange}
                        >
                          <option value="select">{__t("select_status")}</option>
                          <option value="active">{__t("active")}</option>
                          <option value="inactive"> {__t("inactive")}</option>
                        </select>
                        <label id="-error" className="error">
                          {this.state.errors["status"]}
                        </label>
                      </div>
                    </div>
                    <hr />
                    <label className="control-label required-field">
                      {" "}
                      {__t("role")}
                    </label>
                    <div className="row form-group">
                      {keys.map((val, key) => {
                        return (
                          <ListGroup
                            key={key + "1"}
                            className="col-xs-3 col-md-3 col-sm-4"
                          >
                            <ListGroupItem key={key}>
                              {Utility.capitalize(val)}
                            </ListGroupItem>
                            {permission[val].map((item, index) => {
                              return (
                                <ListGroupItem key={index}>
                                  <Checkbox
                                    name={item.item}
                                    key={index}
                                    onChange={this.handleChecked}
                                    checked={this.state.isChecked[item.item]}
                                  >
                                    {item.item}
                                  </Checkbox>
                                </ListGroupItem>
                              );
                            })}
                          </ListGroup>
                        );
                      })}
                    </div>
                    <label id="-error" className="error">
                      {this.state.errors["roles"]}
                    </label>
                    &nbsp;
                    <div className="row form-group">
                      <div className="col-md-12">
                        <button
                          className="btn btn-primary"
                          onClick={this.createRole}
                          type="submit"
                        >
                          {this.props.location.state &&
                          typeof this.props.location.state !== "undefined"
                            ? "Update"
                            : "Create"}
                        </button>{" "}
                        &nbsp;&nbsp;
                        <Link className="btn btn-white" to="/role">
                          {__t("cancel")}
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Can>
    );
  }
}

export default RoleUpdate;
