import React, { Component } from "react";
import ClientSubscription from "./ClientSubscription";
import { Route, Switch } from "react-router-dom";
class Index extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    //console.log(this.props)
    return (

      <Switch>       
        <Route
          path="/clientsubscription"
          render={props => (
            <ClientSubscription
              {...props}
              userPermissions={this.props.userPermissions}
            />
          )}
        />
      </Switch>
      
    );
  }
}

export default Index;
