import React, { Component } from "react";
import { Tab } from "react-bootstrap";
import { Link } from "react-router-dom";
import { __t } from "../../locale/language";
import Utility from "../common/Utility";
class Tab4ForAdminReseller extends Component {
  constructor(props) {
    super(props);
    this.state = {
      form: {},
      token: localStorage.getItem("token"),
      client_id: localStorage.getItem("client_id"),
      // client_id: 126,
      google_analytics: false,
      adobe_analytics: false,
      generic_analytics: false,
      facebook_analytics: false,
      twitter_analytics: false,
      linkedin_analytics: false,
      show_ga: false,
      show_adobe: false,
      show_generic: false,
      show_facebook: false,
      show_twitter: false,
      show_linkedin: false,
    };
  }
  componentDidMount() {
    this.getData();
  }
  getData = async () => {
    let client_id = this.state.client_id;
    let urldata = { id: client_id };
    let request = await Utility.ExecuteData(
      "get_tracking_analytics",
      this.state.token,
      urldata
    );
    if (request.code == 200) {
      if (request.parentData) {
        let pd = request.parentData;
        this.setState({
          show_ga: pd.google_analytics,
          show_adobe: pd.adobe_analytics,
          show_generic: pd.generic_analytics,
          show_facebook: pd.facebook_analytics,
          show_twitter: pd.twitter_analytics,
          show_linkedin: pd.linkedin_analytics,
        });
      }
      if (request.usertype === "admin") {
        this.setState({
          show_ga: true,
          show_adobe: true,
          show_generic: true,
          show_facebook: true,
          show_twitter: true,
          show_linkedin: true,
        });
      }
      if (request.data) {
        let d = request.data;
        this.setState({
          google_analytics: d.google_analytics,
          adobe_analytics: d.adobe_analytics,
          generic_analytics: d.generic_analytics,
          facebook_analytics: d.facebook_analytics,
          twitter_analytics: d.twitter_analytics,
          linkedin_analytics: d.linkedin_analytics,
        });
      }
    }
  };
  checksmtp = async () => {
    if (this.validate()) {
      // let request = await Utility.ExecuteData("checkSmtpSettings", this.state.token, this.state.form)
      // if (request.code == 200) {
      //     let response = request.data;
      //     if (response.success == 1) {
      //         alert(__t("valid_smtp_configurations"))
      //         this.setState({ "verified": true })
      //     }
      // } else {
      //     alert(__t("invalid_smtp_configurations"))
      // }
    }
  };
  handleUpdate = async () => {
    if (this.state.validate) {
      let urldata = {
        id: this.state.client_id,
        google_analytics: this.state.google_analytics,
        adobe_analytics: this.state.adobe_analytics,
        generic_analytics: this.state.generic_analytics,
        facebook_analytics: this.state.facebook_analytics,
        twitter_analytics: this.state.twitter_analytics,
        linkedin_analytics: this.state.linkedin_analytics,
      };
      let request = await Utility.ExecuteData(
        "update_tracking_analytics",
        this.state.token,
        urldata
      );
      if (request.code == 200) {
        alert(__t("tracking_updated"));
      }
    } else {
      alert(__t("nothing_to_update"));
    }
  };
  handleChange = (e) => {
    let isChecked = e.target.value;
    if (isChecked === "on") {
      this.setState({ [e.target.name]: true, validate: true });
    } else {
      this.setState({ [e.target.name]: false, validate: true });
    }
  };

  render() {
    let {
      google_analytics,
      adobe_analytics,
      generic_analytics,
      facebook_analytics,
      twitter_analytics,
      linkedin_analytics,
      show_ga,
      show_adobe,
      show_generic,
      show_facebook,
      show_twitter,
      show_linkedin,
    } = this.state;

    return (
      <Tab eventKey={4} title="SMTP Detail For Client" disabled>
        <div className="row">
          <div className="col-md-6 col-sm-6 col-xs-12">
            <h2>
              <label className="control-label">
                {__t("campaign_analytics_tracking")}
              </label>
            </h2>
            {show_ga && (
              <div className="row">
                <div className="radio col-md-8 col-sm-8 col-xs-10">
                  <label className="control-label">
                    {__t("google_analytics")}
                  </label>
                </div>
                <div className="radio col-md-2 col-sm-2 col-xs-3">
                  <label>
                    <input
                      type="radio"
                      value="on"
                      name="google_analytics"
                      id="google_analytics"
                      checked={google_analytics === true ? true : false}
                      onClick={(e) => this.handleChange(e)}
                    />{" "}
                    ON
                  </label>
                </div>
                <div className="radio col-md-2 col-sm-2 col-xs-3">
                  <label>
                    <input
                      type="radio"
                      value="off"
                      name="google_analytics"
                      id="google_analytics"
                      checked={google_analytics === false ? true : false}
                      onClick={(e) => this.handleChange(e)}
                    />{" "}
                    OFF
                  </label>
                </div>
              </div>
            )}
            {show_adobe && (
              <div className="row">
                <div className="radio col-md-8 col-sm-8 col-xs-10">
                  <label className="control-label">
                    {__t("adobe_analytics")}
                  </label>
                </div>
                <div className="radio col-md-2 col-sm-2 col-xs-3">
                  <label>
                    <input
                      type="radio"
                      value="on"
                      name="adobe_analytics"
                      id="adobe_analytics"
                      checked={adobe_analytics === true ? true : false}
                      onClick={(e) => this.handleChange(e)}
                    />{" "}
                    ON
                  </label>
                </div>
                <div className="radio col-md-2 col-sm-2 col-xs-3">
                  <label>
                    <input
                      type="radio"
                      value="off"
                      name="adobe_analytics"
                      id="adobe_analytics"
                      checked={adobe_analytics === false ? true : false}
                      onClick={(e) => this.handleChange(e)}
                    />{" "}
                    OFF
                  </label>
                </div>
              </div>
            )}
            {show_generic && (
              <div className="row">
                <div className="radio col-md-8 col-sm-8 col-xs-10">
                  <label className="control-label">
                    {__t("generic_analytics")}
                  </label>
                </div>
                <div className="radio col-md-2 col-sm-2 col-xs-3">
                  <label>
                    <input
                      type="radio"
                      value="on"
                      name="generic_analytics"
                      id="generic_analytics"
                      checked={generic_analytics === true ? true : false}
                      onClick={(e) => this.handleChange(e)}
                    />{" "}
                    ON
                  </label>
                </div>
                <div className="radio col-md-2 col-sm-2 col-xs-3">
                  <label>
                    <input
                      type="radio"
                      value="off"
                      name="generic_analytics"
                      id="generic_analytics"
                      checked={generic_analytics === false ? true : false}
                      onClick={(e) => this.handleChange(e)}
                    />{" "}
                    OFF
                  </label>
                </div>
              </div>
            )}
          </div>
          <div className="col-md-6 col-sm-6 col-xs-12">
            <h2>
              <label className="control-label">
                {__t("campaign_social_media_tracking")}
              </label>
            </h2>
            {show_facebook && (
              <div className="row">
                <div className="radio col-md-8 col-sm-8 col-xs-10">
                  <label className="control-label">
                    {__t("facebook_analytics")}
                  </label>
                </div>
                <div className="radio col-md-2 col-sm-2 col-xs-3">
                  <label>
                    <input
                      type="radio"
                      value="on"
                      name="facebook_analytics"
                      id="facebook_analytics"
                      checked={facebook_analytics === true ? true : false}
                      onClick={(e) => this.handleChange(e)}
                    />{" "}
                    ON
                  </label>
                </div>
                <div className="radio col-md-2 col-sm-2 col-xs-3">
                  <label>
                    <input
                      type="radio"
                      value="off"
                      name="facebook_analytics"
                      id="facebook_analytics"
                      checked={facebook_analytics === false ? true : false}
                      onClick={(e) => this.handleChange(e)}
                    />{" "}
                    OFF
                  </label>
                </div>
              </div>
            )}
            {show_twitter && (
              <div className="row">
                <div className="radio col-md-8 col-sm-8 col-xs-10">
                  <label className="control-label">
                    {__t("twitter_analytics")}
                  </label>
                </div>
                <div className="radio col-md-2 col-sm-2 col-xs-3">
                  <label>
                    <input
                      type="radio"
                      value="on"
                      name="twitter_analytics"
                      id="twitter_analytics"
                      checked={twitter_analytics === true ? true : false}
                      onClick={(e) => this.handleChange(e)}
                    />{" "}
                    ON
                  </label>
                </div>
                <div className="radio col-md-2 col-sm-2 col-xs-3">
                  <label>
                    <input
                      type="radio"
                      value="off"
                      name="twitter_analytics"
                      id="twitter_analytics"
                      checked={twitter_analytics === false ? true : false}
                      onClick={(e) => this.handleChange(e)}
                    />{" "}
                    OFF
                  </label>
                </div>
              </div>
            )}
            {show_linkedin && (
              <div className="row">
                <div className="radio col-md-8 col-sm-8 col-xs-10">
                  <label className="control-label">
                    {__t("linkedin_analytics")}
                  </label>
                </div>
                <div className="radio col-md-2 col-sm-2 col-xs-3">
                  <label>
                    <input
                      type="radio"
                      value="on"
                      name="linkedin_analytics"
                      id="linkedin_analytics"
                      checked={linkedin_analytics === true ? true : false}
                      onClick={(e) => this.handleChange(e)}
                    />{" "}
                    ON
                  </label>
                </div>
                <div className="radio col-md-2 col-sm-2 col-xs-3">
                  <label>
                    <input
                      type="radio"
                      value="off"
                      name="linkedin_analytics"
                      id="linkedin_analytics"
                      checked={linkedin_analytics === false ? true : false}
                      onClick={(e) => this.handleChange(e)}
                    />{" "}
                    OFF
                  </label>
                </div>
              </div>
            )}
          </div>
        </div>

        <div className="row form-group">
          <div className="col-md-12">
            <button
              className={
                "btn btn-primary " + (this.state.validate ? "" : "disabled")
              }
              onClick={this.handleUpdate}
              type="submit"
            >
              {__t("update")}
            </button>
          </div>
        </div>
      </Tab>
    );
  }
}

export default Tab4ForAdminReseller;
