import React from 'react';
import { __t } from "../../../locale/language";
export default class DropdownField extends React.Component {
    constructor(props) {
        super(props)
        this.state = {

        }
        this.value = props.defaultValue;
    }
    componentDidMount() {
        let defaultValue = this.props.defaultValue;
        let list_options = this.props.options;
        this.setState({ value:defaultValue, list_options })

    }
    componentWillReceiveProps(nextProps) {
        if (this.props != nextProps) {
            let defaultValue = nextProps.defaultValue;
            let list_options = nextProps.options;
            this.setState({ value: defaultValue, defaultValue, list_options })

        }

    }
    onChange = (e) => {
        if (this.props.onChange) {
            this.props.onChange(e);
        }
        this.value = e.target.value;
        this.setState({ value: e.target.value })
    }
    render() {
        
        return (<><select name="contact_list" className={"form-control " + this.props.className}
            //value={this.state.contact_list} onChange={this.handleChange} 
            value={this.state.value} onChange={this.onChange}
        >
            <option value="select">{this.props.label}</option>
            {this.state.list_options && this.state.list_options.length > 0
                ? this.state.list_options.map(v => {
                    if (v.value != null) {
                        return (
                            <option key={v.value} value={v.value}>
                                {v.label}
                            </option>
                        );
                    }

                })
                : ""}
        </select></>)
    }
}