import React from "react";
import { Link } from "react-router-dom";
import Utility from "../common/Utility";
import { __t } from "../../locale/language";
import Can from "../common/AccessControl";
import ImportModal from "./ImportModal";
import Moment from "moment";
import CreateContact from "./CreateContact";
import $ from "jquery";

class DataList extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            type : this.props.match.params.type,
            page: 1,
            data_sets: [],
            tables : [],
            isOpen : false,
            displayColumns: [{ COLUMN_NAME: "TitleAbbEng" }, { COLUMN_NAME: "ParlLastName" }, { COLUMN_NAME: "ParlFirstName" }, { COLUMN_NAME: "ProvinceEng" }, { COLUMN_NAME: "ConstituencyName" }, { COLUMN_NAME: "Email:" }, { COLUMN_NAME: "HillAddPhone" }, { COLUMN_NAME: "PartyShortTitle" }],
            columns : [], 
            table : "",
            editItem : null,
            scenario : "",
            search:""
        };
        // this.search = "";
        this.tablename = ""
    }

    componentWillMount() {
        this.getTables();
    }
    editItem = (editItem) => {
        this.setState({ editItem, scenario : "update"});
    }
    toggle = () =>{
        this.setState({ isOpen: !this.state.isOpen});
    }
    changeTable = async () =>{
        let table = this.tablename.value;
        let res = await this.getContactData(table, 1);
        if (res.code == 200) {
            this.setState({ data_sets: res.data, page: 1, table });
        }
    }
    getTables = async () =>{
        let res = await Utility.executeRequest("get-data-tables", {});
        
        if (res.code == 200){
            
            let tables = this.state.type == "contact_lists" ? res.tables.contact_lists : res.tables.riding_table;
            let rescolumns = await Utility.executeRequest("get-data-columns", { table: tables[0].table_name });
            let contactData = await this.getContactData(tables[0].table_name, 1);
            this.setState({ tables: tables, columns: rescolumns.data, data_sets: contactData.data, table : tables[0].table_name});
        }
    }
    getContactData = async (table, page) =>{
        table = table || this.state.table;
        page = page || this.state.page;
        return await Utility.executeRequest("get-data-contact", { page : page, type: this.state.type, table: table,search:this.state.search });
    }
    clearSearch = async () => {
        //this.getDataSet(1);
       // this.getDataSet(this.state.page)
       this.getTables();
    };
    getDataSet = async page => {
        let res = await this.getContactData(this.state.table, page);
        if (res.code == 200) {
            this.setState({ data_sets: res.data, page: page });
        }
    };
    updateTable = () => {
        //console.log("sdcf")
        //console.log(this.state.search)
        this.getDataSet(this.state.page);
    };
    deleteData = async id => {
        if (window.confirm(__t("delete_confirm"))) {
            let res = await Utility.executeRequest("delete-data-contact", { type : this.state.type, table: this.state.table, id: id });
            if (res.code == 200) {
                this.getDataSet(this.state.page);
            }
        }
    };
    toggleForm = () =>{
        this.setState({ editItem : null, scenario : ""},()=>{
            this.updateTable()
        });
    }
    createContact = () => {
        this.setState({ editItem: null, scenario: "create" });
    }
    handleChange=(event)=> {
        this.setState({ [event.target.name]: event.target.value });
      }
    render() {
        let { columns, scenario, editItem, table, type } = this.state;
        if (editItem != null || scenario !== ""){
            if (scenario == "create"){
                editItem= {};
                columns.map(v => {
                    Object.assign(editItem, { [v.COLUMN_NAME]: "" });
                })
            }
            return(
                <CreateContact 
                columns={columns} 
                scenario={scenario} 
                toggleForm={this.toggleForm} 
                editItem={editItem} 
                table={table} 
                type={type} />
            );
            
        }
        const { data_sets } = this.state;
        return (
            <React.Fragment>
                <div className="row wrapper border-bottom white-bg page-heading">
                    <div className="col-lg-10">
                        <h2 style={{ marginTop: 10 }}>{__t(this.state.type)}</h2>
                        <ol className="breadcrumb">
                            <li className="breadcrumb-item">
                                <Link to="/">{__t("dashboard")}</Link>
                            </li>
                            <li className="breadcrumb-item">
                                <Link to="/contacts">{__t("contact_data_set")}</Link>
                            </li>
                            <li className="breadcrumb-item active">
                                <strong>{__t(this.state.type)}</strong>
                            </li>
                        </ol>
                    </div>
                </div>
                <div className="wrapper wrapper-content animated">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="ibox ">
                                <div className="ibox-title">
                                    <div className="row">
                                        <div className="col-md-10 col-sm-12 col-xs-12">
                                            <div className=" form-inline">
                                                <div className="form-group mb-2">
                                                    <select name="tablename" onChange={this.changeTable} ref={tablename => this.tablename = tablename} className="form-control">
                                                        {this.state.tables.map(v=>{
                                                            return <option key={v.table_name} value={v.table_name}>{v.table_name}</option>
                                                        })}
                                                    </select>
                                                </div>
                                                <div className="form-group mb-2">
                                                    <input
                                                        type="text"
                                                        name="search"
                                                        ref={search => {
                                                            this.search = search;
                                                        }}
                                                        id="search_field"
                                                        defaultValue={this.state.search}
                                                        className="form-control"
                                                        placeholder={__t("search_by_first_name")}
                                                        onChange={this.handleChange}
                                                    />
                                                </div>

                                                <button onClick={this.updateTable} className="btn btn-primary mb-2">
                                                    {__t("search")}
                                                </button>
                                                <button onClick={this.clearSearch} className="btn btn-warning mb-2"
                                                 onClick={() => {
                                                    this.setState({ search: "" }, () => {
                                                    //   this.updateContactList(1, 10, this.state.category);
                                                    this.clearSearch()
                                                      document.getElementById("search_field").value =
                                                        "";
                                                    });
                                                  }}
                                                >
                                                    {__t("clear")}
                                                </button>
                                              
                                                <button onClick={this.createContact} className="btn btn-primary mb-2">
                                                    {__t("create")}
                                                </button>
                                               
                                               
                                            </div>
                                        </div>
                                        <div className="col-md-2 col-sm-12 col-xs-12">
                                        <Link className="btn btn-dark mb-2" to={{ pathname: `/contacts` }} style={{"float":"right"}}>
                                                    {__t("back")}
                                                </Link>
                                            {/* <div className="form-inline" style={{ float: "right" }}>
                                                <button onClick={this.toggle} className="btn btn-primary mb-2">
                                                    {__t("import_csv")}
                                                </button>
                                            </div> */}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="ibox-content my-2">
                        {data_sets.count > 0 ? (
                            <table width="" border="0" className={"table table-striped table-hover table-bordered table-condensed tbl_word_break table-responsive"}>
                                <thead>
                                    <tr>
                                        {this.state.displayColumns.map(v=>{
                                            return <th key={"th" + v.COLUMN_NAME}>{v.COLUMN_NAME}</th>
                                        })}
                                        <th>{__t("action")}</th>
                                    </tr>
                                </thead>

                                <tbody>
                                    {data_sets.rows.map(v => {
                                        return (
                                            <tr key={v.id}>
                                                {this.state.displayColumns.map(column => {
                                                    return <td key={column.COLUMN_NAME}>{v[column.COLUMN_NAME]}</td>
                                                })}

                                                <td className="flex_align_center">
                                                    <button className="btn btn-sm btn-primary mr-2"
                                                        onClick={() => {
                                                            this.editItem(v);
                                                        }}>
                                                       
                                                        <i className="fa fa-pencil" />
                                                    </button>
                                                   
                                                    <button
                                                        className="btn btn-sm btn-primary"
                                                        onClick={() => {
                                                            this.deleteData(v.ID);
                                                        }}
                                                    >
                                                        {" "}
                                                        <i className="fa fa-trash" />
                                                    </button>
                                                </td>
                                            </tr>
                                        );
                                    })}
                                </tbody>
                            </table>
                        ) : (
                                <div>
                                    <div className="">{__t("no_record_found")}!</div>
                                </div>
                            )}

                        {this.pagination(this.state.page, 10, data_sets.count, this.getDataSet)}
                    </div>
                </div>
                <ImportModal type={this.state.type} isOpen={this.state.isOpen} toggle={this.toggle} tables={this.state.tables}></ImportModal>
            </React.Fragment>
        );
    }
    pagination = (page, sizePerPage, total, updateTable) => {
        if (total <= 0) {
            return "";
        }
        let total_page = Math.ceil(total / sizePerPage);
        let pages = [];
        //console.log(total_page)
        for (let i = 1; i <= total_page; i++) {
            if (total_page > 10 || page >= 10){
                if (i < page - 5 || i > page + 5){
                    continue;
                }
            }
            pages.push(
                <li key={i} className={page == i ? "page-item active" : "page-item"}>
                    <a
                        className="page-link"
                        onClick={e => {
                            updateTable(i);
                            e.preventDefault();
                        }}
                    >
                        {i}
                    </a>
                </li>
            );
        }
        return (
            <nav aria-label="Page navigation example">
                <ul className="pagination justify-content-end">
                    <li className={page <= 1 ? "page-item disabled" : "page-item"} disabled={page <= 1 ? "disabled" : ""}>
                        <a
                            className="page-link"
                            onClick={e => {
                                if (page > 1) {
                                    updateTable(page - 1);
                                }
                                e.preventDefault();
                            }}
                        >
                            {__t("back")}
                        </a>
                    </li>
                    {pages}

                    <li className={page == total_page ? "page-item disabled" : "page-item"} disabled={page == total_page ? "disabled" : ""}>
                        <a
                            className="page-link"
                            onClick={e => {
                                if (page < total_page) {
                                    updateTable(page + 1);
                                }
                                e.preventDefault();
                            }}
                        >
                            {__t("next")}
                        </a>
                    </li>
                </ul>
            </nav>
        );
    };
}

export default DataList;
