import React, { Component } from "react";
import { __t } from "../../locale/language";

import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";

class ImportModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tables: this.props.tables,
      type: this.props.type,
      isOpen: false
    };
    this.tablename = "";
  }
  componentWillReceiveProps(isOpen) {
    this.setState(isOpen);
  }
  render() {
    return (
      <Modal key={1} isOpen={this.state.isOpen} toggle={() => this.props.toggle()} className={this.props.className}>
        <ModalHeader toggle={() => this.props.toggle()}>{__t("import_csv")}</ModalHeader>
        <ModalBody>
          <div className=" form-inline">
            <div className="col-md-4 form-group">
              <select name="tablename" ref={tablename => (this.tablename = tablename)} className="form-control">
                {this.state.tables.map(v => {
                  return (
                    <option key={v.table_name} value={v.table_name}>
                      {v.table_name}
                    </option>
                  );
                })}
              </select>
            </div>
            <div className="col-md-8 form-group file_input_wrap">
              <div className="box">
                <input type="file" name="csv" id="imagefile" onChange={this.onChange} className="line_h30 inputfile inputfile-2" />
                <label for="imagefile">
                  <i className="fa fa-upload upload_icon" aria-hidden="true" />
                  <span className="inputfile_span">{this.state.file ? this.state.file.name : __t("choose_a_file")}</span>
                </label>
              </div>
              <button type="submit" className="btn btn-primary mx-2 upload">
                {__t("upload")}
              </button>
              <div className="first_loader loader_full " id="upload-loader" style={{ display: "none" }}>
                <div className="lds-ring">
                  <div />
                  <div />
                  <div />
                  <div />
                </div>
              </div>
            </div>
          </div>
        </ModalBody>
        <ModalFooter>
          <Button
            color="primary"
            onClick={() => {
              this.props.toggle();
            }}
          >
            {__t("import")}
          </Button>{" "}
          <Button
            color="secondary"
            onClick={() => {
              this.props.toggle();
            }}
          >
            {__t("cancel")}
          </Button>
        </ModalFooter>
      </Modal>
    );
  }
}

export default ImportModal;
