import React from "react";
import Progress from "../common/Progress";
import Navigation from "../common/Navigation";
import Footer from "../common/Footer";
import TopHeader from "../common/TopHeader";
import { correctHeight, detectBody } from "./Helpers";
import Dashboard from "../dashboard/index";
import UserIndex from "../User/index";
import PaymentgatewayIndex from "../paymentgateway/index";
import EmailIndex from "../email-template/index";
import ClientIndex from "../client/index";
import CampaignIndex from "../campaign/index";
import RoleIndex from "../roles/index";
import Utility from "../common/Utility";
import AssetsIndex from "../assets/index";
import WidgetIndex from "../widgetseditor/index";
import SubscriptionIndex from "../Subscription/index";
import config from "../../config";
import jwt from "jsonwebtoken";
import { Route, Switch } from "react-router-dom";
import { Redirect } from "react-router-dom";
import Template from "../client/Template";
import BulkEmail from "../bulk-email/index";
import Preview from "../client/Preview";
import Preview1 from "../client/Preview1";
import Preview2 from "../client/Preview2";
import Preview3 from "../client/Preview3";
import CreateTemplate from "../client/CreateTemplate";
import ClientDetails from "../dashboard/ClientDetails";
import ClientOverview from "../dashboard/ClientOverview";
import ContactIndex from "../contact/index";
import ResellerIndex from "../reseller/index";
import Alertyesno from "../common/Alertyesno";
import Alertinfo from "../common/Alertinfo";
import AlertDelete from "../common/AlertDelete";
import Settings from "../settings/settings";
import Transaction from "../transaction/index";
import BackupsIndex from "../db-backups/index";
import CheckOut from "../checkout/checkout";
import WidgetMail from "../widget-email/index";
import AllUser from "../alluser/index";
import LetterVariations from "../letter-variations/index";

import $ from "jquery";
import Spinner from "../common/Spinner";
import SubscriptionAlert from "../common/SubscriptionAlert";
import { __t } from "../../locale/language";
import { Link } from "react-router-dom";

import ClientSubscription from "../clientsubscription/index";

var subscription_div = "";
var verified_div = "";
var verified_token = "";
var verified_your_div = "";
require("react-bootstrap-table-next/dist/react-bootstrap-table2.min.css");
require("bootstrap/dist/css/bootstrap.min.css");
var subscription_status;
var subscription_id;
class Main extends React.Component {
  constructor(props, context) {
    super(props);
    this.state = {
      redirect: false,
      userPermissions: [],
      id: "",
      user_type: "",
      first_name: "",
      last_name: "",
      client_id: "",
      subscription_feature: [],
      subscription_status: "",
      subscription_id: "",
      verified_token: ""
    };
    this.token = localStorage.getItem("token");

    this.changeLanguage = this.changeLanguage.bind(this);
  }
  componentWillMount() {
    if (window.location.href.indexOf("token") > -1) {
      this.token = Utility.getUrlParameter("token");
      localStorage.setItem("token", this.token);
    }
    if (this.token != "" && this.token != null && typeof this.token != "undefined") {
      this.setPermission(this.token);
    } else {
      this.setState({ redirect: true });
    }
  }
  componentDidMount() {
    this.getIdFromToken();
    // Run correctHeight function on load and resize window event
    $(window).bind("load resize", function() {
      correctHeight();
      detectBody();
    });

    // Correct height of wrapper after metisMenu animation.
    $(".metismenu a").click(() => {
      setTimeout(() => {
        correctHeight();
      }, 300);
    });
  }

  getIdFromToken = token => {
    let localData = localStorage.getItem("lgn_dtl");
    localData = JSON.parse(localData);
    if (localData) {
      this.setState({
        id: localData.id,
        client_id: localData.client_id,
        user_type: localData.user_type,
        first_name: localData.first_name,
        last_name: localData.last_name
      });
    }
  };
  updatesubscriptionexpired = () => {
    Utility.ExecuteData("updatesubscriptionexpired", this.state.token, {
      token: this.token,
      client_id: this.state.client_id
    }).then(data => {
      if (data.code == 200) {
      }
    });
  };

  setPermission(token) {
    var features = [];
    this.updatesubscriptionexpired();
    Utility.GetUserPermission(token).then(response => {
      if (response.code == "403") {
        this.token = "";
        this.setState({ redirect: true });
        return false;
      }
      if (response.code == "204") {
        return false;
      }

      if (typeof response.err != "undefined") {
        if (response.err.name == "TokenExpiredError") {
          this.token = "";
        }
      } else {

        var verified_token = typeof response.data.verified_token[0] != "undefined" ? response.data.verified_token[0].verified_token : 0;
        // if(verified_token==0){

        verified_div = (
          <div className={"alert alert-info"} id="sub_alert">
            {__t("plese_verify_smtp_detail")}

            <span id="verify_button">
              <Link to="/settings" className="btn btn-danger btn_sm_2" style={{ float: "right" }}>
                {__t("click_to_verify")}
              </Link>
            </span>
          </div>
        );

        verified_your_div = (
          <div className={"alert alert-info"} id="sub_alert">
            {__t("please_verify_your_smtp_detail")}

            <span id="verify_button">
              <Link to="/settings" className="btn btn-danger btn_sm_2" style={{ float: "right" }}>
                {__t("click_to_verify")}
              </Link>
            </span>
          </div>
        );
        // }
        response.data.feature.map((val, i) => {
          features.push(val.key);
        });
        if (response.data.result_status.length > 0) {
          subscription_status = response.data.result_status[0].status;
          subscription_id = response.data.result_status[0].subscription_id;

          let class_alert = "";
          var button = (
            <Link to="/clientsubscription" className="btn btn-danger btn_sm_2" style={{ float: "right" }}>
              {__t("click_to_renew")}
            </Link>
          );

          var sub_status;
          if (subscription_status == "expire") {
            class_alert = "alert-danger";
            sub_status = "expired";
          } else if (subscription_status == "pause") {
            class_alert = "alert-warning";
            sub_status = "paused";
          }

          subscription_div = (
            <div className={"alert " + class_alert} id="sub_alert">
              <strong>
                {__t("your_subscription")} {sub_status}.
              </strong>{" "}
              {__t("please_click_the_renew_button")}
              {subscription_status == "expire" ? <span>{button}</span> : ""}
            </div>
          );
        } else {
          subscription_status = "";
        }
        // console.log(response)
        this.setState({
          userPermissions: response.data.permission,
          subscription_feature: features,
          subscription_status: subscription_status,
          subscription_id: subscription_id,
          verified_token: verified_token,
          subdomain: typeof response.data.verified_token[0]  != "undefined"? response.data.verified_token[0].subdomain : "",
        });
      }
    });
  }
  changeLanguage = event => {
    localStorage.setItem("language", event.target.value);
    this.setState({ lang: event.target.value });
    //window.location.reload();
  };

  render() {
    if (!this.token) {
      return <Redirect push to="/login" />;
    }
    let wrapperClass = "gray-bg  " + this.props.location.pathname;
    return (
      <div>
        {this.state.userPermissions.length > 0 ? (
          <div id="wrapper">
            <Progress />
            <Navigation location={this.props.location} userPermissions={this.state} />

            <div id="page-wrapper" className={wrapperClass}>
              <Spinner />
              <TopHeader changeLanguage={this.changeLanguage} userPermissions={this.state} />
              {this.state.user_type == "admin" && this.state.user_type == "reseller" ? "" : subscription_status == "pause" || subscription_status == "expire" ? <span>{subscription_div}</span> : ""}
              {this.state.verified_token && this.state.verified_token == "0" ? 
                /*<span id="verified_token">{verified_div}</span>*/ ""
               : this.state.verified_token && this.state.verified_token == "2" ? (
                <span id="verified_token_tk">{verified_your_div}</span>
              ) : (
                ""
              )}

              <Switch>
                <Route path="/" exact render={props => <Dashboard key={"pp_home1"} {...props} userPermissions={this.state} />} />
                <Route path="/dashboard" render={props => <Dashboard key={"pp_home2"} {...props} userPermissions={this.state} />} />
                <Route path="/details/:id" render={props => <ClientDetails {...props} userPermissions={this.state} />} />

                <Route path="/clientdetail/:id" render={props => <ClientOverview {...props} userPermissions={this.state} />} />

                <Route path="/clientsubscription" render={props => <ClientSubscription {...props} userPermissions={this.state} />} />

                <Route path="/user" render={props => <UserIndex {...props} userPermissions={this.state} />} />
                <Route path="/client" render={props => <ClientIndex {...props} userPermissions={this.state} />} />
                <Route path="/reseller" render={props => <ResellerIndex {...props} userPermissions={this.state} />} />
                <Route path="/role" render={props => <RoleIndex {...props} userPermissions={this.state} />} />
                <Route path="/subscription" render={props => <SubscriptionIndex {...props} userPermissions={this.state} />} />
                <Route path="/paymentgateway" render={props => <PaymentgatewayIndex {...props} userPermissions={this.state} />} />
                <Route path="/widgets" render={props => (<SubscriptionAlert />, <WidgetIndex {...props} userPermissions={this.state} />)} />
                <Route path="/email-template" render={props => <EmailIndex {...props} userPermissions={this.state} />} />
                <Route path="/assets" render={props => <AssetsIndex {...props} userPermissions={this.state} />} />
                <Route path="/campaign" render={props => (<SubscriptionAlert />, <CampaignIndex {...props} userPermissions={this.state} />)} />
                <Route path="/templates" render={props => (<SubscriptionAlert />, <Template {...props} userPermissions={this.state} />)} />
                <Route path="/template/:id" render={props => <Preview {...props} userPermissions={this.state} />} />
                <Route path="/template2" render={props => <Preview1 {...props} userPermissions={this.state} />} />
                <Route path="/template3" render={props => <Preview2 {...props} userPermissions={this.state} />} />
                <Route path="/template4" render={props => <Preview3 {...props} userPermissions={this.state} />} />
                <Route path="/CreateTemplate" render={props => <CreateTemplate {...props} userPermissions={this.state} />} />
                <Route path="/contacts" render={props => <ContactIndex {...props} userPermissions={this.state} />} />
                <Route path="/db-backups" render={props => <BackupsIndex {...props} userPermissions={this.state} />} />
                <Route path="/settings" render={props => <Settings {...props} userPermissions={this.state} />} />
                <Route path="/transaction" render={props => <Transaction {...props} userPermissions={this.state} />} />
                <Route path="/checkout/:id" render={props => <CheckOut {...props} userPermissions={this.state} />} />
                <Route path="/widget-email" render={props => <WidgetMail {...props} userPermissions={this.state} />} />
                <Route path="/all-user" render={props => <AllUser {...props} userPermissions={this.state} />} />
                <Route path="/bulk-email" render={props => <BulkEmail {...props} userPermissions={this.state} />} />
                {/* <Route path="/letter-variations" render={props => <LetterVariations {...props} userPermissions={this.state} />} /> */}
              </Switch>
              <Alertinfo />
              <Alertyesno />
              <Footer />
            </div>
          </div>
        ) : (
          ""
        )}
      </div>
    );
  }
}

export default Main;
