import React from "react";
import { Route, Switch } from "react-router-dom";

import LetterVariationList from "./LetterVariationList";
import VariationsUpdate from "./VariationsUpdate";

class Index extends React.Component {
  constructor(props) {
    super(props);
  }
  render() {
    return (
      <Switch>
        <Route
          path="/letter-variations/create"
          render={(props) => (
            <VariationsUpdate
              {...props}
              userPermissions={this.props.userPermissions}
            />
          )}
        />
        <Route
          path="/letter-variations/create/"
          render={(props) => (
            <VariationsUpdate
              {...props}
              userPermissions={this.props.userPermissions}
            />
          )}
        />
        <Route
          path="/letter-variations/update/:id"
          render={(props) => (
            <VariationsUpdate
              {...props}
              userPermissions={this.props.userPermissions}
            />
          )}
        />
        <Route
          path="/letter-variations"
          render={(props) => (
            <LetterVariationList
              {...props}
              userPermissions={this.props.userPermissions}
            />
          )}
        />
      </Switch>
    );
  }
}

export default Index;
