import React from "react";
import RoleList from "./RoleList";
import RoleUpdate from "./RoleUpdate";
import { Route, Switch } from "react-router-dom";
class Index extends React.Component {
  constructor(props) {
    super(props);
  }
  render() {
    return (
      <Switch>
        <Route
          path="/role/update"
          render={props => (
            <RoleUpdate
              {...props}
              userPermissions={this.props.userPermissions}
            />
          )}
        />
        <Route
          path="/role/update/"
          render={props => (
            <RoleUpdate
              {...props}
              userPermissions={this.props.userPermissions}
            />
          )}
        />
        <Route
          path="/role/update/:id"
          render={props => (
            <RoleUpdate
              {...props}
              userPermissions={this.props.userPermissions}
            />
          )}
        />
        <Route
          path="/role"
          render={props => (
            <RoleList {...props} userPermissions={this.props.userPermissions} />
          )}
        />
      </Switch>
    );
  }
}

export default Index;
