import React from "react";

const checkPermissions = (userPermissions, allowedPermissions) => {
  if (allowedPermissions.length === 0) {
    return true;
  }

  return userPermissions.some(permission =>
    allowedPermissions.includes(permission)
  );
};

const AccessControl = ({ I, userPermissions, IfNot = "", children }) => {
  const permitted = checkPermissions(userPermissions, I);
  if (permitted) {
    return children;
  }
  return IfNot();
};

AccessControl.defaultProps = {
  allowedPermissions: [],
  permissions: [],
  IfNot: () => null
};

export default AccessControl;
