import React from "react";
import ContactDataSet from "./ContactDataSet";
import CreateDataSet from "./CreateDataSet";
import DataList from "./DataList";
import DatabaseConfiguration from "./DatabaseConfiguration"


import { Route, Switch } from "react-router-dom";
class Index extends React.Component {
  constructor(props) {
    super(props);
  }
  render() {
    return (
      <Switch>
        {/* <Route path="/contacts/:category/create" render={props => <ContactCreate {...props} userPermissions={this.props.userPermissions} />} /> */}
        {/* <Route path="/contacts/contact/update/:id" render={props => <CreateContact {...props} userPermissions={this.props.userPermissions} />} /> */}
        {/* <Route path="/dbconfig" render={props => <DatabaseConfiguration {...props} userPermissions={this.state} />} /> */}
        <Route path="/contacts" exact render={props => <ContactDataSet {...props} userPermissions={this.props.userPermissions} />} />
        <Route path="/contacts/dataset/create" exact render={props => <CreateDataSet {...props} userPermissions={this.props.userPermissions} />} />
        <Route path="/contacts/dataset/dbconfig" exact render={props => <DatabaseConfiguration {...props} userPermissions={this.props.userPermissions} />} />
        <Route path="/contacts/dataset/update/:id" exact render={props => <CreateDataSet {...props} userPermissions={this.props.userPermissions} />} />
        <Route path="/contacts/dataset/:type" render={props => <DataList {...props} userPermissions={this.props.userPermissions} />} />
        
      </Switch>
    );
  }
}

export default Index;
