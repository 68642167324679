import React, { Component } from "react";
import grapesjs from "grapesjs";
import Utility from "../common/Utility";

class Preview2 extends Component {
  constructor(props) {
    super(props);
    this.editor = null;
    this.saveContent = this.saveContent.bind(this);
  }

  saveContent() {
    var iframe = document.getElementsByClassName("gjs-frame")[0];
    var innerDoc = iframe.contentDocument || iframe.contentWindow.document;
    var frameBody = innerDoc.querySelector("body");
    var style = frameBody.querySelector("style");
    var htmlContent = frameBody.querySelector("#wrapper");
    var html = htmlContent.innerHTML; //get html content of document
    var css = this.editor.getCss(); //get css content of document
    //console.log(html);
  }

  shouldComponentUpdate() {
    return false;
  }
  componentDidMount() {
    this.editor = grapesjs.init({
      allowScripts: 1,
      showOffsets: 1,
      autorender: 0,
      noticeOnUnload: 0,
      container: "#gjs",
      height: "700px",
      fromElement: true,
      storageManager: { type: null },
      blockManager: { blocks: [] },
      canvas: {
        styles: [Utility.baseUrlFe() + "custom.css"]
      }
    });

    var pnm = this.editor.Panels;

    // pnm.removeButton("views", "open-sm");
    pnm.removeButton("views", "open-layers");
    pnm.removeButton("views", "open-blocks");
    pnm.removeButton("views", "open-tm");
    // pnm.removePanel("views");

    pnm.addButton("options", [
      {
        id: "undo",
        className: "fa fa-undo icon-undo",
        command: function command(editor, sender) {
          sender.set("active", 0);
          editor.UndoManager.undo(1);
        },
        attributes: {
          title: "Undo (CTRL/CMD + Z)"
        }
      },
      {
        id: "redo",
        className: "fa fa-repeat icon-redo",
        command: function command(editor, sender) {
          sender.set("active", 0);
          editor.UndoManager.redo(1);
        },
        attributes: {
          title: "Redo (CTRL/CMD + Y)"
        }
      },
      {
        id: "save",
        className: "fa fa-save",
        command: "saveHtml",
        attributes: {
          title: "Save"
        }
      }
    ]);

    this.editor.Commands.add("saveHtml", {
      run() {
        var iframe = document.getElementsByClassName("gjs-frame")[0];
        var innerDoc = iframe.contentDocument || iframe.contentWindow.document;
        var frameBody = innerDoc.querySelector("body");
        var style = frameBody.querySelector("style");
        var htmlContent = frameBody.querySelector("#wrapper");
        var html = htmlContent.innerHTML; //get html content of document
        //console.log(html);
        alert("Saved Successfully");
      }
    });

    this.editor.render();
    var comps = this.editor.DomComponents;

    // Change background of the wrapper and set some attribute
    var wrapper = comps.getWrapper();
    wrapper.set("style", {
      "background-color": "white"
    });
  }
  render() {
    return (
      <React.Fragment>
        <div className="panel__top">
          <div className="panel__basic-actions" />
          <div className="panel__devices" />
          <div className="panel__switcher" />
        </div>
        <div className="editor-row">
          <div className="editor-canvas">
            <div
              id="gjs"
              style={{ height: "700px !important", overflow: "hidden" }}
            >
              <div className="header_logo2 preview_header_2">
                <div className="logo_txt">
                  <img
                    src="/img/profile_small.jpg"
                    className="boder_readi_50 logo_img"
                  />
                  <small>Opalina Technology</small>
                </div>
                <div className="header_navbar">
                  <span>Conatct us </span>
                  <span>About us </span>
                  <span> Services </span>
                </div>
              </div>
              <div className="pos_rel">
                <div className="slide_image_2_txt">
                  <h2>Opalina Technology</h2>
                  <p>
                    The point of using Lorem Ipsum is that it has a more-or-less
                    normal
                  </p>
                </div>
                <img
                  src="/img/landing/header_one.jpg"
                  className="logo_img mtop105"
                />
              </div>
              <div className="preview_container2">
                <div className="preview2_section">
                  <div className="col-6 preview2_left_div ">
                    <h2 className="heading">Push Politics</h2>
                    <p className="header_para">
                      It is a long established fact that a reader will be
                      distracted by the readable content of a page when looking
                      at its layout. The point of using Lorem Ipsum is that it
                      has a more-or-less normal distribution of letters, as
                      opposed to using 'Content here, content here', making it
                      look like readable English.
                    </p>
                    <a href="#" className="link_white">
                      Know More
                    </a>
                  </div>
                  <div className="col-6 preview2_right_div pos_rel">
                    <div className="preview2_img_abs">
                      <button className="viewmore_btn">View More</button>
                    </div>
                    <img
                      src="/img/pic06.jpg"
                      className=" preview2_section_img"
                    />
                  </div>
                </div>

                <div className="d_flex preview2_flx_div">
                  <ul class="cards2">
                    <li class="cards__item">
                      <div class="card2">
                        <div class="card__image card__image--fence" />
                        <div class="card__content">
                          <div class="card__title">Flex</div>
                          <p class="card__text">
                            This is the shorthand for flex-grow, flex-shrink and
                            flex-basis combined. The second and third parameters
                            (flex-shrink and flex-basis) are optional. Default
                            is 0 1 auto.{" "}
                          </p>
                          <button class="link_btn">Button</button>
                        </div>
                      </div>
                    </li>
                  </ul>
                  <ul class="cards2">
                    <li class="cards__item">
                      <div class="card2">
                        <div class="card__image card__image--fence" />
                        <div class="card__content">
                          <div class="card__title">Flex</div>
                          <p class="card__text">
                            This is the shorthand for flex-grow, flex-shrink and
                            flex-basis combined. The second and third parameters
                            (flex-shrink and flex-basis) are optional. Default
                            is 0 1 auto.{" "}
                          </p>
                          <button class="link_btn">Button</button>
                        </div>
                      </div>
                    </li>
                  </ul>
                  <ul class="cards2">
                    <li class="cards__item">
                      <div class="card2">
                        <div class="card__image card__image--fence" />
                        <div class="card__content">
                          <div class="card__title">Flex</div>
                          <p class="card__text">
                            This is the shorthand for flex-grow, flex-shrink and
                            flex-basis combined. The second and third parameters
                            (flex-shrink and flex-basis) are optional. Default
                            is 0 1 auto.{" "}
                          </p>
                          <button class="link_btn">Button</button>
                        </div>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="footer_section">
                <div className=" footer_div bg_darkgreen">
                  <h4>Footer</h4>
                  <ul>
                    <li>Conatct us </li>
                    <li>About us </li>
                    <li> Services </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="footer_btns footer_btns_2 display_none">
          <button className="viewmore_btn mright20 bg_darkgreen">Save</button>
          <button className="viewmore_btn bg_darkgreen">Cancel</button>
        </div>
      </React.Fragment>
    );
  }
}

export default Preview2;
