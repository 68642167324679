import React, { Component } from "react";
import { Link, Redirect } from "react-router-dom";
import { Tabs, Tab } from "react-bootstrap";

import { __t } from "../../locale/language";
import Can from "../common/AccessControl";
import { AccessControlFunc } from "../common/AccessControl2";
import CampaignUpdate1 from "./CampaignUpdate1";
import UrlRedirection from "./UrlRedirection";
import Utility from "../common/Utility";
import LetterAutoresponder from "./LetterAutoresponder";
import NewsLetterAutoresponder from "./NewsLetterAutoresponder";

class CampaignUpdate extends Component {
  constructor(props) {
    super(props);
    this.state = {
      key: 1,
      update_redirect: false,
      redirectUrlto: false,
      isConnected: false,
      campaign: {},
      nation_token: '',
      subFeaturePerArr: [],
    };
    this.wholePermissions = this.props.userPermissions;
  }

  getData = async () => {
    const lgn_dtl = JSON.parse(localStorage.lgn_dtl);
    const client_id = lgn_dtl.client_id;
    console.log(client_id);

    const url = "https://api.pushpolitics.org/v1/get-data";
    fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ client_id }),
    })
      .then((response) => response.json())
      .then((data) => {
        console.log(data.token);
        console.log("Client exists");
        this.setState({ isConnected: true, nation_token: data.token }); // Set isConnected to true
      })
      .catch((error) => {
        console.error("An error occurred:", error);
      });
  };
  componentDidMount() {
    this.getData();
    if (
      this.props.location.state &&
      typeof this.props.location.state !== "undefined"
    ) {
      this.setState({ update: true });
    }

    if (this.props.location.state) {
      var camp_id = this.props.location.state.id;
      this.findCampaign(camp_id);
    }
  }
  findCampaign = (camp_id, key = null) => {
    Utility.ExecuteData("findcampaign", this.token, { id: camp_id }).then(
      (data) => {
        let campobj = data.data;
        this.setState(
          {
            campaign: campobj,
            update_redirect: campobj.redirect_url ? true : false,
          },
          () => {
            if (key) {
              this.setState({ redirectUrlto: true });
            }
          }
        );
      }
    );
  };
  handleSelect = (key) => {
    this.setState({ key: key });
  };
  handleCreateCampaign = (data, setkey) => {
    if (data.id) {
      this.findCampaign(data.id, setkey);
    }
  };

  render() {
    const {
      campaign,
      update_redirect,
      subFeaturePerArr,
      isConnected,
      nation_token,
      redirectUrlto,
    } = this.state;
    if (redirectUrlto) {
      const redirectUrl = `/campaign`;

      return <Redirect to={redirectUrl} />;
    }

    return (
      <Can
        I={["update-user"]}
        userPermissions={this.props.userPermissions.userPermissions}
      >
        <div>
          <div className="row wrapper border-bottom white-bg page-heading">
            <div className="col-lg-10">
              <h2 id="title2" style={{ marginTop: 10 }}>
                {__t("create_campaign")}
              </h2>
              <ol className="breadcrumb">
                <li className="breadcrumb-item">
                  <Link to="/">{__t("dashboard")}</Link>
                </li>
                <li className="breadcrumb-item">
                  <Link to="/campaign">{__t("campaign")}</Link>
                </li>
                <li className="breadcrumb-item active">
                  <strong id="title">{__t("create_campaign")}</strong>
                </li>
              </ol>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12">
              <div className="ibox float-e-margins">
                {/* <div className="ibox-title">
                  <h5>Subscription</h5>
                </div> */}
                <div className="ibox-content">
                  <div className="form-horizontal">
                    <Tabs
                      activeKey={this.state.key}
                      onSelect={this.handleSelect}
                      animation={false}
                      id="noanim-tab-example"
                    >
                      <Tab eventKey={1} title={__t("Campaign Details")}>
                        {this.state.key === 1 ? (
                          <CampaignUpdate1
                            {...this.props}
                            campaign={campaign}
                            isConnected={isConnected}
                            nation_token={nation_token}
                            handleCreateCampaign={this.handleCreateCampaign}
                          />
                        ) : (
                          ""
                        )}
                      </Tab>
                      {AccessControlFunc(
                        ["update-user"],
                        this.wholePermissions,
                        ["letter_autoresponder"]
                      ) && (
                        <Tab eventKey={2} title={__t("Letter Autoresponder")}>
                          {this.state.key === 2 ? (
                            <LetterAutoresponder
                              {...this.props}
                              campaign={campaign}
                              campid={campaign.id}
                              handleTopTabSelect={this.handleSelect}
                            />
                          ) : (
                            ""
                          )}
                        </Tab>
                      )}
                      {AccessControlFunc(
                        ["update-user"],
                        this.wholePermissions,
                        ["newsletter_autoresponder"]
                      ) && (
                        <Tab
                          eventKey={3}
                          title={__t("Newsletter Autoresponder")}
                        >
                          {this.state.key === 3 ? (
                            <NewsLetterAutoresponder
                              {...this.props}
                              campaign={campaign}
                              campid={campaign.id}
                              handleTopTabSelect={this.handleSelect}
                            />
                          ) : (
                            ""
                          )}
                        </Tab>
                      )}
                      {AccessControlFunc(
                        ["update-user"],
                        this.wholePermissions,
                        ["url_redirection"]
                      ) && (
                        <Tab eventKey={4} title={__t("URL Redirection")}>
                          {this.state.key === 4 ? (
                            <UrlRedirection
                              {...this.props}
                              campaign={campaign}
                              update={update_redirect}
                            />
                          ) : (
                            ""
                          )}
                        </Tab>
                      )}
                    </Tabs>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Can>
    );
  }
}

export default CampaignUpdate;
