import React from "react";
import { Link } from "react-router-dom";
import RemotePagination from "../common/Table";
import Utility from "../common/Utility";
import { __t } from "../../locale/language";
import Can from "../common/AccessControl";
import Moment from "moment";
import $, { isArray } from "jquery";
import SubscriptionAlert from "../common/SubscriptionAlert";
import CommonDelete from "../common/CommonDelete";
const set1 = new Set();
var get_email = [];
class CampaignList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      page: 1,
      data: [],
      sizePerPage: 10,
      total: 0,
      search: "",
      client_id: localStorage.getItem("client_id"),
      subscription_feature: this.props.userPermissions.subscription_feature,
      user_type: this.props.userPermissions.user_type,
      customList: "",
      resellerList: "",
      camp_number: "",
      status_type: "",
      select_type: "",
      custom_client_id: "",
      delete_id: "",
    };
    this.updateCampaignList = this.updateCampaignList.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.deleteCampaign = this.deleteCampaign.bind(this);
    this.customClientList = this.customClientList.bind(this);
    this.selectUser = this.selectUser.bind(this);
    this.getUsercampaign = this.getUsercampaign.bind(this);
    this.getUserstatuswise = this.getUserstatuswise.bind(this);
    this.delete_cnferm = this.delete_cnferm.bind(this);
    this.delete = this.delete.bind(this);
    this.getclientdata = this.getclientdata.bind(this);
    this.resellerdata = this.resellerdata.bind(this);
    this.getclientsname = this.getclientsname.bind(this);
  }

  handleChange(event) {
    this.setState({ [event.target.name]: event.target.value });
  }
  generateShortcode(cell, row, rowIndex) {
    return <span>{"[pp_campaign campaign_id=" + row.id + "]"}</span>;
  }

  delete_cnferm(type) {
    //console.log(type);
    //console.log(this.state.delete_id);
    if (type == "yes") {
      this.deleteCampaign(this.state.delete_id);
    } else {
      $("#alertshow").hide();
    }
  }
  delete(id) {
    this.setState(
      {
        delete_id: id,
      },
      () => {
        $("#alertshow").css("display", "block");
      }
    );
  }

  deleteCampaign(id) {
    id = { id: id };
    Utility.ExecuteData("deletecampaign", this.token, id)
      .then((responseJson) => {
        //console.log(responseJson);
        if (responseJson.code === 200) {
          //console.log(responseJson);
          this.updateCampaignList(1, 10);
          // this.setState({ redirect: true });
        } else {
          alert(__t("try_again"));
        }
      })
      .catch((error) => {
        this.setState({ errors: true });
      });
  }
  getUserstatuswise(e) {
    var type = e.target.value;
    // //console.log(type)
    // //console.log(this.state.custom_client_id)
    // //console.log(this.state.select_type)
    // //console.log($('#custom_list').val())
    if (type != "select") {
      this.setState(
        {
          status_type: type,
        },
        () => {
          // //console.log(this.state.select_type)
          // //console.log(this.state.status_type)
          if (
            ($("#custom_list").val() == "" ||
              $("#custom_list").val() == "select") &&
            ($("#clientList").val() == "select" || $("#clientList").val() == "")
          ) {
            //  //console.log("sdvhcdb")
            this.updateCampaignList(1, 10);
          } else if (
            this.state.select_type != "" ||
            this.state.select_type != "select"
          ) {
            ////console.log("sdvhcdb")
            if (
              $("#custom_list").val() != "" &&
              $("#custom_list").val() != "select"
            ) {
              // //console.log(this.state.custom_client_id)
              Utility.ExecuteData("getclientcampaign", this.token, {
                page: this.state.page,
                limit: this.state.sizePerPage,
                search: this.state.search,
                // client_id: this.state.custom_client_id,
                search_id: this.state.custom_client_id,
                status_type: this.state.status_type,
                status_check_type: "client",
              }).then((data) => {
                //console.log(data);
                this.setState({
                  data: data.clientList,
                  total: data.data.length > 0 ? data.count[0].count : 0,
                  status_type: this.state.status_type,
                });
              });
            } else if (
              this.state.status_type != "self" &&
              this.state.status_type != "admin"
            ) {
              Utility.ExecuteData("getusercampaign", this.token, {
                page: this.state.page,
                limit: this.state.sizePerPage,
                search: this.state.search,
                search_id: this.state.custom_client_id,
                status_type: this.state.status_type,
                status_check_type: "client",
              }).then((data) => {
                this.setState(() => ({
                  data: data.clientList,
                  total: data.data.length > 0 ? data.count[0].count : 0,
                  status_type: this.state.status_type,
                }));
              });
            }
          } else {
            //console.log("sdvhcdb");
            this.updateCampaignList(1, 10);
          }
        }
      );
    } else {
      this.setState({
        status_type: "",
      });
    }
  }
  renderButtons(cell, row, rowIndex) {
    //console.log(this.props.userPermissions)
    return (
      <span>
        {this.props.userPermissions.subscription_status == "active" ||
        this.state.user_type == "admin" ||
        this.state.user_type == "reseller" ? (
          <Can
            I={["view-user"]}
            userPermissions={this.props.userPermissions.userPermissions}
          >
            <Link
              to={{
                pathname: `/campaign/update/${row.id}`,
                state: { id: `${row.id}` },
              }}
              style={{ marginRight: "10px" }}
              className="btn btn-primary btn-sm"
            >
              <i className="fa fa-pencil" aria-hidden="true" />
            </Link>
            &nbsp;
            <Link
              className="btn btn-primary btn-sm"
              to={{
                pathname: `../../preview.html?id=${row.id}`,
              }}
              target="blank"
              style={{ marginRight: "10px" }}
            >
              <i className="fa fa-eye" title="preview" aria-hidden="true" />
            </Link>
            {/* &nbsp;
            <Link
              className="btn btn-white"
              to={{
                pathname: `/campaign/analytics/${row.id}`,
                state: { id: `${row.id}` }
              }}
            >
              <i className="fa fa-bar-chart" aria-hidden="true" />
            </Link> */}
          </Can>
        ) : (
          ""
        )}
        <Can
          I={["delete-campaign"]}
          userPermissions={this.props.userPermissions.userPermissions}
        >
          <button
            onClick={() => this.delete(`${row.id}`)}
            className="btn btn-primary btn-sm"
          >
            <i className="fa fa-trash" aria-hidden="true" />
          </button>
        </Can>
      </span>
    );
  }
  renderAnalytics(cell, row, rowIndex) {
    return (
      <span>
        {this.props.userPermissions.subscription_status == "active" ||
        this.state.user_type == "admin" ||
        this.state.user_type == "reseller" ? (
          <Can
            I={["view-campaign"]}
            userPermissions={this.props.userPermissions.userPermissions}
          >
            &nbsp;
            <Link
              className="btn btn-white"
              to={{
                pathname: `/campaign/analytics/${row.id}`,
                state: { id: `${row.id}` },
              }}
            >
              <i className="fa fa-bar-chart" aria-hidden="true" />
              {__t("analytics")}
            </Link>
          </Can>
        ) : (
          ""
        )}
      </span>
    );
  }
  token = localStorage.getItem("token");
  handleTableChange = (type, { page, sizePerPage }) => {
    const currentIndex = (page - 1) * sizePerPage;
    //write fetch data function
    //console.log($("#clientList").val());
    var filter_type = $("#clientList").val();
    if (filter_type == "select" || filter_type == "") {
      this.updateCampaignList(page, sizePerPage);
    } else if (filter_type == "admin" || filter_type == "self") {
      this.customClientList(filter_type, page, sizePerPage);
    } else {
      this.updateCampaignList(page, sizePerPage);

      // Utility.ExecuteData("getusercampaign", this.token, {
      //   page: page,
      //   limit: sizePerPage,
      //   search: this.state.search,
      //   search_id: filter_type
      // }).then(data => {
      //   //console.log(data);
      //   this.setState(() => ({
      //     //data: data.data,
      //     data: data.clientList,
      //     total: data.count[0].count,
      //     customList: data.clientList,
      //     select_type: filter_type,
      //     custom_client_id: filter_type,
      //     page,
      //     sizePerPage
      //     // all_client: val,
      //     // customList: data.data.rows
      //   }));
      // });
    }
  };

  updateCampaignList(page = 1, sizePerPage = 10) {
    $("#alertshow").hide();
    this.setState({
      customList: "",
    });
    //console.log(this.state.status_type);
    Utility.ExecuteData("campaigndata", this.token, {
      page: page,
      limit: sizePerPage,
      search: this.state.search,
      client_id: this.state.client_id,
      status_type: this.state.status_type,
    }).then((data) => {
      // console.log(data.total, "data");
      var total_camp;

      if (this.state.user_type == "client") {
        total_camp = data.camp_number[0].camp_number;
      }
      if (data) {
        if (this.state.search) {
          this.setState(() => ({
            page,
            data: data.data,
            sizePerPage,
            // total: data.total[0].count,
            customList: data.clientList.rows,
            resellerList: data.resellerlist.rows,
            // camp_number: total_camp,
          }));
        } else {
          this.setState(() => ({
            page,
            data: data.data,
            sizePerPage,
            total:
              data.total && isArray(data.total)
                ? data.total[0].count
                : data.total,
            customList: data.clientList.rows,
            resellerList: data.resellerlist.rows,
            camp_number: total_camp,
          }));
        }
      }
      //  //console.log(this.state.customList)
    });
  }
  customClientList(type, page = 1, sizePerPage = 10) {
    Utility.ExecuteData("getcustomclientist", this.token, {
      client_id: this.state.client_id,
      user_type: type,
      page: page,
      limit: sizePerPage,
    }).then((data) => {
      this.setState({
        customList: data.data,
        data: data.camp_data,
        select_type: type,
        total: data.count[0].count,
        page,
        sizePerPage,
      });
    });
  }
  getclientdata(e) {
    var val = e.target.value;
    ////console.log(e.target.value)
    if (val != "select") {
      Utility.ExecuteData("getclientcampaign", this.token, {
        page: this.state.page,
        limit: this.state.sizePerPage,
        search: this.state.search,
        search_id: e.target.value,
        status_type: this.state.status_type,
      }).then((data) => {
        if (data) {
          // //console.log(data);
          this.setState({
            data: data.clientList,
            total: data.count[0].count,
            status_type: this.state.status_type,
            custom_client_id: val,
          });
        }
      });
    } else {
      //   this.resellerdata(1, 10)
    }
  }
  getUsercampaign(e) {
    //console.log(e.target.value);
    var val = e.target.value;
    Utility.ExecuteData("getusercampaign", this.token, {
      page: this.state.page,
      limit: this.state.sizePerPage,
      search: this.state.search,
      client_id: e.target.value,
      status_type: this.state.status_type,
    }).then((data) => {
      if (data) {
        //console.log(data);
        this.setState(
          {
            data: data.data,
            total: data.count[0].count,
            status_type: this.state.status_type,
            custom_client_id: val,
          },
          () => {
            //console.log("sdfghjdfg", this.state.data);
          }
        );
      }
    });
  }
  selectUser(e, page = 1, sizePerPage = 10) {
    var val = e.target.value;
    //console.log(val);
    if (val == "admin" || val == "self") {
      this.customClientList(val, 1, 10);
    } else if (val == "select") {
      $("#custom_list").val("select");
      var select_type = $("#custom_list").val();
      this.setState({
        select_type: select_type,
      });
      this.updateCampaignList(1, 10);
    } else {
      Utility.ExecuteData("getusercampaign", this.token, {
        page: this.state.page,
        limit: this.state.sizePerPage,
        search: this.state.search,
        search_id: e.target.value,
      }).then((data) => {
        //console.log(data);
        this.setState({
          //data: data.data,
          data: data.clientList,
          total: data.count[0].count,
          customList: data.clientList,
          select_type: val,
          custom_client_id: val,
          page,
          sizePerPage,
          // all_client: val,
          // customList: data.data.rows
        });
      });
    }
  }
  resellerdata(page = 1, sizePerPage = 10) {
    //console.log("dvbdfx");
    if (this.state.select_type == "self") {
    }
    Utility.ExecuteData("getusercampaign", this.token, {
      page: this.state.page,
      limit: this.state.sizePerPage,
      search: this.state.search,
      search_id: this.state.client_id,
    }).then((data) => {
      //console.log(data);
      this.setState({
        //data: data.data,
        data: data.clientList,
        total: data.count[0].count,
        customList: data.clientList,
        select_type: this.state.client_id,
        custom_client_id: this.state.client_id,
        // all_client: val,
        // customList: data.data.rows
      });
    });
  }
  componentDidMount() {
    let { sizePerPage } = this.state;
    if (
      this.state.subscription_feature.includes("campaign") &&
      this.state.user_type == "client"
    ) {
      this.updateCampaignList(1, sizePerPage);
    } else {
      $("#subs_text").text("Please upgrade your plan!");
      $("#subs_alert").show();
    }
    if (this.state.user_type == "admin") {
      this.updateCampaignList(1, sizePerPage);
    } else if (this.state.user_type == "reseller") {
      this.resellerdata(1, sizePerPage);
    }
  }

  getclientsname(arr, comp) {
    //  //console.log(comp)
    const unique = arr
      .map((e) => e[comp])

      // store the keys of the unique objects
      .map((e, i, final) => final.indexOf(e) === i && i)

      // eliminate the dead keys & store unique objects
      .filter((e) => arr[e])
      .map((e) => arr[e]);
    // //console.log(unique)
    return unique;
  }

  render() {
    let { data, sizePerPage, page, total } = this.state;
    var remain = "";

    remain = this.state.camp_number - this.state.total;

    const columns = [
      { dataField: "title", text: __t("camp_title") },
      { dataField: "sub_title", text: __t("sub_title") },
      { dataField: "status", text: "Status" },
      { dataField: "client_name", text: __t("client_name") },
      {
        dataField: "updated_at",
        text: __t("updated_at"),
        formatter: (cellContent) =>
          Moment(cellContent).format("DD-MMM-YYYY HH:mm:ss"),
      },
      {
        dataField: "analytics",
        text: __t("analytics"),
        formatter: this.renderAnalytics.bind(this),
      },
      {
        dataField: "action",
        text: __t("action"),
        formatter: this.renderButtons.bind(this),
      },
    ];

    // console.log(this.props.userPermissions.subscription_status ,
    // this.state.subscription_feature.includes("campaign") ,
    // this.state.camp_number, this.state.total);
    return (
      <Can
        I={["view-user"]}
        userPermissions={this.props.userPermissions.userPermissions}
      >
        <CommonDelete delete_cnferm={this.delete_cnferm} />
        {this.state.user_type == "admin" ||
        this.state.user_type == "reseller" ? (
          <div>
            <div className="row wrapper border-bottom white-bg page-heading">
              <div className="col-lg-10">
                <h2 style={{ marginTop: 10 }}>{__t("campaign_list")}</h2>
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to="/">{__t("dashboard")}</Link>
                  </li>
                  <li className="breadcrumb-item">
                    <Link to="/campaign">{__t("campaign")}</Link>
                  </li>
                  <li className="breadcrumb-item active">
                    <strong>{__t("campaign_list")}</strong>
                  </li>
                </ol>
              </div>
            </div>
            <div className="wrapper wrapper-content animated fadeInRight">
              <div className="row">
                <div className="col-lg-12 ">
                  <div className="ibox ">
                    <div className="ibox-title">
                      <div className="row from-control">
                        &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                        {this.state.user_type == "admin" ? (
                          <select
                            className="form-control col-md-2"
                            id="clientList"
                            onChange={this.selectUser}
                            name="all_client"
                            defaultValue={this.state.all_client}
                          >
                            <option value="select">{__t("select")}</option>
                            <option value="self">
                              {__t("self_registered")}
                            </option>
                            <option value="admin">{__t("admin_caps")}</option>
                            {this.state.resellerList
                              ? this.state.resellerList.map((i) => {
                                  if (i.id != "1") {
                                    return (
                                      <option key={i.id} value={i.id}>
                                        {i.name}
                                      </option>
                                    );
                                  }
                                })
                              : ""}
                          </select>
                        ) : (
                          ""
                        )}
                        &nbsp;
                        <select
                          className="form-control col-md-2"
                          id="custom_list"
                          onChange={this.getclientdata}
                          name="custom_client"
                          // defaultValue={this.state.all_client}
                        >
                          <option value="select">{__t("select_client")}</option>

                          {this.state.customList
                            ? this.getclientsname(
                                this.state.customList,
                                "id"
                              ).map((val, m) => {
                                // //console.log(val)
                                return (
                                  <option
                                    key={val.id}
                                    value={
                                      val.client_id ? val.client_id : val.id
                                    }
                                  >
                                    {val.client_name
                                      ? val.client_name
                                      : val.name}
                                  </option>
                                );
                              })
                            : ""}
                        </select>
                        &nbsp;
                        {this.state.user_type == "admin" ? (
                          <select
                            className="form-control col-md-2"
                            id="set_status"
                            onChange={this.getUserstatuswise}
                            name="statuswise"
                          >
                            <option value="select">
                              {__t("select_status")}
                            </option>
                            <option value="active">{__t("active")}</option>
                            <option value="inactive">{__t("inactive")}</option>
                          </select>
                        ) : (
                          ""
                        )}
                        &nbsp;
                        <input
                          className="form-control col-md-3"
                          type="text"
                          name="search"
                          id="search_field"
                          onChange={this.handleChange}
                          placeholder={__t("search_by_title")}
                        />
                        &nbsp;
                        <input
                          className="btn btn-primary"
                          type="button"
                          onClick={() => {
                            if (this.state.user_type == "admin") {
                              this.updateCampaignList();
                            } else if (this.state.user_type == "reseller") {
                              this.resellerdata();
                            }
                          }}
                          name="btn_search"
                          value={__t("search")}
                        />
                        &nbsp;
                        <input
                          className="btn btn-warning"
                          type="button"
                          onClick={() => {
                            this.setState({ search: "" }, () => {
                              if (this.state.user_type == "admin") {
                                this.updateCampaignList();
                              } else if (this.state.user_type == "reseller") {
                                this.resellerdata();
                              }
                              $("#set_status").val("select");
                              $("#custom_list").val("select");
                              $("#clientList").val("select");
                              document.getElementById("search_field").value =
                                "";
                            });
                          }}
                          name="btn_search"
                          value={__t("clear")}
                        />
                        &nbsp;
                        <br />
                        &nbsp; &nbsp;
                        <strong className="mt-2 ml-5">
                          {__t("total_camp")} : {this.state.total}
                        </strong>
                      </div>
                    </div>
                    <div className="ibox-content">
                      {this.state.total > 0 ? (
                        <RemotePagination
                          columns={columns}
                          data={data}
                          page={page}
                          sizePerPage={sizePerPage}
                          totalSize={total}
                          onTableChange={this.handleTableChange}
                        />
                      ) : (
                        <div>{__t("no_record_found")}</div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : this.state.subscription_feature.includes("campaign") &&
          this.state.user_type == "client" ? (
          <div>
            <div className="row wrapper border-bottom white-bg page-heading">
              <div className="col-lg-10">
                <h2 style={{ marginTop: 10 }}>{__t("campaign_list")}</h2>
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to="/">{__t("dashboard")}</Link>
                  </li>
                  <li className="breadcrumb-item">
                    <Link to="/campaign">{__t("campaign")}</Link>
                  </li>

                  <li className="breadcrumb-item active">
                    <strong>{__t("campaign_list")}</strong>
                  </li>
                </ol>
              </div>
            </div>
            <div className="wrapper wrapper-content animated fadeInRight">
              <div className="row">
                <div className="col-lg-12 ">
                  <div className="ibox ">
                    <div className="ibox-title">
                      <div className="row from-control">
                        &nbsp; &nbsp; &nbsp;
                        <input
                          className="form-control col-md-3"
                          type="text"
                          name="search"
                          id="search_field"
                          onChange={this.handleChange}
                          placeholder={__t("search_by_title")}
                        />
                        &nbsp;
                        <input
                          className="btn btn-primary"
                          type="button"
                          onClick={() => {
                            this.updateCampaignList();
                          }}
                          name="btn_search"
                          value={__t("search")}
                        />
                        &nbsp;
                        <input
                          className="btn btn-warning"
                          type="button"
                          onClick={() => {
                            this.setState({ search: "" }, () => {
                              this.updateCampaignList();
                              document.getElementById("search_field").value =
                                "";
                            });
                          }}
                          name="btn_search"
                          value={__t("clear")}
                        />
                        &nbsp;
                        {this.props.userPermissions.subscription_status ==
                          "active" ||
                        this.state.subscription_feature.includes("campaign") &&
                        this.state.camp_number != this.state.total ? (
                          <Can
                            I={["create-campaign"]}
                            userPermissions={
                              this.props.userPermissions.userPermissions
                            }
                          >
                            <Link
                              className="btn btn-primary flex_align_center"
                              to="/campaign/create"
                            >
                              {__t("create")}
                            </Link>
                          </Can>
                        ) : (
                          ""
                        )}
                        <br />
                        <br />
                        <div className="flex_align_center ml-5">
                          <strong>
                            {__t("campaigns_caps")} : {this.state.total} /{" "}
                            {__t("remaining_campaings")}: {remain}
                          </strong>
                        </div>
                      </div>
                    </div>
                    <div className="ibox-content">
                      {this.state.total > 0 ? (
                        <RemotePagination
                          columns={columns}
                          data={data}
                          page={page}
                          sizePerPage={sizePerPage}
                          totalSize={total}
                          onTableChange={this.handleTableChange}
                        />
                      ) : (
                        __t("no_record_found")
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <SubscriptionAlert />
        )}
      </Can>
    );
  }
}

export default CampaignList;
