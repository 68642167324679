import React from "react";
import { __t } from "../../locale/language";
import classnames from "classnames";
import Utility from "../common/Utility";
import $ from "jquery";
import Axios from "axios";

import {
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
  Modal,
  ModalHeader,
  ModalBody,
  Button,
  ModalFooter
} from "reactstrap";
var select_image = "";
const BASE_URL = Utility.baseUrlBe() + "assetscreate";
class SelectImageModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      modal: false,
      activeTab: "1",
      allMedia: [],
      token: localStorage.getItem("token"),
      url: "",
      uploadedImage: "",
      file: "",
      client_id: localStorage.getItem("client_id")
    };
    this.toggletab = this.toggletab.bind(this);
    this.getImage = this.getImage.bind(this);
    this.selectImage = this.selectImage.bind(this);
    this.onFormSubmit = this.onFormSubmit.bind(this);
    this.onChange = this.onChange.bind(this);
  }
  componentWillReceiveProps({ isOpen }) {
    if (isOpen) {
      //api than =>
      var page = 1,
        sizePerPage = 10;
      Utility.ExecuteData("assetsdata", this.state.token, {
        page: page,
        limit: sizePerPage,
        search: "",
        token: this.state.token,
        message: ""
      }).then(result => {
        this.setState({ allMedia: result.data.rows });
      });
    }
    this.setState({ modal: isOpen });
  }
  client_id = localStorage.getItem("client_id");
  token = localStorage.getItem("token");
  selectImage(url, id, size) {
    var type = ""
    //console.log(id);
    //console.log(url)
    if (select_image) {
      $("#" + select_image + " .pos_abs_dark").removeClass("chk_display");
      $("#" + id + " .pos_abs_dark").addClass("chk_display");
      select_image = id;
    } else {
      $("#" + id + " .pos_abs_dark").addClass("chk_display");
      select_image = id;
    }
   
   
    let promise = new Promise(function (resolve, reject) {
      var img = new Image();
      img.src = url;
      img.onload = function () {
       // let imsize = Utility.imageSize(size)
         if (this.width > 1280 && this.height > 720 && size> 5253158) {
          //if (this.width > 100 && this.height > 10) {
          $("#msg_text").text(__t("size_should_below_5mb"));
          $("#alertinfo").show();
          type = "false"
          resolve( img.src);
        }
        else {
          type = "true"
          resolve( img.src);
        }
      };
    });
    promise.
    then(() => {
     
      if(type =="true"){
        this.setState({ url:  url }, () => {
          //console.log(url)
          $("#applybtn").css("display", "block");
        });
      }
     })
  
  }

  getImage = (file, id) => {
    // //console.log(file, id);
    var file_typ = file.file_type;
    var check_image = file_typ.substring(0, 5);
    // //console.log(file_typ.substring(0, 5))
    var file_ext = file.ext;
    var found;
    var file_size = file.size
    var defaultImage;
    let default_url = "/img/";
    if (check_image == "image") {
      if (file.url_deleted == null || file.url_deleted == "") {
        defaultImage = file.url;
      } else {
        defaultImage = file.url_deleted;
      }
    } else if (check_image == "video") {
      defaultImage = "video image url";
    } else {
      var obj = {
        pdf: "pdf",
        csv: "csv",
        doc: "doc",
        xls: "xls",
        file: "file"
      };

      found = Object.keys(obj).filter(function (key) {
        return obj[key] === file_ext;
      });
      if (found != "") {
        defaultImage = default_url + "default_" + found + "." + "png";
        //console.log(found);
        //console.log(defaultImage);
      } else {
        defaultImage = "default image url";
      }
    }
    return (
      <div className="pos_rel" id={id}>
        <img
          className="assets_img"
          onClick={() => {
            this.selectImage(defaultImage, id, file_size);
          }}
          src={defaultImage}
          alt="alt"
        />
        <div className="pos_abs_dark">
          <i className="fa fa-check-circle txt_white darkcheck" />
        </div>
      </div>
    );
  };
  onChange(e) {
    var type = ""
    var _URL = window.URL || window.webkitURL;
    var file, img;
    if ((file = e.target.files[0])) {
      img = new Image();
      img.src = _URL.createObjectURL(file);
      let promise = new Promise(function (resolve, reject) {
        img.onload = function () {

         // let imsize = Utility.imageSize(file.size)
          //console.log(this.width, this.height)
          if (this.width > 1280 && this.height > 720 && file.size > 5253158) {
            // if (this.width > 100 && this.height > 100) {
            $("#msg_text").text(__t("size_should_below_5mb"));
            $("#alertinfo").show();
            type = "false"
            resolve(file);
          }
          else {
            type = "true"
            resolve(file);
          }
        };

        // immediately give the result: 123
      });
      promise.
        then(() => {

          if (type == "true") {
            this.setState({ file: file });
          }
        })
    }


    //this.setState({ file: e.target.files[0] });
  }

  onFormSubmit(e) {
    //console.log(this.state.file);
    e.preventDefault();
    if (this.state.file) {
      //console.log("helloooo");
      const formData = new FormData();
      formData.append("file", this.state.file);
      formData.append("client_id", this.state.client_id);
      formData.append("token", this.state.token);

      const config = {
        headers: {
          "content-type": "multipart/form-data",
          "Content-Type": "application/json",
          authorization: "Bearer " + this.state.token
        }
      };
      $("#upload-loader").css("display", "block");
      Axios.post(BASE_URL, formData, config)
        .then(response => {
          this.setState(
            {
              message: __t("file_is_successfully_uploaded"),
              uploadedImage: response.data.url,
              url: response.data.url
            },
            () => {
              $("#upload-loader").css("display", "none");
              $("#applybtn").css("display", "block");
            }
          );
          $("#msg_text").text(__t("file_is_successfully_uploaded"));
          $("#alertinfo").show();
        })
        .catch(error => {
          $("#upload-loader").css("display", "none");
        });
      document.getElementById("imagefile").value = "";
      this.state.file = "";
    } else {
      $("#msg_text").text(__t("please_select_a_file"));
      $("#alertinfo").show();
    }
  }
  token = localStorage.getItem("token");
  toggletab(tab) {
    $("#applybtn").css("display", "none");
    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab
      });
    }
  }
  render() {
    return (
      <Modal
        key={3}
        isOpen={this.state.modal}
        toggle={() => this.props.mediaToggle()}
      >
        <ModalHeader>Select Image</ModalHeader>
        <ModalBody className="media-modal">
          <Nav tabs>
            <NavItem>
              <NavLink
                className={classnames({
                  active: this.state.activeTab === "1"
                })}
                onClick={() => {
                  this.toggletab("1");
                }}
              >
                Select Image
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                className={classnames({
                  active: this.state.activeTab === "2"
                })}
                onClick={() => {
                  this.toggletab("2");
                }}
              >
                {__t("upload_image")}
              </NavLink>
            </NavItem>
          </Nav>
          <TabContent activeTab={this.state.activeTab}>
            <TabPane tabId="1">
              <div className="form-group pos_rel" />

              <div className="flex-container flx_align_center">
                {this.state.allMedia
                  ? this.state.allMedia.map((value, k) => {
                    // //console.log(value);
                    var ext = value.name.split(".").pop();
                    // ext_type = ext;
                    if (
                      ext == "jpg" ||
                      ext == "png" ||
                      ext == "PNG" ||
                      ext == "JPG" ||
                      ext == "JPEG" ||
                      ext == "jpeg"
                    ) {
                      return (
                        <div key={k}>
                          <div className="pos_rel pos_relhover" id={k}>
                            <div>{this.getImage(value, k + 1)}</div>
                          </div>
                        </div>
                      );
                    }
                  })
                  : ""}
              </div>
            </TabPane>
          </TabContent>
          <TabContent activeTab={this.state.activeTab}>
            <TabPane tabId="2">
              <form onSubmit={this.onFormSubmit} className="p-2">
                <div className="file_input_wrap">
                  <div className="box">
                    <input
                      type="file"
                      name="myImage"
                      id="imagefile"
                      onChange={this.onChange}
                      className="line_h30 inputfile inputfile-2"
                    />
                    <label htmlFor="imagefile">
                      <i
                        className="fa fa-upload upload_icon"
                        aria-hidden="true"
                      />
                      <span className="inputfile_span">
                        {this.state.file
                          ? this.state.file.name
                          : __t("choose_a_file")}
                      </span>
                    </label>
                  </div>
                  <button type="submit" className="btn btn-primary mx-2 upload">
                    {__t("upload")}
                  </button>
                  <div
                    className="first_loader loader_full "
                    id="upload-loader"
                    style={{ display: "none" }}
                  >
                    <div className="lds-ring">
                      <div />
                      <div />
                      <div />
                      <div />
                    </div>
                  </div>
                </div>
              </form>
              {this.state.uploadedImage ? (
                <img
                  className="media_uploaded_img"
                  src={this.state.uploadedImage}
                />
              ) : (
                  ""
                )}
            </TabPane>
          </TabContent>
        </ModalBody>
        <ModalFooter>
          <Button
            id="applybtn"
            color="primary"
            style={{ display: "none" }}
            onClick={() => {
              this.props.mediaToggle(this.props.setImageUrl(this.state.url));
            }}
          >
            {__t("apply")}
          </Button>{" "}
          <Button
            color="secondary"
            onClick={() => {
              this.props.mediaToggle();
            }}
          >
            {__t("cancel")}
          </Button>
        </ModalFooter>
      </Modal>
    );
  }
}
export default SelectImageModal;
