import React, { Component } from "react";
import StripeCheckout from "react-stripe-checkout";
import Utility from "../common/Utility";
import { __t } from "../../locale/language";

class StripeButton extends Component {
  constructor(props) {
    super(props);
    this.state = {
      publishableKey: props.publishableKey,
      amount: typeof props.amount != "undefined" ? props.amount : 0,
      subsid: props.subsid,
      client_id: props.client_id,
      local_token: props.token,
      showButton: true,
      hostname: ''
    };
  }
  componentWillMount() {
    Utility.ExecuteData("checkStatusPaypalButton")
      .then(data => {
        //console.log(data.status[0])
        if (data.code == 204 || data.status[0].status_stripe == "inactive") {
          this.setState({
            showButton: false
          })
        }
      })
  }

  componentDidMount() {
    if (window.location.hostname) {
      if (window.location.hostname.indexOf("pushpolitics.org") !== -1 || window.location.hostname.indexOf("csaeconnect.ca") !== -1) {
        let hostname = window.location.hostname;
        if (hostname.indexOf('admin') !== -1) {
          hostname = hostname.split("admin").join("www");
        }
        this.setState({ hostname })
      }
    }
  }

  componentWillReceiveProps(props) {
    // //console.log(this.props.publishableKey)
    this.setState({
      amount: props.amount,
      subsid: props.subsid,
      client_id: props.client_id,
      local_token: props.token,
      publishableKey: props.publishableKey
    });
  }
  onToken = token => {

    Utility.ExecuteData("stripe-payment", this.state.local_token, {
      subsid: this.state.subsid,
      amount: this.state.amount,
      token: token,
      type: "renew",
      client_id: this.state.client_id,
      local_token: this.state.local_token
    })
      .then(responseJson => {
        //console.log(responseJson);
        if (responseJson.status == "1") {
          this.props.stripe_reload();
        }
        //alert("Payment Success");

      })
      .catch(err => {
        //console.log("Payment Error: ", err);
        alert("Payment Error");
      });
  };

  render() {
    var publishableKey;
    if (this.state.publishableKey != "undefined") {
      publishableKey = this.state.publishableKey
    }

    if (this.state.amount > 0 && this.state.showButton) {
      let { tax_data } = this.props;
      let amount = this.state.amount;
      let tax_amt = 0;
      if (tax_data && tax_data.tax_rate) {
        tax_amt = amount * tax_data.tax_rate / 100;
      }
      amount = amount + tax_amt;

      return (
        <StripeCheckout
          label={__t("pay_with_stripe")} //Component button text
          name={this.state.hostname} //Modal Header
          description="Upgrade to a premium account today."
          panelLabel={__t("pay")} //Submit button in modal
          amount={Math.round(amount * 100)}
          currency={'CAD'}
          token={this.onToken}
          stripeKey={publishableKey}
          image="" //Pop-in header image
        //billingAddress={true}
        />
      );
    } else {
      return "";
    }
  }
}
export default StripeButton;
