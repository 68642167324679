import React from "react";
import { Link } from "react-router-dom";
import RemotePagination from "../common/Table";
import Utility from "../common/Utility";
import { __t } from "../../locale/language";
import Can from "../common/AccessControl";
import Moment from "moment";
import $ from "jquery";
import CommonDelete from "../common/CommonDelete";

class ClientList extends React.Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      page: 1,
      data: [],
      sizePerPage: 10,
      total: 0,
      search: "",
      today: new Date(),
      user_type: Utility.getIdFromToken(localStorage.getItem("token")),
      client_id: localStorage.getItem("client_id"),
      delete_id: ""
    };
    this.deleteClient = this.deleteClient.bind(this);
    this.updateClientList = this.updateClientList.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.delete_cnferm = this.delete_cnferm.bind(this);
    this.delete = this.delete.bind(this);
  }
  delete_cnferm(type) {
    ////console.log(type)
    ////console.log(this.state.delete_id)
    if (type == "yes") {
      this.deleteClient(this.state.delete_id);
    } else {
      $("#alertshow").hide();
    }
  }
  delete(id) {
    this.setState(
      {
        delete_id: id
      },
      () => {
        $("#alertshow").css("display", "block");
      }
    );
  }
  handleChange(event) {
    this.setState({ [event.target.name]: event.target.value });
  }

  renderButtons(cell, row, rowIndex) {
    return (
      <span>
        <Can
          I={["update-client"]}
          userPermissions={this.props.userPermissions.userPermissions}
        >
          <Link
            to={{
              pathname: `/client/update/${row.id}`,
              state: { id: `${row.id}` }
            }}
            className="btn btn-primary btn-sm"
          >
            <i className="fa fa-pencil" aria-hidden="true" />
            {__t("edit")}
          </Link>
        </Can>
        &nbsp;
        <Can
          I={["client-delete"]}
          userPermissions={this.props.userPermissions.userPermissions}
        >
          <button
            onClick={() => this.delete(`${row.id}`)}
            className="btn btn-primary btn-sm"
          >
            <i className="fa fa-trash" aria-hidden="true" />
          </button>
        </Can>
      </span>
    );
  }
  token = localStorage.getItem("token");
  handleTableChange = (type, { page, sizePerPage }) => {
    const currentIndex = (page - 1) * sizePerPage;

    this.updateClientList(page, sizePerPage);
  };
  deleteClient(id) {
    id = { id: id };
    Utility.ExecuteData("deleteclient", this.token, id)
      .then(responseJson => {
        if (responseJson.code === 200) {
          this.updateClientList(1, 10);
        } else {
          alert(__t("try_again"));
        }
      })
      .catch(error => {
        this.setState({ errors: true });
      });
  }

  updateClientList(page = 1, sizePerPage = 10) {
    $("#alertshow").hide();
    Utility.ExecuteData("clientdata", this.token, {
      page: page,
      limit: sizePerPage,
      search: this.state.search,
      user_type: this.state.user_type,
      client_id: this.state.client_id
    }).then(res => {
      this.setState({
        page,
        data: res.client_list,
        sizePerPage,
        total: res.count
      }, () => {
        console.log('data===', this.state.data);

      });
    });
  }
  componentDidMount() {
    this.updateClientList(1, 10);
  }
  getDuration(end_date) {
    var now = Moment(new Date()); //todays date
    var end = Moment(end_date); // another date
    var duration = Moment.duration(end.diff(now));

    var days = parseInt(duration.asDays());
    var hour = parseInt(duration.asHours());
    hour = hour - days * 24
    if (days < 0) {
      return 0;
    }
    return days ? days + "D " + hour + "H" : 0 + "D " + hour + "H";
  }
  render() {
    const { data, sizePerPage, page, total } = this.state;
    // console.log(data);
    const columns = [
      { dataField: "name", text: "Name" },
      { dataField: "title", text: "Title" },
      {
        dataField: "reseller_name",
        text: __t("reseller"),
        formatter: function (cell, row, rowIndex) {
          if (row.reseller_id == null) {
            return __t("From Website");
          } else {
            return row.reseller_name;
          }
        }
      },
      { dataField: "sub_name", text: "Plan" },
      { dataField: "sub_status", text: "Plan Status" },
      {
        dataField: "end_date",
        text: "Remaining Days, Hours",
        formatter: this.getDuration.bind(this)
      },

      { dataField: "status", text: "Status" },
      // {
      //   dataField: "created_at",
      //   text: __t("created_at"),
      //   formatter: cellContent =>
      //     Moment(cellContent).format("DD-MMM-YYYY HH:mm:ss")
      // },
      {
        dataField: "updated_at",
        text: __t("updated_at"),
        formatter: cellContent =>
          Moment(cellContent).format("DD-MMM-YYYY HH:mm:ss")
      },
      {
        dataField: "action",
        text: "Action",
        formatter: this.renderButtons.bind(this)
      }
    ];
    return (
      <Can
        I={["view-client"]}
        userPermissions={this.props.userPermissions.userPermissions}
      >
        <CommonDelete delete_cnferm={this.delete_cnferm} />
        <div className="row wrapper border-bottom white-bg page-heading">
          <div className="col-lg-10">
            <h2 style={{ marginTop: 10 }}>{__t("client_list")}</h2>
            <ol className="breadcrumb">
              <li className="breadcrumb-item">
                <Link to="/">{__t("dashboard")}</Link>
              </li>
              <li className="breadcrumb-item">
                <Link to="/client">{__t("clients")}</Link>
              </li>
              <li className="breadcrumb-item active">
                <strong>{__t("client_list")}</strong>
              </li>
            </ol>
          </div>
        </div>
        <div className="wrapper wrapper-content animated fadeInRight">
          <div className="row">
            <div className="col-lg-12 ">
              <div className="ibox ">

                <div className="ibox-title">
                  <div className="row from-control">
                    &nbsp; &nbsp; &nbsp;
                    <input
                      className="form-control col-md-3"
                      type="text"
                      name="search"
                      id="search_field"
                      onChange={this.handleChange}
                      placeholder={__t("search_by_name_title")}
                    />
                    &nbsp;
                    <input
                      className="btn btn-primary"
                      type="button"
                      onClick={() => {
                        this.updateClientList();
                      }}
                      name="btn_search"
                      value="Search"
                    />
                    &nbsp;
                    <input
                      className="btn btn-warning"
                      type="button"
                      onClick={() => {
                        this.setState({ search: "" }, () => {
                          this.updateClientList();
                          document.getElementById("search_field").value = "";
                        });
                      }}
                      name="btn_search"
                      value="Clear"
                    />
                    &nbsp;
                    <Can
                      I={["create-client"]}
                      userPermissions={
                        this.props.userPermissions.userPermissions
                      }
                    >
                      <Link className="btn btn-primary" to="/client/update">
                        {__t("create_client")}
                      </Link>
                    </Can>
                    <br />
                    &nbsp; &nbsp;
                    <strong style={{ marginTop: "10px" }}>
                      {__t("total_clients")} : {this.state.total}
                    </strong>
                  </div>
                </div>

                <div className="ibox-content">
                  {this.state.total > 0 ? (
                    <RemotePagination
                      columns={columns}
                      data={data}
                      page={page}
                      sizePerPage={sizePerPage}
                      totalSize={total}
                      onTableChange={this.handleTableChange}
                    />
                  ) : (
                      __t("no_record_found")
                    )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </Can>
    );
  }
}

export default ClientList;
