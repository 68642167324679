import React from "react";
import { Redirect, Link } from "react-router-dom";
import $ from "jquery";
import Utility from "../common/Utility";
import { __language, __t } from "../../locale/language";
class ForgetPassword extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      errors: {}
    };
    this.login = this.login.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleValidation = this.handleValidation.bind(this);
  }
  login() {
    //console.log(this.state.email);
    if (this.handleValidation()) {
      //console.log(this.state.email);
      // Utility.ExecuteData("insertPasswordToken", this.token, {
      //   email: this.state.email
      // })
      var domain = "";
      var url = window.location.href;
      url = url.split('/')
      var subDomain = url[2]
      //console.log(subDomain)
      
      fetch(
        Utility.baseUrlBe() +
          "insertPasswordToken?templatenme=forgotpassword&email=" +
          this.state.email+"&domain="+subDomain,
        {
          method: "GET",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json"
          }
        }
      )
        .then(response => response.json())
        .then(responseJson => {
          //console.log(responseJson);
          if (responseJson.code === 200) {
            this.handleValidation(this.setState({ errors: "password sent" }));
            this.state.email = "";
            $("#email").val("");
          } else if (responseJson.code === 204) {
            this.handleValidation(this.setState({ errors: "not found" }));
          } else {
            $("#msg_text").text("Something went wront. Try Again.");
            $("#alertinfo").show();
          }
        })
        .catch(error => {
          alert("internal server error");
        });
    }
  }
  handleValidation() {
    let fields = this.state;
    let errors = {};
    let formIsValid = true;

    if (fields.errors === "password sent") {
      formIsValid = false;
      errors["password_sent"] = __t("password_sent_to_mail");
    }
    if (fields.errors === "not found") {
      //console.log("dcvds");
      formIsValid = false;
      errors["password_sent"] = __t("email_not_found");
    }

    if (typeof fields["email"] !== "undefined") {
      let lastAtPos = fields["email"].lastIndexOf("@");
      let lastDotPos = fields["email"].lastIndexOf(".");

      if (
        !(
          lastAtPos < lastDotPos &&
          lastAtPos > 0 &&
          fields["email"].indexOf("@@") == -1 &&
          lastDotPos > 2 &&
          fields["email"].length - lastDotPos > 2
        )
      ) {
        formIsValid = false;
        errors["email_login"] = __t("chk_valid_email");
      }
    }
    if (!fields["email"]) {
      formIsValid = false;
      errors["email_login"] = __t("chk_empty_email");
    }

    this.setState({ errors: errors });
    return formIsValid;
  }
  handleChange(event) {
    this.setState({ [event.target.name]: event.target.value });
  }
  render() {
    return (
      <div className="middle-box text-center loginscreen animated fadeInDown">
        <div>
          <div>
            <h1 className="logo-name">PP</h1>
          </div>
          <h3>Welcome to Push Politics</h3>

          <p>Type registered email for getting password reset link.</p>

          <div className="form-group">
            <input
              id="email"
              type="email"
              name="email"
              className="form-control"
              placeholder="Email"
              required=""
              onChange={this.handleChange}
            />
          </div>
          <span style={{ color: "red" }}>
            {this.state.errors["password_sent"]}
          </span>
          <span style={{ color: "red" }}>
            {this.state.errors["email_login"]}
          </span>
          <button
            type="submit"
            className="btn btn-primary block full-width m-b"
            onClick={this.login}
          >
            Submit
          </button>

          <Link to="/login">
            <small>Login</small>
          </Link>

          <p className="m-t">
            {" "}
            <small>Designed & developed by Opalina</small>{" "}
          </p>
        </div>
      </div>
    );
  }

  componentDidMount() {
    $("body").addClass("gray-bg");
  }

  componentWillUnmount() {
    $("body").removeClass("gray-bg");
  }
}

export default ForgetPassword;
