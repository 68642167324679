import React from "react";

class Footer extends React.Component {

  render() {
    var host = window.location.hostname;
    const content = {
      "admin.pushpolitics.org": {
        title: "Push Politics Ca",
        logo: "https://www.pushpolitics.org/Assets/images/pp33.png",
        description: ""
      },
      "admin.csaeconnect.ca": {
        title: "CSAE",
        logo: "https://www.csaeconnect.ca/Assets/images/output-onlinepngtools.png",
        description: ""
      },
      "localhost": {
        title: "Push Politics Ca",
        logo: "https://www.pushpolitics.org/Assets/images/pp33.png",
        description: ""
      },
    };
    return (
      <div className="footer">
        <div className="pull-right">
          <strong>Copyright</strong> {content[host].title} &copy; 2018
        </div>
        <div />
      </div>
    );
  }
}

export default Footer;
