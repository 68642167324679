import React, { Component } from "react";
import { Tabs, Tab } from "react-bootstrap";
import { Link } from "react-router-dom";
import { __t } from "../../locale/language";
class Category extends Component {
  constructor(props) {
    super(props);
  }
  render() {
    return (
      <div>
        <div className="row wrapper border-bottom white-bg page-heading">
          <div className="col-lg-10">
            <h2 style={{ marginTop: 10 }}>{__t("assets_list")}</h2>

            <ol className="breadcrumb">
              <li className="breadcrumb-item">
                <Link to="/">{__t("dashboard")}</Link>
              </li>
              <li className="breadcrumb-item">
                <Link to="/assets">{__t("assets")}</Link>
              </li>
            </ol>
          </div>
        </div>
        <div className="flex_center mt-3">
          {" "}
          <label className="lbl_cat">{__t("please_select_category")}</label>
        </div>

        <div className=" doc_category_wrap ">
          {/* <div
            className="pos_rel pos_relhover doc_category doc_category_doc"
            onClick={() => {
              this.props.getDocumnetData("application");
            }}
          >
            <label className="doc_cat_label">{__t("document")}</label>
          </div> */}
          <div className="flx_space_between  pos_abs_btns_img" />
          <div className="pos_rel pos_relhover doc_category doc_category_pic">
            <label
              className="doc_cat_label"
              onClick={() => {
                this.props.getDocumnetData("image/png, image/gif, image/jpeg");
                // this.props.getDocumnetData("image/*");
              }}
            >
              {__t("images")}
            </label>
          </div>
          <div className="flx_space_between  pos_abs_btns_img" />
          {/* <div
            className="pos_rel pos_relhover doc_category doc_category_vid"
            onClick={() => {
              this.props.getDocumnetData("video");
            }}
          >
            <label className="doc_cat_label">{__t("videos")}</label>
          </div> */}
          <div className="flx_space_between  pos_abs_btns_img" />
        </div>
      </div>
    );
  }
}

export default Category;
