import React from "react";
import { Link } from "react-router-dom";
import Utility from "../common/Utility";
import { __t } from "../../locale/language";
import Can from "../common/AccessControl";
import Moment from "moment";
import $, { isArray } from "jquery";
import { Redirect } from "react-router-dom";
class ContactDataSet extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      scenario: props.match.params.id ? "update" : "create",
      riding_lists: [],
      contact_lists: [],
      rel_riding_fields: [],
      rel_contact_fields: [],
      redirect: false,
      data_set: {},
      id: props.match.params.id ? props.match.params.id : "",
      errors: {},
    };
    this.title =
      this.riding_list =
      this.contact_list =
      this.rel_riding_field =
      this.rel_contact_field =
      this.status =
        "";
  }
  componentWillMount() {
    if (typeof this.props.match.params.id != "undefined") {
      this.getDataSet(this.props.match.params.id);
    } else {
      this.getDataTables();
    }
  }
  componentDidMount() {
    if (typeof this.props.match.params.id != "undefined") {
      $("#title2").text(__t("edit_data_set"));
      $("#title").text(__t("edit_data_set"));
    }
  }

  handleChange = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };
  getDataSet = async (id) => {
    let res = await Utility.executeRequest("get-data-set", { id: id });

    if (res.code == 200) {
      let data_set = res.data.rows[0];
      let tables = await Utility.executeRequest("get-data-tables", {});
      let rel_contact_fields = await Utility.executeRequest(
        "get-data-columns",
        { table: data_set.contact_list }
      );
      let rel_riding_fields = await Utility.executeRequest("get-data-columns", {
        table: data_set.riding_list,
      });
      this.setState({
        data_set,
        title: data_set.title,
        status: data_set.status,
        rel_contact_fields: rel_contact_fields.data,
        rel_riding_fields: rel_riding_fields.data,
        riding_lists: tables.tables.riding_table,
        contact_lists: tables.tables.contact_lists,
      });
    } else {
    }
  };
  getDataTables = async () => {
    let res = await Utility.executeRequest("get-data-tables", {});
    if (res.code == 200) {
      this.setState({
        riding_lists: res.tables.riding_table,
        contact_lists: res.tables.contact_lists,
      });
    }
  };
  getTableColumns = async (type, table) => {
    let res = await Utility.executeRequest("get-data-columns", {
      table: table,
    });
    if (res.code == 200) {
      this.setState({ [type]: res.data });
    } else {
      this.setState({ [type]: [] });
    }
  };
  tableSelectHandle = (event, type) => {
    this.getTableColumns(type, event.target.value);
  };
  //   update = () => {
  //     this.saveDataSet();
  //   };

  checkValidation = () => {
    let fields = this.state;
    let errors = {};
    let formIsValid = true;
    //return false
    //console.log(fields)
    if (!fields["title"]) {
      formIsValid = false;
      errors["title"] = __t("title_cannot_be_empty");
    }
    if (!fields["status"]) {
      formIsValid = false;
      errors["status"] = __t("please_select_a_status");
    }
    if ($("#rid_list").val() == "") {
      formIsValid = false;
      errors["riding_list"] = __t("riding_list_cannot_be_empty");
    }
    if ($("#rel_riding").val() == "") {
      formIsValid = false;
      errors["rel_riding_field"] = __t("rel_riding_field_cannot_be_empty");
    }
    if ($("#cont_list").val() == "") {
      formIsValid = false;
      errors["contact_list"] = __t("contact_list_cannot_be_empty");
    }
    if ($("#rel_contact").val() == "") {
      formIsValid = false;
      errors["rel_contact_field"] = __t("rel_contact_field_cannot_be_empty");
    }
    this.setState({ errors: errors });
    return formIsValid;
  };

  handleChange = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };

  saveDataSet = async () => {
    if (this.checkValidation()) {
      let data_set = {
        title: this.title.value,
        riding_list: this.riding_list.value,
        rel_riding_field: this.rel_riding_field.value,
        rel_contact_field: this.rel_contact_field.value,
        contact_list: this.contact_list.value,
        status: this.status.value,
        id: this.state.id,
      };
      let validate = true;
      if (validate) {
        let res = await Utility.executeRequest("update-data-set", {
          id: this.state.id,
          data_set: data_set,
        });
        if (typeof res.code != "undefined") {
          this.setState({ redirect: true });
        }
      }
    }
  };
  render() {
    let {
      riding_lists,
      rel_riding_fields,
      rel_contact_fields,
      contact_lists,
      redirect,
      data_set,
    } = this.state;
    if (redirect) {
      return <Redirect push to="/contacts/" />;
    }
    //console.log(data_set);
    return (
      <React.Fragment>
        <div className="row wrapper border-bottom white-bg page-heading">
          <div className="col-lg-10">
            <h2 style={{ marginTop: 10 }}>{__t("contact_data_set")}</h2>
            <ol className="breadcrumb">
              <li className="breadcrumb-item">
                <Link to="/">{__t("dashboard")}</Link>
              </li>
              <li className="breadcrumb-item">
                <Link to="/contacts">{__t("contact_data_set")}</Link>
              </li>
              <li className="breadcrumb-item active">
                <strong id="title">{__t("create_data_set")}</strong>
              </li>
            </ol>
          </div>
        </div>
        <div className="wrapper wrapper-content animated fadeInRight">
          <div className="ibox-content my-2">
            <div className="ibox-title">
              <h5 id="title2">{__t("create_data_set")}</h5>
            </div>
            <div className="form-horizontal col-md-12 col-sm-12 col-xs-12">
              <div className="row">
                <div className="form-group col-md-6 col-sm-6 col-xs-12">
                  <label className="control-label required-field">
                    {" "}
                    {__t("title")}
                  </label>
                  <input
                    type="text"
                    name="title"
                    className="form-control"
                    placeholder={__t("title")}
                    ref={(title) => {
                      this.title = title;
                    }}
                    onChange={this.handleChange}
                    defaultValue={data_set.title}
                  />
                  <label id="-error" className="error">
                    {this.state.errors["title"]}
                  </label>
                </div>
                <div className="form-group col-md-6 col-sm-6 col-xs-12">
                  <label className="control-label required-field">
                    {" "}
                    {__t("status")}
                  </label>
                  <select
                    name="status"
                    className="form-control"
                    ref={(status) => {
                      this.status = status;
                    }}
                    onChange={this.handleChange}
                  >
                    <option value="">{__t("select_status")}</option>
                    <option
                      value="active"
                      selected={data_set.status == "active" ? "selected" : ""}
                    >
                      {__t("active")}
                    </option>
                    <option
                      value="inactive"
                      selected={data_set.status == "inactive" ? "selected" : ""}
                    >
                      {__t("inactive")}
                    </option>
                  </select>
                  <label id="-error" className="error">
                    {this.state.errors["status"]}
                  </label>
                </div>
                <div className="form-group col-md-6 col-sm-6 col-xs-12">
                  <label className="control-label required-field">
                    {" "}
                    {__t("riding_lists")}
                  </label>
                  <select
                    name="riding_list"
                    className="form-control"
                    ref={(riding_list) => {
                      this.riding_list = riding_list;
                    }}
                    onChange={(e) =>
                      this.tableSelectHandle(e, "rel_riding_fields")
                    }
                    id="rid_list"
                  >
                    <option value="">{__t("select_riding_list")}</option>
                    {riding_lists.map((v) => {
                      return (
                        <option
                          key={v.table_name}
                          value={v.table_name}
                          selected={
                            data_set.riding_list == v.table_name
                              ? "selected"
                              : ""
                          }
                        >
                          {v.table_name}
                        </option>
                      );
                    })}
                  </select>
                  <label id="-error" className="error">
                    {this.state.errors["riding_list"]}
                  </label>
                </div>
                <div className="form-group col-md-6 col-sm-6 col-xs-12">
                  <label className="control-label required-field">
                    {" "}
                    {__t("rel_riding_field")}
                  </label>
                  <select
                    name="rel_riding_field"
                    className="form-control"
                    ref={(rel_riding_field) => {
                      this.rel_riding_field = rel_riding_field;
                    }}
                    id="rel_riding"
                  >
                    <option value="">{__t("select_rel_riding_field")}</option>
                    {rel_riding_fields &&
                      isArray(rel_riding_fields) &&
                      rel_riding_fields.map((v) => {
                        return (
                          <option
                            key={v.COLUMN_NAME}
                            value={v.COLUMN_NAME}
                            selected={
                              data_set.rel_riding_field == v.COLUMN_NAME
                                ? "selected"
                                : ""
                            }
                          >
                            {v.COLUMN_NAME}
                          </option>
                        );
                      })}
                  </select>
                  <label id="-error" className="error">
                    {this.state.errors["rel_riding_field"]}
                  </label>
                </div>
                <div className="form-group col-md-6 col-sm-6 col-xs-12">
                  <label className="control-label required-field">
                    {" "}
                    {__t("contact_list")}
                  </label>
                  <select
                    name="contact_list"
                    className="form-control"
                    ref={(contact_list) => {
                      this.contact_list = contact_list;
                    }}
                    onChange={(e) =>
                      this.tableSelectHandle(e, "rel_contact_fields")
                    }
                    id="cont_list"
                  >
                    <option value="">{__t("select_contact_list")}</option>
                    {contact_lists.map((v) => {
                      return (
                        <option
                          key={v.table_name}
                          value={v.table_name}
                          selected={
                            data_set.contact_list == v.table_name
                              ? "selected"
                              : ""
                          }
                        >
                          {v.table_name}
                        </option>
                      );
                    })}
                  </select>
                  <label id="-error" className="error">
                    {this.state.errors["contact_list"]}
                  </label>
                </div>
                <div className="form-group col-md-6 col-sm-6 col-xs-12">
                  <label className="control-label required-field">
                    {" "}
                    {__t("rel_contact_lists")}
                  </label>
                  <select
                    name="rel_contact_field"
                    className="form-control"
                    ref={(rel_contact_field) => {
                      this.rel_contact_field = rel_contact_field;
                    }}
                    id="rel_contact"
                  >
                    <option value="">{__t("select_rel_contact_field")}</option>
                    {rel_contact_fields &&
                      isArray(rel_contact_fields) &&
                      rel_contact_fields.map((v) => {
                        return (
                          <option
                            key={v.COLUMN_NAME}
                            value={v.COLUMN_NAME}
                            selected={
                              data_set.rel_contact_field == v.COLUMN_NAME
                                ? "selected"
                                : ""
                            }
                          >
                            {v.COLUMN_NAME}
                          </option>
                        );
                      })}
                  </select>
                  <label id="-error" className="error">
                    {this.state.errors["rel_contact_field"]}
                  </label>
                </div>

                <div className="form-group col-md-6 col-sm-6 col-xs-12">
                  <button
                    className="btn btn-sm btn-primary"
                    onClick={() => this.saveDataSet()}
                  >
                    {__t(this.state.scenario)}
                  </button>
                  <Link
                    to="/contacts"
                    className="btn btn-sm btn-primary"
                    style={{ marginLeft: "8px" }}
                  >
                    {__t("cancel")}
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default ContactDataSet;
