import React from "react";
import $ from "jquery";
import ReactQuill from "react-quill";
import { Tabs, Tab } from "react-bootstrap";

import { __t } from "../../locale/language";
import Utility from "../common/Utility";
import AdressComponents from "../common/AdressComponent";
import { filterEmbed } from "../widgetseditor/utils";
import {
  LetterVariationsArr,
  LtrVariationDefaultMsg,
  LtrVariationDefaultSubject,
} from "./provinceArr";
import { bytesToSize, fileFullBasePath } from "../Helper";

const firstKey = "Provinces / Territories";

class NewsLetterAutoresponder extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      campaign_id: props.match.params.id ? props.match.params.id : "",
      // campaign_id: 7502,
      endis_responder: "off",
      letterVariations: "",
      from_name: "",
      subject: LtrVariationDefaultSubject,
      cc: [],
      bcc: [],
      errors: {},
      embedName: [],
      embedColumns: [],
      defaultWidgetForm: [],
      LetterVariationsArray: [],
      dynamicLetterTemplate: [],
      Attachment: [],
      selectLetterTypeArr: [firstKey],
      selectLetterTypeObject: { [firstKey]: LetterVariationsArr },
      tabKey: 0,
      message: LtrVariationDefaultMsg,
      client_id: localStorage.getItem("client_id"),
      token: localStorage.getItem("token"),
      user_type: this.props.userPermissions.user_type,
      subscription_feature: this.props.userPermissions.subscription_feature,
      update: false,
      id: "",
    };
    this.quillRef = null;
    this.wholePermissions = this.props.userPermissions;
  }
  token = localStorage.getItem("token");
  client_id = localStorage.getItem("client_id");

  UNSAFE_componentWillMount() {
    this.getCampResponderData();
  }
  componentDidMount() {
    this.attachQuillRefs();
    if (this.props.campid) {
      this.setState({ campaign_id: this.props.campid });
    }
    this.mountSelectFileQuery();
  }
  mountSelectFileQuery = () => {
    var self = this;
    $(".custom-file-input").on("change", function () {
      let fileName = ""; //$(this).val().split("\\").pop();
      if ($(this)[0] && $(this)[0].files) {
        let files = $(this)[0].files;
        if (files.length > 2) {
          alert(__t("Attachment Number of Files MAX: 2"));
          self.setState({ maxFileAlert: true });
        } else {
          self.setState({ maxFileAlert: false });
        }
        let sizeWarn = false;
        for (let i = 0; i < files.length; i++) {
          const el = files[i];
          if (i > 0) {
            fileName += ", ";
          }
          if (el.size > 10485760) {
            sizeWarn = true;
          } else {
            fileName += el.name + ` (${bytesToSize(el.size)})`;
          }
        }
        if (sizeWarn) {
          alert(__t("Attachment File Size MAX: 10 [in MB]"));
        }
      }
      $(this).next(".custom-file-label").addClass("selected").html(fileName);
    });
  };
  attachQuillRefs = () => {
    // Ensure React-Quill reference is available:
    if (typeof this.reactQuillRef.getEditor !== "function") return;
    // Skip if Quill reference is defined:
    if (this.quillRef != null) return;
    const quillRef = this.reactQuillRef.getEditor();
    if (quillRef != null) this.quillRef = quillRef;
  };
  getCampResponderData = async () => {
    let result = await Utility.executeRequest("findcampresponder", {
      camp_id: this.state.campaign_id,
      type: "Newsletter",
    });
    if (result.data) {
      let { data } = result;
      if (data.id) {
        this.setState(
          {
            id: data.id,
            update: true,
            endis_responder:
              data.endis_responder && data.endis_responder == 1 ? "on" : "off",
            letterType: data.letterType ? data.letterType : "",
            letterVariations: data.letterVariations
              ? data.letterVariations
              : "",
            cc: data.cc ? JSON.parse(data.cc) : [],
            bcc: data.bcc ? JSON.parse(data.bcc) : [],
            from_name: data.from_name ? data.from_name : "",
            subject: data.subject ? data.subject : "",
            message: data.message ? data.message : "",
            Attachment: data.Attachment ? JSON.parse(data.Attachment) : [],
            upAttachmentArr: data.Attachment ? JSON.parse(data.Attachment) : [],
          },
          () => {
            this.findWidget();
            if(data.letterType && data.letterVariations) {
              this.findLetterVariationTemplate(data.letterVariations);
            }
          }
        );
      }
    } else {
      this.findWidget();
    }
  };
  getContactList = () => {
    return new Promise(async (resolve, reject) => {
      let subs_id = localStorage.getItem("subs_id");
      let res = await Utility.executeRequest(
        "get-data-set-rel",
        {
          rel: "subscription",
          rel_id: subs_id,
        },
        ""
      );
      var arr = [];
      res.data.rows.map((val, key) => {
        var list_options = {};
        list_options["value"] = val.id;
        list_options["label"] = val.title;
        arr.push(list_options);
      });
      resolve({
        list_options: arr,
        contact_lists: res.data.rows,
      });
    }).catch((e) => {});
  };
  findWidget = async () => {
    let widgets = { id: 0 };
    if (this.props.campaign && this.props.campaign.widgets) {
      widgets = this.props.campaign.widgets;
      if (typeof widgets == "string") {
        widgets = JSON.parse(widgets)[0];
      }
    }
    let result = await Utility.executeRequest(
      "findwidgetforupdate",
      {
        id: widgets.id,
        client_id: this.state.client_id,
        search: "",
      },
      ""
    );
    if (result.data && result.data[0]) {
      let widdgetObj = result.data[0];
      let clists = await this.getContactList();
      this.setLetterTypeSelectList(widdgetObj.form_json);

      let contact_list = [];
      let res = await Utility.executeRequest(
        "get_contact_set_data",
        {
          id: widdgetObj.id,
          type: "widget",
        },
        ""
      );
      if (res.data.length > 0) {
        contact_list = clists.contact_lists.filter((elem) => {
          return elem.id == res.data[0].data_set_id;
        });
      }
      let clist = [];
      if (contact_list.length) {
        clist = await Utility.executeRequest(
          "get-data-columns",
          {
            table: contact_list[0]["contact_list"],
          },
          ""
        );
      }
      const embedColumns = typeof clist.data != "undefined" ? clist.data : [];
      const defaultWidgetForm = widdgetObj.form_json.length
        ? JSON.parse(widdgetObj.form_json)
        : "";
      let embedName = filterEmbed(defaultWidgetForm);
      this.setState({ embedName, embedColumns });
    }
  };
  setLetterTypeSelectList = (form_json) => {
    if (typeof form_json == "string") {
      form_json = JSON.parse(form_json);
    }
    let { selectLetterTypeObject } = this.state;
    let selectLetterTypeArr = [firstKey];
    for (let i = 0; i < form_json.length; i++) {
      const fel = form_json[i];
      if (
        fel.name &&
        fel.element == "Selection List" &&
        fel.required &&
        fel.required == true
      ) {
        selectLetterTypeArr.push(fel.label);
        let tempArr = [];
        if (fel.selectionOption) {
          let selectionOption = JSON.parse(fel.selectionOption);
          for (let j = 0; j < selectionOption.length; j++) {
            const oel = selectionOption[j];
            tempArr.push(oel.option);
          }
        }
        selectLetterTypeObject[fel.label] = tempArr;
      }
    }
    this.setState(
      { form_json, selectLetterTypeObject, selectLetterTypeArr },
      () => {
        const { letterType } = this.state;
        this.handleTypeSelect(letterType);
      }
    );
  };
  findLetterVariationTemplate = (name) => {
    Utility.ExecuteData("findlettervarbyname", this.token, {
      client_id: this.state.client_id,
      camp_id: this.state.campaign_id,
      name,
      type: "Newsletter",
    }).then((data) => {
      let resObj = data.data;
      if (resObj && (resObj.template || resObj.subject)) {
        this.setState({
          subject: resObj.subject ? resObj.subject : LtrVariationDefaultSubject,
          message: resObj.template,
          from_name: resObj.from_name,
        });
      } else {
        this.setState({ subject: "", message: "" });
      }
    });
  };
  handleTypeSelect = (value) => {
    const { selectLetterTypeObject } = this.state;
    this.setState({ letterType: value });
    if (value) {
      this.setState(
        { LetterVariationsArray: selectLetterTypeObject[value] },
        () => {
          const { letterVariations, LetterVariationsArray } = this.state;
          if (letterVariations && LetterVariationsArray) {
            let tabKey = LetterVariationsArray.indexOf(letterVariations);
            this.setState({ tabKey: tabKey + 1 });
          }
        }
      );
    } else {
      this.setState({
        subject: LtrVariationDefaultSubject,
        message: LtrVariationDefaultMsg,
        LetterVariationsArray: [],
        tabKey: 0,
      });
    }
  };
  handleTabChange = (tabKey) => {
    const { LetterVariationsArray } = this.state;
    this.setState(
      { tabKey, letterVariations: LetterVariationsArray[tabKey - 1] },
      () => {
        this.findLetterVariationTemplate(this.state.letterVariations);
      }
    );
  };
  handleOnChange = (e) => {
    let el = e.target;
    this.setState({ [el.name]: el.value });
  };
  editorChange = (value) => {
    this.setState({ message: value });
  };
  addMoreCcBcc = (type) => {
    if (type == "cc") {
      let cc = this.state.cc;
      cc.push({ name: "", email: "" });
      this.setState({ cc: cc });
    } else if (type == "bcc") {
      let bcc = this.state.bcc;
      bcc.push({ name: "", email: "" });
      this.setState({ bcc: bcc });
    }
  };
  removeFromArray = (k, type) => {
    let field = this.state[type];
    let removedArr = field.splice(k, 1);
    if(this.state.removedArr) { 
      removedArr = [...this.state.removedArr, removedArr[0]]
    }
    this.setState({ [field]: field, removedArr });
    if (type === "Attachment") {
      this.setState({ upAttachmentArr: field });
    }
  };
  handleChangeCcBcc = (e, k, type) => {
    if (type == "cc") {
      let cc = this.state.cc;
      let obj = cc[k];
      obj[e.target.name] = e.target.value;
      cc[k] = obj;
      this.setState({ cc: cc });
    } else if (type == "bcc") {
      let bcc = this.state.bcc;
      let obj = bcc[k];
      obj[e.target.name] = e.target.value;
      bcc[k] = obj;
      this.setState({ bcc: bcc });
    }
  };
  HandleCreateUpdate = (e) => {
    e.preventDefault();
    if (this.state.maxFileAlert) {
      alert("Please select Attachment number of Files MAX: 2");
      return;
    }
    if (this.state.subject.trim() === "") {
      alert(__t("Please enter subject !"));
      return;
    }
    if (this.state.campaign_id) {
      let myForm = document.getElementById("myForm");
      var formData = new FormData(myForm);
      formData.append("id", this.state.campaign_id);
      formData.append("letterVariations", this.state.letterVariations);
      if (this.state.cc.length > 0) {
        formData.append("cc", JSON.stringify(this.state.cc));
      }
      if (this.state.bcc.length > 0) {
        formData.append("bcc", JSON.stringify(this.state.bcc));
      }
      formData.append("message", this.state.message);
      formData.append("client_id", this.state.client_id);
      formData.append("type", "Newsletter");
      if (this.state.upAttachmentArr) {
        formData.append(
          "upAttachmentArr",
          JSON.stringify(this.state.upAttachmentArr)
        );
      }
      if(this.state.removedArr) { 
        formData.append(
          "removedArr",
          JSON.stringify(this.state.removedArr)
        );
      }

      Utility.ExecuteFormData("updatecampresponder", formData)
        .then((responseJson) => {
          if (responseJson.code === 200) {
            $("#msg_text").text(__t("successfully_updated_campaign"));
            $("#alertinfo").show();
            if (this.props.handleTopTabSelect && !this.state.update) {
              setTimeout(() => {
                this.props.handleTopTabSelect(4);
              }, 1000);
            }
          } else {
            alert("Something went wront. Try Again.");
          }
        })
        .catch((error) => {
          this.setState({ errors: true });
        });
    }
  };
  handleClick = (name) => {
    var range = this.quillRef.getSelection();
    let position = range ? range.index : 0;
    this.quillRef.insertText(position, "{" + name + "}");
  };

  render() {
    const {
      errors,
      endis_responder,
      letterType,
      update,
      tabKey,
      campaign_id,
      LetterVariationsArray,
      selectLetterTypeArr,
      LetterType,
    } = this.state;
    return (
      <div>
        <form
          onSubmit={this.HandleCreateUpdate}
          id="myForm"
          enctype="multipart/form-data"
        >
          <div className="row form-group">
            {/* Enable / Disable AutoResponder */}
            <div className="col-md-12 col-sm-12 col-xs-12">
              <div className="row">
                <div className="radio col-md-12 col-sm-12 col-xs-10">
                  <label className="control-label">
                    {__t("Enable / Disable AutoResponder") + ": "}
                  </label>
                </div>
                <div className="radio col-md-2 col-sm-2 col-xs-3">
                  <label>
                    <input
                      type="radio"
                      value="on"
                      name="endis_responder"
                      checked={endis_responder == "on" ? true : false}
                      onClick={this.handleOnChange}
                    />
                    {" " + __t("Enable")}
                  </label>
                </div>
                <div className="radio col-md-2 col-sm-2 col-xs-3">
                  <label>
                    <input
                      type="radio"
                      value="off"
                      name="endis_responder"
                      checked={endis_responder == "off" ? true : false}
                      onClick={this.handleOnChange}
                    />
                    {" " + __t("Disable")}
                  </label>
                </div>
                <label id="-error" className="error">
                  {errors["endis_responder"]}
                </label>
              </div>
            </div>
            {/* Select Letter Variations Type */}
            <div className="col-md-8 col-sm-8 col-xs-12">
              <label className="control-label">
                {__t(
                  "Select Dynamic Letter Variations bases on the selected field"
                )}
              </label>
              <select
                name="letterType"
                className="form-control"
                value={letterType}
                onChange={(e) => this.handleTypeSelect(e.target.value)}
              >
                <option value="">
                  ----{__t("Select Letter Variations")}----
                </option>
                {selectLetterTypeArr.map((el, key) => {
                  let selected = false;
                  if (el === LetterType) {
                    selected = true;
                  }
                  return (
                    <option value={el} key={key} selected={selected}>
                      {" " + el}
                    </option>
                  );
                })}
              </select>
              <label id="-error" className="error">
                {this.state.errors["letterType"]}
              </label>
            </div>
            {/* Tab */}
            <div className="col-md-12 col-sm-12 col-xs-12">
              <hr />
              {LetterVariationsArray && LetterVariationsArray.length > 0 ? (
                <Tabs
                  activeKey={tabKey}
                  onSelect={this.handleTabChange}
                  animation={false}
                  id="noanim-tab-example"
                  className="greentab"
                >
                  {LetterVariationsArray.map((el, key) => {
                    return (
                      <Tab
                        eventKey={key + 1}
                        title={__t(el)}
                        key={key}
                        style={{ background: "lightgreen !important" }}
                      ></Tab>
                    );
                  })}
                </Tabs>
              ) : (
                ""
              )}
            </div>
            {/* from_name */}
            <div className="col-md-8 col-sm-8 col-xs-12">
              <label className="control-label required-field">
                {__t("From Name")}
              </label>
              <input
                type="text"
                name="from_name"
                value={this.state.from_name}
                className="form-control"
                placeholder={__t("Enter From Name")}
                onChange={this.handleOnChange}
              />
              <label id="-error" className="error">
                {this.state.errors["subject"]}
              </label>
            </div>
            {/* subject */}
            <div className="col-md-8 col-sm-8 col-xs-12">
              <label className="control-label required-field">
                {__t("subject")}
              </label>
              <input
                type="text"
                name="subject"
                value={this.state.subject}
                className="form-control"
                placeholder={__t("enter_sub")}
                onChange={this.handleOnChange}
              />
              <label id="-error" className="error">
                {this.state.errors["subject"]}
              </label>
            </div>
            {/* cc */}
            <div className="col-md-6 col-sm-6 col-xs-12">
              {this.state.cc.map((v, k) => {
                return (
                  <div className="row col-md-12" key={k}>
                    <div className="col-1 align_center">
                      <label className="control-label ">{__t("cc")}</label>
                    </div>
                    <div className="col-4">
                      <input
                        type="text"
                        name={"name"}
                        value={v.name}
                        className="col-md-12 form-control mr-2"
                        placeholder={__t("name")}
                        onChange={(e) => {
                          this.handleChangeCcBcc(e, k, "cc");
                        }}
                      />
                    </div>
                    <div className="col-5">
                      <input
                        type="text"
                        name={"email"}
                        value={v.email}
                        className="col-md-12 form-control"
                        placeholder={__t("enter_email")}
                        onChange={(e) => {
                          this.handleChangeCcBcc(e, k, "cc");
                        }}
                        onKeyUp={(e) => {
                          let val = e.target.value;
                          let lastAtPos = val.lastIndexOf("@");
                          let lastDotPos = val.lastIndexOf(".");

                          if (
                            !(
                              lastAtPos < lastDotPos &&
                              lastAtPos > 0 &&
                              val.indexOf("@@") == -1 &&
                              lastDotPos > 2 &&
                              val.length - lastDotPos > 2
                            )
                          ) {
                            e.target.style.border =
                              "1px solid rgb(245, 40, 40)";
                          } else {
                            e.target.style.border = "1px solid #e5e6e7";
                          }
                        }}
                      />
                    </div>
                    <div className="col-2">
                      <button
                        type="button"
                        className="btn btn-small"
                        onClick={() => {
                          this.removeFromArray(k, "cc");
                        }}
                      >
                        <i className="fa fa-trash" aria-hidden="true" />
                      </button>
                    </div>
                  </div>
                );
              })}
              <button
                type="button"
                className="btn btn-small ml-2"
                onClick={() => {
                  this.addMoreCcBcc("cc");
                }}
              >
                <i className="fa fa-plus mr-2" aria-hidden="true" />
                CC
              </button>
              <label id="-error" className="error">
                {this.state.errors["cc"]}
              </label>
            </div>
            {/* bcc */}
            <div className="col-md-6 col-sm-6 col-xs-12">
              {this.state.bcc.map((v, k) => {
                return (
                  <div className="row col-md-12" key={k}>
                    <div className="col-1 align_center">
                      <label className="control-label">{__t("bcc")}</label>
                    </div>
                    <div className="col-4">
                      <input
                        type="text"
                        name={"name"}
                        value={v.name}
                        className="col-md-12 form-control"
                        placeholder={__t("name")}
                        onChange={(e) => {
                          this.handleChangeCcBcc(e, k, "bcc");
                        }}
                      />
                    </div>
                    <div className="col-5">
                      <input
                        type="text"
                        name={"email"}
                        value={v.email}
                        className="col-md-12 form-control"
                        placeholder={__t("enter_email")}
                        onChange={(e) => {
                          this.handleChangeCcBcc(e, k, "bcc");
                        }}
                        onKeyUp={(e) => {
                          let val = e.target.value;
                          let lastAtPos = val.lastIndexOf("@");
                          let lastDotPos = val.lastIndexOf(".");

                          if (
                            !(
                              lastAtPos < lastDotPos &&
                              lastAtPos > 0 &&
                              val.indexOf("@@") == -1 &&
                              lastDotPos > 2 &&
                              val.length - lastDotPos > 2
                            )
                          ) {
                            e.target.style.border =
                              "1px solid rgb(245, 40, 40)";
                          } else {
                            e.target.style.border = "1px solid #e5e6e7";
                          }
                        }}
                      />
                    </div>
                    <div className="col-2">
                      <button
                        type="button"
                        className="btn btn-small"
                        onClick={() => {
                          this.removeFromArray(k, "bcc");
                        }}
                      >
                        <i className="fa fa-trash " aria-hidden="true" />
                      </button>
                    </div>
                  </div>
                );
              })}
              <button
                type="button"
                className="btn btn-small"
                onClick={() => {
                  this.addMoreCcBcc("bcc");
                }}
              >
                <i className="fa fa-plus mr-2" aria-hidden="true" />
                BCC
              </button>
              <label id="-error" className="error">
                {this.state.errors["bcc"]}
              </label>
            </div>
            {/* Message */}
            <div className="col-md-8 col-sm-8 col-xs-12">
              <label className="control-label required-field">
                {__t("Default Message")}
              </label>
              <ReactQuill
                ref={(el) => {
                  this.reactQuillRef = el;
                }}
                theme={"snow"}
                onChange={this.editorChange}
                modules={NewsLetterAutoresponder.modules}
                formats={NewsLetterAutoresponder.formats}
                value={this.state.message}
              />
              <label id="-error" className="error">
                {this.state.errors["message"]}
              </label>
              <label>{__t("field_merge_label")} : </label>
              {this.state.embedName.map((v, i) => {
                if (v.name == "address") {
                  return (
                    <AdressComponents handleClick={this.handleClick} key={i} />
                  );
                } else {
                  return (
                    <button
                      type="button"
                      className={"embed-btn"}
                      key={i}
                      onClick={() => {
                        this.handleClick(v.name);
                      }}
                      data-for="soclose"
                      data-tip={Utility.getLabel(v.label)}
                    >
                      {v.label}
                    </button>
                  );
                }
              })}
              <br />
              <label>{__t("field_merge_label_rep")} : </label>
              {this.state.embedColumns.map((v, i) => {
                if (v.COLUMN_NAME != "ID") {
                  return (
                    <button
                      type="button"
                      className={"embed-btn"}
                      key={v.COLUMN_NAME}
                      onClick={() => {
                        this.handleClick(v.COLUMN_NAME);
                      }}
                      data-for="soclose"
                      data-tip={Utility.getLabel(v.COLUMN_NAME)}
                      key={i}
                    >
                      {v.COLUMN_NAME}
                    </button>
                  );
                } else {
                  return null;
                }
              })}
            </div>
            {/* Attachment  */}
            <div className="col-md-8 col-sm-8 col-xs-12">
              <label className="control-label">{__t("Attachment")}</label>
              <div className="custom-file">
                <input
                  id="attachment"
                  name="Attachment"
                  type="file"
                  className="custom-file-input"
                  accept="application/pdf"
                  multiple
                />
                <label for="attachment" className="custom-file-label">
                  Choose file...
                </label>
              </div>
              <label id="-error" className="text-warning">
                {__t(
                  "Autoresponder Attachment Allowed File Extensions: PDF, Files MAX: 2 & File Size MAX: 10 [in MB]"
                )}
              </label>
              {this.state.Attachment.length > 0 ? (
                <>
                  <br />
                  <label className="control-label">
                    {__t("Uploaded Attachment") + " : "}
                  </label>
                  <ul>
                    {this.state.Attachment.map((el, key) => {
                      return (
                        <li key={key}>
                          <a href={fileFullBasePath() + el} target="_blank">
                            {el}
                          </a>
                          &nbsp;&nbsp;&nbsp;
                          <i
                            className="fa fa-trash "
                            aria-hidden="true"
                            onClick={() => {
                              this.removeFromArray(key, "Attachment");
                            }}
                          />
                        </li>
                      );
                    })}
                  </ul>
                </>
              ) : (
                ""
              )}
            </div>
            {/* button */}
            <div className="col-md-8 col-sm-8 col-xs-12">
              {campaign_id && (
                <button className="btn btn-primary" type="submit">
                  {update ? "Update" : "Create"}
                </button>
              )}
            </div>
          </div>
        </form>
      </div>
    );
  }
}

/*
 * Quill modules to attach to editor
 * See https://quilljs.com/docs/modules/ for complete options
 */
NewsLetterAutoresponder.modules = {};
NewsLetterAutoresponder.modules.toolbar = [
  ["bold", "italic", "underline", "strike"], // toggled buttons
  ["blockquote", "code-block"], // blocks
  [{ header: 1 }, { header: 2 }], // custom button values
  [{ list: "ordered" }, { list: "bullet" }], // lists
  [{ script: "sub" }, { script: "super" }], // superscript/subscript
  [{ indent: "-1" }, { indent: "+1" }], // outdent/indent
  [{ direction: "rtl" }], // text direction
  [{ size: ["small", false, "large", "huge"] }], // custom dropdown
  [{ header: [1, 2, 3, 4, 5, 6, false] }], // header dropdown
  [{ color: [] }, { background: [] }], // dropdown with defaults
  [{ font: [] }], // font family
  [{ align: [] }], // text align
  ["clean"], // remove formatting
];

/*
 * Quill editor formats
 * See https://quilljs.com/docs/formats/
 */
NewsLetterAutoresponder.formats = [
  "header",
  "font",
  "background",
  "color",
  "code",
  "size",
  "bold",
  "italic",
  "underline",
  "strike",
  "blockquote",
  "list",
  "bullet",
  "indent",
  "script",
  "align",
  "direction",
  "link",
  "image",
  "code-block",
  "formula",
  "video",
];

export default NewsLetterAutoresponder;
