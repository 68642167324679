export const LtrVariationDefaultSubject = `New Feature test from {name}`;
export const LtrVariationDefaultMsg = `<p>Thank you {name} for sending your letter. </p><p><br></p><p>We appreciate your efforts to support our campaign and look forward to working with you on getting our initiative passed.</p><p><br></p><p>Regards,</p><p><br></p><p>Our team</p>`;

export const LetterVariationsObj = {
  Alberta: ``,
  "British Columbia": ``,
  Manitoba: ``,
  "New Brunswick": ``,
  "Newfoundland and Labrador": ``,
  "Nova Scotia": ``,
  Ontario: ``,
  "Prince Edward Island": ``,
  Quebec: ``,
  Saskatchewan: ``,
  "Northwest Territories": ``,
  Nunavut: ``,
  Yukon: ``,
};
export const LetterVariationsArr = Object.keys(LetterVariationsObj);
