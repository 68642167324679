import React, { useState, useEffect } from "react";
import "../../styles/tab6_styles.css";
import logo from "../../images/nationbuilder-mark-solid.svg";
import Utility from "../common/Utility";

const APIConnections = () => {
  const [isConnected, setIsConnected] = useState(false);

  // const getConnections = async () => {
  //   const action = "get-connections";
  //   const token = localStorage.getItem("token");

  //   try {
  //     const response = await Utility.ExecuteData(action, token, {}, false);
  //     const connectionList = response.data;
  //     if (connectionList[0].is_connected) {
  //       setIsConnected(connectionList[0].is_connected);
  //     } else {
  //       setIsConnected(false);
  //     }
  //   } catch (error) {
  //     console.error("An error occurred:", error);
  //   }
  // };
  const getConnections = async () => {
    const action = "get-connections";
    const token = localStorage.getItem("token");

    try {
      const response = await Utility.ExecuteData(action, token, {}, false);
      const connectionList = response.data;

      if (connectionList && connectionList.length > 0) {
        // Check if the array is not empty
        setIsConnected(connectionList[0].is_connected);
      } else {
        // Handle the case where connectionList is empty.
        // You can set isConnected to false or take appropriate action here.
        setIsConnected(false);
      }
    } catch (error) {
      console.error("An error occurred:", error);
    }
  };

  useEffect(() => {
    getConnections();
  }, []);

  const addIntegration = async (disable) => {
    const action = "add-integration";
    const token = localStorage.getItem("token");
    const data = {
      api_name: "NationBuilder",
    };
    if (disable) {
      data["disable"] = true;
    }

    try {
      const response = await Utility.ExecuteData(action, token, data, false);
      const { code } = response;

      // if (code === 200) {
      getConnections();
      // }
    } catch (error) {
      console.error("An error occurred:", error);
    }
  };

  return (
    <div className="company-integration">
      <div className="company-box">
        <img src={logo} alt="NationBuilder" className="company-logo" />
        <h3 className="company-name">NationBuilder</h3>
        {isConnected ? (
          <button
            className="connected-button"
            onClick={() => {
              addIntegration("disable");
            }}
          >
            Connected
          </button>
        ) : (
          <button
            className="add-integration-button"
            onClick={() => {
              addIntegration();
            }}
          >
            Add Integration
          </button>
        )}
      </div>
    </div>
  );
};

export default APIConnections;
