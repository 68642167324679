import React from 'react';
import { __t } from '../../locale/language';
export const applyDrag = (arr, dragResult) => {
  return new Promise((resolve, reject) => {
    const { removedIndex, addedIndex, payload } = dragResult;
    if (removedIndex === null && addedIndex === null) return arr;
    const result = [...arr];
    let itemToAdd = payload;
    let duplicate_address = true;
    if (typeof itemToAdd !== 'undefined') {
      result.map((v, k) => {
        if (
          v.element == 'Address By Geo-Location' &&
          itemToAdd.element == 'Address By Geo-Location'
        ) {
          duplicate_address = false;
        }
      });
    }
    if (duplicate_address) {
      if (removedIndex !== null) {
        itemToAdd = result.splice(removedIndex, 1)[0];
      }

      if (addedIndex !== null) {
        result.splice(addedIndex, 0, itemToAdd);
      }
    } else {
      alert(__t('duplicate_address'));
    }

    resolve(result);
  });
};
export const getElementByType = async (form_data, type) => {
  let field = null;
  form_data.map((v, k) => {
    if (typeof v.validation != 'undefined' && v.validation == type) {
      field = v;
    }
  });
  return field;
};
export const checkDuplicateBFieldName = async formData => {
  var valueArr = [];
  formData.map(function(item) {
    if (typeof item.name !== 'undefined') {
      valueArr.push(item.name);
    }
  });
  var isDuplicate = valueArr.some(function(item, idx) {
    return valueArr.indexOf(item) != idx;
  });

  return isDuplicate;
};
export const filterEmbed = formElements => {
  let embedableFields = [
    'Text Field',
    'Address By Geo-Location',
    'Selection List'
  ];
  let embedName = [];
  formElements.map(item => {
    if (
      embedableFields.includes(item.element) &&
      typeof item.name !== 'undefined'
    ) {
      embedName.push({ name: item.name, label: item.label });
    }
  });
  return embedName;
};
export const generateItems = (count, creator) => {
  const result = [];
  for (let i = 0; i < count; i++) {
    result.push(creator(i));
  }
  return result;
};
export const generateFormElement = (
  item,
  handleChange,
  widget_setting,
  merge_column
) => {
  switch (item.element) {
    case 'Headings':
      return (
        <div className={`form-group`}>
          <h1
            className={item.className}
            dangerouslySetInnerHTML={{ __html: item.text }}
          />
        </div>
      );
    case 'Paragraph':
      return <p dangerouslySetInnerHTML={{ __html: item.text }} />;
    case 'Text Field':
      return (
        <div className={`form-group`}>
          <label className='control-label'>{item.label}</label>
          <input
            type='text'
            name={item.name}
            style={{ borderRadius: widget_setting.border_radius + 'px' }}
            placeholder={item.placeholder}
            className={item.className}
          />
        </div>
      );
    case 'Text Area':
      return (
        <div className={`form-group`}>
          <label className='control-label'>{item.label}</label>
          <div
            className='message_area'
            dangerouslySetInnerHTML={{ __html: item.text }}
            style={{ borderRadius: widget_setting.border_radius + 'px' }}
          />
        </div>
      );
    case 'Image':
      return (
        <div style={{ textAlign: item.imageAlign || 'center' }}>
          <img
            src={item.url}
            className={item.className}
            height={item.height ? item.height + 'px' : '100%'}
          />
          {item.heading || item.paragraph ? (
            <div className='widget_title_wrapp'>
              <span
                className='image-heading'
                style={{ color: item.fontColor || '#000000' }}
                dangerouslySetInnerHTML={{ __html: item.heading }}
              />
              <span
                className='image-paragrapg'
                style={{ color: item.fontColor || '#000000' }}
                dangerouslySetInnerHTML={{ __html: item.paragraph }}
              />
            </div>
          ) : (
            ''
          )}
        </div>
      );
    case 'Donation':
      return (
        <div className={`form-group`}>
          <label className='control-label'>{item.label}</label>
          <br />
          {item.donation_arr.length
            ? item.donation_arr.map((val, k) => {
                return (
                  <div key={k}>
                    <input type='radio' name='amount' />
                    {val}
                  </div>
                );
              })
            : 'Add Donation'}
        </div>
      );
    case 'Address By Geo-Location':
      return (
        <div className={`form-group`}>
          <label className='control-label'>{item.label}</label>
          <input
            type='text'
            name=''
            className='form-control'
            style={{ borderRadius: widget_setting.border_radius + 'px' }}
          />
        </div>
      );
    case 'Selection List':
      let options =
        typeof item.selectionOption !== 'undefined'
          ? JSON.parse(item.selectionOption)
          : [];
      return (
        <div className={`form-group`}>
          <label className='control-label'>{item.label}</label>
          <select
            className='form-control'
            style={{ borderRadius: widget_setting.border_radius + 'px' }}
          >
            <option>Default Option</option>
            {options.map((v, k) => {
              return (
                <option key={k} value={v.option}>
                  {v.option}
                </option>
              );
            })}
          </select>
        </div>
      );
    case 'Widget Submit To':
      return (
        <div className={`form-group`}>
          <label className='control-label'>
            Selected Option will appear in widget
          </label>
        </div>
      );
    case 'Email Specific Person':
      return (
        <div className={`form-group`}>
          <label className='control-label'>
            Email To :{' '}
            <bold>
              {item.name} ({item.email})
            </bold>
          </label>
        </div>
      );
    case 'Submit Button':
      return (
        <div className={`form-group`} style={{ textAlign: item.align }}>
          <button
            className={'btn btn-primary ' + item.class}
            style={{ backgroundColor: item.color }}
          >
            {item.title}
          </button>
        </div>
      );
    case 'Checkbox':
      // // name: 'privacy_policy',
      return item.name === 'privacy_policy' ? (
        <div className='col-md-12'>
          <label className='form-group'>
            <input type='checkbox' />
            {item.label}
          </label>

          <span className=''>
            {item.hyperlink_label}
          </span>
        </div>
      ) : (
        <div className='col-md-12'>
          <label className='form-group'>
            <input type='checkbox' />
            {item.label}
          </label>
        </div>
      );
    case 'Radiobtn':
      // name: 'newsletter_signup',
      return <div className={`form-group`}>
        <label className='control-label'>{item.label}</label>
        <div className='col-md-12'>
          {item.options.map((el, key) => {
            let option_title = item.optionsTitle ? item.optionsTitle[key] : el;
            let checked = false;
            if(item.newsletter_signup && el === item.newsletter_signup) {
              checked = true;
            }
            return (
            <label style={{ marginRight: item.btnMargin }} key={key}>
              <input
                type="radio"
                name={item.name}
                value={el}
                checked={checked}
              />
              {" " + option_title + " "}
            </label>
            )
          })}
        </div>
      </div>;
  }
};
export const formElement = [
  {
    element: 'Image',
    url:
      'https://www.visitportugal.com/sites/www.visitportugal.com/files/mediateca/23_660x371.jpg',
    className: 'custom_class form_img_class',
    height: '',
    width: '',
    heading: 'Widget Title',
    paragraph: 'Lorem ipsum dolor sit amet.',
    fontColor: '#FFFFFF',
    imageAlign: 'center'
  },
  { element: 'Headings', text: 'Widget Title', className: 'custom_class' },
  {
    element: 'Paragraph',
    text: 'Lorem ipsum dolor sit amat.',
    className: 'custom_class'
  },
  {
    element: 'Text Field',
    name: 'input_field',
    placeholder: 'Input Field',
    validation: '',
    label: 'Input Field',
    className: 'form-control'
  },
  // {
  //   element: "Text Area",
  //   name: "message",
  //   text: "Here is your default text message for contact",
  //   validation: "",
  //   row: 5,
  //   col: 30,
  //   label: "Message",
  //   className: "form-control form-textarea"
  // },
  // {
  //   element: "Address By Geo-Location",
  //   name: "address",
  //   validation: "",
  //   label: "My Address",
  //   className: "form-control"
  // },
  {
    element: 'Selection List',
    name: 'select',
    validation: '',
    label: 'Selection List',
    className: 'form-control'
  },
  {
    element: 'Checkbox',
    elem_name: 'Privacy Policy',
    name: 'privacy_policy',
    validation: '',
    label: 'I Accept PRIVACY and TERMS of USE',
    className: 'form-control',
    required: true
  },
  {
    element: 'Checkbox',
    elem_name: 'Campaign Update',
    name: 'campaign_update',
    validation: '',
    label: 'Subscribe for Campaign updates',
    className: 'form-control'
  },
  {
    element: 'Radiobtn',
    elem_name: 'Sign up for our Newsletter',
    name: 'newsletter_signup',
    validation: '',
    label: 'Newsletter/Mailing List',
    className: 'form-control',
    options: ["Send Letter only", "Signup for Newsletter only"],
    optionsTitle: ["Send Letter only", "Signup for Newsletter only"],
    btnMargin: "10px"
  }
  // {
  //   element: "Contsct List Search",
  //   category: "All",
  //   status: "active"
  // },
  // {
  //   element: "Email Specific Person",
  //   name: "",
  //   email: "",
  //   contact_id: "",
  //   category: ""
  // }
  // {
  //   element: "Submit Button",
  //   title: "Submit",
  //   class: ""
  // }
];

export const defaultWidgetFormForContact = [
  {
    id: 1,
    element: 'Image',
    url:
      'https://www.visitportugal.com/sites/www.visitportugal.com/files/mediateca/23_660x371.jpg',
    className: 'custom_class form_img_class',
    height: 250,
    width: 100,
    heading: 'Widget Title Contact',
    paragraph: 'Lorem ipsum dolor sit amet.',
    fontColor: '#FFFFFF',
    imageAlign: 'center'
  },
  {
    id: 2,
    element: 'Headings',
    text: 'Widget Title',
    className: 'custom_class'
  },
  {
    id: 3,
    element: 'Paragraph',
    text: 'Lorem ipsum dolor sit amat.',
    className: 'custom_class'
  },
  {
    id: 7,
    element: 'Address By Geo-Location',
    name: 'address',
    validation: '',
    label: 'Address',
    className: 'form-control',
    required: true
  },
  {
    id: 4,
    element: 'Text Field',
    name: 'name',
    placeholder: 'Name',
    validation: '',
    label: 'Name',
    className: 'form-control',
    required: true
  },
  {
    id: 5,
    element: 'Text Field',
    name: 'email',
    placeholder: 'Email',
    validation: 'Email',
    label: 'Email',
    className: 'form-control',
    required: true
  },
  {
    id: 6,
    element: 'Text Field',
    name: 'phone',
    placeholder: 'Contact No',
    validation: 'Phone',
    label: 'Phone',
    className: 'form-control',
    required: true
  },
  {
    id: 0,
    element: 'Text Area',
    name: 'message',
    text:
      '<p>Hi {contact_name},</p><p>Here is your default text message for contact, and you can mention any field value in your message by using {field_name} here, it is replaced by original value.</p><p><br></p><p><strong>Thanks &amp; Regards</strong></p><p><strong>{name},{phone}</strong></p>',
    validation: '',
    row: 5,
    col: 30,
    label: 'Message',
    className: 'form-control form-textarea',
    required: true,
    lock_message: false
  },
  {
    id: 8,
    element: 'Checkbox',
    name: 'privacy_policy',
    validation: '',
    label: 'I Accept PRIVACY and TERMS of USE',
    hyperlink_label: 'Read our terms of use and privacy policy',
    className: 'form-control',
    required: true
  },
  {
    id: 9,
    element: 'Checkbox',
    name: 'campaign_update',
    validation: '',
    label: 'Subscribe for Campaign updates',
    className: 'form-control',
    required: false
  },
  {
    id: 10,
    element: 'Submit Button',
    title: 'Submit Widget',
    name: 'Submit button',
    validation: '',
    class: 'btn btn-primary',
    color: 'rgb(23, 14, 78)',
    align: 'center'
  }
];
export const defaultWidgetFormForDonation = [
  {
    id: 1,
    element: 'Image',
    url:
      'https://www.visitportugal.com/sites/www.visitportugal.com/files/mediateca/23_660x371.jpg',
    className: 'custom_class form_img_class',
    height: 250,
    width: 100,
    heading: 'Widget Title Donation',
    paragraph: 'Lorem ipsum dolor sit amet.',
    fontColor: '#FFFFFF',
    imageAlign: 'center'
  },
  {
    id: 2,
    element: 'Headings',
    text: 'Widget Title',
    className: 'custom_class'
  },
  {
    id: 3,
    element: 'Paragraph',
    text: 'Lorem ipsum dolor sit amat.',
    className: 'custom_class'
  },
  {
    id: 4,
    element: 'Text Field',
    name: 'name',
    placeholder: 'Name',
    validation: '',
    label: 'Name',
    className: 'form-control',
    required: true
  },
  {
    id: 5,
    element: 'Text Field',
    name: 'email',
    placeholder: 'Email',
    validation: 'email',
    label: 'Email',
    className: 'form-control',
    required: true
  },
  {
    id: 6,
    element: 'Text Field',
    name: 'phone',
    placeholder: 'Contact No',
    validation: 'phone',
    label: 'Phone',
    className: 'form-control',
    required: true
  },
  // {
  //   id: 0,
  //   element: "Text Area",
  //   name: "message",
  //   text: "Here is your default text message for contact",
  //   validation: "",
  //   row: 5,
  //   col: 30,
  //   label: "Message",
  //   className: "form-control form-textarea",
  //   required: true,
  //   lock_message: false
  // },
  {
    id: 7,
    element: 'Donation',
    name: 'donation_amount',
    label: 'Add Donation Amount',
    donation_arr: [],
    className: 'form-control',
    required: true
  },
  {
    id: 8,
    element: 'Checkbox',
    name: 'privacy_policy',
    validation: '',
    label: 'I Accept PRIVACY and TERMS of USE',
    className: 'form-control',
    required: true
  },
  {
    id: 9,
    element: 'Checkbox',
    name: 'campaign_update',
    validation: '',
    label: 'Subscribe for Campaign updates',
    className: 'form-control',
    required: true
  }
];
