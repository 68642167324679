import React, { Component } from "react";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";
import Main from "./components/layouts/Main";
import Login from "./components/layouts/Login";
import ForgetPassword from "./components/layouts/ForgetPassword";
import ChangePassword from "./components/layouts/ChangePassword";
import { createBrowserHistory } from "history";

import NotFound from "./components/layouts/NotFound";

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      https: false,
    };
  }
  componentDidMount() {
    const url = window.location.origin;
    if (!url.includes("localhost") && !url.includes("https")) {
      window.location = `https:${url.split(":")[1]}`;
    } else {
      this.setState({ https: true });
    }
  }
  render() {
    if (!this.state.https) {
      return null;
    }
    return (
      <BrowserRouter history={createBrowserHistory}>
        <Switch>
          <Route path="/login" exact component={Login} />
          <Route
            key={"my_login_key"}
            path="/login/:token"
            exact
            component={Login}
          />
          <Route path="/forget-password" exact component={ForgetPassword} />
          <Route path="/change-password" exact component={ChangePassword} />

          <Route path="/cgi-bin/" exact component={NotFound} />
          <Route path="/cgi-bin" exact component={NotFound} />

          <Route path="/not-found" component={NotFound} />

          <Route path="/" component={Main} />
          {/* <Redirect to="/not-found" /> */}
        </Switch>
      </BrowserRouter>
    );
  }
}
export default App;
