import React, { Component } from "react";
import Utility from "../common/Utility";
import Moment from "moment";
import { __t } from "../../locale/language";
import { __currency } from "../../locale/language";
import { Link } from "react-router-dom";
import $, { isArray } from "jquery";

class AdminDB extends Component {
  constructor(props) {
    super(props);
    this.state = {
      client_id: localStorage.getItem("client_id"),
      token: localStorage.getItem("token"),
      user_type: Utility.getIdFromToken(localStorage.getItem("token")),
      reseller_count: "",
      client_count: "",
      user_count: "",
      campaign_count: "",
      widgets_count: "",
      alluser_count: "",
      camp_list: "",
      revenueData: "",
      monthly: "",
      yearly: "",
      total_rev: "",
      total_reseller: "",
      camp_count: "",
      subscription_count: "",
      transactions_count: "",
      page: 1,
      sizePerPage: 10,
      total: "",
      revenue_reseller: "",
    };
    this.getResellerData = this.getResellerData.bind(this);
    this.getClientData = this.getClientData.bind(this);
    this.getUserData = this.getUserData.bind(this);
    this.getCampaignData = this.getCampaignData.bind(this);
    this.getWidgetsData = this.getWidgetsData.bind(this);
    this.getallUserData = this.getallUserData.bind(this);
    this.getCampaignList = this.getCampaignList.bind(this);
    this.getClientRevenue = this.getClientRevenue.bind(this);
    this.getSubscriptionsCount = this.getSubscriptionsCount.bind(this);
    this.getTransactionCount = this.getTransactionCount.bind(this);
  }

  getResellerData() {
    //console.log(this.state.user_type);
    Utility.ExecuteData("ClientCount", this.state.token, {
      token: this.state.token,
      user_type: this.state.user_type,
      type: "reseller",
    }).then((data) => {
      console.log(typeof data.data);
      console.log(data.data[0]);

      if (isArray(data.data)) {
        this.setState(() => ({
          reseller_count: data.data[0],
        }));
      } else {
        this.setState(() => ({
          reseller_count: data.data,
        }));
      }
    });
  }
  getClientData() {
    //console.log(this.state.user_type);
    Utility.ExecuteData("ClientCount", this.state.token, {
      token: this.state.token,
      user_type: this.state.user_type,
      type: "client",
    }).then((data) => {
      // //console.log(data.data[0]);
      if (isArray(data.data)) {
        this.setState(() => ({
          client_count: data.data[0],
        }));
      } else {
        this.setState(() => ({
          client_count: data.data,
        }));
      }
    });
  }
  getUserData() {
    //  //console.log(this.state.user_type);
    Utility.ExecuteData("UserCount", this.state.token, {
      token: this.state.token,
      user_type: this.state.user_type,
      type: "user",
    }).then((data) => {
      // //console.log(data.data[0]);
      console.log(typeof data.data);

      if (isArray(data.data)) {
        this.setState(() => ({
          user_count: data.data[0],
        }));
      } else {
        this.setState(() => ({
          user_count: data.data,
        }));
      }

      // this.setState(() => ({
      //   user_count: data.data[0],
      // }));
    });
  }

  getCampaignData() {
    //console.log(this.state.user_type);
    Utility.ExecuteData("CampaignCount", this.state.token, {
      token: this.state.token,
      user_type: this.state.user_type,
      type: "campaign",
    }).then((data) => {
      //console.log(data.data[0]);
      console.log(isArray(data.data));

      this.setState(() => ({
        campaign_count: data.data[0],
      }));
    });
  }
  getWidgetsData() {
    //  //console.log(this.state.user_type);
    Utility.ExecuteData("WidgetsCount", this.state.token, {
      token: this.state.token,
      user_type: this.state.user_type,
      type: "widgets",
    }).then((data) => {
      //   //console.log(data.data[0]);

      this.setState(() => ({
        widgets_count: data.data[0],
      }));
    });
  }
  getallUserData() {
    ////console.log(this.state.user_type);
    Utility.ExecuteData("AllUserCount", this.state.token, {
      token: this.state.token,
      user_type: this.state.user_type,
      type: "user",
    }).then((data) => {
      // //console.log(data.data[0]);

      this.setState(() => ({
        alluser_count: data.data[0],
      }));
    });
  }
  getClientRevenue() {
    //  //console.log(this.state.user_type);
    Utility.ExecuteData("getClientRevenue", this.state.token, {
      token: this.state.token,
      user_type: this.state.user_type,
      type: "user",
    }).then((data) => {
      //console.log(data.total_reveneue);
      var arr1 = [];
      data.data.map((val, k) => {
        var obj = {};
        obj["id"] = val.id;
        obj["name"] = val.name;
        obj["client_count"] = val.client_count;
        let mid = 0;

        data.monthly_revenue.map((value, m) => {
          if (val.id == value.id) {
            //obj['monthly'] = value.monthly_price
            mid = value.monthly_price;
          }
        });
        obj["monthly"] = mid;
        let ifid = 0;
        data.year_revenue.map((data, j) => {
          if (val.id == data.id) {
            ifid = data.yearly_price;
          }
        });
        obj["yearly"] = ifid;
        arr1.push(obj);
      });

      arr1.sort(function (a, b) {
        return b.yearly - a.yearly;
      });
      // //console.log(arr1);
      // //console.log(arr1.slice(0, 10))
      var sum = [];
      if (data) {
        arr1.map((val, k) => {
          // //console.log(val.yearly)
          sum.push(val.yearly);
        });

        let total = 0;
        for (var i = 0; i < sum.length; i++) {
          total += sum[i] << 0;
        }

        this.setState({
          revenueData: arr1.slice(0, 10),
          total_reseller: data.total_reseller[0].reseller,
          total_rev: total,
          total: data.data.length,
          revenue_reseller: arr1,
        });
      }
    });
  }
  updateTable = (page) => {
    let startIndex = (page - 1) * 10;
    // //console.log(startIndex)
    // //console.log(this.state.revenue_reseller)
    //console.log(this.state.revenue_reseller.slice(startIndex, startIndex + 10))
    let revenueData = this.state.revenue_reseller.slice(
      startIndex,
      startIndex + 10
    );
    this.setState({
      revenueData: revenueData,
      page: page,
    });
  };

  updateTablePage = (page) => {
    //  this.getClientRevenue(page);
    this.updateTable(page);
  };
  componentDidMount() {
    this.getClientData();
    this.getResellerData();
    this.getUserData();
    this.getCampaignData();
    this.getWidgetsData();
    this.getallUserData();
    this.getCampaignList();
    this.getClientRevenue();
    this.getSubscriptionsCount();
    this.getTransactionCount();
    this.getLetterReport();
  }

  getLetterReport = (sortby, orderby) => {
    Utility.ExecuteData("getadminlettersrecord", this.state.token, {
      token: this.state.token,
      user_type: this.state.user_type,
      sortby: sortby,
      orderby: orderby,
    }).then((data) => {
      //console.log(data);
      this.setState({
        letter_report: data.data,
      });
    });
  };

  getSubscriptionsCount() {
    Utility.ExecuteData("getSubscriptionsCount", this.state.token, {
      token: this.state.token,
      user_type: this.state.user_type,
    }).then((data) => {
      //console.log(data);
      this.setState({
        subscription_count: data.data[0],
      });
    });
  }

  getTransactionCount() {
    Utility.ExecuteData("getTransactionCount", this.state.token, {
      token: this.state.token,
    }).then((data) => {
      //console.log(data);
      this.setState({
        transactions_count: data.data[0],
      });
    });
  }
  getCampaignList() {
    Utility.ExecuteData("getCampaignList", this.state.token, {
      token: this.state.token,
      user_type: this.state.user_type,
      type: "user",
    }).then((data) => {
      // //console.log(data);
      this.setState({
        camp_list: data.data,
        camp_count: data.count[0].total,
      });
    });
  }

  pagination = (page, sizePerPage, total, getClientRevenue) => {
    if (total <= 0) {
      return "";
    }
    let total_page = Math.ceil(total / sizePerPage);
    let pages = [];
    for (let i = 1; i <= total_page; i++) {
      pages.push(
        <li key={i} className={page == i ? "page-item active" : "page-item"}>
          <a
            className="page-link"
            onClick={(e) => {
              // updateTable(i);
              getClientRevenue(i);
              e.preventDefault();
            }}
          >
            {i}
          </a>
        </li>
      );
    }
    return (
      <nav aria-label="Page navigation example">
        <ul className="pagination justify-content-end">
          <li
            className={page <= 1 ? "page-item disabled" : "page-item"}
            disabled={page <= 1 ? "disabled" : ""}
          >
            <a
              className="page-link"
              onClick={(e) => {
                if (page > 1) {
                  //updateTable(page - 1);
                  getClientRevenue(page - 1);
                }
                e.preventDefault();
              }}
            >
              {__t("back")}
            </a>
          </li>
          {pages}

          <li
            className={page == total_page ? "page-item disabled" : "page-item"}
            disabled={page == total_page ? "disabled" : ""}
          >
            <a
              className="page-link"
              onClick={(e) => {
                if (page < total_page) {
                  // updateTable(page + 1);
                  getClientRevenue(page + 1);
                }
                e.preventDefault();
              }}
            >
              {__t("next")}
            </a>
          </li>
        </ul>
      </nav>
    );
  };

  client_details = (data) => {
    //console.log(data)
  };

  renderClientLetterCounts() {
    const clientLetterCounts = {};

    if (
      !Array.isArray(this.state.letter_report) ||
      this.state.letter_report.length === 0
    ) {
      return (
        <tr>
          <td colSpan="5">No records found</td>
        </tr>
      );
    }

    // this.state.camp_list.forEach((val) => {
    //   const client = val.client_name;
    //   const month = Moment(val.publish_on).format("MMMM");
    //   const year = Moment(val.publish_on).format("YYYY");
    //   const key = client + month + year;

    //   if (!clientLetterCounts[key]) {
    //     clientLetterCounts[key] = {
    //       client: client,
    //       reseller: val.reseller_name || "N/A",
    //       month: month,
    //       year: year,
    //       count: val.letter_sent,
    //     };
    //   } else {
    //     clientLetterCounts[key].count += val.letter_sent;
    //   }
    // });

    // const sortedClientLetterCounts = Object.values(clientLetterCounts).sort(
    //   (a, b) => {
    //     // Sort by client name alphabetically
    //     if (a.client < b.client) return -1;
    //     if (a.client > b.client) return 1;

    //     // Sort by total letter sent
    //     if (a.count > b.count) return -1;
    //     if (a.count < b.count) return 1;

    //     // Sort by month and year
    //     const aDate = Moment(`${a.month} ${a.year}`, "MMMM YYYY");
    //     const bDate = Moment(`${b.month} ${b.year}`, "MMMM YYYY");
    //     return bDate - aDate;
    //   }
    // );

    if (this.state.letter_report.length > 0) {
      return this.state.letter_report.map((val, index) => (
        <tr key={index}>
          <td>{val.client_name}</td>
          <td>{val.reseller_name}</td>
          <td>{val.date.split(" ")[0]}</td>
          <td>{val.date.split(" ")[1]}</td>
          <td>{val.letter_sent}</td>
        </tr>
      ));
    } else {
      return (
        <tr>
          <td colSpan="5">No records found</td>
        </tr>
      );
    }
  }

  render() {
    //console.log(this.state.total_rev);

    console.log(this.state.reseller_count);
    console.log(this.state.client_count);
    console.log(this.state.campaign_count);
    console.log(this.state.widgets_count);
    console.log(this.state.subscription_count);
    console.log(this.state.transactions_count);
    var reseller;
    var client;
    var user;
    var campaign;
    var widgets;
    var alluser;
    var subscriptions;
    var transactions;
    if (
      typeof this.state.reseller_count != "undefined" &&
      typeof this.state.client_count != "undefined" &&
      typeof this.state.campaign_count != "undefined" &&
      typeof this.state.widgets_count != "undefined" &&
      typeof this.state.subscription_count != "undefined" &&
      typeof this.state.transactions_count != "undefined"
    ) {
      reseller = this.state.reseller_count;
      client = this.state.client_count;
      user = this.state.user_count;
      campaign = this.state.campaign_count;
      widgets = this.state.widgets_count;
      alluser = this.state.alluser_count;
      subscriptions = this.state.subscription_count;
      transactions = this.state.transactions_count;
    }

    return (
      <div>
        <div className="row">
          <div className="col-lg-3">
            <div className="ibox ">
              <div className="ibox-title">
                <h5>{__t("reseller")}</h5>
              </div>
              <div className="ibox-content">
                <h1 className="no-margins">
                  {reseller.Total}/{reseller.Total == 0 ? "0" : reseller.Active}
                  /{reseller.Total == 0 ? "0" : reseller.Inactive}
                </h1>
                <div className="stat-percent font-bold text-success" />
                <small>
                  {__t("total")}/{__t("active")}/{__t("inactive")}
                </small>
              </div>
            </div>
          </div>
          <div className="col-lg-3">
            <div className="ibox ">
              <div className="ibox-title">
                <h5>{__t("clients")}</h5>
              </div>
              <div className="ibox-content">
                <h1 className="no-margins">
                  {client.Total}/{client.Total == 0 ? "0" : client.Active}/
                  {client.Total == 0 ? "0" : client.Inactive}
                </h1>
                <div className="stat-percent font-bold text-success" />
                <small>
                  {__t("total")}/{__t("active")}/{__t("inactive")}
                </small>
              </div>
            </div>
          </div>

          <div className="col-lg-3">
            <div className="ibox ">
              <div className="ibox-title">
                <h5> {__t("staff_users")}</h5>
              </div>
              <div className="ibox-content">
                <h1 className="no-margins">
                  {user.Total}/{user.Total == 0 ? "0" : user.Active}/
                  {user.Total == 0 ? "0" : user.Inactive}
                </h1>
                <div className="stat-percent font-bold text-success" />
                <small>
                  {__t("total")}/{__t("active")}/{__t("inactive")}
                </small>
              </div>
            </div>
          </div>

          <div className="col-lg-3">
            <div className="ibox ">
              <div className="ibox-title">
                <h5>{__t("all_users")}</h5>
              </div>
              <div className="ibox-content">
                <h1 className="no-margins">
                  {alluser.Total}/{alluser.Total == 0 ? "0" : alluser.Active}/
                  {alluser.Total == 0 ? "0" : alluser.Inactive}
                </h1>
                <div className="stat-percent font-bold text-success" />
                <small>
                  {__t("total")}/{__t("active")}/{__t("inactive")}
                </small>
              </div>
            </div>
          </div>
          <div className="col-lg-3">
            <div className="ibox ">
              <div className="ibox-title">
                <h5>{__t("campaign")}</h5>
              </div>
              <div className="ibox-content">
                <h1 className="no-margins">
                  {campaign.Total}/{campaign.Total == 0 ? "0" : campaign.Active}
                  /{campaign.Total == 0 ? "0" : campaign.Inactive}
                </h1>
                <div className="stat-percent font-bold text-success" />
                <small>
                  {__t("total")}/{__t("active")}/{__t("inactive")}
                </small>
              </div>
            </div>
          </div>
          <div className="col-lg-3">
            <div className="ibox ">
              <div className="ibox-title">
                <h5>{__t("widgets")}</h5>
              </div>
              <div className="ibox-content">
                <h1 className="no-margins">
                  {widgets.Total}/{widgets.Total == 0 ? "0" : widgets.Active}/
                  {widgets.Total == 0 ? "0" : widgets.Inactive}
                </h1>
                <div className="stat-percent font-bold text-success" />
                <small>
                  {__t("total")}/{__t("active")}/{__t("inactive")}
                </small>
              </div>
            </div>
          </div>

          <div className="col-lg-3">
            <div className="ibox ">
              <div className="ibox-title">
                <h5>{__t("subscription")}</h5>
              </div>
              <div className="ibox-content">
                <h1 className="no-margins">
                  {subscriptions.Total}/
                  {subscriptions.Total == 0 ? "0" : subscriptions.Active}/
                  {subscriptions.Total == 0 ? "0" : subscriptions.Inactive}
                </h1>
                <div className="stat-percent font-bold text-success" />
                <small>
                  {__t("total")}/{__t("active")}/{__t("inactive")}
                </small>
              </div>
            </div>
          </div>

          <div className="col-lg-3">
            <div className="ibox ">
              <div className="ibox-title">
                <h5>{__t("transactions")}</h5>
              </div>
              <div className="ibox-content">
                <h1 className="no-margins">
                  {transactions.Total}/
                  {transactions.Total == 0 ? "0" : transactions.Succeeded}/
                  {transactions.Total == 0 ? "0" : transactions.Failed}
                </h1>
                <div className="stat-percent font-bold text-success" />
                <small>
                  {__t("total")}/{__t("completed")}/{__t("failed")}
                </small>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-6">
            <div className="ibox float-e-margins">
              <div className="ibox-title">
                <h5>{__t("campaign")}</h5>
                <h5 style={{ float: "right" }}>
                  {__t("campaign_count")}:{this.state.camp_count}
                </h5>
              </div>
              <div className="ibox-content p0">
                <table className="table table-hover no-margins">
                  <thead>
                    <tr>
                      <th className="border_top0">{__t("campaign")}</th>
                      <th className="border_top0">{__t("clients")}</th>
                      <th className="border_top0">{__t("reseller")}</th>
                      <th className="border_top0">{__t("start_from")}</th>
                      <th className="border_top0">{__t("letter_sent")}</th>
                    </tr>
                  </thead>
                  <tbody>
                    {this.state.camp_list && this.state.camp_list.length > 0 ? (
                      this.state.camp_list.map((val, k) => {
                        // //console.log(val);
                        return (
                          <tr key={k}>
                            <td>{val.title} </td>
                            <td>{val.client_name}</td>
                            <td>
                              {val.reseller_name == null
                                ? __t("n_a")
                                : val.reseller_name}
                            </td>
                            <td>
                              <small>
                                {Moment(val.publish_on).format(
                                  "DD-MMM-YYYY HH:mm:ss"
                                )}
                              </small>
                            </td>
                            <td>{val.letter_sent}</td>
                            <td className="text-navy"> </td>
                          </tr>
                        );
                      })
                    ) : (
                      <tr>
                        <td />
                        <td>{__t("no_record_found")}</td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <div
            className="col-lg-6"
            style={{
              height: "437px",
              overflow: "hidden",
              overflowY: "auto", // Use overflowY instead of "overflow-y"
            }}
          >
            <div className="ibox float-e-margins">
              <div className="ibox-content p0">
                <table className="table table-hover">
                  <thead>
                    <tr>
                      <th
                        className="sortable"
                        onClick={(e) => {
                          this.Sort(e, "client_name");
                        }}
                      >
                        Clients
                      </th>
                      <th
                        className="sortable"
                        onClick={(e) => {
                          this.Sort(e, "reseller_name");
                        }}
                      >
                        Resellers
                      </th>

                      <th
                        className="sortable"
                        onClick={(e) => {
                          this.Sort(e, "month");
                        }}
                      >
                        Month
                      </th>
                      <th
                        className="sortable"
                        onClick={(e) => {
                          this.Sort(e, "year");
                        }}
                      >
                        Year
                      </th>
                      <th
                        className="sortable"
                        onClick={(e) => {
                          this.Sort(e, "letter_sent");
                        }}
                      >
                        Letter Sent
                      </th>
                    </tr>
                  </thead>
                  <tbody>{this.renderClientLetterCounts()}</tbody>
                </table>
              </div>
            </div>
          </div>
          <div className="col-lg-6">
            <div className="ibox float-e-margins">
              <div className="ibox-title">
                <h5 className="mr-5">{__t("reseller_overview")}</h5>
                <h5>
                  {" "}
                  {__t("total_reseller")} : {this.state.total_reseller}
                </h5>
                <h5 style={{ float: "right" }}>
                  {__t("total_revenue")} :{__currency()}
                  {this.state.total_rev}
                </h5>
              </div>

              <div className="ibox-content p0">
                <table className="table table-hover no-margins">
                  <thead>
                    <tr>
                      <th className="border_top0">{__t("reseller")}</th>
                      <th className="border_top0">{__t("clients")}</th>
                      <th className="border_top0">
                        {__t("current_month_revenus")}
                      </th>
                      <th className="border_top0">
                        {__t("current_year_revenus")}
                      </th>
                      <th className="border_top0">{__t("action")}</th>
                    </tr>
                  </thead>
                  <tbody>
                    {this.state.revenueData && this.state.revenueData.length > 0
                      ? this.state.revenueData.map((value, m) => {
                          //console.log(value)
                          return (
                            <tr
                              key={m}
                              onClick={() => {
                                this.client_details(value);
                              }}
                            >
                              <td>
                                <p>{value.name}</p>
                              </td>
                              <td>
                                <p>{value.client_count}</p>
                              </td>
                              <td>
                                <p className="">
                                  {__currency()}
                                  {value.monthly}
                                </p>
                              </td>
                              <td>
                                <p className="">
                                  {__currency()}
                                  {value.yearly}
                                </p>
                              </td>
                              <td>
                                <button className="btn btn-default btn-sm">
                                  <Link
                                    to={{
                                      pathname: `/details/${value.id}`,
                                      state: { name: `${value.name}` },
                                    }}
                                    // to={"/details/" + value.id}
                                  >
                                    {__t("view")}
                                  </Link>
                                </button>
                              </td>
                            </tr>
                          );
                        })
                      : null}
                  </tbody>
                </table>
              </div>
            </div>
            {this.pagination(
              this.state.page,
              this.state.sizePerPage,
              this.state.total,
              this.updateTablePage
            )}
          </div>
        </div>
      </div>
    );
  }

  Sort = (e, sortby) => {
    console.log(sortby);
    var $sortable = $(".sortable");
    var $this = $(e.target);
    var asc = $this.hasClass("asc");
    var desc = $this.hasClass("desc");
    $sortable.removeClass("asc").removeClass("desc");
    if (desc || (!asc && !desc)) {
      if (desc) {
        this.getLetterReport("we.created_at", "desc");
      } else {
        $this.addClass("asc");
        this.getLetterReport(sortby, "asc");
      }
    } else {
      $this.addClass("desc");
      this.getLetterReport(sortby, "desc");
    }
  };
}

export default AdminDB;
