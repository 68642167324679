import React, { Component } from "react";
import Utility from "../common/Utility";
import { __t, __currency } from "../../locale/language";
import { Redirect, Link } from "react-router-dom";
import Moment from "moment";
import $ from "jquery";
import paypal from "paypal-checkout";
import PaypalExpress from "./PaypalButton";
import axios from "axios";
import StripeButton from "./StripeButton";
import { CURRENCY } from "../Helper";
import ExpireAlert from "../common/ExpireAlert";

const fromEuroToCent = amount => amount * 100;
const successPayment = data => {
  alert("Payment Successful");
  //console.log(data);
};
const errorPayment = data => {
  alert("Payment Error");
  //console.log(data);
};
var subscription_div = "";

class ClientSubscription extends Component {
  constructor(props) {
    super(props);
    this.state = {
      redirect: false,
      client_id: localStorage.getItem("client_id"),
      token: localStorage.getItem("token"),
      user_type: Utility.getIdFromToken(localStorage.getItem("token")),
      days: "",
      title: "",
      status: "",
      subs_title: "",
      subs_price: "",
      subs_duration: "",
      id: "",
      order_id: "",
      neworder_id: "",
      subsid: "",
      feature: "",
      subscription: [],
      payer_id: "",
      payment_mode: "",
      order_id: "",
      order_plan: "",
      order_price: "",
      order_duration: "",
      client_name: "",
      transactiondetail: "",
      duration: "",
      publishableKey: "",
      // publishableKey: "pk_test_hoBeunfWK2l63s8UxnyLblYF",
      sub_id: "",
      add_data: "",
      highsetPurchase: "",
      subscription_data: "",
      tax_data: null
    };
    this.getPlanStatus = this.getPlanStatus.bind(this);
    this.getsubScriptionDetail = this.getsubScriptionDetail.bind(this);
    this.generatePdf = this.generatePdf.bind(this);
    this.insertOrderTransaction = this.insertOrderTransaction.bind(this);
    this.getTransactionDetails = this.getTransactionDetails.bind(this);
    this.getOrder = this.getOrder.bind(this);
    this.insertClient = this.insertClient.bind(this);
    this.stripe_reload = this.stripe_reload.bind(this);
  }

  getPlanStatus() {
    Utility.ExecuteData("getPlanStatus", this.state.token, {
      token: this.state.token,
      client_id: this.state.client_id
    }).then(data => {
      //console.log(data);
      localStorage.setItem("subs_id", data.data[0].subscription_id);
      var now = Moment(new Date()); //todays date
      var end = Moment(data.data[0].end_date); // another date
      var duration = Moment.duration(end.diff(now));
      var days = parseInt(duration.asDays());
      //console.log(days);

      this.setState({
        days: days,
        plan_data: data,
        title: data.data[0].title,
        status: data.data[0].status,
        start_date: data.data[0].start_date,
        end_date: data.data[0].end_date,
        sub_id: data.data[0].id
      });
    });
  }
  gethighestSubscription = () => {
    var id = this.props.userPermissions.client_id;

    Utility.ExecuteData("gethighestSubscription", this.state.token, {
      token: this.state.token,
      highsetPurchase: this.state.highsetPurchase,
      client_id: id
    }).then(data => {
      //console.log(data)
      this.setState({
        subscription_data: data.data
      })
    })
  }
  componentDidMount() {
    this.getsubScriptionDetail();
    this.getPlanStatus();
    this.getTransactionDetails();
  }
  getTransactionDetails() {
    var subs_id = this.props.userPermissions.subscription_id;
    var id = this.props.userPermissions.client_id;
    var client_name = this.props.userPermissions.first_name;
    var transactiondetail = "";
    var arr = []
    //console.log(client_name);
    Utility.ExecuteData("getTransactionDetails", this.state.token, {
      token: this.state.token,
      client_id: id
    }).then(data => {
      transactiondetail = data.data
      // transactiondetail = data.client_data
      if (data) {
        data.data.map((val, k) => {
          arr.push(val.price)
        })
      }

      var highsetPurchase = Math.max(...arr)
      //console.log(Math.max(...arr))
      this.setState({
        //transactiondetail: data.data,
        transactiondetail: transactiondetail,
        // add_data: data.add_data,
        highsetPurchase: highsetPurchase

      }, () => {
        //console.log(this.state.highsetPurchase)
        this.gethighestSubscription();
      });
    });
  }
  getsubScriptionDetail() {
    var subs_id = this.props.userPermissions.subscription_id;
    var id = this.props.userPermissions.client_id;
    var client_name = this.props.userPermissions.first_name;
    ////console.log(subs_id);
    Utility.ExecuteData("getSubscriptionDetail", this.state.token, {
      token: this.state.token,
      subsid: subs_id
    }).then(data => {
      this.setState({
        tax_data: data.tax_data,
        subs_title: data.data[0].title,
        subs_duration: data.data[0].duration,
        subs_price: data.data[0].price,
        plan_status: data.data[0].status,
        subsid: subs_id,
        id: id,
        client_name: client_name,
        duration: data.data[0].duration,
        publishableKey: data.keys[0].public_key_stripe
      });
    });
  }
  getOrder() {
    var feature = [];
    this.state.subscription.map(i => {
      this.setState({
        price: i.price,
        title: i.title
      });
      feature.push(i.feature);
    });
    this.setState({
      feature: feature
    });

    fetch(Utility.baseUrlBe() + "getorder", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json"
      },
      body: JSON.stringify(this.state)
    })
      .then(response => response.json())
      .then(responseJson => {
        if (responseJson.code === 200) {
          this.setState({
            neworder_id: responseJson.id
          });
        } else if (responseJson.code === 204) {
        }
      })
      .catch(error => {
        this.setState({ errors: true });
      });
  }

  generatePdf(order) {
    var client_name = this.props.userPermissions.first_name;
    //console.log(this.state.transactiondetail)
    //console.log(order)

    let address = "";
    // return false
    this.state.transactiondetail.map((val, k) => {
      //console.log(val.address)
      address = val.address
    })
    // return false
    if (address != "") {
      Utility.ExecuteData("generatePdf", this.state.token, {
        token: this.state.token,
        user_type: this.state.user_type,
        order_id: order.id,
        payment_mode: order.payment_mode ? order.payment_mode : __t("cash"),
        // order_plan: order.title,
        // order_price: order.price,
        // order_duration: order.duration,
        client_name: client_name,
        address: address,
        pdf_type: order.payment_mode ? "transaction" : "cash",
        client_subs_id: order.id,
        client_id: this.state.client_id
      }).then(data => {
        if (data.code == 200) {
          //console.log(data);
          var file = data.data;
          var url = ""
          if (data.type == "true") {
            url = data.data
          }
          else {
            url = Utility.uploadUrl2() + data.data;
          }
          
          var file = data.data;
          window.open(url, "_blank");
          return false;
        }
        else {
          $("#msg_text").text(__t("please_contact_to_admin"));
          $("#alertinfo").show();
        }

      });
    }
    else {
      $("#msg_text").text(__t("please_provide_your_address"));
      $("#alertinfo").show();
      this.setState({ redirect: true })
    }
    // return false

  }

  insertOrderTransaction() {
    fetch(Utility.baseUrlBe() + "TransactionforCancel", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json"
      },
      body: JSON.stringify(this.state)
    })
      .then(response => response.json())
      .then(responseJson => {
        //console.log(responseJson);
        if (responseJson.code === 200) {
          this.setState({
            showsuccess: true,
            trans_title: "Your transaction has been cancelled!",
            alert_messege: "please try again."
          });
        } else if (responseJson.code === 204) {
        }
      })
      .catch(error => {
        this.setState({ errors: true });
      });
  }
  stripe_reload() {
    //console.log("dfvhbjh");

    window.location.reload();
  }
  insertClient(status) {
    //console.log(this.state.duration);

    fetch(Utility.baseUrlBe() + status, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json"
      },
      body: JSON.stringify(this.state)
    })
      .then(response => response.json())
      .then(responseJson => {
        if (responseJson.code === 200) {
          this.setState(
            {
              showsuccess: true,
              trans_title: "Your transaction has been Successful.",
              // alert_messege:"please go to admin panel.",
              transaction_key: true
            },
            () => {
              window.location.reload();
            }
          );
        } else if (responseJson.code == 204) {
        }
      })
      .catch(error => {
        this.setState({ errors: true });
      });
  }
  render() {
    // //console.log(getOrder)
    // //console.log(this.state.id)
    var upgrade_subs_id = ""
    const { redirect } = this.state;
    if (redirect) {
      return <Redirect push to="/settings/" />;
    }
    subscription_div = (
      <div className={"alert alert-info"} id="sub_alert">
        {__t("current_plan_is")}{" "}
        <strong>
          {this.state.title} {__t("plan")}{" "}
        </strong>
        {__t("and_will_expire_after")} {""}
        <strong>
          {this.state.days != "NaN" ? this.state.days : "00"} {__t("days")}
        </strong>{" "}
        .
      </div>
    );
    var price;
    // //console.log(this.state.subsid);
    const onSuccess = payment => {
      //console.log("The payment was succeeded!", payment);
      // return false
      this.insertClient("insertClientSubscription");
    };
    const onCancel = data => {
      ////console.log("The payment was cancelled!", data);
      this.setState({
        payment: data,
        payment_id: data.paymentID,
        payment_token: data.paymentToken,
        payment_status: "Cancel"
      });
      this.insertOrderTransaction();
    };
    const onError = err => {
      //console.log("Error!", err);
      this.setState({
        payment: data,
        payment_id: data.paymentID,
        payment_token: data.paymentToken,
        payment_status: "Failed"
      });
      this.insertOrderTransaction();
    };
    let env = "sandbox";
    // //console.log(this.state.neworder_id)
    const data = {
      userid: this.state.id,
      orderid: this.state.neworder_id,
      subsid: this.state.subsid,
      status: "started",

      subscription_list: this.state.subscription
    };
    ////console.log(data)

    const { showButton } = this.state;

    const payment = async () => {
      let actkn = localStorage.getItem("token");
      let res = await Utility.executeRequest(
        "createPayment",
        { subsid: this.state.data.subsid },
        actkn
      );
      return res.id;
    };

    const payment_paypal = async () => {
      //console.log(upgrade_subs_id)
      //return false
      let actkn = localStorage.getItem("token");
      let res = await Utility.executeRequest(
        "createPayment",
        { subsid: upgrade_subs_id },
        actkn
      );
      return res.id;
    };

    const onAuthorize_paypal = async (paypal_res, actions) => {
      paypal_res.subs_id = upgrade_subs_id;
      paypal_res["subs_id"] = upgrade_subs_id;
      let actkn = localStorage.getItem("token");
      let res = await Utility.executeRequest(
        "executePayment",
        paypal_res,
        actkn
      );
      this.onSuccess(res);
    };



    const onAuthorize = async (paypal_res, actions) => {
      paypal_res.subs_id = this.state.data.subsid;
      paypal_res["subs_id"] = this.state.data.subsid;
      let actkn = localStorage.getItem("token");
      let res = await Utility.executeRequest(
        "executePayment",
        paypal_res,
        actkn
      );
      this.onSuccess(res);
    };
    // const CURRENCY = "INR";

    //const fromEuroToCent = amount => amount * 100;

    var CREATEStripe_URL = Utility.baseUrlBe() + "createStripePayment";

    const successPayment = data => {
      alert("Payment Successful");
      //console.log(data);
    };

    const errorPayment = data => {
      alert("Payment Error");
      //console.log(data);
    };

    const onToken = (amount, description) => token =>
      axios
        .post(CREATEStripe_URL, {
          description,
          source: token.id,
          currency: CURRENCY,
          //amount: fromEuroToCent(amount)
          amount: amount,
          userid: this.state.id,
          orderid: "41",
          subsid: this.state.subsid,
          status: "started",

          subscription_list: this.state.subscription
        })
        .then(successPayment)
        .catch(errorPayment);

    // //console.log(this.props.userPermissions.subscription_id);
    var publishkey;
    if (this.state.publishableKey != "undefined") {
      // //console.log(this.state.publishableKey);
      publishkey = this.state.publishableKey;
    }
    //console.log(this.state.subscription_data)
    return (
      <div>
        {this.state.plan_data && <ExpireAlert plan_data={this.state.plan_data} />}
        {/* {this.state.status && this.state.status == "active"
              ? subscription_div
              : ""} */}
        <div className="row">

          {this.props.userPermissions.subscription_status != "expire" ? (
            // this.state.status && this.state.status == "active" ?
            <div className="col-lg-12">
              <div className="ibox float-e-margins">
                <div className="ibox-title">
                  <h5>{__t("transaction")}</h5>
                </div>
                <div className="ibox-content">
                  <table className="table table-hover no-margins">
                    <thead>
                      <tr>
                        {/* <th>{__t("order_id")}</th> */}
                        <th>{__t("subscription_plan")}</th>
                        <th>{__t("subscription_start")}</th>
                        <th>{__t("subscription_end")}</th>
                        <th>{__t("duration")}</th>
                        <th>{__t("payment_mode")}</th>
                        <th>{__t("price")}</th>
                        <th>{__t("download_reciept")}</th>
                      </tr>
                    </thead>
                    <tbody>
                      {this.state.transactiondetail
                        ? this.state.transactiondetail.map(m => {
                          return (
                            <tr key={m.id}>
                              {/* <td>{m.id}</td> */}
                              <td>{m.title}</td>
                              <td>
                                {m.start_date ? Moment(m.start_date).format('DD-MMM-YYYY h:mm a') : ""}
                              </td>
                              <td>
                                {m.end_date ? Moment(m.end_date).format('DD-MMM-YYYY h:mm a') : ""}
                              </td>
                              <td>
                                {m.duration} {__t("days")}
                              </td>
                              <td>{m.payment_mode ? m.payment_mode : __t("cash")}</td>
                              <td>
                                {__currency()}
                                {m.subscription_price}
                              </td>
                              <td>
                                <button
                                  className="btn-pdf btn-sm"
                                  onClick={() => {
                                    this.generatePdf(m);
                                  }}
                                >
                                  <i
                                    className="fa fa-file-pdf-o mr-2 pdf_ico"
                                    aria-hidden="true"
                                  />
                                  {__t("download_pdf")}
                                </button>
                              </td>
                            </tr>
                          );
                        })
                        : ""}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          ) : (
              <div className="col-lg-12">
                <div className="ibox float-e-margins">
                  <div className="ibox-title">
                    <h5>{__t("subscription_plan")}</h5>
                  </div>
                  <div className="ibox-content">
                    <table className="table table-hover no-margins">
                      <thead>
                        <tr>
                          <th>{__t("title")}</th>
                          {/* <th>{__t("subscription_start")}</th> */}
                          {/* <th>{__t("subscription_end")}</th> */}
                          <th>{__t("duration")}</th>
                          <th>{__t("price")}</th>
                          <th>{__t("total_amount")}</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>{this.state.subs_title}</td>
                          {/* <td>
                            {this.state.start_date ? Moment(this.state.start_date).format('DD-MMM-YYYY h:mm a') : ""}
                          </td>
                          <td>
                            {this.state.end_date ? Moment(this.state.end_date).format('DD-MMM-YYYY h:mm a') : ""}
                          </td> */}
                          <td>
                            {this.state.subs_duration} {__t("days")}
                          </td>
                          <td>
                            {__currency()}
                            {this.state.subs_price}
                          </td>
                          {this.state.subs_price ?
                            <td>
                              {__currency()}
                              {(this.state.subs_price + this.state.subs_price * (this.state.tax_data ? this.state.tax_data.tax_rate : 0) / 100).toFixed(2)}
                            </td>
                            : <td></td>
                          }
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <div className="flex_all_center mt-2">
                    <PaypalExpress
                      env={env}
                      onSuccess={onSuccess}
                      payment={payment}
                      commit={true}
                      onAuthorize={onAuthorize}
                      onError={onError}
                      onCancel={onCancel}
                    />
                      &nbsp;&nbsp;
                    {this.state.plan_status === 'active' ?
                      <StripeButton
                        subsid={this.state.subsid}
                        client_id={this.state.client_id}
                        token={this.state.token}
                        tax_data={this.state.tax_data}
                        amount={this.state.subs_price}
                        publishableKey={publishkey}
                        stripe_reload={this.stripe_reload}
                      />
                      : <>
                        <strong style={{ color: "red" }}>{__t("plan_discontinued")}</strong>
                      </>
                    }
                  </div>
                </div>
              </div>
            )}

          {this.state.subscription_data && this.state.subscription_data.length > 0 ?
            <div className="col-lg-12">
              <div className="ibox float-e-margins">
                <div className="ibox-title">
                  <h5>{__t("upgrade_plan")}</h5>
                </div>
                <div className="ibox-content">
                  <table className="table table-hover no-margins">
                    <thead>
                      <tr>
                        {/* <th>{__t("order_id")}</th> */}
                        <th>{__t("subscription_plan")}</th>
                        <th>{__t("duration")}</th>

                        <th>{__t("price")}</th>
                        <th>{__t("total_amount")}</th>
                        <th>{__t("upgrade_plan")}</th>

                      </tr>
                    </thead>
                    <tbody>
                      {this.state.subscription_data
                        ? this.state.subscription_data.map(m => {
                          upgrade_subs_id = m.id
                          return (

                            <tr key={m.id}>
                              {/* <td>{m.id}</td> */}
                              <td>{m.title}</td>
                              <td>
                                {m.duration} {__t("days")}
                              </td>

                              <td>
                                {__currency()}
                                {m.price}
                              </td>
                              <td>
                                {__currency()}
                                {(m.price + m.price * (this.state.tax_data ? this.state.tax_data.tax_rate : 0) / 100).toFixed(2)}
                              </td>
                              {/* <button>{__t("upgrade")}</button> */}

                              {/* <PaypalExpress
                                    env={env}
                                    onSuccess={onSuccess}
                                    payment={payment_paypal}
                                    commit={true}
                                    onAuthorize={onAuthorize_paypal}
                                    onError={onError}
                                    onCancel={onCancel}
                                  /> */}
                              <td>
                                <StripeButton
                                  subsid={m.id}
                                  client_id={this.state.client_id}
                                  token={this.state.token}
                                  tax_data={this.state.tax_data}
                                  amount={m.price}
                                  publishableKey={publishkey}
                                  stripe_reload={this.stripe_reload}
                                />
                              </td>
                            </tr>
                          );
                        })
                        : ""}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            : ""
          }
        </div>
      </div>
    );
  }
}

export default ClientSubscription;
