import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import { ListGroup, ListGroupItem, Checkbox } from "react-bootstrap";
import { Link } from "react-router-dom";
import Utility from "../common/Utility";
import { __t } from "../../locale/language";
import ReactQuill from "react-quill";
import Can from "../common/AccessControl";
import $ from "jquery";
class EmailTemplateUpdate extends Component {
  constructor(props) {
    super(props);
    this.state = {
      redirect: false,
      title: "",
      errors: {},
      subject: "",
      cc: [],
      bcc: [],
      reply_to: "",
      message: `<p>{TitleAbbEng} {ParlFirstName} {ParlLastName}</p>
      <br /><p>House of Commons<br />Ottawa, Ontario<br />K-1A 0A6</p><br/>
      <p>{SalutationLtrE} {SalutationEng} {ParlLastName},</p><br/>
      <p>Letter content here</p><br/>
      <p>{name}<br />{address}<br />{email}</p>`,
      client_id: localStorage.getItem("client_id"),
      status: "",
      token: localStorage.getItem("token"),
      mail_send_to: "",
      email_type: "widget",
      user_type: this.props.userPermissions.user_type,
    };
    this.testmail = this.testmail.bind(this);
  }
  componentDidMount() {
    if (
      typeof this.props.location.state != "undefined" &&
      this.props.location.state.id
    ) {
      $("#title").text(__t("edit_email_template"));
    }
    if (
      this.props.location.state &&
      typeof this.props.location.state !== "undefined"
    ) {
      this.findemailtemplate();
    }
  }

  findemailtemplate = () => {
    var id = this.props.location.state.id;
    Utility.ExecuteData("findemailtemplate", this.token, { id: id }).then(
      data => {
        console.log(data.data.message);
        this.setState({
          title: data.data.title,
          subject: data.data.subject,
          cc: data.data.cc !== "" ? JSON.parse(data.data.cc) : this.state.cc,
          bcc:
            data.data.bcc !== "" ? JSON.parse(data.data.bcc) : this.state.bcc,
          message: data.data.message,
          reply_to: data.data.reply_to,
          mail_send_to: data.data.to,
          status: data.data.status
          // email_type: data.data.email_type
        });
      }
    );
  };
  handleValidation = () => {
    let fields = this.state;
    let errors = {};
    let formIsValid = true;
    if (!fields["title"]) {
      formIsValid = false;
      errors["title"] = __t("select_title");
    }
    if (!fields["subject"]) {
      formIsValid = false;
      errors["subject"] = __t("subject");
    }
    if (!fields["status"] || fields["status"] == "select") {
      formIsValid = false;
      errors["status"] = __t("select_status");
    }

    if (!fields["message"]) {
      formIsValid = false;
      errors["message"] = __t("enter_msg_here");
    }
    // if (!fields["email_type"]) {
    //   formIsValid = false;
    //   errors["email_type"] = __t("email_type");
    // }
    if (fields["cc"]) {
      fields["cc"].map(v => {
        if (v.name == "" || v.email == "") {
          formIsValid = false;
          errors["cc"] = __t("name_or_mail_cannot_be_empty");
        } else {
          let lastAtPos = v.email.lastIndexOf("@");
          let lastDotPos = v.email.lastIndexOf(".");

          if (
            !(
              lastAtPos < lastDotPos &&
              lastAtPos > 0 &&
              v.email.indexOf("@@") == -1 &&
              lastDotPos > 2 &&
              v.email.length - lastDotPos > 2
            )
          ) {
            formIsValid = false;
            errors["cc"] = __t("invalid_email");
          }
        }
      });
    }
    if (fields["bcc"]) {
      fields["bcc"].map(v => {
        if (v.name == "" || v.email == "") {
          formIsValid = false;
          errors["bcc"] = __t("name_or_mail_cannot_be_empty");
        } else {
          let lastAtPos = v.email.lastIndexOf("@");
          let lastDotPos = v.email.lastIndexOf(".");

          if (
            !(
              lastAtPos < lastDotPos &&
              lastAtPos > 0 &&
              v.email.indexOf("@@") == -1 &&
              lastDotPos > 2 &&
              v.email.length - lastDotPos > 2
            )
          ) {
            formIsValid = false;
            errors["cc"] = __t("invalid_email");
          }
        }
      });
    }
    this.setState({ errors: errors });
    return formIsValid;
  };
  handleChange = event => {
    this.setState({ [event.target.name]: event.target.value });
  };

  editorChange = value => {
    this.setState({ message: value });
  };
  create = () => {
    if (this.handleValidation()) {
      if (
        this.props.location.state &&
        typeof this.props.location.state !== "undefined"
      ) {
        this.setState({ id: this.props.location.state.id }, () => {
          Utility.ExecuteData("updateemailtmpl", this.token, {
            id: this.state.id,
            title: this.state.title,
            subject: this.state.subject,
            mail_send_to: this.state.mail_send_to,
            cc: JSON.stringify(this.state.cc),
            bcc: JSON.stringify(this.state.bcc),
            email_type: this.state.email_type,
            message: this.state.message,
            reply_to: this.state.reply_to,
            status: this.state.status
          })
            .then(responseJson => {
              if (responseJson.code === 200) {
                this.setState({ redirect: true });
              } else {
                this.setState({ errors: true });
              }
            })
            .catch(error => {
              this.setState({ errors: true });
            });
        });
      } else {
        Utility.ExecuteData("createemailtmpl", this.token, {
          title: this.state.title,
          subject: this.state.subject,
          mail_send_to: this.state.mail_send_to,
          cc: JSON.stringify(this.state.cc),
          bcc: JSON.stringify(this.state.bcc),
          email_type: this.state.email_type,
          message: this.state.message,
          reply_to: this.state.reply_to,
          status: this.state.status
        })
          .then(responseJson => {
            if (responseJson.code === 200) {
              this.setState({ redirect: true });
            } else {
              let errors = {};
              errors["title"] = __t("email_title_already_exist");
              this.setState({ errors: errors });
            }
          })
          .catch(error => {
            this.setState({ errors: true });
          });
      }
    }
  };
  testmail() {
    if (this.state.mail_send_to) {
      Utility.ExecuteData("testmail", this.token, {
        subject: this.state.subject,
        message: "some message",
        client_id: this.state.client_id,
        mail_send_to: this.state.mail_send_to
      }).then(result => {
        if (result.data.email_status == true) {
          $("#msg_text").text(__t("mail_successfully_sent"));
          $("#alertinfo").show();
        } else if (result.data.email_status == false) {
          $("#msg_text").text(__t("invalid_smtp_configurations"));
          $("#alertinfo").show();
        }
        this.setState({
          testmail_status: result.data
        });
      });
    } else {
      $("#msg_text").text(__t("please_enter_a_test_mail"));
      $("#alertinfo").show();
      return;
    }
  }
  addCc = (e, k, type) => {
    if (type == "cc") {
      let cc = this.state.cc;
      let obj = cc[k];
      obj[e.target.name] = e.target.value;
      cc[k] = obj;
      this.setState({ cc: cc });
    } else if (type == "bcc") {
      let bcc = this.state.bcc;
      let obj = bcc[k];
      obj[e.target.name] = e.target.value;
      bcc[k] = obj;
      this.setState({ bcc: bcc });
    }
  };
  addMore = type => {
    if (type == "cc") {
      let cc = this.state.cc;
      cc.push({ name: "", email: "" });
      this.setState({ cc: cc });
    } else if (type == "bcc") {
      let bcc = this.state.bcc;
      bcc.push({ name: "", email: "" });
      this.setState({ bcc: bcc });
    }
  };
  removeCc = (k, type) => {
    if (type == "cc") {
      let cc = this.state.cc;
      cc.splice(k, 1);
      this.setState({ cc: cc });
    } else if (type == "bcc") {
      let bcc = this.state.bcc;
      bcc.splice(k, 1);
      this.setState({ bcc: bcc });
    }
  };
  token = localStorage.getItem("token");
  client_id = localStorage.getItem("client_id");
  render() {
    const { redirect } = this.state;
    if (redirect) {
      return <Redirect push to="/email-template/" />;
    }
    return (
      <Can
        I={["update-email-tmpl"]}
        userPermissions={this.props.userPermissions.userPermissions}
      >
        <div>
          <div className="row wrapper border-bottom white-bg page-heading">
            <div className="col-lg-10">
              <h2 style={{ marginTop: 10 }}>{__t("email_template")}</h2>
              <ol className="breadcrumb">
                <li className="breadcrumb-item">
                  <Link to="/">{__t("dashboard")}</Link>
                </li>
                <li className="breadcrumb-item">
                  <Link to="/email-template">{__t("email_template")}</Link>
                </li>
                <li className="breadcrumb-item active">
                  <strong id="title">{__t("create_email_template")}</strong>
                </li>
              </ol>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12">
              <div className="ibox float-e-margins">
                <div className="ibox-title">
                  <h5>{__t("email_template")}</h5>
                </div>
                <div className="ibox-content">
                  <div className="form-horizontal">
                    <div className="row form-group">
                      <div className="col-md-6 col-sm-6 col-xs-12">
                        <label className="control-label required-field">
                          {__t("title")}
                        </label>
                        {this.props.path == "create" ? (
                          <input
                            type="text"
                            name="title"
                            value={this.state.title}
                            className="form-control"
                            placeholder={__t("title")}
                            onChange={this.handleChange}
                          />
                        ) : (
                            <input
                              type="text"
                              name="title"
                              className="form-control"
                              placeholder={__t("title")}
                              value={this.state.title}
                              onChange={this.handleChange}
                              readOnly
                            />
                          )}
                        <label id="-error" className="error">
                          {this.state.errors["title"]}
                        </label>
                      </div>
                      <div className="col-md-6 col-sm-6 col-xs-12">
                        <label className="control-label required-field">
                          {__t("subject")}
                        </label>
                        <input
                          type="text"
                          name="subject"
                          value={this.state.subject}
                          className="form-control"
                          placeholder={__t("enter_sub")}
                          onChange={this.handleChange}
                        />
                        <label id="-error" className="error">
                          {this.state.errors["subject"]}
                        </label>
                      </div>
                    </div>
                    <div className="row form-group">
                      <div className="col-md-12 col-sm-12 col-xs-12">
                        <label className="control-label required-field">
                          {__t("message")}
                        </label>
                        <ReactQuill
                          value={this.state.message}
                          onChange={this.editorChange}
                        />
                        <label id="-error" className="error">
                          {this.state.errors["message"]}
                        </label>
                      </div>
                    </div>
                    <div className="row form-group">
                      <div className="col-md-6 col-sm-6 col-xs-12">
                        {this.state.cc.map((v, k) => {
                          return (
                            <div className="row col-md-12" key={k}>
                              <div className="col-1 align_center">
                                <label className="control-label ">
                                  {__t("cc")}
                                </label>
                              </div>
                              <div className="col-4">
                                <input
                                  type="text"
                                  name={"name"}
                                  value={v.name}
                                  className="col-md-12 form-control mr-2"
                                  placeholder={__t("name")}
                                  onChange={e => {
                                    this.addCc(e, k, "cc");
                                  }}
                                />
                              </div>
                              <div className="col-5">
                                <input
                                  type="text"
                                  name={"email"}
                                  value={v.email}
                                  className="col-md-12 form-control"
                                  placeholder={__t("enter_email")}
                                  onChange={e => {
                                    this.addCc(e, k, "cc");
                                  }}
                                  onKeyUp={e => {
                                    let val = e.target.value;
                                    let lastAtPos = val.lastIndexOf("@");
                                    let lastDotPos = val.lastIndexOf(".");

                                    if (
                                      !(
                                        lastAtPos < lastDotPos &&
                                        lastAtPos > 0 &&
                                        val.indexOf("@@") == -1 &&
                                        lastDotPos > 2 &&
                                        val.length - lastDotPos > 2
                                      )
                                    ) {
                                      e.target.style.border =
                                        "1px solid rgb(245, 40, 40)";
                                    } else {
                                      e.target.style.border =
                                        "1px solid #e5e6e7";
                                    }
                                  }}
                                />
                              </div>
                              <div className="col-2">
                                <button
                                  className="btn btn-small"
                                  onClick={() => {
                                    this.removeCc(k, "cc");
                                  }}
                                >
                                  <i className="fa fa-trash" aria-hidden="true" />
                                </button>
                              </div>
                            </div>
                          );
                        })}
                        <button
                          className="btn btn-small ml-2"
                          onClick={() => {
                            this.addMore("cc");
                          }}
                        >
                          <i className="fa fa-plus mr-2" aria-hidden="true" />
                          CC
                        </button>
                        <label id="-error" className="error">
                          {this.state.errors["cc"]}
                        </label>
                      </div>
                      <div className="col-md-6 col-sm-6 col-xs-12">
                        {this.state.bcc.map((v, k) => {
                          return (
                            <div className="row col-md-12" key={k}>
                              <div className="col-1 align_center">
                                <label className="control-label">
                                  {__t("bcc")}
                                </label>
                              </div>
                              <div className="col-4">
                                <input
                                  type="text"
                                  name={"name"}
                                  value={v.name}
                                  className="col-md-12 form-control"
                                  placeholder={__t("name")}
                                  onChange={e => {
                                    this.addCc(e, k, "bcc");
                                  }}
                                />
                              </div>
                              <div className="col-5">
                                <input
                                  type="text"
                                  name={"email"}
                                  value={v.email}
                                  className="col-md-12 form-control"
                                  placeholder={__t("enter_email")}
                                  onChange={e => {
                                    this.addCc(e, k, "bcc");
                                  }}
                                  onKeyUp={e => {
                                    let val = e.target.value;
                                    let lastAtPos = val.lastIndexOf("@");
                                    let lastDotPos = val.lastIndexOf(".");

                                    if (
                                      !(
                                        lastAtPos < lastDotPos &&
                                        lastAtPos > 0 &&
                                        val.indexOf("@@") == -1 &&
                                        lastDotPos > 2 &&
                                        val.length - lastDotPos > 2
                                      )
                                    ) {
                                      e.target.style.border =
                                        "1px solid rgb(245, 40, 40)";
                                    } else {
                                      e.target.style.border =
                                        "1px solid #e5e6e7";
                                    }
                                  }}
                                />
                              </div>
                              <div className="col-2">
                                <button
                                  className="btn btn-small"
                                  onClick={() => {
                                    this.removeCc(k, "bcc");
                                  }}
                                >
                                  <i className="fa fa-trash " aria-hidden="true" />
                                </button>
                              </div>
                            </div>
                          );
                        })}
                        <button
                          className="btn btn-small"
                          onClick={() => {
                            this.addMore("bcc");
                          }}
                        >
                          <i className=
                            "fa fa-plus mr-2" aria-hidden="true" />
                          BCC
                        </button>
                        <label id="-error" className="error">
                          {this.state.errors["bcc"]}
                        </label>
                      </div>
                    </div>
                    <div className="row form-group">

                      {
                        this.state.user_type == "admin" ?
                          <div className="col-md-6 col-sm-6 col-xs-12">
                            <label className="control-label">
                              {__t("reply_to")}
                            </label>
                            <input
                              type="text"
                              name="reply_to"
                              value={this.state.reply_to}
                              className="form-control"
                              placeholder={__t("reply_to")}
                              onChange={this.handleChange}
                            />
                            <label id="-error" className="error">
                              {this.state.errors["reply_to"]}
                            </label>
                          </div> : ""
                      }



                      <div className="col-md-6 col-sm-6 col-xs-12">
                        <label className="control-label required-field">
                          {__t("status")}
                        </label>
                        {this.state.email_type == "Default" ? (
                          <input
                            name="status"
                            className="form-control"
                            value={this.state.status}
                            readOnly
                          />
                        ) : (
                            <select
                              name="status"
                              className="form-control"
                              value={this.state.status}
                              onChange={this.handleChange}
                            >
                              <option value="select">--{__t("select_status")}--</option>
                              <option value="active">{__t("active")}</option>
                              <option value="inactive">{__t("inactive")}</option>
                            </select>
                          )}
                        <label id="-error" className="error">
                          {this.state.errors["status"]}
                        </label>
                      </div>
                    </div>
                    {/* <div className="row form-group">
                      <div className="col-md-6 col-sm-6 col-xs-12">
                        <label className="control-label required-field">
                          {" "}
                          {__t("type")}
                        </label>
                        <select
                          name="email_type"
                          className="form-control"
                          value={this.state.email_type}
                          onChange={this.handleChange}
                        >
                          <option>--{__t("select_type")}--</option>
                          <option value="widget">{__t("widget")}</option>
                          <option value="offer">{__t("offer")}</option>
                        </select>
                        <label id="-error" className="error">
                          {this.state.errors["email_type"]}
                        </label>
                      </div>
                    </div> */}


                    {/* send test mail */}

                    {/* <div className="row form-group">
                      <div className="col-md-6 col-sm-6 col-xs-12">
                        <label className="control-label">
                          {__t("test_mail_msg")}
                        </label>
                        <input
                          type="text"
                          name="mail_send_to"
                          value={this.state.mail_send_to}
                          className="form-control"
                          placeholder={__t("enter_email")}
                          onChange={this.handleChange}
                        />
                      </div>
                      <div className="col-md-6 col-sm-6 col-xs-12">
                        <button
                          style={{ marginTop: "24px" }}
                          onClick={this.testmail}
                          className="btn btn-primary"
                        >
                          {__t("send")}
                        </button>
                      </div>
                    </div> */}



                    <div className="row form-group">
                      <div className="col-md-12">
                        <button
                          className="btn btn-primary"
                          onClick={this.create}
                          type="submit"
                        >
                          {this.props.location.state &&
                            typeof this.props.location.state !== "undefined"
                            ? "Update"
                            : "Create"}
                        </button>{" "}
                        &nbsp;&nbsp;
                        <Link className="btn btn-white" to="/email-template">
                          {__t("cancel")}
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Can>
    );
  }
}

export default EmailTemplateUpdate;
