import React from "react";
import { Link } from "react-router-dom";
import Table from "../common/Table";
import Utility from "../common/Utility";
import { __t } from "../../locale/language";
import Can from "../common/AccessControl";
import Moment from "moment";
import { Route, Switch } from "react-router-dom";
import Alertyesno from "../common/Alertyesno";
import Alertinfo from "../common/Alertinfo";
import AlertDelete from "../common/AlertDelete";

class Index extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      tableData: [],
      token: localStorage.getItem("token"),
      columns: ["file_name", "created_on", "Download"]
    };
    this.updateBackupsList = this.updateBackupsList.bind(this);
    this.createTable = this.createTable.bind(this);
  }
  updateBackupsList() {
    Utility.ExecuteData("backupsdata", this.token, {}).then(data => {
      //console.log(data);
      this.setState({ tableData: data.data });
    });
  }
  saveFile() {
    //console.log("url");
  }
  componentDidMount() {
    this.updateBackupsList();
  }
  createBackups = () => {
    Utility.ExecuteData("createbackup", this.token, {}).then(data => {
      if (data.code === 200) {
        //console.log("successfully created");
        this.updateBackupsList();
      }
      //console.log(data);
    });
  };
  createTable() {
    // Data

    var dataColumns = this.state.columns;
    var dataRows = this.state.tableData;
    //console.log(this.state.tableData);
    //console.log(dataRows);
    if (this.state.tableData) {
      var tableHeaders = (
        <thead>
          <tr>
            {dataColumns.map(function(column) {
              return <th>{__t(column)}</th>;
            })}
          </tr>
        </thead>
      );

      var tableBody = dataRows.map(function(row) {
        return (
          <tbody>
            <tr>
              {dataColumns.map(function(column) {
                if (column == "Download") {
                  return (
                    <td>
                      <a
                        className="btn btn-primary"
                        href={row["file_url"]}
                        target="blank"
                      >
                        {__t("download")}
                      </a>
                    </td>
                  );
                }
                if (column == "created_on") {
                  return (
                    <td>
                      {Moment(row["created_on"]).format("DD-MMM-YYYY HH:mm:ss")}
                    </td>
                  );
                }
                return <td>{row[column]}</td>;
              })}
            </tr>
          </tbody>
        );
      });

      // Decorate with Bootstrap CSS
      return (
        <div className="react-bootstrap-table">
          <table className="table table-hover table-bordered table-condensed">
            {tableHeaders}
            {tableBody}
          </table>
        </div>
      );
    }
  }
  token = localStorage.getItem("token");
  render() {
    return (
      <Can
        I={["update-user"]}
        userPermissions={this.props.userPermissions.userPermissions}
      >
        <div>
          <div className="row wrapper border-bottom white-bg page-heading">
            <div className="col-lg-10">
              <h2 style={{ marginTop: 10 }}>{__t("db_backups")}</h2>
              <ol className="breadcrumb">
                <li className="breadcrumb-item">
                  <Link to="/">{__t("dashboard")}</Link>
                </li>
                <li className="breadcrumb-item active">
                  <strong>{__t("create_db_backup")}</strong>
                </li>
              </ol>
            </div>
          </div>
          <div className="wrapper wrapper-content animated fadeInRight">
            <div className="row">
              <div className="col-lg-12 ">
                <div className="ibox ">
                  <div className="ibox-title">
                    <div className="row from-control">
                      &nbsp; &nbsp;
                      <input
                        className="btn btn-primary"
                        type="button"
                        onClick={() => {
                          this.createBackups();
                        }}
                        value={__t("create_db_backup")}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="ibox-content">{this.createTable()}</div>
          </div>
        </div>
      </Can>
    );
  }
}

export default Index;
