import React from "react";
import TransactionList from "./TransactionList";
import { Route, Switch } from "react-router-dom";

class Index extends React.Component {
  constructor(props) {
    super(props);
  }
  render() {
    return (
      <Switch>       
        <Route
          path="/transaction"
          render={props => (
            <TransactionList
              {...props}
              userPermissions={this.props.userPermissions}
            />
          )}
        />
        
      </Switch>
    );
  }
}

export default Index;
