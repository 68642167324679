import React, { Component } from "react";
import grapesjs from "grapesjs";
import Utility from "../common/Utility";
import { Link } from "react-router-dom";
import AlertSuccess from "../common/Alertsuccess";
import { __t } from "../../locale/language";
import "grapesjs-blocks-basic";
import "grapesjs-blocks-flexbox";
import $ from "jquery";
import Axios from "axios";
import SelectImageModal from "../client/mediaModal";
const BASE_URL = Utility.baseUrlBe() + "assetscreate";

var imageArray;
var image_array = [];
class Preview extends Component {
  constructor(props) {
    super(props);
    this.editor = null;
    this.token = localStorage.getItem("token");
    this.client_id = localStorage.getItem("client_id");
    this.state = {
      template: "",
      token: localStorage.getItem("token"),
      client_id: localStorage.getItem("client_id"),
      redirect: false,
      title: "",
      message: "",
      result: "",
      user_id: "",
      tem_client_id: "",
      tem_user_id: "",
      price: "",
      type: "",
      image_array: [],
      uploadedImage: "",
      thumb: "",
      file: "",
      temp_type: "",
      edititem: {},
      mediaModal: false,
      user_type: this.props.userPermissions.user_type ? this.props.userPermissions.user_type : "",
      temp_id: this.props.match.params.id
    };
    this.ok = this.ok.bind(this);
    this.getprice = this.getprice.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.SaveTemplate = this.SaveTemplate.bind(this);
    this.onChange = this.onChange.bind(this);
    this.onFormSubmit = this.onFormSubmit.bind(this);
    this.setImageUrl = this.setImageUrl.bind(this);
  }
  setImageUrl(url) {
    let editItem = this.state.edititem;
    editItem["url"] = url;
    this.setState({
      edititem: editItem,
      thumb: url
    });
  }
  getThumb(img) {
    var file_ext = img.ext;
    var ext = img.name.split(".");
    ext = ext[ext.length - 1];
    //  var img_name_ext = img.substr(0, img.lastIndexOf("."));
    var thumb_img = Utility.getThumbimage(img.name, ext);
    return thumb_img;
  }
  componentDidMount() {
    ////console.log(this.state.temp_id);
    var ref = this;
    Utility.ExecuteData("template/" + this.props.match.params.id, this.token, {
      client_id: this.client_id,
      user_type: ref.state.user_type
    }).then(result => {
      var arr;
      if (result.code === 200) {
        Utility.ExecuteData("getImageAssets", this.state.token, {
          limit: 20,
          client_id: this.client_id,
          token: this.state.token
        }).then(data => {
          //if (data.data.length != 0) {
          imageArray = data.data;
          //if (data.code == "200") {
          imageArray.map(i => {
            var img = this.getThumb(i);
            image_array.push({ src: i.url });
          });

          arr = image_array.join();
          // //console.log(image_array);
          const previewItem = result.data[0].template;
          var temp = previewItem;
          if (ref.state.user_type == "client") {
            let client_data = result.c_data[0];
            temp = temp.replace("{client_title}", client_data["title"]);
            temp = temp.replace('<img src="http://admin.ppca.opalina.in/upload/1559125847791_logo_placeholder.png" class="c56959"/>', '<img src="' + client_data["logo"] + '" class="c56959"/>');
            temp = temp.replace("{short_description}", client_data["short_description"]);
            temp = temp.replace("{client_name}", client_data["name"]);
            temp = temp.replace("{long_description}", client_data["long_description"]);
            //temp = temp.replace("","");
          }
          this.setState(
            {
              template: temp,
              title: result.data[0].title,
              result: result.data,
              style: result.data[0].style,
              user_id: result.data[0].user_id,
              tem_client_id: result.data[0].client_id,
              tem_user_id: result.data[0].user_id,
              price: result.data[0].price,
              type: result.data[0].type,
              thumb: result.data[0].thumb,
              status: result.data[0].status
            },
            () => {
              //console.log("after image ");
              this.getGrapeEditor();
            }
          );
          // } else {
          //   ////console.log("dvbhj");
          // }
          //}
          // else {
          //   $("#msg_text").text(__t("add_image_assets"));
          //   $("#alertinfo").show();
          // }
        });
      }
    });
  }
  getGrapeEditor() {
    var gjs = document.getElementById("gjs");
    gjs.innerHTML = this.state.template;

    // initialize the grapes.js and define functions
    this.editor = grapesjs.init({
      allowScripts: 1,
      showOffsets: 1,
      autorender: 0,
      noticeOnUnload: 0,
      container: "#gjs",
      height: "700px",
      fromElement: true,
      storageManager: { type: null },
      style: this.state.style,
      // blockManager: { blocks: [] },
      plugins: ["gjs-blocks-basic", "gjs-blocks-flexbox"],
      pluginsOpts: {
        "gjs-blocks-flexbox": {}
      },
      canvas: {
        styles: ["../../custom.css"]
      },
      assetManager: {
        storageType: "",
        storeOnChange: true,
        storeAfterUpload: true,
        dropzone: 0,
        openAssetsOnDrop: 1,
        modalTitle: "Select Image",
        assets: image_array,
        uploadFile: (e, clb) => {
          var files = e.dataTransfer ? e.dataTransfer.files : e.target.files;
          //this.uploadAsset(files);
          if (this.state.dragged_image !== files[0].name) {
            this.setState({ dragged_image: files[0].name }, () => {
              this.uploadAsset(files, clb);
            });
          }
        }
      }
    });
    //   ////console.log([arr])
    var blockManager = this.editor.BlockManager;

    blockManager.add("my-first-block", {
      label: "Campaign block",
      content: '<div class="campaign-block">Paste your shortcode here</div>'
    });
    var pnm = this.editor.Panels;
    pnm.addButton("options", [
      {
        id: "undo",
        className: "fa fa-undo icon-undo",
        command: function command(editor, sender) {
          sender.set("active", 0);
          editor.UndoManager.undo(1);
        },
        attributes: {
          title: "Undo (CTRL/CMD + Z)"
        }
      },
      {
        id: "redo",
        className: "fa fa-repeat icon-redo",
        command: function command(editor, sender) {
          sender.set("active", 0);
          editor.UndoManager.redo(1);
        },
        attributes: {
          title: "Redo (CTRL/CMD + Y)"
        }
      }
    ]);

    this.editor.render();
    var comps = this.editor.DomComponents;

    // Change background of the wrapper and set some attribute
    var wrapper = comps.getWrapper();
    wrapper.set("style", {
      "background-color": "white"
    });
    /*******************/
    var grapEditor = this.editor;
    var pfx = grapEditor.getConfig().stylePrefix;
							var modal = grapEditor.Modal;
							var cmdm = grapEditor.Commands;
							var codeViewer = grapEditor.CodeManager.getViewer('CodeMirror').clone();
							var pnm = grapEditor.Panels;
							var container = document.createElement('div');
							var btnEdit = document.createElement('button');

							codeViewer.set({
							    codeName: 'htmlmixed',
							    readOnly: 0,
							    theme: 'hopscotch',
							    autoBeautify: true,
							    autoCloseTags: true,
							    autoCloseBrackets: true,
							    lineWrapping: true,
							    styleActiveLine: true,
							    smartIndent: true,
							    indentWithTabs: true
							});

							btnEdit.innerHTML = 'Import';
							btnEdit.className = pfx + 'btn-prim ' + pfx + 'btn-import';
							btnEdit.onclick = function() {
							    var code = codeViewer.editor.getValue();
							    comps.getWrapper().set('content', '');
							    grapEditor.setComponents(code.trim());
							    modal.close();
							};

							cmdm.add('html-import', {
							    run: function(editor, sender) {
							        sender && sender.set('active', 0);
							        var viewer = codeViewer.editor;
							        modal.setTitle('Edit code');
							        if (!viewer) {
							            var txtarea = document.createElement('textarea');
							            container.appendChild(txtarea);
							            container.appendChild(btnEdit);
							            codeViewer.init(txtarea);
							            viewer = codeViewer.editor;
							        }
							        modal.setContent('');
							        modal.setContent(container);
							        codeViewer.setContent('');
							        modal.open();
							        viewer.refresh();
							    }
							});

							pnm.addButton('options',
							    [
							        {
							            id: 'import',
							            className: 'fa fa-download',
							            command: 'html-import',
							            attributes: {
							                title: 'Import Your Template'
							            }
							        }
							    ]
							);

    /*******************/
  }
  uploadAsset = (files, clb) => {
    var img_type = files[0].type;
    if (img_type.includes("image")) {
      const formData = new FormData();
      formData.append("file", files[0]);
      formData.append("client_id", this.state.client_id);
      formData.append("token", this.state.token);
      var actkn = typeof this.state.token != "undefined" ? "Bearer " + this.state.token : "";
      const config = {
        headers: {
          "content-type": "multipart/form-data",
          authorization: actkn
        }
      };
      Axios.post(BASE_URL, formData, config)
        .then(response => {
          const am = this.editor.AssetManager;
          am.add([
            {
              src: response.data.url
            }
          ]);
          am.render();
          clb();
        })
        .catch(error => {
          this.setState({ errors: true });
        });
    } else {
      $("#msg_text").text(__t("invalid_file_type"));
      $("#alertinfo").show();
    }
  };
  ok() {
    this.setState({
      message: ""
    });
  }
  SaveTemplate() {
    const editor = this.editor;
    var ref = this;
    ////console.log(this.state);
    //return false
    Utility.ExecuteData("saveTemplate", ref.state.token, {
      client_id: ref.state.client_id,
      template: editor.getHtml(),
      style: editor.getCss(),
      token: ref.state.token,
      title: ref.state.title,
      template_data: ref.state.result,
      user_id: ref.state.user_id,
      tem_client_id: ref.state.tem_client_id,
      tem_user_id: ref.state.tem_user_id,
      temp_type:
        ref.props.userPermissions.user_type == "admin" || ref.props.userPermissions.user_type == "reseller"
          ? //? document.getElementById("type").value
            ref.state.type
          : "custom",
      price: "",
      user_type: ref.state.user_type,
      type:
        ref.props.userPermissions.user_type == "admin" || ref.props.userPermissions.user_type == "reseller"
          ? // ? document.getElementById("type").value
            ref.state.type
          : "custom",
      thumb: ref.state.thumb,
      status: ref.state.status,
      temp_id: ref.state.temp_id
    }).then(result => {
      if (result.title) {
        ref.setState({
          title: result.title,
          message: "updated successfully"
        });
      } else {
        // ////console.log(result);
        ref.setState({
          title: result.data.title,
          message: "save successfully",
          tem_client_id: result.data.client_id,
          tem_user_id: result.data.user_id
        });
      }
    });
  }
  getprice() {
    var type = document.getElementById("type").value;
    if (type === "free") {
      document.getElementById("price").value = "0";
    } else if (type === "premium") {
      document.getElementById("price").value = "2999";
    }
  }
  handleChange(event) {
    this.setState({ [event.target.name]: event.target.value });
  }
  onChange(e) {
    this.setState({ file: e.target.files[0] });
    ////console.log(e.target.files[0]);
  }
  mediaToggle = () => {
    this.setState({ mediaModal: !this.state.mediaModal, edititem: [] });
  };
  submit = () => {
    Utility.ExecuteData("addAdditionalCss", this.state.token, {
      token: this.state.token,
      temp_id: this.state.temp_id,
      additional_css: this.state.style
    }).then(data => {
      //console.log(data);
      if (data.code == 200) {
        $("#msg_text").text(__t("style_updated_successfully"));
        $("#alertinfo").show();
      }
    });
  };
  onFormSubmit(e) {
    ////console.log(e);
    e.preventDefault();
    ////console.log(this.state.file);
    var fileimg = this.state.file;
    if (this.state.file) {
      var img_type = this.state.file.type;
      // ////console.log(img_type.includes("image"));
      if (img_type.includes("image")) {
        const formData = new FormData();
        formData.append("file", this.state.file);
        formData.append("client_id", this.state.client_id);
        formData.append("token", this.state.token);
        // let actkn = localStorage.getItem("token");
        var actkn = typeof this.state.token != "undefined" ? "Bearer " + this.state.token : "";
        ////console.log(actkn);
        const config = {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            // authorization: "Bearer " + actkn
            authorization: actkn
          }
        };
        Axios.post(BASE_URL, formData, config)
          .then(response => {
            ////console.log(fileimg);
            var thumbimg = this.getThumb(fileimg);
            ////console.log(thumbimg);
            ////console.log(response);
            $("#msg_text").text("File is successfully uploaded");
            $("#alertinfo").show();
            this.setState({
              uploadedImage: response.url
            });
            const am = this.editor.AssetManager;
            am.add([
              {
                src: response.data.url
              }
            ]);
            am.render();
          })
          .catch(error => {
            this.setState({ errors: true });
          });
        document.getElementById("file-1").value = "";
        this.state.file = "";
      } else {
        document.getElementById("file-1").value = "";
        this.state.file = "";
        $("#msg_text").text(__t("please_select_only_image_type"));
        $("#alertinfo").show();
      }
    } else {
      $("#msg_text").text(__t("please_select_a_file"));
      $("#alertinfo").show();
    }
  }
  render() {
    return (
      <React.Fragment>
        <div className="row wrapper border-bottom white-bg page-heading">
          <div className="col-lg-12" style={{ top: "-15px" }}>
            <h2 style={{ marginTop: 10 }}>{__t("templates")}</h2>
            <ol className="breadcrumb">
              <li className="breadcrumb-item">
                <Link to="/templates">{__t("templates")}</Link>
              </li>
              <li className="breadcrumb-item">
                <Link to={"/template/" + this.props.match.params.id}>{__t("Edit Template")}</Link>
              </li>
              <div className="tempbtn">
                {" "}
                <button className="btn btn-success ml-3" onClick={this.SaveTemplate}>
                  {__t("Save")}
                </button>
              </div>

              <div className="gobackbtn">
                {" "}
                <Link to={"/templates/"} className="btn btn-white ml-3">
                  {__t("go_back")}
                </Link>
              </div>
              {/* <li className="breadcrumb-item active">
                <strong>{__t("client_list")}</strong>
              </li> */}
            </ol>
          </div>
            <form>
              <div className="form-row align_center my-2 ml-2">
                <div className="col-md-3 img-container mr-3">
                  <img src={this.state.thumb} alt={""} className={"img-responsive thumb-image"} />
                  <div onClick={this.mediaToggle} className={"btn btn-primary centered"}>
                    {__t("upload_thumb_image")}
                  </div>
                </div>
                {this.state.uploadedImage ? (
                  <div className="imgupperdiv">
                    <img style={{ height: "50px", width: "50px" }} src={this.state.uploadedImage} />
                  </div>
                ) : (
                  ""
                )}
                <div className="box " style={{ height: "47px" }}>
                  <input type="file" onChange={this.onChange} name="file" id="file-1" className="inputfile inputfile-1" data-multiple-caption="{count} files selected" multiple accept="image/*" />
                  <label htmlFor="file-1" className="ht48">
                    {" "}
                    <i className="fa fa-upload text_white" aria-hidden="true">
                      <span className="inputfile_span">{this.state.file ? this.state.file.name : __t("choose_a_file")}</span>
                    </i>
                  </label>
                </div>

                <button style={{ height: "46px" }} onClick={this.onFormSubmit} className="btn_ btn-primary mx-2 wd160">
                  {__t("Upload")}
                </button>

                <div className="col-md-3 temp-3-form">
                  <input type="text" name="title" autoComplete="off" className="form-control" placeholder={__t(" Add title")} defaultValue={this.state.title} onChange={this.handleChange} />
                </div>
                
          {this.state.user_type == "admin" ? (
                <div className="col-md-2 temp-3-form">
                  <strong htmlFor="inputState" className="mr-2">
                    {__t("type")}
                  </strong>
                  <select id={"type"} name="type" className="form-control" value={this.state.type} onChange={this.handleChange}>
                    <option value="free">{__t("free")}</option>
                    <option value="premium">{__t("premium")}</option>
                  </select>{" "}
                </div>
                 ) : ""}
              </div>
         
              <SelectImageModal isOpen={this.state.mediaModal} mediaToggle={this.mediaToggle} setImageUrl={this.setImageUrl} item={this.state.edititem} />
            </form>

        </div>

        <div className="panel__top">
          <div className="panel__basic-actions" />
          <div className="panel__devices" />
          {/* <div className="panel__switcher" /> */}
        </div>
        <div className="editor-row">
          <div className="editor-canvas">
            <div id="gjs" style={{ height: "700px !important", overflow: "hidden" }} />
          </div>
        </div>
        {/* <div className="form-group" style={{ paddingBottom: "50px", paddingTop: "20px" }}>
          <label>{__t("additional_styles")}</label>
          <textarea type="text" name="style" autoComplete="off" rows="7" className="form-control" placeholder="" value={this.state.style} onChange={this.handleChange} />
          <br />
          <button className="btn btn-success ml-3" onClick={this.submit}>
            {__t("apply")}
          </button>
        </div> */}

        <div className="clearfix" />
        {this.state.message ? <AlertSuccess message={this.state.message} ok={this.ok} /> : ""}
      </React.Fragment>
    );
  }
}

export default Preview;
