import React from "react";
import UserList from "./UserList";
import UserUpdate from "./UserUpdate";
import { Route, Switch } from "react-router-dom";
class Index extends React.Component {
  constructor(props) {
    super(props);
  }
  render() {
    return (
      <Switch>
        <Route
          path="/user/create"
          render={props => (
            <UserUpdate
              {...props}
              userPermissions={this.props.userPermissions}
            />
          )}
        />
        <Route
          path="/user/create/"
          render={props => (
            <UserUpdate
              {...props}
              userPermissions={this.props.userPermissions}
            />
          )}
        />
        <Route
          path="/user/update/:id"
          render={props => (
            <UserUpdate
              {...props}
              userPermissions={this.props.userPermissions}
            />
          )}
        />
        <Route
          path="/user"
          render={props => (
            <UserList {...props} userPermissions={this.props.userPermissions} />
          )}
        />
      </Switch>
    );
  }
}

export default Index;
