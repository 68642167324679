import React, { useState, useEffect } from "react";
import "../../styles/tab6_styles.css";
import logo from "../../images/nationbuilder-mark-solid.svg";
import Utility from "../common/Utility";

const APIIntegration = () => {
  const [isConnected, setIsConnected] = useState(false);
  const [nationSlug, setNationSlug] = useState("");
  const [oauthClientId, setOauthClientId] = useState("");
  const [oauthClientSecret, setOauthClientSecret] = useState("");

  const authorize = async () => {
    const urlParams = new URLSearchParams(window.location.search);
    const code = urlParams.get("code");
    try {
      const token = localStorage.getItem("token");
      if (code) {
        const response = await Utility.ExecuteData(
          "oauth-callback",
          token,
          {
            code: code,
            clientId: oauthClientId,
            clientSecret: oauthClientSecret,
            nationSlug: nationSlug,
          },
          false
        );
        const { message } = response;

        if (response.code == 200) {
          console.log(message);
          getData();
          window.history.replaceState(null, "", window.location.pathname);
          window.location.reload();
        }
      }
    } catch (error) {
      console.error("An error occurred:", error);
    }
  };

  const getData = async () => {
    const lgn_dtl = JSON.parse(localStorage.lgn_dtl);
    const client_id = lgn_dtl.client_id;

    const url = "https://api.pushpolitics.org/v1/get-data";
    fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ client_id }),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.success) {
          setIsConnected(true);
        } else {
          setIsConnected(false);
          console.log("Client not exist or null token in tokens DB");
        }
      })
      .catch((error) => {
        console.error("An error occurred:", error);
      });
  };

  useEffect(() => {
    getData();
    getBuilderConfig();
  }, []);

  useEffect(() => {
    if (nationSlug && oauthClientId && oauthClientSecret) {
      authorize();
    }
  }, [nationSlug, oauthClientId, oauthClientSecret]);

  const getBuilderConfig = async () => {
    const action = "getbuilderconfig";
    const token = localStorage.getItem("token");
    const loginDetails = JSON.parse(localStorage.getItem("lgn_dtl"));
    const client_id = loginDetails.client_id;

    const dataa = {
      client_id: client_id,
    };

    try {
      const response = await Utility.ExecuteData(action, token, dataa, true);
      const { data } = response;
      // Extract the values from the 'data' object
      if (response && response.code == 200) {
        const {
          nation_slug: nationSlug,
          oauth_client_id: oauthClientId,
          oauth_client_secret: oauthClientSecret,
        } = data;

        // Update the component's state using the extracted values
        setNationSlug(nationSlug);
        setOauthClientId(oauthClientId);
        setOauthClientSecret(oauthClientSecret);
        // setIsConnected(true);
      }
    } catch (error) {
      console.error("An error occurred:", error);
    }
  };

  const handleAuthorize = async () => {
    const action = "authorize";
    const token = localStorage.getItem("token");

    // Check if nationSlug or oauthClientId is empty
    if (!nationSlug || !oauthClientId) {
      // Display a message to the user to enter the data
      alert("Please enter NationBuilder Configurations before authorizing.");
      return; // Return to exit the function
    }

    const requestBody = {
      clientId: oauthClientId,
      nationSlug: nationSlug,
    };

    try {
      const response = await Utility.ExecuteData(
        action,
        token,
        requestBody,
        false
      );
      const { code, url } = response;

      if (code === 200) {
        window.location.href = url;
      } else {
        // console.log(message);
      }
    } catch (error) {
      console.error("An error occurred:", error);
    }
  };

  const removeIntegration = async (event) => {
    event.preventDefault();

    const action = "removebuilderconfig";
    const token = localStorage.getItem("token");
    // const loginDetails = JSON.parse(localStorage.getItem("lgn_dtl"));
    // const client_id = loginDetails.client_id;

    // const data = {
    //   user_data: {
    //     client_id: client_id,
    //     nation_slug: "",
    //     oauth_client_id: "",
    //     oauth_client_secret: "",
    //     title: "",
    //     description: "",
    //   },
    // };

    try {
      const response = await Utility.ExecuteData(action, token, {}, true);
      const { code, message } = response;

      if (code == 200) {
        console.log("Integration Removed.");
        setIsConnected(false);

        // Reload the page after successful form submission
        window.location.reload();
      } else {
        console.log(message);
      }
    } catch (error) {
      console.error("An error occurred:", error);
    }
  };

  return (
    <div className="company-integration">
      <div className="company-box">
        <img src={logo} alt="NationBuilder" className="company-logo" />
        <h3 className="company-name">NationBuilder</h3>
        {isConnected ? (
          <button className="connected-button" onClick={removeIntegration}>
            Connected
          </button>
        ) : (
          <button className="add-integration-button" onClick={handleAuthorize}>
            Add Integration
          </button>
        )}
      </div>
    </div>
  );
};

export default APIIntegration;
