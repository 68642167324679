import React from "react";
import ReactDOM from "react-dom";
import paypal from "paypal-checkout";
import Utility from '../common/Utility';
const PayPalButton = paypal.Button.driver("react", { React, ReactDOM });
class PaypalExpress extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showButton: true
    };
  }
  componentWillMount(){
    Utility.ExecuteData("checkStatusPaypalButton")
    .then(data => {
      //console.log(data.status[0])
      if(data.code==204||data.status[0].paypal_status=="inactive"){
        this.setState({
          showButton:false
        })
      }
    })
  }

  render() {
   
    const {    
      env,
      commit,
       payment,
       onAuthorize,
      onError,
      onCancel
         } = this.props;


    return (
      <div>{
        this.state.showButton? <PayPalButton
        env={env}     
        payment={payment}
        commit={commit}
        onAuthorize={onAuthorize}
        onError={onError}
        onCancel={onCancel}
      />:''
      }
       
      </div>
    );
  }
}

export default PaypalExpress;
