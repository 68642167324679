import React from "react";
import Utility from "../common/Utility";
import { Link } from "react-router-dom";
import { __t } from "../../locale/language";
import { CSVLink } from "react-csv";
import Moment from "moment";
class Analytics extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      total_count: "",
      camp_name: "",
      party_count: [],
      city_count: [],
      official_count: [],
      officialcsvdata: [],
      partycsvdata: [],
      citycsvdata: [],
      page: 1,
      sizePerPage: 10,
      total: "",
      token: localStorage.getItem("token")
    };
    this.totalcount = this.totalcount.bind(this);
    this.officialcount = this.officialcount.bind(this);
    this.citycount = this.citycount.bind(this);
    this.partycount = this.partycount.bind(this);
    this.getcitycsv = this.getcitycsv.bind(this);
    this.getofficialcsv = this.getofficialcsv.bind(this);
    this.getpartycsv = this.getpartycsv.bind(this);
  }
  totalcount() {
    var id = this.props.location.state.id;
    Utility.ExecuteData("getcampaigntotalcount", this.token, {
      id: id
    }).then(data => {
      //console.log(data);
      if (data) {
        this.setState({
          total_count: data.data[0].total_count,
          camp_name: data.data[0].title
        });
      }
    });
  }
  officialcount() {
    var id = this.props.location.state.id;
    Utility.ExecuteData("getcountbyofficial", this.token, {
      id: id
    }).then(data => {

      if (data) {
        this.setState({ official_count: data.data }, () => {
          this.getofficialcsv();
        });
      }
    });
  }
  citycount() {
    var id = this.props.location.state.id;
    Utility.ExecuteData("getcountbycity", this.token, {
      id: id
    }).then(data => {
      //console.log(data);
      if (data) {
        this.setState({ city_count: data.data }, () => {
          this.getcitycsv();
        });
      }
    });
  }
  partycount() {
    var id = this.props.location.state.id;
    Utility.ExecuteData("getcountbyparty", this.token, {
      id: id
    }).then(data => {
      //console.log(data);
      if (data) {
        this.setState({
          party_count: data.data.slice(0, 10),
          total: data.data.length
        }, () => {
          this.getpartycsv();
        });
      }
    });
  }
  updateTablePage = page => {
    //  this.getClientRevenue(page);
    this.updateTable(page)
  };
  updateTable = page => {
    let startIndex = (page - 1) * 10;
    // //console.log(startIndex)
    // //console.log(this.state.revenue_reseller)
    //console.log(this.state.revenue_reseller.slice(startIndex, startIndex + 10))
    let party_count = this.state.party_count.slice(startIndex, startIndex + 10)
    this.setState({
      party_count: party_count,
      page: page
    })
  };
  pagination = (page, sizePerPage, total, getPartyData) => {
    if (total <= 0) {
      return "";
    }
    let total_page = Math.ceil(total / sizePerPage);
    let pages = [];
    for (let i = 1; i <= total_page; i++) {
      pages.push(
        <li key={i} className={page == i ? "page-item active" : "page-item"}>
          <a
            className="page-link"
            onClick={e => {
              // updateTable(i);
              getPartyData(i)
              e.preventDefault();
            }}
          >
            {i}
          </a>
        </li>
      );
    }
    return (
      <nav aria-label="Page navigation example">
        <ul className="pagination justify-content-end">
          <li className={page <= 1 ? "page-item disabled" : "page-item"} disabled={page <= 1 ? "disabled" : ""}>
            <a
              className="page-link"
              onClick={e => {
                if (page > 1) {
                  //updateTable(page - 1);
                  getPartyData(page - 1)
                }
                e.preventDefault();
              }}
            >
              {__t("back")}
            </a>
          </li>
          {pages}

          <li className={page == total_page ? "page-item disabled" : "page-item"} disabled={page == total_page ? "disabled" : ""}>
            <a
              className="page-link"
              onClick={e => {
                if (page < total_page) {
                  // updateTable(page + 1);
                  getPartyData(page + 1)
                }
                e.preventDefault();
              }}
            >
              {__t("next")}
            </a>
          </li>
        </ul>
      </nav>
    );
  };
  componentWillMount() {
    //console.log(this.props);
    this.totalcount();
    this.citycount();
    this.partycount();
    this.officialcount();


  }
  getpartycsv() {
    let newarr = [];
    this.state.party_count.map((val3, key) => {
      var obj3 = {}
      var count3 = Object.keys(val3).length
      for (var k = 0; k <= count3; k++) {

        obj3["Party Name"] = val3["contact_party"]
        obj3["Count"] = val3['count']
      }


      newarr.push(obj3)
    })
    this.setState({ partycsvdata: newarr });
  }
  getcitycsv() {
    var newarr = [];
    this.state.city_count.map((val2, k) => {
      var obj2 = {}
      var count2 = Object.keys(val2).length
      
      for (var j = 0; j <= count2; j++) {

        obj2["Constituency Name"] = val2["contact_constituency"]
        obj2["Count"] = val2['count']

      }
      newarr.push(obj2)
      
    })
    this.setState({ citycsvdata: newarr });
  }

  getofficialcsv() {

    var newarr = [];
    
    this.state.official_count.map((val, k) => {
      var count = Object.keys(val).length
      var obj = {}
      for (var i = 0; i <= count; i++) {
        obj["Contact Name"] = val["contact_name"]
        obj["Contact email"] = val['contact_email']
        obj["Contact Party"] = val['contact_party']
        obj["Contact Constituency"] = val['contact_constituency']
        obj["Letter Count"] = val['count']
      }
      newarr.push(obj)
    })


    this.setState({ officialcsvdata: newarr });


  }
  token = localStorage.getItem("token");
  render() {
    return (
      <React.Fragment>
       
        <div className="ibox-title">
          <h3>
            {__t("campaign_name")}  : {this.state.camp_name}


            <Link
              style={{ float: "right", marginTop: "-10px" }}
              className="btn btn-white"
              to="/campaign"
            >
              {__t("go_back")}
            </Link>
          </h3>

        </div>
       <div className={"row"}>
       <div className="col-lg-3">
            <div className="ibox">
              <div className="ibox-title">
                <h5>{__t("total_letter_sent")}</h5>

              </div>
              <div className="ibox-content">
                <h1 className="no-margins">
                  {this.state.total_count ? this.state.total_count : 0}
                </h1>
                <div className="stat-percent font-bold text-success" />
              </div>
            </div>
          </div>
       </div>
          <div className={"row"}>
          <div className="col-lg-12">
          <div className="ibox float-e-margins">
            <div className="ibox-title">
              <h5>{__t("letter_sent_to_official")}</h5>
              <CSVLink
                style={{ float: "right", marginTop: "-10px" ,padding: "5px 8px"}}
                filename="widget-email.csv"
                className="btn btn-primary"
                data={this.state.officialcsvdata}
              >
                {__t("export")}
              </CSVLink>
            </div>
            <div className="ibox-content" style={{height:"400px", overflowY:"scroll"}}>
              <table className="table">
                <thead>
                  <tr>
                    <th>{__t("s_no")}</th>
                    <th>{__t("contact_name")}</th>
                    <th>{__t("contact_email")}</th>
                    <th>{__t("contact_party")}</th>
                    <th>{__t("contact_constituency")}</th>
                    <th>{__t("letter_count")}</th>
                  </tr>
                </thead>
                <tbody>
                  {this.state.official_count.map((val, key) => {
                    return (
                      <tr key={"offcial" + key}>
                        <td>{key + 1}</td>
                        <td>{val.contact_name}</td>
                        <td>{val.contact_email}</td>
                        <td>{val.contact_party}</td>
                        <td>{val.contact_constituency}</td>
                        <td>{val.count}</td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </div>
        </div>
          </div>
        
  
          
          <div className={"row"}>
          <div className="col-md-6">
            <div className="ibox float-e-margins">
              <div className="ibox-title">
                <h5>{__t("letter_sent_by_constituency")}</h5>
                <CSVLink
                  style={{ float: "right", marginTop: "-10px" ,padding: "5px 8px"}}
                  filename="widget-email.csv"
                  className="btn btn-primary"
                  data={this.state.citycsvdata}
                >
                  {__t("export")}
                </CSVLink>
              </div>
              <div className="ibox-content" style={{height:"400px", overflowY:"scroll"}}>
                <table className="table">
                  <thead>
                    <tr>
                      <th>{__t("s_no")}</th>
                      <th>{__t("constituency_name")}</th>
                      <th>{__t("count")}</th>
                    </tr>
                  </thead>
                  <tbody>
                    {this.state.city_count.map((val, key) => {
                      return (
                        <tr key={"city" + key}>
                          <td>{key + 1}</td>
                          <td>{val.contact_constituency ? val.contact_constituency : __t("n_a")}</td>
                          <td>{val.count}</td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <div className="col-md-6">
            <div className="ibox float-e-margins">
              <div className="ibox-title">
                <h5>{__t("letter_sent_by_party")}</h5>
                <CSVLink
                  style={{ float: "right", marginTop: "-10px" ,padding: "5px 8px" }}
                  filename="widget-email.csv"
                  className="btn btn-primary"
                  data={this.state.partycsvdata}
                >
                  {__t("export")}
                </CSVLink>
              </div>
              <div className="ibox-content">
                <table className="table">
                  <thead>
                    <tr>
                      <th>{__t("s_no")}</th>
                      <th>{__t("party_name")}</th>
                      <th>{__t("count")}</th>
                    </tr>
                  </thead>
                  <tbody>
                    {this.state.party_count.map((val, key) => {
                      return (
                        <tr key={"party" + key}>
                          <td>{key + 1}</td>
                          <td>
                            {val.contact_party ? val.contact_party : "Other"}
                          </td>
                          <td>{val.count}</td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
                {this.pagination(this.state.page, this.state.sizePerPage, this.state.total, this.updateTablePage)}
              </div>
              
            </div>
            
          </div>
          </div>
          
        
          </React.Fragment>
    );
  }
}

export default Analytics;
