import React from "react";
import $ from "jquery";

import { __t } from "../../locale/language";
import Utility from "../common/Utility";

class UrlRedirection extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      campaign_id: "",
      url_post_form_submission: false,
      url_to_redirect: "",
      redirection_confirm_msg: false,
      message_box_text: __t(
        "You are being redirected to the campaign website. Is this OK?"
      ),
      errors: {},
      client_id: localStorage.getItem("client_id"),
      token: localStorage.getItem("token"),
      user_type: this.props.userPermissions.user_type,
      subscription_feature: this.props.userPermissions.subscription_feature,
      update: false,
    };
  }
  token = localStorage.getItem("token");
  client_id = localStorage.getItem("client_id");

  componentDidMount() {
    let { campaign, update } = this.props;
    if (campaign) {
      let url_post_form_submission =
        campaign.url_post_form && campaign.url_post_form == "1" ? true : false;
      let redirection_confirm_msg =
        campaign.redirect_conf_msg && campaign.redirect_conf_msg == "1"
          ? true
          : false;
      let url_to_redirect = campaign.redirect_url ? campaign.redirect_url : "";
      let message_box_text = campaign.msg_box_text
        ? campaign.msg_box_text
        : this.state.message_box_text;
      this.setState({
        campaign_id: campaign.id,
        url_post_form_submission,
        redirection_confirm_msg,
        url_to_redirect,
        message_box_text,
        update,
      });
    }
  }

  handleChangeRadio = (e) => {
    let isChecked = e.target.value;
    if (isChecked === "on") {
      this.setState({ [e.target.name]: true, validate: true });
    } else {
      this.setState({ [e.target.name]: false, validate: true });
    }
  };
  handleOnChange = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };
  HandleCreateUpdate = () => {
    if (this.state.url_post_form_submission) {
      if (this.state.url_to_redirect.trim() === "") {
        alert(__t("Please enter a URL to Redirect after form is submitted"));
        return;
      }
    }
    if (this.state.redirection_confirm_msg) {
      if (this.state.message_box_text.trim() === "") {
        alert(__t("Please enter message box text!!"));
        return;
      }
    }
    if (this.state.campaign_id) {
      let option = {
        id: this.state.campaign_id,
        url_post_form: this.state.url_post_form_submission,
        redirect_url: this.state.url_to_redirect,
        redirect_conf_msg: this.state.redirection_confirm_msg,
        msg_box_text: this.state.message_box_text,
      };

      Utility.ExecuteData("updatecampaignsome", this.token, option)
        .then((responseJson) => {
          if (responseJson.code === 200) {
            $("#msg_text").text(__t("successfully_updated_campaign"));
            $("#alertinfo").show();
          } else {
            alert("Something went wront. Try Again.");
          }
        })
        .catch((error) => {
          this.setState({ errors: true });
        });
    }
  };

  render() {
    const {
      errors,
      url_post_form_submission,
      url_to_redirect,
      redirection_confirm_msg,
      message_box_text,
      update,
      campaign_id,
    } = this.state;

    return (
      <div>
        <div className="row form-group">
          <div className="col-md-12 col-sm-12 col-xs-12">
            <div className="row">
              <div className="radio col-md-12 col-sm-12 col-xs-10">
                <label className="control-label">
                  {__t(
                    "Enable / Disable URL Redirection Post Form Submission"
                  ) + ": "}
                </label>
              </div>
              <div className="radio col-md-2 col-sm-2 col-xs-3">
                <label>
                  <input
                    type="radio"
                    value="on"
                    name="url_post_form_submission"
                    id="url_post_form_submission"
                    checked={url_post_form_submission === true ? true : false}
                    onClick={(e) => this.handleChangeRadio(e)}
                  />
                  {" " + __t("Enable")}
                </label>
              </div>
              <div className="radio col-md-2 col-sm-2 col-xs-3">
                <label>
                  <input
                    type="radio"
                    value="off"
                    name="url_post_form_submission"
                    id="url_post_form_submission"
                    checked={url_post_form_submission === false ? true : false}
                    onClick={(e) => this.handleChangeRadio(e)}
                  />
                  {" " + __t("Disable")}
                </label>
              </div>
            </div>
          </div>
          <div className="col-md-8 col-sm-8 col-xs-12">
            <label className="control-label">
              {__t("URL to Redirect after form is submitted") + ": "}
            </label>
            <input
              type="text"
              name="url_to_redirect"
              value={url_to_redirect}
              className="form-control"
              placeholder={""}
              onChange={this.handleOnChange}
            />
            <label id="-error" className="error">
              {errors["url_to_redirect"]}
            </label>
          </div>
          <div className="col-md-12 col-sm-12 col-xs-12">
            <div className="row">
              <div className="radio col-md-12 col-sm-12 col-xs-10">
                <label className="control-label">
                  {__t(
                    "Enable / Disable Redirection Confirmation Message Box"
                  ) + ": "}
                </label>
              </div>
              <div className="radio col-md-2 col-sm-2 col-xs-3">
                <label>
                  <input
                    type="radio"
                    value="on"
                    name="redirection_confirm_msg"
                    id="redirection_confirm_msg"
                    checked={redirection_confirm_msg === true ? true : false}
                    onClick={(e) => this.handleChangeRadio(e)}
                  />
                  {" " + __t("Enable")}
                </label>
              </div>
              <div className="radio col-md-2 col-sm-2 col-xs-3">
                <label>
                  <input
                    type="radio"
                    value="off"
                    name="redirection_confirm_msg"
                    id="redirection_confirm_msg"
                    checked={redirection_confirm_msg === false ? true : false}
                    onClick={(e) => this.handleChangeRadio(e)}
                  />
                  {" " + __t("Disable")}
                </label>
              </div>
            </div>
          </div>
          <div className="col-md-8 col-sm-8 col-xs-12">
            <label className="control-label">
              {__t("Message Box Text") + ": "}
            </label>
            <input
              type="text"
              name="message_box_text"
              value={message_box_text}
              className="form-control"
              placeholder={""}
              onChange={this.handleOnChange}
            />
            <label id="-error" className="error">
              {errors["message_box_text"]}
            </label>
          </div>
          <div className="col-md-8 col-sm-8 col-xs-12">
            {campaign_id && (
              <button
                className="btn btn-primary"
                onClick={this.HandleCreateUpdate}
                type="submit"
              >
                {update ? "Update" : "Create"}
              </button>
            )}
          </div>
        </div>
      </div>
    );
  }
}

export default UrlRedirection;
