import React from "react";
import { Redirect, Link } from "react-router-dom";
import { ListGroup, ListGroupItem, Checkbox } from "react-bootstrap";
import Utility from "../common/Utility";
import bcrypt from "bcryptjs";
import { __t } from "../../locale/language";
import Can from "../common/AccessControl";
import $ from "jquery";

import { Tabs, Tab } from "react-bootstrap";
import TwoFA from "../common/TwoFA";

class UserUpdate extends React.Component {
  constructor(props) {
    super(props);
    console.log(Utility.getIdFromToken(localStorage.getItem("token")));
    this.state = {
      first_name: "",
      last_name: "",
      user_name: "",
      cell_number: "",
      office_number: "",
      user_designation: "",
      user_address: "",
      password: "",
      confirm_password: "",
      email: "",
      status: "",
      redirect: false,
      errors: {},
      user_type: Utility.getIdFromToken(localStorage.getItem("token")),
      token: localStorage.getItem("token"),
      client_id: localStorage.getItem("client_id"),
      user_roles: [],
      userrole: [],
      verified: 0,
      verifiedemail: 0,
      activeTab: "editUser",
      showSecondTab: 0,
      resultData: {},
    };
    this.register = this.register.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.createHash = this.createHash.bind(this);
  }

  getTwoFAConfig = async () => {
    let res = await Utility.GetData("get2faconfig", {});
    if (res && res.code === 200) {
      this.setState({
        resultData: res.data,
        showSecondTab:
          res.data !== null && res.data.enable_user !== undefined
            ? res.data.enable_user
            : false,
      });
    }
  };

  handleTabSelect = (selectedTab) => {
    this.setState({ activeTab: selectedTab });
  };

  // handleChange = (e) => {
  //   this.setState({ otp: e.target.value });
  // };

  componentDidMount() {
    this.getTwoFAConfig();
    console.log(this.props.match.params, "this.props.match.params");

    if (this.props.match.params.id) {
      $("#title").text(__t("edit_user"));
      $("#title2").text(__t("edit_user"));
    }
    this.userRoles();
    if (
      this.props.location.state &&
      typeof this.props.location.state !== "undefined"
    ) {
      this.findUser();
      this.findUserRoles();
    } else if (this.props.match.params && this.props.match.params.id) {
      this.findUser();
      this.findUserRoles();
    }
  }
  updateVerified = async (elem) => {
    let checked = elem.target.checked;
    let name = elem.target.name;
    console.log(checked);

    this.setState({
      [name]: checked ? 1 : 0,
    });
  };
  findUserRoles = () => {
    var id = this.props.location.state
      ? this.props.location.state.id
      : this.props.match.params.id;
    Utility.ExecuteData("finduserroles", this.token, { id: id }).then(
      (data) => {
        var i = 0;
        data.data.map(() => {
          this.updateChecked(data.data[i++].role_id);
        });
      }
    );
  };
  updateChecked = (e) => {
    const { name } = e;
    this.setState((prevState) => ({
      userrole: {
        ...prevState.userrole,
        [e]: !prevState.userrole[e],
      },
    }));
  };
  findUser = () => {
    var id = this.props.location.state
      ? this.props.location.state.id
      : this.props.match.params.id;
    Utility.ExecuteData("finduser", this.token, { id: id }).then((data) => {
      this.setState(
        {
          first_name: data.data.first_name,
          last_name: data.data.last_name,
          user_name: data.data.user_name,
          cell_number: data.data.cell_number,
          email: data.data.email,
          status: data.data.status,
          office_number: data.data.office_number,
          user_designation: data.data.user_designation,
          user_address: data.data.user_address,
          verified: data.data.verified,
        },
        () => {
          if (this.state.verified) {
            document.getElementById("verified").checked = true;
          }
        }
      );
    });
  };
  userRoles = () => {
    Utility.ExecuteData("userrole", this.token, {
      client_id: this.client_id,
    }).then((data) => {
      if (data.code == 200) {
        this.setState({
          user_roles: data.data,
        });
      }
    });
  };
  client_id = localStorage.getItem("client_id");
  createHash(pwd) {
    const saltRounds = 10;
    return new Promise((resolve, reject) => {
      bcrypt.genSalt(saltRounds, function (err, salt) {
        if (err) return err;
        bcrypt.hash(pwd, salt, function (err, hash) {
          // Store hash in your password DB.
          if (err) {
            reject(err);
          } else {
            resolve(hash);
          }
        });
      });
    });
  }

  token = localStorage.getItem("token");
  handleChange(event) {
    this.setState({ [event.target.name]: event.target.value });
  }
  handleValidation() {
    //console.log(this.state.userrole);

    //  return false
    let fields = this.state;
    let errors = {};
    let formIsValid = true;
    if (
      !this.props.location.state &&
      typeof this.props.location.state === "undefined"
    ) {
      if (!fields["confirm_password"]) {
        formIsValid = false;
        errors["confirm_password"] = __t("message_conf_pwd");
      } else if (fields["password"] !== fields["confirm_password"]) {
        formIsValid = false;
        errors["confirm_password"] = __t("message_pwd_match");
      }
      //password
      if (!fields["password"]) {
        formIsValid = false;
        errors["password"] = __t("message_pwd_empty");
      }
    } else {
      if (this.state.password) {
        if (!fields["confirm_password"]) {
          formIsValid = false;
          errors["confirm_password"] = __t("message_conf_pwd");
        } else if (fields["password"] !== fields["confirm_password"]) {
          formIsValid = false;
          errors["confirm_password"] = __t("message_pwd_match");
        }
      } else if (this.state.confirm_password) {
        if (!fields["password"]) {
          formIsValid = false;
          errors["password"] = __t("message_pwd_empty");
        }
      }
    }

    if (!fields["first_name"]) {
      formIsValid = false;
      errors["first_name"] = __t("chk_empty_fname");
    }
    if (fields["userrole"].length <= 0) {
      formIsValid = false;
      errors["userroles"] = __t("roles_cannot_empty");
    }
    var entries = [];
    Object.entries(fields.userrole).map((val, k) => {
      //console.log(val[1]);
      entries.push(val[1]);
    });
    //console.log(entries);
    if (entries.includes(true)) {
    } else {
      formIsValid = false;
      errors["userroles"] = __t("roles_cannot_empty");
      //console.log("dfvhbf");
    }
    //return false
    if (!fields["user_name"]) {
      formIsValid = false;
      errors["user_name"] = __t("chk_empty_uname");
    }
    if (!fields["status"]) {
      formIsValid = false;
      errors["status"] = __t("select_status");
    }

    if (!fields["email"]) {
      formIsValid = false;
      errors["email"] = __t("chk_empty_email");
    } else if (typeof fields["email"] !== "undefined") {
      let lastAtPos = fields["email"].lastIndexOf("@");
      let lastDotPos = fields["email"].lastIndexOf(".");
      //let reEmail = /^(?:[\w\!\#\$\%\&\'\*\+\-\/\=\?\^\`\{\|\}\~]+\.)*[\w\!\#\$\%\&\'\*\+\-\/\=\?\^\`\{\|\}\~]+@(?:(?:(?:[a-zA-Z0-9](?:[a-zA-Z0-9\-](?!\.)){0,61}[a-zA-Z0-9]?\.)+[a-zA-Z0-9](?:[a-zA-Z0-9\-](?!$)){0,61}[a-zA-Z0-9]?)|(?:\[(?:(?:[01]?\d{1,2}|2[0-4]\d|25[0-5])\.){3}(?:[01]?\d{1,2}|2[0-4]\d|25[0-5])\]))$/;
      if (
        // !(fields["email"].match(reEmail))
        !(
          lastAtPos < lastDotPos &&
          lastAtPos > 0 &&
          fields["email"].indexOf("@@") == -1 &&
          lastDotPos > 2 &&
          fields["email"].length - lastDotPos > 2
        )
      ) {
        formIsValid = false;
        errors["email"] = __t("chk_valid_email");
      }
    }
    this.setState({ errors: errors });
    return formIsValid;
  }
  handleChecked = (e) => {
    const { name } = e.target;
    this.setState((prevState) => ({
      userrole: {
        ...prevState.userrole,
        [name]: !prevState.userrole[name],
      },
    }));
  };
  register() {
    if (this.handleValidation()) {
      if (
        this.props.location.state &&
        typeof this.props.location.state !== "undefined"
      ) {
        this.setState({ id: this.props.location.state.id }, async () => {
          // for client update when password is not change
          let hash = {};
          let requestData = this.state;
          if (this.state.password) {
            await this.createHash(this.state.password).then((myhash) => {
              requestData["password"] = myhash;
            });
          }

          Utility.ExecuteData("userupdate", this.token, requestData)
            .then((responseJson) => {
              if (responseJson.code === 200) {
                this.setState({ redirect: true });
              } else {
                alert(__t("try_again"));
              }
            })
            .catch((error) => {
              this.setState({ errors: true });
            });
        });
      } else {
        //for user create
        this.createHash(this.state.password)
          .then((myhash) => {
            this.setState({ password: myhash });
          })
          .then(() => {
            Utility.ExecuteData("register", this.token, this.state)
              .then((responseJson) => {
                if (responseJson.code === 200) {
                  this.setState({ redirect: true });
                } else if (responseJson.success == "email already exist") {
                  let formIsValid = false;
                  let errors = {};
                  errors["email"] = __t("email_exists");
                  this.setState({ errors: errors });
                } else {
                  alert(__t("try_again"));
                }
              })
              .catch((error) => {
                this.setState({ errors: true });
              });
          });
      }
    }
  }

  validationfirstName = (e) => {
    if (e.target.value.toLowerCase() == "admin") {
      alert("First name ' " + e.target.value + " ' is already taken !!");
      e.target.value = "";
    }
  };
  render() {
    const { redirect, activeTab, showSecondTab } = this.state;
    var flag = "";
    if (
      this.props.match.params &&
      typeof this.props.match.params !== "undefined" &&
      this.props.match.params.id
    ) {
      flag = "update";
    } else {
      flag = "create";
    }
    if (redirect) {
      return <Redirect push to="/user/" />;
    }

    return (
      <Can
        I={["update-user"]}
        userPermissions={this.props.userPermissions.userPermissions}
      >
        <div>
          <div className="row wrapper border-bottom white-bg page-heading">
            <div className="col-lg-10">
              <h2 style={{ marginTop: 10 }}>{__t("user")}</h2>
              <ol className="breadcrumb">
                <li className="breadcrumb-item">
                  <Link to="/">{__t("dashboard")}</Link>
                </li>
                <li className="breadcrumb-item">
                  <Link to="/user">{__t("user")}</Link>
                </li>
                <li className="breadcrumb-item active">
                  <strong id="title"> {__t("create_user")}</strong>
                </li>
              </ol>
            </div>
          </div>

          <div style={{ marginTop: "20px" }}>
            <style>{`.fade:not(.show) {opacity: 1;}`}</style>
            <Tabs
              id="myTabs"
              activeKey={activeTab}
              onSelect={this.handleTabSelect}
            >
              <Tab eventKey="editUser" title="Edit User">
                <div className="wrapper wrapper-content animated fadeInRight">
                  <div className="col-md-12">
                    <div className="ibox float-e-margins">
                      <div className="ibox-title">
                        <h5 id="title2">{__t("create_user")}</h5>
                      </div>
                      <div className="ibox-content">
                        <div className="form-horizontal">
                          <div className="row form-group">
                            <div className="col-md-6 col-sm-6 col-xs-12">
                              <label className="control-label required-field">
                                {__t("first_name")}
                              </label>
                              {/* {this.state.first_name == "admin" ? (
                          <input
                            type="text"
                            name="first_name"
                            value={this.state.first_name}
                            className="form-control"
                            placeholder={__t("first_name")}
                            onChange={this.handleChange}
                            readOnly
                          />
                        ) : (
                            <input
                              type="text"
                              name="first_name"
                              value={this.state.first_name}
                              className="form-control"
                              placeholder={__t("first_name")}
                              onChange={this.handleChange}
                            />
                          )} */}

                              <input
                                type="text"
                                name="first_name"
                                value={this.state.first_name}
                                className="form-control"
                                placeholder={__t("first_name")}
                                onChange={this.handleChange}
                                onKeyUp={(e) => this.validationfirstName(e)}
                              />
                              <label id="-error" className="error">
                                {this.state.errors["first_name"]}
                              </label>
                            </div>
                            <div className="col-md-6 col-sm-6 col-xs-12">
                              <label className="control-label">
                                {__t("last_name")}
                              </label>
                              <input
                                type="text"
                                name="last_name"
                                value={this.state.last_name}
                                className="form-control"
                                placeholder={__t("last_name")}
                                onChange={this.handleChange}
                              />
                            </div>
                          </div>

                          <div className="row form-group">
                            <div className="col-md-6 col-sm-6 col-xs-12">
                              <label className="control-label required-field">
                                {__t("uname")}
                              </label>
                              <input
                                type="text"
                                name="user_name"
                                value={this.state.user_name}
                                className="form-control"
                                placeholder={__t("uname")}
                                onChange={this.handleChange}
                              />
                              <label id="-error" className="error">
                                {this.state.errors["user_name"]}
                              </label>
                            </div>
                            <div className="col-md-6 col-sm-6 col-xs-12">
                              <label className="control-label required-field">
                                {__t("email")}
                              </label>
                              {this.props.match.params.id ? (
                                <input
                                  type="email"
                                  name="email"
                                  autoComplete="off"
                                  value={this.state.email}
                                  className="form-control"
                                  placeholder={__t("email")}
                                  onChange={this.handleChange}
                                  readOnly={"readonly"}
                                />
                              ) : (
                                <input
                                  type="email"
                                  name="email"
                                  autoComplete="off"
                                  value={this.state.email}
                                  className="form-control"
                                  placeholder={__t("email")}
                                  onChange={this.handleChange}
                                />
                              )}

                              <div>
                                <label htmlFor={"verified"}>
                                  <input
                                    type="checkbox"
                                    name={"verified"}
                                    id={"verified"}
                                    value={this.state.verified}
                                    onClick={(e) => {
                                      this.updateVerified(e);
                                    }}
                                    defaultChecked={this.state.verified}
                                  />
                                  {__t("verified")}
                                </label>
                                <br />
                                <label htmlFor="verifiedemail">
                                  <input
                                    type="checkbox"
                                    id="verifiedemail"
                                    name="verifiedemail"
                                    onClick={(e) => {
                                      this.updateVerified(e);
                                    }}
                                  />{" "}
                                  Send Verification Email
                                </label>
                              </div>

                              <label id="-error" className="error">
                                {this.state.errors["email"]}
                              </label>
                            </div>
                          </div>

                          <div className="row  form-group">
                            <div className="col-md-6 col-sm-6 col-xs-12">
                              <label
                                className={
                                  flag == "update"
                                    ? "control-label"
                                    : "control-label required-field"
                                }
                              >
                                {__t("password")}
                              </label>
                              <input
                                type="password"
                                name="password"
                                id="password"
                                value={this.state.password}
                                autoComplete="off"
                                className="form-control"
                                placeholder={__t("password")}
                                onChange={this.handleChange}
                              />
                              <label id="-error" className="error">
                                {this.state.errors["password"]}
                              </label>
                            </div>
                            <div className="col-md-6 col-sm-6 col-xs-12">
                              <label
                                className={
                                  flag == "update"
                                    ? "control-label"
                                    : "control-label required-field"
                                }
                              >
                                {__t("conf_password")}
                              </label>
                              <input
                                type="password"
                                name="confirm_password"
                                value={this.state.confirm_password}
                                autoComplete="off"
                                className="form-control"
                                placeholder={__t("conf_password")}
                                onChange={this.handleChange}
                              />
                              <label id="-error" className="error">
                                {this.state.errors["confirm_password"]}
                              </label>
                            </div>
                          </div>

                          <div className="row form-group">
                            <div className="col-md-6 col-sm-6 col-xs-12">
                              <label className="control-label">
                                {__t("mobile_number")}
                              </label>
                              <input
                                type="text"
                                name="cell_number"
                                autoComplete="off"
                                value={this.state.cell_number}
                                className="form-control"
                                placeholder={__t("mobile_number")}
                                onChange={this.handleChange}
                              />
                            </div>

                            <div className="col-md-6 col-sm-6 col-xs-12">
                              <label className="control-label">
                                {__t("office_number")}
                              </label>
                              <input
                                type="text"
                                name="office_number"
                                value={this.state.office_number}
                                className="form-control"
                                placeholder={__t("office_number")}
                                onChange={this.handleChange}
                              />
                            </div>
                          </div>

                          <div className="row form-group">
                            <div className="col-md-6 col-sm-6 col-xs-12">
                              <label className="control-label">
                                {__t("user_designation")}
                              </label>
                              <input
                                type="text"
                                name="user_designation"
                                value={this.state.user_designation}
                                className="form-control"
                                placeholder={__t("user_designation")}
                                onChange={this.handleChange}
                              />
                            </div>
                            <div className="col-md-6 col-sm-6 col-xs-12">
                              <label className="control-label">
                                {__t("user_address")}
                              </label>
                              <textarea
                                type=""
                                name="user_address"
                                value={this.state.user_address}
                                className="form-control"
                                placeholder="Address"
                                onChange={this.handleChange}
                              />
                            </div>
                          </div>

                          <div className="row form-group">
                            <div className="col-md-6 col-sm-6 col-xs-12">
                              <label className="control-label">
                                {__t("role")}
                              </label>
                              <ListGroup>
                                {this.state.user_roles.map((val, key) => {
                                  return (
                                    <ListGroupItem key={key}>
                                      {val.title == "Admin" ? (
                                        <Checkbox
                                          name={val.id}
                                          disabled
                                          key={key}
                                          onChange={this.handleChecked}
                                          checked={this.state.userrole[val.id]}
                                        >
                                          &nbsp;&nbsp;
                                          {val.title}
                                        </Checkbox>
                                      ) : (
                                        <Checkbox
                                          name={val.id}
                                          key={key}
                                          onChange={this.handleChecked}
                                          checked={this.state.userrole[val.id]}
                                        >
                                          &nbsp;&nbsp;
                                          {val.title}
                                        </Checkbox>
                                      )}
                                    </ListGroupItem>
                                  );
                                })}
                              </ListGroup>
                              <label id="-error" className="error">
                                {this.state.errors["userroles"]}
                              </label>
                            </div>
                            <div className="col-md-6 col-sm-6 col-xs-12">
                              <label className="control-label required-field">
                                {__t("status")}
                              </label>
                              <select
                                name="status"
                                className="form-control"
                                value={this.state.status}
                                onChange={this.handleChange}
                              >
                                <option value="">{__t("select_status")}</option>
                                <option value="active">{__t("active")}</option>
                                <option value="inactive">
                                  {__t("inactive")}
                                </option>
                              </select>
                              <label id="-error" className="error">
                                {this.state.errors["status"]}
                              </label>
                            </div>
                          </div>

                          <div className="row form-group">
                            <div className="col-md-12">
                              <button
                                className="btn btn-primary"
                                onClick={this.register}
                                type="submit"
                              >
                                {/* {this.props.location.state &&
                                typeof this.props.location.state !== "undefined" */}
                                {this.props.match.params &&
                                typeof this.props.match.params !==
                                  "undefined" &&
                                this.props.match.params.id
                                  ? __t("update")
                                  : __t("create")}
                              </button>{" "}
                              &nbsp;&nbsp;
                              <Link to="/user" className="btn btn-white">
                                {__t("cancel")}
                              </Link>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Tab>
              {showSecondTab == 1 && (
                <Tab eventKey="twoFactorAuth" title="Two Factor Authentication">
                  <TwoFA />
                </Tab>
              )}
            </Tabs>
          </div>
        </div>
      </Can>
    );
  }
}

export default UserUpdate;
