import $ from 'jquery';
import { __t } from "../../locale/language";
import React from 'react';
export default class CommonDelete extends React.Component {
    constructor(props){
        super(props)
    }
    
    render() {
        return (
         
            <div className="alert_oerlay" id="alertshow" style={{ "display": "none" }}  >

            <div className="alert_box info_back" >
              <i className="fa fa-question-circle info_txt fa_alert"></i>
              <h3 id="alert_prompt_msg">{__t("delete_confirm")} </h3>
              <div className="flx_space_between wd70">
                <button className="btn btn-success info_btn" onClick={() => {
                  this.props.delete_cnferm("yes")
                }}>{__t("Yes")}</button>
                <button className="btn btn-success info_btn" onClick={() => {
                  this.props.delete_cnferm("no")
                }} >{__t("No")}</button>
              </div>
            </div>
              </div>

        )
    }
}