import React from "react";
import { Link } from "react-router-dom";
import Utility from "../common/Utility";
import { __t } from "../../locale/language";
import { __currency } from "../../locale/language";
import Can from "../common/AccessControl";
import Moment from "moment";
import TransactionModal from "./transactionModal";
import $ from "jquery";
class TransactionList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      page: 1,
      tranxData: [],
      sizePerPage: 10,
      total: 0,
      search: "",
      resellerData: [],
      clientData: [],
      edititem: {},
      modal: false,
      status: "",
      transactions_count: "",
      rev_data: "",
      user_type: this.props.userPermissions.user_type,
      reseller_id: this.props.userPermissions.user_type == "reseller" ? localStorage.getItem("client_id") : "",
      search_client_id: "",
      column: ["tranx_id", "mode", "amount", "client", "status", "plan", "email", "date", "paid_unpaid", "refund"]
    };
  }
  componentWillMount() {
    Utility.ExecuteData("get-tranx-data", this.token, { client_list: "y", reseller_id: this.state.reseller_id }).then(data => {
      if (data) {
        var clientlist = [];
        var id = this.state.clientid == "" ? null : this.state.clientid;
        if (this.state.user_type == "reseller") {
          clientlist = typeof data.clients_list[0] !== "undefined" ? data.clients_list[0].client_list : [];
        }
        var r_data = data.tranx.rev_data;
        var sum = 0;
        r_data.map((val, k) => {
          sum += val.amount;
        });
        this.setState({
          rev_data: sum,
          clientData: clientlist,
          reseller_id: id,
          resellerData: data.clients_list,
          tranxData: data.tranx.tranx_list,
          total: data.tranx.count
        });
      }
    });
    this.getTransactionCount();
  }
  getTransactionCount = () => {
    Utility.ExecuteData("getTransactionCount", this.state.token, {
      token: this.state.token
    }).then(data => {
      //console.log(data);
      this.setState({
        transactions_count: data.data[0]
      });
    });
  };
  handleChange = event => {
    this.setState({ [event.target.name]: event.target.value });
  };

  toggle = update => {
    if (update) {
      this.updateTable({
        reseller_id: this.state.reseller_id,
        search_client_id: this.state.search_client_id,
        search: this.state.search,
        status: this.state.status,
        page: this.state.page,
        modal: !this.state.modal
      });
    } else {
      this.setState({ modal: !this.state.modal });
    }
  };
  editElement = (elem, type) => {
    this.setState({ modal: true, edititem: elem, type: type }, () => {});
  };

  updateTable = search => {
    //console.log(search);
    Utility.ExecuteData("get-tranx-data", this.token, search).then(data => {
      let clientlist = this.state.clientData;
      if (search.reseller_id != this.state.reseller_id) {
        let id = search.reseller_id == "" ? null : search.reseller_id;
        this.state.resellerData.map((val, k) => {
          if (val.reseller_id == id) {
            clientlist = val.client_list;
          }
        });
      }
      var r_data = data.tranx.rev_data;
      var sum = 0;
      r_data.map((val, k) => {
        sum += val.amount;
      });
      //console.log(sum);
      this.setState({
        rev_data: sum,
        tranxData: data.tranx.tranx_list,
        total: data.tranx.count,
        page: 1,
        clientData: clientlist,
        ...search
      });
    });
  };
  render() {
    var transactions;
    if (typeof this.state.transactions_count != "undefined") {
      transactions = this.state.transactions_count;
    }

    return (
      <Can I={["view-transaction"]} userPermissions={this.props.userPermissions.userPermissions}>
        <div>
          <div className="row wrapper border-bottom white-bg page-heading">
            <div className="col-lg-8">
              <h2 style={{ marginTop: 10 }}>{__t("transaction_list")}</h2>
              <ol className="breadcrumb">
                <li className="breadcrumb-item">
                  <Link to="/">{__t("dashboard")}</Link>
                </li>
                <li className="breadcrumb-item">
                  <Link to="/transaction">{__t("transaction")}</Link>
                </li>
              </ol>
            </div>
            <div className="col-lg-4">
              <h2 style={{ marginTop: 10 }}>
                {__t("total_tranx")} : <strong>{this.state.total}</strong>
              </h2>

              <p style={{ marginTop: 10 }}>
                {__t("total_revenue")}: {__currency()}
                {this.state.rev_data}
                <strong />
              </p>
            </div>
          </div>
          {/* <div className="row wrapper border-bottom white-bg page-heading flex_end">
            <div className="col-lg-3">
              <h2 className="no-margins">
                {transactions.Total}/{transactions.Total == 0 ? "0" : transactions.Succeeded}/
                  {transactions.Total == 0 ? "0" : transactions.Failed}
              </h2>
              <div className="stat-percent font-bold text-success" />
              <h4>
                {__t("total")}/{__t("completed")}/{__t("failed")}
              </h4>
            </div>
          </div> */}
          <div className="wrapper wrapper-content">
            <div>
              <div className="col-lg-12">
                <div className="ibox">
                  <div className="ibox-title">
                    <div className="row from-control">
                      &nbsp; &nbsp; &nbsp;
                      {this.props.userPermissions.user_type == "admin" ? (
                        <div className="row_flex">
                          <select
                            onChange={e => {
                              let reseller_id = e.target.value;
                              this.updateTable({ reseller_id: reseller_id, search: this.state.search, status: this.state.status, page: 1 });
                            }}
                            value={this.state.reseller_id}
                            id="resellerlist"
                            className="form-control"
                          >
                            <option value="">--{__t("select_reseller")}--</option>

                            {this.state.resellerData
                              ? this.state.resellerData.map((val, i) => {
                                  // if (val.reseller_name == null) {
                                  //   return (
                                  //     <option value="self" key={i}>
                                  //       {__t("by_self")}
                                  //     </option>
                                  //   );
                                  // } else {
                                  if (val.reseller_name != null) {
                                    return (
                                      <option value={val.reseller_id} key={i}>
                                        {val.reseller_name}
                                      </option>
                                    );
                                  }

                                  // }
                                })
                              : ""}
                          </select>
                        </div>
                      ) : (
                        ""
                      )}
                      <div className="row_flex">
                        &nbsp;
                        <select
                          id="clientlist"
                          onChange={e => {
                            let client_id = e.target.value;
                            this.updateTable({ reseller_id: this.state.reseller_id, search_client_id: client_id, search: this.state.search, status: this.state.status, page: 1 });
                          }}
                          value={this.state.search_client_id}
                          className="form-control"
                        >
                          <option value="">--{__t("select_client")}--</option>
                          {this.state.clientData
                            ? this.state.clientData.map(i => {
                                return (
                                  <option value={i.id} key={i.id}>
                                    {i.name}
                                  </option>
                                );
                              })
                            : ""}
                        </select>
                      </div>
                      <div className="row_flex">
                        &nbsp;
                        <select
                          id="status"
                          onChange={e => {
                            let status = e.target.value;
                            this.updateTable({ reseller_id: this.state.reseller_id, search_client_id: this.state.search_client_id, search: this.state.search, status: status, page: 1 });
                          }}
                          value={this.state.status}
                          className="form-control"
                        >
                          <option value="">--{__t("select_status")}--</option>
                          <option value="completed">{__t("completed")}</option>
                          <option value="started">{__t("started")}</option>
                          <option value="failed">{__t("failed")}</option>
                        </select>
                      </div>
                      &nbsp;
                      <input
                        className="form-control col-md-3"
                        type="text"
                        name="search"
                        value={this.state.search}
                        id="search_field"
                        onChange={this.handleChange}
                        placeholder={__t("search_by_plan_or_email")}
                      />
                      &nbsp;
                      <input
                        className="btn btn-primary"
                        type="button"
                        onClick={() => {
                          {
                            this.updateTable({ reseller_id: this.state.reseller_id, search_client_id: this.state.search_client_id, search: this.state.search, status: this.state.status, page: 1 });
                          }
                        }}
                        name="btn_search"
                        value={__t("search")}
                      />
                      &nbsp;
                      <input
                        className="btn btn-warning"
                        type="button"
                        onClick={() => {
                          this.updateTable({ reseller_id: "", search_client_id: "", search: "", status: "", page: 1 });
                        }}
                        name="btn_search"
                        value={__t("clear")}
                      />
                    </div>
                  </div>
                  <div className="ibox-content">
                    {this.state.total > 0 ? (
                      <div className={""}>
                        <table className={"table table-striped table-hover table-bordered table-condensed tbl_word_break"}>
                          <thead>
                            <tr>
                              {this.state.column.map(th => {
                                if (this.state.user_type == "reseller" && false === ["paid_unpaid", "refund"].includes(th)) {
                                  return <th key={th}>{__t(th)}</th>;
                                }
                                if (this.state.user_type == "admin") {
                                  return <th key={th}>{__t(th)}</th>;
                                }
                              })}
                            </tr>
                          </thead>
                          <tbody>
                            {this.state.tranxData.map(v => {
                              var t_status = "";
                              if (v.transaction_status == "succeeded" || v.transaction_status == "approved") {
                                t_status = __t("completed");
                              } else if (v.transaction_status == "cancelled") {
                                t_status = __t("failed");
                              } else {
                                t_status = __t(v.transaction_status);
                              }
                              return (
                                <tr key={v.transaction_id}>
                                  <td>{v.transaction_id}</td>
                                  <td>{v.payment_mode}</td>
                                  <td>
                                    {__currency()}
                                    {v.amount}
                                  </td>
                                  <td>
                                    {v.client_name} {v.client_status == "deleted" ? <span>({__t(v.client_status)})</span> : ""}
                                  </td>
                                  <td>{t_status}</td>

                                  <td>{v.title}</td>
                                  <td>{v.email}</td>
                                  <td>{Moment(v.transaction_date).format("DD-MMM-YYYY HH:mm:ss")}</td>
                                  {this.state.user_type == "admin" ? <td>{this.getPaidBtn(v)}</td> : ""}
                                  {this.state.user_type == "admin" ? <td>{this.getRefundBtn(v)}</td> : ""}
                                </tr>
                              );
                            })}
                          </tbody>
                        </table>
                        {this.pagination(this.state.page, this.state.sizePerPage, this.state.total, this.updateTablePage)}
                      </div>
                    ) : (
                      __t("no_record_found")
                    )}
                  </div>
                </div>
              </div>
              <div className="min300">
                <TransactionModal isOpen={this.state.modal} item={this.state.edititem} toggle={this.toggle} type={this.state.type} />
              </div>
            </div>
          </div>
        </div>
      </Can>
    );
  }
  updateTablePage = page => {
    this.updateTable({ reseller_id: this.state.reseller_id, search_client_id: this.state.search_client_id, search: this.state.search, status: this.state.status, page: page });
  };
  pagination = (page, sizePerPage, total, updateTable) => {
    if (total <= 0) {
      return "";
    }
    let total_page = Math.ceil(total / sizePerPage);
    let pages = [];
    for (let i = 1; i <= total_page; i++) {
      pages.push(
        <li key={i} className={page == i ? "page-item active" : "page-item"}>
          <a
            className="page-link"
            onClick={e => {
              updateTable(i);
              e.preventDefault();
            }}
          >
            {i}
          </a>
        </li>
      );
    }
    return (
      <nav aria-label="Page navigation example">
        <ul className="pagination justify-content-end">
          <li className={page <= 1 ? "page-item disabled" : "page-item"} disabled={page <= 1 ? "disabled" : ""}>
            <a
              className="page-link"
              onClick={e => {
                if (page > 1) {
                  updateTable(page - 1);
                }
                e.preventDefault();
              }}
            >
              {__t("back")}
            </a>
          </li>
          {pages}

          <li className={page == total_page ? "page-item disabled" : "page-item"} disabled={page == total_page ? "disabled" : ""}>
            <a
              className="page-link"
              onClick={e => {
                if (page < total_page) {
                  updateTable(page + 1);
                }
                e.preventDefault();
              }}
            >
              {__t("next")}
            </a>
          </li>
        </ul>
      </nav>
    );
  };
  getRefundBtn = row => {
    if (row.transaction_status == "approved" || row.transaction_status == "succeeded") {
      if (row.refund == "0") {
        return (
          <button id={"refund_btn" + row.id} onClick={() => this.editElement(row, "refund")} className="btn btn-primary btn-sm">
            {__t("refund")}
          </button>
        );
      } else {
        return (
          <button id={"refund_label" + row.id} className="btn btn-primary btn-sm" onClick={() => this.editElement(row, "refund_reverse")}>
            {__t("refunded")}
            {/* <i className="fa fa-check" aria-hidden="true" /> */}
          </button>
        );
      }
    } else if (row.transaction_status == "cancelled" || row.transaction_status == "started") {
      return <label>{__t("n_a")}</label>;
    }
  };
  getPaidBtn = row => {
    if (row.transaction_status == "approved" || row.transaction_status == "succeeded") {
      if (row.paid_status == "0") {
        return (
          <button id={"pay_btn" + row.id} onClick={() => this.editElement(row, "pay")} className="btn btn-primary btn-sm">
            {__t("unpaid")}{" "}
          </button>
        );
      } else {
        return (
          <button id={"pay_label" + row.id} className="btn btn-primary btn-sm" onClick={() => this.editElement(row, "unpay")}>
            {__t("paid")} <i className="fa fa-check" aria-hidden="true" />
          </button>
        );
      }
    } else if (row.transaction_status == "cancelled") {
      return <label>{__t("cancelled")} </label>;
    } else if (row.transaction_status == "started") {
      return <label>{__t("not_completed")} </label>;
    }
  };
}

export default TransactionList;
