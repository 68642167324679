import React from "react";
import { Link } from "react-router-dom";
import RemotePagination from "../common/Table";
import Utility from "../common/Utility";
import Spinner from "../common/Spinner";
import { __t } from "../../locale/language";
import Can from "../common/AccessControl";
import Moment from "moment";
import $ from "jquery";
import CommonDelete from "../common/CommonDelete"

class UserList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      page: 1,
      data: [],
      sizePerPage: 10,
      total: 0,
      search: "",
      client_id: localStorage.getItem("client_id"),
      user_type: this.props.userPermissions.user_type,
      all_client: "",
      customList: "",
      resellerList: "",
      select_type: "",
      delete_id: ""
    };
    
    this.updateUserList = this.updateUserList.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.selectUser = this.selectUser.bind(this);
    this.getUserListByName = this.getUserListByName.bind(this);
    this.customClientList = this.customClientList.bind(this);
    this.getclientData = this.getclientData.bind(this);
  }

  delete_cnferm=(type)=> {
    //console.log(type)
    //console.log(this.state.delete_id)
    if (type == "yes") {
      this.deleteUser(this.state.delete_id)
    }
    else {
      $("#alertshow").hide();
    }
  }
  delete=(id)=> {
    this.setState({
      delete_id: id
    }, () => {
      $("#alertshow").css("display", "block")
    })
  }
  deleteUser=(id)=> {
    id = { "id": id }
    Utility.ExecuteData("deleteuser", this.token, id)
      .then(responseJson => {
        //console.log(responseJson)
        if (responseJson.code === 200) {
          //console.log(responseJson)
          this.updateUserList(1, 10);
          // this.setState({ redirect: true });
        } else {
          alert(__t("try_again"));
        }
      })
      .catch(error => {
        this.setState({ errors: true });
      });
  }
  handleChange(event) {
    this.setState({ [event.target.name]: event.target.value });
  }
  client_id = localStorage.getItem("client_id");
  renderButtons(cell, row, rowIndex) {

    return (
      <React.Fragment>
        {this.state.user_type == "admin" ? (
          <Can I={["update-user"]} userPermissions={this.props.userPermissions.userPermissions}>
            <Link
              to={{
                pathname: `/all-user/update/${row.id}`,
                state: { id: `${row.id}` }
              }}
              className="btn btn-primary btn-sm"
            >
              <i className="fa fa-pencil" aria-hidden="true" /> {__t("edit")}
            </Link>
          </Can>
        ) : (
            ""
          )}
            &nbsp;
        {
          row.status!="deleted" ?
          row.id != this.state.client_id ?
          
            <button onClick={() => this.delete(`${row.id}`)}
              className="btn btn-primary btn-sm"
            >
              <i className="fa fa-trash" aria-hidden="true" />
            </button> : "":""
        }


        <Can I={["delete-user"]} userPermissions={this.props.userPermissions.userPermissions}>
          {this.state.user_id && this.state.user_id != row.id ? (
            <button onClick={() => this.delete(`${row.id}`)} className="btn btn-primary btn-sm">
              <i className="fa fa-trash" aria-hidden="true" />
            </button>
          ) : (
            ""
          )}
        </Can>
      </React.Fragment>
    );
  }
  token = localStorage.getItem("token");
  handleTableChange = (type, { page, sizePerPage }) => {
    const currentIndex = (page - 1) * sizePerPage;
    //write fetch data function
    this.setState({
      data: ""
    });
    var filter_type = $("#clientList").val();
    
    if (filter_type == "self" || filter_type == "admin") {
      this.customClientList(filter_type, page, sizePerPage);
    } else if (filter_type == "select" || filter_type == "") {
      this.updateUserList(page, sizePerPage);
    } else {
      Utility.ExecuteData("getclientuser", this.token, {
        page: this.state.page,
        limit: this.state.sizePerPage,
        search: this.state.search,
        client_id: filter_type
      }).then(data => {
       
        this.setState(() => ({
          data: data.data.rows,
          total: data.data.count,
          clientList: data.clientList.rows,
          all_client: filter_type,
          customList: data.clientList.rows,
          page,
          sizePerPage
        }));
      });
    }
  };
  updateUserList(page = 1, sizePerPage = 10) {
    $("#alertshow").hide();
    Utility.ExecuteData("getalluser", this.token, {
      page: page,
      limit: sizePerPage,
      search: this.state.search,
      client_id: this.state.client_id
    }).then(data => {
      
      this.setState(() => ({
        page,
        data: data.data.rows,
        sizePerPage,
        total: data.data.count,
        customList: data.clientList.rows,
        resellerList: data.resellerlist.rows
      }));
    });
  }
  getUserListByName() {
    Utility.ExecuteData("getuserbyname", this.token, {
      page: this.state.page,
      limit: this.state.sizePerPage,
      search: this.state.search,
      client_id: this.state.all_client
    }).then(data => {
      
      this.setState(() => ({
        data: data.data.rows,
        total: data.data.count,
        clientList: data.clientList.rows
      }));
    });
  }
  componentDidMount() {
    this.updateUserList(1, 10);
  }
  selectUser(e) {
    
    var val = e.target.value;
    if (val == "admin" || val == "self") {
      this.customClientList(val, 1, 10);
      this.setState({
        select_type: val
      });
    } else if (val == "") {
      $("#custom_list").val("select");
      this.setState({
        select_type: val
      });
      this.updateUserList(1, 10);
    } else {
      Utility.ExecuteData("getclientuser", this.token, {
        page: this.state.page,
        limit: this.state.sizePerPage,
        search: this.state.search,
        client_id: e.target.value
      }).then(data => {
        this.setState(() => ({
          data: data.data.rows,
          total: data.data.count,
          clientList: data.clientList.rows,
          all_client: val,
          customList: data.clientList.rows
        }));
      });
    }
  }
  getclientData(e) {
    var val = e.target.value;
    Utility.ExecuteData("getclientuser", this.token, {
      page: this.state.page,
      limit: this.state.sizePerPage,
      search: this.state.search,
      client_id: e.target.value
    }).then(data => {
      this.setState(() => ({
        data: data.data.rows,
        total: data.data.count,
        //clientList: data.clientList.rows,
        all_client: val
      }));
    });
  }
  customClientList(type, page = 1, sizePerPage = 10) {
    // //console.log(type)
    Utility.ExecuteData("getcustomclientist", this.token, {
      client_id: this.state.client_id,
      user_type: type,
      page: page,
      limit: sizePerPage
    }).then(data => {
      // //console.log(data);
      if (data) {
        this.setState({
          customList: data.data,
          data: data.data,
          total: data.count[0].count,
          page,
          sizePerPage,
          select_type: type
        });
      }
    });
  }
  render() {
    const { data, sizePerPage, page, total } = this.state;

    const columns = [
      { dataField: "first_name", text: __t("first_name") },
      { dataField: "email", text: __t("email") },
      { dataField: "status", text: __t("status") },
      {
        dataField: "cell_number",
        text: __t("mobile_number"),
        formatter: cellContent => (cellContent ? cellContent : "NA")
      },

      {
        dataField: "updated_at",
        text: __t("updated_at"),
        formatter: cellContent => Moment(cellContent).format("DD-MMM-YYYY HH:mm:ss")
      },
      {
        dataField: "action",
        text: "Action",
        formatter: this.renderButtons.bind(this)
      }
    ];

    return (
      <Can I={["all-user"]} userPermissions={this.props.userPermissions.userPermissions}>
        <div>
        <CommonDelete delete_cnferm={this.delete_cnferm} />
          <div className="row wrapper border-bottom white-bg page-heading">
            <div className="col-lg-10">
              <h2 style={{ marginTop: 10 }}>{__t("all_user_list")}</h2>
              <ol className="breadcrumb">
                <li className="breadcrumb-item">
                  <Link to="/">{__t("dashboard")}</Link>
                </li>
                <li className="breadcrumb-item">
                  <Link to="/all-user">{__t("all_user")}</Link>
                </li>
                <li className="breadcrumb-item active">
                  <strong>{__t("user_list")}</strong>
                </li>
              </ol>
            </div>
          </div>
          <div className="wrapper wrapper-content animated fadeInRight">
            <div className="row">
              <div className="col-lg-12 ">
                <div className="ibox ">
                  <div className="ibox-title">
                    <div className="row from-control">
                      &nbsp; &nbsp;
                      <select className="form-control col-md-3" id="clientList" onChange={this.selectUser} name="all_client" defaultValue={this.state.all_client}>
                        <option value="">{__t("select")}</option>
                        {/* <option value="self">{__t("self_registered")}</option> */}
                        <option value="1">{__t("admin_caps")}</option>
                        {this.state.resellerList
                          ? this.state.resellerList.map(i => {
                            if (i.id != "1") {
                              return (
                                <option key={i.id} value={i.id}>
                                  {i.name}
                                </option>
                              );
                            }
                          })
                          : ""}
                      </select>
                      &nbsp;
                      <select className="form-control col-md-3" id="custom_list" onChange={this.getclientData} name="custom_client">
                        <option value="select">{__t("select_client")}</option>

                        {this.state.customList
                          ? this.state.customList.map(m => {
                            return (
                              <option key={m.id} value={m.id}>
                                {m.name}
                              </option>
                            );
                          })
                          : ""}
                      </select>
                      &nbsp;
                      <input className="form-control col-md-3" type="text" name="search" id="search_field" onChange={this.handleChange} placeholder={__t("search_by_name_email")} />
                      &nbsp;
                      <input
                        className="btn btn-primary"
                        type="button"
                        onClick={() => {
                          this.getUserListByName();
                        }}
                        name="btn_search"
                        value={__t("search")}
                      />
                      &nbsp;&nbsp; &nbsp;
                      <input
                        className="btn btn-warning"
                        type="button"
                        onClick={() => {
                          this.setState({ search: "", all_client: "" }, () => {
                            this.updateUserList();
                            document.getElementById("search_field").value = "";
                            $("#custom_list").val("select");
                            $("#clientList").val("");
                          });
                        }}
                        name="btn_search"
                        value={__t("clear")}
                      />
                      &nbsp; &nbsp;
                      <strong style={{ marginTop: "10px" }}>
                        {__t("total_users")}:{this.state.total}
                      </strong>
                    </div>
                  </div>

                  <div className="ibox-content">
                    <Spinner />
                    {this.state.total > 0 ? (
                      <RemotePagination columns={columns} data={data} page={page} sizePerPage={sizePerPage} totalSize={total} onTableChange={this.handleTableChange} />
                    ) : (
                        __t("no_record_found")
                      )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Can>
    );
  }
}

export default UserList;
