import React from "react";
import { Link } from "react-router-dom";
import RemotePagination from "../common/Table";
import Utility from "../common/Utility";
import Spinner from "../common/Spinner";
import { __t } from "../../locale/language";
import Can from "../common/AccessControl";
import Moment from "moment";
import $ from "jquery";
import CommonDelete from "../common/CommonDelete"
class UserList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      page: 1,
      data: [],
      sizePerPage: 10,
      total: 0,
      search: "",
      client_id: localStorage.getItem("client_id"),
      user_id:JSON.parse(localStorage.getItem("lgn_dtl")).id,
      user_type: this.props.userPermissions.user_type,
      delete_id: ""
    };
    this.deleteUser = this.deleteUser.bind(this);
    this.updateUserList = this.updateUserList.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.delete_cnferm = this.delete_cnferm.bind(this);
    this.delete = this.delete.bind(this);
  }
  delete_cnferm(type) {
    //console.log(type)
    //console.log(this.state.delete_id)
    if (type == "yes") {
      this.deleteUser(this.state.delete_id)
    }
    else {
      $("#alertshow").hide();
    }
  }
  delete(id) {
    this.setState({
      delete_id: id
    }, () => {
      $("#alertshow").css("display", "block")
    })
  }

  deleteUser(id) {
   
    id = { "id": id }
    Utility.ExecuteData("deleteuser", this.token, id)
      .then(responseJson => {
        //console.log(responseJson)
        if (responseJson.code === 200) {
          //console.log(responseJson)
          this.updateUserList(1, 10);
          // this.setState({ redirect: true });
        } else {
          alert(__t("try_again"));
        }
      })
      .catch(error => {
        this.setState({ errors: true });
      });

  };
  handleChange(event) {
    this.setState({ [event.target.name]: event.target.value });
  }
  client_id = localStorage.getItem("client_id");
  renderButtons(cell, row, rowIndex) {
    //console.log(row)
    //console.log(this.state.user_id)
    return (
      <React.Fragment>
        {this.props.userPermissions.subscription_status == "active" ||
          (this.state.user_type == "admin" ||
            this.state.user_type == "reseller") ? (
            <Can
              I={["update-user"]}
              userPermissions={this.props.userPermissions.userPermissions}
            >

              < Link
                to={{
                  pathname: `/user/update/${row.id}`,
                  state: { id: `${row.id}` }
                }}
                className="btn btn-primary btn-sm" style={{ "marginRight": "10px" }}
              >
                <i className="fa fa-pencil" aria-hidden="true" />
              </Link>

            </Can>

          ) : (
            ""
          )
        }
        <Can
          I={["delete-user"]}
          userPermissions={this.props.userPermissions.userPermissions}
        >
        {
         this.state.user_id  && this.state.user_id != row.id ?
              <button onClick={() => this.delete(`${row.id}`)}
                className="btn btn-primary btn-sm"
              >
                <i className="fa fa-trash" aria-hidden="true" />
              </button> : ""
          }

        </Can>
      </React.Fragment>
    );
  }
  token = localStorage.getItem("token");
  handleTableChange = (type, { page, sizePerPage }) => {
    const currentIndex = (page - 1) * sizePerPage;
    //write fetch data function
    this.updateUserList(page, sizePerPage);
  };
  updateUserList(page = 1, sizePerPage = 10) {
    $("#alertshow").hide();
    Utility.ExecuteData("userdata", this.token, {
      page: page,
      limit: sizePerPage,
      search: this.state.search,
      client_id: this.state.client_id,
      user_type: this.state.user_type
    }).then(data => {
      //console.log(data)
      this.setState(() => ({
        page,
        data: data.data.rows,
        sizePerPage,
        total: data.data.count
      }));
    });
  }
  componentDidMount() {
    this.updateUserList(1, 10);
  }
  render() {
    //console.log( JSON.parse(localStorage.getItem("lgn_dtl")).id)
    const { data, sizePerPage, page, total } = this.state;
    const columns = [
      { dataField: "first_name", text: __t("first_name") },
      // { dataField: "last_name", text: __t("last_name") },
      { dataField: "email", text: __t("email") },
      { dataField: "status", text: __t("status") },
      { dataField: "role", text: __t("roles") },
      {
        dataField: "cell_number",
        text: __t("mobile_number"),
        formatter: cellContent => (cellContent ? cellContent : "NA")
      },
      // {
      //   dataField: "created_at",
      //   text: __t("created_at"),
      //   formatter: cellContent =>
      //     Moment(cellContent).format("DD-MMM-YYYY HH:mm:ss")
      // },
      {
        dataField: "updated_at",
        text: __t("updated_at"),
        formatter: cellContent =>
          Moment(cellContent).format("DD-MMM-YYYY HH:mm:ss")
      },
      {
        dataField: "action",
        text: "Action",
        formatter: this.renderButtons.bind(this)
      }
    ];

    return (
      <Can
        I={["view-user"]}
        userPermissions={this.props.userPermissions.userPermissions}
      >
        <div>
          <CommonDelete delete_cnferm={this.delete_cnferm} />
          <div className="row wrapper border-bottom white-bg page-heading">
            <div className="col-lg-10">
              <h2 style={{ marginTop: 10 }}>{__t("user_list")}</h2>
              <ol className="breadcrumb">
                <li className="breadcrumb-item">
                  <Link to="/">{__t("dashboard")}</Link>
                </li>
                <li className="breadcrumb-item">
                  <Link to="/user">{__t("user")}</Link>
                </li>
                <li className="breadcrumb-item active">
                  <strong>{__t("user_list")}</strong>
                </li>
              </ol>
            </div>
          </div>
          <div className="wrapper wrapper-content animated fadeInRight">
            <div className="row">
              <div className="col-lg-12 ">
                <div className="ibox ">
                  <div className="ibox-title">
                    <div className="row from-control">
                      {" "}
                      &nbsp; &nbsp; &nbsp;
                      <input
                        className="form-control col-md-3"
                        type="text"
                        name="search"
                        id="search_field"
                        onChange={this.handleChange}
                        placeholder={__t("search_by_name_email")}
                      />
                      &nbsp;
                      <input
                        className="btn btn-primary"
                        type="button"
                        onClick={() => {
                          this.updateUserList();
                        }}
                        name="btn_search"
                        value={__t("search")}
                      />
                      &nbsp;
                      <input
                        className="btn btn-warning"
                        type="button"
                        onClick={() => {
                          this.setState({ search: "" }, () => {
                            this.updateUserList();
                            document.getElementById("search_field").value = "";
                          });
                        }}
                        name="btn_search"
                        value={__t("clear")}
                      />
                      &nbsp;
                      {this.props.userPermissions.subscription_status ==
                        "active" ||
                        this.state.user_type == "admin" ||
                        this.state.user_type == "reseller" ? (
                          <Can
                            I={["create-user"]}
                            userPermissions={
                              this.props.userPermissions.userPermissions
                            }
                          >
                            <Link className="btn btn-primary" to="/user/create">
                              {__t("create_user")}
                            </Link>
                          </Can>
                        ) : (
                          ""
                        )}
                      <br />
                      &nbsp; &nbsp;
                      <strong style={{ marginTop: "10px" }}>
                        {__t("total_users")} : {this.state.total}
                      </strong>
                    </div>
                  </div>
                  <div className="ibox-content">
                    <Spinner />
                    {this.state.total > 0 ? (
                      <RemotePagination
                        columns={columns}
                        data={data}
                        page={page}
                        sizePerPage={sizePerPage}
                        totalSize={total}
                        onTableChange={this.handleTableChange}
                      />
                    ) : (
                        __t("no_record_found")
                      )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Can>
    );
  }
}

export default UserList;
