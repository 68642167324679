import React from "react";
import Utility from "../common/Utility";
import { __t } from "../../locale/language";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import Moment from "moment";

class TransactionModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      modal: false,
      item: this.props.item,
      type: this.props.type,
      token: localStorage.getItem("token"),
      note: ""
    };

    this.handleChange = this.handleChange.bind(this);
    this.submit = this.submit.bind(this);
    this.handleChange = this.handleChange.bind(this);
  }
  handleChange(event) {
    this.setState({ [event.target.name]: event.target.value });
  }
  componentWillReceiveProps({ isOpen, item, type }) {
    if (typeof item != "undefined") {
      this.setState({ modal: isOpen, item: item, type: type });
    }
  }
  shouldComponentUpdate(nextProps, nextState) {
    if (this.state.modal == nextState.modal) {
      return false;
    } else {
      return true;
    }
  }
  submit(evt) {
    let message = "";
    if (this.state.type == "pay") {
      message = __t("payment_paid");
    } else if (this.state.type == "unpay") {
      message = __t("payment_unpaid");
    } else if (this.state.type == "refund") {
      message = __t("payment_is_refunded");
    } else {
      message = __t("refund_is_reversed");
    }
    var today = new Date();
    let notObj = {
      date: today,
      text: this.state.note,
      message: message,
      type: this.state.type == "pay" || this.state.type == "unpay" ? "paid_note" : "refund_note"
    };

    // if (this.state.type == "pay") {
    // if (this.state.type == "pay" || this.state.type == "unpay") {
    //   obj = [{
    //     paid_note: this.state.note, refund_note: ""
    //   }]
    // }
    // else {
    //   obj = [{
    //     paid_note: "", refund_note: this.state.note
    //   }]
    // }

    // return false
    let promise = new Promise((resolve, reject) => {
      Utility.ExecuteData("transactionStatusUpdate", this.state.token, {
        token: this.state.token,
        id: evt.item.id,
        type: this.state.type,
        note: notObj
      })
        .then(responseJson => {
          this.setState({
            note: ""
          });
          resolve("updated");
        })
        .catch(error => {
          alert("internal error");
        });
    });
    promise.then(message => {
      this.props.toggle(true);
    });
  }

  render() {
    let item = this.state.item;
    var quote = "";
    var button_title = "";
    if (this.state.type == "pay") {
      quote = __t("payment_not_paid");
      button_title = __t("pay");
    } else if (this.state.type == "unpay") {
      quote = __t("payment_pay");
      button_title = __t("reverse");
    } else if (this.state.type == "refund") {
      quote = __t("payment_refunded");
      button_title = __t("refund");
    } else {
      quote = __t("payment_refund_reverse");
      button_title = __t("refund_reversed");
    }
    var item_note = "";
    if (typeof item != "undefined") {
      if (typeof item.note != "undefined" && item.note != "" && item.note != null) {
        item_note = JSON.parse(item.note);
      }

      var body = (
        <ModalBody>
          {item_note ? (
            <div>
              <div className="ibox-title flex_center border_0 flex_start padding0">
                {/* <h5>{this.state.type == "pay" ? __t("payment_not_paid") : __t("payment_not_refunded")}</h5> */}
                <h5 className="heading_font">{__t("previous_activity")}</h5>
              </div>
              <div className="col-md-12">
                <div className="col-lg-12 message_wrapp">
                  {item_note
                    ? item_note.map((val, k) => {
                        return (
                          <div key={k}>
                            {val.text ? (
                              <h3>
                                {__t("note")}: {val.text}
                              </h3>
                            ) : (
                              ""
                            )}
                            {val.message}
                            {__t("on")} {Moment(val.date).format("DD-MMM-YYYY HH:mm:ss")}
                          </div>
                        );
                      })
                    : ""}
                </div>
                &nbsp;&nbsp;&nbsp;
              </div>
            </div>
          ) : (
            ""
          )}
          <div className="note_box">
            <label>{__t("note")}:</label>
            <textarea name="note" className={"form-control"} onChange={this.handleChange} />
          </div>

          <div className="flex_center" style={{ marginTop: "10px" }}>
            <button
              className="btn btn-default btn-sm col-sm-2"
              onClick={() => {
                this.submit(this.state);
              }}
            >
              {button_title}
            </button>

            <button
              className="btn btn-default btn-sm col-sm-2"
              onClick={() => {
                this.props.toggle(false);
              }}
              style={{ marginLeft: "5px" }}
            >
              {__t("close")}
            </button>
          </div>
        </ModalBody>
      );
    }

    return (
      <Modal isOpen={this.state.modal} toggle={() => this.props.toggle(false)} className={`modal-sm min300`}>
        <ModalHeader className="padding_1020 model-header">
          <label className="flex_align_center">{quote}</label>
        </ModalHeader>
        {body}
      </Modal>
    );
  }
}

export default TransactionModal;
