import React from "react";
import ClientList from "./ClientList";
import ClientUpdate from "./ClientUpdate";
import CheckOut from '../checkout/checkout'
import { Route, Switch } from "react-router-dom";
class Index extends React.Component {
  constructor(props) {
    super(props);
  }
  render() {
    return (
      <div>
        <Switch>
          <Route
            path="/client/update"
            render={props => (
              <ClientUpdate
                {...props}
                userPermissions={this.props.userPermissions}
              />
            )}
          />
          <Route
            path="/client/update/"
            render={props => (
              <ClientUpdate
                {...props}
                userPermissions={this.props.userPermissions}
              />
            )}
          />
          <Route
            path="/client/update/:id"
            render={props => (
              <ClientUpdate
                {...props}
                userPermissions={this.props.userPermissions}
              />
            )}
          />
          <Route
            path="/client/"
            render={props => (
              <ClientList
                {...props}
                userPermissions={this.props.userPermissions}
              />
            )}
          />
            <Route
            path="/checkout/:id"
            render={props =>
               (
              <CheckOut
              userPermissions={this.props.userPermissions}  {...props}
               />
            )}
          />
        </Switch>
      </div>
    );
  }
}

export default Index;
