import $ from 'jquery';
import { __t } from "../../locale/language";
import React from 'react';
import { Link } from "react-router-dom";
export default class SubscriptionAlert extends React.Component {
    constructor(props){
        super(props)
    }
    
    render() {
        return (
         
            <div className="alertoverlay1" id="subs_alert" style={{"display":"none"}}>

                <div className="alert_box success_back1">
                   
                    <h3 id="subs_text"> </h3>
                    {/* <link href="http://ppca.opalina.in/#" className="btn btn-success success_btn" >{__t("click_to_upgrade")}</link> */}
                    <Link to="/clientsubscription" className="btn btn-success success_btn" >{__t("click_to_upgrade")}</Link> 
                </div>
            </div>

        )
    }
}