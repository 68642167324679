import React from "react";
import $ from "jquery";
import { __t } from "../../locale/language";
import { label } from "../../label";
import Axios from "axios";

class Utility extends React.Component {
  constructor(props, context) {
    super(props);
  }

  PostData = (action, type) => {
    $(".gray-bg").addClass("sk-loading");
    return fetch(this.baseUrlBe() + action, { method: type })
      .then((response) => response.json())
      .then((responseJson) => {
        $(".gray-bg").removeClass("sk-loading");
        return responseJson;
      })
      .catch((error) => {
        $(".gray-bg").removeClass("sk-loading");
        window.alert("Internet Connection Error.");
        console.error(error);
      });
  };

  ExecuteData = (action, token, data, loading = true) => {
    let actkn = localStorage.getItem("token");
    if (loading) {
      $(".gray-bg").addClass("sk-loading");
    }
    return new Promise((resolve, reject) => {
      fetch(this.baseUrlBe() + action, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json; charset=utf-8",
          authorization: "Bearer " + actkn,
        },
        body: JSON.stringify(data),
      })
        .then((response) => response.json())
        .then((responseJson) => {
          $(".gray-bg").removeClass("sk-loading");

          if (responseJson.code == 403) {
            localStorage.clear();
            window.location.reload();
          } else {
            resolve(responseJson);
          }
        })
        .catch((error) => {
          if (loading) {
            $(".gray-bg").removeClass("sk-loading");
          }
          window.alert("Internet Connection Error.");
          console.error(error);
        });
    }).catch((error) => {
      console.error(error);
    });

    // $('.sk-spinner').css("display","none")
  };

  ExecuteFormData = (action, formData, loading = true) => {
    let actkn = localStorage.getItem("token");
    if (loading) {
      $(".gray-bg").addClass("sk-loading");
    }
    return new Promise((resolve, reject) => {
      const config = {
        headers: {
          // Accept: "application/json",
          "content-type":
            "multipart/form-data; application/json; charset=utf-8",
          authorization: "Bearer " + actkn,
        },
      };
      Axios.post(this.baseUrlBe() + action, formData, config)
        .then((response) => {
          resolve(response.data);
          if (loading) {
            $(".gray-bg").removeClass("sk-loading");
          }
        })
        .catch((error) => {
          console.error(error);
          if (loading) {
            $(".gray-bg").removeClass("sk-loading");
          }
          window.alert("Internet Connection Error.");
        });
    }).catch((error) => {
      console.error(error);
    });
  };

  async executeRequest(act, req, actkn, loading = true) {
    if (loading) {
      $(".gray-bg").addClass("sk-loading");
    }
    actkn = actkn || localStorage.getItem("token");
    actkn = typeof actkn != "undefined" ? "Bearer " + actkn : "";
    let head = {
      Accept: "application/json",
      "Content-Type": "application/json; charset=utf-8",
      authorization: actkn,
    };
    let promise = new Promise((resolve, reject) => {
      fetch(this.baseUrlBe() + act, {
        method: "POST",
        headers: head,
        body: JSON.stringify(req),
      })
        .then((response) => response.json())
        .then((responseJson) => {
          if (loading) {
            $(".gray-bg").removeClass("sk-loading");
          }
          if (typeof responseJson.code !== "undefined") {
            if (responseJson.code == 403) {
              localStorage.clear();
              window.location.reload();
            } else {
              resolve(responseJson);
            }
          } else {
            if (loading) {
              $(".gray-bg").removeClass("sk-loading");
            }
            window.alert("Internet connection err");
          }
        })
        .catch((error) => {
          if (loading) {
            $(".gray-bg").removeClass("sk-loading");
          }
          window.alert("Internet Connection Error.");
        });
    }).catch((error) => {
      console.error(error);
      localStorage.clear();
    });
    let result = await promise;
    return result;
  }

  getIdFromToken = (token) => {
    let userData = localStorage.getItem("lgn_dtl");
    userData = JSON.parse(userData);
    if (userData) {
      return userData.user_type;
    } else {
    }
  };

  GetData = (action, token) => {
    $(".gray-bg").addClass("sk-loading");
    let actkn = localStorage.getItem("token");
    return new Promise((resolve, reject) => {
      fetch(this.baseUrlBe() + action, {
        method: "Get",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          authorization: "Bearer " + actkn,
        },
      })
        .then((response) => response.json())
        .then((responseJson) => {
          $(".gray-bg").removeClass("sk-loading");
          if (typeof responseJson.code !== "undefined") {
            if (responseJson.code == 403) {
              localStorage.clear();
              //resolve(responseJson);
              window.location.reload();
            } else {
              resolve(responseJson);
            }
          } else {
            $(".gray-bg").removeClass("sk-loading");
            window.alert("Internet connection err");
          }
        })
        .catch((error) => {
          $(".gray-bg").removeClass("sk-loading");
          window.alert("Internet Connection Error.");
          console.error(error);
        });
    }).catch((error) => {
      console.error(error);
    });
  };

  GetUserPermission = (token) => {
    let actkn = localStorage.getItem("token");
    if (actkn !== null && actkn !== "") {
      return new Promise((resolve, reject) => {
        fetch(this.baseUrlBe() + "permission?token=" + actkn, {
          method: "Get",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            authorization: "Bearer " + token,
          },
        })
          .then((response) => response.json())
          .then((responseJson) => {
            if (typeof responseJson.code !== "undefined") {
              if (responseJson.code == 403) {
                localStorage.clear();
                window.location.reload();
                //resolve(responseJson);
              } else {
                resolve(responseJson);
              }
            } else {
              $(".gray-bg").removeClass("sk-loading");
              window.alert("Internet connection err");
            }
          })
          .catch((error) => {
            $(".gray-bg").removeClass("sk-loading");
            window.alert("Internet Connection Error.");
            console.error(error);
          });
      }).catch((error) => {
        console.error(error);
      });
    } else {
      //localStorage.clear();
      // window.location.reload();
    }
  };

  //function for capitaize first lette
  capitalize = (str) => {
    return str.charAt(0).toUpperCase() + str.slice(1);
  };

  // set base url
  baseUrlBe = () => {
    var baseurl = "https://api.pushpolitics.org/v1/";
    //  var baseurl = "http://localhost:5000/v1/";
    // var baseurl = "http://192.168.0.96:5000/v1/";

    return baseurl;
  };
  baseUrlFe = () => {
    var baseurl = "https://api.pushpolitics.org/v1/";
    // var baseurl = "http://localhost:5000/v1/";
    return baseurl;
  };
  uploadUrl = () => {
    return "https://admin.pushpolitics.org/upload/";
  };
  uploadUrl2 = () => {
    if (window.location && window.location.hostname.indexOf("csaeconnect.ca")) {
      return "https://admin.csaeconnect.ca/upload/";
    }
    return "https://pushpolitics.org/upload/";
  };
  getThumbimage = (img, ext) => {
    //console.log(img,ext)
    //var upload_url = "http://localhost:3000/upload/";
    var index = img.lastIndexOf("/") + 1;
    var filename = img.substr(index);
    // console.log(filename)
    var upload_url = "https://admin.pushpolitics.org/upload/";
    var img_name_ext = filename.substr(0, filename.lastIndexOf("."));
    //console.log(img_name_ext)

    var thumb_img = upload_url + img_name_ext + "_Small." + ext;
    return thumb_img;
  };

  progressBar = () => {
    var element = document.getElementById("myprogressBar");
    var width = 1;
    var identity = setInterval(scene, 10);
    function scene() {
      if (width >= 100) {
        clearInterval(identity);
      } else {
        width++;
        element.style.width = width + "%";
        element.innerHTML = width * 1 + "%";
      }
    }
  };

  checkSize = (imgsize) => {
    var image_size = "";
    var sizes = ["Bytes", "KB", "MB", "GB", "TB"];
    if (imgsize == 0) return "0 Byte";
    var i = parseInt(Math.floor(Math.log(imgsize) / Math.log(1024)));
    image_size = Math.round(imgsize / Math.pow(1024, i), 2) + " " + sizes[i];
    return image_size;
  };

  imageSize = (imgsize) => {
    var image_size = "";
    // var sizes = ["Bytes", "KB", "MB", "GB", "TB"];
    if (imgsize == 0) return "0 Byte";
    var i = parseInt(Math.floor(Math.log(imgsize) / Math.log(1024)));
    image_size = Math.round(imgsize / Math.pow(1024, i), 2);
    return image_size;
  };
  getUrlParameter(sParam) {
    var sPageURL = window.location.search.substring(1),
      sURLVariables = sPageURL.split("&"),
      sParameterName,
      i;
    console.log(sURLVariables.length);
    for (i = 0; i < sURLVariables.length; i++) {
      sParameterName = sURLVariables[i].split("=");

      if (sParameterName[0] === sParam) {
        console.log(sParameterName[1]);
        return sParameterName[1] === undefined
          ? true
          : decodeURIComponent(sParameterName[1]);
      }
    }
  }

  getLabel = (item) => {
    // console.log(label);
    let itm = item.toLowerCase();
    return label[itm] ? label[itm] : item;
  };
}
export default new Utility();
