import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import { Link } from "react-router-dom";
import $ from "jquery";
import Select from "react-select";
import DateTimePicker from "react-datetime-picker";
import ReactQuill from "react-quill";

import SelectImageModal from "./mediaModal";
import Utility from "../common/Utility";
import { __t } from "../../locale/language";
import Can from "../common/AccessControl";
import { generateFormElement } from "./renderForm";

class CampaignUpdate1 extends Component {
  constructor(props) {
    super(props);
    this.state = {
      redirect: false,
      title: "",
      sub_title: "",
      errors: {},
      description: "",
      publish_on: new Date(),
      client_id: localStorage.getItem("client_id"),
      status: "",
      letter_rotation_selector: "random",
      token: localStorage.getItem("token"),
      options: [],
      tags: [],
      selectedOption: [],
      selectedTag: [],
      widgets: "",
      mediaModal: false,
      end_date: "",
      image: "",
      is_header: "0",
      user_type: this.props.userPermissions.user_type,
      subscription_feature: this.props.userPermissions.subscription_feature,
      show_ga: false,
      show_adobe: false,
      show_generic: false,
      show_facebook: false,
      show_twitter: false,
      show_linkedin: false,
      representative_status: "yes",

      nationSlug: "",
    };
    this.generateForm = this.generateForm.bind(this);
  }
  generateForm = (form) => {
    let i = 0;
    return form.map((item) => {
      return <div>{generateFormElement(item)}</div>;
    });
  };
  mediaToggle = () => {
    this.setState({
      mediaModal: !this.state.mediaModal,
    });
  };
  setImageUrl = (url) => {
    this.setState({
      image: url,
    });
  };
  removeImage = () => {
    this.setState({
      image: "",
    });
  };

  getNationData = async () => {
    const action = "getbuilderconfig";
    const token = localStorage.getItem("token");
    const loginDetails = JSON.parse(localStorage.getItem("lgn_dtl"));
    const client_id = loginDetails.client_id;

    const dataa = {
      client_id: client_id,
    };

    try {
      const response = await Utility.ExecuteData(action, token, dataa, true);
      const { data } = response;

      if (response.code == 200) {
        const { nation_slug: nationSlug } = data;

        this.setState({
          nationSlug,
        });
      }
    } catch (error) {
      console.error("An error occurred:", error);
    }
  };

  findNationTags = async (token) => {
    let ref = this;

    let req = await Utility.ExecuteData("getnationtag", this.token, {
      token: token,
      nationSlug: this.state.nationSlug,
    });
    let tag = JSON.parse(req.data);
    console.log(tag.results);
    var option_arr = [];

    tag.results.map((val, key) => {
      option_arr.push({ value: val.name, label: val.name });
    });
    ref.setState({
      tags: option_arr,
    });
  };

  componentWillReceiveProps(nextProps) {
    console.log("Component received new props", nextProps);
    if (
      nextProps.nation_token &&
      this.props.nation_token != nextProps.nation_token
    ) {
      this.findNationTags(nextProps.nation_token);
    }
  }
  componentDidMount() {
    this.getNationData();
    if (
      typeof this.props.location.state != "undefined" &&
      this.props.location.state.id &&
      this.state.user_type != "client"
    ) {
      $("#title").text(__t("edit_campaign"));
      $("#title2").text(__t("edit_campaign"));
      this.findCampaign();
      this.findwidgets();
      this.getTrackingAndAnalytics();
    } else {
      if (
        this.state.subscription_feature.includes("campaign") &&
        this.state.user_type == "client"
      ) {
        if (
          typeof this.props.location.state == "undefined" &&
          this.state.user_type != "client"
        ) {
          this.setState({
            redirect: true,
          });
        } else {
          if (
            typeof this.props.location.state != "undefined" &&
            this.props.location.state.id
          ) {
            $("#title").text(__t("edit_campaign"));
            $("#title2").text(__t("edit_campaign"));
          }
          if (
            this.props.location.state &&
            typeof this.props.location.state !== "undefined"
          ) {
            this.findCampaign();
            this.getTrackingAndAnalytics();
          }
          this.findwidgets();
        }
      } else {
        this.setState({
          redirect: true,
        });
      }
    }
  }

  findwidgets = () => {
    Utility.ExecuteData("findallwidget", this.token, {
      client_id: this.state.client_id,
    }).then((data) => {
      var option_arr = [];
      if (data.code === 200) {
        // console.log(this.props.location.state, 'this.props.location.state');
        // console.log(data.camp_data.length, 'data.camp_data.length');
        // console.log(this.props.location.state, 'this.props.location.state');
        if (
          typeof this.props.location.state == "undefined" &&
          data.camp_data.length > 0 &&
          data.camp_data[0].camp_number == data.total[0].count
        ) {
          this.setState({
            redirect: true,
          });
        } else {
          data.data.map((val, key) => {
            option_arr.push({ value: val.id, label: val.title });
          });
        }
      }
      this.setState({ options: option_arr });
    });
  };
  campaignPreview = () => {
    var json_widget = this.state.selectedOption;
    if (!json_widget.length) {
      alert("Please select a widget first");
    } else {
      var ids = [];
      json_widget.map((value, key) => {
        ids.push(value.value);
      });
      var id = ids[0];
      return (
        <a href={Utility.baseUrlFe + "/preview.html?id=" + id} target="blank" />
      );
      // Utility.ExecuteData("findwidgetforpreview", this.token, {
      //   id: ids
      // }).then(data => {
      //   if (data.code === 200) {
      //     this.setState({ widgets: data.data });
      //   } else {
      //     alert("Something went wrong. Please try again.");
      //   }
      // });
    }
  };
  findCampaign = () => {
    var id = this.props.location.state.id;
    Utility.ExecuteData("findcampaign", this.token, { id: id }).then((data) => {
      var arr = [];
      if (data.data.widgets) {
        var arr_data = JSON.parse(data.data.widgets);
        arr_data.map((val, key) => {
          arr.push({ value: val.id, label: val.title });
        });
      }
      var arrtag = [];
      if (data.data.tags) {
        var arr_tag = JSON.parse(data.data.tags);
        arr_tag.map((val, key) => {
          arrtag.push({ value: val.id, label: val.title });
        });
      }

      var widget_arr = [];
      arr.map((val, key) => {
        widget_arr.push({ id: val.value, title: val.label });
      });
      var tag_arr = [];
      arrtag.map((val, key) => {
        tag_arr.push({ id: val.value, title: val.label });
      });

      var pub_on = new Date(data.data.publish_on);
      var end_on = new Date(data.data.end_date);
      this.setState({
        title: data.data.title,
        sub_title: data.data.sub_title,
        social_point: data.data.social_point,
        description: data.data.description,
        representative_status: data.data.representative_status
          ? data.data.representative_status
          : "yes",
        selectedOption: arr,
        widget_arr: widget_arr,
        selectedTag: arrtag,
        tagAry: tag_arr,
        status: data.data.status,
        is_header: data.data.is_header,
        letter_rotation_selector: data.data.letter_rotation_selector,
        publish_on: pub_on,
        end_date: end_on,
        image: data.data.image,
        google_analytics: data.data.google_analytics
          ? data.data.google_analytics
          : "",
        adobe_analytics: data.data.adobe_analytics
          ? data.data.adobe_analytics
          : "",
        generic_analytics: data.data.generic_analytics
          ? data.data.generic_analytics
          : "",
        facebook_analytics: data.data.facebook_analytics
          ? data.data.facebook_analytics
          : "",
        twitter_analytics: data.data.twitter_analytics
          ? data.data.twitter_analytics
          : "",
        linkedin_analytics: data.data.linkedin_analytics
          ? data.data.linkedin_analytics
          : "",
      });
    });
  };
  getTrackingAndAnalytics = async () => {
    let client_id = this.state.client_id;
    let urldata = { id: client_id, type: "client" };
    let request = await Utility.ExecuteData(
      "get_tracking_analytics",
      this.state.token,
      urldata
    );
    if (request.code == 200) {
      if (request.parentData) {
        let pd = request.parentData;
        this.setState({
          show_ga: pd.google_analytics,
          show_adobe: pd.adobe_analytics,
          show_generic: pd.generic_analytics,
          show_facebook: pd.facebook_analytics,
          show_twitter: pd.twitter_analytics,
          show_linkedin: pd.linkedin_analytics,
        });
      }
      if (request.usertype === "admin") {
        this.setState({
          show_ga: true,
          show_adobe: true,
          show_generic: true,
          show_facebook: true,
          show_twitter: true,
          show_linkedin: true,
        });
      }
    }
  };
  onChange = (publish_on) => {
    if (this.state.end_date && this.state.end_date < publish_on) {
      alert("Publish date cannot be greater then end date.");
      return;
    } else {
      this.setState({ publish_on: publish_on });
    }
  };
  dateChange = (end_date) => {
    if (!this.state.publish_on) {
      alert("Please select published date first.");
      return;
    } else if (this.state.publish_on > end_date) {
      alert("End date cannot be less then published date.");
      return;
    } else {
      this.setState({ end_date: end_date });
    }
  };

  handleValidation = () => {
    let fields = this.state;
    let errors = {};
    //  return false
    let formIsValid = true;
    if (!fields["title"]) {
      formIsValid = false;
      errors["title"] = __t("select_title");
    }
    // if (!fields["sub_title"]) {
    //   formIsValid = false;
    //   errors["sub_title"] = __t("select_sub_title");
    // }
    if (!fields["status"]) {
      formIsValid = false;
      errors["status"] = __t("select_status");
    }
    // if (!fields["social_point"]) {
    //   formIsValid = false;
    //   errors["social_point"] = __t("enter_social_point");
    // }
    if (!fields["publish_on"]) {
      formIsValid = false;
      errors["published_on"] = __t("select_publish_on");
    }
    if (!fields["end_date"]) {
      formIsValid = false;
      errors["end_date"] = __t("select_end_date");
    }
    // if (!fields["description"] || fields.description == "<p><br></p>") {
    //   formIsValid = false;
    //   errors["description"] = __t("select_description");
    // }
    if (!fields.selectedOption.length) {
      formIsValid = false;
      errors["selected_option"] = __t("selected_option");
    }
    // if (!fields.selectedTag.length) {
    //   formIsValid = false;
    //   errors["selected_tag"] = __t("selected_tag");
    // }

    if (fields.google_analytics) {
      let { returnValue, err } = this.analyticValidation(
        "google_analytics",
        fields.google_analytics,
        "ga_code_err"
      );
      formIsValid = formIsValid && returnValue ? true : false;
      errors = { ...errors, ...err };
    }
    if (fields.adobe_analytics) {
      let { returnValue, err } = this.analyticValidation(
        "adobe_analytics",
        fields.adobe_analytics,
        "adobe_code_err"
      );
      formIsValid = formIsValid && returnValue ? true : false;
      errors = { ...errors, ...err };
    }
    if (fields.generic_analytics) {
      let { returnValue, err } = this.analyticValidation(
        "generic_analytics",
        fields.generic_analytics,
        "generic_code_err"
      );
      formIsValid = formIsValid && returnValue ? true : false;
      errors = { ...errors, ...err };
    }
    if (fields.facebook_analytics) {
      let { returnValue, err } = this.analyticValidation(
        "facebook_analytics",
        fields.facebook_analytics,
        "fb_code_err"
      );
      formIsValid = formIsValid && returnValue ? true : false;
      errors = { ...errors, ...err };
    }
    if (fields.twitter_analytics) {
      let { returnValue, err } = this.analyticValidation(
        "twitter_analytics",
        fields.twitter_analytics,
        "twitter_code_err"
      );
      formIsValid = formIsValid && returnValue ? true : false;
      errors = { ...errors, ...err };
    }
    if (fields.linkedin_analytics) {
      let { returnValue, err } = this.analyticValidation(
        "linkedin_analytics",
        fields.linkedin_analytics,
        "linkedin_code_err"
      );
      formIsValid = formIsValid && returnValue ? true : false;
      errors = { ...errors, ...err };
    }
    // formIsValid = false;

    this.setState({ errors: errors });
    return formIsValid;
  };

  analyticValidation = (id, code, errKey) => {
    let returnValue = true;
    let err = {};
    if (code) {
      var csOpen = (code.match(/<script/g) || []).length;
      var csClose = (code.match(/<\/script>/g) || []).length;
      if (code.includes("<!--") || code.includes("-->")) {
        err[errKey] = __t("script_stmt_unused");
        document.getElementById(id).focus();
        returnValue = false;
      }
      let csOpen4 = (code.match(/</g) || []).length;
      let csClose4 = (code.match(/>/g) || []).length;
      if (csOpen !== csClose || csOpen4 !== csClose4) {
        err[errKey] = __t("script_tag_invalid");
        document.getElementById(id).focus();
        returnValue = false;
      }

      csOpen = (code.match(/[(]/g) || []).length;
      csClose = (code.match(/[)]/g) || []).length;
      let csOpen2 = (code.match(/{/g) || []).length;
      let csClose2 = (code.match(/}/g) || []).length;
      let csOpen3 = (code.match(/[[]/g) || []).length;
      let csClose3 = (code.match(/]/g) || []).length;
      if (csOpen !== csClose || csOpen2 !== csClose2 || csOpen3 !== csClose3) {
        err[errKey] = __t("script_bracket_invalid");
        document.getElementById(id).focus();
        returnValue = false;
      }
      if (
        code.includes("window .") ||
        code.includes("window. ") ||
        code.includes(" .push") ||
        code.includes(". push") ||
        code.includes("newDate()") ||
        code.includes(" | ") ||
        code.includes(" & ") ||
        code.includes("! =")
      ) {
        err[errKey] = __t("script_stmt_invalid");
        document.getElementById(id).focus();
        returnValue = false;
      }
      if (
        code.includes('src=""') ||
        code.includes('src=" "') ||
        code.includes("src=''") ||
        code.includes("src=' '")
      ) {
        err[errKey] = __t("script_scr_invalid");
        document.getElementById(id).focus();
        returnValue = false;
      }
    }
    return { returnValue, err };
  };

  handleChange = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };
  create = () => {
    if (this.handleValidation()) {
      if (
        this.props.location.state &&
        typeof this.props.location.state !== "undefined"
      ) {
        this.setState({ id: this.props.location.state.id }, () => {
          Utility.ExecuteData("updatecampaign", this.token, this.state)
            .then((responseJson) => {
              if (responseJson.code === 200) {
                // this.setState({ redirect: true });
                $("#msg_text").text(__t("successfully_updated_campaign"));
                $("#alertinfo").show();
              } else {
                alert("Something went wront. Try Again.");
              }
            })
            .catch((error) => {
              this.setState({ errors: true });
            });
        });
      } else {
        Utility.ExecuteData("createcampaign", this.token, this.state)
          .then((responseJson) => {
            if (responseJson.code === 200) {
              if (this.props.handleCreateCampaign) {
                this.props.handleCreateCampaign(responseJson.data, 2);
              }
              // this.setState({ redirect: true });
            } else {
              alert("Something went wront. Try Again.");
            }
          })
          .catch((error) => {
            this.setState({ errors: true });
          });
      }
    }
  };
  editorChange = (value) => {
    this.setState({ description: value });
  };

  token = localStorage.getItem("token");
  client_id = localStorage.getItem("client_id");

  multiHandleChange = (selectedOption) => {
    let rotation = false;

    if (
      this.state.subscription_feature &&
      Array.isArray(this.state.subscription_feature) &&
      this.state.subscription_feature.includes("letter_rotation")
    ) {
      rotation = true;
    }

    if (!rotation && selectedOption.length > 1) {
      alert("You can select max 1 widget at a time");
    } else {
      var arr = [];
      selectedOption.map((val, key) => {
        arr.push({ id: val.value, title: val.label });
      });
      this.setState({ selectedOption, widget_arr: arr });
    }
  };
  multiHandleChangeTag = (selectedOption) => {
    var arr = [];
    selectedOption.map((val, key) => {
      arr.push({ id: val.value, title: val.label });
    });
    this.setState({ selectedTag: selectedOption, tagAry: arr });
  };

  headerChange = (e) => {
    if (e.target.checked) {
      this.setState({ is_header: "1" });
    } else {
      this.setState({ is_header: "0" });
    }
  };

  render() {
    var camp_id = "";
    var is_header = this.state.is_header;
    if (
      this.props.location.state &&
      typeof this.props.location.state !== "undefined"
    ) {
      camp_id = this.props.location.state.id;
    }

    let userData = localStorage.getItem("lgn_dtl");
    userData = JSON.parse(userData);

    const { redirect } = this.state;
    if (redirect) {
      return <Redirect push to="/campaign/" />;
    }
    const ccampaign_id =
      typeof this.props.location.state != "undefined" &&
      this.props.location.state.id
        ? this.props.location.state.id
        : "";
    const {
      show_ga,
      show_adobe,
      show_generic,
      show_facebook,
      show_twitter,
      show_linkedin,
      google_analytics,
      generic_analytics,
      adobe_analytics,
      facebook_analytics,
      twitter_analytics,
      linkedin_analytics,
      errors,
    } = this.state;

    return (
      <Can
        I={["update-user"]}
        userPermissions={this.props.userPermissions.userPermissions}
      >
        <div>
          <div className="row">
            <div className="col-md-12">
              <div className="ibox float-e-margins">
                <div>
                  {/* className="ibox-content" */}
                  <div className="form-horizontal">
                    <div className="row form-group">
                      <div className="col-md-6 col-sm-6 col-xs-12">
                        <label className="control-label required-field">
                          {__t("title")}
                        </label>
                        <input
                          type="text"
                          name="title"
                          value={this.state.title}
                          className="form-control"
                          placeholder={__t("title")}
                          onChange={this.handleChange}
                        />
                        <label id="-error" className="error">
                          {this.state.errors["title"]}
                        </label>
                      </div>
                      <div className="col-md-6 col-sm-6 col-xs-12">
                        <label className="control-label">
                          {__t("sub_title")}
                        </label>
                        <input
                          type="text"
                          name="sub_title"
                          value={this.state.sub_title}
                          className="form-control"
                          placeholder={__t("sub_title")}
                          onChange={this.handleChange}
                        />
                        <label id="-error" className="error">
                          {this.state.errors["sub_title"]}
                        </label>
                      </div>
                    </div>
                    <div className={"row form-group"}>
                      <div className={"col-md-12 col-sm-12 col-xs-12"}>
                        <div className={"row"}>
                          <div className={"col-md-6"}>
                            <div className={"img-container"}>
                              <img
                                src={
                                  this.state.image ||
                                  "http://admin.pushpolitics.org/upload/1565508276740_placeholder-header-2.png"
                                }
                                className={"img-responsive"}
                                style={{ width: "inherit" }}
                              ></img>
                            </div>
                          </div>
                          <div className={"col-md-6"}>
                            <div className={"row"}>
                              <div className={"col-md-12 col-sm-12 col-xs-12"}>
                                <span
                                  className={"btn btn-primary"}
                                  onClick={this.mediaToggle}
                                >
                                  {__t("change_image")}
                                </span>
                                <span
                                  className={"btn btn-primary"}
                                  onClick={this.removeImage}
                                  style={{ marginLeft: 10 }}
                                >
                                  {__t("remove_image")}
                                </span>
                              </div>
                              <div className={"col-md-12 col-sm-12 col-xs-12"}>
                                <label>
                                  <input
                                    type={"checkbox"}
                                    onChange={this.headerChange}
                                    checked={is_header == "1" ? true : false}
                                  />
                                  <span style={{}}>
                                    {" "}
                                    {__t("display_header")}
                                  </span>
                                </label>
                              </div>
                            </div>
                            {this.state.subscription_feature &&
                              Array.isArray(this.state.subscription_feature) &&
                              this.state.subscription_feature.includes(
                                "letter_rotation"
                              ) && (
                                <div className="col-md-6 col-sm-6 col-xs-12">
                                  <label className="control-label required-field">
                                    {__t("letter_rotation_selector")}
                                  </label>
                                  <select
                                    name="letter_rotation_selector"
                                    className="form-control"
                                    value={this.state.letter_rotation_selector}
                                    onChange={this.handleChange}
                                  >
                                    {/* <option value="">--{__t("select_status")}--</option> */}
                                    <option value="random">
                                      {" "}
                                      {__t("random")}
                                    </option>
                                    <option value="letter_rotation_party">
                                      {" "}
                                      {__t("letter_rotation_by_party")}
                                    </option>
                                    <option value="letter_rotation_province">
                                      {__t(
                                        "letter_rotation_by_province_region"
                                      )}
                                    </option>
                                  </select>
                                  <label id="-error" className="error">
                                    {
                                      this.state.errors[
                                        "letter_rotation_selector"
                                      ]
                                    }
                                  </label>
                                </div>
                              )}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="row form-group">
                      <div className="col-md-12 col-sm-12 col-xs-12">
                        <label className="control-label">
                          {__t("description")}
                        </label>
                        <ReactQuill
                          placeholder={__t("description")}
                          value={this.state.description}
                          onChange={this.editorChange}
                        />
                        <label id="-error" className="error">
                          {this.state.errors["description"]}
                        </label>
                      </div>
                    </div>
                    <div className="row form-group">
                      <div className="col-md-6 col-sm-6 col-xs-12">
                        <label className="control-label required-field">
                          {/* {__t("widgets")} */}
                          {__t("letter_widgets")}
                        </label>
                        <Select
                          value={this.state.selectedOption}
                          isMulti
                          tabIndex={4}
                          onChange={this.multiHandleChange}
                          options={this.state.options}
                        />
                        <label id="-error" className="error">
                          {this.state.errors["selected_option"]}
                        </label>
                      </div>
                      <div className="col-md-6 col-sm-6 col-xs-12">
                        <label className="control-label required-field">
                          {__t("status")}
                        </label>
                        <select
                          name="status"
                          className="form-control"
                          value={this.state.status}
                          onChange={this.handleChange}
                        >
                          <option value="">--{__t("select_status")}--</option>
                          <option value="active"> {__t("active")}</option>
                          <option value="inactive">{__t("inactive")}</option>
                        </select>
                        <label id="-error" className="error">
                          {this.state.errors["status"]}
                        </label>
                      </div>
                    </div>
                    <div className="row form-group">
                      <div className="col-md-6 col-sm-6 col-xs-12">
                        <label className="control-label required-field">
                          {__t("published_date")}
                        </label>
                        <DateTimePicker
                          className="form-control"
                          onChange={this.onChange}
                          value={this.state.publish_on}
                        />
                        <label id="-error" className="error">
                          {this.state.errors["published_on"]}
                        </label>
                      </div>

                      <div className="col-md-6 col-sm-6 col-xs-12">
                        <label className="control-label required-field">
                          {__t("end_date")}
                        </label>
                        <DateTimePicker
                          className="form-control"
                          onChange={this.dateChange}
                          value={this.state.end_date}
                        />
                        <label id="-error" className="error">
                          {this.state.errors["end_date"]}
                        </label>
                      </div>
                    </div>

                    {this.props.isConnected ? (
                      <div className="row form-group">
                        <div className="col-md-6 col-sm-6 col-xs-12">
                          <label className="control-label required-field">
                            {__t("NationBuilder Tags")}
                          </label>
                          <Select
                            value={this.state.selectedTag}
                            isMulti
                            tabIndex={4}
                            onChange={this.multiHandleChangeTag}
                            options={this.state.tags}
                          />
                          <label id="-error" className="error">
                            {this.state.errors["selected_tag"]}
                          </label>
                        </div>
                        <div className="col-md-6 col-sm-6 col-xs-12">
                          <label className="control-label required-field">
                            {__t("NationBuilder Social Points")}
                          </label>
                          <input
                            type="text"
                            name="social_point"
                            value={this.state.social_point}
                            className="form-control"
                            placeholder={__t("social_point")}
                            onChange={this.handleChange}
                          />
                          <label id="-error" className="error">
                            {this.state.errors["social_point"]}
                          </label>
                        </div>
                        <div className="col-md-6 col-sm-6 col-xs-12">
                          <label className="control-label required-field">
                            {__t("NationBuilder Log Email Activity")}
                          </label>
                          <select
                            name="representative_status"
                            className="form-control"
                            value={this.state.representative_status}
                            onChange={this.handleChange}
                          >
                            {/* <option value="">
                              --{__t("Log Email Activity")}--
                            </option> */}
                            <option value="no"> {__t("No")}</option>
                            <option value="yes">{__t("Yes")}</option>
                          </select>
                          <label id="-error" className="error">
                            {this.state.errors["representative_status"]}
                          </label>
                        </div>
                      </div>
                    ) : (
                      ""
                    )}
                    <div className="row form-group">
                      <div className="col-md-12">
                        <button
                          className="btn btn-primary"
                          onClick={this.create}
                          type="submit"
                        >
                          {this.props.location.state &&
                          typeof this.props.location.state !== "undefined"
                            ? "Update"
                            : "Create"}
                        </button>
                        &nbsp;&nbsp;
                        <Link className="btn btn-white" to="/campaign">
                          {__t("cancel")}
                        </Link>
                        &nbsp;&nbsp;
                        {this.props.location.state &&
                        typeof this.props.location.state !== "undefined" ? (
                          <Link
                            className="btn btn-primary btn-sm"
                            to={{
                              pathname: `../../preview.html?id=${camp_id}`,
                            }}
                            target="blank"
                            style={{ marginRight: "10px" }}
                          >
                            {/* <i className="fa fa-eye" title="preview" aria-hidden="true" /> */}
                            {__t("preview")}
                          </Link>
                        ) : (
                          ""
                        )}
                        {/* {this.state.widgets ? (
                          <Campaign1 widgets={this.state.widgets} />
                        ) : (
                          ""
                        )} */}
                      </div>
                    </div>
                    {ccampaign_id != "" ? (
                      <div className="row form-group">
                        {this.props.isConnected ? (
                          <div className="col-md-12">
                            <label>{__t("embid_code_nation_use")}</label>
                            <textarea
                              value={
                                '<div class="pp_campaign" data-id="' +
                                ccampaign_id +
                                '" data-NB="{{ request.current_user.id }}" data-fname="{{request.current_user.first_name}}" data-lname="{{request.current_user.last_name}}" data-email="{{request.current_user.email}}"></div><script src="https://' +
                                window.location.hostname +
                                "/widget.js?ID=" +
                                userData.client_id +
                                '"></script><script>EmbeddableWidget.mount({class: "pp_campaign" });</script>'
                              }
                              className={"form-control"}
                              rows={3}
                              readOnly
                            />
                          </div>
                        ) : (
                          ""
                        )}
                        <div className="col-md-12">
                          <label>{__t("embid_code_use")}</label>
                          <textarea
                            value={
                              '<div class="pp_campaign" data-id="' +
                              ccampaign_id +
                              '"></div><script src="https://' +
                              window.location.hostname +
                              '/widget.js"></script><script>EmbeddableWidget.mount({class: "pp_campaign" });</script>'
                            }
                            className={"form-control"}
                            rows={3}
                            readOnly
                          />
                        </div>
                        &nbsp;&nbsp;
                        <div className="col-md-12">
                          <label>{__t("short_code_use")}</label>
                          <textarea
                            value={
                              "[pp_campaign campaign_id=" + ccampaign_id + "]"
                            }
                            className={"form-control"}
                            rows={3}
                            readOnly
                          />
                        </div>
                        {/* tracking and analytics code */}
                        {show_ga && (
                          <div className="col-md-12">
                            <label>{__t("google_analytics")}</label>
                            <textarea
                              value={google_analytics ? google_analytics : ""}
                              name="google_analytics"
                              id="google_analytics"
                              className={"form-control"}
                              placeholder={__t("google_analytics_placeholder")}
                              rows={3}
                              onChange={this.handleChange}
                              // readOnly
                            />
                            <label id="-error" className="error">
                              {errors["ga_code_err"]}
                            </label>
                          </div>
                        )}
                        {show_adobe && (
                          <div className="col-md-12">
                            <label>{__t("adobe_analytics")}</label>
                            <textarea
                              value={adobe_analytics ? adobe_analytics : ""}
                              name="adobe_analytics"
                              id="adobe_analytics"
                              className={"form-control"}
                              rows={3}
                              onChange={this.handleChange}
                              // readOnly
                            />
                            <label id="-error" className="error">
                              {errors["adobe_code_err"]}
                            </label>
                          </div>
                        )}
                        {show_generic && (
                          <div className="col-md-12">
                            <label>{__t("generic_analytics")}</label>
                            <textarea
                              value={generic_analytics ? generic_analytics : ""}
                              name="generic_analytics"
                              id="generic_analytics"
                              className={"form-control"}
                              rows={3}
                              onChange={this.handleChange}
                              // readOnly
                            />
                            <label id="-error" className="error">
                              {errors["generic_code_err"]}
                            </label>
                          </div>
                        )}
                        {show_facebook && (
                          <div className="col-md-12">
                            <label>{__t("facebook_analytics")}</label>
                            <textarea
                              value={
                                facebook_analytics ? facebook_analytics : ""
                              }
                              name="facebook_analytics"
                              id="facebook_analytics"
                              className={"form-control"}
                              rows={3}
                              onChange={this.handleChange}
                              // readOnly
                            />
                            <label id="-error" className="error">
                              {errors["fb_code_err"]}
                            </label>
                          </div>
                        )}
                        {show_twitter && (
                          <div className="col-md-12">
                            <label>{__t("twitter_analytics")}</label>
                            <textarea
                              value={twitter_analytics ? twitter_analytics : ""}
                              name="twitter_analytics"
                              id="twitter_analytics"
                              className={"form-control"}
                              rows={3}
                              onChange={this.handleChange}
                              // readOnly
                            />
                            <label id="-error" className="error">
                              {errors["twitter_code_err"]}
                            </label>
                          </div>
                        )}
                        {show_linkedin && (
                          <div className="col-md-12">
                            <label>{__t("linkedin_analytics")}</label>
                            <textarea
                              value={
                                linkedin_analytics ? linkedin_analytics : ""
                              }
                              name="linkedin_analytics"
                              id="linkedin_analytics"
                              className={"form-control"}
                              rows={3}
                              onChange={this.handleChange}
                              // readOnly
                            />
                            <label id="-error" className="error">
                              {errors["linkedin_code_err"]}
                            </label>
                          </div>
                        )}
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <SelectImageModal
            isOpen={this.state.mediaModal}
            mediaToggle={this.mediaToggle}
            setImageUrl={this.setImageUrl}
          />
        </div>
      </Can>
    );
  }
}

export default CampaignUpdate1;
