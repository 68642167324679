import React from "react";
import { Redirect, Link } from "react-router-dom";
import Utility from "../common/Utility";
import { __t } from "../../locale/language";
import Can from "../common/AccessControl";
import Moment from "moment";
import $ from "jquery";
class DatabaseConfiguration extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      host: "",
      username: "",
      password: "",
      database: "",
      host2: "",
      username2: "",
      password2: "",
      database2: "",
      errors: {},
      redirect: false,
      token: localStorage.getItem("token")
    };

    this.handleChange = this.handleChange.bind(this);
  }
  handleChange(event) {
    this.setState({ [event.target.name]: event.target.value });
  }
  componentDidMount() {
    Utility.ExecuteData("get_db_config_data", this.token, this.state).then(responseJson => {
      if (responseJson.code === 200) {
        this.setState({
          host: responseJson.data.riding_config.host,
          username: responseJson.data.riding_config.user,
          password: responseJson.data.riding_config.password,
          database: responseJson.data.riding_config.database,
          host2: responseJson.data.contact_config.host,
          username2: responseJson.data.contact_config.user,
          password2: responseJson.data.contact_config.password,
          database2: responseJson.data.contact_config.database
        });
      }
    });
  }

  checkValidation() {
    let fields = this.state;
    let errors = {};
    let formIsValid = true;
    if (!fields["host2"]) {
      formIsValid = false;
      errors["host2"] = __t("contact_list_host_cannot_be_empty");
    }
    if (!fields["username2"]) {
      formIsValid = false;
      errors["username2"] = __t("contact_list_username_cannot_be_empty");
    }
    if (!fields["password2"]) {
      formIsValid = false;
      errors["password2"] = __t("contact_list_password_cannot_be_empty");
    }
    if (!fields["database2"]) {
      formIsValid = false;
      errors["database2"] = __t("contact_list_database_cannot_be_empty");
    }
    if (!fields["host"]) {
      formIsValid = false;
      errors["host"] = __t("riding_list_host_cannot_be_empty");
    }
    if (!fields["username"]) {
      formIsValid = false;
      errors["username"] = __t("riding_list_username_cannot_be_empty");
    }
    if (!fields["password"]) {
      formIsValid = false;
      errors["password"] = __t("riding_list_password_cannot_be_empty");
    }
    if (!fields["database"]) {
      formIsValid = false;
      errors["database"] = __t("riding_list_database_cannot_be_empty");
    }
    this.setState({
      errors: errors
    });
    return formIsValid;
  }
  submit() {
    if (this.checkValidation()) {
      Utility.ExecuteData("db_config", this.token, this.state).then(responseJson => {
        if (responseJson.code === 200) {
           this.setState({ redirect: true });
          // $("#msg_text").text(__t("successfully_updated"));
          // $("#alertinfo").show();
        }
        else if(responseJson.code==304){
         
          $("#msg_text").text(__t("invalid_credentials"));
          $("#alertinfo").show();
        }
        
        else {
          alert("Something went wront. Try Again.");
        }
      });
    }
  }
  render() {
    const { redirect } = this.state;
    if (redirect) {
      return <Redirect push to="/contacts" />;
    }
    return (
      <div>
        <div className="row wrapper border-bottom white-bg page-heading">
          <div className="col-lg-10">
            <h2 style={{ marginTop: 10 }}>{__t("contact_data_set")}</h2>
            <ol className="breadcrumb">
              <li className="breadcrumb-item">
                <Link to="/">{__t("dashboard")}</Link>
              </li>
              <li className="breadcrumb-item">
                <Link to="/contacts">{__t("contact_data_set")}</Link>
              </li>
              <li className="breadcrumb-item active">
                <strong>{__t("db_config")}</strong>
              </li>
            </ol>
          </div>
        </div>
        
        <div className="ibox-content my-2">
          <div className="row form-group">
            <div className="col-sm-6">
              <label className="lbl_css">{__t("riding_list")}</label>
            </div>
            <div className="col-sm-6">
              {" "}
              <label className="lbl_css" style={{ marginLeft: "-30px" }}>
                {__t("contact_data_list")}
              </label>
            </div>
          </div>
          <div className="row form-group">
            <div className="row form-group col-sm-6">
              <div className="col-md-12 col-sm-12 col-xs-12">
                <label className="control-label required-field">{__t("host")}</label>
                <input type="text" name="host" className="form-control" placeholder={__t("host")} value={this.state.host} onChange={this.handleChange} />
                <label id="-error" className="error">
                  {this.state.errors["host"]}
                </label>
              </div>
              <div className="col-md-12 col-sm-6 col-xs-12">
                <label className="control-label required-field">{__t("username")}</label>
                <input type="text" name="username" autoComplete="off" className="form-control" placeholder={__t("username")} value={this.state.username} onChange={this.handleChange} />
                <label id="-error" className="error">
                  {this.state.errors["username"]}
                </label>
              </div>
              <div className="col-md-12 col-sm-6 col-xs-12">
                <label className="control-label required-field">{__t("password")}</label>
                <input type="text" name="password" autoComplete="off" className="form-control" placeholder={__t("password")} value={this.state.password} onChange={this.handleChange} />
                <label id="-error" className="error">
                  {this.state.errors["password"]}
                </label>
              </div>
              <div className="col-md-12 col-sm-6 col-xs-12">
                <label className="control-label required-field">{__t("database")}</label>
                <input type="text" name="database" autoComplete="off" className="form-control" placeholder={__t("database")} value={this.state.database} onChange={this.handleChange} />
                <label id="-error" className="error">
                  {this.state.errors["database"]}
                </label>
              </div>
            </div>

            <div className="row form-group col-sm-6">
              <div className="col-md-12 col-sm-12 col-xs-12">
                <label className="control-label required-field">{__t("host")}</label>
                <input type="text" name="host2" className="form-control" placeholder={__t("host")} value={this.state.host2} onChange={this.handleChange} />
                <label id="-error" className="error">
                  {this.state.errors["host2"]}
                </label>
              </div>
              <div className="col-md-12 col-sm-6 col-xs-12">
                <label className="control-label required-field">{__t("username")}</label>
                <input type="text" name="username2" autoComplete="off" className="form-control" placeholder={__t("username")} value={this.state.username2} onChange={this.handleChange} />
                <label id="-error" className="error">
                  {this.state.errors["username2"]}
                </label>
              </div>
              <div className="col-md-12 col-sm-6 col-xs-12">
                <label className="control-label required-field">{__t("password")}</label>
                <input type="text" name="password2" autoComplete="off" className="form-control" placeholder={__t("password")} value={this.state.password2} onChange={this.handleChange} />
                <label id="-error" className="error">
                  {this.state.errors["password2"]}
                </label>
              </div>
              <div className="col-md-12 col-sm-6 col-xs-12">
                <label className="control-label required-field">{__t("database")}</label>
                <input type="text" name="database2" autoComplete="off" className="form-control" placeholder={__t("database")} value={this.state.database2} onChange={this.handleChange} />
                <label id="-error" className="error">
                  {this.state.errors["database2"]}
                </label>
              </div>
            </div>
          </div>
          <button
            className="btn btn-primary btn-sm ml-3"
            id="submit"
            onClick={() => {
              this.submit();
            }}
          >
            {__t("Save")}
          </button>
          <Link to="/contacts" className="btn btn-sm btn-primary" style={{ marginLeft: "8px" }}>
            {__t("cancel")}
          </Link>
        </div>
      </div>
    );
  }
}

export default DatabaseConfiguration;
