import React from "react";
import { Link } from "react-router-dom";
import RemotePagination from "../common/Table";
import Utility from "../common/Utility";
import { __t } from "../../locale/language";
import Can from "../common/AccessControl";
import Moment from "moment";
import $ from "jquery";

class ResellerList extends React.Component {
  constructor(props, context) {
    super(props, context);
    this.state = { page: 1, data: [], sizePerPage: 10, total: 0, search: "" };
    this.deleteReseller = this.deleteReseller.bind(this);
    this.updateResellerList = this.updateResellerList.bind(this);
    this.handleChange = this.handleChange.bind(this);
  }
  handleChange(event) {
    this.setState({ [event.target.name]: event.target.value });
  }
  deleteReseller = props => {
    if (window.confirm("Are you sure want to delete?")) {
      //console.log("Delete user : " + props.deleteUser);
    }
  };

  renderButtons(cell, row, rowIndex) {
    return (
      <span>
        <Can
          I={["update-client"]}
          userPermissions={this.props.userPermissions.userPermissions}
        >
          <Link
            to={{
              pathname: `/reseller/update/${row.id}`,
              state: { id: `${row.id}` }
            }}
            className="btn btn-primary btn-sm"
          >
            <i className="fa fa-pencil" aria-hidden="true" /> Edit
          </Link>
        </Can>
      </span>
    );
  }
  token = localStorage.getItem("token");
  handleTableChange = (type, { page, sizePerPage }) => {
    const currentIndex = (page - 1) * sizePerPage;
    //write fetch data function
    this.updateResellerList(page, sizePerPage);
  };
  updateResellerList(page = 1, sizePerPage = 10) {
    Utility.ExecuteData("resellerdata", this.token, {
      page: page,
      limit: sizePerPage,
      search: this.state.search
    }).then(data => {
      //data.data.rows.forEach(function (element) { element.Active = "false"; });

      data.data.rows.map((ele, m) => {
        data.client_count.map((val, k) => {
          var count = val.client_count
          data.data.rows.forEach(function (element) {
            if (val.id == element.id) {
              element.client_count = count;

            }
          });

        })
      })
      this.setState(() => ({
        page,
        data: data.data.rows,
        sizePerPage,
        total: data.data.count
      }));
    });
  }
  componentDidMount() {
    this.updateResellerList(1, 10);
  }
  render() {
    //console.log(this.state.data);
    const { data, sizePerPage, page, total } = this.state;
    const columns = [
      { dataField: "name", text: "Name" },
      { dataField: "title", text: "Title" },
      { dataField: "subdomain", text: "Domain" },
      // { dataField: "username", text: "Username" },
      { dataField: "status", text: "Status" },
      { dataField: "client_count", text: "client_count" },
      // {
      //   dataField: "created_at",
      //   text: __t("created_at"),
      //   formatter: cellContent =>
      //     Moment(cellContent).format("DD-MMM-YYYY HH:mm:ss")
      // },
      {
        dataField: "updated_at",
        text: __t("updated_at"),
        formatter: cellContent =>
          Moment(cellContent).format("DD-MMM-YYYY HH:mm:ss")
      },
      {
        dataField: "action",
        text: "Action",
        formatter: this.renderButtons.bind(this)
      }
    ];
    return (
      <Can
        I={["view-reseller"]}
        userPermissions={this.props.userPermissions.userPermissions}
      >
        <div className="row wrapper border-bottom white-bg page-heading">
          <div className="col-lg-10">
            <h2 style={{ marginTop: 10 }}>{__t("reseller_list")}</h2>
            <ol className="breadcrumb">
              <li className="breadcrumb-item">
                <Link to="/">{__t("dashboard")}</Link>
              </li>
              <li className="breadcrumb-item">
                <Link to="/reseller">{__t("reseller")}</Link>
              </li>
              <li className="breadcrumb-item active">
                <strong>{__t("reseller_list")}</strong>
              </li>
            </ol>
          </div>
        </div>
        <div className="wrapper wrapper-content animated fadeInRight">
          <div className="row">
            <div className="col-lg-12 ">
              <div className="ibox ">
                <div className="ibox-title">
                  <div className="row from-control">
                    &nbsp; &nbsp; &nbsp;
                    <input
                      className="form-control col-md-3"
                      type="text"
                      name="search"
                      id="search_field"
                      onChange={this.handleChange}
                      placeholder="Search by Name, Title"
                    />
                    &nbsp;
                    <input
                      className="btn btn-primary"
                      type="button"
                      onClick={() => {
                        this.updateResellerList();
                      }}
                      name="btn_search"
                      value="Search"
                    />
                    &nbsp;
                    <input
                      className="btn btn-warning"
                      type="button"
                      onClick={() => {
                        this.setState({ search: "" }, () => {
                          this.updateResellerList();
                          document.getElementById("search_field").value = "";
                        });
                      }}
                      name="btn_search"
                      value="Clear"
                    />
                    &nbsp;
                    <Can
                      I={["create-client"]}
                      userPermissions={
                        this.props.userPermissions.userPermissions
                      }
                    >
                      <Link className="btn btn-primary" to="/reseller/update">
                        {__t("create_reseller")}
                      </Link>
                    </Can>
                    <br />
                    &nbsp; &nbsp;
                    <strong style={{ marginTop: "10px" }}>
                      {__t("total_reseller")} : {this.state.total}
                    </strong>
                  </div>
                </div>

                <div className="ibox-content">
                  {this.state.total > 0 ? (
                    <RemotePagination
                      columns={columns}
                      data={data}
                      page={page}
                      sizePerPage={sizePerPage}
                      totalSize={total}
                      onTableChange={this.handleTableChange}
                    />
                  ) : (
                      __t("no_record_found")
                    )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </Can>
    );
  }
}

export default ResellerList;
