import React, { Component } from "react";
import { Modal } from "react-bootstrap";
import Utility from "./Utility";

class TwoFA extends Component {
  constructor(props) {
    super(props);
    this.state = {
      userEmail: "",
      // handles modals
      showModal: false,
      showEditModal: false,

      // handles verify API Call
      otp: "",
      setupUrl: "",
      qrCodeData: "",
      verificationMessage: "",
      setupMsg: "",

      // handles secret functionality
      showSecret: false,
      secretValue: "",

      //   handles description
      exist: false,
      descriptions: [],
      updated: null,
      selectedAuth: "",
      oldAuth: "",
      defaultAuth: "",
      defaultDescription: "",

      resultData: {},
    };
    this.getTwoFAConfig = this.getTwoFAConfig.bind(this);
  }

  getUserEmail = async () => {
    try {
      const action = "get-email";
      const token = localStorage.getItem("token");
      const data = {};

      const response = await Utility.ExecuteData(action, token, data, false);
      const { email } = response;
      this.setState({ userEmail: email });
    } catch (error) {
      console.error(error);
    }
  };

  getData = async () => {
    try {
      const action = "2fa_getData";
      const token = localStorage.getItem("token");
      const userId = JSON.parse(localStorage.getItem("lgn_dtl")).id;
      const data = { userId: this.props.userId ?? userId };

      const response = await Utility.ExecuteData(action, token, data, false);
      this.setState({
        exist: response.descriptions.map((record) => record.description).length,
        descriptions: response.descriptions.map((record) => record.description),
        updated: response.descriptions.map((record) => record.updated),
        defaultAuth: response.descriptions.map(
          (record) => record.defaultAuth
        )[0],
      });
    } catch (error) {
      console.error(error);
    }
  };

  getTwoFAConfig = async () => {
    let res = await Utility.GetData("get2faconfig", {});
    if (res && res.code === 200) {
      this.setState({
        resultData: res.data,
      });
    }
  };

  handleModalOpen = async () => {
    const loginDetail = JSON.parse(localStorage.lgn_dtl);
    const parentDomain = loginDetail.parentdomain;
    const qrName = parentDomain + ":" + this.state.userEmail;
    try {
      const response = await Utility.ExecuteData(
        "2fa_setup",
        this.token,
        { qrName },
        true
      );
      const { otpauthUrl, qrCodeData } = response;
      this.setState({
        showModal: true,
        setupUrl: otpauthUrl,
        qrCodeData: qrCodeData,
        verificationMessage: "",
      });
    } catch (error) {
      console.error(error);
    }
  };

  handleModalClose = () => {
    this.setState({ showModal: false });
  };

  handleEditModalOpen = (index) => {
    this.setState({
      showEditModal: true,
      oldAuth: this.state.descriptions[index],
    });
  };

  handleEditModalClose = () => {
    this.setState({ showEditModal: false });
  };

  handleShowSecret = () => {
    const { setupUrl } = this.state;
    const secretValue = setupUrl.split("secret=")[1];
    this.setState({ showSecret: true, secretValue });
  };

  handleHideSecret = () => {
    this.setState({ showSecret: false });
  };

  handleUpdate = async () => {
    try {
      const action = "2fa_update";
      const token = localStorage.getItem("token");
      const secretKey = this.state.setupUrl.split("secret=")[1];
      const data = {
        otp: this.state.otp,
        secretKey: secretKey,
        description: this.state.selectedAuth,
        userId: this.props.userId || null, // Assign null if userId prop is not available
      };

      const response = await Utility.ExecuteData(action, token, data, true);
      const { success } = response;
      if (success) {
        this.setState({
          setupMsg: "Two Factor Authentication Successfully Setup",
        });
        this.getData();
      } else {
        this.setState({
          setupMsg: "Two Factor Authentication Setup Failed. Try Again",
        });
      }
    } catch (error) {
      console.error(error);
    } finally {
      this.setState({ showModal: false, otp: "", selectedAuth: "" });
    }
  };

  handleEditUpdate = async () => {
    try {
      const action = "2fa_editData";
      const token = localStorage.getItem("token");
      const data = {
        old_description: this.state.oldAuth,
        new_description: this.state.selectedAuth,
        userId: this.props.userId,
      };
      const response = await Utility.ExecuteData(action, token, data, false);
      const { success } = response;
    } catch (error) {
      console.error(error);
    } finally {
      this.setState({ showEditModal: false, selectedAuth: "" });
      this.getData();
    }
  };

  deleteRecord = async (index) => {
    try {
      const action = "2fa_delete";
      const token = localStorage.getItem("token");
      const data = {
        description: this.state.descriptions[index],
        userId: this.props.userId || null,
      };
      const response = await Utility.ExecuteData(action, token, data, true);
      this.getData();
    } catch (error) {
      console.error(error);
    }
  };

  handleRadioChange = (event) => {
    const selectedAuth = event.target.value;
    this.setState({ defaultAuth: selectedAuth }, () => {
      // Make API call after setting the state
      try {
        const action = "2fa_defaultUpdate";
        const token = localStorage.getItem("token");
        const data = {
          defaultAuth: this.state.defaultAuth,
          userId: this.props.userId,
        };
        const response = Utility.ExecuteData(action, token, data, false);
      } catch (error) {
        console.error("Error updating default authenticator", error);
      }
    });
  };

  handleChange = (e) => {
    this.setState({ otp: e.target.value });
  };
  handleChangeOTP = (value) => {
    this.setState({ otp: value });
  };

  handleChangeDescription = (value) => {
    this.setState({ selectedAuth: value });
  };

  componentDidMount() {
    this.getData();
    this.getTwoFAConfig();
    this.getUserEmail();
  }

  render() {
    const {
      showModal,
      showEditModal,

      otp,
      qrCodeData,

      exist,
      descriptions,
      updated,
      selectedAuth,

      setupMsg,

      showSecret,
      secretValue,
      resultData,
    } = this.state;
    return (
      <div>
        <div className="wrapper wrapper-content">
          <div className="col-md-12">
            <div className="ibox float-e-margins">
              <div className="white-bg" style={{ padding: "10px" }}>
                <div className="aut_flex">
                  <div className="auth_img">
                    <img src="/img/auth.jpg" alt="" />
                  </div>
                  <h4 className="auth_heading">
                    Two-factor authentication
                    <span
                      style={{
                        color:
                          resultData && resultData.enable_user === "1"
                            ? "limegreen"
                            : "red",
                      }}
                    >
                      {resultData && resultData.enable_user === "1"
                        ? "ENABLED"
                        : "DISABLED"}
                    </span>
                  </h4>
                </div>

                <div style={{ paddingLeft: "30px" }}>
                  <div className="authmob_flex">
                    <div className="auth_mobimg">
                      <img src="/img/authmob.jpg" alt="" />
                    </div>

                    <div className="">
                      <h4
                        style={{
                          fontWeight: "bold",
                          marginBottom: "5px",
                        }}
                      >
                        <img
                          src="/img/check.svg"
                          className="check_mob"
                          alt=""
                        />{" "}
                        Mobile application
                      </h4>
                      <p style={{ marginBottom: "5px" }}>
                        Authenticate using a free mobile TOTP app compatible
                        with Android/iOS/Windows Phones. Example: Google
                        Authenticator{" "}
                      </p>
                    </div>
                  </div>

                  <div className="description">
                    <h4
                      style={{
                        background: "#0ff5f5",
                        padding: "5px",
                        fontWeight: "bold",
                        color: "#0e1a60",
                      }}
                    >
                      Description (Select the default Authenticator)
                    </h4>
                  </div>

                  {exist >= 1 && (
                    <div className="description2">
                      {descriptions.map((description, index) => (
                        <div className="drop_flex" key={index}>
                          <div>
                            <div>
                              <input
                                type="radio"
                                id={`radio-${index}`}
                                name="authenticator-radio"
                                value={description}
                                onChange={this.handleRadioChange}
                                checked={this.state.defaultAuth === description}
                              />
                              <label
                                htmlFor={`radio-${index}`}
                                style={{ marginLeft: "8px" }}
                              >
                                {description}
                              </label>
                            </div>

                            <h6>
                              {updated[index] ? (
                                <>Last used: {updated[index].split("T")[0]}</>
                              ) : (
                                <>
                                  You have not used this authenticator for login
                                  yet.
                                </>
                              )}
                            </h6>
                          </div>

                          <div className="dropdown">
                            <button
                              className="cust_drop"
                              type="button"
                              data-toggle="dropdown"
                            >
                              <img src="/img/threedots.svg" alt="" />
                            </button>
                            <ul className="dropdown-menu dorp_menu">
                              <li>
                                <button
                                  onClick={() =>
                                    this.handleEditModalOpen(index)
                                  }
                                >
                                  Edit
                                </button>
                              </li>
                              <li>
                                <button
                                  onClick={() => this.deleteRecord(index)}
                                >
                                  Delete
                                </button>
                              </li>
                            </ul>
                          </div>
                        </div>
                      ))}
                    </div>
                  )}

                  {!exist && <div style={{ paddingTop: "20px" }}></div>}

                  <div style={{ borderBottom: "1px solid #0ff5f5" }}></div>
                  <button
                    style={{ marginTop: "20px" }}
                    className="btn btn-primary"
                    onClick={this.handleModalOpen}
                  >
                    Add Application
                  </button>

                  <Modal
                    show={showModal}
                    onHide={this.handleModalClose}
                    // dialogClassName="modal-50w"
                    className="custom_modal"
                  >
                    <Modal.Header closeButton></Modal.Header>
                    <Modal.Body>
                      <div className="modal_body_content">
                        <h2>Two-factor authentication via mobile app</h2>
                        <h4>Add a mobile application</h4>
                        <div>Scan the QR code</div>

                        <div className="qr_code_flex">
                          <div className="qr_code_img">
                            {qrCodeData && (
                              <img src={qrCodeData} alt="QR Code" />
                            )}
                            <div>
                              {showSecret ? (
                                <>
                                  <div>{secretValue}</div>
                                  <button
                                    className="scret_btn"
                                    onClick={this.handleHideSecret}
                                  >
                                    Hide Secret
                                  </button>
                                </>
                              ) : (
                                <button
                                  className="scret_btn"
                                  onClick={this.handleShowSecret}
                                >
                                  Show Secret
                                </button>
                              )}
                            </div>
                          </div>

                          <div className="qr_field">
                            <div className="doe_form_label">
                              <label className="code_label">Enter OTP:</label>
                              <input
                                id="otpInput"
                                type="text"
                                placeholder="Code generated by the application"
                                value={otp}
                                onChange={this.handleChange}
                              />
                            </div>

                            <div className="doe_form_label">
                              <label className="code_label">
                                Enter Description:
                              </label>
                              <input
                                id="descriptionInput"
                                placeholder="Application Description"
                                type="text"
                                value={selectedAuth}
                                onChange={(event) =>
                                  this.handleChangeDescription(
                                    event.target.value
                                  )
                                }
                              />
                            </div>
                          </div>
                        </div>
                        <div className="secret_btn_flex">
                          <button
                            className="scret_btn2 mr-2"
                            onClick={this.handleModalClose}
                          >
                            Close
                          </button>
                          <button
                            className="scret_btn2"
                            onClick={this.handleUpdate}
                          >
                            Confirm
                          </button>
                        </div>
                      </div>
                    </Modal.Body>
                  </Modal>
                  <Modal
                    show={showEditModal}
                    onHide={this.handleEditModalClose}
                    // dialogClassName="modal-50w"
                    className="custom_modal"
                  >
                    <Modal.Header closeButton></Modal.Header>
                    <Modal.Body>
                      <div className="modal_body_content">
                        <h2>Two-factor authentication via mobile app</h2>

                        <div className="qr_code_flex">
                          <div className="doe_form_label">
                            <label className="code_label">
                              Enter Description:
                            </label>
                            <input
                              id="descriptionInput"
                              placeholder="Application Description"
                              type="text"
                              value={selectedAuth}
                              onChange={(event) =>
                                this.handleChangeDescription(event.target.value)
                              }
                            />
                          </div>
                        </div>

                        <div className="secret_btn_flex">
                          <button
                            className="scret_btn2 mr-2"
                            onClick={this.handleEditModalClose}
                          >
                            Close
                          </button>
                          <button
                            className="scret_btn2"
                            onClick={this.handleEditUpdate}
                          >
                            Confirm
                          </button>
                        </div>
                      </div>
                    </Modal.Body>
                  </Modal>

                  <div>
                    {setupMsg && (
                      <p style={{ fontSize: "18px", color: "#333" }}>
                        {setupMsg}
                      </p>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default TwoFA;
