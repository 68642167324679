import React, { Component } from "react";
import { Tabs, Tab } from "react-bootstrap";
import { Redirect } from "react-router-dom";
import { Link } from "react-router-dom";
import { __t } from "../../locale/language";
class Tab3 extends Component {
  constructor(props) {
    super(props);
  }
  render() {
    const tab3_data = this.props.tab3_data;
    console.log(tab3_data.verified);

    return (
      <Tab eventKey={3} title="User Detail" disabled>
        <div className="row form-group">
          <div className="col-md-6 col-sm-6 col-xs-12">
            <label className="control-label required-field">{__t("first_name")}</label>
            <input type="text" name="first_name" value={tab3_data.first_name} className="form-control" placeholder={__t("first_name")} onChange={this.props.handleChange} />
            <label id="-error" className="error">
              {tab3_data.errors["first_name"]}
            </label>
          </div>
          <div className="col-md-6 col-sm-6 col-xs-12">
            <label className="control-label">{__t("last_name")}</label>
            <input type="text" name="last_name" value={tab3_data.last_name} className="form-control" placeholder={__t("last_name")} onChange={this.props.handleChange} />
          </div>
        </div>

        <div className="row form-group">
          <div className="col-md-6 col-sm-6 col-xs-12">
            <label className="control-label required-field">{__t("uname")}</label>
            <input type="text" name="user_name" value={tab3_data.user_name} className="form-control" placeholder={__t("uname")} onChange={this.props.handleChange} />
            <label id="-error" className="error">
              {tab3_data.errors["user_name"]}
            </label>
          </div>
          <div className="col-md-6 col-sm-6 col-xs-12">
            <label className="control-label required-field">{__t("email")}</label>
            <input type="email" name="user_email" autoComplete="off" defaultValue={tab3_data.email} className="form-control" placeholder={__t("email")} onChange={this.props.handleChange} />
            <label id="-error" className="error">
              {tab3_data.errors["user_email"]}
            </label>
            <div>
              <label for="verified">
                <input type="checkbox" id="verified" name="verified" onChange={this.props.handleChange} checked={tab3_data.verified == 1 ? "checked" : ""} /> Verified
              </label>
              <br />
              <label for="verifiedemail">
                <input type="checkbox" id="verifiedemail" name="verifiedemail" onChange={this.props.handleChange} /> Send Verification Email
              </label>
            </div>
          </div>

        </div>
        {
          this.props.flag == "create" ?
            <div className="row  form-group">
              <div className="col-md-6 col-sm-6 col-xs-12">
                <label className="control-label required-field">{__t("password")}</label>
                <input
                  type="password"
                  name="user_password"
                  id="new-password"
                  value={tab3_data.user_password}
                  autoComplete="off"
                  className="form-control"
                  placeholder={__t("password")}
                  onChange={this.props.handleChange}
                />
                <label id="-error" className="error">
                  {tab3_data.errors["user_password"]}
                </label>
              </div>
              <div className="col-md-6 col-sm-6 col-xs-12">
                <label className="control-label required-field">{__t("conf_password")}</label>
                <input
                  type="password"
                  name="confirm_password"
                  value={tab3_data.confirm_password}
                  autoComplete="off"
                  className="form-control"
                  placeholder={__t("conf_password")}
                  onChange={this.props.handleChange}
                />
                <label id="-error" className="error">
                  {tab3_data.errors["confirm_password"]}
                </label>
              </div>
            </div>


            : ""
        }
        <div className="row form-group">
          <div className="col-md-6 col-sm-6 col-xs-12">
            <label className="control-label">{__t("mobile_number")}</label>
            <input type="text" name="user_contact" autoComplete="off" value={tab3_data.user_contact} className="form-control" placeholder={__t("mobile_number")} onChange={this.props.handleChange} />
            <label id="-error" className="error">
              {tab3_data.errors["user_contact"]}
            </label>
          </div>
          <div className="col-md-6 col-sm-6 col-xs-12">
            <label className="control-label required-field">{__t("status")}</label>
            <select name="user_status" className="form-control" value={tab3_data.user_status} onChange={this.props.handleChange}>
              <option value="">--{__t("select_status")}--</option>
              <option value="active">{__t("active")}</option>
              <option value="inactive">{__t("inactive")}</option>
            </select>
            <label id="-error" className="error">
              {tab3_data.errors["user_status"]}
            </label>
          </div>
        </div>
        <div className="row form-group">
          <div className="col-md-12">
            <button className="btn btn-primary" onClick={this.props.create} type="submit">
              {this.props.capitalize(this.props.flag)}
            </button>
            &nbsp;&nbsp;
            <Link className="btn btn-white" to="/client">
              {__t("cancel")}
            </Link>
          </div>
        </div>
      </Tab>
    );
  }
}

export default Tab3;
