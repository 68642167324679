import React, { Component } from "react";
import grapesjs from "grapesjs";
import { Redirect } from "react-router-dom";
import Axios from "axios";
import Utility from "../common/Utility";
import { Link } from "react-router-dom";
import AlertSuccess from "../common/Alertsuccess";
import "grapesjs-blocks-basic";
import "grapesjs-blocks-flexbox";
import { __t } from "../../locale/language";
import SelectImageModal from "../client/mediaModal";
import $ from "jquery";
const BASE_URL = Utility.baseUrlBe() + "assetscreate";
var image_array = [];
var imageArray;
class CreateTemplate extends Component {
  constructor(props) {
    super(props);
    this.editor = null;
    this.token = localStorage.getItem("token");
    this.client_id = localStorage.getItem("client_id");
    this.state = {
      template: "",
      token: localStorage.getItem("token"),
      client_id: localStorage.getItem("client_id"),
      subscription_feature: this.props.userPermissions.subscription_feature,
      redirect: false,
      title: "",
      message: "",
      result: "",
      user_id: "",
      tem_client_id: "",
      tem_user_id: "",
      price: "",
      type: "",
      user_type: this.props.userPermissions.user_type,
      temp_type: "free",
      uploadedImage: "",
      // thumb: "http://localhost:3000/img/default-image.png",
      thumb: "/img/default-image.png",
      default_url: "/img/",
      dragged_image: "",
      errors: {},
      edititem: {},
      mediaModal: false
    };
    this.ok = this.ok.bind(this);

    this.handleChange = this.handleChange.bind(this);
    this.typeChange = this.typeChange.bind(this);
    this.checkValidation = this.checkValidation.bind(this);
    this.createTemplate = this.createTemplate.bind(this);
    this.onFormSubmit = this.onFormSubmit.bind(this);
    this.onChange = this.onChange.bind(this);
    this.getThumb = this.getThumb.bind(this);
    this.setImageUrl = this.setImageUrl.bind(this);
  }
  getThumb(img) {
    var file_ext = img.ext;
    var ext = img.name.split(".");
    ext = ext[ext.length - 1];

    var thumb_img = Utility.getThumbimage(img.name, ext);
    return thumb_img;
  }
  checkValidation() {
    let fields = this.state;
    let errors = {};
    let formIsValid = true;

    if (!fields["title"]) {
      formIsValid = false;
      errors["title"] = __t("select_title");
    }
    if (this.state.thumb == "/img/default-image.png") {
      formIsValid = false;
      errors["thumb"] = __t("please_upload_thumb");
    }
    this.setState({ errors: errors });
    return formIsValid;
  }
  mediaToggle = () => {
    this.setState({ mediaModal: !this.state.mediaModal, edititem: [] });
  };
  componentDidMount() {
    //console.log(this.props);
    if ((this.state.subscription_feature.includes("subdomain") && this.state.user_type == "client") || (this.state.user_type == "admin" || this.state.user_type == "reseller")) {
      var ref = this;
      Utility.ExecuteData("getImageAssets", this.state.token, {
        limit: 20,
        client_id: this.client_id,
        token: this.state.token
      }).then(data => {
        imageArray = data.data;
        // if (data.code == "200") {
        imageArray.map(i => {
          //  var img=this.getThumb(i)
          image_array.push({ src: i.url });
        });
        this.getGrapeJSEditor();
        //}
      });
    } else {
      this.setState({
        redirect: true
      });
    }
  }
  getGrapeJSEditor() {
    var actkn = typeof this.state.token != "undefined" ? "Bearer " + this.state.token : "";
    this.editor = grapesjs.init({
      allowScripts: 1,
      showOffsets: 1,
      autorender: 0,
      noticeOnUnload: 0,
      container: "#gjs",
      height: "700px",
      fromElement: true,
      storageManager: { type: null },

      // blockManager: { blocks: [] },
      plugins: ["gjs-blocks-basic", "gjs-blocks-flexbox"],

      canvas: {
        styles: ["../../custom.css"]
      },
      assetManager: {
        storageType: "",
        storeOnChange: true,
        storeAfterUpload: true,
        dropzone: 1,
        openAssetsOnDrop: 1,
        autoAdd: 1,
        headers: {
          headers: {
            "content-type": "multipart/form-data",
            authorization: actkn
          }
        },
        modalTitle: "Select Image",
        assets: image_array,
        uploadFile: (e, clb) => {
          var files = e.dataTransfer ? e.dataTransfer.files : e.target.files;
          if (files && files[0] && files[0].name && this.state.dragged_image !== files[0].name) {
            this.setState({ dragged_image: files[0].name }, () => {
              this.uploadAsset(files, clb);
            });
          }
        }
      }
    });
    var blockManager = this.editor.BlockManager;

    blockManager.add("my-first-block", {
      label: "Campaign block",
      content: '<div class="campaign-block">Paste your shortcode here</div>'
    });

    var pnm = this.editor.Panels;
    pnm.addButton("options", [
      {
        id: "undo",
        className: "fa fa-undo icon-undo",
        command: function command(editor, sender) {
          sender.set("active", 0);
          editor.UndoManager.undo(1);
        },
        attributes: {
          title: "Undo (CTRL/CMD + Z)"
        }
      },
      {
        id: "redo",
        className: "fa fa-repeat icon-redo",
        command: function command(editor, sender) {
          sender.set("active", 0);
          editor.UndoManager.redo(1);
        },
        attributes: {
          title: "Redo (CTRL/CMD + Y)"
        }
      }
    ]);
    const editor = this.editor;
    this.editor.render();
    var comps = this.editor.DomComponents;
    // Change background of the wrapper and set some attribute
    var wrapper = comps.getWrapper();
    wrapper.set("style", {
      "background-color": "white"
    });
    /*******************/
    var grapEditor = this.editor;
    var pfx = grapEditor.getConfig().stylePrefix;
    var modal = grapEditor.Modal;
    var cmdm = grapEditor.Commands;
    var codeViewer = grapEditor.CodeManager.getViewer('CodeMirror').clone();
    var pnm = grapEditor.Panels;
    var container = document.createElement('div');
    var btnEdit = document.createElement('button');

    codeViewer.set({
      codeName: 'htmlmixed',
      readOnly: 0,
      theme: 'hopscotch',
      autoBeautify: true,
      autoCloseTags: true,
      autoCloseBrackets: true,
      lineWrapping: true,
      styleActiveLine: true,
      smartIndent: true,
      indentWithTabs: true
    });

    btnEdit.innerHTML = 'Import';
    btnEdit.className = pfx + 'btn-prim ' + pfx + 'btn-import';
    btnEdit.onclick = function () {
      var code = codeViewer.editor.getValue();
      comps.getWrapper().set('content', '');
      grapEditor.setComponents(code.trim());
      modal.close();
    };

    cmdm.add('html-import', {
      run: function (editor, sender) {
        sender && sender.set('active', 0);
        var viewer = codeViewer.editor;
        modal.setTitle('Edit code');
        if (!viewer) {
          var txtarea = document.createElement('textarea');
          container.appendChild(txtarea);
          container.appendChild(btnEdit);
          codeViewer.init(txtarea);
          viewer = codeViewer.editor;
        }
        modal.setContent('');
        modal.setContent(container);
        codeViewer.setContent('');
        modal.open();
        viewer.refresh();
      }
    });

    pnm.addButton('options',
      [
        {
          id: 'import',
          className: 'fa fa-download',
          command: 'html-import',
          attributes: {
            title: 'Import Your Template'
          }
        }
      ]
    );

    /*******************/
  }
  uploadAsset = (files, clb) => {
    var img_type = files[0].type;
    if (img_type.includes("image")) {
      const formData = new FormData();
      formData.append("file", files[0]);
      formData.append("client_id", this.state.client_id);
      formData.append("token", this.state.token);
      var actkn = typeof this.state.token != "undefined" ? "Bearer " + this.state.token : "";
      const config = {
        headers: {
          "content-type": "multipart/form-data",
          authorization: actkn
        }
      };
      Axios.post(BASE_URL, formData, config)
        .then(response => {
          const am = this.editor.AssetManager;
          am.add([
            {
              src: response.data.url
            }
          ]);
          am.render();
          clb();
        })
        .catch(error => {
          this.setState({ errors: true });
        });
    } else {
      $("#msg_text").text(__t("invalid_file_type"));
      $("#alertinfo").show();
    }
  };
  ok() {
    this.setState({
      message: ""
    });
  }
  createTemplate() {
    var ref = this;
    const editor = this.editor;
    if (ref.checkValidation()) {
      Utility.ExecuteData("saveCustomTemplate", ref.state.token, {
        id: ref.state.id !== undefined ? ref.state.id : "",
        client_id: ref.state.client_id,
        template: editor.getHtml(),
        token: ref.state.token,
        style: editor.getCss(),
        title: ref.state.title,
        temp_type: ref.state.temp_type,
        user_type: ref.state.user_type,
        thumb: this.state.thumb,
        status: "inactive"
      }).then(result => {
        if (result.data.id) {
          ref.setState({
            id: result.data.id,
            message: "created successfully"
          });
        } else {
          ref.setState({ message: "save successfully" });
        }
      });
    }
    //  else {
    //   $("#msg_text").text("Please create a proper Template with title");
    //   $("#alertinfo").show();
    // }
  }

  handleChange(event) {
    this.setState({ [event.target.name]: event.target.value });
  }
  typeChange(e) {
    var temp_type = e.target.value;
    this.setState({
      temp_type: e.target.value
    });
  }
  setImageUrl(url) {
    //console.log(url);
    let editItem = this.state.edititem;
    editItem["url"] = url;
    this.setState({
      edititem: editItem,
      thumb: url
    });
  }
  onFormSubmit(e) {
    e.preventDefault();
    var fileimg = this.state.file;

    if (this.state.file) {
      var img_type = this.state.file.type;

      if (img_type.includes("image")) {
        const formData = new FormData();
        formData.append("file", this.state.file);
        formData.append("client_id", this.state.client_id);
        formData.append("token", this.state.token);
        var actkn = typeof this.state.token != "undefined" ? "Bearer " + this.state.token : "";
        const config = {
          headers: {
            "content-type": "multipart/form-data",
            authorization: actkn
          }
        };
        Axios.post(BASE_URL, formData, config)
          .then(response => {
            var thumbimg = this.getThumb(fileimg);

            $("#msg_text").text("File is successfully uploaded");
            $("#alertinfo").show();
            this.setState({
              uploadedImage: response.url
            });
            //   image_array.push({"src":response.data.url})  ;
            const am = this.editor.AssetManager;
            am.add([
              {
                src: response.data.url
              }
            ]);
            am.render();
          })
          .catch(error => {
            this.setState({ errors: true });
          });
        document.getElementById("file-1").value = "";
        this.state.file = "";
      } else {
        document.getElementById("file-1").value = "";
        this.state.file = "";
        $("#msg_text").text("Please select only Image type.");
        $("#alertinfo").show();
      }
    } else {
      $("#msg_text").text("Please select a file");
      $("#alertinfo").show();
    }
  }
  onChange(e) {
    this.setState({ file: e.target.files[0] });
  }
  render() {
    const { redirect } = this.state;
    if (redirect) {
      return <Redirect push to="/templates" />;
    }
    return (
      <React.Fragment>
        <div className="row wrapper border-bottom white-bg page-heading">
          <div className="col-lg-12" style={{ top: "-15px" }}>
            <h2 style={{ marginTop: 10 }}>{__t("templates")}</h2>
            <ol className="breadcrumb">
              <li className="breadcrumb-item">
                <Link to="/templates">{__t("templates")}</Link>
              </li>
              <li className="breadcrumb-item">
                <Link to="/CreateTemplate">{__t("Create Template")}</Link>
              </li>
              {this.state.user_type == "admin" || this.state.user_type == "reseller" ? (
                <div className="tempbtn">
                  {" "}
                  <button onClick={this.createTemplate} className="btn btn-success ml-3">
                    {__t("save")}
                  </button>
                </div>
              ) : (
                  ""
                )}
              <div className="gobackbtn">
                {" "}
                <Link to={"/templates/"} className="btn btn-white ml-3">
                  {__t("go_back")}
                </Link>
              </div>
              {/* <li className="breadcrumb-item active">
                <strong>{__t("client_list")}</strong>
              </li> */}
            </ol>
          </div>
          {this.state.user_type != "client" ? (
            <form className="width100">
              <div className="form-row align_center my-2 ml-2">
                <div className="col-md-3 img-container mr-3">
                  <img src={this.state.thumb} alt={""} className={"img-responsive thumb-image"} />
                  <div onClick={this.mediaToggle} className={"btn btn-primary centered"}>
                    {__t("upload_thumb_image")}
                  </div>
                  <label id="-error" className="error">
                    {this.state.errors["thumb"]}
                  </label>
                </div>

                {this.state.uploadedImage ? (
                  <div className="imgupperdiv">
                    <img style={{ height: "50px", width: "50px" }} src={this.state.uploadedImage} />
                  </div>
                ) : (
                    ""
                  )}
                <div className="box " style={{ height: "47px" }}>
                  <input type="file" onChange={this.onChange} name="file" id="file-1" className="inputfile inputfile-1" data-multiple-caption="{count} files selected" multiple accept="image/*" />
                  <label htmlFor="file-1" className="ht48">
                    {" "}
                    <i className="fa fa-upload text_white" aria-hidden="true">
                      <span className="inputfile_span">{this.state.file ? this.state.file.name : "Choose A file"}</span>
                    </i>
                  </label>
                </div>

                <button style={{ height: "46px" }} onClick={this.onFormSubmit} className="btn_ btn-primary mx-2 wd160">
                  {__t("upload")}
                </button>

                <div className="col-md-3 temp-3-form column_flex_start">
                  <input type="text" name="title" autoComplete="off" className="form-control" placeholder={__t(" Add title")} defaultValue={this.state.title} onChange={this.handleChange} />
                  <label id="-error" className="error">
                    {this.state.errors["title"]}
                  </label>
                </div>
                <div className="col-md-2 temp-3-form">
                  <strong htmlFor="inputState" className="mr-2">
                    {__t("type")}
                  </strong>
                  <select id={"type"} name="type" className="form-control" onChange={this.typeChange}>
                    <option value="free">{__t("free")}</option>
                    <option value="premium">{__t("premium")}</option>
                  </select>
                </div>
              </div>
              <div className="offset-sm-2">
                <label>{__t("uploaded_image_shown")}</label>
              </div>

              <SelectImageModal isOpen={this.state.mediaModal} mediaToggle={this.mediaToggle} setImageUrl={this.setImageUrl} item={this.state.edititem} />
            </form>
          ) : (
              ""
            )}
        </div>

        <div className="panel__top">
          <div className="panel__basic-actions" />
          <div className="panel__devices" />
          {/* <div className="panel__switcher" /> */}
        </div>
        <div className="editor-row">
          <div className="editor-canvas">
            <div id="gjs" style={{ height: "700px !important", overflow: "hidden" }} />
          </div>
        </div>
        <div className="clearfix" />
        {/* <div className="form-group">
          <label>{__t("additional_styles")}</label>
          <textarea onChange={this.handleChange} className="form-control" rows="7" name="style">
            {this.state.style}
          </textarea>
        </div> */}
        <div className="clearfix" />
        {this.state.message ? <AlertSuccess message={this.state.message} ok={this.ok} /> : ""}
      </React.Fragment>
    );
  }
}

export default CreateTemplate;
