import React from "react";

const NotFound = () => {
  return (
    <div
      style={{
        fontFamily: "Arial, sans-serif",
        textAlign: "center",
        paddingTop: "100px",
        background: "#2F4050",
        maxWidth: "800px",
        margin: "0 auto",
        padding: "40px 20px",
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
      }}
    >
      <h1 style={{ fontSize: "72px", color: "#fff", margin: "0px auto" }}>
        404 Error !
      </h1>
      <h1
        style={{
          fontSize: "48px",
          color: "#fff",
          marginBottom: "40px",
          fontWeight: 400,
          margin: "10px auto",
        }}
      >
        Page not found
      </h1>
      <a
        href="/"
        style={{
          textDecoration: "none",
          background: "#fff",
          color: "#2F4050",
          fontSize: "16px",
          borderRadius: "4px",
          padding: "15px 32px",
          //   fontSize: "32px",
          display: "inline-flex",
          margin: "20px auto 20px auto",
        }}
      >
        Go to Home
      </a>
    </div>
  );
};

export default NotFound;
