import React from "react";
import { Link } from "react-router-dom";
import RemotePagination from "../common/Table";
import Utility from "../common/Utility";
import { __t } from "../../locale/language";
import Can from "../common/AccessControl";
import Moment from "moment";
import $ from "jquery";
import SubscriptionAlert from "../common/SubscriptionAlert";
import CommonDelete from "../common/CommonDelete"
class WidgetList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      page: 1,
      data: [],
      sizePerPage: 10,
      total: 0,
      search: "",
      client_id: localStorage.getItem("client_id"),
      user_type: this.props.userPermissions.user_type,
      subscription_feature: this.props.userPermissions.subscription_feature,
      delete_id: "",
      testListObj: {}
    };
    this.deleteWidget = this.deleteWidget.bind(this);
    this.updateWidgetList = this.updateWidgetList.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.delete_cnferm = this.delete_cnferm.bind(this);
    this.delete = this.delete.bind(this);
  }

  delete_cnferm(type) {
    if (type == "yes") {
      this.deleteWidget(this.state.delete_id)
    }
    else {
      $("#alertshow").hide();
    }
  }
  delete(id) {
    this.setState({
      delete_id: id
    }, () => {
      $("#alertshow").css("display", "block")
    })
  }

  deleteWidget(id) {
    id = { id: id };
    Utility.ExecuteData("deletewidget", this.token, id)
      .then(responseJson => {
        if (responseJson.code === 200) {
          this.updateWidgetList(1, 10);
        } else {
          alert(__t("try_again"));
        }
      })
      .catch(error => {
        this.setState({ errors: true });
      });
  }
  handleChange(event) {
    this.setState({ [event.target.name]: event.target.value });
  }
  client_id = localStorage.getItem("client_id");
  renderButtons(cell, row, rowIndex) {
    return (
      <span>
        <Can
          I={["update-widget"]}
          userPermissions={this.props.userPermissions.userPermissions}
        >
          <Link
            to={{
              pathname: `/widgets/update/${row.id}`,
              state: { id: `${row.id}` }
            }}
            className="btn btn-primary btn-sm"
            style={{ marginRight: "10px" }}
          >
            <i className="fa fa-pencil" aria-hidden="true" />
          </Link>
        </Can>
        <Can
          I={["delete-widget"]}
          userPermissions={this.props.userPermissions.userPermissions}
        >
          <button
            onClick={() => this.delete(`${row.id}`)}
            className="btn btn-primary btn-sm"
          >
            <i className="fa fa-trash" aria-hidden="true" />
          </button>
        </Can>
        {this.state.testListObj && this.state.testListObj[row.id] ?
          <span className="btn btn-sm" 
            style={{ background:"red", color: "white", marginLeft: "10px" }}>
            Test Mode
          </span>
        :
          <span className="btn btn-sm" 
            style={{ background:"green", color: "white", marginLeft: "10px" }}>
            Live Mode
          </span>
        }
      </span>
    );
  }
  token = localStorage.getItem("token");
  handleTableChange = (type, { page, sizePerPage }) => {
    const currentIndex = (page - 1) * sizePerPage;
    //write fetch data function
    this.updateWidgetList(page, sizePerPage);
  };
  updateWidgetList(page = 1, sizePerPage = 10) {
    $("#alertshow").hide();
    Utility.ExecuteData("widgetdata", this.token, {
      page: page,
      limit: sizePerPage,
      search: this.state.search,
      client_id: this.state.client_id
    }).then(data => {
      this.setState({
        page,
        data: data.data.rows,
        sizePerPage,
        total: data.data.count
      });
    });
  }
  componentDidMount() {
    this.getContactListObj();
  }
  getOnMount = () => {
    if (
      this.state.subscription_feature.includes("widget") &&
      this.state.user_type == "client"
    ) {
      this.updateWidgetList(1, 10);
    } else {
      $("#subs_text").text(__t("please_upgrade_your_plan"));
      $("#subs_alert").show();
      this.updateWidgetList(1, 10);
    }
  }
  getContactListObj = async () => {
    let res = await Utility.executeRequest("get_contact_setdata_list", { 
      type: "widget", client_id: this.state.client_id 
    });
    if(res.data) {
      this.setState({ testListObj: res.data },()=>{
        this.getOnMount();
      });
    }
  }
  
  render() {
    const { data, sizePerPage, page, total } = this.state;
    const columns = [
      { dataField: "title", text: __t("title") },
      { dataField: "status", text: __t("status") },
      // {
      //   dataField: "created_at",
      //   text: __t("created_at"),
      //   formatter: cellContent =>
      //     Moment(cellContent).format("DD-MMM-YYYY HH:mm:ss")
      // },
      {
        dataField: "updated_at",
        text: __t("updated_at"),
        formatter: cellContent =>
          Moment(cellContent).format("DD-MMM-YYYY HH:mm:ss")
      },
      {
        dataField: "action",
        text: "Action",
        formatter: this.renderButtons.bind(this)
      }
    ];

    return (
      <Can
        I={["view-widget"]}
        userPermissions={this.props.userPermissions.userPermissions}
      >
      <CommonDelete delete_cnferm={this.delete_cnferm} />
        {this.state.user_type == "admin" ||
        this.state.user_type == "reseller" ? (
          <div>
            <div className="row wrapper border-bottom white-bg page-heading">
              <div className="col-lg-10">
                <h2 style={{ marginTop: 10 }}>{__t("widgets_list")}</h2>
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to="/">{__t("dashboard")}</Link>
                  </li>
                  <li className="breadcrumb-item">
                    <Link to="/widgets">{__t("widgets")}</Link>
                  </li>
                  <li className="breadcrumb-item active">
                    <strong>{__t("widgets_list")}</strong>
                  </li>
                </ol>
              </div>
            </div>
            <div className="wrapper wrapper-content animated fadeInRight">
              <div className="row">
                <div className="col-lg-12 ">
                  <div className="ibox ">
                    <div className="ibox-title">
                      <div className="row from-control">
                        &nbsp; &nbsp; &nbsp;
                        <input
                          className="form-control col-md-3"
                          type="text"
                          name="search"
                          id="search_field"
                          onChange={this.handleChange}
                          placeholder={__t("search_by_title")}
                        />
                        &nbsp;
                        <input
                          className="btn btn-primary"
                          type="button"
                          onClick={() => {
                            this.updateWidgetList();
                          }}
                          name="btn_search"
                          value={__t("search")}
                        />
                        &nbsp;
                        <input
                          className="btn btn-warning"
                          type="button"
                          onClick={() => {
                            this.setState({ search: "" }, () => {
                              this.updateWidgetList();
                              document.getElementById("search_field").value =
                                "";
                            });
                          }}
                          name="btn_search"
                          value={__t("Clear")}
                        />
                        &nbsp;
                        <Can
                          I={["create-widget"]}
                          userPermissions={
                            this.props.userPermissions.userPermissions
                          }
                        >
                          <Link
                            className="btn btn-primary"
                            to="/widgets/create"
                          >
                            {this.props.userPermissions.user_type == "client"
                              ? __t("create_widget")
                              : __t("create_default_widget")}
                          </Link>
                        </Can>
                        <br />
                        &nbsp; &nbsp;
                        <strong style={{ marginTop: "10px" }}>
                          {__t("total_widgets")} : {this.state.total}
                        </strong>
                      </div>
                    </div>
                    <div className="ibox-content">
                      {this.state.total > 0 ? (
                        <RemotePagination
                          columns={columns}
                          data={data}
                          page={page}
                          sizePerPage={sizePerPage}
                          totalSize={total}
                          onTableChange={this.handleTableChange}
                        />
                      ) : (
                        __t("no_record_found")
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : this.state.subscription_feature.includes("widget") &&
          this.state.user_type == "client" ? (
          <div>
            <div className="row wrapper border-bottom white-bg page-heading">
              <div className="col-lg-10">
                <h2 style={{ marginTop: 10 }}>{__t("widgets_list")}</h2>
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to="/">{__t("dashboard")}</Link>
                  </li>
                  <li className="breadcrumb-item">
                    <Link to="/widgets">{__t("widgets")}</Link>
                  </li>
                  <li className="breadcrumb-item active">
                    <strong>{__t("widgets_list")}</strong>
                  </li>
                </ol>
              </div>
            </div>
            <div className="wrapper wrapper-content animated fadeInRight">
              <div className="row">
                <div className="col-lg-12 ">
                  <div className="ibox ">
                    <div className="ibox-title">
                      <div className="row from-control">
                        &nbsp; &nbsp; &nbsp;
                        <input
                          className="form-control col-md-3"
                          type="text"
                          name="search"
                          id="search_field"
                          onChange={this.handleChange}
                          placeholder={__t("search_by_title")}
                        />
                        &nbsp;
                        <input
                          className="btn btn-primary"
                          type="button"
                          onClick={() => {
                            this.updateWidgetList();
                          }}
                          name="btn_search"
                          value={__t("search")}
                        />
                        &nbsp;
                        <input
                          className="btn btn-warning"
                          type="button"
                          onClick={() => {
                            this.setState({ search: "" }, () => {
                              this.updateWidgetList();
                              document.getElementById("search_field").value =
                                "";
                            });
                          }}
                          name="btn_search"
                          value={__t("Clear")}
                        />
                        &nbsp;
                        {this.props.userPermissions.subscription_status ==
                          "active" ||
                        this.state.subscription_feature.includes("widget") ||
                        this.state.user_type == "admin" ||
                        this.state.user_type == "reseller" ? (
                          <Can
                            I={["create-widget"]}
                            userPermissions={
                              this.props.userPermissions.userPermissions
                            }
                          >
                            <Link
                              className="btn btn-primary"
                              to="/widgets/create"
                            >
                              {__t("create_widget")}
                            </Link>
                          </Can>
                        ) : (
                          ""
                        )}
                        <br />
                      </div>
                    </div>
                    <div className="ibox-content">
                      {this.state.total > 0 ? (
                        <RemotePagination
                          columns={columns}
                          data={data}
                          page={page}
                          sizePerPage={sizePerPage}
                          totalSize={total}
                          onTableChange={this.handleTableChange}
                        />
                      ) : (
                        <div>{__t("no_record_found")}</div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <SubscriptionAlert />
        )}
      </Can>
    );
  }
}

export default WidgetList;
