import React from "react";
import { Redirect, Link } from "react-router-dom";
import { ListGroup, ListGroupItem, Checkbox } from "react-bootstrap";
import Utility from "../common/Utility";
import Editor from "./editor";
import { __t } from "../../locale/language";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import ReactQuill from "react-quill";
import { AccessControlFunc } from "../common/AccessControl2";

class EditorModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      modal: false,
      item: this.props.item,
      merge_column: this.props.merge_column
    };
    this.editor = this.editor.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.addDonation = this.addDonation.bind(this);
    this.removeDonation = this.removeDonation.bind(this);
    this.addOption = this.addOption.bind(this);
    this.removeOption = this.removeOption.bind(this);
    this.handleCheck = this.handleCheck.bind(this);
    this.editorChange = this.editorChange.bind(this);
  }

  handleChange(event, type="", key=null) {
    var obj = {};
    let name = event.target.name;
    let val = event.target.value;
    obj["item"] = this.state.item;

    if(name == "optionsTitle") {
      obj["item"]["optionsTitle"] = obj["item"]["optionsTitle"] ? obj["item"]["optionsTitle"] : [];
      obj["item"]["optionsTitle"][key] = val
    } else {
      if (name == "name") {
        obj["item"][name] = val.replace(/ /g, "_").toLowerCase();
      } else {
        obj["item"][name] = val;
      }
    }
    if(name == "required") {
      obj["item"][name] = event.target.checked;
    }
    this.setState(obj);
  }
  addDonation() {
    if (this.state.item.donation_amount !== "" && typeof this.state.item.donation_amount !== "undefined") {
      if (this.state.item.donation_amount < 0) {
        alert(__t("positive_amount_only"));
        return;
      }
      let arr = typeof this.state.item.donation_arr !== "undefined" ? this.state.item.donation_arr : [];
      arr.push(this.state.item.donation_amount);
      var obj = {};
      obj["item"] = this.state.item;
      obj["item"]["donation_amount"] = "";
      obj["item"]["donation_arr"] = [...new Set(arr)];
      this.setState(obj);
    } else {
      alert(__t("pls_add_amt"));
    }
  }
  handleCheck(id) {
    var elem = document.getElementById(id);
    var obj = {};
    obj["item"] = this.state.item;
    obj["item"]["required"] = elem.checked;
    this.setState(obj);
  }
  handleLock = id => {
    var elem = document.getElementById(id);
    var obj = {};
    obj["item"] = this.state.item;
    obj["item"]["lock_message"] = elem.checked;
    this.setState(obj);
  };
  removeDonation(e) {
    let arr = typeof this.state.item.donation_arr !== "undefined" ? this.state.item.donation_arr : [];

    var index = arr.indexOf(e);
    if (index > -1) {
      arr.splice(index, 1);
    }

    var obj = {};
    obj["item"] = this.state.item;
    obj["item"]["donation_amount"] = "";
    obj["item"]["donation_arr"] = arr;

    this.setState(obj);
  }
  addOption() {
    if (this.state.item.option !== "" && typeof this.state.item.option !== "undefined") {
      let arr = typeof this.state.item.selectionOption !== "undefined" ? JSON.parse(this.state.item.selectionOption) : [];

      if (arr.length) {
        var id = arr.length + 1;
        var found = arr.some(el => {
          return el.option == this.state.item.option;
        });
        if (!found) {
          arr.push({ option: this.state.item.option });
        }
        // if (arr.indexOf(this.state.item.option) !== -1) {
        //   arr.push({ option: this.state.item.option });
        // }
      } else {
        arr.push({ option: this.state.item.option });
      }

      var obj = {};
      obj["item"] = this.state.item;
      obj["item"]["option"] = "";
      obj["item"]["selectionOption"] = JSON.stringify(arr);

      this.setState(obj);
    } else {
      alert("Please add a option");
    }
  }
  editorChange = (value, item, name) => {
    var obj = {};
    obj["item"] = item;
    obj["item"][name] = value;
    this.setState(obj);
  };
  removeOption(index) {
    let arr = typeof this.state.item.selectionOption !== "undefined" ? JSON.parse(this.state.item.selectionOption) : [];

    //var index = arr.indexOf(e);
    if (index > -1) {
      arr.splice(index, 1);
    }

    var obj = {};
    obj["item"] = this.state.item;
    obj["item"]["option"] = "";
    obj["item"]["selectionOption"] = JSON.stringify(arr);

    this.setState(obj);
  }
  componentWillReceiveProps({ isOpen, item, merge_column }) {
    this.setState({ modal: isOpen, item: item, merge_column });
  }
  emailNotAllowed = (e) => {
    if (e.keyCode == 76 && e.target.value.toLowerCase() == "emai") {
      alert(__t("email_in_field_name_not_allowed"));
      e.preventDefault();
      return false;
    }
  }
  editor = item => {
    switch (item.element) {
      case "Image":
        return (
          <div>
            <div className={`form-group`}>
              <label className="control-label">{__t("heading")}</label>
              <ReactQuill
                placeholder={__t("heading")}
                defaultValue={item.heading}
                onChange={e => {
                  this.editorChange(e, item, "heading");
                }}
              />
            </div>
            <div className={`form-group`}>
              <label className="control-label">{__t("paragraph")}</label>
              <ReactQuill
                placeholder={__t("heading")}
                defaultValue={item.paragraph}
                onChange={e => {
                  this.editorChange(e, item, "paragraph");
                }}
              />

            </div>
            <div className={`form-group`}>
              <img src={item.url} height="100px" width="100px" />
              &nbsp;&nbsp;
              <input type="button" className="btn btn-primary" onClick={this.props.mediaToggle} value="Select Image" />
            </div>
            <div className="form-group">
              <label className="control-label required-field">{__t("image_alignment")}</label>
              <select name="imageAlign" className="form-control" value={item.imageAlign || "center"} onChange={this.handleChange}>
                <option value="center">{__t("center")}</option>
                <option value="left">{__t("left")}</option>
                <option value="right">{__t("right")}</option>
              </select>
            </div>
            <div className={`form-group`}>
              <label className="control-label">{__t("custom_css_class")}</label>
              <input type="text" name="className" value={item.className} placeholder={__t("custom_css_class")} className={`form-control`} onChange={this.handleChange} />
            </div>
            <div className={`form-group`}>
              <label className="control-label">{__t("height")}</label>
              <input type="text" name="height" value={item.height} placeholder={__t("height") + " px"} className={`form-control`} onChange={this.handleChange} />
            </div>
            <div className="form-group">
              <label className="control-label">{__t("font_color")}</label>
              <input
                type="color"
                value={item.fontColor || "#000000"}
                name="fontColor"
                className="form-control"
                style={{ height: "30px", width: "100px", padding: "2px" }}
                onChange={this.handleChange}
              />
            </div>

          </div>
        );
      case "Headings":
        return (
          <div>
            <div className={`form-group`}>
              <label className="control-label">{__t("text")}</label>
              <ReactQuill
                defaultValue={item.text}
                onChange={e => {
                  this.editorChange(e, item, "text");
                }}
              />

            </div>
            <div className={`form-group`}>
              <label className="control-label">{__t("custom_css_class")}</label>
              <input type="text" name="className" value={item.className} placeholder={__t("custom_css_class")} className={`form-control`} onChange={this.handleChange} />
            </div>
          </div>
        );
      case "Paragraph":
        return (
          <div>
            <div className={`form-group`}>
              <label className="control-label">{__t("text")}</label>
              <ReactQuill
                defaultValue={item.text}
                onChange={e => {
                  this.editorChange(e, item, "text");
                }}
              />

            </div>
            <div className={`form-group`}>
              <label className="control-label">{__t("custom_css_class")}</label>
              <input type="text" name="className" value={item.className} placeholder={__t("custom_css_class")} className={`form-control`} onChange={this.handleChange} />
            </div>
          </div>
        );
      case "Text Field":
        return (
          <div>
            <div className={`form-group`}>
              <label className="control-label">{__t("field_label")}</label>
              <input type="text" name="label" value={item.label} placeholder={item.placeholder} className={`form-control`} onChange={this.handleChange} />
            </div>
            {item.name !== "email" ?
              <div className={`form-group`}>
                <label className="control-label">{__t("field_name")}</label>
                <input type="text" name="name" onKeyDown={this.emailNotAllowed} value={item.name} placeholder={item.name} className={`form-control`} onChange={this.handleChange} />
              </div>
              : null}
            <div className={`form-group`}>
              <label className="control-label">{__t("placeholder")}</label>
              <input type="text" name="placeholder" value={item.placeholder} placeholder={item.placeholder} className={`form-control`} onChange={this.handleChange} />
            </div>
            {!(item.name !== "email" || item.name !== "name") ?
              <div className={`form-group`}>
                <label className="control-label">{__t("type")}</label>
                <select name="validation" className="form-control" value={item.validation} onChange={this.handleChange}>
                  <option value="">{__t("none")}</option>
                  <option value="Email">{__t("email")}</option>
                  <option value="Phone">{__t("phone")}</option>
                </select>
              </div>
              : null}
            <div className={`form-group`}>
              <label className="control-label">
                {__t("required")} &nbsp;&nbsp;
                <input
                  type="checkbox"
                  value={true}
                  id={"required_" + item.id}
                  name="required"
                  onClick={e => {
                    if (item.name != "email" || !item.required ) { this.handleCheck("required_" + item.id) } else { alert(__t("email_field_is_always_mandatory"));e.preventDefault(); return false; }
                  }}
                  defaultChecked={item.required || (item.name == "email" && item.required) ? true : false}
                />
              </label>
            </div>
          </div>
        );
      case "Text Area":
        return (
          <div>
            <div className={`form-group`}>
              <label className="control-label">{__t("field_label")}</label>
              <input type="text" name="label" value={item.label} placeholder="Label" className={`form-control`} onChange={this.handleChange} />
            </div>
            {/* <div className={`form-group`}>
              <label className="control-label">{__t("field_name")}</label>
              <input type="text" name="name" value={item.name} placeholder={item.name} className={`form-control`} onChange={this.handleChange} />
            </div> */}
            <div className={`form-group`}>
              <label className="control-label">{__t("defaile_message")}</label>
              <Editor
                defaultValue={item.text}
                onChange={e => {
                  this.editorChange(e, item, "text");
                }}
                embedColumns={this.state.merge_column}
                embedName={this.props.embedName}
              />
            </div>
            <div className={`form-group`}>
              <label className="control-label">
                <input
                  type="checkbox"
                  value={true}
                  id={"required_" + item.id}
                  name="required"
                  onClick={e => {
                    this.handleCheck("required_" + item.id);
                  }}
                  defaultChecked={item.required}
                />
                &nbsp;{__t("required")}
              </label>
              &nbsp;&nbsp;&nbsp;&nbsp;
              <label>
                <input
                  type="checkbox"
                  value={true}
                  id={"lock_" + item.id}
                  name="lock_message"
                  onClick={e => {
                    this.handleLock("lock_" + item.id);
                  }}
                  defaultChecked={item.lock_message}
                />
                &nbsp;{__t("lock_message")}
              </label>
            </div>
          </div>
        );
      case "Donation":
        return (
          <div>
            <div className={`form-group`}>
              <label className="control-label">{__t("amount")}</label>
              <div className="row">
                &nbsp;&nbsp;
                <input
                  type="number"
                  name="donation_amount"
                  value={typeof this.state.item.donation_amount !== "undefined" ? this.state.item.donation_amount : ""}
                  placeholder="Amount"
                  className={`form-control col-md-4`}
                  onChange={this.handleChange}
                />{" "}
                &nbsp;&nbsp;
                <input
                  type="button"
                  className=" btn btn-primary"
                  onClick={() => {
                    this.addDonation();
                  }}
                  value="+ Add"
                />
              </div>
              <br />
              <div className="container">
                <div className="row">
                  {typeof this.state.item.donation_arr !== "undefined"
                    ? this.state.item.donation_arr.map((val, key) => {
                      return (
                        <div className="add_opt" key={key}>
                          {val}
                          <input
                            type="button"
                            className="mx-2 close_btn_sm"
                            onClick={() => {
                              this.removeDonation(val);
                            }}
                            value="x"
                            id={val}
                          />
                        </div>
                      );
                    })
                    : ""}
                </div>
              </div>
            </div>
          </div>
        );
      case "Address By Geo-Location":
        return (
          <div className={`form-group`}>
            <label className="control-label">{__t("label")}</label>
            <input type="text" name="label" value={item.label} className="form-control" onChange={this.handleChange} />
          </div>
        );
      case "Selection List":
        let options = typeof this.state.item.selectionOption !== "undefined" ? JSON.parse(this.state.item.selectionOption) : [];
        return (
          <div>
            <div className={`form-group`}>
              <label className="control-label">{__t("field_label")}</label>
              <input type="text" name="label" value={item.label} placeholder={item.placeholder} className={`form-control`} onChange={this.handleChange} />
            </div>
            <div className={`form-group`}>
              <label className="control-label">{__t("field_name")}</label>
              <input type="text" name="name" value={item.name} placeholder={item.name} className={`form-control`} onChange={this.handleChange} />
            </div>
            <div className={`form-group`}>
              <label className="control-label">{__t("add-option")}</label>
              <div className="container">
                <div className="row">
                  <input
                    type="text"
                    name="option"
                    value={typeof this.state.item.option !== "undefined" ? this.state.item.option : ""}
                    placeholder="Add Option"
                    className={`form-control col-md-6`}
                    onChange={this.handleChange}
                  />
                  <input
                    type="button"
                    className="mx-2  btn btn-primary"
                    onClick={() => {
                      this.addOption();
                    }}
                    value="+ Add"
                  />
                </div>
              </div>
              <br />
              <div className="container">
                <div className="row">
                  {options.map((val, key) => {
                    return (
                      <div className="add_opt" key={key}>
                        {val.option}
                        <input
                          type="button"
                          className="mx-2 close_btn_sm"
                          onClick={() => {
                            this.removeOption(key);
                          }}
                          value="x"
                          name="x"
                          id={key}
                        />
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
            <div className={`form-group`}>
              <label className="control-label">
                {__t("required")} &nbsp;&nbsp;
                <input type="checkbox" value={true} name="required" onChange={this.handleChange} defaultChecked={item.required} />
              </label>
            </div>
          </div>
        );
      case "Contsct List Search":
        return (
          <div className={`form-group`}>
            <label className="control-label">Contact list search available in your widget</label>
          </div>
        );
      case "Email Specific Person":
        return (
          <div className={`form-group`}>
            <label className="control-label">Contact Name</label>
            <input type="text" name={"name"} value={item.name} className={"form-control"} onChange={this.handleChange} />
            <label className="control-label">Contact Email</label>
            <input type="text" name={"email"} value={item.email} className={"form-control"} onChange={this.handleChange} />
          </div>
        );
      case "Submit Button":
        let is_newsletter = AccessControlFunc(
          ["update-user"],
          this.props.userPermissions,
          ["newsletter_autoresponder"]
        );
        return (
          <div className={`form-group`}>
            <label className="control-label">{__t("button_title")}</label>
            <input type="text" name={"title"} value={item.title} className="form-control" onChange={this.handleChange} />
            {is_newsletter && <>
              <label className="control-label">{__t("Button Title when Signup for Newsletter ")}</label>
              <input type="text" name={"signuptitle"} value={item.signuptitle} className="form-control" onChange={this.handleChange} />
            </>}
            
            <label className="control-label">{__t("button_color")}</label>
            <input type="color" name={"color"} value={item.color} className="form-control col-sm-2" onChange={this.handleChange} style={{ height: "40px" }} />
            <label className="control-label">{__t("text_color")}</label>
            <input type="color" name={"text_color"} value={item.text_color} className="form-control col-sm-2" onChange={this.handleChange} style={{ height: "40px" }} />
            <label className="control-label">{__t("alignment")}</label>
            <select name="align" value={item.align} onChange={this.handleChange} class={"form-control"}>
              <option value="center">{__t("center")}</option>
              <option value="left">{__t("left")}</option>
              <option value="right">{__t("right")}</option>
            </select>
          </div>
        );
      case "Checkbox":
        return item.name == "privacy_policy" ? (
          <React.Fragment>
            <div className={`form-group`}>
              <label className="control-label">{__t("label")}</label>
              <input type="text" name={"label"} value={item.label} className="form-control" onChange={this.handleChange} />
              {item.name == "privacy_policy" ? <label>{__t("define_your_privacy_policy_above")}</label> : ""}
            </div>
            <div className={`form-group`}>
              <label className="control-label">{__t("hyper link")}</label>
              <input type="text" name={"hyperlink_label"} value={item.hyperlink_label} className="form-control" onChange={this.handleChange} />
            </div>
          </React.Fragment>
        ) : (
            <div className={`form-group`}>
              <label className="control-label">{__t("label")}</label>
              <input type="text" name={"label"} value={item.label} className="form-control" onChange={this.handleChange} />
            </div>
          )
      case "Radiobtn":
        return (
          <React.Fragment>
            <div className={`form-group`}>
              <label className="control-label">{__t("label")}</label>
              <input type="text" name={"label"} value={item.label} className="form-control" onChange={this.handleChange} />
            </div>
            {item.options && item.options.map((el,key)=>{
              let option_title = item.optionsTitle ? item.optionsTitle[key] : el;
              let checked = false;
              if(item.newsletter_signup && el === item.newsletter_signup) {
                checked = true;
              }
              return (
              <div className={`form-group`} key={key}>
                <label style={{ marginRight: item.btnMargin }} key={key}>
                  <input
                    type="radio"
                    name={item.name}
                    value={el}
                    onClick={this.handleChange}
                    defaultChecked={checked}
                  />
                  {/* {" " + el + " "} */}
                  <input type="text" name={"optionsTitle"} 
                    value={option_title} 
                    className="form-control" 
                    onChange={(e)=>this.handleChange(e, "optionsTitle", key)}
                    placeholder={__t(option_title + " title")}
                    style={{ width: "600px" }}
                  />
                </label>
                {/* <label className="control-label">{__t("Button") + " " + (key + 1) + ": "}</label>
                <p>{el}</p> */}
              </div>
              )
            })}
          </React.Fragment>
        )
    }
  };
  render() {
    return (
      <Modal key={1} isOpen={this.state.modal} toggle={() => this.props.toggle()} className={this.props.className}>
        <ModalHeader toggle={() => this.props.toggle()}>Customize</ModalHeader>
        <ModalBody>{this.editor(this.state.item)}</ModalBody>
        <ModalFooter>
          <Button
            color="primary"
            onClick={() => {
              this.props.toggle();
            }}
          >
            {__t("ok")}
          </Button>{" "}
          {/* <Button
            color="secondary"
            onClick={() => {
              this.props.toggle();
            }}
          >
            {__t("cancel")}
          </Button> */}
        </ModalFooter>
      </Modal>
    );
  }
}

export default EditorModal;
