import React from "react";
import { Redirect, Link } from "react-router-dom";
import { ListGroup, ListGroupItem, Checkbox } from "react-bootstrap";
import Utility from "../common/Utility";
import { __t } from "../../locale/language";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import $ from "jquery";
import Alertinfo from "../common/Alertinfo";
import Can from "../common/AccessControl";
import Moment from "moment";
var kb = "";
var date = "";
class AssetModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      modal: false,
      item: this.props.item,
      image: "",
      token: localStorage.getItem("token")
    };

    this.handleChange = this.handleChange.bind(this);
    this.submit = this.submit.bind(this);
  }
  handleChange(event) {
    var obj = {};
    let name = event.target.name;
    let val = event.target.value;
    obj["item"] = this.state.item;
    obj["item"][name] = val;
    this.setState(obj);
  }
  componentWillReceiveProps({ isOpen, item, image }) {
    if (typeof item != "undefined" && typeof image != "undefined") {
      this.setState({ modal: isOpen, item: item, image: image }, () => { });
      date = Moment(item.updated_at).format("DD-MMM-YYYY HH:mm:ss");
      kb = Utility.checkSize(item.size);
    }
  }
  submit(evt) {
    let promise = new Promise((resolve, reject) => {
      Utility.ExecuteData("assetsupdate", this.state.token, {
        token: this.state.token,
        item: evt.item
      })
        .then(responseJson => {
          resolve("updated");
        })
        .catch(error => {
          alert("internal error");
        });
    });
    promise.then(message => {
      this.props.toggle();
    });
  }

  render() {
    let item = this.state.item;
    //console.log(item)
    var file_typ;
    var check_image;
    let default_url = "/img/";
    if (typeof item != "undefined" && typeof item.id != "undefined") {
      var vid = document.getElementById("imgshow");
      ////console.log(this.state.image);
      file_typ = item.file_type;
      check_image = file_typ.substring(0, 5);
      if (check_image != "video" && check_image != "image") {
        ////console.log("dvdbhg");
        $(".assets_img_1").css("widtht", "200px");
      }

      var body = (
        <ModalBody>
          <div className="">
            <div className="img_attach_container">
              <div className="img_attach_left">
                {check_image != "video" && check_image != "image" ? (
                  <img
                    id="imgshow"
                    alt="an image"
                    className="assets_img_1"
                    style={{ width: "200px" }}
                    src={this.state.image}
                  />
                ) : check_image == "image" ? (
                  <img
                    id="imgshow"
                    alt="an image"
                    className="assets_img_1"
                    src={item.url_deleted != "" ? item.url_deleted : item.url}
                  />
                ) : (
                      <video
                        controls
                        autoPlay
                        className="assets_img_1"
                        src={item.url_deleted != "" ? item.url_deleted : item.url}
                      />
                    )}
              </div>
              <div className="img_attach_right model_img_wrapper">
                <div className="flx_column border_btm">
                  <label>
                    <strong>{__t("file_name")}:</strong>{" "}
                    <span>{item.name}</span>
                  </label>
                  <label>
                    <strong>{__t("file_type")}:</strong>{" "}
                    <span>{item.file_type}</span>
                  </label>
                  <label>
                    <strong>{__t("upload_on")}:</strong> <span>{date}</span>
                  </label>
                  <label>
                    <strong>{__t("file_size")}:</strong> <span>{kb}</span>
                  </label>
                </div>
                <div className="form-inline margintop20 imgattach_details">
                  <div className="form-group p-1">
                    <label className="control-label" htmlFor="email">
                      {__t("url")}:
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="email"
                      placeholder=""
                      name=""
                      defaultValue={item.url}
                      readOnly
                    />
                  </div>
                  <div className="form-group p-1">
                    <label className="control-label" htmlFor="email">
                      {__t("title")}:{" "}
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="title"
                      placeholder=""
                      name="title"
                      value={item.title}
                      onChange={this.handleChange}
                    />
                  </div>
                  <div className="form-group p-1">
                    <label className="control-label" htmlFor="Caption">
                      {__t("caption")}:
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="Caption"
                      placeholder=""
                      name="description"
                      value={item.description}
                      onChange={this.handleChange}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </ModalBody>
      );
    }

    return (
      <Modal
        isOpen={this.state.modal}
        toggle={() => this.props.toggle()}
        className={`modal-lg`}
      >
        <ModalHeader className="padding_1020 model-header">
          {/* // toggle={() => this.props.toggle()} */}
          <strong>Attachment Details</strong>
        </ModalHeader>
        {body}
        <ModalFooter className="padding_1020">
          {/* <Can
            I={["delete-assets"]}
            userPermissions={this.props.userPermissions.userPermissions}
          > */}
          <Button
           className="btn_custom"
           color="primary"
            onClick={() => {
              this.props.toggle();
            }}
           
          >
            {__t("close")}
          </Button>
          <Button
            className="btn_custom"
            color="primary"
            onClick={() => {
              this.submit(this.state);
            }}
          >
            {__t("apply")}
          </Button>
          {/* </Can>{" "} */}
        </ModalFooter>
      </Modal>
    );
  }
}

export default AssetModal;
