import React from "react";
import { Tabs, Tab } from "react-bootstrap";
import APIIntegration from "./Tab6";
import Utility from "../common/Utility";
import "../../styles/sync_settings_styles.css";

class ClientAPITabs extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      activeTab: "tab1",

      nationSlug: "",
      title: "",
      description: "",
      apiKey: "",
      oauthClientId: "",
      oauthClientSecret: "",

      overrideSMTP: false,
      senderEmail: "",
      password: "",
      host: "",
      senderName: "",
      port: "",

      nationBuilderPersonFields: {
        first_name: "/first_name",
        last_name: "/last_name",
        email: "/email",
        email_opt_in: "/email_opt_in",
        ppfirstName: "name",
        pplastName: "name",
        address: "address1",
        city: "city",
        state: "state",
        country: "country_code",
        postal_code: "zip",
      },

      showSMPTSettings: false,
    };
  }

  handleTabSelect = (tabId) => {
    this.setState({
      activeTab: tabId,
    });
  };

  handleInputChange = (event) => {
    const { name, value } = event.target;
    this.setState({
      [name]: value,
    });
  };

  handleRadioChange = (event) => {
    const { name, value } = event.target;

    if (name === "overrideSMTP") {
      this.setState((prevState) => ({
        overrideSMTP: value === "on" ? true : false,
      }));
    }
  };

  handleFieldChange = (field, event) => {
    const newValue = event.target.value;

    this.setState({
      nationBuilderPersonFields: {
        ...this.state.nationBuilderPersonFields,
        [field]: newValue,
      },
    });
  };

  handleFormSubmit1 = async (event) => {
    event.preventDefault();

    const { nationSlug, oauthClientId, title, description, oauthClientSecret } =
      this.state;

    const action = "savebuilderconfig";
    const token = localStorage.getItem("token");
    const loginDetails = JSON.parse(localStorage.getItem("lgn_dtl"));
    const client_id = loginDetails.client_id;

    const data = {
      user_data: {
        client_id: client_id,
        nation_slug: nationSlug,
        oauth_client_id: oauthClientId,
        oauth_client_secret: oauthClientSecret,
        title: title,
        description: description,
      },
    };

    try {
      const response = await Utility.ExecuteData(action, token, data, true);
      const { code, message } = response;

      if (code == 200) {
        console.log("Data Saved.");

        // Reload the page after successful form submission
        window.location.reload();
      } else {
        console.log(message);
      }
    } catch (error) {
      console.error("An error occurred:", error);
    }
  };

  handleFormSubmit2 = async (event) => {
    event.preventDefault();

    const { overrideSMTP, senderEmail, password, host, senderName, port } =
      this.state;

    const action = "savebuildersmtp";
    const token = localStorage.getItem("token");
    const loginDetails = JSON.parse(localStorage.getItem("lgn_dtl"));
    const client_id = loginDetails.client_id;

    const data = {
      user_data: {
        client_id: client_id,
        override_default: overrideSMTP,
        sender_email: senderEmail,
        sender_name: senderName,
        password: password,
        host: host,
        port: port,
      },
    };

    try {
      const response = await Utility.ExecuteData(action, token, data, true);
      const { success, message } = response;

      if (success) {
        console.log("Data Saved.");
      } else {
        console.log(message);
      }
    } catch (error) {
      console.error("An error occurred:", error);
    }
  };

  handleFormSubmit3 = async (event) => {
    event.preventDefault();

    const action = "savecrmsync";
    const token = localStorage.getItem("token");

    const payload = {
      nationBuilderPersonFields: JSON.stringify(
        this.state.nationBuilderPersonFields
      ),
    };

    try {
      const response = await Utility.ExecuteData(action, token, payload, false);
      const { success, data } = response;

      if (success) {
        console.log(data);
      } else {
        console.log(response.error);
      }
    } catch (error) {
      console.error("An error occurred:", error);
    }
  };

  getFormData1 = async () => {
    const action = "getbuilderconfig";
    const token = localStorage.getItem("token");
    const loginDetails = JSON.parse(localStorage.getItem("lgn_dtl"));
    const client_id = loginDetails.client_id;

    const dataa = {
      client_id: client_id,
    };

    try {
      const response = await Utility.ExecuteData(action, token, dataa, true);
      const { data } = response;
      // Extract the values from the 'data' object
      if (response.code == 200) {
        const {
          nation_slug: nationSlug,
          oauth_client_id: oauthClientId,
          oauth_client_secret: oauthClientSecret,
          title,
          description,
        } = data;

        // Update the component's state using the extracted values
        this.setState({
          nationSlug,
          oauthClientId,
          oauthClientSecret,
          title,
          description,
        });
      }
    } catch (error) {
      console.error("An error occurred:", error);
    }
  };
  getFormData2 = async () => {
    const action = "getbuildersmtp";
    const token = localStorage.getItem("token");
    const loginDetails = JSON.parse(localStorage.getItem("lgn_dtl"));
    const client_id = loginDetails.client_id;

    const dataa = {
      client_id: client_id,
    };

    try {
      const response = await Utility.ExecuteData(action, token, dataa, true);
      const { data } = response;
      // Extract the values from the 'data' object
      if (response && response.code == 200) {
        const {
          override_default: overrideSMTP,
          sender_email: senderEmail,
          password,
          host,
          sender_name: senderName,
          port,
        } = data;

        // Set overrideSMTP based on the value of override_default
        const overrideSMTPValue = overrideSMTP === 1 ? true : false;

        // Update the component's state using the extracted values
        this.setState({
          overrideSMTP: overrideSMTPValue,
          senderEmail,
          password,
          host,
          senderName,
          port,
        });
      }
    } catch (error) {
      console.error("An error occurred:", error);
    }
  };
  // getFormData3 = async () => {
  //   const action = "getcrmsync";
  //   const token = localStorage.getItem("token");

  //   const dataa = {};

  //   try {
  //     const response = await Utility.ExecuteData(action, token, dataa, true);
  //     const { data } = response;
  //     const parsedData = JSON.parse(data);
  //     this.setState({ nationBuilderPersonFields: parsedData });
  //   } catch (error) {
  //     console.error("An error occurred:", error);
  //   }
  // };
  getFormData3 = async () => {
    const lgnDtlValue = localStorage.getItem("lgn_dtl");
    const lgnDtlObject = JSON.parse(lgnDtlValue);
    const clientId = lgnDtlObject.client_id;

    const url = "https://api.pushpolitics.org/v1/getcrmsync";
    fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ clientId }),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.data) {
          const parsedData = JSON.parse(data.data);
          this.setState({ nationBuilderPersonFields: parsedData });
          console.log(this.state.nationBuilderPersonFields);
        }
      })
      .catch((error) => {
        console.error("An error occurred:", error);
      });
  };

  handleEdit = (field) => {
    const newValue = prompt("Enter new value for NationBuilder person field:");

    if (newValue) {
      this.setState({
        nationBuilderPersonFields: {
          ...this.state.nationBuilderPersonFields,
          [field]: newValue,
        },
      });
    }
  };

  componentDidMount() {
    this.getFormData1();
    this.getFormData2();
    this.getFormData3();
  }

  render() {
    const {
      activeTab,
      nationSlug,
      title,
      description,
      oauthClientId,
      oauthClientSecret,
      overrideSMTP,
      senderEmail,
      password,
      host,
      senderName,
      port,

      showSMPTSettings,
    } = this.state;
    // console.log(this.state.nationBuilderPersonFields["ppfirstName"]);

    return (
      <>
        <style>
          {`
            .custom-opacity {
              opacity: 1 !important;
            }
          `}
        </style>
        <Tabs activeKey={activeTab} onSelect={this.handleTabSelect}>
          <Tab
            eventKey="tab1"
            title="Integration Hub"
            className="custom-opacity"
          >
            <APIIntegration />
          </Tab>
          <Tab eventKey="tab2" title="NationBuilder" className="custom-opacity">
            <div style={{ marginTop: "30px" }}>
              <Tabs defaultActiveKey="edit-tab1" className="custom-opacity">
                <Tab
                  eventKey="edit-tab1"
                  title="Edit"
                  className="custom-opacity"
                >
                  <div style={{ marginTop: "30px" }}>
                    <Tabs className="custom-opacity">
                      <Tab
                        eventKey="sub-tab1"
                        title="Basic Configuration"
                        className="custom-opacity"
                      >
                        <div>
                          <form onSubmit={this.handleFormSubmit1}>
                            <div className="row">
                              <div className="col">
                                <h5>NationBuilder Settings</h5>
                                <div className="form-group">
                                  <label>
                                    Nation Slug{" "}
                                    {/* <span className="required-field-data">
                                      *
                                    </span> */}
                                  </label>
                                  <input
                                    type="text"
                                    name="nationSlug"
                                    value={nationSlug}
                                    onChange={this.handleInputChange}
                                    className="form-control"
                                    // required
                                  />
                                </div>
                              </div>
                              <div className="col">
                                <h5>Action Network Settings</h5>
                                <div className="form-group">
                                  <label>
                                    Oauth Client ID{" "}
                                    {/* <span className="required-field-data">
                                      *
                                    </span> */}
                                  </label>
                                  <input
                                    type="text"
                                    name="oauthClientId"
                                    value={oauthClientId}
                                    onChange={this.handleInputChange}
                                    className="form-control"
                                    // required // Add the required attribute here
                                  />
                                </div>
                                <div className="form-group">
                                  <label>
                                    Oauth Client Secret{" "}
                                    {/* <span className="required-field-data">
                                      *
                                    </span> */}
                                  </label>
                                  <input
                                    type="text"
                                    name="oauthClientSecret"
                                    value={oauthClientSecret}
                                    onChange={this.handleInputChange}
                                    className="form-control"
                                    // required
                                  />
                                </div>
                              </div>
                            </div>
                            <div className="row">
                              <div className="col">
                                <h5>Integration Setup</h5>
                                <div className="form-group">
                                  <label>Title</label>
                                  <input
                                    type="text"
                                    name="title"
                                    value={title}
                                    onChange={this.handleInputChange}
                                    className="form-control"
                                  />
                                </div>
                                <div className="form-group">
                                  <label>Description</label>
                                  <textarea
                                    name="description"
                                    value={description}
                                    onChange={this.handleInputChange}
                                    className="form-control"
                                  ></textarea>
                                </div>
                                <div className="form-group">
                                  <button
                                    type="submit"
                                    className="btn btn-primary"
                                  >
                                    Save
                                  </button>
                                </div>
                              </div>
                            </div>
                          </form>
                        </div>
                      </Tab>

                      {showSMPTSettings && (
                        <Tab
                          eventKey="sub-tab2"
                          title="Send Configuration"
                          className="custom-opacity"
                        >
                          <div>
                            <form onSubmit={this.handleFormSubmit2}>
                              <div className="row">
                                <div className="col">
                                  <h5>Override Default SMTP Settings</h5>
                                  <div className="form-check form-check-inline">
                                    <input
                                      type="radio"
                                      name="overrideSMTP"
                                      checked={overrideSMTP === true}
                                      onChange={this.handleRadioChange}
                                      className="form-check-input"
                                      id="overrideSMTPOn"
                                      value="on"
                                    />
                                    <label
                                      className="form-check-label"
                                      htmlFor="overrideSMTPOn"
                                    >
                                      On
                                    </label>
                                  </div>
                                  <div className="form-check form-check-inline">
                                    <input
                                      type="radio"
                                      name="overrideSMTP"
                                      checked={overrideSMTP === false}
                                      onChange={this.handleRadioChange}
                                      className="form-check-input"
                                      id="overrideSMTPOff"
                                      value="off"
                                    />
                                    <label
                                      className="form-check-label"
                                      htmlFor="overrideSMTPOff"
                                    >
                                      Off
                                    </label>
                                  </div>
                                </div>
                              </div>

                              <div className="row">
                                <div className="col">
                                  <h5>Sender Configuration</h5>
                                  <div className="form-group">
                                    <label>Sender Email</label>
                                    <input
                                      type="email"
                                      name="senderEmail"
                                      value={senderEmail}
                                      onChange={this.handleInputChange}
                                      className="form-control"
                                    />
                                  </div>
                                  <div className="form-group">
                                    <label>Password</label>
                                    <input
                                      type="password"
                                      name="password"
                                      value={password}
                                      onChange={this.handleInputChange}
                                      className="form-control"
                                    />
                                  </div>
                                  <div className="form-group">
                                    <label>Host</label>
                                    <input
                                      type="text"
                                      name="host"
                                      value={host}
                                      onChange={this.handleInputChange}
                                      className="form-control"
                                    />
                                  </div>
                                </div>
                                <div className="col">
                                  <h5>Sender Name</h5>
                                  <div className="form-group">
                                    <label>Sender Name</label>
                                    <input
                                      type="text"
                                      name="senderName"
                                      value={senderName}
                                      onChange={this.handleInputChange}
                                      className="form-control"
                                    />
                                  </div>
                                  <div className="form-group">
                                    <label>Port</label>
                                    <input
                                      type="number"
                                      name="port"
                                      value={port}
                                      onChange={this.handleInputChange}
                                      className="form-control"
                                    />
                                  </div>
                                </div>
                              </div>
                              <div className="row">
                                <div className="col">
                                  <button
                                    type="submit"
                                    className="btn btn-primary"
                                  >
                                    Save
                                  </button>
                                </div>
                              </div>
                            </form>
                          </div>
                        </Tab>
                      )}

                      <Tab
                        eventKey="sub-tab3"
                        title="Advanced"
                        className="custom-opacity"
                      >
                        <div className="box">
                          <div className="box-header">
                            <h2>CRM Integrations - Sync Settings</h2>
                          </div>
                          <div className="box-content">
                            <div className="row">
                              <div className="column">
                                <h4>Webform Component</h4>
                                <p></p>

                                <p>
                                  First Name
                                  <select
                                    style={{ marginLeft: "10px" }}
                                    value={
                                      this.state.nationBuilderPersonFields[
                                        "ppfirstName"
                                      ]
                                    }
                                    onChange={(e) =>
                                      this.handleFieldChange("ppfirstName", e)
                                    }
                                  >
                                    <option value="name">name</option>
                                    <option value="nom">nom</option>
                                    <option value="firstname">firstname</option>
                                    <option value="prenom">prenom</option>
                                  </select>
                                </p>

                                <p>
                                  Last Name
                                  <select
                                    style={{ marginLeft: "10px" }}
                                    value={
                                      this.state.nationBuilderPersonFields[
                                        "pplastName"
                                      ]
                                    }
                                    onChange={(e) =>
                                      this.handleFieldChange("pplastName", e)
                                    }
                                  >
                                    <option value="name">name</option>
                                    <option value="nom">nom</option>
                                    <option value="lastname">lastname</option>
                                    <option value="nomdefamille">
                                      nomdefamille
                                    </option>
                                  </select>
                                </p>

                                <p>Email Address</p>

                                <p>
                                  Address =&#123;street_number&#125;
                                  &#123;route&#125;
                                </p>

                                <p>City = &#123;locality&#125;</p>
                                <p>
                                  Province/State =
                                  &#123;administrative_area_level_1&#125;
                                </p>
                                <p>Country = &#123;country&#125;</p>
                                <p>
                                  Postal Code / Zip = &#123;postal_code&#125;
                                </p>
                              </div>

                              <div className="column">
                                <h4>
                                  NationBuilder Person Field(
                                  <a href="https://nationbuilder.com/people_api">
                                    NationBuilder API People Reference
                                  </a>
                                  )
                                </h4>
                                <p>
                                  {this.state.nationBuilderPersonFields &&
                                  this.state.nationBuilderPersonFields
                                    .first_name
                                    ? this.state.nationBuilderPersonFields
                                        .first_name
                                    : ""}
                                </p>
                                <p>
                                  {this.state.nationBuilderPersonFields &&
                                  this.state.nationBuilderPersonFields.last_name
                                    ? this.state.nationBuilderPersonFields
                                        .last_name
                                    : ""}
                                </p>
                                <p>
                                  {this.state.nationBuilderPersonFields &&
                                  this.state.nationBuilderPersonFields.email
                                    ? this.state.nationBuilderPersonFields.email
                                    : ""}
                                </p>
                                <p>
                                  {this.state.nationBuilderPersonFields &&
                                  this.state.nationBuilderPersonFields.address
                                    ? this.state.nationBuilderPersonFields
                                        .address
                                    : ""}
                                </p>
                                <p>
                                  {this.state.nationBuilderPersonFields &&
                                  this.state.nationBuilderPersonFields.city
                                    ? this.state.nationBuilderPersonFields.city
                                    : ""}
                                </p>
                                <p>
                                  {this.state.nationBuilderPersonFields &&
                                  this.state.nationBuilderPersonFields.state
                                    ? this.state.nationBuilderPersonFields.state
                                    : ""}
                                </p>
                                <p>
                                  {this.state.nationBuilderPersonFields &&
                                  this.state.nationBuilderPersonFields.country
                                    ? this.state.nationBuilderPersonFields
                                        .country
                                    : ""}
                                </p>
                                <p>
                                  {this.state.nationBuilderPersonFields &&
                                  this.state.nationBuilderPersonFields
                                    .postal_code
                                    ? this.state.nationBuilderPersonFields
                                        .postal_code
                                    : ""}
                                </p>
                              </div>
                              <div className="column">
                                <h4>Operations</h4>
                                <p>
                                  <button
                                    className="edit"
                                    onClick={() =>
                                      this.handleEdit("first_name")
                                    }
                                  >
                                    Edit
                                  </button>
                                </p>
                                <p>
                                  <button
                                    className="edit"
                                    onClick={() => this.handleEdit("last_name")}
                                  >
                                    Edit
                                  </button>
                                </p>
                                <p>
                                  <button
                                    className="edit"
                                    onClick={() => this.handleEdit("email")}
                                  >
                                    Edit
                                  </button>
                                </p>
                                <p>
                                  <button
                                    className="edit"
                                    onClick={() => this.handleEdit("address")}
                                  >
                                    Edit
                                  </button>
                                </p>
                                <p>
                                  <button
                                    className="edit"
                                    onClick={() => this.handleEdit("city")}
                                  >
                                    Edit
                                  </button>
                                </p>
                                <p>
                                  <button
                                    className="edit"
                                    onClick={() => this.handleEdit("state")}
                                  >
                                    Edit
                                  </button>
                                </p>
                                <p>
                                  <button
                                    className="edit"
                                    onClick={() => this.handleEdit("country")}
                                  >
                                    Edit
                                  </button>
                                </p>
                                <p>
                                  <button
                                    className="edit"
                                    onClick={() =>
                                      this.handleEdit("postal_code")
                                    }
                                  >
                                    Edit
                                  </button>
                                </p>
                              </div>
                            </div>
                          </div>
                          <div className="box-footer">
                            <button
                              className="save-button"
                              onClick={this.handleFormSubmit3}
                            >
                              Save
                            </button>
                          </div>
                        </div>
                      </Tab>
                    </Tabs>
                  </div>
                </Tab>
              </Tabs>
            </div>
          </Tab>
        </Tabs>
      </>
    );
  }
}

export default ClientAPITabs;
