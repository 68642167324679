import React from "react";
import ReactDOM from "react-dom";
import paypal from "paypal-checkout";
const PayPalButton = paypal.Button.driver("react", { React, ReactDOM });
class PaypalExpress extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showButton: true
    };
  }

  render() {   
    const {    
      env,
      commit,
      client,
      onSuccess,
      payment,
      onAuthorize    
         } = this.props;
   
    const { showButton } = this.state;   

    return (
      <div>
        <PayPalButton
          env={env}
          client={client}
          payment={payment}
          commit={commit}
          onAuthorize={onAuthorize}
          payment={payment}
        
        />
      </div>
    );
  }
}

export default PaypalExpress;
