import $ from "jquery";
import { __t } from "../../locale/language";
import React from "react";
class ContactDeleteAlert extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
        this.close = this.close.bind(this);
        this.yes = this.yes.bind(this);
    }
    close() {
        $("#contact_delete").hide();
    }
    yes() {
        console.log("dvbdjh")
        this.props.contact_delete()
    }
    progressBar() {
        var element = document.getElementById("myprogressBar");
        var width = 1;
        var identity = setInterval(scene, 10);
        function scene() {
            if (width >= 100) {
                clearInterval(identity);
            } else {
                width++;
                element.style.width = width + "%";
                element.innerHTML = width * 1 + "%";
            }
        }
    }
    render() {
        return (
            <div className="alert_oerlay" id="contact_delete" style={{ display: "none" }}>
                <div className="alert_box info_back">
                    <i className="fa fa-info info_txt fa_alert" />
                    <h3 id="msg_text2" />
                    <div>
                    <button className="btn btn-success info_btn" onClick={this.yes}>
                        {__t("yes")}
                    </button>   &nbsp;&nbsp;
                    <button className="btn btn-success info_btn" onClick={this.close}>
                        {__t("no")}
                    </button>
                    </div>
                  
                </div>
            </div>
        );
    }
}
export default ContactDeleteAlert;
