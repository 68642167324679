import React, { Component } from "react";
import Utility from "../common/Utility";
import { __t } from "../../locale/language";
import Moment from "moment";
import $ from "jquery";
import ExpireAlert from "../common/ExpireAlert";

var subscription_div = "";

class ClientDB extends Component {
  constructor(props) {
    super(props);
    this.state = {
      client_id: localStorage.getItem("client_id"),
      token: localStorage.getItem("token"),
      user_type: Utility.getIdFromToken(localStorage.getItem("token")),
      user_count: "",
      campaign_count: "",
      widgets_count: "",
      days: "",
      title: "",
      status: "",
      camp_list: "",
      camp_count: "",
      client_letters_records: null,
    };
    this.getUserData = this.getUserData.bind(this);
    this.getCampaignData = this.getCampaignData.bind(this);
    this.getWidgetsData = this.getWidgetsData.bind(this);
    this.getPlanStatus = this.getPlanStatus.bind(this);
    this.getCampaignList = this.getCampaignList.bind(this);
  }
  getUserData() {
    ////console.log(this.state.user_type);
    Utility.ExecuteData("UserCount", this.state.token, {
      token: this.state.token,
      user_type: this.state.user_type,
      type: "user",
      client_id: this.state.client_id,
    }).then((data) => {
      // //console.log(data.data[0]);

      this.setState(() => ({
        user_count: data.data[0],
      }));
    });
  }
  updatesubscriptionexpired = () => {
    Utility.ExecuteData("updatesubscriptionexpired", this.state.token, {
      token: this.state.token,
      client_id: this.state.client_id,
    }).then((data) => {
      //console.log(data)
      if (data.code == 200) {
        $("#sub_alert1").hide();
      }
    });
  };
  getPlanStatus() {
    Utility.ExecuteData("getPlanStatus", this.state.token, {
      token: this.state.token,
      client_id: this.state.client_id,
    }).then((data) => {
      //console.log(data.data);
      if (data.data.length) {
        localStorage.setItem("subs_id", data.data[0].subscription_id);
        var now = Moment(new Date()); //todays date
        var end = Moment(data.data[0].end_date); // another date
        var duration = Moment.duration(end.diff(now));
        var days = parseInt(duration.asDays());
        console.log("duration days===", duration);
        console.log("days===", days);
        // if (days <= 0 ) {
        //   //console.log("sdbh")
        //   this.updatesubscriptionexpired()
        // }
        this.setState({
          days: days < 0 || days == 0 ? "0" : days,
          title: data.data[0].title,
          status: data.data[0].status,
        });
      }
    });
  }
  getCampaignData() {
    // //console.log(this.state.user_type);
    Utility.ExecuteData("CampaignCount", this.state.token, {
      token: this.state.token,
      user_type: this.state.user_type,
      type: "campaign",
      client_id: this.state.client_id,
    }).then((data) => {
      // //console.log(data.data[0]);

      this.setState(() => ({
        campaign_count: data.data[0],
      }));
    });
  }
  getWidgetsData() {
    //  //console.log(this.state.user_type);
    Utility.ExecuteData("WidgetsCount", this.state.token, {
      token: this.state.token,
      user_type: this.state.user_type,
      type: "widgets",
      client_id: this.state.client_id,
    }).then((data) => {
      ////console.log(data.data[0]);

      this.setState(() => ({
        widgets_count: data.data[0],
      }));
    });
  }
  componentDidMount() {
    this.getUserData();
    this.getCampaignData();
    this.getWidgetsData();
    // this.getPlanStatus();
    this.getCampaignList();
    this.fetchMonthlyLetterCounts();
  }
  getCampaignList() {
    Utility.ExecuteData("getCampaignList", this.state.token, {
      token: this.state.token,
      user_type: this.state.user_type,
      type: "user",
    }).then((data) => {
      // //console.log(data);
      this.setState({
        camp_list: data.data,
        camp_count: data.count[0].total,
      });
    });
  }

  fetchMonthlyLetterCounts = async () => {
    const action = "getclientlettersrecord";
    const token = localStorage.getItem("token");
    const payload = { token };

    const response = await Utility.ExecuteData(action, token, payload, false);
    this.setState({ client_letters_records: response });
  };

  renderMonthlyLetterCounts() {
    try {
      const { client_letters_records } = this.state;
      if (client_letters_records) {
        let ary = client_letters_records.data;
        console.log(ary, "aryary");

        // if (!client_letters_records || client_letters_records.length === 0) {
        //   return (
        //     <tr>
        //       <td colSpan="3">No records found</td>
        //     </tr>
        //   );
        // }

        // const monthlyLetterCounts = {};

        // client_letters_records.forEach((val) => {
        //   const publishDate = Moment(val.created_at);
        //   const year = publishDate.format("YYYY");
        //   const month = publishDate.format("MMMM");
        //   const yearMonth = year + " " + month;

        //   if (!monthlyLetterCounts[yearMonth]) {
        //     monthlyLetterCounts[yearMonth] = {
        //       year: year,
        //       month: month,
        //       count: 0,
        //     };
        //   }

        //   monthlyLetterCounts[yearMonth].count += 1;
        // });

        // const sortedMonthlyLetterCounts = Object.values(monthlyLetterCounts).sort(
        //   (a, b) =>
        //     Moment(b.year + " " + b.month, "YYYY MMMM") -
        //     Moment(a.year + " " + a.month, "YYYY MMMM")
        // );

        if (ary.length > 0) {
          return ary.map((val, index) => (
            <tr key={index}>
              <td>{val.date.split(" ")[1]}</td>
              <td>{val.date.split(" ")[0]}</td>
              <td>{val.count}</td>
            </tr>
          ));
        }
      } else {
        return (
          <tr>
            <td colSpan="3">No records found</td>
          </tr>
        );
      }
    } catch (error) {
      console.error("Error fetching records:", error);
      return (
        <tr>
          <td colSpan="3">Error fetching records</td>
        </tr>
      );
    }
  }

  render() {
    var user;
    var campaign;
    var widgets;
    if (
      typeof this.state.campaign_count != "undefined" &&
      typeof this.state.widgets_count != "undefined"
    ) {
      user = this.state.user_count;
      campaign = this.state.campaign_count;
      widgets = this.state.widgets_count;
    }
    //console.log(this.state.days);

    subscription_div = (
      <div className={"alert alert-info"} id="sub_alert1">
        {__t("current_plan_is")}{" "}
        <strong>
          {this.state.title} {__t("plan")}{" "}
        </strong>
        {__t("and_will_expire_after")} {""}
        <strong>
          {this.state.days != "NaN" || this.state.days < 0
            ? this.state.days
            : "00"}{" "}
          {__t("days")}
        </strong>{" "}
        .
      </div>
    );
    return (
      <div style={{ marginTop: "-40px" }}>
        {/* {this.state.status && this.state.status == "active"
          ? subscription_div
          : ""} */}
        <ExpireAlert />

        <div className="row">
          <div className="col-lg-3">
            <div className="ibox ">
              <div className="ibox-title">
                <h5>{__t("users")}</h5>
              </div>
              <div className="ibox-content">
                <h1 className="no-margins">
                  {user.Total}/{user.Total == 0 ? "0" : user.Active}/
                  {user.Total == 0 ? "0" : user.Inactive}
                </h1>
                <div className="stat-percent font-bold text-success" />
                <small>
                  {__t("total")}/{__t("active")}/{__t("inactive")}
                </small>
              </div>
            </div>
          </div>
          <div className="col-lg-3">
            <div className="ibox ">
              <div className="ibox-title">
                <h5>{__t("campaign")}</h5>
              </div>
              <div className="ibox-content">
                <h1 className="no-margins">
                  {campaign.Total}/{campaign.Total == 0 ? "0" : campaign.Active}
                  /{campaign.Total == 0 ? "0" : campaign.Inactive}
                </h1>
                <div className="stat-percent font-bold text-success" />
                <small>
                  {__t("total")}/{__t("active")}/{__t("inactive")}
                </small>
              </div>
            </div>
          </div>
          <div className="col-lg-3">
            <div className="ibox ">
              <div className="ibox-title">
                <h5>{__t("widgets")}</h5>
              </div>
              <div className="ibox-content">
                <h1 className="no-margins">
                  {widgets.Total}/{widgets.Total == 0 ? "0" : widgets.Active}/
                  {widgets.Total == 0 ? "0" : widgets.Inactive}
                </h1>
                <div className="stat-percent font-bold text-success" />
                <small>
                  {__t("total")}/{__t("active")}/{__t("inactive")}
                </small>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-6">
            <div className="ibox float-e-margins">
              <div className="ibox-title">
                <h5>{__t("campaign")}</h5>
                <h5 style={{ float: "right" }}>
                  {__t("campaign_count")}:{this.state.camp_count}
                </h5>
              </div>
              <div className="ibox-content  p0">
                <table className="table table-hover no-margins">
                  <thead>
                    <tr>
                      <th className="border_top0">{__t("campaign")}</th>
                      <th className="border_top0">{__t("start_from")}</th>
                      <th className="border_top0">{__t("letter_sent")}</th>
                    </tr>
                  </thead>
                  <tbody>
                    {this.state.camp_list.length > 0 ? (
                      this.state.camp_list.map((val, k) => {
                        ////console.log(val);
                        return (
                          <tr key={k}>
                            <td>{val.title} </td>

                            <td>
                              <small>
                                {Moment(val.publish_on).format(
                                  "DD-MMM-YYYY HH:mm:ss"
                                )}
                              </small>
                            </td>
                            <td>{val.letter_sent}</td>
                            <td className="text-navy"> </td>
                          </tr>
                        );
                      })
                    ) : (
                      <tr>
                        <td />
                        <td>{__t("no_record_found")}</td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-lg-6">
            <div className="ibox float-e-margins">
              <div className="ibox-content p0">
                <table className="table table-hover no-margins">
                  <thead>
                    <tr>
                      <th className="border_top0">Year</th>
                      <th className="border_top0">Month</th>
                      <th className="border_top0">Letter Sent</th>
                    </tr>
                  </thead>
                  <tbody>{this.renderMonthlyLetterCounts()}</tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default ClientDB;
