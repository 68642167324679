import React from "react";
import { Redirect } from "react-router-dom";
import { Tabs, Tab } from "react-bootstrap";
import Utility from "../common/Utility";
import Tab1 from "./Tab1";
import Tab2 from "./Tab2";
import Tab3ForMyClient from "./Tab3";
import Tab4ForAdminReseller from "./Tab4";
import TwoFactorAuthentication from "./Tab5";
import Can from "../common/AccessControl";
import bcrypt from "bcryptjs";
import $ from "jquery";
import { __t } from "../../locale/language";
import APIIntegration from "./Tab6";
import ClientAPITabs from "./ClientAPITabs";
import APIConnections from "./APIConnections";

class Settings extends React.Component {
  constructor(props) {
    super(props);
    // console.log(props)
    this.state = {
      redirect: false,
      connected: false,
      errors: {},
      name: "",
      subdomain: "",
      title: "",
      status: "",
      sender_email: "",
      sender_name: "",
      username: "",
      password: "",
      port: "",
      host: "",
      first_name: "",
      last_name: "",
      user_contact: "",
      user_password: "",
      confirm_password: "",
      user_email: "",
      address: "",
      user_type: this.props.userPermissions.user_type,
      user_status: "",
      token: localStorage.getItem("token"),
      key: 1,
      subscription_plan: "",
      plan: 0,
      id: localStorage.getItem("client_id"),
      client_id: localStorage.getItem("client_id"),
      feature_title: [],
      subscription_feature: this.props.userPermissions.subscription_feature,
      verified_token: "",
      telephone_number: "",
      fax_number: "",
      tax_label: "",
      tax_number: "",
      tax_rate: "",
      tax_data: {
        telephone_number: "",
        fax_number: "",
        tax_label: "",
        tax_number: "",
        tax_rate: "",
      },
      parentDomain: "",
    };
    this.handleSelect = this.handleSelect.bind(this);
    this.handleChange = this.handleChange.bind(this);
    //  this.nextTab = this.nextTab.bind(this);
    this.updateClientInfo = this.updateClientInfo.bind(this);
  }

  // getConnections = async () => {
  //   let response = await Utility.GetData("get-connections", {});
  //   const connectionList = response.data;
  //   if (connectionList[0].is_connected) {
  //     this.setState({ connected: true });
  //   } else {
  //     this.setState({ connected: false });
  //   }
  // };

  // getConnections = async () => {
  //   const action = "get-connections";
  //   const token = localStorage.getItem("token");

  //   try {
  //     const response = await Utility.ExecuteData(action, token, {}, false);
  //     const connectionList = response.data;
  //     if (connectionList[0].is_connected) {
  //       this.setState({ connected: true });
  //     } else {
  //       this.setState({ connected: false });
  //     }
  //   } catch (error) {
  //     console.error("An error occurred:", error);
  //   }
  // };
  getConnections = async () => {
    const action = "get-connections";
    const token = localStorage.getItem("token");

    try {
      const response = await Utility.ExecuteData(action, token, {}, false);
      const connectionList = response.data;

      if (
        connectionList &&
        connectionList[0] &&
        connectionList[0].is_connected !== undefined
      ) {
        if (connectionList[0].is_connected) {
          this.setState({ connected: true });
        } else {
          this.setState({ connected: false });
        }
      } else {
        this.setState({ connected: false });
        // Handle the case where connectionList is null, undefined, or doesn't have the expected structure.
        // You can set a default value or perform any other appropriate actions here.
      }
    } catch (error) {
      console.error("An error occurred:", error);
    }
  };

  componentDidMount() {
    this.getConnections();
    this.getSubsData();
    const urlParams = new URLSearchParams(window.location.search);
    const code = urlParams.get("code");

    if (code) {
      this.setState({ key: 2 }); // Set Tab 2 as the active tab
    }
  }
  getSubsData() {
    var id = localStorage.getItem("client_id");
    Utility.ExecuteData("subscriptionPlanforClient", this.state.token, {
      id: id,
    }).then((result) => {
      // console.log(result);
      if (result.code == 200) {
        var title = [];
        var res = result.data;
        res.map((i) => {
          title.push(i.title);
        });
        //console.log(result);
        if (result.data.length > 0) {
          //console.log(result.data[0].password);
          let tax_data = result.data[0].tax_data
            ? JSON.parse(result.data[0].tax_data)
            : "";
          //console.log(tax_data)
          let parentDomain = "";
          let domain = result.data[0].subdomain.split(".");
          if (result.data[0].parentdomain) {
            // alert(result.data[0].parentdomain);
            parentDomain = result.data[0].parentdomain.split(".");
            parentDomain = parentDomain[1] + "." + parentDomain[2];
            this.setState({ parentDomain });
          }
          this.setState({
            name: result.data[0].name,
            subdomain: domain[0],
            title: result.data[0].type,
            status: result.data[0].status,
            address: result.data[0].address,
            subscription_plan: result.data[0].title,
            feature_title: title,
            sender_email: result.data[0].sender_email,
            sender_name: result.data[0].sender_name,
            sender_username: result.data[0].username,
            host: result.data[0].host,
            port: result.data[0].port,
            username: result.data[0].username,
            telephone_number: tax_data.telephone_number,
            fax_number: tax_data.fax_number,
            tax_label: tax_data.tax_label,
            tax_number: tax_data.tax_number,
            tax_rate: tax_data.tax_rate,
            hst_number: result.data[0].hst_number,
          });
        }
      }
    });
  }

  handleChange(event) {
    this.setState({ [event.target.name]: event.target.value });
  }
  checkValidation(key) {
    let fields = this.state;
    let errors = {};
    let formIsValid = true;
    if (fields.user_type != "client") {
      // if (!fields["telephone_number"] && false) {
      //   formIsValid = false;
      //   errors["telephone_number"] = __t("telephone_number_cannot_be_empty");
      // }
      // if (!fields["fax_number"] && false) {
      //   formIsValid = false;
      //   errors["fax_number"] = __t("fax_number_cannot_be_empty");
      // }
      if (!fields["tax_number"]) {
        formIsValid = false;
        errors["tax_number"] = __t("tax_number_cannot_be_empty");
      }
      if (!fields["tax_label"]) {
        formIsValid = false;
        errors["tax_label"] = __t("tax_label_cannot_be_empty");
      }
      if (!fields["tax_rate"]) {
        formIsValid = false;
        errors["tax_rate"] = __t("tax_rate_cannot_be_empty");
      }
    }

    if (!fields["status"]) {
      formIsValid = false;
      errors["status"] = __t("select_status");
    }

    // if (!fields["password"]) {
    //   formIsValid = false;
    //   errors["password"] = "Password Cannot be empty";
    // }

    if (!fields["sender_name"]) {
      formIsValid = false;
      errors["sender_name"] = __t("sender_name_cannot_be_empty");
    }
    // if (!fields["username"]) {
    //   formIsValid = false;
    //   errors["username"] = __t("chk_empty_uname");
    // }
    if (!fields["host"]) {
      formIsValid = false;
      errors["host"] = __t("host_cannot_be_empty");
    }
    if (!fields["port"]) {
      formIsValid = false;
      errors["port"] = __t("port_cannot_be_empty");
    }
    if (!fields["sender_email"]) {
      formIsValid = false;
      errors["sender_email"] = __t("chk_empty_email");
    } else if (typeof fields["sender_email"] !== "undefined") {
      let lastAtPos = fields["sender_email"].lastIndexOf("@");
      let lastDotPos = fields["sender_email"].lastIndexOf(".");
      if (
        !(
          lastAtPos < lastDotPos &&
          lastAtPos > 0 &&
          fields["sender_email"].indexOf("@@") == -1 &&
          lastDotPos > 2 &&
          fields["sender_email"].length - lastDotPos > 2
        )
      ) {
        formIsValid = false;
        errors["sender_email"] = __t("chk_valid_email");
      }
    }

    this.setState({
      errors: errors,
    });
    return formIsValid;
  }
  createHash(pwd) {
    const saltRounds = 10;
    return new Promise((resolve, reject) => {
      bcrypt.genSalt(saltRounds, function (err, salt) {
        if (err) return err;
        bcrypt.hash(pwd, salt, function (err, hash) {
          // Store hash in your password DB.
          if (err) {
            reject(err);
          } else {
            resolve(hash);
          }
        });
      });
    });
  }
  validation = () => {
    let fields = {
      ...this.state,
      subdomain: this.state.subdomain + "." + this.state.parentDomain,
    };

    // alert(JSON.stringify(fields))

    let errors = {};
    let formIsValid = true;
    if (!fields["name"]) {
      formIsValid = false;
      errors["name"] = __t("name_cannot_be_empty");
    }

    if (!fields["title"]) {
      formIsValid = false;
      errors["title"] = __t("title_cannot_be_empty");
    }
    if (!fields["address"]) {
      formIsValid = false;
      errors["address"] = __t("address_cannot_be_empty");
    }

    if (fields.user_type == "admin") {
      if (!fields["hst_number"]) {
        formIsValid = false;
        errors["hst_number"] = __t("hst_number_cannot_be_empty");
      }
    }

    if (fields.user_type != "client") {
      // if (!fields["telephone_number"]) {
      //   formIsValid = false;
      //   errors["telephone_number"] = __t("telephone_number_cannot_be_empty");
      // }
      // if (!fields["fax_number"]) {
      //   formIsValid = false;
      //   errors["fax_number"] = __t("fax_number_cannot_be_empty");
      // }
      if (!fields["tax_number"]) {
        formIsValid = false;
        errors["tax_number"] = __t("tax_number_cannot_be_empty");
      }
      if (!fields["tax_label"]) {
        formIsValid = false;
        errors["tax_label"] = __t("tax_label_cannot_be_empty");
      }
      if (!fields["tax_rate"]) {
        formIsValid = false;
        errors["tax_rate"] = __t("tax_rate_cannot_be_empty");
      }
    }

    this.setState({
      errors: errors,
    });

    // modified by adil on 25/02/20, only for client without use of SMTP
    if (formIsValid) {
      if (fields.user_type === "client") {
        const {
          name,
          subdomain,
          title,
          status,
          address,
          subscription_plan,
          id,
          client_id,
          token,
          sender_email,
        } = fields;

        let newObjToSend = {};
        newObjToSend["name"] = name;
        newObjToSend["subdomain"] = subdomain;
        newObjToSend["title"] = title;
        newObjToSend["status"] = status;
        newObjToSend["address"] = address;
        newObjToSend["subscription_plan"] = subscription_plan;

        newObjToSend["id"] = id;
        newObjToSend["client_id"] = client_id;
        newObjToSend["sender_email"] = sender_email;

        // console.log(newObjToSend);
        Utility.ExecuteData("updatesubscriptionforclient", token, newObjToSend)
          .then((responseJson) => {
            // console.log(responseJson);
            if (responseJson.code === 200) {
              if (responseJson.data == 0) {
                alert(__t(responseJson.message));
              } else {
                this.setState(
                  {
                    redirect: true,
                    verified_token: responseJson.verified_token,
                  },
                  () => {
                    // console.log(this.state.verified_token);
                    if (this.state.verified_token == "1") {
                      $("#verified_token").hide();
                      $("#verified_token_tk").hide();
                      $("#verified_token_tk").css("display", "none");
                      $("#verified_token").css("display", "none");
                      $("#msg_text").text(__t("successfully_updated"));
                      $("#alertinfo").show();
                    } else if (this.state.verified_token == "2") {
                      $("#verified_token_tk").show();
                      $("#verified_token_tk").css("display", "block");
                      $("#msg_text").text(
                        __t("successfully_updated_invalid_credentials")
                      );
                      $("#alertinfo").show();
                    } else if (this.state.verified_token == "0") {
                      $("#verified_token").show();
                      $("#verified_token").css("display", "block");
                      $("#msg_text").text(
                        __t("successfully_updated_invalid_credentials")
                      );
                      $("#alertinfo").show();
                    }
                  }
                );
              }
            } else {
              alert(__t("try_again"));
            }
          })
          .catch((error) => {
            this.setState({ errors: true });
          });

        return false;
      }
    }

    return formIsValid;
  };
  valid = () => {
    if (this.validation()) {
      this.handleSelect(2);
    }
  };
  handleSelect(key) {
    console.log(key, "hgfhgfhgfhfs");
    if (key == 2) {
      this.validation();
    }
    this.setState({ key: key });
  }
  // nextTab(key) {
  //   if (key == 2) {
  //     if (this.checkValidation(1)) {
  //       this.setState({ key: key });
  //     }
  //   }
  // }
  updateSetting = (e) => {
    this.setState({
      tax_data: Object.assign(this.state.tax_data, {
        [e.target.name]: e.target.value,
      }),
    });
  };
  checkSmtp = () => {
    Utility.ExecuteData("test-smtp", this.state.token, this.state)
      .then((res) => {
        if (res.code == 200) {
          if (res.cli_data == "true") {
            $("#verified_token").hide();
            $("#verified_token_tk").hide();
            $("#verified_token_tk").css("display", "none");
            $("#verified_token").css("display", "none");
            $("#msg_text").text(__t("test_mail_sent"));
            $("#alertinfo").show();
          } else {
            console.log(res.err);
            var err_msg = "";
            Object.entries(res.err).forEach(
              ([key, value]) =>
                (err_msg =
                  err_msg + `<p style="margin-bottom:0px">${key}: ${value}</p>`)
            );
            $("#verified_token").hide();
            $("#verified_token_tk").hide();
            $("#verified_token_tk").css("display", "none");
            $("#verified_token").css("display", "none");
            $("#err-detail-block").css("display", "block");
            $(".info_back").css("height", "265px");
            $("#err-detail").html(err_msg);
            $("#msg_text").text(__t("test_mail_err"));
            $("#alertinfo").show();
          }
        } else if (res.code == 400) {
          alert(res.message);
        }
      })
      .catch((e) => {
        console.log(e);
      });
  };
  updateClientInfo() {
    if (this.checkValidation(this.state.key)) {
      let tax_data = this.state.tax_data;
      tax_data.telephone_number = this.state.telephone_number;
      tax_data.fax_number = this.state.fax_number;
      tax_data.tax_label = this.state.tax_label;
      tax_data.tax_number = this.state.tax_number;
      tax_data.tax_rate = this.state.tax_rate;
      this.setState(
        {
          tax_data: tax_data,
        },
        () => {
          // var data = this.state;
          var data = {
            ...this.state,
            subdomain: this.state.subdomain + "." + this.state.parentDomain,
          };
          let host = this.props.userPermissions.subdomain;

          if (host == "") {
            host = window.location.hostname;
          }
          // console.log(data);

          // let d = host.split(".")
          // var domain = "."+d[1]+"."+d[2];
          // let subdmn = this.state.subdomain.split(".")
          // Object.assign(data,{subdomain : subdmn[0]+""+domain});

          Utility.ExecuteData(
            "updatesubscriptionforclient",
            this.state.token,
            data
          )
            .then((responseJson) => {
              //console.log(responseJson);
              if (responseJson.code === 200) {
                if (responseJson.data == 0) {
                  alert(__t(responseJson.message));
                } else {
                  this.setState(
                    {
                      redirect: true,
                      verified_token: responseJson.verified_token,
                    },
                    () => {
                      //console.log(this.state.verified_token);
                      if (this.state.verified_token == "1") {
                        $("#verified_token").hide();
                        $("#verified_token_tk").hide();
                        $("#verified_token_tk").css("display", "none");
                        $("#verified_token").css("display", "none");
                        $("#msg_text").text(__t("successfully_updated"));
                        $("#alertinfo").show();
                      } else if (this.state.verified_token == "2") {
                        $("#verified_token_tk").show();
                        $("#verified_token_tk").css("display", "block");
                        $("#msg_text").text(
                          __t("successfully_updated_invalid_credentials")
                        );
                        $("#alertinfo").show();
                      } else if (this.state.verified_token == "0") {
                        $("#verified_token").show();
                        $("#verified_token").css("display", "block");
                        $("#msg_text").text(
                          __t("successfully_updated_invalid_credentials")
                        );
                        $("#alertinfo").show();
                      }
                    }
                  );
                }
              } else {
                alert(__t("try_again"));
              }
            })
            .catch((error) => {
              this.setState({ errors: true });
            });
        }
      );
      // return false;
    }
  }

  render() {
    // console.log({...this.state, subdomain: this.state.subdomain + '.'+ this.state.parentDomain})
    // console.log(this.state.subdomain + '.'+ this.state.parentDomain)
    return (
      <Can
        I={["view-settings"]}
        userPermissions={this.props.userPermissions.userPermissions}
      >
        <div className="row">
          <div className="col-md-12">
            <div className="ibox float-e-margins">
              <div className="ibox-title">
                <h5>{__t("settings")}</h5>
              </div>
              <div className="ibox-content">
                <div className="form-horizontal">
                  <Tabs
                    activeKey={this.state.key}
                    onSelect={this.handleSelect}
                    animation={false}
                    id="noanim-tab-example"
                  >
                    <Tab eventKey={1} title={__t("account_detail")}>
                      <Tab1
                        user_type={this.state.user_type}
                        tab1_data={this.state}
                        handleChange={this.handleChange}
                        subscription_feature={this.state.subscription_feature}
                        handleSelect={this.handleSelect}
                        validation={this.valid}
                        updateSetting={this.updateSetting}
                        subDomain={this.props.userPermissions.subdomain}
                        parentDomain={this.state.parentDomain}
                      />
                    </Tab>

                    {this.state.user_type !== "client" && (
                      <Tab eventKey={2} title={__t("smtp_detail")}>
                        <Tab2
                          capitalize={this.capitalize}
                          checkSmtp={this.checkSmtp}
                          updateClientInfo={this.updateClientInfo}
                          tab2_data={this.state}
                          handleChange={this.handleChange}
                          handleValidation={this.handleValidation}
                        />
                      </Tab>
                    )}

                    {this.state.user_type !== "client" && (
                      <Tab eventKey={3} title={__t("client_smtp_detail")}>
                        {/* dated 16-04-2020 */}
                        <Tab3ForMyClient />
                      </Tab>
                    )}

                    {this.state.user_type !== "client" && (
                      <Tab eventKey={4} title={__t("tracking_analytics")}>
                        {/* dated 10-12-2020 */}
                        <Tab4ForAdminReseller />
                      </Tab>
                    )}

                    {this.state.user_type !== "client" && (
                      <Tab
                        eventKey={5}
                        title={__t("Two Factor Authentication")}
                      >
                        <TwoFactorAuthentication />
                      </Tab>
                    )}

                    {this.state.user_type == "admin" && (
                      <Tab eventKey={6} title={__t("API Integration")}>
                        <APIConnections />
                      </Tab>
                    )}
                    {this.state.user_type == "client" &&
                      this.state.connected && (
                        <Tab
                          eventKey={7}
                          title={__t("API Integrations")}
                          style={{ marginTop: "30px" }}
                        >
                          <ClientAPITabs />
                        </Tab>
                      )}
                  </Tabs>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Can>
    );
  }
}

export default Settings;
