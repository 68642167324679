import $ from 'jquery';
import { __t } from "../../locale/language";
import React from 'react';
class Alertdelete extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            message: "",
            msg_val: '',
            val: ''
        }
        this.msg_no = this.msg_no.bind(this);
        this.msg_yes = this.msg_yes.bind(this);
    }
    msg_no() {
        $('#alertdelete').fadeOut('ease');
    }
    msg_yes(val) {
        var val = val
        this.setState({
            val: val
        })
        this.props.selected_delete()
        $("#alertdelete").css("display", "none");
        return val;

    }
    

    render() {
      
        return (

            <div className="alert_oerlay" id="alertdelete" style={{ "display": "none" }}  >

                <div className="alert_box info_back" >
                    <i className="fa fa-question-circle info_txt fa_alert"></i>
                    <h3 id="alert_prompt_msg"> {this.props.dlt_msg} </h3>
                    <div className="flx_space_between wd70">
                        <button className="btn btn-success info_btn" onClick={() => {
                            this.msg_yes("yes")
                        }}>{__t("Yes")}</button>
                        <button className="btn btn-success info_btn" onClick={this.msg_no}>{__t("No")}</button>
                    </div>
                </div>
            </div>

        )
    }
}
export default Alertdelete;