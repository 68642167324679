import React, { Component } from "react";
import Admin from "./AdminDB";
import Reseller from "./ResellerDB";
import Client from "./ClientDB";
class Index extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <div className="wrapper wrapper-content">
        <div className="row">
          <div className="col-lg-12">
            <div className="m-t-lg">
              {this.props.userPermissions.user_type == "admin" ? (
                <Admin />
              ) :
                this.props.userPermissions.user_type == "reseller" ?
                  <Reseller />
                  :
                  (
                    <Client />
                  )}
              <div />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Index;
