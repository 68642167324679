import $ from "jquery";
import { __t } from "../../locale/language";
import React from "react";
class Spinner extends React.Component {
  render() {
    return (
      <div className="sk-spinner sk-spinner-double-bounce">
        <div className="sk-double-bounce1" />
        <div className="sk-double-bounce2" />
      </div>
    );
  }
}
export default Spinner;
