import React from "react";
import { Link } from "react-router-dom";
import RemotePagination from "../common/Table";
import Utility from "../common/Utility";
import Spinner from "../common/Spinner";
import { __t } from "../../locale/language";
import Can from "../common/AccessControl";
import Moment from "moment";
import $ from "jquery";
class EmailList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      page: 1,
      data: [],
      sizePerPage: 10,
      total: 0,
      search: "",
      client_id: localStorage.getItem("client_id")
    };

    this.getscheduleList = this.getscheduleList.bind(this);
    this.handleChange = this.handleChange.bind(this);
  }
  deleteUser = props => {
    if (window.confirm(__t("delete_confirm"))) {
      console.log("Delete user : " + props.deleteUser);
    }
  };
  handleChange(event) {
    this.setState({ [event.target.name]: event.target.value });
  }
  client_id = localStorage.getItem("client_id");
  renderButtons(cell, row, rowIndex) {
    return (
      <span>
        <Can
          I={["update-email-schedule"]}
          userPermissions={this.props.userPermissions.userPermissions}
        >
          <Link
            to={{
              pathname: `/bulk-email/update/${row.id}`,
              state: { id: `${row.id}` }
            }}
            className="btn btn-primary btn-sm"
          >
            <i className="fa fa-pencil" aria-hidden="true" /> {__t("edit")}
          </Link>
        </Can>
      </span>
    );
  }
  token = localStorage.getItem("token");
  handleTableChange = (type, { page, sizePerPage }) => {
    const currentIndex = (page - 1) * sizePerPage;
    //write fetch data function
    this.getscheduleList(page, sizePerPage);
  };
  getscheduleList(page = 1, sizePerPage = 10) {
    Utility.ExecuteData("getschedule", this.token, {
      page: page,
      limit: sizePerPage,
      search: this.state.search,
      client_id: this.state.client_id
    }).then(data => {
      console.log(data);
      this.setState(() => ({
        page,
        data: data.data.rows,
        sizePerPage,
        total: data.data.count
      }));
    });
  }
  componentDidMount() {
    this.getscheduleList(1, 10);
  }
  render() {
    const { data, sizePerPage, page, total } = this.state;
    const columns = [
      { dataField: "title", text: __t("title") },
      {
        dataField: "scheduled_on",
        text: __t("schedule_on"),
        formatter: cellContent =>
          Moment(cellContent).format("DD-MMM-YYYY HH:mm:ss")
      },
      { dataField: "status", text: __t("status") },
      // { dataField: "email_group", text: __t("email_group") },
      {
        dataField: "created_on",
        text: __t("created_on"),
        formatter: cellContent =>
          Moment(cellContent).format("DD-MMM-YYYY HH:mm:ss")
      },
      {
        dataField: "updated_on",
        text: __t("updated_on"),
        formatter: cellContent =>
          Moment(cellContent).format("DD-MMM-YYYY HH:mm:ss")
      },

      {
        dataField: "action",
        text: "Action",
        formatter: this.renderButtons.bind(this)
      }
    ];

    return (
      <Can
        I={["view-email-schedule"]}
        userPermissions={this.props.userPermissions.userPermissions}
      >
        <div>
          <div className="row wrapper border-bottom white-bg page-heading">
            <div className="col-lg-10">
              <h2 style={{ marginTop: 10 }}>{__t("bulk_email_list")}</h2>
              <ol className="breadcrumb">
                <li className="breadcrumb-item">
                  <Link to="/">{__t("dashboard")}</Link>
                </li>
                <li className="breadcrumb-item">
                  <Link to="/bulk-email">{__t("bulk_email")}</Link>
                </li>
              </ol>
            </div>
          </div>
          <div className="wrapper wrapper-content animated fadeInRight">
            <div className="row">
              <div className="col-lg-12 ">
                <div className="ibox ">
                  <div className="ibox-title">
                    <div className="ml-1">
                      <Can
                        I={["create-email-schedule"]}
                        userPermissions={
                          this.props.userPermissions.userPermissions
                        }
                      >
                        <Link
                          className="btn btn-primary"
                          to="/bulk-email/create"
                        >
                          {__t("create_schedule")}
                        </Link>
                      </Can>&nbsp;
                      <strong >{__t("total_schedules")}: {this.state.total}</strong>
                    </div>
                  </div>
                  <div className="ibox-content">
                    <Spinner />
                    <RemotePagination
                      columns={columns}
                      data={data}
                      page={page}
                      sizePerPage={sizePerPage}
                      totalSize={total}
                      onTableChange={this.handleTableChange}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Can>
    );
  }
}

export default EmailList;
