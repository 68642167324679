import React, { Component } from 'react';
import Moment, { min } from "moment";

import { __t, __currency } from "../../locale/language";
import Utility from "./Utility";

class ExpireAlert extends Component {
    state = {}
    constructor(props){
        super(props);
    }
    componentDidMount(){
        if (this.props.plan_data) {
            let plan_data = this.props.plan_data.data[0];
            console.log('plan_data', plan_data);

            localStorage.setItem("subs_id", plan_data.subscription_id);
            var now = Moment(new Date()); //todays date
            var end = Moment(plan_data.end_date); // another date
            var duration = Moment.duration(end.diff(now));
            var days = parseInt(duration.asDays());
            let hour = parseInt(duration.asHours());
            hour = hour - days * 24
            let minutes = parseInt(duration.minutes());

            this.setState({
                days, hour, minutes,
                title: plan_data.title,
                status: plan_data.status,
                sub_id: plan_data.id
            });
        } else {
            this.getPlanStatus();
        }       
    }
    getPlanStatus() {
        Utility.ExecuteData("getPlanStatus", this.state.token, {
            token: this.state.token,
            client_id: this.state.client_id
        }).then(data => {
            //console.log(data);
            localStorage.setItem("subs_id", data.data[0].subscription_id);
            var now = Moment(new Date()); //todays date
            var end = Moment(data.data[0].end_date); // another date
            var duration = Moment.duration(end.diff(now));
            var days = parseInt(duration.asDays());
            let hour = parseInt(duration.asHours());
            hour = hour - days * 24
            let minutes = parseInt(duration.minutes());

            this.setState({
                days, hour, minutes,
                title: data.data[0].title,
                status: data.data[0].status,
                sub_id: data.data[0].id
            });
        });
    }
    render() {
        return (
            <>
                {this.state.status && this.state.status == "active" ?
                    <div className={"alert alert-info"} id="sub_alert">
                        {__t("current_plan_is")}{" "}
                        <strong>
                            {this.state.title} {__t("plan")}{" "}
                        </strong>
                        {__t("and_will_expire_after")} {""}
                        <strong>
                            {this.state.days != "NaN" ? this.state.days : "00"} {__t("days")}
                        </strong>{" "}
                        <strong>
                            {this.state.hour != "NaN" ? this.state.hour : "00"} {__t("hours")}
                        </strong>{" ."}
                    </div>
                    : ""
                }
            </>
        );
    }
}

export default ExpireAlert;