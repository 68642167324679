export const label = {
    "address": "(Supporter Address / Adresse du supporteur)",
    "constituency": "(Supporter Constituency / Circonscription du supporteur)",
    "name": "(e.g. John Smith, Marie-France Tremblay)",
    "email": "(Supporter email / adresse de courriel du supporteur)",
    "phone": "(Supporter phone number / nombre du telephone du supporteur)",
    "parllastname": "(politician last name / nom de famille du politicien)",
    "parlfirstname": "(politician first name / prénom du politician)",
    "salutationeng": "(e.g. Mr., Ms., Dr., Min.)",
    "salutationfr": "(M. , Mme, Monsieur le ministre, Madame la ministre)",
    "salutationltre": "(Dear)",
    "salutationltrf": "(Cher/Chères)",
    "titleabbeng": "(Mr., Ms., Dr., Hon.)",
    "titleabbfr": "(M., Mme, L’ hon.)",
    "initialeng": "(e.g. P.C., M.P., M.P.P, M.N.A., M.L.A., M.H.A.)",
    "initialfr": "(e.g., député, députée, C.P.)",
    "constituencyname": "(e.g. Ottawa Centre, Halifax West, Ward 1)",
    "constituencynamefr": "(e.g. Ottawa-Centre, Halifax-Ouest, LA CITÉ-LIMOILOU)",
    "provinceeng": "(Mailing address province; e.g. Newfoundland and Labrador, Saskatchewan, Quebec, Northwest Territories)",
    "provincefr": "(province pour l'adresse postale, e.g. Terre-Neuve-et-Labrador, Saskatchewan, Québec, Territoires du Nord-Ouest)",
    "buildingname": "(e.g. City Hall, Main Legislative Building)",
    "buildingnamefr": "(Nom du edifice/e.g. édifice Lucien-Saulnier, l'édifice du Centre)",
    "buildingprovinceen": "(e.g. Newfoundland and Labrador, Saskatchewan, Quebec, Northwest Territories)",
    "buildingprovincefr": "(e.g. Terre-Neuve-et-Labrador, Saskatchewan, Québec, Territoires du Nord-Ouest)",
    "buildingcityen": "(e.g. Hamilton, Victoria, Rivière-du-Loup, Saint John)",
    "buildingcityfr": "(e.g. Hamilton, Victoria, Rivière-du-Loup, Saint John)",
    "buildingpostalcode": "(A1B 2C3)",
    "hilladdphone": "(Phone/Téléphone: 555-555-5555)",
    "hilladdfax": "(Fax/Facsimile: 555-555-5555)",
    "partyshorttitle": "",
    "partyshorttitlefr": "",
    "partyabbeng": "(e.g. NDP, C.P.C, P.Q., L.P.C.)",
    "partyabbfr": "(e.g. NPD, P.C.C., P.Q., P.L.C.)",
    "languagepreference": "(English/French)",
    "preferencelinguistique": "(Anglais/Français)",
    "email:": "(Politician e-mail / Adresse de courriel du politicien)"
}