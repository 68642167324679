import React, { Component } from "react";
import { Tab } from "react-bootstrap";
import { Link } from "react-router-dom";
import { __t } from "../../locale/language";
class Tab2 extends Component {
  constructor(props) {
    super(props);
  }
  render() {
    const tab2_data = this.props.tab2_data;
    return (
      <Tab eventKey={2} title="SMTP Detail" disabled>
        <div className="row form-group">
          <div className="col-md-6 col-sm-6 col-xs-12">
            <label className="control-label">{__t("sender_email")}</label>
            <input
              type="email"
              name="sender_email"
              className="form-control"
              placeholder="Sender Email"
              value={tab2_data.sender_email}
              onChange={this.props.handleChange}
            />
            <label id="-error" className="error">
              {tab2_data.errors["sender_email"]}
            </label>
          </div>
          <div className="col-md-6 col-sm-6 col-xs-12">
            <label className="control-label">{__t("sender_name")}</label>
            <input
              type="text"
              name="sender_name"
              autoComplete="off"
              className="form-control"
              placeholder="Sender Name"
              value={tab2_data.sender_name}
              onChange={this.props.handleChange}
            />
            <label id="-error" className="error">
              {tab2_data.errors["sender_name"]}
            </label>
          </div>
        </div>
        <div className="row form-group">
          {/* <div className="col-md-6 col-sm-6 col-xs-12">
            <label className="control-label">{__t("uname")}</label>
            <input
              type="text"
              name="username"
              autoComplete="off"
              className="form-control"
              placeholder="User Name"
              value={tab2_data.username}
              onChange={this.props.handleChange}
            />
            <label id="-error" className="error">
              {tab2_data.errors["username"]}
            </label>
          </div> */}
          <div className="col-md-6 col-sm-6 col-xs-12">
            <label className="control-label">{__t("password")}</label>
            <input
              type="password"
              name="password"
              autoComplete="new-password"
              className="form-control"
              placeholder="Password"
              onChange={this.props.handleChange}
            />
            <label id="-error" className="error">
              {tab2_data.errors["password"]}
            </label>
          </div>

          <div className="col-md-6 col-sm-6 col-xs-12">
            <label className="control-label">{__t("host")}</label>
            <input
              type="text"
              name="host"
              autoComplete="off"
              className="form-control"
              placeholder="Host"
              value={tab2_data.host}
              onChange={this.props.handleChange}
            />
            <label id="-error" className="error">
              {tab2_data.errors["host"]}
            </label>
           
        
          </div>
        </div>
        <div className="row form-group">
        
         
          
          <div className="col-md-6 col-sm-6 col-xs-12">
            <label className="control-label">{__t("port")}</label>
            <input
              type="text"
              name="port"
              autoComplete="off"
              className="form-control"
              placeholder="Port"
              value={tab2_data.port}
              onChange={this.props.handleChange}
            />
            <label id="-error" className="error">
              {tab2_data.errors["port"]}
            </label>
          </div>
        </div>
       
          <div className="row form-group">
            <div className="col-md-12">
              <button
                className="btn btn-primary"
                onClick={this.props.updateClientInfo}
                type="submit"
              >{__t("submit")}
           
              </button>
              &nbsp;&nbsp;
              <button className={"btn btn-primary"} onClick={this.props.checkSmtp}>
                {__t("test_smtp")}
              </button>
              &nbsp;&nbsp;
              <button className="btn btn-white">
                {
                  tab2_data.user_type!="client"?
                  <Link to="/client">{__t("cancel")}</Link>
                  :<Link to="/">{__t("cancel")}</Link>
                }
              </button>
            </div>
          </div>
      
      </Tab>
    );
  }
}

export default Tab2;
