import React from "react";
import { Link, Redirect } from "react-router-dom";
import $ from "jquery";
import ReactQuill from "react-quill";

import { __t } from "../../locale/language";
import Utility from "../common/Utility";
import Can from "../common/AccessControl";
import { AccessControlFunc } from "../common/AccessControl2";

const defaultMessage = ``;

class VariationsUpdate extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      letter_id: "",
      letter_title: "",
      letter_subject: "",
      letter_status: true,
      letter_template: defaultMessage,
      errors: {},
      client_id: localStorage.getItem("client_id"),
      token: localStorage.getItem("token"),
      user_type: this.props.userPermissions.user_type,
      subscription_feature: this.props.userPermissions.subscription_feature,
      update: false,
    };
  }
  token = localStorage.getItem("token");
  client_id = localStorage.getItem("client_id");

  componentDidMount() {
    let { match } = this.props;
    this.attachQuillRefs();
    if (match.params && match.params.id) {
      var id = match.params.id;
      this.findLetterVariation(id);
    }
  }
  findLetterVariation = (id) => {
    Utility.ExecuteData("findlettervar", this.token, { id }).then((data) => {
      let resObj = data.data;
      this.setState({
        letter_title: resObj.title,
        letter_subject: resObj.subject,
        letter_template: resObj.template,
        letter_id: id,
        letter_status: resObj.status ? true : false,
        update: true,
      });
    });
  };
  attachQuillRefs = () => {
    // Ensure React-Quill reference is available:
    if (
      this.reactQuillRef &&
      typeof this.reactQuillRef.getEditor !== "function"
    )
      return;
    // Skip if Quill reference is defined:
    if (this.quillRef != null) return;
    if (this.reactQuillRef) {
      const quillRef = this.reactQuillRef.getEditor();
      if (quillRef != null) this.quillRef = quillRef;
    }
  };
  handleChangeRadio = (e) => {
    let isChecked = e.target.value;
    if (isChecked === "1") {
      this.setState({ [e.target.name]: true });
    } else {
      this.setState({ [e.target.name]: false });
    }
  };
  handleOnChange = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };
  editorChange = (value) => {
    this.setState({ letter_template: value });
  };
  HandleCreateUpdate = () => {
    let {
      letter_title,
      letter_template,
      letter_subject,
      letter_id,
      client_id,
    } = this.state;
    if (letter_title.trim() === "") {
      alert(__t("Please enter title!!"));
      return;
    }
    let option = {
      title: letter_title,
      subject: letter_subject,
      template: letter_template,
      client_id,
    };
    if (letter_id) {
      option["id"] = letter_id;
    }
    Utility.ExecuteData("creatuplettervar", this.token, option)
      .then((responseJson) => {
        if (responseJson.code === 200) {
          let msg = `Letter template ${
            this.state.update ? "updated" : "created"
          } successfully`;
          $("#msg_text").text(__t(msg));
          $("#alertinfo").show();
          this.setState({ redirect: true });
        } else {
          alert("Something went wront. Try Again.");
        }
      })
      .catch((error) => {
        this.setState({ errors: true });
      });
  };

  render() {
    const {
      errors,
      letter_subject,
      letter_title,
      letter_status,
      letter_template,
      update,
      redirect,
    } = this.state;

    if (redirect) {
      return <Redirect push to="/letter-variations/" />;
    }
    let title = update ? `Update ` : `Create `;
    title += ` Letter Variation`;

    return AccessControlFunc(["update-user"], this.props.userPermissions, [
      "letter_autoresponder",
      "newsletter_autoresponder",
    ]) ? (
      <div>
        <div className="row wrapper border-bottom white-bg page-heading">
          <div className="col-lg-10">
            <h2 id="title2" style={{ marginTop: 10 }}>
              {__t(title)}
            </h2>
            <ol className="breadcrumb">
              <li className="breadcrumb-item">
                <Link to="/">{__t("dashboard")}</Link>
              </li>
              <li className="breadcrumb-item">
                <Link to="/letter-variations">{__t("Letter Variation")}</Link>
              </li>
              <li className="breadcrumb-item active">
                <strong id="title">{__t(title)}</strong>
              </li>
            </ol>
          </div>
        </div>
        <div className="row">
          <div className="col-md-12">
            <div className="ibox float-e-margins">
              <div className="ibox-content">
                <div className="form-horizontal">
                  <div>
                    <div className="row form-group">
                      {/* Title */}
                      <div className="col-md-8 col-sm-8 col-xs-12">
                        <label className="control-label required-field">
                          {__t("Title") + ": "}
                        </label>
                        <input
                          type="text"
                          name="letter_title"
                          value={letter_title}
                          className="form-control"
                          placeholder={""}
                          onChange={this.handleOnChange}
                        />
                        <label id="-error" className="error">
                          {errors["letter_title"]}
                        </label>
                      </div>
                      {/* Subject */}
                      <div className="col-md-8 col-sm-8 col-xs-12">
                        <label className="control-label required-field">
                          {__t("Subject") + ": "}
                        </label>
                        <input
                          type="text"
                          name="letter_subject"
                          value={letter_subject}
                          className="form-control"
                          placeholder={""}
                          onChange={this.handleOnChange}
                        />
                        <label id="-error" className="error">
                          {errors["letter_subject"]}
                        </label>
                      </div>
                      {/* Email template */}
                      <div className="col-md-8 col-sm-8 col-xs-12">
                        <label className="control-label required-field">
                          {__t("Autoresponder Letter template")}
                        </label>
                        <ReactQuill
                          ref={(el) => {
                            this.reactQuillRef = el;
                          }}
                          theme={"snow"}
                          onChange={this.editorChange}
                          modules={VariationsUpdate.modules}
                          formats={VariationsUpdate.formats}
                          value={letter_template}
                        />
                        <label id="-error" className="error">
                          {this.state.errors["letter_template"]}
                        </label>
                      </div>
                      {/* Letter Status */}
                      {false && (
                        <div className="col-md-12 col-sm-12 col-xs-12">
                          <div className="row">
                            <div className="radio col-md-12 col-sm-12 col-xs-10">
                              <label className="control-label">
                                {__t("Letter Status") + ": "}
                              </label>
                            </div>
                            <div className="radio col-md-2 col-sm-2 col-xs-3">
                              <label>
                                <input
                                  type="radio"
                                  value="1"
                                  name="letter_status"
                                  id="letter_status"
                                  checked={
                                    letter_status === true ? true : false
                                  }
                                  onClick={(e) => this.handleChangeRadio(e)}
                                />
                                {" " + __t("Active")}
                              </label>
                            </div>
                            <div className="radio col-md-2 col-sm-2 col-xs-3">
                              <label>
                                <input
                                  type="radio"
                                  value="0"
                                  name="letter_status"
                                  id="letter_status"
                                  checked={
                                    letter_status === false ? true : false
                                  }
                                  onClick={(e) => this.handleChangeRadio(e)}
                                />
                                {" " + __t("In-active")}
                              </label>
                            </div>
                          </div>
                        </div>
                      )}
                      {/* submit btn */}
                      <div className="col-md-8 col-sm-8 col-xs-12">
                        <button
                          className="btn btn-primary"
                          onClick={this.HandleCreateUpdate}
                          type="submit"
                        >
                          {update ? "Update" : "Create"}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    ) : (
      ""
    );
  }
}

/*
 * Quill modules to attach to editor
 * See https://quilljs.com/docs/modules/ for complete options
 */
VariationsUpdate.modules = {};
VariationsUpdate.modules.toolbar = [
  ["bold", "italic", "underline", "strike"], // toggled buttons
  ["blockquote", "code-block"], // blocks
  [{ header: 1 }, { header: 2 }], // custom button values
  [{ list: "ordered" }, { list: "bullet" }], // lists
  [{ script: "sub" }, { script: "super" }], // superscript/subscript
  [{ indent: "-1" }, { indent: "+1" }], // outdent/indent
  [{ direction: "rtl" }], // text direction
  [{ size: ["small", false, "large", "huge"] }], // custom dropdown
  [{ header: [1, 2, 3, 4, 5, 6, false] }], // header dropdown
  [{ color: [] }, { background: [] }], // dropdown with defaults
  [{ font: [] }], // font family
  [{ align: [] }], // text align
  ["clean"], // remove formatting
];

/*
 * Quill editor formats
 * See https://quilljs.com/docs/formats/
 */
VariationsUpdate.formats = [
  "header",
  "font",
  "background",
  "color",
  "code",
  "size",
  "bold",
  "italic",
  "underline",
  "strike",
  "blockquote",
  "list",
  "bullet",
  "indent",
  "script",
  "align",
  "direction",
  "link",
  "image",
  "code-block",
  "formula",
  "video",
];
export default VariationsUpdate;
