import React from "react";
import { Dropdown } from "react-bootstrap";
import { smoothlyMenu } from "../layouts/Helpers";
import { Link, Location, Redirect } from "react-router-dom";
import { __t, __language } from "../../locale/language";
import Can from "./AccessControl";
import $ from "jquery";
import Select from "react-select";
import Utility from "../common/Utility";

import "sweetalert2/dist/sweetalert2.min.css";
import Swal from "sweetalert2";

class TopHeader extends React.Component {
  toggleNavigation(e) {
    e.preventDefault();
    $("body").toggleClass("mini-navbar");
    smoothlyMenu();
  }
  constructor(props, context) {
    super(props);
    this.state = {
      redirect: false,
      userPermissions: this.props.userPermissions,
      selectedOption: null,
      sewwe: [],
      selected: null,
      userData: localStorage.getItem("lgn_dtl"),
      token: localStorage.getItem("token"),
      maintenance: 0,
    };
    this.logout = this.logout.bind(this);
    this.changeLanguage = this.props.changeLanguage.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.userList = this.userList.bind(this);
  }
  userList() {
    var ref = this;
    Utility.ExecuteData("finduserslist", this.state.token, {
      user_id: 1,
      user_type: "admin",
    }).then((data) => {
      if (data.code == 200) {
        var ResP = [];
        var res = {};
        var ObjData = data.data;
        Object.keys(ObjData).map(function (key, index) {
          ResP.push({
            value: ObjData[key]["id"],
            label:
              ObjData[key]["name"] + " (" + ObjData[key]["subdomain"] + ")",
          });
        });
        ref.setState({
          sewwe: ResP,
        });
      }
    });
  }

  updateMaintenance = async (elem) => {
    let checked = elem.target.checked;
    let res = await Utility.ExecuteData("maintenace", this.state.token, {
      status: "update",
    }).catch((err) => {
      console.error(err);
    });
    if (checked) {
      this.setState({ maintenance: 1 });
    } else {
      this.setState({ maintenance: 0 });
    }
  };
  async componentDidMount() {
    let res = await Utility.ExecuteData("maintenace", this.state.token, {
      status: "get",
    }).catch((e) => {
      console.error(e);
    });
    this.setState({ maintenance: res.status == "1" ? true : false }, () => {
      if (this.state.maintenance) {
        document.getElementById("maintenance").checked = true;
      }
    });
  }

  handleChange(selectedOption) {
    this.setState({
      selected: selectedOption,
    });
  }
  logout() {
    Swal.fire({
      title: "Confirm Logout",
      text: "Are you sure you want to log out?",
      icon: "question",
      showCancelButton: true,
      confirmButtonText: "Yes, Log Out",
      cancelButtonText: "Cancel",
    }).then((result) => {
      if (result.isConfirmed) {
        /**logout from main domain too */
        let host = window.location.host;
        let protocol = window.location.protocol;
        if (host.indexOf("localhost") != -1) {
          host = "admin.pushpolitics.org";
        }
        let [subdomain, ...domain] = host.split(".");
        domain = domain.join(".");
        let url = protocol + "//www." + domain + "/logout";
        localStorage.clear();

        window.location.href = url;

        /**logout from this domain*/

        this.setState({ redirect: true });
        Swal.fire(
          "Logged Out",
          "You have been successfully logged out.",
          "success"
        );
      }
    });
  }
  render() {
    let user_data = JSON.parse(this.state.userData);
    const { redirect } = this.state;
    if (redirect) {
      return <Redirect push to="/login" />;
    }

    return (
      <div className="row border-bottom">
        <nav
          className="navbar navbar-static-top white-bg"
          role="navigation"
          style={{ width: "100%" }}
        >
          <div className="navbar-header" style={{ minWidth: "200px" }}>
            <a
              className="navbar-minimalize minimalize-styl-2 btn btn-primary "
              onClick={this.toggleNavigation}
              href="#"
            >
              <i className="fa fa-bars" />{" "}
            </a>
            {/* <h1 className="header-title">Title</h1> */}
          </div>

          <ul className="nav navbar-top-links navbar-right">
            <li>
              {/* <div>
                <label className="control-label">View as : </label>
                <div style={{ width: "150px" }}>
                  <Select
                    value={this.state.selected}
                    options={this.state.sewwe}
                    onChange={this.handleChange}
                  />
                </div>
              </div> */}
            </li>
            {user_data.user_type == "admin" ? (
              <li>
                <div style={{ display: "inline-block" }}>
                  <label className="control-label">
                    {__t("maintenance_label")}{" "}
                  </label>
                  <div style={{ display: "inline-block", marginLeft: "10px" }}>
                    <input
                      type="checkbox"
                      name={"maintenance"}
                      id={"maintenance"}
                      onClick={(e) => {
                        this.updateMaintenance(e);
                      }}
                      defaultChecked={this.state.maintenance}
                    />
                  </div>
                </div>
              </li>
            ) : null}
          </ul>
          <ul className="nav navbar-top-links navbar-right">
            <li>{__language(this.changeLanguage)}</li>
            <li>
              <a onClick={this.logout}>
                <i className="fa fa-sign-out" /> {__t("log_out")}
              </a>
            </li>
          </ul>
        </nav>
      </div>
    );
  }
}

export default TopHeader;
