import React from "react";
import { Redirect, Link } from "react-router-dom";
import RemotePagination from "../common/Table";
import Utility from "../common/Utility";
import { __t } from "../../locale/language";
import Moment from "moment";
import $ from "jquery";
import Can from "../common/AccessControl";
class PaymentgatewayList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      page: 1,
      data: [],
      sizePerPage: 10,
      total: 0,
      search: "",
      client_id: localStorage.getItem("client_id")
    };
    this.deletePaymentgateway = this.deletePaymentgateway.bind(this);
    this.updatePaymentgatewayList = this.updatePaymentgatewayList.bind(this);
    this.handleChange = this.handleChange.bind(this);
  }

  deletePaymentgateway = props => {
    if (window.confirm(__t("delete_confirm"))) {
      //console.log("Delete paymentgateway : " + props.deletePaymentgateway);
    }
  };
  client_id = localStorage.getItem("client_id");
  handleChange(event) {
    this.setState({ [event.target.name]: event.target.value });
  }
  renderButtons(cell, row, rowIndex) {
    return (
      <span>
        <Link
          to={{
            pathname: `/paymentgateway/update/${row.id}`,
            state: { id: `${row.id}` }
          }}
          className="btn btn-primary btn-sm"
        >
          <i className="fa fa-pencil" aria-hidden="true" /> {__t("edit")}
        </Link>
      </span>
    );
  }
  token = localStorage.getItem("token");
  handleTableChange = (type, { page, sizePerPage }) => {
    const currentIndex = (page - 1) * sizePerPage;
    //write fetch data function
    this.updatePaymentgatewayList(page, sizePerPage);
  };
  updatePaymentgatewayList(page = 1, sizePerPage = 10) {
    Utility.ExecuteData("paymentgatewaydata", this.token, {
      page: page,
      limit: sizePerPage,
      search: this.state.search,
      client_id: this.client_id
    }).then(data => {
      this.setState(() => ({
        page,
        data: data.data.rows,
        sizePerPage,
        total: data.data.count
      }));
    });
  }
  componentDidMount() {
    this.updatePaymentgatewayList(1, 10);
  }
  render() {
    const { data, sizePerPage, page, total } = this.state;
    const columns = [
      { dataField: "account_id", text: __t("account_id") },
      { dataField: "access_key", text: __t("access_key") },
      { dataField: "access_token", text: __t("access_token") },
      { dataField: "type", text: __t("type") },
      {
        dataField: "created_at",
        text: __t("created_at"),
        formatter: cellContent =>
          Moment(cellContent).format("DD-MMM-YYYY HH:mm:ss")
      },
      {
        dataField: "updated_at",
        text: __t("updated_at"),
        formatter: cellContent =>
          Moment(cellContent).format("DD-MMM-YYYY HH:mm:ss")
      },
      {
        dataField: "action",
        text: "Action",
        formatter: this.renderButtons.bind(this)
      }
    ];

    return (
      <Can
        I={["view-paymentgateway"]}
        userPermissions={this.props.userPermissions.userPermissions}
      >
        <div>
          <div className="row wrapper border-bottom white-bg page-heading">
            <div className="col-lg-10">
              <h2 style={{ marginTop: 10 }}>{__t("paymentgateway_list")}</h2>
              <ol className="breadcrumb">
                <li className="breadcrumb-item">
                  <Link to="/">{__t("dashboard")}</Link>
                </li>
                <li className="breadcrumb-item">
                  <Link to="/paymentgateway">{__t("paymentgateway")}</Link>
                </li>
                <li className="breadcrumb-item active">
                  <strong>{__t("paymentgateway_list")}</strong>
                </li>
              </ol>
            </div>
          </div>
          <div className="wrapper wrapper-content animated fadeInRight">
            <div className="row">
              <div className="col-lg-12 ">
                <div className="ibox ">
                  <div className="ibox-title">
                    <div className="row from-control">
                      &nbsp; &nbsp; &nbsp;
                      <input
                        className="form-control col-md-3"
                        type="text"
                        name="search"
                        id="search_field"
                        onChange={this.handleChange}
                        placeholder={__t("search")}
                      />
                      &nbsp;
                      <input
                        className="btn btn-primary"
                        type="button"
                        onClick={() => {
                          this.updatePaymentgatewayList();
                        }}
                        name="btn_search"
                        value={__t("search")}
                      />
                      &nbsp;
                      <input
                        className="btn btn-warning"
                        type="button"
                        onClick={() => {
                          this.setState({ search: "" }, () => {
                            this.updatePaymentgatewayList();
                            document.getElementById("search_field").value = "";
                          });
                        }}
                        name="btn_search"
                        value={__t("clear")}
                      />
                      &nbsp;
                      {/* <Link
                      className="btn btn-primary"
                      to="/paymentgateway/create"
                    >
                      {__t("create")}
                    </Link> */}
                      <br />
                    </div>
                  </div>
                  <div className="ibox-content">
                    <RemotePagination
                      columns={columns}
                      data={data}
                      page={page}
                      sizePerPage={sizePerPage}
                      totalSize={total}
                      onTableChange={this.handleTableChange}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Can>
    );
  }
}

export default PaymentgatewayList;
