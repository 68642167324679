import React from "react";
import SubscriptionList from "./SubscriptionList";
import SubscriptionUpdate from "./SubscriptionUpdate";
import { Route, Switch } from "react-router-dom";
class Index extends React.Component {
  constructor(props) {
    super(props);
  }
  render() {
    return (
      <Switch>
        <Route
          path="/subscription/create"
          render={props => (
            <SubscriptionUpdate
              {...props}
              userPermissions={this.props.userPermissions}
            />
          )}
        />
        <Route
          path="/subscription/create/"
          render={props => (
            <SubscriptionUpdate
              {...props}
              userPermissions={this.props.userPermissions}
            />
          )}
        />
        <Route
          path="/subscription/update/:id"
          render={props => (
            <SubscriptionUpdate
              {...props}
              userPermissions={this.props.userPermissions}
            />
          )}
        />
        <Route
          path="/subscription"
          render={props => (
            <SubscriptionList
              {...props}
              userPermissions={this.props.userPermissions}
            />
          )}
        />
      </Switch>
    );
  }
}

export default Index;
