import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import { ListGroup, ListGroupItem, Checkbox } from "react-bootstrap";
import { Link } from "react-router-dom";
import Select from "react-select";
import $ from "jquery";

import Utility from "../common/Utility";
import { __t } from "../../locale/language";
import Can from "../common/AccessControl";

var radioValue = "";
var price_config = [];
var opt = {};
var contact_data = [];

class SubscriptionUpdate extends Component {
  constructor(props) {
    super(props);
    this.state = {
      redirect: false,
      title: "",
      errors: {},
      description: "",
      duration: "",
      price: "",
      type: "client",
      client_id: localStorage.getItem("client_id"),
      status: "",
      token: localStorage.getItem("token"),
      featurelist: [],
      features: [],
      options: [],
      selectedOption: [],
      widgets: "",
      list_options: [],
      contact_list: [],
      user_type: this.props.userPermissions.user_type,
      price_configuration: [],
      thirty: "",
      sixty: "",
      one_eighty: "",
      three_sixty_five: "",
      package_data: "",
      price_list: "",
      package_status: false,
      package_price: [],
      remove_id: [],
    };
    this.multiHandleChangeList = this.multiHandleChangeList.bind(this);
    this.featuresList = this.featuresList.bind(this);
    this.findSubscription = this.findSubscription.bind(this);
    this.findfeatures = this.findfeatures.bind(this);
    this.findsubswidget = this.findsubswidget.bind(this);
    this.create = this.create.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleChecked = this.handleChecked.bind(this);
    this.multiHandleChange = this.multiHandleChange.bind(this);
    this.setResellerValue = this.setResellerValue.bind(this);
    this.getResellerPackage = this.getResellerPackage.bind(this);
  }

  componentWillMount() {
    this.getDatasets();
    this.featuresList();
  }
  getDatasets = async () => {
    let res = null;

    if (this.props.userPermissions.user_type == "admin") {
      res = await Utility.executeRequest("get-data-set", { limit: 1000 });
    } else {
      res = await Utility.executeRequest("get-data-set-rel", {
        rel: "reseller",
        rel_id: this.state.client_id,
      });
      // res = await Utility.executeRequest("get-data-set-rel", { rel: "subscription", rel_id: id });
    }
    if (res.code == 200) {
      contact_data = res.data.rows;
      let list_options = [];
      res.data.rows.map((v) => {
        if (v.id != null) {
          list_options.push({ value: v.id, label: v.title });
        }
      });
      this.setState({ list_options: list_options });
    }
  };
  componentDidMount() {
    price_config.length = 0;
    if (
      typeof this.props.location.state != "undefined" &&
      this.props.location.state.id
    ) {
      $("#title").text(__t("edit_subscription"));
      this.findsubswidget();
    }
    if (
      this.props.location.state &&
      typeof this.props.location.state !== "undefined"
    ) {
      this.findSubscription();
      //this.findfeatures();
      var id = this.props.location.state.id;
      this.findfeatures(id);
    }
    if (this.state.user_type == "reseller") {
      opt["disabled"] = "disabled";
      //this.getResellerContactList();
      this.getResellerPackage();
    }
    this.findwidgets();
  }
  getResellerPackage() {
    Utility.ExecuteData("getResellerPackage", this.token, {
      client_id: this.state.client_id,
    }).then((data) => {
      this.setState({
        package_data: data.data,
      });
    });
  }
  setPackageData = (e) => {
    var getdata = [];
    var getpackageprice = [];
    var subs_id = "";
    this.state.package_data.map((k) => {
      if (k.id == e.target.value) {
        getdata.push(k);
        getpackageprice.push(JSON.parse(k.price_configuration));
        subs_id = k.id;
      }
    });

    this.setState(
      {
        package_status: true,
        title: getdata[0].title,
        description: getdata[0].description,
        status: getdata[0].status,
        package_price: getpackageprice,
      },
      () => {
        this.findfeatures(subs_id);
      }
    );
  };
  findwidgets = () => {
    Utility.ExecuteData("findwidget", this.token, {
      client_id: this.state.client_id,
      search: "",
    }).then((data) => {
      var option_arr = [];
      if (data.code === 200) {
        data.data.map((val, key) => {
          option_arr.push({ value: val.id, label: val.title });
        });
      }
      this.setState({ options: option_arr });
    });
  };
  findfeatures = (id) => {
    Utility.ExecuteData("findfeatures", this.token, {
      id: id,
    }).then((data) => {
      var i = 0;
      let checkedFeature = [];
      let features = [];
      for (let j = 0; j < data.data.length; j++) {
        const f = data.data[j];
        checkedFeature.push(f.feature_widget_id);
      }
      for (let k = 0; k < this.state.featurelist.length; k++) {
        const fle = this.state.featurelist[k];
        const fid = fle.id;
        features[fid] = checkedFeature.includes(fid);
        this.setState({ features });
      }
    });
  };
  findsubswidget = () => {
    var id = this.props.location.state.id;
    Utility.ExecuteData("findsubswidget", this.token, {
      id: id,
      client_id: this.state.client_id,
    }).then((data) => {
      var sub_data = data.data;
      var option_arr = [];
      data.data.map((val, key) => {
        option_arr.push({ id: val.value, title: val.label });
      });
      this.setState({
        selectedOption: sub_data,
        widgets: option_arr,
        widget_arr: option_arr,
      });
    });
  };
  featuresList = () => {
    Utility.GetData("featureslist", this.token).then((featuresdata) => {
      this.setState({ featurelist: featuresdata.data });
    });
  };
  findSubscription = async () => {
    var id = this.props.location.state.id;
    let res = await Utility.executeRequest("get-data-set-rel", {
      rel: "subscription",
      rel_id: id,
    });
    let list_options = [];
    if (res.code == 200) {
      contact_data = res.data.rows;
      res.data.rows.map((v) => {
        if (v.id != null) {
          list_options.push({ value: v.id, label: v.title });
        }
      });
    }
    Utility.ExecuteData("findsubscription", this.token, { id: id }).then(
      (data) => {
        //var nw_contact = JSON.parse(data.data.contact_list);
        var price_data = JSON.parse(data.data.price_configuration);
        var reseller_data = "";
        var check_radio = data.data.duration;
        if (this.state.user_type == "reseller") {
          reseller_data = price_data;
        }
        $("#" + check_radio).attr("checked", true);
        $("#duration").val(data.data.duration);

        //  package_price=price_data
        this.setState({
          title: data.data.title,
          description: data.data.description,
          status: data.data.status,
          duration: data.data.duration,
          price: data.data.price,
          contact_list: list_options,
          type: data.data.type,
          package_price: reseller_data,
          thirty:
            typeof price_data[0] !== "undefined" ? price_data[0].price : 0,
          sixty: typeof price_data[1] !== "undefined" ? price_data[1].price : 0,
          one_eighty:
            typeof price_data[2] !== "undefined" ? price_data[2].price : 0,
          three_sixty_five:
            typeof price_data[3] !== "undefined" ? price_data[3].price : 0,
          campaign_number: data.data.campaign_number,
        });
      }
    );
  };
  handleValidation = () => {
    let fields = this.state;
    let errors = {};
    let formIsValid = true;

    if (this.state.user_type == "admin") {
      if (!fields["thirty"]) {
        formIsValid = false;
        errors["thirty"] = __t("please_set_all_price");
      }
      if (!fields["sixty"]) {
        formIsValid = false;
        errors["sixty"] = __t("please_set_all_price");
      }
      if (!fields["one_eighty"]) {
        formIsValid = false;
        errors["one_eighty"] = __t("please_set_all_price");
      }
      if (!fields["three_sixty_five"]) {
        formIsValid = false;
        errors["three_sixty_five"] = __t("please_set_all_price");
      }
      // return false
      if (!fields["title"]) {
        formIsValid = false;
        errors["title"] = __t("select_title");
      }
      if (!fields["campaign_number"] || fields["campaign_number"] == 0) {
        formIsValid = false;
        errors["campaign_number"] = __t("plese_give_campaign_number");
      }
      if (radioValue == undefined || radioValue == "") {
        formIsValid = false;

        errors["radio"] = __t("please_select_defult_prce");
      }
    }

    if (this.state.user_type == "reseller") {
      if (!fields["title"]) {
        formIsValid = false;
        errors["title"] = __t("select_title");
      }
      if (
        !fields["duration"] ||
        fields["duration"] == "select" ||
        fields.duration == "0"
      ) {
        formIsValid = false;
        errors["duration"] = __t("select_duration");
      }
      if (!fields["price"] || fields.price == "0") {
        formIsValid = false;
        errors["price"] = __t("select_price");
      }
    }
    if (!fields["type"]) {
      formIsValid = false;
      errors["type"] = __t("select_type");
    }
    if (!fields["status"]) {
      formIsValid = false;
      errors["status"] = __t("select_status");
    }
    if (!fields["campaign_number"] || fields["campaign_number"] == 0) {
      formIsValid = false;
      errors["campaign_number"] = __t("plese_give_campaign_number");
    }
    if (this.state.user_type == "reseller") {
      this.setState({ errors: errors });
    } else {
      this.setState({ errors: errors });
    }

    return formIsValid;
  };
  handleChange = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };
  create = () => {
    var duration;
    var price;
    // return false;
    if (this.state.user_type == "admin") {
      radioValue = $("input[name=subs_price]:checked").val();
      if (radioValue) {
        if (radioValue == "thirty") {
          duration = 30;
          price = this.state.thirty;
        } else if (radioValue == "sixty") {
          duration = 60;
          price = this.state.sixty;
        } else if (radioValue == "one_eighty") {
          duration = 180;
          price = this.state.one_eighty;
        } else if (radioValue == "three_sixty_five") {
          duration = 365;
          price = this.state.three_sixty_five;
        }
      }
      price_config = [];
      price_config.push(
        {
          duration: "30 days",
          price: this.state.thirty,
        },
        {
          duration: "60 days",
          price: this.state.sixty,
        },
        {
          duration: "180 days",
          price: this.state.one_eighty,
        },
        {
          duration: "365 days",
          price: this.state.three_sixty_five,
        }
      );
    }

    var remove_id = [];
    if (
      this.props.location.state &&
      typeof this.props.location.state !== "undefined"
    ) {
      var cnt = this.state.contact_list;
      var cnt2 = contact_data;
      cnt2 = cnt2.filter(function (item) {
        return !cnt.includes(item);
      });
      cnt2.map((val, k) => {
        remove_id.push(val.data_set_id);
      });
      //   $("#tab_2").removeAttr("disabled","")
    }
    // return false
    this.setState(
      {
        price_configuration:
          price_config.length == 0 ? this.state.package_price : price_config,
        duration:
          typeof duration == "undefined" ? this.state.duration : duration,
        price: typeof price == "undefined" ? this.state.price : price,
        remove_id: remove_id,
      },
      () => {
        // return false
        if (this.handleValidation()) {
          if (
            this.props.location.state &&
            typeof this.props.location.state !== "undefined"
          ) {
            this.setState({ id: this.props.location.state.id }, () => {
              Utility.ExecuteData("updatesubscription", this.token, this.state)
                .then((responseJson) => {
                  if (responseJson.code == 200) {
                    price_config = [];
                    this.setState({ redirect: true });
                  } else if (responseJson.code === 301) {
                    //$("#msg_text").text(__t("subs_alredy_created"));
                    let errors = {};
                    let formIsValid = true;
                    formIsValid = false;
                    errors["title"] = __t("subs_alredy_created");
                    this.setState({ errors: errors });
                  } else {
                    alert("Something went wront. Try Again.");
                  }
                })
                .catch((error) => {
                  this.setState({ errors: true });
                });
            });
          } else {
            Utility.ExecuteData("createsubscription", this.token, this.state)
              .then((responseJson) => {
                if (responseJson.code === 200) {
                  price_config = [];
                  this.setState({ redirect: true });
                } else if (responseJson.code === 301) {
                  //$("#msg_text").text(__t("subs_alredy_created"));
                  let errors = {};
                  let formIsValid = true;
                  formIsValid = false;
                  errors["title"] = __t("subs_alredy_created");
                  this.setState({ errors: errors });
                } else {
                  alert("Something went wront. Try Again.");
                }
              })
              .catch((error) => {
                this.setState({ errors: true });
              });
          }
        }
      }
    );
    //  return false
  };
  multiHandleChange = (selectedOption) => {
    var arr = [];
    selectedOption.map((val, key) => {
      arr.push({ id: val.value, title: val.label });
    });
    this.setState({ selectedOption, widget_arr: arr });
  };
  multiHandleChangeList = (contact_list) => {
    var arr = [];
    contact_list.map((val, key) => {
      arr.push({ id: val.value, title: val.label });
    });
    this.setState({ contact_list });
  };
  handleChecked = (e) => {
    const { name } = e.target;
    this.setState((prevState) => ({
      features: {
        ...prevState.features,
        [name]: !prevState.features[name],
      },
    }));
  };
  // getResellerContactList = () => {
  //   Utility.ExecuteData("getResellerContactList", this.token, {
  //     client_id: this.state.client_id
  //   }).then(data => {
  //     this.setState({
  //       list_options: JSON.parse(data.data[0].contact)
  //     });
  //   });
  // }
  setResellerValue(e) {
    var price = "";
    var duration = "";
    if (this.state.package_price.length > 0) {
      if (e.target.value == "30") {
        price = this.state.package_price[0][0].price;
        duration = "30";
      } else if (e.target.value == "60") {
        price = this.state.package_price[0][1].price;
        duration = "60";
      } else if (e.target.value == "180") {
        price = this.state.package_price[0][2].price;
        duration = "180";
      } else if (e.target.value == "365") {
        price = this.state.package_price[0][3].price;
        duration = "365";
      } else if (e.target.value == "select") {
        price = "0";
        duration = "0";
      }
      this.setState({
        price: price,
        duration: duration,
      });
    }
  }

  token = localStorage.getItem("token");
  client_id = localStorage.getItem("client_id");
  render() {
    const { redirect } = this.state;
    if (redirect) {
      return <Redirect push to="/subscription/" />;
    }
    return (
      <Can
        I={["update-subscription"]}
        userPermissions={this.props.userPermissions.userPermissions}
      >
        <div>
          <div className="row wrapper border-bottom white-bg page-heading">
            <div className="col-lg-10">
              <h2 style={{ marginTop: 10 }}>{__t("subscription")}</h2>
              <ol className="breadcrumb">
                <li className="breadcrumb-item">
                  <Link to="/">{__t("dashboard")}</Link>
                </li>
                <li className="breadcrumb-item">
                  <Link to="/subscription">{__t("subscription")}</Link>
                </li>
                <li className="breadcrumb-item active">
                  <strong id="title">{__t("create_subscription")}</strong>
                </li>
              </ol>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12">
              <div className="ibox float-e-margins">
                <div className="ibox-title">
                  <h5>Subscription</h5>
                </div>
                <div className="ibox-content">
                  <div className="form-horizontal">
                    {(this.props.location.state &&
                      typeof this.props.location.state !== "undefined") ||
                    this.state.user_type == "admin" ? (
                      ""
                    ) : (
                      <div className="row form-group">
                        <div className="col-md-6 col-sm-6 col-xs-12">
                          <label className="control-label required-field">
                            {__t("subs_package")}
                          </label>
                          <select
                            name="subs_package"
                            className="form-control"
                            defaultValue={this.state.subs_package}
                            onChange={(e) => {
                              this.setPackageData(e);
                            }}
                          >
                            <option value="select">--Select Package--</option>
                            {this.state.package_data
                              ? this.state.package_data.map((k) => {
                                  return (
                                    <option value={k.id} key={k.id}>
                                      {k.title}
                                    </option>
                                  );
                                })
                              : ""}
                          </select>
                        </div>
                      </div>
                    )}

                    {(this.state.package_status == true &&
                      this.state.user_type == "reseller") ||
                    this.state.user_type == "admin" ||
                    (this.props.location.state &&
                      typeof this.props.location.state !== "undefined") ? (
                      <div>
                        <div className="row form-group">
                          <div className="col-md-6 col-sm-6 col-xs-12">
                            <label className="control-label required-field">
                              {__t("title")}
                            </label>
                            <input
                              type="text"
                              name="title"
                              value={this.state.title}
                              className="form-control"
                              placeholder={__t("title")}
                              onChange={this.handleChange}
                            />
                            <label id="-error" className="error">
                              {this.state.errors["title"]}
                            </label>
                          </div>
                          <div className="col-md-6 col-sm-6 col-xs-12">
                            <label className="control-label">
                              {__t("description")}
                            </label>
                            <textarea
                              type=""
                              name="description"
                              value={this.state.description}
                              className="form-control"
                              placeholder={__t("description")}
                              onChange={this.handleChange}
                            />
                          </div>
                        </div>
                        <div className="row form-group">
                          <div className="col-md-6 col-sm-6 col-xs-12">
                            {this.state.user_type == "reseller" ? (
                              <div className="row">
                                <div className="col-md-6 col-sm-6 col-xs-12">
                                  <label className="control-label required-field">
                                    {__t("duration")}
                                  </label>
                                  <select
                                    id="duration"
                                    name="duration"
                                    className="form-control"
                                    defaultValue={this.state.duration}
                                    onChange={this.setResellerValue}
                                  >
                                    <option value="select">
                                      --Select Status--
                                    </option>
                                    <option value="30">30 Days</option>
                                    <option value="60">60 Days</option>
                                    <option value="180">180 Days</option>
                                    <option value="365">365 Days</option>
                                  </select>

                                  <label id="-error" className="error">
                                    {this.state.errors["duration"]}
                                  </label>
                                </div>
                                <div className="col-md-6 col-sm-6 col-xs-12">
                                  <label className="control-label required-field">
                                    {__t("price")}
                                  </label>
                                  <input
                                    type="text"
                                    name="price"
                                    value={this.state.price}
                                    className="form-control"
                                    placeholder={__t("price")}
                                    onChange={this.handleChange}
                                    readOnly
                                  />
                                  <label id="-error" className="error">
                                    {this.state.errors["price"]}
                                  </label>
                                </div>
                              </div>
                            ) : (
                              ""
                            )}

                            {this.state.user_type == "admin" ? (
                              <div className="">
                                <label className="control-label required-field">
                                  {__t("price_configuration")}
                                </label>
                                <label id="-error" className="error">
                                  {this.state.errors["radio"]}
                                </label>
                                <div className="form-group">
                                  <input
                                    type="radio"
                                    id="30"
                                    name="subs_price"
                                    value="thirty"
                                  />
                                  <label id="thirty" className="control-label">
                                    30 {__t("days")}
                                  </label>
                                  <input
                                    type="number"
                                    name="thirty"
                                    value={this.state.thirty}
                                    className="form-control"
                                    placeholder={__t("price")}
                                    onChange={this.handleChange}
                                  />
                                  <label id="-error" className="error">
                                    {this.state.errors["thirty"]}
                                  </label>
                                </div>
                                <div className="form-group">
                                  <input
                                    type="radio"
                                    id="60"
                                    name="subs_price"
                                    value="sixty"
                                  />{" "}
                                  <label id="sixty" className="control-label">
                                    60 {__t("days")}
                                  </label>
                                  <input
                                    type="number"
                                    name="sixty"
                                    value={this.state.sixty}
                                    className="form-control"
                                    placeholder={__t("price")}
                                    onChange={this.handleChange}
                                  />
                                  <label id="-error" className="error">
                                    {this.state.errors["sixty"]}
                                  </label>
                                </div>
                                <div className="form-group">
                                  <input
                                    type="radio"
                                    id="180"
                                    name="subs_price"
                                    value="one_eighty"
                                  />{" "}
                                  <label
                                    id="one_eighty"
                                    className="control-label"
                                  >
                                    180 {__t("days")}
                                  </label>
                                  <input
                                    type="number"
                                    name="one_eighty"
                                    value={this.state.one_eighty}
                                    className="form-control"
                                    placeholder={__t("price")}
                                    onChange={this.handleChange}
                                  />
                                  <label id="-error" className="error">
                                    {this.state.errors["one_eighty"]}
                                  </label>
                                </div>
                                <div className="form-group">
                                  <input
                                    type="radio"
                                    id="365"
                                    name="subs_price"
                                    value="three_sixty_five"
                                  />{" "}
                                  <label
                                    id="three_sixty_five"
                                    className="control-label"
                                  >
                                    365 {__t("days")}
                                  </label>
                                  <input
                                    type="number"
                                    name="three_sixty_five"
                                    value={this.state.three_sixty_five}
                                    className="form-control"
                                    placeholder={__t("price")}
                                    onChange={this.handleChange}
                                  />
                                  <label id="-error" className="error">
                                    {this.state.errors["three_sixty_five"]}
                                  </label>
                                </div>
                              </div>
                            ) : (
                              ""
                            )}
                          </div>
                          <div className="col-md-6 col-sm-6 col-xs-12">
                            <div className="form-group">
                              <label className="control-label required-field">
                                {__t("number_of_campaign")}
                              </label>
                              <input
                                type="number"
                                name="campaign_number"
                                defaultValue={this.state.campaign_number}
                                className="form-control"
                                placeholder={__t("campaign_number")}
                                onChange={this.handleChange}
                              />
                              <label id="-error" className="error">
                                {this.state.errors["campaign_number"]}
                              </label>
                            </div>
                            <div className="form-group">
                              <label className="control-label">
                                {__t("features")}
                              </label>
                              <ListGroup>
                                {this.state.featurelist.map((val, key) => {
                                  return (
                                    <ListGroupItem key={key}>
                                      <Checkbox
                                        id="checkbox"
                                        name={val.id}
                                        key={key}
                                        onChange={this.handleChecked}
                                        checked={this.state.features[val.id]}
                                        {...opt}
                                      >
                                        &nbsp;&nbsp;
                                        {val.title}
                                      </Checkbox>
                                    </ListGroupItem>
                                  );
                                })}
                              </ListGroup>
                            </div>
                          </div>
                        </div>
                        <div className="row form-group">
                          <div className="col-md-6 col-sm-6 col-xs-12">
                            <div className="form-group">
                              <label className="control-label">
                                {__t("widgets")}
                              </label>
                              <Select
                                value={this.state.selectedOption}
                                isMulti
                                onChange={this.multiHandleChange}
                                options={this.state.options}
                              />
                            </div>

                            {/* <div className="form-group">
    <label className="control-label required-field">
      {__t("type")}
    </label>
    <select
      name="type"
      className="form-control"
      value={this.state.type}
      onChange={this.handleChange}
    >
      <option value="">--Select Type--</option>
      <option value="client">Client</option>
      <option value="reseller">Reseller</option>
    </select>
    <label id="-error" className="error">
      {this.state.errors["type"]}
    </label>
  </div> */}
                          </div>
                          <div className="col-md-6 col-sm-6 col-xs-12">
                            <div className="form-group">
                              <label className="control-label">
                                {__t("contact_lists")}
                              </label>

                              <Select
                                value={this.state.contact_list}
                                isMulti
                                onChange={this.multiHandleChangeList}
                                options={this.state.list_options}
                                name="contact_list"
                              />
                            </div>
                          </div>
                          <div className="col-md-6 col-sm-6 col-xs-12">
                            <div className="form-group">
                              <label className="control-label required-field">
                                {__t("status")}
                              </label>
                              <select
                                name="status"
                                className="form-control"
                                value={this.state.status}
                                onChange={this.handleChange}
                              >
                                <option value="">--Select Status--</option>
                                <option value="active">Active</option>
                                <option value="inactive">Inactive</option>
                              </select>
                              <label id="-error" className="error">
                                {this.state.errors["status"]}
                              </label>
                            </div>
                          </div>
                        </div>
                        <div className="row form-group">
                          <div className="col-md-12">
                            <button
                              className="btn btn-primary"
                              onClick={this.create}
                              type="submit"
                            >
                              {this.props.location.state &&
                              typeof this.props.location.state !== "undefined"
                                ? "Update"
                                : "Create"}
                            </button>{" "}
                            &nbsp;&nbsp;
                            <Link className="btn btn-white" to="/subscription">
                              {__t("cancel")}
                            </Link>
                          </div>
                        </div>
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Can>
    );
  }
}

export default SubscriptionUpdate;
