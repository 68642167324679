import $ from 'jquery';
import { __t } from "../../locale/language";
import React from 'react';
class Alert extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            currentURL: null
        }
        this.close = this.close.bind(this);
    
    }

    // alertPrompt(msg) {
    //     console.log(msg)
    //   //  $('.alert_oerlay').css("display","block")
    //     $('#alert').show();
    //     $('#alert_prompt_msg').text(msg);
    //     // setTimeout(function () {
    //     //   $('.alert_oerlay').hide();
    //     // }, 3000)

    // }

    close() {
        $('.alert_oerlay').hide();
    }

    render() {
       
        return (

            <div className="alert_oerlay" id="alert" style={{ "display": "none" }}>

                <div className="alert_box danger_back">
                    <i className="fa fa-exclamation-triangle danger_txt fa_alert"></i>
                    <h3 id="alert_prompt_msg"> {this.props.alertmessage} </h3>
                    <button className="btn btn-success danger_btn" onClick={this.close}>{__t("ok")}</button>
                </div>
            </div>

        )
    }
}
export default Alert;