import React from "react";
import { Link } from "react-router-dom";
import RemotePagination from "../common/Table";
import Utility from "../common/Utility";
import { __t } from "../../locale/language";
import Can from "../common/AccessControl";
import Moment from "moment";
import $ from "jquery";
import CommonDelete from "../common/CommonDelete"
class RoleList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      page: 1,
      data: [],
      sizePerPage: 10,
      total: 0,
      search: "",
      client_id: localStorage.getItem("client_id"),
      user_type: this.props.userPermissions.user_type,
      delete_id: ""
    };
    this.deleteRole=this.deleteRole.bind(this)
    this.delete_cnferm = this.delete_cnferm.bind(this);
    this.delete = this.delete.bind(this);
  }
  handleChange = event => {
    this.setState({ [event.target.name]: event.target.value });
  };
  delete_cnferm(type) {
    //console.log(type)
    //console.log(this.state.delete_id)
    if (type == "yes") {
      this.deleteRole(this.state.delete_id)
    }
    else {
      $("#alertshow").hide();
    }
  }
  delete(id) {
    this.setState({
      delete_id: id
    }, () => {
      $("#alertshow").css("display", "block")
    })
  }

  deleteRole(id){
    
    id={"id":id}
    Utility.ExecuteData("deleterole", this.token, id)
    .then(responseJson => {
      //console.log(responseJson)
      if (responseJson.code === 200) {
        //console.log(responseJson)
        this.updateRoleList(1, 10);
       // this.setState({ redirect: true });
      } else {
        alert(__t("try_again"));
      }
    })
    .catch(error => {
      this.setState({ errors: true });
    });
  }
  renderButtons(cell, row, rowIndex) {
    //console.log(row)
    var btn;
    if (row.title == "admin" || row.title == "Admin") {
      //btn = <span />;

      btn = (
        <Link
          to={{
            pathname: `/role/update/${row.id}`,
            state: { id: `${row.id}` }
          }}
          className="btn btn-primary btn-sm" style={{"marginRight":"10px"}}
        >
          <i className="fa fa-pencil" aria-hidden="true" />
        </Link>
      );
      
    } else {
      btn = (
        <Link
          to={{
            pathname: `/role/update/${row.id}`,
            state: { id: `${row.id}` }
          }}
          className="btn btn-primary btn-sm" style={{"marginRight":"10px"}}
        >
          <i className="fa fa-pencil" aria-hidden="true" />
        </Link>
      );
    }
    return (
      <span>
        <Can
          I={["view-role"]}
          userPermissions={this.props.userPermissions.userPermissions}
        >
          {btn}
        </Can>
       
      {row.title !=="admin" || row.title=="Admin"?  <Can
          I={["delete-role"]}
          userPermissions={this.props.userPermissions.userPermissions}
        >
          {
            row.client_id!=this.state.client_id?
            <button  onClick={()=>this.delete(`${row.id}`)} 
              className="btn btn-primary btn-sm"
            >
              <i className="fa fa-trash" aria-hidden="true" />
            </button>
            :""
          }
            
        </Can>:""}
      </span>
    );
  }
  client_id = localStorage.getItem("client_id");
  token = localStorage.getItem("token");
  handleTableChange = (type, { page, sizePerPage }) => {
    const currentIndex = (page - 1) * sizePerPage;
    //write fetch data function
    this.updateRoleList(page, sizePerPage);
  };
  updateRoleList = (page = 1, sizePerPage = 10) => {
    $("#alertshow").hide();
    Utility.ExecuteData("roledata", this.token, {
      page: page,
      limit: sizePerPage,
      search: this.state.search,
      client_id: this.client_id
    }).then(data => {
      this.setState(() => ({
        page,
        data: data.data.rows,
        sizePerPage,
        total: data.data.count
      }));
    });
  };
  componentDidMount() {
    this.updateRoleList(1, 10);
  }
  render() {
    const { data, sizePerPage, page, total } = this.state;
    const columns = [
      { dataField: "title", text: __t("title") },
      { dataField: "description", text: __t("description") },
      { dataField: "status", text: __t("status") },
      // {
      //   dataField: "created_at",
      //   text: __t("created_at"),
      //   formatter: cellContent =>
      //     Moment(cellContent).format("DD-MMM-YYYY HH:mm:ss")
      // },
      {
        dataField: "updated_at",
        text: __t("updated_at"),
        formatter: cellContent =>
          Moment(cellContent).format("DD-MMM-YYYY HH:mm:ss")
      },
      {
        dataField: "action",
        text: "Action",
        formatter: this.renderButtons.bind(this)
      }
    ];
    return (
      <Can
        I={["view-role"]}
        userPermissions={this.props.userPermissions.userPermissions}
      >
        <div>
        <CommonDelete delete_cnferm={this.delete_cnferm} />
          <div className="row wrapper border-bottom white-bg page-heading">
            <div className="col-lg-10">
              <h2 style={{ marginTop: 10 }}> {__t("role_list")} </h2>
              <ol className="breadcrumb">
                <li className="breadcrumb-item">
                  <Link to="/">{__t("dashboard")}</Link>
                </li>
                <li className="breadcrumb-item">
                  <Link to="/role"> {__t("role")}</Link>
                </li>
                <li className="breadcrumb-item active">
                  <strong> {__t("role_list")}</strong>
                </li>
              </ol>
            </div>
            <div className="col-lg-2" />
          </div>
          <div className="wrapper wrapper-content animated fadeInRight">
            <div className="row">
              <div className="col-lg-12 ">
                <div className="ibox ">
                  <div className="ibox-title">
                    <div className="row from-control">
                      &nbsp; &nbsp; &nbsp;
                      <input
                        className="form-control col-md-3"
                        type="text"
                        name="search"
                        id="search_field"
                        onChange={this.handleChange}
                        placeholder={__t("search_by_title")}
                      />
                      &nbsp;
                      <input
                        className="btn btn-primary"
                        type="button"
                        onClick={() => {
                          this.updateRoleList();
                        }}
                        name="btn_search"
                        value={__t("search")}
                      />
                      &nbsp;
                      <input
                        className="btn btn-warning"
                        type="button"
                        onClick={() => {
                          this.setState({ search: "" }, () => {
                            this.updateRoleList();
                            document.getElementById("search_field").value = "";
                          });
                        }}
                        name="btn_search"
                        value={__t("clear")}
                      />
                      &nbsp;
                      {this.props.userPermissions.subscription_status ==
                        "active" ||
                      this.state.user_type == "admin" ||
                      this.state.user_type == "reseller" ? (
                        <Can
                          I={["create-role"]}
                          userPermissions={
                            this.props.userPermissions.userPermissions
                          }
                        >
                          <Link className="btn btn-primary" to="/role/update">
                            {__t("create_role")}
                          </Link>
                        </Can>
                      ) : (
                        ""
                      )}
                      
                      <br />
                      &nbsp; &nbsp;
                      <strong style={{ marginTop: "10px" }}>
                        {__t("total_roles")} : {this.state.total}
                      </strong>
                    </div>
                  </div>
                  <div className="ibox-content">
                    {this.state.total > 0 ? (
                      <RemotePagination
                        columns={columns}
                        data={data}
                        page={page}
                        sizePerPage={sizePerPage}
                        totalSize={total}
                        onTableChange={this.handleTableChange}
                      />
                    ) : (
                      __t("no_record_found")
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Can>
    );
  }
}

export default RoleList;
