import React from "react";
import { Link } from "react-router-dom";
import Moment from "moment";
import $ from "jquery";

import Utility from "../common/Utility";
import { __t } from "../../locale/language";
import Can from "../common/AccessControl";
import CommonDelete from "../common/CommonDelete";
import RemotePagination from "../common/Table";
import { AccessControlFunc } from "../common/AccessControl2";

const pagesize = 10;

class LetterVariationList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      page: 1,
      data: [],
      sizePerPage: pagesize,
      total: 0,
      search: "",
      client_id: localStorage.getItem("client_id"),
      user_type: this.props.userPermissions.user_type,
      subscription_feature: this.props.userPermissions.subscription_feature,
      delete_id: "",
    };
  }
  client_id = localStorage.getItem("client_id");
  token = localStorage.getItem("token");

  componentDidMount() {
    this.letterVariationList(1);
  }
  letterVariationList = (page = 1, sizePerPage = this.state.sizePerPage) => {
    $("#alertshow").hide();
    Utility.ExecuteData("lettervarlist", this.token, {
      page: page,
      limit: sizePerPage,
      search: this.state.search,
      client_id: this.state.client_id,
    }).then((data) => {
      let tempArr = [];
      for (let i = 0; i < data.data.rows.length; i++) {
        let el = data.data.rows[i];
        el.status = el.status ? "Active" : "In-active";
        tempArr.push(el);
      }
      this.setState(() => ({
        page,
        data: tempArr,
        sizePerPage,
        total: data.data.count,
      }));
    });
  };

  delete_cnferm = (type) => {
    if (type == "yes") {
      this.deleteWidget(this.state.delete_id);
    } else {
      $("#alertshow").hide();
    }
  };
  delete = (id) => {
    this.setState(
      {
        delete_id: id,
      },
      () => {
        $("#alertshow").css("display", "block");
      }
    );
  };
  deleteWidget = (id) => {
    id = { id: id };
    Utility.ExecuteData("deletelettervariation", this.token, id)
      .then((responseJson) => {
        if (responseJson.code === 200) {
          this.letterVariationList(this.state.page, this.state.sizePerPage);
          // this.setState({ redirect: true });
        } else {
          alert(__t("try_again"));
        }
      })
      .catch((error) => {
        this.setState({ errors: true });
      });
  };
  handleChange = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };
  renderButtons = (cell, row, rowIndex) => {
    return (
      <span>
        <Can
          I={["update-widget"]}
          userPermissions={this.props.userPermissions.userPermissions}
        >
          <Link
            to={{
              pathname: `/letter-variations/update/${row.id}`,
              state: { id: `${row.id}` },
            }}
            className="btn btn-primary btn-sm"
            style={{ marginRight: "10px" }}
          >
            <i className="fa fa-pencil" aria-hidden="true" />
          </Link>
        </Can>
        <Can
          I={["delete-widget"]}
          userPermissions={this.props.userPermissions.userPermissions}
        >
          <button
            onClick={() => this.delete(`${row.id}`)}
            className="btn btn-primary btn-sm"
          >
            <i className="fa fa-trash" aria-hidden="true" />
          </button>
        </Can>
      </span>
    );
  };
  handleTableChange = (type, { page, sizePerPage }) => {
    const currentIndex = (page - 1) * sizePerPage;
    this.letterVariationList(page, sizePerPage);
  };

  render() {
    const { data, sizePerPage, page, total } = this.state;
    const columns = [
      { dataField: "title", text: __t("title") },
      { dataField: "subject", text: __t("Subject") },
      {
        dataField: "created_at",
        text: __t("created_at"),
        formatter: (cellContent) =>
          Moment(cellContent).format("DD-MMM-YYYY HH:mm:ss"),
      },
      {
        dataField: "updated_at",
        text: __t("updated_at"),
        formatter: (cellContent) =>
          Moment(cellContent).format("DD-MMM-YYYY HH:mm:ss"),
      },
      {
        dataField: "action",
        text: "Action",
        formatter: this.renderButtons.bind(this),
      },
    ];

    return AccessControlFunc(["update-user"], this.props.userPermissions, [
      "letter_autoresponder",
      "newsletter_autoresponder",
    ]) ? (
      <div>
        <CommonDelete delete_cnferm={this.delete_cnferm} />
        {/* this.state.user_type == "admin" || */}
        {/* this.state.user_type == "reseller" */}
        {/* this.state.user_type == "client" */}
        <div>
          <div className="row wrapper border-bottom white-bg page-heading">
            <div className="col-lg-10">
              <h2 style={{ marginTop: 10 }}>{__t("Letter Variation List")}</h2>
              <ol className="breadcrumb">
                <li className="breadcrumb-item">
                  <Link to="/">{__t("dashboard")}</Link>
                </li>
                <li className="breadcrumb-item active">
                  <strong>{__t("Letter Variation List")}</strong>
                </li>
              </ol>
            </div>
          </div>
          <div className="wrapper wrapper-content animated fadeInRight">
            <div className="row">
              <div className="col-lg-12 ">
                <div className="ibox ">
                  <div className="ibox-title">
                    <div className="row from-control">
                      &nbsp; &nbsp; &nbsp;
                      <input
                        className="form-control col-md-3"
                        type="text"
                        name="search"
                        id="search_field"
                        onChange={this.handleChange}
                        placeholder={__t("search_by_title")}
                      />
                      &nbsp;
                      <input
                        className="btn btn-primary"
                        type="button"
                        onClick={() => {
                          this.letterVariationList();
                        }}
                        name="btn_search"
                        value={__t("search")}
                      />
                      &nbsp;
                      <input
                        className="btn btn-warning"
                        type="button"
                        onClick={() => {
                          this.setState({ search: "" }, () => {
                            this.letterVariationList();
                            document.getElementById("search_field").value = "";
                          });
                        }}
                        name="btn_search"
                        value={__t("Clear")}
                      />
                      &nbsp;
                      <Can
                        I={["create-widget"]}
                        userPermissions={
                          this.props.userPermissions.userPermissions
                        }
                      >
                        <Link
                          className="btn btn-primary"
                          to="/letter-variations/create"
                        >
                          {__t("Create Letter Variations")}
                        </Link>
                      </Can>
                      <br />
                    </div>
                  </div>
                  <div className="ibox-content">
                    {this.state.total > 0 ? (
                      <RemotePagination
                        columns={columns}
                        data={data}
                        page={page}
                        sizePerPage={sizePerPage}
                        totalSize={total}
                        onTableChange={this.handleTableChange}
                      />
                    ) : (
                      <div>{__t("no_record_found")}</div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    ) : (
      ""
    );
  }
}

export default LetterVariationList;
