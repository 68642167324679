import React, { Component } from "react";
import { Redirect, Link } from "react-router-dom";
import Utility from "../common/Utility";
import { __language, __t } from "../../locale/language";
import $ from "jquery";

import SweetAlert4 from "react-bootstrap-sweetalert";

class Login extends Component {
  constructor(props, context) {
    super(props);
    this.state = {
      redirect: false,
      errors: {},
      lang: "en",
      message: "",
      email_login: "",
      password_login: "",
      redirectParam: "",
      err_msg: "",
      loginAttempts: 0,
      accountLocked: false,
      accountLockedMessage: false,
    };
    this.login = this.login.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.changeLanguage = this.changeLanguage.bind(this);
    this.accountLockedMessageHide = this.accountLockedMessageHide.bind(this);
    localStorage.setItem("language", "English");
  }
  changeLanguage = (event) => {
    localStorage.setItem("language", event.target.value);
    this.setState({ lang: event.target.value });
  };
  componentWillMount() {
    if (typeof this.props.match.params.token != "undefined") {
      this.verifyToken(this.props.match.params.token);
    }
  }
  verifyToken = async (token) => {
    let res = await Utility.executeRequest(
      "logindashbord",
      { token: token },
      token
    );
    if (res.code == 200) {
      localStorage.setItem("token", res.token);
      localStorage.setItem("client_id", res.user_data.client_id);
      localStorage.setItem("lgn_dtl", JSON.stringify(res.user_data));

      const searchParams = new URLSearchParams(this.props.location.search);
      const redirectParam = searchParams.get("redirect");
      this.setState({
        redirect: true,
        redirectParam: redirectParam,
      });
    } else {
      this.setState({
        err_msg: res.err_msg,
      });
    }
    // Utility.ExecuteData("logindashbord", token, {
    //   token: token
    // }).then(res => {

    // });
  };
  handleValidation() {
    let fields = this.state;
    let errors = {};
    let formIsValid = true;
    var msg_status = this.state.message;

    if (fields.errors === "not valid") {
      formIsValid = false;
      errors["password_login"] = __t("message_email_pwd_empty");
    }
    if (fields.errors === "inactive status") {
      formIsValid = false;
      errors["status_inactive"] =
        __t("your ") +
        msg_status +
        "," +
        __t("plaese_contact_to_website_administrator");
    }
    //password
    if (!fields["password_login"]) {
      formIsValid = false;
      errors["password_login"] = __t("message_pwd_empty");
    }

    //Email
    if (typeof fields["email_login"] !== "undefined") {
      let lastAtPos = fields["email_login"].lastIndexOf("@");
      let lastDotPos = fields["email_login"].lastIndexOf(".");

      if (
        !(
          lastAtPos < lastDotPos &&
          lastAtPos > 0 &&
          fields["email_login"].indexOf("@@") == -1 &&
          lastDotPos > 2 &&
          fields["email_login"].length - lastDotPos > 2
        )
      ) {
        formIsValid = false;
        errors["email_login"] = __t("chk_valid_email");
      }
    }
    if (!fields["email_login"]) {
      formIsValid = false;
      errors["email_login"] = __t("chk_empty_email");
    }

    this.setState({ errors: errors });
    return formIsValid;
  }
  async login() {
    // for account lock
    const email = this.state.email_login;
    const accountLocked =
      localStorage.getItem(`accountLocked_${email}`) === "true";
    const lockTimestamp = parseInt(
      localStorage.getItem(`accountLockTimestamp_${email}`),
      10
    );
    const lockDuration = 1 * 60 * 1000; // 1 minute in milliseconds
    const currentTime = Date.now();

    if (accountLocked && currentTime - lockTimestamp < lockDuration) {
      // If account is already locked, show the account locked popup
      this.setState({ accountLockedMessage: true });
      return;
    }

    if (this.handleValidation()) {
      let host = window.location.host;
      let res = await Utility.executeRequest("login", {
        email: this.state.email_login,
        pass: this.state.password_login,
        host: host,
      });

      if (res.code == 200) {
        localStorage.setItem("token", res.token);
        localStorage.setItem("client_id", res.user_data.client_id);
        localStorage.setItem("lgn_dtl", JSON.stringify(res.user_data));
        this.setState({
          redirect: true,
        });
      } else {
        // Handle invalid login
        this.setState((prevState) => ({
          loginAttempts: prevState.loginAttempts + 1,
        }));

        if (this.state.loginAttempts >= 3) {
          // Lock the account for 30 minutes
          localStorage.setItem(`accountLocked_${email}`, "true");
          localStorage.setItem(`accountLockTimestamp_${email}`, Date.now());
          this.setState({
            accountLockedMessage: true,
          });

          setTimeout(() => {
            localStorage.removeItem(`accountLocked_${email}`);
            localStorage.removeItem(`accountLockTimestamp_${email}`);
            this.setState({
              loginAttempts: 0,
              accountLockedMessage: false,
            });
          }, 1 * 60 * 1000);
        } else {
          this.setState({
            err_msg: res.err_msg,
          });
        }
      }
    }
  }
  handleChange(event) {
    this.setState({ [event.target.name]: event.target.value });
  }
  accountLockedMessageHide() {
    this.setState({
      accountLockedMessage: false,
    });
  }

  render() {
    const { redirect, redirectParam } = this.state;
    if (redirectParam) {
      const userID = redirectParam.split("/")[2];
      // const redirectUrl = `https://admin.pushpolitics.org/user/update/${userID}`;
      const redirectUrl = `/user/update/${userID}`;

      return <Redirect to={redirectUrl} />;
    }

    var host = window.location.hostname;
    const content = {
      "admin.pushpolitics.org": {
        title: "Push Politics Ca",
        logo: "https://www.pushpolitics.org/Assets/images/pp33.png",
        description: "",
      },
      "admin.csaeconnect.ca": {
        title: "CSAE",
        logo: "https://www.csaeconnect.ca/Assets/images/output-onlinepngtools.png",
        description: "",
      },
      localhost: {
        title: "Push Politics Ca",
        logo: "https://www.pushpolitics.org/Assets/images/pp33.png",
        description: "",
      },
    };
    if (redirect)
      return (
        <Redirect
          to={{
            pathname: "/dashboard",
            state: { user_type: this.state.user_type },
          }}
        />
      );

    return (
      <div>
        <div className="middle-box text-center loginscreen animated fadeInDown">
          <div>
            <div>
              <h1 className="logo-name">
                <img src={content[host].logo} style={{ width: "100%" }} />
              </h1>
            </div>
            <h3>Welcome to {content[host].title}</h3>

            <p>Log in to see it in action.</p>

            <div className="form-group">
              <input
                type="email"
                name="email_login"
                className="form-control"
                placeholder="Email"
                required
                onChange={this.handleChange}
              />
            </div>
            <span style={{ color: "red" }}>
              {this.state.errors["email_login"]}
            </span>
            <div className="form-group">
              <input
                type="password"
                name="password_login"
                className="form-control"
                placeholder="Password"
                required=""
                onChange={this.handleChange}
              />
            </div>
            <span style={{ color: "red" }}>
              {this.state.errors["password_login"]}
            </span>
            <button
              type="submit"
              className="btn btn-primary block full-width m-b"
              onClick={this.login}
            >
              Login
            </button>
            <span style={{ color: "red" }}>
              {this.state.errors["status_inactive"]}
              {__t(this.state.err_msg)}
            </span>
            <br />
            {/* <Link to="/forget-password">
            <small>Forgot password?</small>
          </Link> */}
            {__language(this.changeLanguage)}
            <p className="m-t">
              {" "}
              <small>{content[host].title} &copy; 2018</small>{" "}
            </p>
          </div>
        </div>
        <SweetAlert4
          type="warning"
          title="Account Locked"
          show={this.state.accountLockedMessage}
          onConfirm={this.accountLockedMessageHide}
        >
          Due to multiple invalid attempts, you account is locked and you are
          requested to wait for 30 minutes to login again
        </SweetAlert4>
      </div>
    );
  }

  componentDidMount() {
    $("body").addClass("gray-bg");
    setTimeout(function () {
      $("body").removeClass("sk-loading");
    }, 2000);
  }

  componentWillUnmount() {
    $("body").removeClass("gray-bg");
  }
}

export default Login;
