import React from "react";
import PaymentgatewayList from "./PaymentgatewayList";
import PaymentgatewayUpdate from "./PaymentgatewayUpdate";
import { Route, Switch } from "react-router-dom";
class Index extends React.Component {
  constructor(props) {
    super(props);
  }
  render() {
    return (
      <Switch>
        <Route
          path="/paymentgateway/create"
          render={props => (
            <PaymentgatewayUpdate
              {...props}
              userPermissions={this.props.userPermissions}
            />
          )}
        />
        <Route
          path="/paymentgateway/create/"
          render={props => (
            <PaymentgatewayUpdate
              {...props}
              userPermissions={this.props.userPermissions}
            />
          )}
        />
        {/* <Route
          path="/paymentgateway/update/:id"
          render={props => (
            <PaymentgatewayList
              {...props}
              userPermissions={this.props.userPermissions}
            />
          )}
        /> */}
        <Route
          path="/paymentgateway"
          render={props => (
            <PaymentgatewayUpdate
              {...props}
              userPermissions={this.props.userPermissions}
            />
          )}
        />
      </Switch>
    );
  }
}

export default Index;
