import React from "react";
import { Link } from "react-router-dom";
import RemotePagination from "../common/Table";
import Utility from "../common/Utility";
import { __t } from "../../locale/language";
import Can from "../common/AccessControl";
import AssetstList from "./AssetsList";
import { Route, Switch } from "react-router-dom";


class Index extends React.Component {
  constructor(props) {
   //console.log("dvbchd")
    super(props);
   
  }
  render() {
    return (
      <Switch>
    <Route
            path="/assets"
            render={props => (
              <AssetstList 
                {...props}
                userPermissions={this.props.userPermissions}
              />
            )}
          />
       {/* <Route
            component={Alertyesno}
          />
          <Route
            component={Alertinfo}
          />
          <Route
            component={AlertDelete}
          /> */}
          

      </Switch>
    );
  }
}

export default Index;
