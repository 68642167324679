export const CURRENCY = () => {
  return "cad";
  // return "USD";
};

export const bytesToSize = (bytes) => {
  var sizes = ["Bytes", "KB", "MB", "GB", "TB"];
  if (bytes == 0) return "0 Byte";
  var i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)));
  return Math.round(bytes / Math.pow(1024, i), 2) + " " + sizes[i];
};

export const fileFullBasePath = () => {
  let path = "https://admin.pushpolitics.org/upload/";
  let hostname = window.location ? window.location.hostname : "";
  if (hostname && hostname.indexOf("csaeconnect.ca") != -1) {
    path = "https://admin.csaeconnect.ca/upload/";
  }
  return path;
};
