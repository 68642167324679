import React, { Component } from "react";
import Utility from "../common/Utility";
import { Redirect, Link } from "react-router-dom";
import { Button, ButtonGroup } from "reactstrap";
import "../../custom.css";
import SubscriptionAlert from "../common/SubscriptionAlert";
import { __t } from "../../locale/language";
import SelectImageModal from "./mediaModal";
import ReactQuill from "react-quill";
import $ from "jquery";
var features = [];
var client_data;
var url;

var defaultImage;
class Template extends Component {
  constructor(props) {
    super(props);
    this.state = {
      template: "",
      client_id: localStorage.getItem("client_id"),
      user_id: this.props.userPermissions.user_id,
      user_type: this.props.userPermissions.user_type,
      token: localStorage.getItem("token"),
      subscription_feature: this.props.userPermissions.subscription_feature,
      subscription_status: this.props.userPermissions.subscription_status,
      name: "",
      logo: "",
      short_description: "",
      long_description: "",
      modal: false,
      mediaModal: false,
      edititem: {},
      errors: {},
      showdata: "",
      rawResult: [],
      redirect: false,
    };
    this.getActivate = this.getActivate.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.submit_data = this.submit_data.bind(this);
    this.mediaToggle = this.mediaToggle.bind(this);
    this.setImageUrl = this.setImageUrl.bind(this);
    this.checkValidation = this.checkValidation.bind(this);
    this.createTemplate = this.createTemplate.bind(this);
    this.editorChange = this.editorChange.bind(this);
    this.editorChange2 = this.editorChange2.bind(this);
    this.getTemplateByType = this.getTemplateByType.bind(this);
  }
  getActivate(temp_id, temp_status) {
    // return false
    if (temp_status == "inactive") {
      temp_status = "active";

      Utility.ExecuteData("UpdateTemplateStatus", this.state.token, {
        id: temp_id,
        status: temp_status,
        client_id: this.state.client_id,
        user_id: this.state.user_id
      }).then(result => {
        if (result.data == "subdomain missing") {
          $("#msg_text").text(__t("subdomain_missing_please_complete_your_profile"));
          $("#alertinfo").show();
          this.setState({
            redirect: true,
          })

        } else {
          this.getTemplate();
        }
      });
    } else {
      return false;
    }
  }
  componentDidMount() {
    //console.log(this.state.logo )
    // if (this.state.logo == "") {
    //   $("#edit_box").hide()
    // }
    // else{
    //   $("#edit_box").show()
    // }
    var default_url = "/img/";
    defaultImage = default_url + "default_image.png";
    if ((this.state.subscription_feature.includes("subdomain") && this.state.user_type == "client") || (this.state.user_type == "admin" || this.state.user_type == "reseller")) {
      this.getTemplate();
    } else {
      $("#subs_text").text(__t("please_upgrade_your_plan"));
      $("#subs_alert").show();
    }
  }
  shouldComponentUpdate() {
    return true;
  }
  setImageUrl(url) {
    this.setState({
      logo: url
    });

    url = url;
  }
  handleChange(event) {
    this.setState({ [event.target.name]: event.target.value });
  }
  submit_data() {
    if (this.checkValidation()) {
      Utility.ExecuteData("clientdataupdate", this.state.token, this.state)
        .then(responseJson => {
          if (responseJson.code === 200) {
            $("#edit_box").show();
            this.setState({
              showdata: "true"
            });
          } else {
            alert(__t("something_went_wrong_please_try_again"));
          }
        })
        .catch(error => {
          this.setState({ errors: true });
        });
    }
  }
  checkValidation() {
    let fields = this.state;
    let errors = {};
    let formIsValid = true;
    //console.log(fields);

    if (!fields["name"]) {
      formIsValid = false;
      errors["name"] = __t("name_cannot_be_empty");
    }
    // if (this.state.logo == defaultImage) {
    //   formIsValid = false;
    //   errors["logo"] = __t("please_upload_logo");
    // }
    if (!fields["short_description"] || fields.short_description == "<p><br></p>") {
      formIsValid = false;
      errors["short_description"] = __t("short_description_cannot_be_empty");
    }

    if (!fields["long_description"] || fields.long_description == "<p><br></p>") {
      formIsValid = false;
      errors["long_description"] = __t("long_description_cannot_be_empty");
    }

    this.setState({ errors: errors });
    return formIsValid;
  }
  mediaToggle() {
    this.setState({
      modal: !this.state.modal,
      mediaModal: !this.state.mediaModal
    });
  }

  editorChange = value => {
    this.setState({ short_description: value });
  };

  editorChange2 = value => {
    this.setState({ long_description: value });
  };

  getTemplate() {
    var upgrade_button = "";
    var edit_button = "";

    // let default_url = "/img/";
    // defaultImage = default_url + "default_image.png";

    Utility.ExecuteData("template", this.state.token, {
      client_id: this.state.client_id,
      user_id: this.props.userPermissions.user_id,
      user_type: this.props.userPermissions.user_type
      //  type:type
    }).then(result => {
      if (result.code === 200) {

        client_data = result.c_data;
        //console.log(client_data);
        if (
          client_data &&
          this.state.user_type == "client" &&
          (client_data[0].logo == null ||
            client_data[0].logo == "" ||
            (client_data[0].long_description == null || client_data[0].long_description == "") ||
            (client_data[0].short_description == null || client_data[0].short_description == ""))
        ) {
          this.setState({
            showdata: "false"
          }, () => {
            $("#edit_box").hide()
            $("#back").hide()
          });
        } else {
          this.setState({
            showdata: "true"
          }, () => {
            $("#edit_box").show()
          });
        }
        if (client_data) {
          this.setState({
            name: client_data[0].name,
            logo: client_data[0].logo == "" || client_data[0].logo == null ? defaultImage : client_data[0].logo,
            short_description: client_data[0].short_description,
            long_description: client_data[0].long_description
          });
        }

        const tempItems = result.data.map(temp => {
          edit_button = (
            <Link to={"/template/" + temp.id} className="txt_white ">
              <button className="btn btn-success  mright10 padding_btn">{__t("edit")}</button>
            </Link>
          );

          if (temp.client_id == this.state.client_id) {
            if (this.state.subscription_feature.includes("premium_template")) {
              upgrade_button = (
                <button className={temp.status == "active" ? "btn mright10 padding_btn btn_green" : "btn btn-success  mright10 padding_btn"} onClick={() => this.getActivate(temp.id, temp.status)}>
                  {temp.status == "active" ? "Activated" : "Activate"}
                </button>
              );
            } else if (this.state.subscription_feature.includes("free_template")) {
              if (temp.type == "premium") {
                upgrade_button = (
                  <button
                    className={temp.status == "active" ? "btn mright10 padding_btn btn_green" : "btn btn-success  mright10 padding_btn"}

                  // onClick={() => this.getActivate(temp.id)}
                  >
                    {temp.status != "active" ? (
                      <a href="http://pushpolitics.org/#" className="txt_white">
                        {temp.status == "active" ? "Activated" : "Upgrade"}
                      </a>
                    ) : (
                        <a href="" className="txt_white">
                          {temp.status == "active" ? "Activated" : "Upgrade"}
                        </a>
                      )}
                  </button>
                );
              } else if (temp.type == "free") {
                upgrade_button = (
                  <button className={temp.status == "active" ? "btn mright10 padding_btn btn_green" : "btn btn-success  mright10 padding_btn"} onClick={() => this.getActivate(temp.id, temp.status)}>
                    {temp.status == "active" ? "Activated" : "Activate"}
                  </button>
                );
              } else if (temp.type == "custom") {

                upgrade_button = (
                  <button className={temp.status == "active" ? "btn mright10 padding_btn btn_green" : "btn btn-success  mright10 padding_btn"} onClick={() => this.getActivate(temp.id, temp.status)}>
                    {temp.status == "active" ? "Activated" : "Activate"}
                  </button>
                );


              }
            }
          }

          return (
            <div key={temp.id} id="test" className={"equalHMV eq test _" + temp.type}>
              <div className="pos_rel ht300" className={temp.type}>
                <img src={temp.thumb} className="templets_img" />
                <div className="preview2_img_abs_temp">
                  {((temp.type == "free" || temp.type == "custom" || (temp.type == "premium" && this.state.subscription_feature.includes("premium_template"))) &&
                    this.state.subscription_status == "active") ||
                    (this.state.user_type == "admin" || this.state.user_type == "reseller") ? (
                      <span>{edit_button}</span>
                    ) : (
                      ""
                    )}
                </div>

                <div className={temp.status == "active" ? "flx_al_center border_top_gray activate_btn_box status_class" : "flx_al_center border_top_gray activate_btn_box"}>
                  <label className="templet_prev_label mleft10 m-0">
                    <strong className={temp.status == "active" ? "txt_white" : "txt_black"}>{temp.title}</strong>
                  </label>{" "}
                  <div className="row_flex1 templet_prev_label">
                    {this.props.userPermissions.user_type == "admin" || this.props.userPermissions.user_type == "reseller" ? (
                      <div className="flex_center">
                        <strong className="p-1">{temp.type}</strong>
                        {/* {temp.type=="premium"?<button className="btn mright10 padding_btn btn-success"><Link to={"/checkout/" + temp.id} className="txt_white">
                      buy now
                    </Link></button>:''} */}
                      </div>
                    ) : (
                        <div>
                          <strong className="p-1">{temp.type}</strong>
                          {temp.client_id != "1" && this.state.subscription_status == "active" ? <span>{upgrade_button}</span> : ""}
                        </div>
                      )}

                    {upgrade_button == "" || temp.client_id == "1" ? edit_button : ""}
                  </div>
                </div>
              </div>
            </div>
          );
        });
        this.setState({
          template: tempItems
        });
      }
    });
  }
  createTemplate() { }
  getTemplateByType(type) {
    if (type == "all") {
      $(".test").css("display", "block");
    } else {
      $(".test").css("display", "none");
      $("._" + type).css("display", "block");
    }
  }
  edit_profile = () => {
    Utility.ExecuteData("get_client_data", this.state.token, {
      client_id: this.state.client_id
    }).then(data => {
      $("#edit_box").hide();
      this.setState({
        showdata: "false",
        name: data.data.name,
        logo: data.data.logo,
        short_description: data.data.short_description,
        long_description: data.data.long_description
      });
    })

  }
  back = () => {
    this.setState({
      showdata: "true",
    }, () => {
      $("#edit_box").show()
    })

  }
  render() {
    const { redirect } = this.state;
    if (redirect) {
      return <Redirect push to="/settings" />;
    }
    return (
      <React.Fragment>
        <div className="row wrapper border-bottom white-bg page-heading">
          <div className="col-lg-10">
            <h2 style={{ marginTop: 10 }}>{__t("templates")}</h2>
            <ol className="breadcrumb">
              <li className="breadcrumb-item">
                <Link to="/templates">{__t("templates")}</Link>
              </li>
              <li className="breadcrumb-item">
                <Link to="/templates">{__t("View Template")}</Link>
              </li>
              {this.state.user_type == "admin" || this.state.user_type == "reseller" ? (
                <div className="tempbtn">
                  {" "}
                  <Link to={"/CreateTemplate/"} className="btn btn-success ml-3">
                    {__t("create")}
                  </Link>
                </div>
              ) : (
                  ""
                )}
              {/* <li className="breadcrumb-item active">
                <strong>{__t("client_list")}</strong>
              </li> */}
            </ol>
          </div>
        </div>
        <div className="ibox-title text-center" id="edit_box">

          {
            this.state.user_type == "client" ?
              <Button color="secondary" onClick={() => this.edit_profile()} className="mr-2" style={{ "float": "left" }}>
                {__t("edit_profile")}
              </Button>
              : ""
          }
          <ButtonGroup>
            <Button color="secondary" onClick={() => this.getTemplateByType("all")} className="mr-2">
              {__t("all")}
            </Button>
            <Button color="secondary" onClick={() => this.getTemplateByType("free")} className="mr-2">
              {__t("free")}
            </Button>
            <Button color="secondary" onClick={() => this.getTemplateByType("premium")} className="mr-2">
              {__t("premium")}
            </Button>
            {this.state.user_type == "client" ?
              <Button color="secondary" onClick={() => this.getTemplateByType("custom")} className="mr-2">
                {__t("custom")}
              </Button>
              : ""}

          </ButtonGroup>

        </div>

        <div className="pos_rel back_white pb50">
          {/* {this.state.user_type == "admin" ||
          this.state.user_type == "reseller" ? (
            <Link to={"/CreateTemplate/"} className="btn btn-primary ml-3">
              {__t("create")}
            </Link>
          ) : (
            ""
          )} */}

          <div className="paddingBlock">
            {this.state.showdata == "false" ? (
              <div className="row">
                <div className="col-md-12">
                  <div className="ibox float-e-margins">
                    <div className="ibox-title space_between_align">
                      <h5 id="title">{__t("complete_profile")}</h5>
                      <button className="btn btn-primary ml-3" id="back" onClick={this.back}>
                        {__t("back")}
                      </button>
                    </div>
                    <div className="ibox-content">
                      <div className="form-horizontal">
                        <div className="row form-group">
                          <div className="col-md-6 col-sm-6 col-xs-12">
                            <label className="control-label ">{__t("name")}</label>
                            <input type="text" name="name" autoComplete="off"
                              className="form-control" placeholder={__t("name")}
                              value={this.state.name} onChange={this.handleChange} />
                            <label id="-error" className="error">
                              {this.state.errors["name"]}
                            </label>
                          </div>

                          {/* <div className="col-md-6 col-sm-6 col-xs-12"> */}

                          {/* <ReactQuill
                              name="short_description"
                              className="form-control"
                              value={this.state.short_description}
                              onChange={this.editorChange}
                            /> 
                            </div> */}
                          <div className="col-md-12 col-sm-12 col-xs-12">
                            <label className="control-label required-field">{__t("short_description")}</label>
                            <ReactQuill className="form-control" value={this.state.short_description}
                              onChange={this.editorChange} />
                          </div>
                          <label id="-error" className="error">
                            {this.state.errors["short_description"]}
                          </label>

                          <div className="col-md-12 col-sm-12 col-xs-12">
                            <label className="control-label required-field">{__t("long_description")}</label>
                            <ReactQuill className="form-control" value={this.state.long_description} onChange={this.editorChange2} />
                            <label id="-error" className="error">
                              {this.state.errors["long_description"]}
                            </label>
                          </div>

                          <div className="col-md-12 col-sm-12 col-xs-12">
                            <div className="row">
                              <div className="col-sm-12 " style={{ float: "right", justifyContent: "flex-end" }}>
                                <label className="control-label">{__t("logo")}</label>
                                <img className="logo_img ml-2 no-guttor" src={this.state.logo} alt="alt" />
                                {/* <input
                                  type="text"
                                  name="logo"
                                  autoComplete="off"
                                  className="form-control"
                                  placeholder={__t("logo")}
                                  value={this.state.logo}
                                  onChange={this.handleChange}
                                /> */}
                                <label id="-error" className="error">
                                  {this.state.errors["logo"]}
                                </label>
                                <button className="btn btn-primary btn-sm ml-4" onClick={this.mediaToggle}>
                                  {__t("select_image")}
                                </button>   &nbsp;&nbsp;
                                <label style={{ "color": "#cc5965" }} >
                                  {__t("size_should_below_200kb")}
                                </label>
                              </div>
                              <div className="col-sm-8" />
                            </div>
                          </div>

                        </div>
                      </div>
                      <button className="btn btn-primary ml-3" type="submit" onClick={this.submit_data}>
                        {__t("submit")}
                      </button>


                    </div>
                  </div>
                </div>
                <SelectImageModal isOpen={this.state.mediaModal} mediaToggle={this.mediaToggle} setImageUrl={this.setImageUrl} />
              </div>
            ) : (
                <div>
                  {(this.state.subscription_feature.includes("subdomain") && this.state.user_type == "client") || (this.state.user_type == "admin" || this.state.user_type == "reseller") ? (
                    <div className="equalHMVWrap eqWrap">{this.state.template}</div>
                  ) : (
                      <SubscriptionAlert />
                    )}
                </div>
              )}
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default Template;
