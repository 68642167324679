import $ from 'jquery';
import { __t } from "../../locale/language";
import React from 'react';
class Alertinfo extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
         display:false  
        }
        this.close = this.close.bind(this);

    }
    close() {
        $("#err-detail").html("");
        $(".info_back").css("height","200px");
        $("#err-detail-block").css("display","none");
        $('#alertinfo').hide();
    }
    more = ()=>{
        this.setState({display : !this.state.display})
    }
    render() {
        return (

            <div className="alert_oerlay" id="alertinfo" style={{ "display":  "none"}}>

                <div className="alert_box info_back">
                    <i className="fa fa-info info_txt fa_alert"></i>
                    <h3 id="msg_text"></h3>
                    <div id={"err-detail-block"} style={{display : "none"}}>
                        <span onClick={this.more}  style={{textDecoration:"underline",fontWeight:"bold",cursor:"pointer"}}>More Detail</span>
                        <div id={"err-detail"} style={{ "display": this.state.display ? "block" : "none" }}></div>
                    </div>
                    <button className="btn btn-success info_btn" onClick={this.close}>{__t("ok")}</button>
                </div>
            </div>

        )
    }
}
export default Alertinfo;