import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import { Link } from "react-router-dom";
import Utility from "../common/Utility";
import { __t } from "../../locale/language";
import Can from "../common/AccessControl";
class PaymentgatewayUpdate extends Component {
  constructor(props) {
    super(props);
    this.state = {
      redirect: false,
      errors: {},
      paypal_client_id: "",
      paypal_secret_key: "",
      paypale_status: "",
      stripe_publishable_key: "",
      stripe_secret_key: "",
      stripe_status: "",
      google_api_key: "",
      pay_id: ""
    };
    this.handleChange = this.handleChange.bind(this);
  }
  componentDidMount() {
    this.findPaymentgateway();
  }
  findPaymentgateway = () => {
    Utility.ExecuteData(
      "findpaymentgateway",
      localStorage.getItem("token"),
      {}
    ).then(res => {
      if (res.data) {
        this.setState({
          pay_id: res.data.id,
          paypal_client_id: res.data.client_secret,
          paypal_secret_key: res.data.gateway_id,
          paypale_status: res.data.status,
          stripe_publishable_key: res.data.public_key_stripe,
          stripe_secret_key: res.data.secret_key_stripe,
          stripe_status: res.data.status_stripe,
          google_api_key: res.data.google_api
        });
      }
    });
  };
  handleValidation = () => {
    let fields = this.state;
    // return false
    let errors = {};
    let formIsValid = true;

    if (fields.user_type == "reseller") {
      if (fields.paymentGateway[0].google_api == "") {
        formIsValid = false;
        errors["google_api"] = __t("google_api_field");
      }
    }
    // else {

    if (fields["paypal_ac_id"]) {
      if (!fields["paypal_status"]) {
        formIsValid = false;
        errors["paypal_status"] = __t("select_status");
      }
    }
    if (fields["swipe_ac_id"]) {
      if (!fields["swipe_status"]) {
        formIsValid = false;
        errors["swipe_status"] = __t("select_status");
      }
    }
    // }
    this.setState({ errors: errors });
    return formIsValid;
  };

  handleChange(e) {
    this.setState({ [e.target.name]: e.target.value });
  }
  create = () => {
    if (this.handleValidation()) {
      //console.log(this.state);
      Utility.ExecuteData("savepaymentgateway", this.token, this.state)
        .then(responseJson => {
          if (responseJson.code === 200) {
            alert("Save Successfully");
          } else {
            alert("Something went wrong. Try Again.");
          }
        })
        .catch(error => {
          this.setState({ errors: true });
        });
    }
  };

  render() {
    let user_type = this.props.userPermissions.user_type;
    const { redirect } = this.state;
    if (redirect) {
      return <Redirect push to="/paymentgateway/" />;
    }
    return (
      <Can
        I={["view-paymentgateway"]}
        userPermissions={this.props.userPermissions.userPermissions}
      >
        <div>
          <div className="row wrapper border-bottom white-bg page-heading">
            <div className="col-lg-10">
              <h2 style={{ marginTop: 10 }}>{__t("keys")}</h2>
              <ol className="breadcrumb">
                <li className="breadcrumb-item">
                  <Link to="/">{__t("dashboard")}</Link>
                </li>

                <li className="breadcrumb-item active">
                  <strong>{__t("keys")}</strong>
                </li>
              </ol>
            </div>
          </div>
          <div className="row animated fadeInRight">
            <div className="col-md-12">
              <div className="ibox float-e-margins">
                {user_type == "admin" ? (
                  <div>
                    <div className="ibox-title">
                      <h5>
                        {__t("paymentgateway")} ({__t("paypal")})
                      </h5>
                    </div>
                    <div className="ibox-content">
                      <div className="row">
                        <div
                          className={"gorm-group col-md-6 col-sm-6 col-xs-12"}
                        >
                          <label className="control-label">
                            {__t("paypal_client_id")}
                          </label>
                          <input
                            type="text"
                            name={"paypal_client_id"}
                            value={this.state.paypal_client_id}
                            className={"form-control"}
                            placeholder={__t("paypal_client_id")}
                            onChange={this.handleChange}
                          />
                        </div>
                        <div
                          className={"gorm-group col-md-6 col-sm-6 col-xs-12"}
                        >
                          <label className="control-label">
                            {__t("paypal_secret_key")}
                          </label>
                          <input
                            type="text"
                            name={"paypal_secret_key"}
                            value={this.state.paypal_secret_key}
                            className={"form-control"}
                            placeholder={__t("paypal_secret_key")}
                            onChange={this.handleChange}
                          />
                        </div>
                        <div
                          className={"gorm-group col-md-6 col-sm-6 col-xs-12"}
                        >
                          <label className="control-label">
                            {__t("status")}
                          </label>
                          <select
                            type="text"
                            name={"paypale_status"}
                            value={this.state.paypale_status}
                            className={"form-control"}
                            onChange={this.handleChange}
                          >
                            <option value={""}>{__t("select_status")}</option>
                            <option value={"active"}>{__t("active")}</option>
                            <option value={"inactive"}>
                              {__t("inactive")}
                            </option>
                          </select>
                        </div>
                      </div>
                    </div>

                    <div className="ibox-title">
                      <h5>
                        {__t("paymentgateway")} ({__t("stripe")})
                      </h5>
                    </div>
                    <div className="ibox-content">
                      <div className="row">
                        <div
                          className={"gorm-group col-md-6 col-sm-6 col-xs-12"}
                        >
                          <label className="control-label">
                            {__t("stripe_publishable_key")}
                          </label>
                          <input
                            type="text"
                            name={"stripe_publishable_key"}
                            value={this.state.stripe_publishable_key}
                            className={"form-control"}
                            placeholder={__t("stripe_publishable_key")}
                            onChange={this.handleChange}
                          />
                        </div>
                        <div
                          className={"gorm-group col-md-6 col-sm-6 col-xs-12"}
                        >
                          <label className="control-label">
                            {__t("stripe_secret_key")}
                          </label>
                          <input
                            type="text"
                            name={"stripe_secret_key"}
                            value={this.state.stripe_secret_key}
                            className={"form-control"}
                            placeholder={__t("stripe_secret_key")}
                            onChange={this.handleChange}
                          />
                        </div>
                        <div
                          className={"gorm-group col-md-6 col-sm-6 col-xs-12"}
                        >
                          <label className="control-label">
                            {__t("status")}
                          </label>
                          <select
                            type="text"
                            name={"stripe_status"}
                            value={this.state.stripe_status}
                            className={"form-control"}
                            onChange={this.handleChange}
                          >
                            <option value={""}>{__t("select_status")}</option>
                            <option value={"active"}>{__t("active")}</option>
                            <option value={"inactive"}>
                              {__t("inactive")}
                            </option>
                          </select>
                        </div>
                      </div>
                    </div>
                  </div>
                ) : (
                  ""
                )}

                <div className="ibox-title">
                  <h5>{__t("google_geo_api_key")}</h5>
                </div>
                <div className="ibox-content">
                  <div className="form-horizontal">
                    <div className={"gorm-group col-md-6 col-sm-6 col-xs-12"}>
                      <input
                        type="text"
                        name={"google_api_key"}
                        value={this.state.google_api_key}
                        className={"form-control"}
                        placeholder={__t("google_places_api_key")}
                        onChange={this.handleChange}
                      />
                    </div>
                  </div>
                </div>
                <div className="ibox-content">
                  <div className="form-horizontal">
                    <div className="row form-group">
                      <div className="col-md-12">
                        <button
                          className="btn btn-primary"
                          onClick={this.create}
                          type="submit"
                        >
                          {__t("save")}
                        </button>
                        &nbsp;&nbsp;
                        <Link className="btn btn-white" to="/">
                          {__t("cancel")}
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Can>
    );
  }
}

export default PaymentgatewayUpdate;
